import _ from 'lodash';

const CORD: 'cord' = 'cord';
const DONOR: 'donor' = 'donor';
const PATIENT: 'patient' = 'patient';
const SAMPLE: 'sample' = 'sample';

export const subjectSearchTypes = {
  cord: {
    id: CORD,
    displayName: 'Cord Donor',
  },
  donor: {
    id: DONOR,
    displayName: 'Donor',
  },
  patient: {
    id: PATIENT,
    displayName: 'Patient',
  },
  sample: {
    id: SAMPLE,
    displayName: 'Sample',
  },
};

export const getSubjectSearchTypesDisplayString = (id: string) =>
  _.find(subjectSearchTypes, (type) => type.id === id)?.displayName;

export const getSubjectSearchPlaceholder = (id: string) => {
  if (id === subjectSearchTypes.cord.id) {
    return 'Donor';
  }
  return _.find(subjectSearchTypes, (type) => type.id === id)?.displayName;
};

export const getSubjectSearchResultDisplayString = (id: string) => {
  if (id === subjectSearchTypes.donor.id) {
    return 'Adult Donor';
  }
  return _.find(subjectSearchTypes, (type) => type.id === id)?.displayName;
};

import React, { PureComponent } from 'react';
import { Popover, PopoverPosition } from 'react-tiny-popover';
import { colors, styles as commonStyles } from '../../../style';
import { ReportStatus } from '../../types';
import ReportPageButton from './ReportPageButton';

type Props = {
  isPopUpShown: boolean;
  message: string;
  onConfirm: (reason: string) => void;
  onCancel: (
    event: React.SyntheticEvent<HTMLButtonElement> & {
      currentTarget: HTMLButtonElement;
    }
  ) => void;
  handleButtonClick: (status: ReportStatus) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  target: HTMLButtonElement | null | undefined;
  placement: string;
  updatedStatus: string;
  buttonName: string;
};
type State = {
  reason: string;
};

const styles = {
  ...commonStyles,
  popUpConfirmation: {
    border: `1px solid ${colors.ANGreen}`,
    color: '#000',
    backgroundColor: 'white',
    padding: '20px',
    top: '30%',
    left: '62%',
    transform: 'translate(-50%, -50%) !important',
  },
  popUpConfirmationButton: {
    display: 'inline-flex',
    margin: '0.5em',
  },
};

class ReasonPopUp extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { reason: '' };
  }

  render() {
    const { isPopUpShown, message, onCancel, placement, handleButtonClick, updatedStatus, buttonName } = this.props;
    const { reason } = this.state;
    const popoverContent = (
      <div>
        {message}
        <input type="text" value={reason} onChange={this.handleReasonChange} />
        <button
          className="btn remove-arrow centre-text-on-btn"
          style={styles.popUpConfirmationButton}
          onClick={this.internalOnConfirm}
          type="button"
        >
          Confirm
        </button>
        <button
          className="btn remove-arrow centre-text-on-btn"
          style={styles.popUpConfirmationButton}
          onClick={onCancel}
          type="button"
        >
          Cancel
        </button>
      </div>
    );
    return (
      <Popover
        isOpen={isPopUpShown}
        // @ts-expect-error - function signature type of onCancel is not matching with react-tiny-popover onClickOutside props
        onClickOutside={onCancel}
        positions={[placement as PopoverPosition]}
        content={popoverContent}
        containerStyle={
          {
            ...styles.popUpLevel,
            ...styles.notificationPopUp,
            ...styles.popUpConfirmation,
          } as Partial<CSSStyleDeclaration>
        }
        reposition={false}
      >
        <ReportPageButton handleButtonClick={handleButtonClick} updatedStatus={updatedStatus} buttonName={buttonName} />
      </Popover>
    );
  }

  handleReasonChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    this.setState({
      reason: target.value,
    });
  };

  internalOnConfirm = () => {
    const { onConfirm } = this.props;
    const { reason } = this.state;
    onConfirm(reason);
  };
}

export default ReasonPopUp;

import React from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../redux/actions';
import Selectors from '../../../redux/selectors';
import { ReduxState } from '../../../../rootReducer';

export const DkmsUkDonorsFilter = () => {
  const excludeDkmsUkDonors = useSelector((state: ReduxState) => Selectors.getExcludeDkmsUkDonorsFilter(state));

  const dispatch = useDispatch();

  const handleExcludeDkmsUkDonorsChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const updateExcludeDkmsUkFilter = bindActionCreators(actions.updateExcludeDkmsUkDonorsFilter, dispatch);
    updateExcludeDkmsUkFilter(event.currentTarget.checked);
  };

  return (
    <div className="form-checkbox-wrapper">
      <input
        checked={excludeDkmsUkDonors}
        id="excludeDkmsUkDonors"
        name="excludeDkmsUkDonors"
        onChange={handleExcludeDkmsUkDonorsChange}
        type="checkbox"
        className="form-checkbox--large"
      />
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor="excludeDkmsUkDonors">Exclude DKMS UK Donors</label>
    </div>
  );
};

import _ from 'lodash';
import { SearchRequestSelectors } from '../../donorMatchSearchRequests';
import { searchTypesDetails } from '../../core/constants/searchTypes';
import * as FilterHelper from '../helpers/filterHelper';
import donorTypes from '../../core/constants/donorTypes';
import type { AdultSearchResult, CordSearchResult, DonorType, SearchResults } from '../../core/types';
import type { SearchType } from '../../core/constants/searchTypes';
import type {
  AgeFilterValue,
  AppliedFiltersArray,
  AppliedFiltersMap,
  HideableColumn,
  MinFilterValue,
  MinFilterType,
  MismatchesFilterValue,
  SearchMetrics,
  ValuesFilterType,
} from '../types';
import type { ReduxState } from '../../rootReducer';
import type { ResultsPageUIReducerState } from './resultsPageUI/resultsPageUi';
import type {
  ResultSetSummary,
  SavedResultSet,
  SearchRequestSummary,
  SelectedResult,
} from '../../donorMatchSearchRequests/types';
import type { ApiData } from './resultsPageData/searchResultsData';

const getSearchRequest = (state: ReduxState): ApiData<SearchRequestSummary> =>
  state.searchResultsPage.searchResultsData.searchRequest;

const getResultsPageUiData = (state: ReduxState): ResultsPageUIReducerState => state.searchResultsPage.resultsPageUi;

const isSearchRequestClosed = (state: ReduxState): boolean => {
  const searchRequest = getSearchRequest(state);
  return searchRequest.data ? searchRequest.data.status === 'Closed' : false;
};

const isSearchRequestHlaAdjusted = (state: ReduxState): boolean => {
  const searchRequest = getSearchRequest(state);
  return searchRequest.data
    ? searchRequest.data.isHlaAdjusted || searchRequest.data.searchedHlaMatchCurrentPatientHla === false
    : false;
};

const getRequestPatientId = (state: ReduxState): string | undefined => {
  const { data } = getSearchRequest(state);
  return data ? data.patientId : undefined;
};

const getRequestSearchType = (state: ReduxState, donorType: DonorType): SearchType | undefined => {
  const { data } = getSearchRequest(state);
  if (data) {
    return donorType === donorTypes.adult.value ? data.adultSearchType : data.cordSearchType;
  }
  return undefined;
};

const getRequestRequestedDate = (state: ReduxState): string | void => {
  const { data } = getSearchRequest(state);
  return data ? data.requestedDate : undefined;
};

const getRequestAlgorithmsUsed = (state: ReduxState): string[] => {
  const { data } = getSearchRequest(state);
  return data ? data.algorithmsUsed : [];
};

const getRequestResultSetSummaries = (state: ReduxState): ResultSetSummary[] => {
  const { data } = getSearchRequest(state);
  return data ? data.resultSetSummaries : [];
};

const getResultSetUnscoredCount = (state: ReduxState, donorType: string): number | undefined => {
  const { data } = getSearchRequest(state);
  const resultSet = data && data.resultSetSummaries.find((set) => set.donorType === donorType);
  return resultSet ? resultSet.unknownCount : undefined;
};

const getDonorsSaved = (state: ReduxState): ApiData<string[]> => state.searchResultsPage.searchResultsData.donorsSaved;

const getCordsSaved = (state: ReduxState): ApiData<string[]> => state.searchResultsPage.searchResultsData.cordsSaved;

const getSavedResultSets = (state: ReduxState, donorType: DonorType): SavedResultSet[] => {
  const { searchResultsData } = state.searchResultsPage;
  const savedResultSetIds =
    (donorType === donorTypes.adult.value ? searchResultsData.donorsSaved.data : searchResultsData.cordsSaved.data) ||
    [];

  const savedResultSets: SavedResultSet[] = [];
  savedResultSetIds.forEach((setId) => {
    const savedResultSet = SearchRequestSelectors.getSingleSavedResultSet(state, donorType, setId);
    if (savedResultSet) {
      savedResultSets.push(savedResultSet);
    }
  });
  return savedResultSets;
};

const getAppliedFilters = (state: ReduxState): AppliedFiltersMap => getResultsPageUiData(state).appliedFilters;
const getAppliedFiltersArray = (state: ReduxState): AppliedFiltersArray =>
  getResultsPageUiData(state).appliedFiltersArray;

const getAgeFilter = (state: ReduxState): AgeFilterValue | undefined => {
  const filter = getAppliedFilters(state).age;
  return filter ? filter.value : undefined;
};

const getExcludeDkmsUkDonorsFilter = (state: ReduxState): boolean | undefined => {
  const filter = getAppliedFilters(state).excludeDkmsUkDonors;
  return filter ? filter.value : undefined;
};

const getMismatchesFilter = (state: ReduxState): MismatchesFilterValue | undefined => {
  const filter = getAppliedFilters(state).mismatches;
  return filter ? filter.value : undefined;
};

const getValuesFilter = (state: ReduxState, filterName: ValuesFilterType): string[] => {
  const filter = getAppliedFilters(state)[filterName];
  return filter ? filter.value : [];
};

const getMinFilter = (state: ReduxState, filterName: MinFilterType): MinFilterValue | undefined => {
  const filter = getAppliedFilters(state)[filterName];
  return filter ? filter.value : undefined;
};

const getSearchMetrics = (state: ReduxState): ApiData<{ [string: string]: SearchMetrics }> =>
  state.searchResultsPage.searchResultsData.searchMetrics;

const getSearchResultsData = (state: ReduxState, resultSetId: string): ApiData<SearchResults> | void =>
  state.searchResultsPage.searchResultsData.SearchResults[resultSetId];

const getSearchResults = (state: ReduxState, resultSetId: string): SearchResults | undefined => {
  const searchResultsData = getSearchResultsData(state, resultSetId);
  return searchResultsData ? searchResultsData.data : undefined;
};

const getSearchErrorMessage = (state: ReduxState, resultSetId: string): string | undefined => {
  const searchResultsData = getSearchResultsData(state, resultSetId);
  return searchResultsData ? searchResultsData.message : undefined;
};

const hasSearchErrored = (state: ReduxState, resultSetId: string): boolean => {
  const searchResultsData = getSearchResultsData(state, resultSetId);
  return searchResultsData ? searchResultsData.hasErrored : false;
};

const isSearchLoading = (state: ReduxState, resultSetId: string): boolean => {
  const searchResultsData = getSearchResultsData(state, resultSetId);
  return searchResultsData ? searchResultsData.isFetching : false;
};

const getSearchMetricsForAlgorithm = (state: ReduxState, resultSetId: string): SearchMetrics | undefined => {
  const allSearchMetrics = getSearchMetrics(state);
  return _.get(allSearchMetrics.data, resultSetId, undefined);
};

const getFilteredSearchResults = (
  state: ReduxState,
  resultSetId: string,
  donorType: string
): SearchResults | undefined => {
  const searchResults = getSearchResults(state, resultSetId);
  const appliedFiltersArray = getAppliedFiltersArray(state);

  if (!searchResults) {
    return undefined;
  }

  if (searchResults.type !== donorType) {
    throw new Error(`Search results are not of type '${donorType}'`);
  }

  return FilterHelper.applyFilters(appliedFiltersArray, searchResults, []) as SearchResults;
};

const getSearchRequestId = (state: ReduxState): string | undefined => {
  const { data } = getSearchRequest(state);
  return data ? data.id : undefined;
};

const getSelectedItems = (state: ReduxState): Partial<SelectedResult>[] => getResultsPageUiData(state).selectedItems;

const getHiddenColumns = (state: ReduxState): HideableColumn[] => getResultsPageUiData(state).hiddenColumns;

const getHasErrored = (state: ReduxState, resultSetId: string): boolean => {
  const { searchResultsData } = state.searchResultsPage;
  const searchResultsErrored = hasSearchErrored(state, resultSetId);
  return (
    searchResultsData.searchRequest.hasErrored ||
    searchResultsData.searchMetrics.hasErrored ||
    searchResultsErrored ||
    searchResultsData.donorsSaved.hasErrored ||
    searchResultsData.cordsSaved.hasErrored
  );
};

const getErrorMessages = (state: ReduxState, resultSetId: string): (string | undefined)[] => {
  const errorMessages = [];
  if (getSearchRequest(state).message) {
    errorMessages.push(getSearchRequest(state).message);
  }
  if (getSearchMetrics(state).message) {
    errorMessages.push(getSearchMetrics(state).message);
  }
  const searchResultsMessage = getSearchErrorMessage(state, resultSetId);
  if (searchResultsMessage) {
    errorMessages.push(searchResultsMessage);
  }
  if (getDonorsSaved(state).message) {
    errorMessages.push(getDonorsSaved(state).message);
  }

  // $FlowExpectedError - not detecting that `message` will always be a string
  return errorMessages;
};

const getSelectedSavedNotes = (state: ReduxState): string => getResultsPageUiData(state).savedDonorsNotes;

const getShownResultSetId = (state: ReduxState, donorType: DonorType): string | undefined => {
  const data = getResultsPageUiData(state);
  return donorType === donorTypes.adult.value ? data.shownSavedDonorSetId : data.shownSavedCordSetId;
};

const getSelectedItemsIds = (state: ReduxState): string[] =>
  getSelectedItems(state).map((donor: Partial<SelectedResult>) => {
    const donorId = donor as SelectedResult;
    return donorId.id;
  });

const getVisibleSelectedIds = (state: ReduxState, resultSetId: string, donorType: string): string[] => {
  const selectedIds = getSelectedItemsIds(state);
  const filteredResults = getFilteredSearchResults(state, resultSetId, donorType);
  const adultSearchResult = filteredResults?.results as AdultSearchResult[];
  const cordSearchResult = filteredResults?.results as CordSearchResult[];

  if (!filteredResults) {
    return [];
  }

  return filteredResults.type === donorTypes.cord.value
    ? cordSearchResult
        .filter((res: CordSearchResult) => selectedIds.includes(res.cord.cordId))
        .map((res: CordSearchResult) => res.cord.cordId)
    : adultSearchResult
        .filter((res: AdultSearchResult) => selectedIds.includes(res.donor.id))
        .map((res: AdultSearchResult) => res.donor.id);
};

const getVisibleSelectedItems = (
  state: ReduxState,
  resultSetId: string,
  donorType: DonorType
): Partial<SelectedResult>[] => {
  const visibleIds = getVisibleSelectedIds(state, resultSetId, donorType);

  return getSelectedItems(state).filter((item) => visibleIds.includes(item.id as string));
};

const isPotentialSearch = (state: ReduxState): boolean => {
  const { data } = getSearchRequest(state);
  return data ? data.adultSearchType === searchTypesDetails.POTENTIAL_SEARCH.value : false;
};

const isFetchingSavedResults = (state: ReduxState, donorType: DonorType): boolean =>
  donorType === donorTypes.adult.value ? getDonorsSaved(state).isFetching : getCordsSaved(state).isFetching;

export default {
  getSearchRequest,
  getRequestPatientId,
  getRequestSearchType,
  getRequestRequestedDate,
  getRequestAlgorithmsUsed,
  getRequestResultSetSummaries,
  getResultSetUnscoredCount,
  getSavedResultSets,
  getSearchResults,
  getSearchMetricsForAlgorithm,
  getAppliedFilters,
  getAppliedFiltersArray,
  getSearchRequestId,
  getSelectedItems,
  getHiddenColumns,
  getHasErrored,
  getErrorMessages,
  getSelectedSavedNotes,
  getShownResultSetId,
  getVisibleSelectedIds,
  getVisibleSelectedItems,
  isPotentialSearch,
  isFetchingSavedResults,
  isSearchRequestClosed,
  // Refactored selectors
  getAgeFilter,
  getMismatchesFilter,
  getValuesFilter,
  getMinFilter,
  // New selectors
  getSearchErrorMessage,
  hasSearchErrored,
  isSearchLoading,
  isSearchRequestHlaAdjusted,
  getFilteredSearchResults,
  getExcludeDkmsUkDonorsFilter,
};

import _ from 'lodash';
import { LOCATION_CHANGE } from 'connected-react-router';
import { Actions, SubjectActions } from './actions';
import {
  OriginalSample,
  RelationshipType,
  SubjectConstants,
  SubjectInvestigation,
  SubjectLinkExpanded,
  TestCategory,
} from '../types';
import { ApiErrorType, InstitutionAddress } from '../../core/types';

export type SubjectReducerState = {
  relationshipTypes: RelationshipType[];
  loadingRelationshipTypesError: ApiErrorType | unknown;
  loadingRelationshipTypes: boolean;
  loadingLinks: boolean;
  loadingLinksError: ApiErrorType | unknown;
  links: SubjectLinkExpanded[];
  updatingLink: boolean;
  updatingLinkError: ApiErrorType | unknown;
  deletingLink: boolean;
  deletingLinkError: ApiErrorType | unknown;
  subject: any | unknown;
  loadingSubject: boolean;
  loadingSubjectError: ApiErrorType | any;
  investigations: SubjectInvestigation[];
  loadingInvestigations: boolean;
  loadingInvestigationsError: string | undefined;
  loadingOriginalSample: boolean;
  loadingOriginalSampleError: ApiErrorType | unknown;
  originalSample: OriginalSample | undefined;
  testCategories: TestCategory[];
  loadingTestCategories: boolean;
  creatingInvestigationError: string | undefined;
  loadingTestCategoriesError: ApiErrorType | unknown;
  creatingSampleInvestigation: boolean;
  constants: SubjectConstants | undefined;
  constantsError: ApiErrorType | unknown;
  loadingConstants: boolean;
  institutionAddresses: InstitutionAddress[];
  institutionAddressesError: ApiErrorType | unknown;
  loadingInstitutionAddresses: boolean;
};

const initialState: SubjectReducerState = {
  relationshipTypes: [],
  loadingRelationshipTypesError: undefined,
  loadingRelationshipTypes: false,
  loadingLinks: false,
  loadingLinksError: undefined,
  links: [],
  updatingLink: false,
  updatingLinkError: undefined,
  deletingLink: false,
  deletingLinkError: undefined,
  subject: undefined,
  loadingSubject: false,
  loadingSubjectError: undefined,
  investigations: [],
  loadingInvestigations: false,
  loadingInvestigationsError: undefined,
  loadingOriginalSample: false,
  loadingOriginalSampleError: undefined,
  originalSample: undefined,
  testCategories: [],
  loadingTestCategories: false,
  creatingInvestigationError: undefined,
  loadingTestCategoriesError: undefined,
  creatingSampleInvestigation: false,
  constants: undefined,
  constantsError: undefined,
  loadingConstants: false,
  institutionAddresses: [],
  institutionAddressesError: undefined,
  loadingInstitutionAddresses: false,
};

export default (state: SubjectReducerState = initialState, action: SubjectActions): SubjectReducerState => {
  switch (action.type) {
    case Actions.GET_RELATIONSHIP_TYPES_REQUEST:
      return {
        ...state,
        loadingRelationshipTypes: true,
        loadingRelationshipTypesError: undefined,
        relationshipTypes: [],
      };
    case Actions.GET_RELATIONSHIP_TYPES_SUCCESS:
      return {
        ...state,
        loadingRelationshipTypes: false,
        loadingRelationshipTypesError: undefined,
        relationshipTypes: action.payload.data,
      };
    case Actions.GET_RELATIONSHIP_TYPES_ERROR:
      return {
        ...state,
        loadingRelationshipTypes: false,
        loadingRelationshipTypesError: action.payload.data,
        relationshipTypes: [],
      };

    case Actions.GET_LINKS_REQUEST:
      return {
        ...state,
        loadingLinks: true,
        loadingLinksError: undefined,
        links: [],
      };
    case Actions.GET_LINKS_SUCCESS:
      return {
        ...state,
        loadingLinks: false,
        loadingLinksError: undefined,
        links: action.payload.data,
      };
    case Actions.GET_LINKS_ERROR:
      return {
        ...state,
        loadingLinks: false,
        loadingLinksError: action.payload.data,
        links: [],
      };

    case Actions.UPDATE_LINK_REQUEST:
      return {
        ...state,
        updatingLink: true,
        updatingLinkError: undefined,
      };
    case Actions.UPDATE_LINK_SUCCESS: {
      return {
        ...state,
        updatingLink: false,
        updatingLinkError: undefined,
      };
    }
    case Actions.UPDATE_LINK_ERROR:
      return {
        ...state,
        updatingLink: false,
        updatingLinkError: action.payload.data,
      };

    case Actions.DELETE_LINK_REQUEST:
      return {
        ...state,
        deletingLink: true,
      };
    case Actions.DELETE_LINK_SUCCESS: {
      const { Id } = action.payload.params;
      const filteredLinks: any[] = _.filter(state.links, (link) => link.Id && link.Id !== Id);
      return {
        ...state,
        deletingLink: false,
        links: filteredLinks,
      };
    }
    case Actions.DELETE_LINK_ERROR:
      return {
        ...state,
        deletingLink: false,
      };

    case Actions.GET_SUBJECT_REQUEST:
      return {
        ...state,
        loadingSubject: true,
        loadingSubjectError: undefined,
      };
    case Actions.GET_SUBJECT_SUCCESS:
      return {
        ...state,
        loadingSubject: false,
        subject: action.payload.data,
        loadingSubjectError: undefined,
      };
    case Actions.GET_SUBJECT_ERROR:
      return {
        ...state,
        loadingSubject: false,
        loadingSubjectError: action.payload.data,
        subject: undefined,
      };
    case Actions.CLEAR_SUBJECT:
      return {
        ...state,
        loadingSubject: false,
        loadingSubjectError: undefined,
        subject: undefined,
      };

    case Actions.GET_SUBJECT_CONSTANTS_REQUEST:
      return {
        ...state,
        constantsError: undefined,
        loadingConstants: true,
      };
    case Actions.GET_SUBJECT_CONSTANTS_SUCCESS:
      return {
        ...state,
        constantsError: undefined,
        loadingConstants: false,
        constants: action.payload.data,
      };
    case Actions.GET_SUBJECT_CONSTANTS_ERROR:
      return {
        ...state,
        constantsError: action.payload.data,
        loadingConstants: false,
      };

    case Actions.GET_INSTITUTION_ADDRESSES_REQUEST:
      return {
        ...state,
        institutionAddressesError: undefined,
        loadingInstitutionAddresses: true,
      };
    case Actions.GET_INSTITUTION_ADDRESSES_SUCCESS:
      return {
        ...state,
        institutionAddressesError: undefined,
        loadingInstitutionAddresses: false,
        institutionAddresses: action.payload.data,
      };
    case Actions.GET_INSTITUTION_ADDRESSES_ERROR:
      return {
        ...state,
        institutionAddressesError: action.payload.data,
        loadingInstitutionAddresses: false,
      };

    case Actions.GET_INVESTIGATIONS_REQUEST:
      return {
        ...state,
        loadingInvestigationsError: undefined,
        loadingInvestigations: true,
      };
    case Actions.GET_INVESTIGATIONS_SUCCESS: {
      return {
        ...state,
        loadingInvestigationsError: undefined,
        loadingInvestigations: false,
        investigations: action.payload.data.Data,
      };
    }
    case Actions.GET_INVESTIGATIONS_ERROR:
      return {
        ...state,
        loadingInvestigationsError: action.payload.params.subjectId,
        loadingInvestigations: false,
      };
    case Actions.GET_INVESTIGATION_ORIGINAL_SAMPLE_REQUEST:
      return {
        ...state,
        loadingOriginalSample: true,
        loadingOriginalSampleError: undefined,
        originalSample: undefined,
      };
    case Actions.GET_INVESTIGATION_ORIGINAL_SAMPLE_SUCCESS:
      return {
        ...state,
        loadingOriginalSample: false,
        loadingOriginalSampleError: undefined,
        originalSample: action.payload.data,
      };
    case Actions.GET_INVESTIGATION_ORIGINAL_SAMPLE_ERROR:
      return {
        ...state,
        loadingOriginalSample: false,
        loadingOriginalSampleError: action.payload,
        originalSample: undefined,
      };
    case Actions.CREATE_SAMPLE_INVESTIGATION_REQUEST:
    case Actions.CREATE_ORIGINAL_SAMPLE_REQUEST:
      return {
        ...state,
        creatingSampleInvestigation: true,
      };

    case Actions.CREATE_SAMPLE_INVESTIGATION_SUCCESS:
    case Actions.CREATE_ORIGINAL_SAMPLE_SUCCESS:
      return {
        ...state,
        creatingSampleInvestigation: false,
        creatingInvestigationError: action.payload.params.errorMessage,
      };

    case Actions.CREATE_SAMPLE_INVESTIGATION_ERROR:
    case Actions.CREATE_ORIGINAL_SAMPLE_ERROR:
      return {
        ...state,
        creatingSampleInvestigation: false,
        creatingInvestigationError: undefined,
      };
    case Actions.GET_TEST_CATEGORIES_REQUEST:
      return {
        ...state,
        loadingTestCategories: true,
        creatingInvestigationError: undefined,
      };
    case Actions.GET_TEST_CATEGORIES_SUCCESS:
      return {
        ...state,
        testCategories: action.payload.data,
        loadingTestCategories: false,
      };
    case Actions.GET_TEST_CATEGORIES_ERROR:
      return {
        ...state,
        loadingTestCategories: false,
        loadingTestCategoriesError: action.payload,
      };

    default:
      return state;
  }
};

import _ from 'lodash';
import { formatGeneticDetails } from '../../../hlaInput/helpers/geneticDetailsFormatter';

import type { Genetics, PatientDetails } from '../../types';
import type { ApiPatientDetails } from '../types/api';

export const convertPatientGeneticsToApi = (details: Genetics, id: string) => {
  const nonGeneticDetails = {
    DonorPatientId: id,
    DonorPatientType: 'P',
  };
  const formattedGeneticDetails = formatGeneticDetails(details);
  return _.merge({}, nonGeneticDetails, ...formattedGeneticDetails);
};

export const convertAdjustedHlaToApi = (details: Genetics) => {
  const formattedAdjustedHla = _.flatten(
    _.map(details, (d, locus) =>
      _.map(d, (antigenId, rowId) => ({
        [`${locus}_${parseInt(rowId, 10) + 1}`]: antigenId,
      }))
    )
  );
  return _.merge({}, ...formattedAdjustedHla);
};

export const convertPatientDetailsToApi = (details: PatientDetails): ApiPatientDetails => ({
  Id: details.id,

  Type: details.patientType,
  IsPrivatePatient: details.isPrivatePatient,
  Status: details.status,
  DateRegistered: details.dateRegistered,

  ClosureReason: details.reasonClosed,
  DateClosed: details.dateClosed,
  CauseOfDeath: details.causeOfDeath,
  DateOfDeath: details.dateOfDeath,

  FirstName: details.firstName,
  MiddleNames: details.secondName,
  Surname: details.surname,
  DateOfBirth: details.dateOfBirth,

  Weight: details.weight,
  Gender: details.gender,
  Ethnicity: details.ethnicity,

  Diagnosis: details.diagnosis,
  DateOfDiagnosis: details.diagnosisDate,

  Cmv: details.cmvType,
  DateCmvTested: details.cmvTestedDate,
  BloodGroup: details.bloodGroup,
  RhD: details.rhType,

  Hospital: details.hospital,
  HospitalNumber: details.hospitalAssignedPatientId,
  ReferringDoctor: details.referringDoctor,

  Invoicee: details.invoicee,
  OriginatingRegistry: details.originatingRegistry,
  OriginatingRegistryPatientId: details.originatingRegistryPatientId,

  Laboratory: details.externalLaboratory,

  // TODO: Ensure ID is sent, not string
  // TODO: Work out whether to be sending this?
  // TransplantCentre: details.transplantCentreId,

  ForeignSearchLocation: details.internationalFileLocation,
  CordSearchLocation: details.cordFileLocation,
  TemporaryFileLocation: details.ukOrGaisFileLocation,

  Notes: details.notes,
});

import React from 'react';
import { styles } from '../../../style';

type Props = {
  data: string[];
};

export default ({ data }: Props) => (
  <div style={styles.doubleLinedCell}>{data.map((entry) => (entry ? <span key={entry}>{entry}</span> : null))}</div>
);

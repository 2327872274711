import React from 'react';
import { HlaPopUpWrapper } from '@an/nova-frontend-hla-popup';
import { styles } from '../../../style';
import { colors } from '../../../../style';
import { algorithmTypes, donorTypes, hlaDisplay } from '../../../../core/index';
import { getLocusMatchCategoryKey } from '../../../constants/locusMatchCategories';

import type { Antigen, DonorType, LocusMatchCategory } from '../../../../core/types';
import lociValues from '../../../../core/constants/lociValues';

type Props = {
  hlaData: Antigen[];
  locusMatchCategory: LocusMatchCategory | null | undefined;
  mouseOverHla: (event: React.MouseEvent<HTMLInputElement>) => void;
  mouseLeftHla: (event: React.MouseEvent<HTMLInputElement>) => void;
  algorithm: string;
  donorType: DonorType;
};

const HlaCell = ({ hlaData, mouseOverHla, mouseLeftHla, locusMatchCategory, algorithm, donorType }: Props) => {
  const hlaDisplayData = hlaDisplay.removeHomozygousData(hlaData);

  const locusHasValue = hlaData[0]?.hlaName != null;

  const shouldShowDpb1MatchCategory =
    (algorithm === algorithmTypes.external || algorithm === algorithmTypes.atlas) &&
    donorType === donorTypes.adult.value &&
    hlaData[0].locus === lociValues.Dpb1;

  // The following does not have an onFocus handler because there is no tab index.  This means the onFocus handler will never be called.
  return (
    <div style={styles.hlaCellContainer}>
      {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
      <div
        key={hlaData[0]?.hlaName}
        style={styles.doubleLinedCell}
        onMouseOver={mouseOverHla}
        onMouseLeave={mouseLeftHla}
      >
        {locusHasValue &&
          hlaDisplayData.map((data) => (
            <div style={styles.hlaCellLocusData} key={data.hlaName}>
              <HlaPopUpWrapper highlightColour={colors.ANGreen} hlaData={data} />
            </div>
          ))}
      </div>
      {shouldShowDpb1MatchCategory && (
        <div style={styles.hlaCellLocusMatchCategory}>
          {getLocusMatchCategoryKey(locusHasValue, locusMatchCategory)}
        </div>
      )}
    </div>
  );
};

export default HlaCell;

import React from 'react';
import { SampleInformation } from '../../../../../../externalInvestigations/types';
import SampleInformationTable from './SampleInformationTable';

type Props = {
  sampleInformations: SampleInformation[];
};

const TestRequestsTab = ({ sampleInformations }: Props) => (
  <div className="results-table results-table--border">
    <SampleInformationTable sampleInformations={sampleInformations} />
  </div>
);

export default TestRequestsTab;

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../../core/components/Button';
import PopUpWrapper from '../../../core/components/PopUpWrapper/PopUpWrapper';
import { deleteLink } from '../../redux/actions';
import Subject from '../../redux/selectors';
import { ReduxState } from '../../../rootReducer';
import { RelationshipType, SubjectLinkExpanded, SubjectType } from '../../types';

type Props = {
  relationshipType: RelationshipType;
  subjectId: string;
  subjectLink: SubjectLinkExpanded;
  subjectType: SubjectType;
};

export const DeleteLinkButton = ({ relationshipType, subjectId, subjectLink, subjectType }: Props) => {
  const [showPopup, setShowPopup] = useState(false);

  const dispatch = useDispatch();
  const isDeletingLink = useSelector((state: ReduxState) => Subject.isDeletingLink(state));

  const handleDeleteLink = () => {
    const updatedSubjectLink = {
      Id: subjectLink.Id,
      DonorId: subjectLink.Donor.Id,
      PatientId: subjectLink.Patient.Id,
      RelationshipType: relationshipType,
    };
    dispatch(deleteLink(updatedSubjectLink, subjectType));
  };

  const handlePopupOpen = () => {
    setShowPopup(true);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  return (
    <PopUpWrapper
      buttonClassName="btn btn--secondary"
      buttonInfo
      isPopUpShown={showPopup}
      name="Delete Link"
      onClick={handlePopupOpen}
      onClosePopover={handlePopupClose}
      placement="top"
      popoverContainerClassName="confirm-popup"
    >
      <small>{`Are you sure you want to delete link to ${subjectType} ${subjectId}?`}</small>
      <div className="btn-group">
        <Button primary text="Yes" onClick={handleDeleteLink} loading={isDeletingLink} />
        <Button secondary text="Cancel" onClick={handlePopupClose} />
      </div>
    </PopUpWrapper>
  );
};

import React, { CSSProperties } from 'react';

type Props = {
  id?: string;
  maxLength: number;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder: string;
  rows: number;
  style?: CSSProperties | undefined;
  value: string | undefined;
  disabled?: boolean;
};

export const SingleLineTextArea = (props: Props) => {
  const disableNewline = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const fullProps = {
    ...props,
    onKeyPress: disableNewline,
  };

  return <textarea {...fullProps} />;
};

SingleLineTextArea.defaultProps = {
  id: undefined,
  style: undefined,
  disabled: undefined,
};

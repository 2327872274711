import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, AnyAction, Dispatch as ReduxDispatch } from 'redux';
import * as actions from '../redux/actions';
import { styles as commonStyles } from '../style';
import EditableRecommendation from './EditableRecommendation';
import { CurrentReportSelectors } from '../../core/redux/selectors';

import type { ApiRecommendation } from '../../types/api';
import type { ReduxState } from '../../../rootReducer';

type DispatchProps = {
  updateEditedRecommendations: typeof actions.updateEditedRecommendations;
  setSelectedRecommendations: typeof actions.setSelectedRecommendations;
};

type StateProps = {
  isReportReadOnly: boolean;
  recommendations: ApiRecommendation[];
  selectedRecommendations: string[];
};
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

const mapStateToProps = (state: ReduxState): StateProps => ({
  isReportReadOnly: CurrentReportSelectors.isReportReadOnly(state),
  recommendations: CurrentReportSelectors.getRecommendationsList(state),
  selectedRecommendations: CurrentReportSelectors.getSelectedRecommendations(state),
});

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>): DispatchProps => ({
  setSelectedRecommendations: bindActionCreators(actions.setSelectedRecommendations, dispatch),
  updateEditedRecommendations: bindActionCreators(actions.updateEditedRecommendations, dispatch),
});

const styles = {
  ...commonStyles,
  noPaddingList: {
    padding: '0px',
    listStyle: 'none',
  },
  checkbox: {
    float: 'left',
    marginLeft: '-25px',
  },
};

class RecommendationsList extends PureComponent<Props> {
  render() {
    const { isReportReadOnly, recommendations } = this.props;
    return (
      <ul className="recommendations-list" style={styles.noPaddingList}>
        {recommendations.map((recommendation) => (
          <li
            key={recommendation.Id}
            style={{
              ...(this.shouldBoldRecommendation(recommendation.Id) ? { fontWeight: 'bold' } : {}),
            }}
          >
            <input
              type="checkbox"
              checked={this.isRecommendationSelected(recommendation.Id)}
              value={recommendation.Id}
              onChange={this.handleRecommendationClick}
              style={{ marginRight: '5px' }}
              disabled={isReportReadOnly}
            />
            <EditableRecommendation
              recommendationEdit={this.handleRecommendationEdit}
              id={recommendation.Id}
              recommendation={recommendation.RecommendationDescription}
              isReadOnly={isReportReadOnly}
            />
          </li>
        ))}
      </ul>
    );
  }

  handleRecommendationClick = (event: React.FormEvent<HTMLInputElement>) => {
    const { setSelectedRecommendations } = this.props;
    const { value } = event.currentTarget;
    // @ts-expect-error we type various ids as strings, but the recommendation ids come back as numbers from the api, so match that here
    return setSelectedRecommendations(parseInt(value, 10));
  };

  handleRecommendationEdit = (editedRecommendation: string, id: string) => {
    const { updateEditedRecommendations } = this.props;
    updateEditedRecommendations({ editedRecommendation, id });
  };

  isRecommendationSelected = (id: string) => {
    const { selectedRecommendations } = this.props;
    return selectedRecommendations.includes(id);
  };

  shouldBoldRecommendation = (id: string) => {
    const { isReportReadOnly } = this.props;
    return isReportReadOnly && this.isRecommendationSelected(id);
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(RecommendationsList);

import donorTypes from '../../../../core/constants/donorTypes';
import type { DonorType } from '../../../../core/types';
import type { ResultTableRow, SavedResultSet } from '../../../../donorMatchSearchRequests/types';

const extraRowHeight = 20;
const extraRowHeightForInternationalRegistries = 40;
const extraRowHeightForSets = 35;
const extraRowHeightForDonors = 60;
const standardRowHeight = 50;

export const setRowHeight = (
  row: ResultTableRow,
  savedResultSets: { [string: string]: SavedResultSet },
  donorType: DonorType
): number => {
  const isCord = donorType === donorTypes.cord.value;

  const setIds = isCord ? row.savedCordSets : row.savedDonorSets;

  const sets = Object.values(savedResultSets).filter(
    (s: SavedResultSet) => setIds.includes(s.id as string) && s.algorithmUsed === row.algorithm
  );

  const notes = sets.map((set) => set.notes);

  const filteredNotes = notes.filter((x) => x.length > 0);

  const additionalNotesHeight = filteredNotes.length * extraRowHeight;

  const additionalRowHeight = sets.length * extraRowHeightForSets;

  const additionalDonorCountHeight =
    row.returnedSolarDonorCount && row.returnedSolarDonorCount.MinorMismatches
      ? extraRowHeightForDonors + extraRowHeight
      : extraRowHeightForDonors;

  const additionalRegistryCountHeight =
    row.externalRegistries && !isCord ? row.externalRegistries.length * extraRowHeightForInternationalRegistries : 0;

  const combinedAdditionalHeight = () => {
    if (sets.length > 1) {
      return Math.max(
        additionalDonorCountHeight,
        additionalRegistryCountHeight,
        additionalRowHeight + additionalNotesHeight
      );
    }
    return Math.max(additionalDonorCountHeight, additionalNotesHeight, additionalRegistryCountHeight);
  };
  return standardRowHeight + combinedAdditionalHeight();
};

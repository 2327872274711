import { externalInvestigationTypes } from '../../core/constants/externalInvestigationTypes';
import {
  ApiExtendedTypingInvestigationDetailsBase,
  ApiIDMInvestigationDetailsBase,
  ApiVTInvestigationDetailsBase,
} from '../../externalInvestigations/types/api';
import { convertBloodRequirementTypeFromApi } from '../../patient/patientDashboard/helpers/apiDataConverter';
import {
  Action,
  ExtendedTypingInvestigationDetailsBase,
  ExternalInvestigationActions,
  ExternalInvestigationDetails,
  IDMInvestigationDetailsBase,
  MessageDenialAction,
  VTInvestigationDetailsBase,
} from '../types';
import {
  ApiAction,
  ApiExternalInvestigationAction,
  ApiActionExternalInvestigationDetails,
  ApiMessageDenialAction,
  ApiActionVTInvestigationDetails,
  ApiActionExtendedTypingInvestigationDetails,
  ApiActionIDMInvestigationDetails,
} from '../types/api';

const convertActionsFromApi = (apiActionsModel: ApiAction[]): Action[] => {
  const apiActionModel = (apiModel: ApiAction) => ({
    messageType: apiModel.MessageType,
    receivedDate: apiModel.ReceivedDate,
    actionId: apiModel.ActionId,
    isActioned: apiModel.IsActioned,
  });
  return apiActionsModel.map(apiActionModel);
};

const convertActionIdmInvestigationDetailsFromApi = (
  idmInvestigationDetails: ApiActionIDMInvestigationDetails
): IDMInvestigationDetailsBase => ({
  infectiousDiseaseMarkers: {
    bloodGroupRhesus: idmInvestigationDetails.InfectiousDiseaseMarkers.BloodGroupRhesus,
    cmv: idmInvestigationDetails.InfectiousDiseaseMarkers.Cmv,
    toxoplasmosis: idmInvestigationDetails.InfectiousDiseaseMarkers.Toxoplasmosis,
    ebv: idmInvestigationDetails.InfectiousDiseaseMarkers.Ebv,
    hivStatus: idmInvestigationDetails.InfectiousDiseaseMarkers.HivStatus,
    hivP24: idmInvestigationDetails.InfectiousDiseaseMarkers.HivP24,
    hepatitisBSurfaceAntigen: idmInvestigationDetails.InfectiousDiseaseMarkers.HepatitisBSurfaceAntigen,
    antibodyHepatitisBSurfaceAntigen: idmInvestigationDetails.InfectiousDiseaseMarkers.AntibodyHepatitisBSurfaceAntigen,
    antibodyHepatitisBCoreAntigen: idmInvestigationDetails.InfectiousDiseaseMarkers.AntibodyHepatitisBCoreAntigen,
    antibodyHepatitisC: idmInvestigationDetails.InfectiousDiseaseMarkers.AntibodyHepatitisC,
    luesStatus: idmInvestigationDetails.InfectiousDiseaseMarkers.LuesStatus,
    altStatus: idmInvestigationDetails.InfectiousDiseaseMarkers.AltStatus,
    hltvAntibody: idmInvestigationDetails.InfectiousDiseaseMarkers.HltvAntibody,
  },
});

const convertActionExtendedTypingInvestigationDetailsFromApi = (
  extendedTypingInvestigationDetails: ApiActionExtendedTypingInvestigationDetails
): ExtendedTypingInvestigationDetailsBase => ({
  resolutionRequired: {
    hlaA: extendedTypingInvestigationDetails.ResolutionRequired.HlaA,
    hlaB: extendedTypingInvestigationDetails.ResolutionRequired.HlaB,
    hlaC: extendedTypingInvestigationDetails.ResolutionRequired.HlaC,
    hlaDrb1: extendedTypingInvestigationDetails.ResolutionRequired.HlaDrb1,
    hlaDrb3: extendedTypingInvestigationDetails.ResolutionRequired.HlaDrb3,
    hlaDrb4: extendedTypingInvestigationDetails.ResolutionRequired.HlaDrb4,
    hlaDrb5: extendedTypingInvestigationDetails.ResolutionRequired.HlaDrb5,
    hlaDqa1: extendedTypingInvestigationDetails.ResolutionRequired.HlaDqa1,
    hlaDqb1: extendedTypingInvestigationDetails.ResolutionRequired.HlaDqb1,
    hlaDpa1: extendedTypingInvestigationDetails.ResolutionRequired.HlaDpa1,
    hlaDpb1: extendedTypingInvestigationDetails.ResolutionRequired.HlaDpb1,
  },
  urgent: extendedTypingInvestigationDetails.Urgent,
});

const convertActionVTInvestigationDetailsFromApi = (
  vtInvestigationDetails: ApiActionVTInvestigationDetails
): VTInvestigationDetailsBase => ({
  edtaRequirements: convertBloodRequirementTypeFromApi(vtInvestigationDetails.EdtaRequirements),
  acdRequirements: convertBloodRequirementTypeFromApi(vtInvestigationDetails.AcdRequirements),
  heparinRequirements: convertBloodRequirementTypeFromApi(vtInvestigationDetails.HeparinRequirements),
  clottedRequirements: convertBloodRequirementTypeFromApi(vtInvestigationDetails.ClottedRequirements),
  urgent: vtInvestigationDetails.Urgent,
  earliestReceiptDate: vtInvestigationDetails.EarliestReceiptDate,
  latestReceiptDate: vtInvestigationDetails.LatestReceiptDate,
  acceptableDays: {
    monday: vtInvestigationDetails.AcceptableDays.Monday,
    tuesday: vtInvestigationDetails.AcceptableDays.Tuesday,
    wednesday: vtInvestigationDetails.AcceptableDays.Wednesday,
    thursday: vtInvestigationDetails.AcceptableDays.Thursday,
    friday: vtInvestigationDetails.AcceptableDays.Friday,
    saturday: vtInvestigationDetails.AcceptableDays.Saturday,
    sunday: vtInvestigationDetails.AcceptableDays.Sunday,
  },
  sampleArrivalDate: vtInvestigationDetails.SampleArrivalDate,
  sampleRecipientInternationalInstitutionCode: vtInvestigationDetails.SampleRecipientInternationalInstitutionCode,
});

const convertActionExternalInvestigationsFromApi = (
  requestType: string,
  externalInvestigation: ApiActionExternalInvestigationDetails
): ExternalInvestigationDetails => {
  if (requestType === externalInvestigationTypes.idm.value) {
    return convertActionIdmInvestigationDetailsFromApi(externalInvestigation as ApiIDMInvestigationDetailsBase);
  }
  if (requestType === externalInvestigationTypes.extendedTyping.value) {
    return convertActionExtendedTypingInvestigationDetailsFromApi(
      externalInvestigation as ApiExtendedTypingInvestigationDetailsBase
    );
  }
  return convertActionVTInvestigationDetailsFromApi(externalInvestigation as ApiVTInvestigationDetailsBase);
};

export const convertExternalInvestigationActionsFromApi = (
  externalInvestigationActions: ApiExternalInvestigationAction[]
): ExternalInvestigationActions[] =>
  externalInvestigationActions.map((externalInvestigationAction) => ({
    transplantCentreName: externalInvestigationAction.TransplantCentreName,
    requestType: externalInvestigationAction.RequestType,
    requestStatus: externalInvestigationAction.RequestStatus,
    patientId: externalInvestigationAction.PatientId,
    formattedPatientId: externalInvestigationAction.FormattedPatientId,
    donorId: externalInvestigationAction.DonorId,
    grid: externalInvestigationAction.Grid,
    id: externalInvestigationAction.Id,
    receivedDate: externalInvestigationAction.ReceivedDate,
    emdisRegistryName: externalInvestigationAction.EmdisRegistryName,
    sampleArrival: externalInvestigationAction.SampleArrival,
    donorStatus: externalInvestigationAction.DonorStatus,
    temporaryUnavailableDate: externalInvestigationAction.TemporaryUnavailableDate,
    details: convertActionExternalInvestigationsFromApi(
      externalInvestigationAction.RequestType,
      externalInvestigationAction.Details
    ),
    actions: convertActionsFromApi(externalInvestigationAction.Actions),
    reservedForThisPatient: externalInvestigationAction.ReservedForThisPatient,
  }));

export const convertRejectedMessageFromApi = (rejectedMessages: ApiMessageDenialAction[]): MessageDenialAction[] =>
  rejectedMessages.map((messageDenialAction) => ({
    transplantCentreName: messageDenialAction.TransplantCentreName,
    requestType: messageDenialAction.Type,
    patientId: messageDenialAction.PatientId,
    donorId: messageDenialAction.DonorId,
    grid: messageDenialAction.Grid,
    receivedDate: messageDenialAction.ReceivedDate,
    emdisRegistryName: messageDenialAction.EmdisRegistryName,
    isActioned: messageDenialAction.IsActioned,
    actionId: messageDenialAction.ActionId,
    remark: messageDenialAction.Remark,
    originOfDenial: messageDenialAction.OriginOfDenial,
  }));

import moment from 'moment';
import { convertAntigen } from '../../core';
import { filterNames } from '../constants/filterNames';
import type {
  ApiAdultDonor,
  ApiAdultSearchResult,
  ApiAppliedAdultFiltersInbound,
  ApiAppliedAdultFiltersOutbound,
  ApiAppliedCordFilters,
  ApiBloodRequirementType,
  ApiCordDonor,
  ApiCordSearchResult,
  ApiDonorHla,
  ApiLocusInfo,
  ApiOriginatingRegistry,
  ApiSearchMetrics,
  ApiVerificationStatus,
} from '../types/api';
import type { AppliedFiltersMap, SearchMetrics } from '../types';
import type { ApiLaboratory } from '../components/ExternalInvestigations/types/api';
import type {
  ExtendedTypingRequestOptions,
  Laboratory,
  SelectedDonorAndVTRequestOptions,
  SelectedDonorAndIDMRequestOptions,
} from '../components/ExternalInvestigations/types';
import type { ApiSavedCordSet, ApiSavedDonorSet, ApiSelectedResult } from '../../donorMatchSearchRequests/types/api';
import type { SavedResultSet, SelectedResult } from '../../donorMatchSearchRequests/types';
import type {
  AdultDonor,
  CordDonor,
  DonorHla,
  LocusInfo,
  AdultSearchResult,
  AdultSearchResults,
  CordSearchResult,
  CordSearchResults,
  OriginatingRegistry,
  VerificationStatus,
} from '../../core/types';

const convertAppliedAdultFiltersFromApi = (filter: ApiAppliedAdultFiltersInbound): AppliedFiltersMap => ({
  abo: filter.Abo
    ? {
        name: filterNames.abo,
        value: filter.Abo,
      }
    : undefined,
  age: filter.Age
    ? {
        name: filterNames.age,
        value: {
          maxAge: filter.Age.MaxAge,
          minAge: filter.Age.MinAge,
        },
      }
    : undefined,
  cmv: filter.Cmv
    ? {
        name: filterNames.cmv,
        value: filter.Cmv,
      }
    : undefined,
  dpb1MatchCategories: filter.Dpb1MatchCategories
    ? {
        name: filterNames.dpb1MatchCategories,
        value: filter.Dpb1MatchCategories,
      }
    : undefined,
  filterSummary: filter.FilterSummary,
  gender: filter.Gender
    ? {
        name: filterNames.gender,
        value: filter.Gender,
      }
    : undefined,
  grid: filter.Grid
    ? {
        name: filterNames.grid,
        value: filter.Grid,
      }
    : undefined,
  homeRegistryId: filter.HomeRegistryId
    ? {
        name: filterNames.homeRegistryId,
        value: filter.HomeRegistryId,
      }
    : undefined,
  mismatches: filter.Mismatches
    ? {
        name: filterNames.mismatches,
        value: {
          locus: filter.Mismatches.Locus || null,
          max: filter.Mismatches.Max,
          searchLociCount: filter.Mismatches.SearchLociCount,
        },
      }
    : undefined,
  originatingRegistryId: filter.RegistryId
    ? {
        name: filterNames.originatingRegistryId,
        value: filter.RegistryId,
      }
    : undefined,
  rhd: filter.Rhd
    ? {
        name: filterNames.rhd,
        value: filter.Rhd,
      }
    : undefined,
});

const convertAppliedAdultFiltersToApi = (filter: AppliedFiltersMap): ApiAppliedAdultFiltersOutbound => ({
  Abo: {
    AllowedValues: filter.abo ? filter.abo.value : [],
  },
  Age: {
    AllowedValues: filter.age ? { MaxAge: filter.age.value.maxAge, MinAge: filter.age.value.minAge } : {},
  },
  Cmv: { AllowedValues: filter.cmv ? filter.cmv.value : [] },
  Dpb1MatchCategories: {
    AllowedValues: filter.dpb1MatchCategories ? filter.dpb1MatchCategories.value : [],
  },
  Gender: { AllowedValues: filter.gender ? filter.gender.value : [] },
  Grid: {
    AllowedValues: filter.grid ? filter.grid.value : [],
  },
  HomeRegistryId: {
    AllowedValues: filter.homeRegistryId ? filter.homeRegistryId.value : [],
  },
  Mismatches: filter.mismatches
    ? {
        Locus: filter.mismatches.value.locus,
        Max: filter.mismatches.value.max,
        SearchLociCount: filter.mismatches.value.searchLociCount,
      }
    : undefined,
  RegistryId: {
    AllowedValues: filter.originatingRegistryId ? filter.originatingRegistryId.value : [],
  },
  Rhd: {
    AllowedValues: filter.rhd ? filter.rhd.value : [],
  },
});

const convertAppliedCordFiltersToApi = (filter: AppliedFiltersMap): ApiAppliedCordFilters => ({
  Abo: { AllowedValues: filter.abo ? filter.abo.value : [] },
  Cd34Count: {
    AllowedValues: filter.cd34 ? { Min: filter.cd34.value.min } : {},
  },
  Cmv: { AllowedValues: filter.cmv ? filter.cmv.value : [] },
  TncCount: { AllowedValues: filter.tnc ? { Min: filter.tnc.value.min } : {} },
});

const convertSelectedResultFromApi = (donor: ApiSelectedResult): SelectedResult => ({
  id: donor.Id,
  matchGrade: donor.MatchGrade,
  matchType: donor.MatchType,
  countryName: donor.CountryName,
});

const convertAppliedCordFiltersFromApi = (filter: ApiAppliedCordFilters): AppliedFiltersMap => ({
  abo:
    filter.Abo && filter.Abo.AllowedValues
      ? {
          name: filterNames.abo,
          value: filter.Abo.AllowedValues,
        }
      : undefined,
  cd34:
    filter.Cd34Count && filter.Cd34Count.AllowedValues
      ? {
          name: filterNames.cd34,
          value: {
            min: filter.Cd34Count.AllowedValues.Min,
          },
        }
      : undefined,
  cmv:
    filter.Cmv && filter.Cmv.AllowedValues
      ? {
          name: filterNames.cmv,
          value: filter.Cmv.AllowedValues,
        }
      : undefined,
  filterSummary: filter.FilterSummary,
  tnc:
    filter.TncCount && filter.TncCount.AllowedValues
      ? {
          name: filterNames.tnc,
          value: {
            min: filter.TncCount.AllowedValues.Min,
          },
        }
      : undefined,
});

export const convertSavedCordSetFromApi = (selection: ApiSavedCordSet): SavedResultSet => ({
  algorithmUsed: selection.AlgorithmUsed,
  appliedFilters: convertAppliedCordFiltersFromApi(selection.AppliedFilters),
  dateModified: selection.DateModified,
  id: selection.Id,
  patientId: selection.PatientId,
  requestId: selection.RequestId,
  resultSetId: selection.ResultSetId,
  notes: selection.Notes,
  selectedResults: selection.SelectedCords.map(convertSelectedResultFromApi),
  user: selection.User,
});

export const convertSavedCordSetsFromApi = (apiSavedCordSets: {
  [string: string]: ApiSavedCordSet;
}): { [string: string]: SavedResultSet } => {
  const cordSets: { [string: string]: SavedResultSet } = {};
  Object.keys(apiSavedCordSets).forEach((key) => {
    cordSets[key] = convertSavedCordSetFromApi(apiSavedCordSets[key]);
  });
  return cordSets;
};

// The inbound ApiSavedCordSet type can't be used as the API expects
// `SavedCordAppliedFilters` rather than `AppliedFilters`
export const convertSavedCordSetToApi = (selection: SavedResultSet) => ({
  Id: selection.id,
  RequestId: selection.requestId,
  PatientId: selection.patientId,
  Notes: selection.notes,
  SavedCordAppliedFilters: convertAppliedCordFiltersToApi(selection.appliedFilters),
  SelectedCords: selection.selectedResults,
  ResultSetId: selection.resultSetId,
});

export const convertSavedDonorSetFromApi = (selection: ApiSavedDonorSet): SavedResultSet => ({
  algorithmUsed: selection.AlgorithmUsed,
  appliedFilters: convertAppliedAdultFiltersFromApi(selection.AppliedFilters),
  dateModified: selection.DateModified,
  id: selection.Id,
  patientId: selection.PatientId,
  requestId: selection.RequestId,
  resultSetId: selection.ResultSetId,
  notes: selection.Notes,
  selectedResults: selection.SelectedDonors.map(convertSelectedResultFromApi),
  user: selection.User,
});

export const convertSavedDonorSetsFromApi = (apiSavedDonorSets: {
  [string: string]: ApiSavedDonorSet;
}): { [string: string]: SavedResultSet } => {
  const donorSets: { [string: string]: SavedResultSet } = {};
  Object.keys(apiSavedDonorSets).forEach((key) => {
    donorSets[key] = convertSavedDonorSetFromApi(apiSavedDonorSets[key]);
  });
  return donorSets;
};

// The inbound ApiSavedDonorSet type can't be used as the API expects
// `SavedDonorAppliedFilters` rather than `AppliedFilters`
export const convertSavedDonorSetToApi = (selection: SavedResultSet) => ({
  Id: selection.id,
  RequestId: selection.requestId,
  PatientId: selection.patientId,
  Notes: selection.notes,
  SavedDonorAppliedFilters: convertAppliedAdultFiltersToApi(selection.appliedFilters),
  SelectedDonors: selection.selectedResults,
  ResultSetId: selection.resultSetId,
});

const convertOriginatingRegistryFromApi = (apiOriginatingRegistry: ApiOriginatingRegistry): OriginatingRegistry => ({
  id: apiOriginatingRegistry.Id ? apiOriginatingRegistry.Id.toString() : '',
  name: apiOriginatingRegistry.Name,
});

export const convertDonorHla = (hla: ApiDonorHla): DonorHla => ({
  dpA11: convertAntigen(hla.Dpa1_1),
  dpA12: convertAntigen(hla.Dpa1_2),
  dpB11: convertAntigen(hla.Dpb1_1),
  dpB12: convertAntigen(hla.Dpb1_2),
  dqA11: convertAntigen(hla.Dqa1_1),
  dqA12: convertAntigen(hla.Dqa1_2),
  dqB11: convertAntigen(hla.Dqb1_1),
  dqB12: convertAntigen(hla.Dqb1_2),
  drB11: convertAntigen(hla.Drb1_1),
  drB12: convertAntigen(hla.Drb1_2),
  drB31: convertAntigen(hla.Drb3_1),
  drB32: convertAntigen(hla.Drb3_2),
  drB41: convertAntigen(hla.Drb4_1),
  drB42: convertAntigen(hla.Drb4_2),
  drB51: convertAntigen(hla.Drb5_1),
  drB52: convertAntigen(hla.Drb5_2),
  hlaA1: convertAntigen(hla.A_1),
  hlaA2: convertAntigen(hla.A_2),
  hlaB1: convertAntigen(hla.B_1),
  hlaB2: convertAntigen(hla.B_2),
  hlaC1: convertAntigen(hla.C_1),
  hlaC2: convertAntigen(hla.C_2),
});

// TODO-Flow: Make `Unknown` fields optional
const convertAdultDonor = (apiDonor: ApiAdultDonor): AdultDonor => ({
  abo: apiDonor.BloodGroupType || 'Unknown',
  age: moment().diff(apiDonor.BirthDate, 'years'),
  cmv: apiDonor.CmvAntibodyType || 'Unknown',
  // TODO-Flow: Make optional
  dateCmvTested: apiDonor.DateCmvTested || '',
  donated: apiDonor.Donated,
  // TODO-Flow: Make optional
  donorRegistry: apiDonor.DonorRegistry || '',
  // TODO-Flow: Make optional
  donorRegistryPrefix: apiDonor.DonorRegistryPrefix || '',
  ethnicity: apiDonor.Ethnicity || 'Unknown',
  gender: apiDonor.Gender || 'Unknown',
  grid: apiDonor.Grid,
  homeRegistryId: apiDonor.HomeRegistryDonorId,
  id: apiDonor.Id,
  // TODO-Flow: Make optional
  lastContacted: apiDonor.LastContacted || '',
  originatingRegistry: convertOriginatingRegistryFromApi(apiDonor.OriginatingRegistry),
  reservedForPatient: apiDonor.ReservedForPatient || '',
  rhd: apiDonor.RhType || 'Unknown',
  state: apiDonor.DonorState,
  // TODO-Flow: Make optional
  status: apiDonor.DonorStatus || '',
  unavailableUntil: apiDonor.UnavailableToDate || '',
  weight: apiDonor.Weight || 'N/A',
  ...convertDonorHla(apiDonor.Hla),
});

const convertLocusInfo = (apiLocusInfo?: ApiLocusInfo): LocusInfo | void =>
  apiLocusInfo
    ? {
        matchCount: apiLocusInfo.MatchCount,
        matchGrade1: apiLocusInfo.MatchGrade1,
        matchGrade2: apiLocusInfo.MatchGrade2,
        matchConfidence1: apiLocusInfo.MatchConfidence1,
        matchConfidence2: apiLocusInfo.MatchConfidence2,
        matchCategory: apiLocusInfo.MatchCategory,
      }
    : undefined;

const convertVerificationStatusFromApi = (apiVerificationStatus?: ApiVerificationStatus): VerificationStatus | void =>
  apiVerificationStatus
    ? {
        isResultVerified: apiVerificationStatus.IsResultVerified,
        reasonResultIsNotVerified: apiVerificationStatus.ReasonResultIsNotVerified,
      }
    : undefined;

const convertAdultSearchResultFromApi = (apiSearchResult: ApiAdultSearchResult): AdultSearchResult => ({
  donor: convertAdultDonor(apiSearchResult.Donor),
  locusAInfo: convertLocusInfo(apiSearchResult.LocusAInfo),
  locusBInfo: convertLocusInfo(apiSearchResult.LocusBInfo),
  locusCInfo: convertLocusInfo(apiSearchResult.LocusCInfo),
  locusDqb1Info: convertLocusInfo(apiSearchResult.LocusDqb1Info),
  locusDrb1Info: convertLocusInfo(apiSearchResult.LocusDrb1Info),
  locusDpb1Info: convertLocusInfo(apiSearchResult.LocusDpb1Info),
  matchGrade: apiSearchResult.MatchGrade,
  searchMatchGrade: apiSearchResult.SearchMatchGrade,
  matchType: apiSearchResult.MatchType,
  orderNumber: apiSearchResult.OrderNumber,
  searchRequestId: apiSearchResult.SearchRequestId,
  searchRunDate: moment(apiSearchResult.SearchRunDate),
  totalScore: apiSearchResult.TotalScore,
  type: 'Adult',
  verificationStatus: convertVerificationStatusFromApi(apiSearchResult.VerificationStatus),
});

export const convertAdultSearchResultsFromApi = (
  resultSetId: string,
  apiSearchResults: ApiAdultSearchResult[]
): AdultSearchResults => ({
  results: apiSearchResults.map(convertAdultSearchResultFromApi),
  resultSetId,
  type: 'Adult',
});

// TODO-Flow: Make 'Unknown' fields optional
const convertCord = (apiCord: ApiCordDonor): CordDonor => ({
  bloodGroup: apiCord.BloodGroupType,
  cd34CellCount: apiCord.Cd34CellCount,
  cmvAntibodyType: apiCord.CmvAntibodyType || 'Unknown',
  cordId: apiCord.CordId || 'Unknown',
  cordState: apiCord.CordState,
  cordStatus: apiCord.CordStatus,
  dateCmvTested: apiCord.DateCmvTested || '',
  finalVolume: apiCord.FinalVolume,
  freezerLocationCode: apiCord.FreezerLocationCode,
  id: apiCord.Id,
  nucleatedCellsPerMl: apiCord.NucleatedCellsPerMl,
  primaryId: apiCord.PrimaryId,
  registryDonorId: apiCord.HomeRegistryDonorId,
  rhType: apiCord.RhType || 'Unknown',
  tncFinalCount: apiCord.TncFinalCount,
  unavailableUntil: apiCord.UnavailableToDate || '',
  viableCD45CellPercentage: apiCord.ViableCD45CellPercentage,
  volReductionMethod: apiCord.VolReductionMethod,
  yearBanked: apiCord.YearBanked,
  ...convertDonorHla(apiCord.Hla),
});

const convertCordSearchResultFromApi = (apiSearchResult: ApiCordSearchResult): CordSearchResult => ({
  cord: convertCord(apiSearchResult.CordDonor),
  hlaActualMatchScore: apiSearchResult.HlaActualMatchScore,
  hlaPotentialMatchScore: apiSearchResult.HlaPotentialMatchScore,
  locusAInfo: convertLocusInfo(apiSearchResult.LocusAInfo),
  locusBInfo: convertLocusInfo(apiSearchResult.LocusBInfo),
  locusCInfo: convertLocusInfo(apiSearchResult.LocusCInfo),
  locusDpb1Info: convertLocusInfo(apiSearchResult.LocusDpb1Info),
  locusDqb1Info: convertLocusInfo(apiSearchResult.LocusDqb1Info),
  locusDrb1Info: convertLocusInfo(apiSearchResult.LocusDrb1Info),
  searchRequestId: apiSearchResult.SearchRequestId,
  totalScore: apiSearchResult.HlaTotalMatchScore,
  matchCountDenominator: apiSearchResult.MatchCountDenominator,
  type: 'Cord',
  verificationStatus: convertVerificationStatusFromApi(apiSearchResult.VerificationStatus),
});

export const convertCordSearchResultsFromApi = (
  resultSetId: string,
  apiCordSearchResults: ApiCordSearchResult[]
): CordSearchResults => ({
  results: apiCordSearchResults.map(convertCordSearchResultFromApi),
  resultSetId,
  type: 'Cord',
});

export const convertSearchMetricsFromApi = (apiSearchMetrics: ApiSearchMetrics): SearchMetrics => ({
  matchingDonorCount: apiSearchMetrics.MatchingDonorCount,
  searchTimeInMilliseconds: apiSearchMetrics.SearchTimeInMilliseconds,
});

export const convertIDMRequestsOptionsToApi = (
  patientId: string,
  idmRequestDetailsAndDonors: SelectedDonorAndIDMRequestOptions[]
) => {
  const items = idmRequestDetailsAndDonors.map((donor) => ({
    RegistryId: donor.originatingRegistry.id,
    DonorId: donor.id,
    Grid: donor.grid,
    CordId: '',
    PayingInstitutionId: '',
    Remark: donor.remark || '',
    InvestigationDetails: {
      InfectiousDiseaseMarkers: donor.infectiousDiseaseMarkers,
    },
  }));
  return {
    PatientId: patientId,
    Items: items,
  };
};

export const convertExtendedTypingRequestOptionsToApi = (
  extendedTypingRequestOptions: ExtendedTypingRequestOptions,
  isUrgent: boolean,
  patientId: string,
  remark: string | undefined | null,
  donorId?: string,
  registryId?: string
) => ({
  PatientId: patientId,
  DonorId: donorId,
  InvestigationDetails: {
    ResolutionRequired: extendedTypingRequestOptions,
    Urgent: isUrgent,
  },
  Remark: remark || '',
  RegistryId: registryId,
});

export const convertVTRequestsOptionsToApi = (
  patientId: string,
  requestDetails: SelectedDonorAndVTRequestOptions[]
) => {
  const items = requestDetails.map((donor) => ({
    RegistryId: donor.originatingRegistry.id,
    DonorId: donor.id,
    Grid: donor.grid,
    CordId: '',
    Remark: donor.remark || '',
    InvestigationDetails: {
      EdtaRequirements:
        donor.edta.quantity || donor.edta.tubes
          ? {
              Quantity: donor.edta.quantity,
              NumberOfTubes: donor.edta.tubes,
            }
          : undefined,
      AcdRequirements:
        donor.acd.quantity || donor.acd.tubes
          ? {
              Quantity: donor.acd.quantity,
              NumberOfTubes: donor.acd.tubes,
            }
          : undefined,
      HeparinRequirements:
        donor.heparin.quantity || donor.heparin.tubes
          ? {
              Quantity: donor.heparin.quantity,
              NumberOfTubes: donor.heparin.tubes,
            }
          : undefined,
      ClottedRequirements:
        donor.clotted.quantity || donor.clotted.tubes
          ? {
              Quantity: donor.clotted.quantity,
              NumberOfTubes: donor.clotted.tubes,
            }
          : undefined,
      Urgent: donor.urgent,
      EarliestReceiptDate: donor.receptionDates.earliest,
      LatestReceiptDate: donor.receptionDates.latest,
      AcceptableDays: donor.weekdaysForReception,
      SampleRecipientInternationalInstitutionCode: donor.receivingInternationalInstitutionCode,
    },
  }));
  return {
    PatientId: patientId,
    Items: items,
  };
};

export const convertDefaultSampleRequirements = (defaultBloodSampleRequirements: ApiBloodRequirementType) => ({
  edta: {
    quantity: defaultBloodSampleRequirements ? defaultBloodSampleRequirements.EdtaRequirements.Quantity : undefined,
    tubes: defaultBloodSampleRequirements ? defaultBloodSampleRequirements.EdtaRequirements.NumberOfTubes : undefined,
  },
  acd: {
    quantity: defaultBloodSampleRequirements ? defaultBloodSampleRequirements.AcdRequirements.Quantity : undefined,
    tubes: defaultBloodSampleRequirements ? defaultBloodSampleRequirements.AcdRequirements.NumberOfTubes : undefined,
  },
  heparin: {
    quantity: defaultBloodSampleRequirements ? defaultBloodSampleRequirements.HeparinRequirements.Quantity : undefined,
    tubes: defaultBloodSampleRequirements
      ? defaultBloodSampleRequirements.HeparinRequirements.NumberOfTubes
      : undefined,
  },
  clotted: {
    quantity: defaultBloodSampleRequirements ? defaultBloodSampleRequirements.ClottedRequirements.Quantity : undefined,
    tubes: defaultBloodSampleRequirements
      ? defaultBloodSampleRequirements.ClottedRequirements.NumberOfTubes
      : undefined,
  },
});

export const convertLaboratoriesFromApi = (apiLaboratoryResults: ApiLaboratory[]): Laboratory[] =>
  apiLaboratoryResults.map((lab) => ({
    id: lab.Id,
    name: lab.Name,
    internationalInstitutionCode: lab.InternationalInstitutionCode,
    defaultBloodSampleRequirements: convertDefaultSampleRequirements(lab.DefaultBloodSampleRequirements),
  }));

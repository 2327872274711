import React, { CSSProperties } from 'react';
import { Popover, PopoverPosition } from 'react-tiny-popover';
import { styles as commonStyles, colors } from '../../../style';

type Props = {
  isPopUpShown: boolean;
  message: string;
  onConfirm: (
    event: React.SyntheticEvent<HTMLButtonElement> & {
      currentTarget: HTMLButtonElement;
    }
  ) => void;
  onCancelButton: (
    event: React.SyntheticEvent<HTMLButtonElement> & {
      currentTarget: HTMLButtonElement;
    }
  ) => void;
  onOpenPopUp: (
    event: React.SyntheticEvent<HTMLButtonElement> & {
      currentTarget: HTMLButtonElement;
    }
  ) => void;
  placement?: string;
  buttonStyle?: CSSProperties;
  buttonText: string;
  buttonClassName: string;
};

const styles = {
  ...commonStyles,
  popUpConfirmation: {
    backgroundColor: 'white',
    border: `1px solid ${colors.ANGreen}`,
    boxShadow: 'rgb(39 43 55 / 15%) 0px 0px 30px',
    color: '#000',
    padding: '20px',
  },
  popUpConfirmationButton: {
    display: 'inline-flex',
    margin: '0.5em',
  },
};

const ConfirmationPopUp = (props: Props) => {
  const {
    isPopUpShown,
    message,
    onConfirm,
    onCancelButton,
    onOpenPopUp,
    placement,
    buttonText,
    buttonClassName,
    buttonStyle,
  } = props;

  const popoverContent = (
    <div>
      {message}
      <br />
      <button className="btn btn--inline" onClick={onConfirm} type="button">
        Yes
      </button>
      <button className="btn btn--inline btn--secondary" onClick={onCancelButton} type="button">
        No
      </button>
    </div>
  );
  return (
    <Popover
      isOpen={isPopUpShown}
      // @ts-expect-error - function signature type of onCancelButton is not matching with react-tiny-popover onClickOutside props
      onClickOutside={onCancelButton}
      content={popoverContent}
      positions={[placement as PopoverPosition]}
      reposition={false}
      containerStyle={
        {
          ...styles.popUpLevel,
          ...styles.notificationPopUp,
          ...styles.popUpConfirmation,
        } as Partial<CSSStyleDeclaration>
      }
    >
      <button
        className={buttonClassName}
        style={{ cursor: 'pointer', ...buttonStyle }}
        onClick={onOpenPopUp}
        type="button"
      >
        {buttonText}
      </button>
    </Popover>
  );
};

ConfirmationPopUp.defaultProps = {
  placement: undefined,
  buttonStyle: undefined,
};

export default ConfirmationPopUp;

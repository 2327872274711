import React, { CSSProperties } from 'react';
import { verificationTypingOptions, Option } from './VTRequestOptions';
import type { BloodRequirementType } from '../types';
import { calculateTotalVolume } from '../helpers/calculateTotalVolume';

type BloodRequirementValidationStatus = {
  [key: string]: boolean;
  acd: boolean;
  edta: boolean;
  heparin: boolean;
  clotted: boolean;
};

type Props = {
  updateBloodRequirements: (id: string, name: string, input: number | null | undefined) => void;
  requestDetails: BloodRequirementType;
  bloodRequirementsValidationStatus: BloodRequirementValidationStatus;
};

type Styles = {
  rowHeader: CSSProperties;
  table: CSSProperties;
  textboxStyle: CSSProperties;
};

const styles: Styles = {
  rowHeader: {
    fontWeight: 400,
    margin: '0px',
    textAlign: 'left',
  },
  table: {
    width: '100%',
    tableLayout: 'fixed',
    textAlign: 'center',
    padding: '10px',
    marginBottom: '0px',
  },
  textboxStyle: {
    maxWidth: '70%',
    margin: '5px',
    textAlign: 'center',
    padding: '3% 0px 3% 15%',
    fontSize: 'smaller',
  },
};

class BloodRequirementsTable extends React.Component<Props> {
  render() {
    const { requestDetails, bloodRequirementsValidationStatus } = this.props;

    return (
      <div
        style={{
          padding: '0px',
          paddingRight: '5%',
        }}
        className="col span_7_of_12"
      >
        <div style={{ padding: '20px 0 10px' }}>Blood Requirements</div>
        <div className="col span_12_of_12 bloodRequirementsTable">
          <div key="tableHeader" className="col span_12_of_12 bloodRequirementsRow bloodRequirementsHeader">
            <div className="col span_3_of_12 bloodRequirementsCell">&nbsp;</div>
            <div className="col span_3_of_12 bloodRequirementsCell">Quantity</div>
            <div className="col span_3_of_12 bloodRequirementsCell">Tubes</div>
            <div className="col span_3_of_12 bloodRequirementsCell">Total Volume</div>
          </div>
          {Object.values(verificationTypingOptions).map((option: Option) => (
            <div key={option.id} className="col span_12_of_12">
              <div className="col span_12_of_12 bloodRequirementsRow">
                <div className="col span_3_of_12 bloodRequirementsCell">
                  <div style={styles.rowHeader}>{option.label}</div>
                </div>
                <div className="col span_3_of_12 bloodRequirementsCell">
                  <input
                    style={styles.textboxStyle}
                    type="number"
                    min="1"
                    id={option.id}
                    name="quantity"
                    // @ts-expect-error - value doesn't accept null as a type declared in BloodRequirement type under quantity
                    value={requestDetails[option.id].quantity != null ? requestDetails[option.id].quantity : ''}
                    onChange={this.handleTextInput}
                  />
                </div>
                <div className="col span_3_of_12 bloodRequirementsCell">
                  <input
                    style={styles.textboxStyle}
                    type="number"
                    min="1"
                    id={option.id}
                    name="tubes"
                    // @ts-expect-error - value doesn't accept null as a type declared in type BloodRequirement/VTRequirement under tubes
                    value={requestDetails[option.id].tubes != null ? requestDetails[option.id].tubes : ''}
                    onChange={this.handleTextInput}
                  />
                </div>
                <div className="col span_3_of_12 bloodRequirementsCell">
                  <div style={{ textAlign: 'center' }}>{calculateTotalVolume(requestDetails[option.id])}</div>
                </div>
              </div>
              {!bloodRequirementsValidationStatus[option.id] && (
                <div className="col span_12_of_12 bloodRequirementsError">
                  Product quantity and tubes values should either both be empty or greater than zero
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }

  handleTextInput = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const { updateBloodRequirements } = this.props;
    const { id, name, value } = event.currentTarget;
    const input = !value ? null : parseInt(value, 10);
    updateBloodRequirements(id, name, input);
  };
}

export default BloodRequirementsTable;

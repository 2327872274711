/*
 * Remark strings in EMDIS cannot contain certain characters, such as newlines.
 * This replaces whitespace characters with normal spaces
 * and strips out other invalid characters.
 * Use to format remark data before sending it to the API.
 */
export default (remark: string | null | undefined): string | null | undefined => {
  if (remark == null) {
    return null;
  }

  const remarkWithValidWhitespace = remark.replace(/\s/g, ' ');
  const remarkWithoutInvalidCharacters = remarkWithValidWhitespace.replace(/[^ -~]/g, '');
  return remarkWithoutInvalidCharacters;
};

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Selectors from '../redux/selectors';
import * as actions from '../redux/actions';

type Props = {
  patientId: string;
};

export const OpenPatientPopUp = ({ patientId }: Props) => {
  const openPatientError = useSelector(Selectors.getOpenPatientError);
  const openingPatientInProgress = useSelector(Selectors.getIsOpeningPatient);
  const dispatch = useDispatch();
  const openPatient = () => dispatch(actions.openPatient(patientId));
  const onSubmit = (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    openPatient();
  };
  return (
    <form onSubmit={onSubmit}>
      <h1>Re-open this patient?</h1>
      {openPatientError && <p>Something went wrong opening patient: {openPatientError}</p>}
      <button className="btn btn--inline btn--search-request" disabled={openingPatientInProgress} type="submit">
        Open patient
      </button>
    </form>
  );
};

import React from 'react';
import { convertToAlgorithmName } from '../../../patient/patientDashboard/helpers/algorithmConverter';
import type { AlgorithmResults } from './types';

type Props = {
  algorithm1Results: AlgorithmResults;
  algorithm2Results: AlgorithmResults;
};

const getUniqueDonorIds = (donorIds1: string[], donorIds2: string[]) =>
  donorIds1.filter((id) => !donorIds2.includes(id));

const formatDonorIds = (donorIds: string[]) => donorIds.map((id) => `${id}, `);

export default ({ algorithm1Results, algorithm2Results }: Props) => (
  <div>
    <h2>Donor Differences</h2>
    <table style={{ width: '100%' }}>
      <thead>
        <tr>
          <th>Donors only in {convertToAlgorithmName(algorithm1Results.algorithmName)}</th>
          <th>Donors only in {convertToAlgorithmName(algorithm2Results.algorithmName)}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td id="algorithm-1-only-donors">
            {formatDonorIds(getUniqueDonorIds(algorithm1Results.rankedDonorIds, algorithm2Results.rankedDonorIds))}
          </td>
          <td id="algorithm-2-only-donors">
            {formatDonorIds(getUniqueDonorIds(algorithm2Results.rankedDonorIds, algorithm1Results.rankedDonorIds))}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);

import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import RecommendationList from './RecommendationsList';
import { CurrentReportSelectors } from '../../core/redux/selectors';
import { ErrorMessage, LoadingMessage } from '../../../core';

import type { ErrorMessages } from '../../../core/types';
import type { ReduxState } from '../../../rootReducer';

type StateProps = {
  errorMessage: ErrorMessages;
  hasErrored: boolean;
  isFetching: boolean;
};
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

const mapStateToProps = (state: ReduxState): StateProps => ({
  hasErrored: CurrentReportSelectors.hasRecommendationListErrored(state),
  errorMessage: CurrentReportSelectors.getRecommendationListErrorMessage(state),
  isFetching: CurrentReportSelectors.isRecommendationsFetching(state),
});

const ReportRecommendations = ({ errorMessage, hasErrored, isFetching }: Props) => (
  <div>
    <h2 className="border-bottom-solid">Recommendations</h2>
    {hasErrored ? (
      <ErrorMessage errorMessages={errorMessage} />
    ) : (
      <LoadingMessage isLoading={isFetching}>
        <RecommendationList />
      </LoadingMessage>
    )}
  </div>
);

const connector = connect(mapStateToProps);

export default connector(ReportRecommendations);

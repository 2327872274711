import { Actions } from '../actionNames';
// eslint-disable-next-line import/no-cycle
import { convertLaboratoriesFromApi } from '../../../../helpers/apiDataConverter';
import type { Laboratory } from '../../types';
import {
  ExternalInvestigationsCreateVTRequestErrorAction,
  ExternalInvestigationsCreateVTRequestSuccessAction,
  ExternalInvestigationsCreateVTRequestRequestAction,
  FetchBritishLaboratoriestSuccessAction,
  FetchBritishLaboratoriesErrorAction,
  FetchBritishLaboratoriesRequestAction,
} from '../actions';

type UsersActions =
  | ExternalInvestigationsCreateVTRequestErrorAction
  | ExternalInvestigationsCreateVTRequestSuccessAction
  | ExternalInvestigationsCreateVTRequestRequestAction
  | FetchBritishLaboratoriestSuccessAction
  | FetchBritishLaboratoriesErrorAction
  | FetchBritishLaboratoriesRequestAction;

export type VTRequestInitiationReducerState = {
  isCreatingRequest: boolean;
  isLoadingLaboratories: boolean;
  errorMessage?: string;
  laboratories?: Laboratory[];
};

const initialState: VTRequestInitiationReducerState = {
  isCreatingRequest: false,
  isLoadingLaboratories: false,
  errorMessage: undefined,
  laboratories: undefined,
};

export default (
  state: VTRequestInitiationReducerState = initialState,
  action: UsersActions
): VTRequestInitiationReducerState => {
  switch (action.type) {
    case Actions.EXTERNAL_INVESTIGATIONS_CREATE_VT_REQUEST_REQUEST:
      return {
        ...state,
        isCreatingRequest: true,
      };
    case Actions.EXTERNAL_INVESTIGATIONS_CREATE_VT_REQUEST_SUCCESS:
      return initialState;
    case Actions.EXTERNAL_INVESTIGATIONS_CREATE_VT_REQUEST_ERROR:
      return {
        ...state,
        isCreatingRequest: false,
        errorMessage: action.payload.params.errorMessage,
      };
    case Actions.FETCH_BRITISH_LABORATORIES_REQUEST:
      return {
        ...state,
        isLoadingLaboratories: true,
      };
    case Actions.FETCH_BRITISH_LABORATORIES_SUCCESS:
      return {
        ...state,
        isLoadingLaboratories: false,
        laboratories: convertLaboratoriesFromApi(action.payload.data),
      };
    case Actions.FETCH_BRITISH_LABORATORIES_ERROR:
      return {
        ...state,
        isLoadingLaboratories: false,
        errorMessage: action.payload.params.errorMessage,
      };
    default:
      return state;
  }
};

import moment from 'moment';
import type { ReduxState } from '../../../rootReducer';
import type { ApiSavedReportMinimal, ApiReportSearchSummary } from '../../types/api';

const getSavedReports = (state: ReduxState) => state.searchReport.savedReports;

const getSavedReportsDetails = (state: ReduxState): { [string: string]: ApiSavedReportMinimal } =>
  getSavedReports(state).reports;

const getSingleSavedReport = (state: ReduxState, id: string): ApiSavedReportMinimal | undefined =>
  getSavedReportsDetails(state)[id];

const getSavedReportsSearchSummary = (state: ReduxState, id: string): ApiReportSearchSummary =>
  getSavedReportsDetails(state)[id].SearchSummary;

const totalMatchTypeSelectedDonors = (
  searchSummary: ApiReportSearchSummary,
  matchType: keyof ApiReportSearchSummary
): number =>
  Object.values<number>(searchSummary[matchType]).reduce((total: number, current: number) => total + current, 0);

const getReportsTotalNumberOfSavedDonors = (state: ReduxState, id: string) => {
  const searchSummary = getSavedReportsSearchSummary(state, id);
  return {
    AB: totalMatchTypeSelectedDonors(searchSummary, 'AB'),
    ABDR: totalMatchTypeSelectedDonors(searchSummary, 'ABDR'),
  };
};

const getReportsAwaitingAuthIds = (state: ReduxState): string[] =>
  state.searchReport.reportDashboard.reportIdsByStatus.awaitingAuthorisation;

const reportsAwaitingAuthCount = (state: ReduxState): number => getSavedReports(state).awaitingAuthorisationCount;

const getAllAwaitingAuthReports = (state: ReduxState): ApiSavedReportMinimal[] => {
  const reportsAwaitingAuthIds = getReportsAwaitingAuthIds(state);
  return reportsAwaitingAuthIds.map((id) => getSingleSavedReport(state, id)) as ApiSavedReportMinimal[];
};

const isFetchingReports = (state: ReduxState): boolean => getSavedReports(state).isFetching;

const hasFetchReportsErrored = (state: ReduxState): boolean => getSavedReports(state).hasErrored;

const getReportErrorMessage = (state: ReduxState): string[] => {
  const error = getSavedReports(state).message;
  return error ? [error] : [];
};

const getReportIsMinimal = (state: ReduxState): boolean => state.searchReport.savedReports.minimal;

const getSearchReportsByIds = (state: ReduxState, reportIds: string[]): ApiSavedReportMinimal[] =>
  reportIds.map((id) => getSingleSavedReport(state, id.toString())) as ApiSavedReportMinimal[];

const getUsersWithReportsList = (state: ReduxState): string[] => {
  // TODO: Add a function that also takes the reports authorisor
  const savedReports = getSavedReportsDetails(state);
  const userList = Object.values(savedReports).reduce((users: string[], report: ApiSavedReportMinimal) => {
    if (users.includes(report.Author)) {
      return users;
    }
    users.push(report.Author);
    return users;
  }, []);
  return userList;
};

const getSavedReportsLastFifteenDays = (state: ReduxState): ApiSavedReportMinimal[] => {
  const reports = getSavedReportsDetails(state);
  return Object.values(reports).filter((report: ApiSavedReportMinimal) =>
    moment(report.DateGenerated).isAfter(moment().subtract(15, 'days'))
  );
};

const getReportsForSingleUser = (state: ReduxState, user: string): ApiSavedReportMinimal[] => {
  const reports = getSavedReportsLastFifteenDays(state);
  return Object.values(reports).filter(
    (report: ApiSavedReportMinimal) => report.Author === user || report.Authoriser === user
  );
};

export default {
  getSavedReportsSearchSummary,
  getReportsTotalNumberOfSavedDonors,
  reportsAwaitingAuthCount,
  getAllAwaitingAuthReports,
  hasFetchReportsErrored,
  getReportErrorMessage,
  getSingleSavedReport,
  getSavedReports,
  getSearchReportsByIds,
  isFetchingReports,
  getUsersWithReportsList,
  getSavedReportsDetails,
  getReportsForSingleUser,
  getReportIsMinimal,
  getSavedReportsLastFifteenDays,
};

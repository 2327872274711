import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FeatureFlag } from '../../../../../featureFlags';
import { Button } from '../../../../../core/components/Button';
import { styles } from '../../../style';
import {
  ExternalInvestigationReportDetails,
  ExternalInvestigationReports,
  ExternalInvestigationReportStatus,
  SampleInformation,
  ExternalInvestigationTypeValue,
  ExternalInvestigationTextMessage,
} from '../../../../../externalInvestigations/types';
import { PopUpWrapper } from '../../../../../core';
import SampleInformationPopUp from './SampleInformationPopUp';
import { HlaSummaryData, HlaSummaryPopup } from './HlaSummaryPopup';
import { fetchHlaSummary } from '../../../redux/actions';
import { SendResultsButton } from './SendResultsButton';
import TextMessagesPopUp from './TextMessagesPopUp';
import { externalInvestigationTypes } from '../../../../../core/constants/externalInvestigationTypes';

const investigationResultButtonsStyles = {
  sampleInfoPopUpStyle: {
    ...styles.patientDashboardPopUp,
    padding: '30px',
    border: '1px solid #dee2e6',
    fontSize: '14px',
    maxWidth: '1000px',
    width: '80%',
    maxHeight: '80%',
  },
  hlaPopUpStyle: {
    ...styles.patientDashboardPopUp,
    padding: '50px',
    border: '1px solid #dee2e6',
    fontSize: '14px',
    maxWidth: '900px',
    maxHeight: '90%',
    overflow: 'auto',
    width: '80%',
  },
  buttonHolderStyle: {
    display: 'flex',
    flexDirection: 'column' as 'column', // to make type checker pick up right type
    justifyContent: 'center',
    alignItems: 'center',
    gap: '6px',
  },
};

type Props = {
  reports: ExternalInvestigationReports;
  generateVirologyReport: () => void;
  generateHLAReport: () => void;
  downloadReport: (filename: string) => void;
  sampleInformations: SampleInformation[];
  hlaSummaryData: HlaSummaryData;
  shouldShowSendResultsButton: boolean;
  investigationId: number;
  isGias?: boolean;
  patientId: string;
  textMessages: ExternalInvestigationTextMessage[];
  investigationType: ExternalInvestigationTypeValue;
};

const getReportButton = (text: string, reportStatus: ExternalInvestigationReportStatus, action: () => void) => {
  if (reportStatus === ExternalInvestigationReportStatus.Generating) {
    return <div className="disabled-action btn--test-result">Processing</div>;
  }

  if (reportStatus === ExternalInvestigationReportStatus.Downloading) {
    return <div className="disabled-action btn--test-result">Downloading</div>;
  }

  return (
    <div>
      <Button
        text={text}
        onClick={() => {
          action();
        }}
        buttonClass="btn btn--table-btn btn--test-result"
      />
    </div>
  );
};

const getVirologyReportButton = (
  virologyReportDetails: ExternalInvestigationReportDetails,
  generateVirologyReport: () => void,
  downloadReport: (filename: string) => void
) =>
  getReportButton(
    'IDM Report',
    virologyReportDetails.reportStatus,
    virologyReportDetails.reportStatus === ExternalInvestigationReportStatus.ReadyToDownload
      ? () => downloadReport(virologyReportDetails.reportFilename as string)
      : generateVirologyReport
  );

type HlaReportButtonProps = {
  hlaSummaryData: HlaSummaryData;
  openPopup: () => void;
  reports: ExternalInvestigationReports;
  generateHLAReport: () => void;
  downloadReport: (filename: string) => void;
  isHLASummaryPopupShown: boolean;
  setIsSampleInfoPopupShown: (value: boolean) => void;
};

const HLAReportButton = (props: HlaReportButtonProps) => {
  const {
    openPopup,
    hlaSummaryData,
    downloadReport,
    generateHLAReport,
    reports,
    isHLASummaryPopupShown,
    setIsSampleInfoPopupShown,
  } = props;
  return (
    <div>
      <PopUpWrapper
        placement=""
        name="HLA Report"
        shouldDarkenBackground
        buttonClassName="btn btn--table-btn btn--test-result"
        popoverContainerClassName="react-tiny-popover-container--center"
        onClick={() => openPopup()}
        isPopUpShown={isHLASummaryPopupShown}
        popUpStyle={investigationResultButtonsStyles.hlaPopUpStyle}
      >
        <HlaSummaryPopup
          hlaSummaryData={hlaSummaryData}
          downloadReport={downloadReport}
          generateHLAReport={generateHLAReport}
          reports={reports}
          onClose={() => setIsSampleInfoPopupShown(false)}
        />
      </PopUpWrapper>
    </div>
  );
};

type SampleInformationButtonProps = {
  setIsSampleInfoPopupShown: (value: boolean) => void;
  isSampleInfoPopupShown: boolean;
  sampleInformations: SampleInformation[];
  textMessages: ExternalInvestigationTextMessage[];
  hlaSummaryData: HlaSummaryData;
};

const SampleInformationsButton = (props: SampleInformationButtonProps) => {
  const { sampleInformations, isSampleInfoPopupShown, setIsSampleInfoPopupShown, textMessages, hlaSummaryData } = props;
  return (
    <div>
      <PopUpWrapper
        placement=""
        name="Sample Info"
        shouldDarkenBackground
        buttonClassName="btn btn--table-btn btn--test-result"
        popoverContainerClassName="react-tiny-popover-container--center"
        popUpStyle={investigationResultButtonsStyles.sampleInfoPopUpStyle}
        isPopUpShown={isSampleInfoPopupShown}
        onClick={() => setIsSampleInfoPopupShown(true)}
      >
        <SampleInformationPopUp
          hlaSummaryData={hlaSummaryData}
          sampleInformations={sampleInformations}
          textMessages={textMessages}
          onCancel={() => setIsSampleInfoPopupShown(false)}
        />
      </PopUpWrapper>
    </div>
  );
};

type TextMessagesButtonProps = {
  setIsTextMessagesPopupShown: (value: boolean) => void;
  isTextMessagesPopupShown: boolean;
  textMessages: ExternalInvestigationTextMessage[];
  hlaSummaryData: HlaSummaryData;
};

const TextMessagesButton = (props: TextMessagesButtonProps) => {
  const { isTextMessagesPopupShown, setIsTextMessagesPopupShown, textMessages, hlaSummaryData } = props;
  return (
    <div>
      <PopUpWrapper
        placement=""
        name="Text Messages"
        shouldDarkenBackground
        buttonClassName="btn btn--table-btn btn--test-result"
        popoverContainerClassName="react-tiny-popover-container--center"
        popUpStyle={investigationResultButtonsStyles.sampleInfoPopUpStyle}
        isPopUpShown={isTextMessagesPopupShown}
        onClick={() => setIsTextMessagesPopupShown(true)}
      >
        <TextMessagesPopUp
          hlaSummaryData={hlaSummaryData}
          textMessages={textMessages}
          onCancel={() => setIsTextMessagesPopupShown(false)}
        />
      </PopUpWrapper>
    </div>
  );
};

export const InvestigationResultsButtons = ({
  reports,
  generateVirologyReport,
  generateHLAReport,
  downloadReport,
  sampleInformations,
  hlaSummaryData,
  shouldShowSendResultsButton,
  investigationId,
  isGias,
  patientId,
  textMessages,
  investigationType,
}: Props) => (
  <>
    {reports.virologyReportDetails.reportStatus !== ExternalInvestigationReportStatus.NoDataAvailable &&
      getVirologyReportButton(reports.virologyReportDetails, generateVirologyReport, downloadReport)}
    {shouldShowSendResultsButton && hlaSummaryData && (
      <SendResultsButton investigationId={investigationId} isGias={isGias} patientId={patientId} />
    )}
    {reports.hlaReportDetails.reportStatus !== ExternalInvestigationReportStatus.NoDataAvailable && (
      <HLAReportButton
        isHLASummaryPopupShown={false}
        setIsSampleInfoPopupShown={(_) => {}}
        openPopup={() => {}}
        hlaSummaryData={hlaSummaryData}
        downloadReport={downloadReport}
        generateHLAReport={generateHLAReport}
        reports={reports}
      />
    )}
    {investigationType === externalInvestigationTypes.vt.value &&
      (sampleInformations.length > 0 || textMessages.length > 0) && (
        <SampleInformationsButton
          hlaSummaryData={hlaSummaryData}
          sampleInformations={sampleInformations}
          setIsSampleInfoPopupShown={(_) => {}}
          isSampleInfoPopupShown={false}
          textMessages={textMessages}
        />
      )}
    {(investigationType === externalInvestigationTypes.idm.value ||
      investigationType === externalInvestigationTypes.extendedTyping.value) &&
      textMessages.length > 0 && (
        <TextMessagesButton
          isTextMessagesPopupShown={false}
          setIsTextMessagesPopupShown={(_) => {}}
          textMessages={textMessages}
          hlaSummaryData={hlaSummaryData}
        />
      )}
  </>
);

// TODO: EM-1254: Remove results button feature flags once live
export const InvestigationResultsButtonsWithFeatureFlags = ({
  reports,
  generateVirologyReport,
  generateHLAReport,
  downloadReport,
  sampleInformations,
  hlaSummaryData,
  shouldShowSendResultsButton,
  investigationId,
  isGias,
  patientId,
  textMessages,
  investigationType,
}: Props) => {
  const [isSampleInfoPopupShown, setIsSampleInfoPopupShown] = useState(false);
  const [isTextMessagesPopupShown, setIsTextMessagesPopupShown] = useState(false);
  const [isHLASummaryPopupShown, setIsHLASummaryPopupShown] = useState(false);
  const dispatch = useDispatch();

  const isSampleInfoButtonShown =
    investigationType === externalInvestigationTypes.vt.value &&
    (sampleInformations.length > 0 || textMessages.length > 0);

  const isTextMessagesButtonShown =
    (investigationType === externalInvestigationTypes.idm.value ||
      investigationType === externalInvestigationTypes.extendedTyping.value) &&
    textMessages.length > 0;

  const openPopup = () => {
    dispatch(fetchHlaSummary(hlaSummaryData.patientId, hlaSummaryData.donorId, hlaSummaryData.investigationId));
    setIsHLASummaryPopupShown(true);
  };
  // TODO: EM-400 - refactor to extract button components to their own files
  return (
    <div style={investigationResultButtonsStyles.buttonHolderStyle}>
      <FeatureFlag
        flag="showVirologyReportButton"
        render={() =>
          reports.virologyReportDetails.reportStatus !== ExternalInvestigationReportStatus.NoDataAvailable &&
          getVirologyReportButton(reports.virologyReportDetails, generateVirologyReport, downloadReport)
        }
      />
      {shouldShowSendResultsButton && (
        <SendResultsButton investigationId={investigationId} isGias={isGias} patientId={patientId} />
      )}

      <FeatureFlag
        flag="showHlaReportButton"
        render={() =>
          reports.hlaReportDetails.reportStatus !== ExternalInvestigationReportStatus.NoDataAvailable && (
            <HLAReportButton
              isHLASummaryPopupShown={isHLASummaryPopupShown}
              openPopup={openPopup}
              hlaSummaryData={hlaSummaryData}
              downloadReport={downloadReport}
              generateHLAReport={generateHLAReport}
              reports={reports}
              setIsSampleInfoPopupShown={setIsHLASummaryPopupShown}
            />
          )
        }
      />
      {isSampleInfoButtonShown && (
        <SampleInformationsButton
          hlaSummaryData={hlaSummaryData}
          sampleInformations={sampleInformations}
          setIsSampleInfoPopupShown={setIsSampleInfoPopupShown}
          isSampleInfoPopupShown={isSampleInfoPopupShown}
          textMessages={textMessages}
        />
      )}

      {isTextMessagesButtonShown && (
        <TextMessagesButton
          isTextMessagesPopupShown={isTextMessagesPopupShown}
          setIsTextMessagesPopupShown={setIsTextMessagesPopupShown}
          textMessages={textMessages}
          hlaSummaryData={hlaSummaryData}
        />
      )}
    </div>
  );
};

InvestigationResultsButtonsWithFeatureFlags.defaultProps = {
  isGias: undefined,
};

InvestigationResultsButtons.defaultProps = {
  isGias: undefined,
};

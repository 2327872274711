import React from 'react';
import ExpandMore from 'react-icons/lib/md/expand-more';
import ExpandLess from 'react-icons/lib/md/expand-less';

type Props = {
  isExpanded: boolean;
  name?: string;
  isToggleNotes?: boolean;
  toggleExpansion: () => void;
};

const ExpandButton = ({ isExpanded, isToggleNotes, name, toggleExpansion }: Props) => (
  <button
    className={isToggleNotes ? 'btn btn--secondary btn--table' : 'expand-arrow'}
    onClick={toggleExpansion}
    type="button"
  >
    {name}
    {isExpanded ? <ExpandLess size="34" /> : <ExpandMore size="34" />}
  </button>
);

ExpandButton.defaultProps = {
  isToggleNotes: false,
  name: undefined,
};

export default ExpandButton;

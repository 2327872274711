export type Option = {
  id: string;
  label: string;
};

export const extendedTypingRequestChoices: {
  [string: string]: { [string: string]: Option };
} = {
  defaultHla: {
    hlaA: {
      label: 'HLA-A',
      id: 'hlaA',
    },
    hlaB: {
      label: 'HLA-B',
      id: 'hlaB',
    },
    hlaC: {
      label: 'HLA-C',
      id: 'hlaC',
    },
    hlaDrb1: {
      label: 'HLA-DRB1',
      id: 'hlaDrb1',
    },
    hlaDqb1: {
      label: 'HLA-DQB1',
      id: 'hlaDqb1',
    },
    hlaDpb1: {
      label: 'HLA-DPB1',
      id: 'hlaDpb1',
    },
  },
  extraHlaOptions: {
    hlaDrb3: {
      label: 'HLA-DRB3',
      id: 'hlaDrb3',
    },
    hlaDrb4: {
      label: 'HLA-DRB4',
      id: 'hlaDrb4',
    },
    hlaDrb5: {
      label: 'HLA-DRB5',
      id: 'hlaDrb5',
    },
    hlaDqa1: {
      label: 'HLA-DQA1',
      id: 'hlaDqa1',
    },

    hlaDpa1: {
      label: 'HLA-DPA1',
      id: 'hlaDpa1',
    },
  },
};

import React from 'react';
import Select, { SingleValue } from 'react-select';

type Props = {
  input: {
    value: string;
    onChange: (arg1: string) => void;
    onBlur: (arg1: string) => void;
  };
  options: RenderSelectInputOption[];
  name: string;
  id: string;
};

type RenderSelectInputOption = {
  value: string;
  label: string | undefined;
};

type State = {
  value: string;
  label?: string;
};

const valueFromId = (opts: RenderSelectInputOption[], value: string) => {
  const files = opts.find((o) => o.value === value);
  return files ? files.label : undefined;
};

export default class RenderInputSelect extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { input, options } = this.props;
    const value = input.value || '';
    this.state = {
      value,
      label: valueFromId(options, input.value) || 'Select...',
    };
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { input, options } = this.props;
    const { value } = this.state;
    if (prevState.value !== value) {
      input.onChange(value);
    }
    if ((input.value || input.value === '') && prevProps.input.value !== input.value) {
      const selectedOption = options.find((option) => option.value === input.value);
      input.onChange(input.value);
      this.updateState({ label: selectedOption ? selectedOption.label : input.value, value: input.value });
    }
  }

  render() {
    const { input, id, name, options } = this.props;
    const { label, value } = this.state;
    return (
      <Select
        {...input}
        id={id}
        name={name}
        options={options}
        value={{
          value,
          label,
        }}
        onChange={this.updateState}
        onBlur={() => input.onBlur(value)}
        className="react-select-container"
        classNamePrefix="react-select"
      />
    );
  }

  updateState = (e: SingleValue<RenderSelectInputOption>) => {
    if (e) {
      const value = e.value || '';
      const label = e.label || '';
      this.setState({ value, label });
    }
  };
}

import React from 'react';
import SingleDashboardColumn from '../SingleDashboardColumn/SingleDashboardColumn';

import type { ReportIdsByStatus } from '../../../types/index';

type Props = {
  reportIds: ReportIdsByStatus;
};

const DashboardColumns = ({ reportIds }: Props) => (
  <div className="col span_12_of_12">
    <div className="col span_3_of_12">
      <SingleDashboardColumn columnName="Draft" reportIds={reportIds.draft} />
    </div>
    <div className="col span_3_of_12">
      <SingleDashboardColumn columnName="Awaiting Authorisation" reportIds={reportIds.awaitingAuthorisation} />
    </div>
    <div className="col span_3_of_12">
      <SingleDashboardColumn columnName="Authorised" reportIds={reportIds.authorised} />
    </div>
    <div className="col span_3_of_12">
      <SingleDashboardColumn columnName="Sent" reportIds={reportIds.sent} />
    </div>
  </div>
);

export default DashboardColumns;

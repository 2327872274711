import moment from 'moment';
import React, { useState } from 'react';
import './dateTimeInputWithCopy.scss';

type Props = {
  dateTime: Date | undefined;
  isReportReadOnly: boolean;
  onDateChange: (event: React.SyntheticEvent<HTMLInputElement>) => void;
};

export const DateTimeInputWithCopy = (props: Props) => {
  const { dateTime, isReportReadOnly, onDateChange } = props;

  const [copyButtonText, setCopyButtonText] = useState<string>('Copy');

  const formattedDateForInputValue = dateTime ? moment(dateTime).format('YYYY-MM-DDTHH:mm') : undefined;
  const formattedDateToCopy = dateTime ? moment(dateTime).format('DD/MM/YYYYTHH:mm') : undefined;

  const removeT = (date: string) => date.replace('T', ' ');

  const copyToClipBoard = async () => {
    try {
      if (formattedDateToCopy) {
        await navigator.clipboard.writeText(removeT(formattedDateToCopy));
        setCopyButtonText('Copied!');
        setTimeout(() => {
          setCopyButtonText('Copy');
        }, 1200);
      }
    } catch (err) {
      alert('Error, failed to copy!');
    }
  };

  return (
    <div className="input-button-container">
      <input
        name="receivedDate"
        type="datetime-local"
        value={formattedDateForInputValue}
        disabled={isReportReadOnly}
        onChange={onDateChange}
        max={moment().format('YYYY-MM-DDTHH:mm')}
      />
      <button type="button" onClick={copyToClipBoard} className="btn btn--secondary copy">
        {copyButtonText}
      </button>
    </div>
  );
};

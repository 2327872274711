import * as React from 'react';
import formatGrid from './gridFormatter';

export const formatRequestContext = (patientId: string, registryName: string, donorId: string, donorGrid?: string) => {
  const donor = !donorGrid ? (
    <>
      <b>Donor ID</b> {donorId}
    </>
  ) : (
    <>
      <b>GRID</b> {formatGrid(donorGrid)}
    </>
  );
  return (
    <h4 key={donorGrid ?? donorId} className="requestContext">
      {donor} | <b>Patient ID</b> {patientId} | <b>EMDIS Registry</b> {registryName}
    </h4>
  );
};

import React from 'react';
import { useMsal } from '@azure/msal-react';
import { Button } from './Button';

export const AuthError = ({ error }: any) => {
  const { instance } = useMsal();

  return (
    <div className="alert alert--danger">
      An authentication error occurred: {error.message}
      <div style={{ textAlign: 'center' }}>
        <Button text="Refresh" onClick={() => window.location.reload()} />
        <Button text="Logout" onClick={() => instance.logoutRedirect()} secondary />
      </div>
    </div>
  );
};

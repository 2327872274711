import * as React from 'react';

type Props = {
  meta: {
    error: string;
    touched: boolean;
    warning: string;
  };
};

const FieldError = ({ meta: { touched, error, warning } }: Props) => (
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <>
    {touched &&
      ((error && <span className="error-message">{error}</span>) ||
        (warning && <span className="warn-message">{warning}</span>))}
  </>
);

export default FieldError;

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, PopUpWrapper } from '../../../core';
import { LinkedSubjectSearchResult } from './LinkedSubjectSearchResult';
import { LinkedSubjectSearch } from './LinkedSubjectSearch';
import Subject from '../../redux/selectors';
import { subjectTypes } from '../../constants/subjectTypes';
import { capitalise } from '../../../core/helpers/capitalise';
import { clearSubject, getLinks, updateLink } from '../../redux/actions';
import type { SubjectType, RelationshipType } from '../../types';
import { ReduxState } from '../../../rootReducer';

type Props = {
  isLoading: boolean;
  subjectLinkType: SubjectType;
  relationshipTypes: RelationshipType[];
  subjectId: string;
};

const popUpStyle: React.CSSProperties = {
  padding: '0',
  position: 'fixed',
  width: '700px',
  top: '50%',
  left: '50%',
  overflow: 'auto',
  maxHeight: '90%',
};

export const SubjectLinkButton = ({ isLoading, subjectLinkType, relationshipTypes, subjectId }: Props) => {
  const [showPopup, setShowPopup] = useState(false);
  const [relationshipType, setRelationshipType] = useState<RelationshipType | undefined>(undefined);

  const subjectSearchResult = useSelector((state: ReduxState) => Subject.getSubject(state));
  const isLoadingNewLinkedSubject = useSelector((state: ReduxState) => Subject.isLoadingSubject(state));

  const dispatch = useDispatch();

  const openPopUp = () => {
    setShowPopup(true);
  };

  const closePopUp = () => {
    setShowPopup(false);
    setRelationshipType(undefined);
    dispatch(clearSubject());
  };

  const isPatientRecord = subjectLinkType === subjectTypes.donor.id;

  const subjectRecordType = isPatientRecord ? subjectTypes.patient.id : subjectTypes.donor.id;

  const isSaving = useSelector((state: ReduxState) => Subject.isUpdatingLink(state));

  const updatedMainLink = subjectSearchResult &&
    relationshipType && {
      DonorId: isPatientRecord ? subjectSearchResult.Id : subjectId,
      PatientId: isPatientRecord ? subjectId : subjectSearchResult.Id,
      RelationshipType: relationshipType,
      IsMainRelationship: !isPatientRecord,
    };

  const isDisabled = !relationshipType || isSaving || !updatedMainLink;

  const saveLink = async () => {
    if (updatedMainLink) {
      const data = await dispatch<any>(updateLink(updatedMainLink, subjectLinkType, isPatientRecord));
      if (data && data.response.ok) {
        dispatch(getLinks(subjectId, subjectRecordType));
        setShowPopup(false);
        dispatch(clearSubject());
        setRelationshipType(undefined);
      }
    }
  };

  const linkText = isPatientRecord ? 'Create New Link' : 'Change Main Link';

  return (
    <PopUpWrapper
      isButtonDisabled={isLoading}
      placement=""
      name={linkText}
      shouldDarkenBackground
      buttonClassName="btn btn--inline"
      popoverContainerClassName="react-tiny-popover-container--center"
      onClick={openPopUp}
      isPopUpShown={showPopup}
      popUpStyle={popUpStyle}
      onClosePopover={closePopUp}
    >
      <form onSubmit={(e) => e.preventDefault()}>
        <h2>{`${capitalise(subjectRecordType)} ${subjectId} - ${linkText}ed ${capitalise(subjectLinkType)}`}</h2>
        <LinkedSubjectSearch isLoading={isLoadingNewLinkedSubject} subjectLinkType={subjectLinkType} />
        <LinkedSubjectSearchResult
          relationshipTypes={relationshipTypes}
          subjectLinkType={subjectLinkType}
          onRelationshipTypeChange={setRelationshipType}
        />
        {subjectSearchResult && (
          <div className="btn-group">
            <Button onClick={saveLink} text="Save" disabled={isDisabled} loading={isSaving} />
            <Button onClick={closePopUp} secondary text="Cancel" />
          </div>
        )}
      </form>
    </PopUpWrapper>
  );
};

import React from 'react';
import { matchGradeConstants } from '../../../../core/index';

const SymbolKeyPopUp = ({ matchGradeSymbol }: { matchGradeSymbol: string }) => {
  const convertMatchGrade = (symbol: string) => {
    switch (symbol) {
      case matchGradeConstants.matchGradeSymbols.Exact:
        return 'Exact Match Donor';
      case matchGradeConstants.matchGradeSymbols.Potential:
        return 'Potential Match Donor';
      case matchGradeConstants.matchGradeSymbols.MinorMismatch:
        return 'Minor Mismatch Donor';
      case matchGradeConstants.matchGradeSymbols.Mismatch:
        return 'Mismatch Donor';
      default:
        return 'Unknown Symbol';
    }
  };

  return <div>{`${matchGradeSymbol} = ${convertMatchGrade(matchGradeSymbol)} `}</div>;
};

export default SymbolKeyPopUp;

import React from 'react';
import Select from 'react-select';
import Checkbox from '../../../../core/components/Checkbox';
import { getDispatchMethodDisplayString, getSelectableDispatchMethodOptions } from '../../../constants/dispatchMethods';
import { type DispatchMethod } from '../../../types';

type Props = {
  isUrgent: boolean;
  onCheckboxChange: (e: React.SyntheticEvent<HTMLInputElement>) => void;
  onSelectChange: (dispatchMethod: DispatchMethod) => void;
  sampleType: DispatchMethod;
};

const SampleCreationDetails = ({ sampleType, isUrgent, onCheckboxChange, onSelectChange }: Props) => (
  <>
    <h4>Sample Details</h4>
    <div style={{ alignItems: 'end', display: 'flex', justifyContent: 'space-between' }}>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor="sample-type-selector" style={{ width: '60%' }}>
        Sample Type*
        <Select
          className="react-select-container"
          classNamePrefix="react-select"
          isClearable={false}
          id="sample-type-selector"
          placeholder="Select..."
          // @ts-expect-error Options object is not assignable
          options={getSelectableDispatchMethodOptions()}
          onChange={(e) => onSelectChange(e?.value as DispatchMethod)}
          value={{ label: getDispatchMethodDisplayString(sampleType), value: sampleType }}
        />
      </label>
      <div style={{ width: '35%' }}>
        <Checkbox label="Urgent?" onChange={onCheckboxChange} id="urgent-checkbox" checked={isUrgent} />
      </div>
    </div>
  </>
);

export default SampleCreationDetails;

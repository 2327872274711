import _ from 'lodash';

export const externalInvestigationStatuses = {
  ResultsReceived: {
    value: 'ResultsReceived',
    name: 'Results Received',
    orderInTable: 1,
  },
  InProgress: {
    value: 'InProgress',
    name: 'In Progress',
    orderInTable: 2,
  },
  RequestSent: {
    value: 'RequestSent',
    name: 'Request Sent',
    orderInTable: 3,
  },
  NoResults: {
    value: 'NoResults',
    name: 'No Results',
    orderInTable: 4,
  },
  RequestRejected: {
    value: 'RequestRejected',
    name: 'Rejected',
    orderInTable: 5,
  },
  FailedToSend: {
    value: 'FailedToSend',
    name: 'Failed To Send',
    orderInTable: 6,
  },
  Completed: {
    value: 'Completed',
    name: 'Completed',
    orderInTable: 7,
  },
  Cancelled: {
    value: 'Cancelled',
    name: 'Cancelled',
    orderInTable: 8,
  },
  Closed: {
    value: 'Closed',
    name: 'Closed',
    orderInTable: 9,
  },
};

export const getExternalInvestigationStatusName = (value: string): string | undefined =>
  _.find(externalInvestigationStatuses, (externalInvestigationStatus) => externalInvestigationStatus.value === value)
    ?.name;

export const getExternalInvestigationStatusOrderInTable = (value: string): number | undefined =>
  _.find(externalInvestigationStatuses, (externalInvestigationStatus) => externalInvestigationStatus.value === value)
    ?.orderInTable;

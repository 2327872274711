import Radium from 'radium';
import { CSSProperties } from 'react';
import variables from '@an/design-system/src/styles/partials/_variables.module.scss';

const ANGreen = variables.greenDarker;
const ANGreenLight = 'rgb(150, 171, 31)';

const spinKeyframes = Radium.keyframes(
  {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  'spin'
);

export const colors = {
  ANGreen,
  ANGreenLight,
};

export const keyframes = {
  spinKeyframes,
};

export type Styles = {
  allRequests: CSSProperties;
  button: CSSProperties;
  cardHeaderDisabled: CSSProperties;
  cardLayout: CSSProperties;
  cardLink: CSSProperties;
  columnCheckbox: CSSProperties;
  errorMessage: CSSProperties;
  notificationPopUp: CSSProperties;
  page: CSSProperties;
  patientRequestFlexBox: CSSProperties;
  patientRequestsListWrapper: CSSProperties;
  popUpLevel: CSSProperties;
  rightTopCorner: CSSProperties;
  summaryCardColumn: CSSProperties;
  summaryCardText: CSSProperties;
  searchCardText: CSSProperties;
  tableHeader: CSSProperties;
  textContainer: CSSProperties;
  userRequestSelect: Record<string, string>;
  underlineOnHover: Record<string, CSSProperties>;
};

export const styles: Styles = {
  allRequests: {
    marginTop: '9px',
    listStyle: 'none',
    paddingLeft: '0px',
    listStyleType: 'none',
    position: 'unset',
  },
  button: {
    marginRight: '10px',
    color: '#fff',
    borderColor: `${ANGreen}`,
  },
  cardHeaderDisabled: {
    padding: '0.01em 16px',
    color: '#fff',
    backgroundColor: '#999',
    position: 'relative',
  },
  cardLayout: {
    padding: '0.01em',
    marginTop: '16px',
    position: 'relative',
  },
  cardLink: {
    textDecoration: 'none',
  },
  columnCheckbox: {
    margin: '10px 5px 10px 15px',
    cursor: 'pointer',
  },
  errorMessage: {
    textAlign: 'center',
    padding: '15px',
    color: '#fff',
    backgroundColor: `${ANGreen}`,
  },
  notificationPopUp: {
    width: '360px',
    textAlign: 'center',
    fontSize: 'initial',
  },
  page: {
    fontSize: '16px',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  patientRequestFlexBox: {
    boxSizing: 'border-box',
    padding: '0px 10px',
  },
  patientRequestsListWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
  },
  popUpLevel: {
    zIndex: 2,
  },
  rightTopCorner: {
    position: 'absolute',
    right: '0px',
    top: '0px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  summaryCardColumn: {
    width: '50%',
  },
  summaryCardText: {
    padding: '10px 15px 0px',
    display: 'flex',
  },
  searchCardText: {
    paddingBottom: '10px',
    display: 'flex',
  },
  tableHeader: {
    color: '#fff',
  },
  textContainer: {
    padding: '10px 16px',
  },
  userRequestSelect: {
    textSize: '16px',
  },
  underlineOnHover: {
    ':hover': {
      textDecoration: 'underline',
    },
  },
};

import React, { SyntheticEvent } from 'react';

export type CheckboxEvent = { target: { id: string } } & HTMLInputElement;

type Props = {
  id: string;
  onChange: SyntheticEvent<HTMLInputElement> | any;
  disabled?: boolean;
  checked?: boolean;
  label: string;
};

const CheckBox = ({ id, onChange, checked, label, disabled }: Props) => (
  <label htmlFor={id} className="control control--checkbox" style={{ alignItems: 'center', display: 'flex' }}>
    <input
      className="form-checkbox--large"
      id={id}
      onChange={onChange}
      disabled={disabled}
      type="checkbox"
      checked={checked}
    />
    <span>{label}</span>
  </label>
);

CheckBox.defaultProps = {
  checked: false,
  disabled: false,
};

export default CheckBox;

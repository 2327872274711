import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import Checkbox from '../../../../core/components/Checkbox';
import { mapInvoiceeAddressOptions } from '../../../../patient/patientCreation/helpers/formsHelpers';
import { ReduxState } from '../../../../rootReducer';
import SubjectSelectors from '../../../redux/selectors';
import type { InstitutionAddress } from '../../../../core/types';

type Props = {
  invoiceeAddresses: InstitutionAddress[];
  requiresInvoicing: boolean;
  onCheckboxChange: () => void;
  onSelectChange: (invoiceeId: string) => void;
  invoiceeId: string | undefined;
};

const SubjectInvestigationInvoicing = ({
  invoiceeAddresses,
  requiresInvoicing,
  onCheckboxChange,
  onSelectChange,
  invoiceeId,
}: Props) => {
  const isLoadingSubject = useSelector((state: ReduxState) => SubjectSelectors.isLoadingSubject(state));
  const isLoading = _.isEmpty(invoiceeAddresses) || isLoadingSubject;
  const parsedInvoiceeId = invoiceeId ? parseInt(invoiceeId, 10) : undefined;
  const invoiceeOptions = invoiceeAddresses && mapInvoiceeAddressOptions(invoiceeAddresses, parsedInvoiceeId);
  const getInvoiceeLabelOption = invoiceeOptions.filter((invoiceeOption) => invoiceeOption.value === parsedInvoiceeId);

  return (
    <>
      <h4>Invoice To</h4>
      {isLoading ? (
        <div className="loader small">&nbsp;</div>
      ) : (
        <div
          data-testid="invoice-details"
          style={{ alignItems: 'end', display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}
        >
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="invoicee-select" style={{ width: '60%' }}>
            Invoicee
            <Select
              value={
                getInvoiceeLabelOption.length > 0
                  ? { label: getInvoiceeLabelOption[0].label, value: parsedInvoiceeId }
                  : undefined
              }
              className="react-select-container"
              classNamePrefix="react-select"
              key={parsedInvoiceeId}
              placeholder="Select..."
              options={invoiceeOptions}
              onChange={(e) => onSelectChange(e?.value?.toString() as string)}
              name="invoicee-select"
            />
          </label>
          <div style={{ width: '35%' }}>
            <Checkbox
              id="requires-invoicing-checkbox-input"
              label="Requires Invoicing?"
              onChange={onCheckboxChange}
              checked={requiresInvoicing}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default SubjectInvestigationInvoicing;

import { styles as commonStyles } from '../../style';

// eslint-disable-next-line import/prefer-default-export
// Style not used in any files.
export const styles: any = {
  ...commonStyles,
  downArrow: {
    width: '0',
    height: '0',
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderTop: '5px solid black',
    position: 'absolute',
    top: '45%',
    marginLeft: '5px',
  },
  upArrow: {
    width: '0',
    height: '0',
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderBottom: '5px solid black',
    position: 'absolute',
    top: '45%',
    marginLeft: '5px',
  },
  transplantCentreDropDownTitle: {
    position: 'relative',
    textAlign: 'Left',
    cursor: 'pointer',
  },
  upArrowNearBottom: {
    width: '0',
    height: '0',
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderBottom: '5px solid black',
    position: 'absolute',
    top: '40%',
    marginLeft: '5px',
  },
  downArrowNearBottom: {
    width: '0',
    height: '0',
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderTop: '5px solid black',
    position: 'absolute',
    top: '40%',
    marginLeft: '5px',
  },
};

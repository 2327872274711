import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, AnyAction, Dispatch as ReduxDispatch } from 'redux';
import { CurrentReportSelectors } from '../../core/redux/selectors';
import * as actions from '../redux/actions';
import type { ReduxState } from '../../../rootReducer';

type DispatchProps = {
  downloadPdfReport: typeof actions.downloadPdfReport;
  generateReportPdf: typeof actions.generateReportPdf;
};

type StateProps = {
  isPdfCreated: boolean;
  isCreatingPdf: boolean;
  isDownloadingPdf: boolean;
  reportFileName?: string;
  reportId: string;
};
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

const mapStateToProps = (state: ReduxState): StateProps => ({
  isPdfCreated: CurrentReportSelectors.isPdfCreated(state),
  isCreatingPdf: CurrentReportSelectors.isCreatingPdf(state),
  isDownloadingPdf: CurrentReportSelectors.isDownloadingPdf(state),
  reportFileName: CurrentReportSelectors.getReportPdfFileName(state),
  reportId: CurrentReportSelectors.getCurrentReportId(state),
});

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>): DispatchProps => ({
  downloadPdfReport: bindActionCreators(actions.downloadPdfReport, dispatch),
  generateReportPdf: bindActionCreators(actions.generateReportPdf, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

class DownloadReport extends PureComponent<Props> {
  static defaultProps = {
    reportFileName: undefined,
  };

  render() {
    const {
      downloadPdfReport,
      generateReportPdf,
      isCreatingPdf,
      isPdfCreated,
      isDownloadingPdf,
      reportId,
      reportFileName,
    } = this.props;
    if (isCreatingPdf || !reportFileName) {
      return (
        <div>
          <div className="disabled-action">Processing Report</div>
        </div>
      );
    }
    if (isDownloadingPdf) {
      return (
        <div>
          <div className="disabled-action">Downloading Report</div>
        </div>
      );
    }

    if (isPdfCreated) {
      return (
        <div>
          <button
            className="btn"
            onClick={() => {
              downloadPdfReport(reportFileName);
            }}
            type="button"
          >
            Download Report PDF
          </button>
        </div>
      );
    }

    return (
      <div>
        <button
          className="btn"
          onClick={() => {
            generateReportPdf(reportId);
          }}
          type="button"
        >
          CREATE REPORT PDF
        </button>
      </div>
    );
  }
}

export default connector(DownloadReport);

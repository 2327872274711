import { CSSProperties } from 'react';
import { styles as commonStyles, Styles as CommonStyles } from '../../style';

export type Styles = CommonStyles & {
  buttonHeader: CSSProperties;
};

// eslint-disable-next-line import/prefer-default-export
export const styles: Styles = {
  ...commonStyles,
  buttonHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
};

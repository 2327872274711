import type { ReduxState } from '../../rootReducer';
import type { FeatureFlagsState } from './reducer';
import type { Features } from '../types';

const getFeatureFlagsState = (state: ReduxState): FeatureFlagsState => state.featureFlags;

const getCurrentFeatureFlags = (state: ReduxState): Features => getFeatureFlagsState(state).features;

const hasFeatureFlagsFetchErrored = (state: ReduxState): boolean => getFeatureFlagsState(state).hasErrored;

const getFeatureFlagsFetchErrorMessage = (state: ReduxState): string[] => {
  const error = getFeatureFlagsState(state).message;
  return error ? [error] : [];
};

export default {
  getCurrentFeatureFlags,
  getFeatureFlagsFetchErrorMessage,
  hasFeatureFlagsFetchErrored,
};

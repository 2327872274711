const ABO: 'abo' = 'abo';
const AGE: 'age' = 'age';
const CD34: 'cd34' = 'cd34';
const CMV: 'cmv' = 'cmv';
const DPB1_MATCH_CATEGORIES: 'dpb1MatchCategories' = 'dpb1MatchCategories';
const EXCLUDE_DKMS_UK_DONORS: 'excludeDkmsUkDonors' = 'excludeDkmsUkDonors';
const GENDER: 'gender' = 'gender';
const GRID: 'grid' = 'grid';
const HOME_REGISTRY_ID: 'homeRegistryId' = 'homeRegistryId';
const MISMATCHES: 'mismatches' = 'mismatches';
const ORIGINATING_REGISTRY_ID: 'originatingRegistryId' = 'originatingRegistryId';
const RHD: 'rhd' = 'rhd';
const TNC: 'tnc' = 'tnc';

export const filterNames = {
  abo: ABO,
  age: AGE,
  cd34: CD34,
  cmv: CMV,
  dpb1MatchCategories: DPB1_MATCH_CATEGORIES,
  excludeDkmsUkDonors: EXCLUDE_DKMS_UK_DONORS,
  gender: GENDER,
  grid: GRID,
  homeRegistryId: HOME_REGISTRY_ID,
  mismatches: MISMATCHES,
  originatingRegistryId: ORIGINATING_REGISTRY_ID,
  rhd: RHD,
  tnc: TNC,
};

// eslint-disable-next-line import/no-cycle
import type { ApiRecommendation } from '../../../types/api';
import { Actions } from '../actions';
import type {
  CurrentReportRecommendationsErrorAction,
  CurrentReportRecommendationsFetchAction,
  CurrentReportRecommendationsSuccessAction,
  CurrentReportUpdateEditedRecommendationsAction,
  SaveNewReportSuccessAction,
  SingleSavedReportSuccessAction,
} from '../actions';

type UsersActions =
  | CurrentReportRecommendationsErrorAction
  | CurrentReportRecommendationsFetchAction
  | CurrentReportRecommendationsSuccessAction
  | CurrentReportUpdateEditedRecommendationsAction
  | SaveNewReportSuccessAction
  | SingleSavedReportSuccessAction;

export type RecommendationsReducerState = {
  hasErrored: boolean;
  isFetching: boolean;
  // TODO-Flow: Move to entities reducer
  list: Record<string, ApiRecommendation>;
  message?: string;
};

const initialState: RecommendationsReducerState = {
  list: {},
  hasErrored: false,
  isFetching: false,
};

const recommendations = (
  state: RecommendationsReducerState = initialState,
  action: UsersActions
): RecommendationsReducerState => {
  switch (action.type) {
    case Actions.CURRENT_REPORT_RECOMMENDATIONS_FETCH:
      return {
        ...state,
        isFetching: true,
        hasErrored: false,
      };
    case Actions.CURRENT_REPORT_RECOMMENDATIONS_SUCCESS:
      return {
        ...state,
        list: action.payload.data.entities.Recommendations,
        isFetching: false,
        hasErrored: false,
      };
    case Actions.CURRENT_REPORT_RECOMMENDATIONS_ERROR:
      return {
        ...state,
        hasErrored: true,
        message: `Error while fetching Recommendations - ${action.payload.response.status}`,
        isFetching: false,
      };
    case Actions.CURRENT_REPORT_UPDATE_EDITED_RECOMMENDATIONS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.payload.id]: {
            ...state.list[action.payload.id],
            RecommendationDescription: action.payload.editedRecommendation,
          },
        },
      };
    case Actions.SINGLE_SAVED_REPORT_SUCCESS:
      return {
        ...state,
        list: action.payload.data.entities.Recommendations,
      };
    default:
      return state;
  }
};

export default recommendations;

/* eslint-disable max-classes-per-file */
import React from 'react';
import HlaCell from './Cells/HlaCell';
import ArrayCell from './Cells/ArrayCell';
import ValueCell from './Cells/ValueCell';
import { MatchCountCell } from './Cells/MatchCountCell';
import { PopUpWrapper } from '../../../core';
import SymbolKeyPopUp from './SymbolKeyPopUp/SymbolKeyPopUp';
import SelectAllItemsCell from './Cells/SelectAllItemsCell/SelectAlItemsCell';
import { Antigen, DonorType, LocusMatchCategory, VerificationStatus } from '../../../core/types';

export type RenderCellParams<T> = {
  algorithm: string;
  data: T;
  donorType: DonorType;
  headerRowIndex: number;
  isRowSelected: boolean;
  mouseLeftHla: () => void;
  mouseMovedOverHla: () => void;
  resultSetId: string;
  rowIndex: number;
};

const renderArrayCell = (params: RenderCellParams<string[]>) => <ArrayCell data={params.data} />;

const renderNumberCell = (params: RenderCellParams<number>) => <ValueCell data={params.data} />;

const renderStringCell = (params: RenderCellParams<string>) => <ValueCell data={params.data} />;

const selectColumnRender = (params: RenderCellParams<unknown>) => (
  <label htmlFor="selectDonor">
    <input type="checkbox" name="selectDonor" checked={params.isRowSelected} readOnly />
  </label>
);

const matchSymbolsCellRender = (params: RenderCellParams<string[]>) => {
  const symbol = params.data.join();
  return (
    <PopUpWrapper
      name={symbol}
      buttonStyle={{ width: '100%' }}
      placement="right"
      popUpStyle={{ border: '2px solid rgb(137, 156, 28)', width: '180px' }}
    >
      <SymbolKeyPopUp matchGradeSymbol={symbol} />
    </PopUpWrapper>
  );
};

export type DonorIdVerificationStatus = {
  donorId: string[];
  verificationStatus?: VerificationStatus | void;
};

const donorIdCellRender = (params: RenderCellParams<DonorIdVerificationStatus>) => {
  const { donorId, verificationStatus } = params.data;
  return verificationStatus && !verificationStatus.isResultVerified ? (
    <PopUpWrapper
      name={<ArrayCell data={donorId} />}
      buttonStyle={{ width: '100%' }}
      placement="right"
      popUpStyle={{ border: '2px solid rgb(137, 156, 28)' }}
    >
      {verificationStatus && verificationStatus.reasonResultIsNotVerified}
    </PopUpWrapper>
  ) : (
    <ArrayCell data={donorId} />
  );
};

type CellRenderer<T> = (params: RenderCellParams<T>) => unknown;

export class ColumnType<T> {
  renderCell: CellRenderer<T>;

  renderHeader: CellRenderer<T>;

  constructor(renderCell: CellRenderer<T>, renderHeader: CellRenderer<T> = (params) => params.data) {
    this.renderCell = renderCell;
    this.renderHeader = renderHeader;
  }

  render = (params: RenderCellParams<T>) =>
    params.headerRowIndex === params.rowIndex ? this.renderHeader(params) : this.renderCell(params);
}

type HlaCellParams = {
  antigens: Antigen[];
  locusMatchCategory?: LocusMatchCategory | null | undefined;
};

type MatchCountParams = {
  matchCountDenominator: number | null | undefined;
  matchCount: number;
};

export const columnTypes = {
  // eslint-disable-next-line
  hla: new ColumnType<HlaCellParams>((params: RenderCellParams<HlaCellParams>) => (
    <HlaCell
      mouseOverHla={params.mouseMovedOverHla}
      mouseLeftHla={params.mouseLeftHla}
      hlaData={params.data.antigens}
      locusMatchCategory={params.data.locusMatchCategory}
      algorithm={params.algorithm}
      donorType={params.donorType}
    />
  )),
  selectColumn: new ColumnType<unknown>(selectColumnRender, (params: RenderCellParams<unknown>) => (
    <SelectAllItemsCell resultSetId={params.resultSetId} donorType={params.donorType} />
  )),
  matchGrade: new ColumnType<string[]>(matchSymbolsCellRender),
  matchCount: new ColumnType<MatchCountParams>((params: RenderCellParams<MatchCountParams>) => (
    <MatchCountCell matchCount={params.data.matchCount} matchCountDenominator={params.data.matchCountDenominator} />
  )),
  donorId: new ColumnType<DonorIdVerificationStatus>(donorIdCellRender),
  rowNumber: new ColumnType<void>((params: RenderCellParams<void>) => params.rowIndex),
  array: new ColumnType<string[]>(renderArrayCell),
  number: new ColumnType<number>(renderNumberCell),
  string: new ColumnType<string>(renderStringCell),
};

export class Column<SR, D> {
  name: string;

  header: string;

  getCellContent: (arg0: SR) => D;

  columnType: ColumnType<D>;

  width: number;

  hideable: boolean | null | undefined;

  constructor(
    name: string,
    header: string,
    getCellContent: (arg0: SR) => D,
    columnType: ColumnType<D>,
    width: number,
    hideable?: boolean
  ) {
    this.name = name;
    this.header = header;
    this.getCellContent = getCellContent;
    this.columnType = columnType;
    this.width = width;
    this.hideable = hideable;
  }
}
export class HlaColumn<SR> extends Column<SR, HlaCellParams> {
  constructor(name: string, header: string, getCellContent: (arg0: SR) => HlaCellParams, width = 125) {
    super(name, header, getCellContent, columnTypes.hla, width, true);
  }
}
export class NumberColumn<SR> extends Column<SR, number> {
  constructor(name: string, header: string, getCellContent: (arg0: SR) => number, width: number, hideable?: boolean) {
    super(name, header, getCellContent, columnTypes.number, width, hideable);
  }
}
export class StringColumn<SR> extends Column<SR, string> {
  constructor(name: string, header: string, getCellContent: (arg0: SR) => string, width: number, hideable?: boolean) {
    super(name, header, getCellContent, columnTypes.string, width, hideable);
  }
}
export class MatchCountColumn<SR> extends Column<SR, MatchCountParams> {
  constructor(
    name: string,
    header: string,
    getCellContent: (arg0: SR) => MatchCountParams,
    width: number,
    hideable?: boolean
  ) {
    super(name, header, getCellContent, columnTypes.matchCount, width, hideable);
  }
}

function getResultsRow<T>(result: T, columns: Column<T, any>[]): unknown[] {
  return columns.map((c: any) => c.getCellContent(result));
}

export function createDonorRows<T>(searchResults: T[], columns: Column<T, any>[]): any[][] {
  const headers: string[] = columns.map((c) => c.header);
  const rowResults: any[] = searchResults ? searchResults.map((result) => getResultsRow<T>(result, columns)) : [];
  const rows: any[][] = [headers].concat(rowResults);
  return rows;
}

export const convertCmvOrRhd = (value: string) => {
  if (!value) {
    return '';
  }

  switch (value.toUpperCase()) {
    case 'POSITIVE':
      return 'POS';
    case 'NEGATIVE':
      return 'NEG';
    case 'EQUIVOCAL':
      return 'EQ';
    default:
      return value;
  }
};

export const convertAbo = (abo: string) => (abo === 'Unknown' ? '' : abo);

export type TableContents<T> = {
  columns: Column<T, any>[];
  createRows: (arg0: T[], arg1: Column<T, unknown>[]) => Array<Array<unknown>>;
};

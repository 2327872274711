// eslint-disable-next-line import/no-cycle
import { Actions, ReportDashboardAction } from './actions';
import type { ReportIdsByStatus } from '../../types';

export type ReportDashbordReducerState = {
  reportIdsByStatus: ReportIdsByStatus;
  selectedUser?: string;
};

const initialState: ReportDashbordReducerState = {
  reportIdsByStatus: {
    draft: [],
    awaitingAuthorisation: [],
    authorised: [],
    sent: [],
  },
};

export default (
  state: ReportDashbordReducerState = initialState,
  action: ReportDashboardAction
): ReportDashbordReducerState => {
  if (action.type === Actions.REPORT_DASHBOARD_REPORT_STATUS_IDS_UPDATE) {
    return {
      ...state,
      reportIdsByStatus: action.payload,
    };
  }
  if (action.type === Actions.REPORT_DASHBOARD_SELECTED_USER_UPDATE) {
    return {
      ...state,
      selectedUser: action.payload,
    };
  }
  return state;
};

import React from 'react';
import type { ApiErrorType } from '../../types';

type Props = {
  error: ApiErrorType | string | undefined;
};

const ApiError = ({ error }: Props) => {
  const messages: string[] = [];
  if (typeof error === 'string') {
    messages.push(error);
  } else if (error && error.Error) {
    messages.push(error.Error);
  } else if (error && error.GlobalErrors && error.GlobalErrors.length > 0) {
    messages.push(error.GlobalErrors[0]);
  } else if (error && error.FieldErrors) {
    Object.keys(error.FieldErrors).forEach((field) => {
      if (error.FieldErrors && error.FieldErrors[field]) {
        messages.push(error.FieldErrors[field].Errors[0]);
      }
    });
  } else {
    messages.push('Unknown error format, please contact IT');
  }

  return (
    <>
      {messages.map((message) => (
        <div data-testid="error" key={message} className="error-message" style={{ textAlign: 'center' }}>
          {message}
        </div>
      ))}
    </>
  );
};

export default ApiError;

import type { ReduxState } from '../../rootReducer';
import type { ExternalInvestigation } from '../types';

const getExternalInvestigationInfo = (state: ReduxState) => state.externalInvestigations;

const isFetchingExternalInvestigations = (state: ReduxState): boolean => getExternalInvestigationInfo(state).isFetching;

const hasFetchExternalInvestigationsErrored = (state: ReduxState): boolean =>
  getExternalInvestigationInfo(state).hasErrored;

const getExternalInvestigationsErrorMessage = (state: ReduxState): string[] => {
  const error = getExternalInvestigationInfo(state).errorMessage;
  return error ? [error] : [];
};

const getIsCancellingExternalInvestigations = (state: ReduxState): boolean =>
  getExternalInvestigationInfo(state).isCancellingExternalInvestigations;

const hasCancelExternalInvestigationsErrored = (state: ReduxState): boolean =>
  getExternalInvestigationInfo(state).cancelExternalInvestigationsHasErrored;

const getCancelExternalInvestigationsError = (state: ReduxState): string | null | undefined =>
  getExternalInvestigationInfo(state).cancelExternalInvestigationsError;

const getExternalInvestigations = (state: ReduxState): ExternalInvestigation[] => {
  const externalInvestigationsState = getExternalInvestigationInfo(state);
  if (externalInvestigationsState && externalInvestigationsState.requests) {
    return externalInvestigationsState.requests;
  }
  return [];
};

export default {
  getExternalInvestigationsErrorMessage,
  hasFetchExternalInvestigationsErrored,
  isFetchingExternalInvestigations,
  getExternalInvestigations,
  getIsCancellingExternalInvestigations,
  hasCancelExternalInvestigationsErrored,
  getCancelExternalInvestigationsError,
};

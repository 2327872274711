import React from 'react';

const NotFoundPage = () => (
  <div className="col span_12_of_12">
    <div style={{ width: '25%', textAlign: 'center', margin: 'auto' }}>
      <h1>404 - Page Not Found!</h1>
      <h3>Oops! The page you are looking for doesn&apos;t exist</h3>
    </div>
  </div>
);

export default NotFoundPage;

import React from 'react';

type Props = {
  donorId: string;
  algorithm1Rank: number;
  algorithm2Rank: number;
  rankDifference: number;
};

export default ({ donorId, algorithm1Rank, algorithm2Rank, rankDifference }: Props) => (
  <tr>
    <td>{donorId}</td>
    <td>{algorithm1Rank}</td>
    <td>{algorithm2Rank}</td>
    <td>{rankDifference}</td>
  </tr>
);

const EXTERNAL_INVESTIGATIONS_CREATE_IDM_REQUEST: 'externalInvestigations/idmRequestInitiation/create' =
  'externalInvestigations/idmRequestInitiation/create';
const EXTERNAL_INVESTIGATIONS_CREATE_IDM_REQUEST_ERROR: 'externalInvestigations/idmRequestInitiation/create/error' =
  'externalInvestigations/idmRequestInitiation/create/error';
const EXTERNAL_INVESTIGATIONS_CREATE_IDM_REQUEST_REQUEST: 'externalInvestigations/idmRequestInitiation/create/request' =
  'externalInvestigations/idmRequestInitiation/create/request';
const EXTERNAL_INVESTIGATIONS_CREATE_IDM_REQUEST_SUCCESS: 'externalInvestigations/idmRequestInitiation/create/success' =
  'externalInvestigations/idmRequestInitiation/create/success';
const EXTERNAL_INVESTIGATIONS_CREATE_EXTENDED_TYPING_REQUEST: 'externalInvestigations/extendedTypingRequestInitiation/create' =
  'externalInvestigations/extendedTypingRequestInitiation/create';
const EXTERNAL_INVESTIGATIONS_CREATE_EXTENDED_TYPING_REQUEST_ERROR: 'externalInvestigations/extendedTypingRequestInitiation/create/error' =
  'externalInvestigations/extendedTypingRequestInitiation/create/error';
const EXTERNAL_INVESTIGATIONS_CREATE_EXTENDED_TYPING_REQUEST_REQUEST: 'externalInvestigations/extendedTypingRequestInitiation/create/request' =
  'externalInvestigations/extendedTypingRequestInitiation/create/request';
const EXTERNAL_INVESTIGATIONS_CREATE_EXTENDED_TYPING_REQUEST_SUCCESS: 'externalInvestigations/extendedTypingRequestInitiation/create/success' =
  'externalInvestigations/extendedTypingRequestInitiation/create/success';
const EXTERNAL_INVESTIGATIONS_CREATE_VT_REQUEST: 'externalInvestigations/verificationTypingRequestInitiation/create' =
  'externalInvestigations/verificationTypingRequestInitiation/create';
const EXTERNAL_INVESTIGATIONS_CREATE_VT_REQUEST_ERROR: 'externalInvestigations/verificationTypingRequestInitiation/create/error' =
  'externalInvestigations/verificationTypingRequestInitiation/create/error';
const EXTERNAL_INVESTIGATIONS_CREATE_VT_REQUEST_REQUEST: 'externalInvestigations/verificationTypingRequestInitiation/create/request' =
  'externalInvestigations/verificationTypingRequestInitiation/create/request';
const EXTERNAL_INVESTIGATIONS_CREATE_VT_REQUEST_SUCCESS: 'externalInvestigations/verificationTypingRequestInitiation/create/success' =
  'externalInvestigations/verificationTypingRequestInitiation/create/success';

const FETCH_BRITISH_LABORATORIES: 'institutions/laboratories/british' = 'institutions/laboratories/british';
const FETCH_BRITISH_LABORATORIES_ERROR: 'institutions/laboratories/british/error' =
  'institutions/laboratories/british/error';
const FETCH_BRITISH_LABORATORIES_REQUEST: 'institutions/laboratories/british/request' =
  'institutions/laboratories/british/request';
const FETCH_BRITISH_LABORATORIES_SUCCESS: 'institutions/laboratories/british/success' =
  'institutions/laboratories/british/success';

export const Actions = {
  EXTERNAL_INVESTIGATIONS_CREATE_IDM_REQUEST,
  EXTERNAL_INVESTIGATIONS_CREATE_IDM_REQUEST_ERROR,
  EXTERNAL_INVESTIGATIONS_CREATE_IDM_REQUEST_REQUEST,
  EXTERNAL_INVESTIGATIONS_CREATE_IDM_REQUEST_SUCCESS,
  EXTERNAL_INVESTIGATIONS_CREATE_EXTENDED_TYPING_REQUEST,
  EXTERNAL_INVESTIGATIONS_CREATE_EXTENDED_TYPING_REQUEST_ERROR,
  EXTERNAL_INVESTIGATIONS_CREATE_EXTENDED_TYPING_REQUEST_REQUEST,
  EXTERNAL_INVESTIGATIONS_CREATE_EXTENDED_TYPING_REQUEST_SUCCESS,
  EXTERNAL_INVESTIGATIONS_CREATE_VT_REQUEST,
  EXTERNAL_INVESTIGATIONS_CREATE_VT_REQUEST_ERROR,
  EXTERNAL_INVESTIGATIONS_CREATE_VT_REQUEST_REQUEST,
  EXTERNAL_INVESTIGATIONS_CREATE_VT_REQUEST_SUCCESS,
  FETCH_BRITISH_LABORATORIES,
  FETCH_BRITISH_LABORATORIES_ERROR,
  FETCH_BRITISH_LABORATORIES_REQUEST,
  FETCH_BRITISH_LABORATORIES_SUCCESS,
};

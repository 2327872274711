import type { ReduxState } from '../../rootReducer';
import { RelationshipType } from '../types';

const getRelationshipTypes = (state: ReduxState): RelationshipType[] => state.subject.relationshipTypes;
const isLoadingRelationshipTypes = (state: ReduxState): boolean => state.subject.loadingRelationshipTypes;

const getLinks = (state: ReduxState) => state.subject.links;
const isLoadingLinks = (state: ReduxState): boolean => state.subject.loadingLinks;

const isUpdatingLink = (state: ReduxState): boolean => state.subject.updatingLink;
const isDeletingLink = (state: ReduxState): boolean => state.subject.deletingLink;

const getSubject = (state: ReduxState) => state.subject.subject;
const isLoadingSubject = (state: ReduxState): boolean => state.subject.loadingSubject;
const loadingSubjectError = (state: ReduxState) => state.subject.loadingSubjectError;

const getLoadingInvestigationsError = (state: ReduxState) => state.subject.loadingInvestigationsError;
const getSubjectInvestigations = (state: ReduxState) => state?.subject?.investigations;
const isLoadingInvestigations = (state: ReduxState) => state.subject.loadingInvestigations;

const getOriginalSample = (state: ReduxState) => state.subject.originalSample;
const isLoadingOriginalSample = (state: ReduxState) => state.subject.loadingOriginalSample;

const getTestCategories = (state: ReduxState) => state?.subject.testCategories || [];
const isLoadingTestCategories = (state: ReduxState) => state.subject.loadingTestCategories;
const getTestCategoriesError = (state: ReduxState) => state.subject.loadingTestCategoriesError;

const isCreatingInvestigation = (state: ReduxState) => state.subject.creatingSampleInvestigation;
const getInvestigationError = (state: ReduxState) => state.subject.creatingInvestigationError;

const getSubjectConstants = (state: ReduxState) => state.subject.constants;
const getInstitutionAddresses = (state: ReduxState) => state.subject.institutionAddresses;

export default {
  getRelationshipTypes,
  isLoadingRelationshipTypes,
  getLinks,
  isLoadingLinks,
  isUpdatingLink,
  isDeletingLink,
  getSubject,
  isLoadingSubject,
  loadingSubjectError,
  getLoadingInvestigationsError,
  getSubjectInvestigations,
  isLoadingInvestigations,
  getOriginalSample,
  isLoadingOriginalSample,
  getTestCategories,
  isLoadingTestCategories,
  getTestCategoriesError,
  isCreatingInvestigation,
  getInvestigationError,
  getSubjectConstants,
  getInstitutionAddresses,
};

import React, { PureComponent } from 'react';
import { AnyAction, bindActionCreators, Dispatch as ReduxDispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import queryString from 'query-string';
import * as actions from '../../redux/actions';
import { ErrorMessage } from '../../../core';
import Selectors from '../../redux/selectors';
import AlgorithmComparator from './AlgorithmComparator';
import { minimumNumberOfAlgorithms } from './AlgorithmComparatorValidation';
import type { DonorType, RouterMatch } from '../../../core/types';
import type { ResultSetSummary } from '../../../donorMatchSearchRequests/types';
import type { ReduxState } from '../../../rootReducer';

type QueryParams = {
  donorType: DonorType;
  resultSetId: string;
};
type OwnProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  location: string;
  // used in `mapStateToProps`
  match: RouterMatch;
};
type StateProps = {
  algorithmsToCompare: string[];
  errorMessages: (string | undefined)[];
  hasErrored: boolean;
  queryParams: QueryParams;
  resultSetSummaries: ResultSetSummary[];
};
type Props = PropsFromRedux & StateProps & OwnProps;

const mapStateToProps = (state: ReduxState, ownProps: OwnProps): StateProps => {
  const queryParams: QueryParams = queryString.parse(ownProps.location.search);
  return {
    algorithmsToCompare: Selectors.getRequestAlgorithmsUsed(state),
    errorMessages: Selectors.getErrorMessages(state, queryParams.resultSetId),
    hasErrored: Selectors.getHasErrored(state, queryParams.resultSetId),
    queryParams,
    resultSetSummaries: Selectors.getRequestResultSetSummaries(state),
  };
};

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>) => ({
  getRequestAndPatientData: bindActionCreators(actions.getRequestAndPatientData, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export class AlgorithmComparisonPage extends PureComponent<Props> {
  componentDidMount() {
    const { match, getRequestAndPatientData } = this.props;
    getRequestAndPatientData(match.params.id);
  }

  render() {
    const { hasErrored } = this.props;

    return <div>{hasErrored ? this.errorMessages() : this.mainBody()}</div>;
  }

  errorMessages() {
    const { errorMessages } = this.props;
    return (
      <div>
        <ErrorMessage errorMessages={errorMessages} />
      </div>
    );
  }

  mainBody() {
    const { algorithmsToCompare } = this.props;
    return algorithmsToCompare ? (
      this.algorithmComparator()
    ) : (
      <p id="loading-data-message">Loading search request data...</p>
    );
  }

  algorithmComparator() {
    const { algorithmsToCompare, queryParams, resultSetSummaries } = this.props;
    return minimumNumberOfAlgorithms(algorithmsToCompare) ? (
      <AlgorithmComparator
        algorithmsToCompare={algorithmsToCompare}
        donorType={queryParams.donorType}
        resultSetSummaries={resultSetSummaries}
      />
    ) : (
      <p id="comparison-not-possible-message" style={{ fontSize: 30, color: 'red' }}>
        Comparison cannot be made as this search request was not run with multiple algorithms.
      </p>
    );
  }
}

export default connector(AlgorithmComparisonPage);

import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch as ReduxDispatch } from 'redux';
import { updateSelectedNotes } from '../../redux/actions';
import SearchResultsSelectors from '../../redux/selectors';
import EditableResultDonorsNotes from './EditableResultDonorsNotes';
import DonorSelectionDescription from './DonorSelectionDescription';
import CordSelectionDescription from './CordSelectionDescription';
import type { AdultSearchResult, SearchResults } from '../../../core/types';
import donorTypes from '../../../core/constants/donorTypes';
import type { ReduxState } from '../../../rootReducer';

type OwnProps = {
  resultSetId: string;
};
type StateProps = {
  isRequestClosed: boolean;
  notes: string;
  searchResults?: SearchResults;
};
type Props = PropsFromRedux & OwnProps & StateProps;

const mapStateToProps = (state: ReduxState, { resultSetId }: OwnProps): StateProps => ({
  searchResults: SearchResultsSelectors.getSearchResults(state, resultSetId),
  isRequestClosed: SearchResultsSelectors.isSearchRequestClosed(state),
  notes: SearchResultsSelectors.getSelectedSavedNotes(state),
});

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>) => ({
  updateSelectedDonorNotes: bindActionCreators(updateSelectedNotes, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class DonorSelectionDetails extends PureComponent<Props> {
  static defaultProps = {
    searchResults: undefined,
  };

  render() {
    const { isRequestClosed, notes, resultSetId, searchResults } = this.props;

    let selectionDescription;
    if (!searchResults) {
      selectionDescription = null;
    } else if (searchResults.type === donorTypes.cord.value) {
      selectionDescription = <CordSelectionDescription resultSetId={resultSetId} />;
    } else {
      selectionDescription = (
        <DonorSelectionDescription
          resultSetId={resultSetId}
          donors={searchResults.results.map((result) => {
            const adultSearchResult = result as AdultSearchResult;
            return adultSearchResult.donor;
          })}
        />
      );
    }
    return (
      <>
        {selectionDescription}
        <EditableResultDonorsNotes notes={notes} notesEdit={this.handleEditNotes} isReadOnly={isRequestClosed} />
      </>
    );
  }

  handleEditNotes = (notes: string) => {
    const { updateSelectedDonorNotes } = this.props;
    updateSelectedDonorNotes(notes);
  };
}

export default connector(DonorSelectionDetails);

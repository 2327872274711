import React from 'react';
import { getValueOrDefault } from '../../../../../../core/helpers/stringValueFormatter';
import Drb345DisplayContainer from '../../../../../core/components/Drb345DisplayContainer';
import HlaPairDisplay from '../../../../../core/components/HlaPairDisplay';
import { VerificationTypingResult } from '../../../../types';

type Props = {
  result: VerificationTypingResult;
};

export const DonorOriginalHLATable = ({ result }: Props) => (
  <div>
    <table className="table">
      <thead className="table-header">
        <tr>
          <th>A</th>
          <th>B</th>
          <th>C</th>
          <th>DRB1</th>
          <th>DRB3/4/5</th>
          <th>DQA1</th>
          <th>DQB1</th>
          <th>DPA1</th>
          <th>DPB1</th>
          <th>
            ABO
            <br />
            RhD
          </th>
          <th>CMV</th>
        </tr>
      </thead>
      <tbody>
        <tr className="centered-table-data">
          <td id="hlaA">
            <HlaPairDisplay locus1={result.originalHla.hlaA1} locus2={result.originalHla.hlaA2} />
          </td>
          <td id="hlaB">
            <HlaPairDisplay locus1={result.originalHla.hlaB1} locus2={result.originalHla.hlaB2} />
          </td>
          <td id="hlaC">
            <HlaPairDisplay locus1={result.originalHla.hlaC1} locus2={result.originalHla.hlaC2} />
          </td>
          <td id="hlaDrb1">
            <HlaPairDisplay locus1={result.originalHla.drB11} locus2={result.originalHla.drB12} />
          </td>
          <td id="hlaDrb3/4/5">
            <Drb345DisplayContainer
              drB31={result.originalHla.drB31}
              drB32={result.originalHla.drB32}
              drB41={result.originalHla.drB41}
              drB42={result.originalHla.drB42}
              drB51={result.originalHla.drB51}
              drB52={result.originalHla.drB52}
            />
          </td>
          <td id="hlaDqa1">
            <HlaPairDisplay locus1={result.originalHla.dqA11} locus2={result.originalHla.dqA12} />
          </td>
          <td id="hlaDqb1">
            <HlaPairDisplay locus1={result.originalHla.dqB11} locus2={result.originalHla.dqB12} />
          </td>
          <td id="hlaDpa1">
            <HlaPairDisplay locus1={result.originalHla.dpA11} locus2={result.originalHla.dpA12} />
          </td>
          <td id="hlaDpb1">
            <HlaPairDisplay locus1={result.originalHla.dpB11} locus2={result.originalHla.dpB12} />
          </td>
          <td id="bloodTypeAndRhesus">
            {getValueOrDefault(result.abo, 'Unknown')}
            <br />
            {getValueOrDefault(result.rhd, 'Unknown')}
          </td>
          <td id="cmvType">{getValueOrDefault(result.cmv, 'Unknown')}</td>
        </tr>
      </tbody>
    </table>
  </div>
);

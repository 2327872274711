import React from 'react';
import { styles } from '../../style';

type Props = { resultType: string };

export default ({ resultType }: Props) => (
  <div
    style={{
      ...styles.cardLayout,
      marginLeft: '10px',
      marginRight: '10px',
    }}
  >
    <h3 style={{ textAlign: 'center' }}>No {resultType} found for this patient</h3>
  </div>
);

import _ from 'lodash';
import {
  // @ts-expect-error - not picking up apiDelete
  apiDelete,
  ErrorAction as ApiErrorAction,
  get,
  post,
  put,
  RequestAction as ApiRequestAction,
  SuccessAction as ApiSuccessAction,
} from '@an/nova-frontend-rest-client';
import { LOCATION_CHANGE, RouterState } from 'connected-react-router';
import moment from 'moment';
import Config from '../../config';
import type { AdultDonor, Dispatch, ApiListResponse, InstitutionAddress } from '../../core/types';
import { addNotification } from '../../notifications/addNotification';
import type { ReduxState } from '../../rootReducer';
import { subjectTypes } from '../constants/subjectTypes';
import type {
  OriginalSample,
  Patient,
  RelationshipType,
  SubjectConstants,
  SubjectInvestigation,
  SubjectLink,
  SubjectLinkExpanded,
  SubjectType,
  TestCategory,
} from '../types';

const GET_RELATIONSHIP_TYPES: 'subject/getRelationshipTypes' = 'subject/getRelationshipTypes';
const GET_RELATIONSHIP_TYPES_REQUEST: 'subject/getRelationshipTypes/request' = 'subject/getRelationshipTypes/request';
const GET_RELATIONSHIP_TYPES_SUCCESS: 'subject/getRelationshipTypes/success' = 'subject/getRelationshipTypes/success';
const GET_RELATIONSHIP_TYPES_ERROR: 'subject/getRelationshipTypes/error' = 'subject/getRelationshipTypes/error';
const GET_LINKS: 'subject/getLinks' = 'subject/getLinks';
const GET_LINKS_REQUEST: 'subject/getLinks/request' = 'subject/getLinks/request';
const GET_LINKS_SUCCESS: 'subject/getLinks/success' = 'subject/getLinks/success';
const GET_LINKS_ERROR: 'subject/getLinks/error' = 'subject/getLinks/error';
const UPDATE_LINK: 'subject/updateLink' = 'subject/updateLink';
const UPDATE_LINK_REQUEST: 'subject/updateLink/request' = 'subject/updateLink/request';
const UPDATE_LINK_SUCCESS: 'subject/updateLink/success' = 'subject/updateLink/success';
const UPDATE_LINK_ERROR: 'subject/updateLink/error' = 'subject/updateLink/error';
const DELETE_LINK: 'subject/deleteLink' = 'subject/deleteLink';
const DELETE_LINK_REQUEST: 'subject/deleteLink/request' = 'subject/deleteLink/request';
const DELETE_LINK_SUCCESS: 'subject/deleteLink/success' = 'subject/deleteLink/success';
const DELETE_LINK_ERROR: 'subject/deleteLink/error' = 'subject/deleteLink/error';
const GET_SUBJECT: 'subject/getSubject' = 'subject/getSubject';
const GET_SUBJECT_REQUEST: 'subject/getSubject/request' = 'subject/getSubject/request';
const GET_SUBJECT_SUCCESS: 'subject/getSubject/success' = 'subject/getSubject/success';
const GET_SUBJECT_ERROR: 'subject/getSubject/error' = 'subject/getSubject/error';
const CLEAR_SUBJECT: 'subject/clearSubject' = 'subject/clearSubject';

const GET_INVESTIGATIONS: 'subject/getInvestigations' = 'subject/getInvestigations';
const GET_INVESTIGATIONS_REQUEST: 'subject/getInvestigations/request' = 'subject/getInvestigations/request';
const GET_INVESTIGATIONS_SUCCESS: 'subject/getInvestigations/success' = 'subject/getInvestigations/success';
const GET_INVESTIGATIONS_ERROR: 'subject/getInvestigations/error' = 'subject/getInvestigations/error';

const GET_INVESTIGATION_ORIGINAL_SAMPLE: 'subject/getInvestigationOriginalSample' =
  'subject/getInvestigationOriginalSample';
const GET_INVESTIGATION_ORIGINAL_SAMPLE_REQUEST: 'subject/getInvestigationOriginalSample/request' =
  'subject/getInvestigationOriginalSample/request';
const GET_INVESTIGATION_ORIGINAL_SAMPLE_SUCCESS: 'subject/getInvestigationOriginalSample/success' =
  'subject/getInvestigationOriginalSample/success';
const GET_INVESTIGATION_ORIGINAL_SAMPLE_ERROR: 'subject/getInvestigationOriginalSample/error' =
  'subject/getInvestigationOriginalSample/error';

const CREATE_SAMPLE_INVESTIGATION: 'subject/createInvestigation' = 'subject/createInvestigation';
const CREATE_SAMPLE_INVESTIGATION_REQUEST: 'subject/createInvestigation/request' =
  'subject/createInvestigation/request';
const CREATE_SAMPLE_INVESTIGATION_SUCCESS: 'subject/createInvestigation/success' =
  'subject/createInvestigation/success';
const CREATE_SAMPLE_INVESTIGATION_ERROR: 'subject/createInvestigation/error' = 'subject/createInvestigation/error';

const CREATE_ORIGINAL_SAMPLE: 'subject/createSample' = 'subject/createSample';
const CREATE_ORIGINAL_SAMPLE_REQUEST: 'subject/createSample/request' = 'subject/createSample/request';
const CREATE_ORIGINAL_SAMPLE_SUCCESS: 'subject/createSample/success' = 'subject/createSample/success';
const CREATE_ORIGINAL_SAMPLE_ERROR: 'subject/createSample/error' = 'subject/createSample/error';

const GET_TEST_CATEGORIES: 'subject/getTestCategories' = 'subject/getTestCategories';
const GET_TEST_CATEGORIES_REQUEST: 'subject/getTestCategories/request' = 'subject/getTestCategories/request';
const GET_TEST_CATEGORIES_SUCCESS: 'subject/getTestCategories/success' = 'subject/getTestCategories/success';
const GET_TEST_CATEGORIES_ERROR: 'subject/getTestCategories/error' = 'subject/getTestCategories/error';

const GET_SUBJECT_CONSTANTS: 'subject/getConstants' = 'subject/getConstants';
const GET_SUBJECT_CONSTANTS_REQUEST: 'subject/getConstants/request' = 'subject/getConstants/request';
const GET_SUBJECT_CONSTANTS_SUCCESS: 'subject/getConstants/success' = 'subject/getConstants/success';
const GET_SUBJECT_CONSTANTS_ERROR: 'subject/getConstants/error' = 'subject/getConstants/error';

const GET_INSTITUTION_ADDRESSES: 'subject/getAddresses' = 'subject/getAddresses';
const GET_INSTITUTION_ADDRESSES_REQUEST: 'subject/getAddresses/request' = 'subject/getAddresses/request';
const GET_INSTITUTION_ADDRESSES_SUCCESS: 'subject/getAddresses/success' = 'subject/getAddresses/success';
const GET_INSTITUTION_ADDRESSES_ERROR: 'subject/getAddresses/error' = 'subject/getAddresses/error';

export const Actions = {
  GET_RELATIONSHIP_TYPES_REQUEST,
  GET_RELATIONSHIP_TYPES_SUCCESS,
  GET_RELATIONSHIP_TYPES_ERROR,
  GET_LINKS_REQUEST,
  GET_LINKS_SUCCESS,
  GET_LINKS_ERROR,
  UPDATE_LINK_REQUEST,
  UPDATE_LINK_SUCCESS,
  UPDATE_LINK_ERROR,
  DELETE_LINK_REQUEST,
  DELETE_LINK_SUCCESS,
  DELETE_LINK_ERROR,
  GET_SUBJECT_REQUEST,
  GET_SUBJECT_SUCCESS,
  GET_SUBJECT_ERROR,
  CLEAR_SUBJECT,
  GET_INVESTIGATIONS_REQUEST,
  GET_INVESTIGATIONS_SUCCESS,
  GET_INVESTIGATIONS_ERROR,
  GET_INVESTIGATION_ORIGINAL_SAMPLE_REQUEST,
  GET_INVESTIGATION_ORIGINAL_SAMPLE_SUCCESS,
  GET_INVESTIGATION_ORIGINAL_SAMPLE_ERROR,
  CREATE_SAMPLE_INVESTIGATION_REQUEST,
  CREATE_SAMPLE_INVESTIGATION_SUCCESS,
  CREATE_SAMPLE_INVESTIGATION_ERROR,
  CREATE_ORIGINAL_SAMPLE_REQUEST,
  CREATE_ORIGINAL_SAMPLE_SUCCESS,
  CREATE_ORIGINAL_SAMPLE_ERROR,
  GET_TEST_CATEGORIES_REQUEST,
  GET_TEST_CATEGORIES_SUCCESS,
  GET_TEST_CATEGORIES_ERROR,
  GET_SUBJECT_CONSTANTS_REQUEST,
  GET_SUBJECT_CONSTANTS_SUCCESS,
  GET_SUBJECT_CONSTANTS_ERROR,
  GET_INSTITUTION_ADDRESSES_REQUEST,
  GET_INSTITUTION_ADDRESSES_SUCCESS,
  GET_INSTITUTION_ADDRESSES_ERROR,
};

type SuccessMessageParam = { successMessage: string };
type ErrorMessageParam = { errorMessage: string };
type SubjectIdParam = { subjectId: string };

export type GetRelationShipTypesRequestAction = ApiRequestAction<
  typeof GET_RELATIONSHIP_TYPES_REQUEST,
  Record<string, unknown>
>;
export type GetRelationShipTypesSuccessAction = ApiSuccessAction<
  typeof GET_RELATIONSHIP_TYPES_SUCCESS,
  Record<string, unknown>,
  RelationshipType[]
>;
export type GetRelationShipTypesErrorAction = ApiErrorAction<
  typeof GET_RELATIONSHIP_TYPES_ERROR,
  Record<string, unknown>
>;

export type GetLinksRequestAction = ApiRequestAction<typeof GET_LINKS_REQUEST, Record<string, unknown>>;
export type GetLinksSuccessAction = ApiSuccessAction<
  typeof GET_LINKS_SUCCESS,
  Record<string, unknown>,
  SubjectLinkExpanded[]
>;
export type GetLinksErrorAction = ApiErrorAction<typeof GET_LINKS_ERROR, Record<string, unknown>>;

export type UpdateLinkRequestAction = ApiRequestAction<typeof UPDATE_LINK_REQUEST, Record<string, unknown>>;
export type UpdateLinkSuccessAction = ApiSuccessAction<typeof UPDATE_LINK_SUCCESS, Record<string, unknown>, string[]>;
export type UpdateLinkErrorAction = ApiErrorAction<typeof UPDATE_LINK_ERROR, Record<string, unknown>>;

export type DeleteLinkRequestAction = ApiRequestAction<typeof DELETE_LINK_REQUEST, Record<string, unknown>>;
export type DeleteLinkSuccessAction = ApiSuccessAction<typeof DELETE_LINK_SUCCESS, Record<string, unknown>, string[]>;
export type DeleteLinkErrorAction = ApiErrorAction<typeof DELETE_LINK_ERROR, Record<string, unknown>>;

export type GetSubjectRequestAction = ApiRequestAction<typeof GET_SUBJECT_REQUEST, Record<string, unknown>>;
export type GetSubjectSuccessAction = ApiSuccessAction<typeof GET_SUBJECT_SUCCESS, Record<string, unknown>, string[]>;
export type GetSubjectErrorAction = ApiErrorAction<typeof GET_SUBJECT_ERROR, ApiErrorAction>;
export type ClearSubject = ApiErrorAction<typeof CLEAR_SUBJECT, Record<string, unknown>>;

export type GetInvestigationsRequestAction = ApiRequestAction<
  typeof GET_INVESTIGATIONS_REQUEST,
  Record<string, unknown>
>;
export type GetInvestigationsSuccessAction = ApiSuccessAction<
  typeof GET_INVESTIGATIONS_SUCCESS,
  string[],
  ApiListResponse<SubjectInvestigation>
>;
export type GetInvestigationsErrorAction = ApiErrorAction<typeof GET_INVESTIGATIONS_ERROR, SubjectIdParam>;

export type GetInvestigationOriginalSampleRequestAction = ApiRequestAction<
  typeof GET_INVESTIGATION_ORIGINAL_SAMPLE_REQUEST,
  Record<string, unknown>
>;
export type GetInvestigationOriginalSampleSuccessAction = ApiSuccessAction<
  typeof GET_INVESTIGATION_ORIGINAL_SAMPLE_SUCCESS,
  string[],
  OriginalSample
>;
export type GetInvestigationOriginalSampleErrorAction = ApiErrorAction<
  typeof GET_INVESTIGATION_ORIGINAL_SAMPLE_ERROR,
  ErrorMessageParam & SuccessMessageParam,
  Record<string, unknown>
>;

export type CreateSampleInvestigationRequestAction = ApiRequestAction<
  typeof CREATE_SAMPLE_INVESTIGATION_REQUEST,
  Record<string, unknown>
>;
export type CreateSampleInvestigationSuccessAction = ApiSuccessAction<
  typeof CREATE_SAMPLE_INVESTIGATION_SUCCESS,
  ErrorMessageParam & SuccessMessageParam,
  string & SuccessMessageParam
>;
export type CreateSampleInvestigationErrorAction = ApiErrorAction<
  typeof CREATE_SAMPLE_INVESTIGATION_ERROR,
  ErrorMessageParam & SuccessMessageParam,
  Error
>;

export type CreateOrginalSampleRequestAction = ApiRequestAction<
  typeof CREATE_ORIGINAL_SAMPLE_REQUEST,
  Record<string, unknown>
>;
export type CreateOrginalSampleSuccessAction = ApiSuccessAction<
  typeof CREATE_ORIGINAL_SAMPLE_SUCCESS,
  ErrorMessageParam & SuccessMessageParam,
  string & SuccessMessageParam
>;
export type CreateOrginalSampleErrorAction = ApiSuccessAction<
  typeof CREATE_ORIGINAL_SAMPLE_ERROR,
  ErrorMessageParam & SuccessMessageParam,
  Error
>;

export type GetTestCategoriesRequestAction = ApiRequestAction<
  typeof GET_TEST_CATEGORIES_REQUEST,
  Record<string, unknown>
>;
export type GetTestCategoriesSuccessAction = ApiSuccessAction<
  typeof GET_TEST_CATEGORIES_SUCCESS,
  string[],
  TestCategory[]
>;
export type GetTestCategoriesErrorAction = ApiErrorAction<
  typeof GET_TEST_CATEGORIES_ERROR,
  ErrorMessageParam,
  Record<string, unknown>
>;

export type GetSubjectConstantsRequestAction = ApiRequestAction<
  typeof GET_SUBJECT_CONSTANTS_REQUEST,
  Record<string, unknown>
>;
export type GetSubjectConstantsSuccessAction = ApiSuccessAction<
  typeof GET_SUBJECT_CONSTANTS_SUCCESS,
  string[],
  SubjectConstants
>;
export type GetSubjectConstantsErrorAction = ApiErrorAction<
  typeof GET_SUBJECT_CONSTANTS_ERROR,
  ErrorMessageParam,
  Record<string, unknown>
>;

export type GetInstitutionAddressesRequestAction = ApiRequestAction<
  typeof GET_INSTITUTION_ADDRESSES_REQUEST,
  Record<string, unknown>
>;
export type GetInstitutionAddressesSuccessAction = ApiSuccessAction<
  typeof GET_INSTITUTION_ADDRESSES_SUCCESS,
  string[],
  InstitutionAddress[]
>;
export type GetInstitutionAddressesErrorAction = ApiErrorAction<
  typeof GET_INSTITUTION_ADDRESSES_ERROR,
  ErrorMessageParam,
  Record<string, unknown>
>;

export type LocationChangeAction = { type: typeof LOCATION_CHANGE; payload: RouterState };

export type SubjectActions =
  | GetRelationShipTypesRequestAction
  | GetRelationShipTypesSuccessAction
  | GetRelationShipTypesErrorAction
  | GetLinksRequestAction
  | GetLinksSuccessAction
  | GetLinksErrorAction
  | UpdateLinkRequestAction
  | UpdateLinkSuccessAction
  | UpdateLinkErrorAction
  | DeleteLinkRequestAction
  | DeleteLinkSuccessAction
  | DeleteLinkErrorAction
  | GetSubjectRequestAction
  | GetSubjectErrorAction
  | GetSubjectSuccessAction
  | GetInvestigationsRequestAction
  | GetInvestigationsSuccessAction
  | GetInvestigationsErrorAction
  | GetInvestigationOriginalSampleRequestAction
  | GetInvestigationOriginalSampleSuccessAction
  | GetInvestigationOriginalSampleErrorAction
  | CreateSampleInvestigationRequestAction
  | CreateSampleInvestigationSuccessAction
  | CreateSampleInvestigationErrorAction
  | CreateOrginalSampleRequestAction
  | CreateOrginalSampleSuccessAction
  | CreateOrginalSampleErrorAction
  | GetTestCategoriesRequestAction
  | GetTestCategoriesSuccessAction
  | GetTestCategoriesErrorAction
  | GetSubjectConstantsRequestAction
  | GetSubjectConstantsSuccessAction
  | GetSubjectConstantsErrorAction
  | GetInstitutionAddressesRequestAction
  | GetInstitutionAddressesSuccessAction
  | GetInstitutionAddressesErrorAction
  | ClearSubject
  | LocationChangeAction;

export const getRelationshipTypes = () =>
  get<ReduxState, ApiRequestAction<RelationshipType[]>>(
    `${Config().apiBaseUrl}relationships/types`,
    GET_RELATIONSHIP_TYPES
  );

export const getLinks = (subjectId: string, subjectType: string) =>
  get<ReduxState, ApiRequestAction<string[]>>(
    `${Config().apiBaseUrl}relationships?${subjectType}Id=${subjectId}`,
    GET_LINKS
  );

export const updateLink =
  (updatedSubjectLink: SubjectLink, subjectLinkType: SubjectType, isNewLink = false) =>
  async (dispatch: Dispatch<any>) => {
    const { DonorId, PatientId } = updatedSubjectLink;

    const updateSuccessString = isNewLink ? 'created' : 'updated';
    const updateFailString = isNewLink ? 'create' : 'update';

    const successMessage =
      subjectLinkType === subjectTypes.patient.id
        ? `Successfully ${updateSuccessString} link to patient ${PatientId}`
        : `Successfully ${updateSuccessString} link to donor ${DonorId}`;

    const errorMessage =
      subjectLinkType === subjectTypes.patient.id
        ? `Failed to ${updateFailString} link to patient ${PatientId}`
        : `Failed to ${updateFailString} link to donor ${DonorId}`;

    const relationship = await dispatch(
      put<ReduxState, ApiRequestAction<void>>(
        `${Config().apiBaseUrl}relationships`,
        UPDATE_LINK,
        {
          updatedSubjectLink,
          subjectLinkType,
          successMessage,
          errorMessage,
        },
        updatedSubjectLink
      )
    );
    addNotification(relationship);
    return relationship;
  };

export const deleteLink =
  (subjectLink: SubjectLink, subjectLinkType: SubjectType) => async (dispatch: Dispatch<any>) => {
    const { Id, DonorId, PatientId } = subjectLink;

    const subjectLinkId = subjectLinkType === subjectTypes.patient.id ? PatientId : DonorId;

    const relationship = await dispatch(
      apiDelete<ReduxState, ApiRequestAction<void>>(
        `${Config().apiBaseUrl}relationships/${Id}`,
        DELETE_LINK,
        {
          Id,
          successMessage: `Successfully deleted link to ${subjectLinkType} ${subjectLinkId}`,
          errorMessage: `Failed to delete link to ${subjectLinkType} ${subjectLinkId}`,
        },
        { Id }
      )
    );
    addNotification(relationship);
    return relationship;
  };

export const getAdultDonor = (donorId: string) =>
  get<ReduxState, ApiRequestAction<AdultDonor>>(`${Config().apiBaseUrl}donors/adults/${donorId}`, GET_SUBJECT, {
    donorId,
    subjectType: subjectTypes.donor.id,
  });

export const getPatient = (patientId: string) =>
  get<ReduxState, ApiRequestAction<Patient>>(`${Config().apiBaseUrl}patient/${patientId}`, GET_SUBJECT, { patientId });

export const clearSubject = () => ({
  type: CLEAR_SUBJECT,
});

export const getSubjectConstants = () =>
  get<ReduxState, ApiRequestAction<SubjectConstants>>(
    `${Config().apiBaseUrl}creation-constants`,
    GET_SUBJECT_CONSTANTS,
    {}
  );

export const getInstitutionAddresses = () =>
  get<ReduxState, ApiRequestAction<InstitutionAddress[]>>(
    `${Config().apiBaseUrl}institutions/institution-addresses`,
    GET_INSTITUTION_ADDRESSES,
    {}
  );

export const getInvestigationOriginalSample = (originalSampleId: string) =>
  get<ReduxState, ApiRequestAction<OriginalSample>>(
    `${Config().apiBaseUrl}original-samples/${originalSampleId}`,
    GET_INVESTIGATION_ORIGINAL_SAMPLE,
    { originalSampleId }
  );

export const getInvestigations = (subjectId: string | undefined) =>
  get<ReduxState, ApiRequestAction<ApiListResponse<SubjectInvestigation>>>(
    `${Config().apiBaseUrl}investigations?pagination.pageSize=1000&model.subjectId=${subjectId}`,
    GET_INVESTIGATIONS,
    { subjectId }
  );

export const createInvestigation =
  (
    invoiceRequired: boolean,
    subjectType: SubjectType,
    subjectId: string | undefined,
    sampleId: string,
    subjectRecordType: string | undefined,
    invoiceAddress?: InstitutionAddress,
    runCode?: string,
    testIds?: number[]
  ) =>
  async (dispatch: Dispatch<any>) => {
    const subjectTypeString = `${_.capitalize(subjectType)}Id`;
    const investigation = await dispatch(
      post<ReduxState, ApiRequestAction<SubjectInvestigation>>(
        `${Config().apiBaseUrl}investigations`,
        CREATE_SAMPLE_INVESTIGATION,
        {
          successMessage: `Successfully created investigation for ${
            subjectRecordType ? subjectRecordType.toLowerCase() : ''
          } ${subjectId || ''}`,
          errorMessage: `Failed to create investigation for ${
            subjectRecordType ? subjectRecordType.toLowerCase() : ''
          } ${subjectId || ''}`,
        },
        {
          SampleId: sampleId,
          [subjectTypeString]: subjectId,
          InvestigationActive: true,
          RunCode: runCode,
          InvoiceAddress: {
            AddressId: invoiceAddress?.Id,
            AddressName: invoiceAddress?.Name,
            Address1: invoiceAddress?.Address1,
            Address2: invoiceAddress?.Address2,
            Address3: invoiceAddress?.Address3,
            Town: invoiceAddress?.Town,
            County: invoiceAddress?.County,
            CountryType: invoiceAddress?.CountryType,
            Postcode: invoiceAddress?.Postcode,
          },
          InvoiceRequired: invoiceRequired,
          KitSentDate: moment(),
          KitRequestedOn: moment(),
          TestIds: testIds,
        }
      )
    );
    addNotification(investigation);
    return investigation;
  };

export const createSample =
  (subjectId: string | null, subjectType: SubjectType, urgent: boolean, dispatchMethod: string) =>
  async (dispatch: Dispatch<any>): Promise<any> => {
    const response = await dispatch(
      post(
        `${Config().apiBaseUrl}original-samples`,
        CREATE_ORIGINAL_SAMPLE,
        {},
        { subjectId, subjectType, urgent, dispatchMethod, sampleType: 'Other' }
      )
    );
    return response;
  };

export const fetchTestCategories = () =>
  get<ReduxState, ApiRequestAction<TestCategory[]>>(
    `${Config().apiBaseUrl}investigations/test-categories`,
    GET_TEST_CATEGORIES,
    {}
  );

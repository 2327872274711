import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch as ReduxDispatch } from 'redux';
import { styles } from '../../../../style';
import * as actions from '../../../../redux/actions';
import Selectors from '../../../../redux/selectors';

import type { AgeFilterValue } from '../../../../types/index';
import type { ReduxState } from '../../../../../rootReducer';

type StateProps = {
  value?: AgeFilterValue;
};
type Props = PropsFromRedux & StateProps;
type State = {
  maxAge?: number;
  minAge?: number;
};

type FilterChangeEvent = {
  name: string;
  value: string;
} & HTMLInputElement;

const mapStateToProps = (state: ReduxState): StateProps => ({
  value: Selectors.getAgeFilter(state),
});

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>) => ({
  updateAgeFilter: bindActionCreators(actions.updateAgeFilter, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

class Filter extends PureComponent<Props, State> {
  static defaultProps = {
    value: undefined,
  };

  constructor(props: Props) {
    super(props);
    const { value } = this.props;
    this.state = {
      minAge: value ? value.minAge : undefined,
      maxAge: value ? value.maxAge : undefined,
    };
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps: Props) {
    const { value } = nextProps;
    const { maxAge, minAge } = this.state;
    const maxAgeValue = value ? value.maxAge : undefined;
    const minAgeValue = value ? value.minAge : undefined;
    if (maxAge !== maxAgeValue || minAge !== minAgeValue) {
      this.setState({
        minAge: minAgeValue,
        maxAge: maxAgeValue,
      });
    }
  }

  render() {
    const { maxAge, minAge } = this.state;
    return (
      <div style={{ display: 'flex' }}>
        <div className="col span_6_of_12">
          <label style={styles.inputLabel} htmlFor="minAge">
            Min Age
            <input
              id="minAge"
              type="number"
              placeholder="No Min Age"
              value={minAge || ''}
              name="minAge"
              className="Select-control"
              onChange={this.handleAgeFilterChange}
              onKeyPress={this.handleKeyPress}
              onBlur={this.handleAgeFilter}
            />
          </label>
        </div>
        <div className="col span_6_of_12">
          <label htmlFor="maxAge" style={styles.inputLabel}>
            Max Age
            <input
              id="maxAge"
              type="number"
              placeholder="No Max Age"
              value={maxAge || ''}
              name="maxAge"
              className="Select-control"
              onChange={this.handleAgeFilterChange}
              onKeyPress={this.handleKeyPress}
              onBlur={this.handleAgeFilter}
            />
          </label>
        </div>
      </div>
    );
  }

  handleAgeFilter = () => {
    const { updateAgeFilter } = this.props;
    const { minAge, maxAge } = this.state;
    updateAgeFilter(minAge, maxAge);
  };

  handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // $FlowExpectedError - not possible to type `keyCode`
    // keyCode is deprecated
    if (event.nativeEvent.keyCode === 13) {
      // 13 is the keyCode for "Return"
      event.currentTarget.blur();
    }
  };

  handleAgeFilterChange = (event: React.SyntheticEvent<FilterChangeEvent>) => {
    const ageFilterType = event.currentTarget.name;
    return this.setState({
      [ageFilterType]: event.currentTarget.value,
    });
  };
}

export default connector(Filter);

import React, { useState } from 'react';
import Select, { SingleValue } from 'react-select';
import { useHistory } from 'react-router-dom';
import { FamilyRelationshipInfoButton } from './FamilyRelationshipInfoButton';
import { Button } from '../../../core/components/Button';
import { getSubjectTypeDisplayString } from '../../constants/subjectTypes';
import { RelationshipType, SubjectLinkExpanded, SubjectType } from '../../types';
import { mapSelectOptions, Option } from '../../../patient/patientCreation/helpers/formsHelpers';
import { DeleteLinkButton } from './DeleteLinkButton';
import { UpdateLinkButton } from './UpdateLinkButton';

type Props = {
  relationshipTypes: RelationshipType[];
  subjectLink: SubjectLinkExpanded;
  subjectType: SubjectType;
  isMainLink: boolean;
};

export const LinkSummary = ({ relationshipTypes, subjectLink, subjectType, isMainLink }: Props) => {
  const [relationshipType, setRelationshipType] = useState<Option>({
    value: subjectLink.RelationshipType,
    label: subjectLink.RelationshipType,
  });
  const history = useHistory();
  const linkedSubjectTypeDisplayName = getSubjectTypeDisplayString(subjectType);
  const isDonor = subjectType === 'donor';
  const subjectName = isDonor
    ? `${subjectLink.Donor.FirstName || ''} ${subjectLink.Donor.LastName || ''}`
    : `${subjectLink.Patient.FirstName || ''} ${subjectLink.Patient.LastName || ''}`;
  const subjectId = isDonor ? subjectLink.Donor.Id : subjectLink.Patient.Id;

  const updateRelationshipType = (newValue: SingleValue<Option>) => {
    if (newValue) {
      setRelationshipType(newValue);
    }
  };

  const handleViewDetails = (id: string) => {
    const link = isDonor ? 'details' : 'ukSearchRequests';
    history.push(`/${subjectType}/${id}/${link}`);
  };

  return (
    <div
      className={`panel link-summary${isMainLink ? ' main-link' : ''}`}
      style={{ maxWidth: '850px', marginLeft: 'auto', marginRight: 'auto' }}
    >
      <table className="link-summary__table">
        <tbody>
          <tr>
            <th>{linkedSubjectTypeDisplayName} Name</th>
            <td>{subjectName}</td>
          </tr>
          <tr>
            <th>{linkedSubjectTypeDisplayName} ID</th>
            <td>{subjectId}</td>
          </tr>
          <tr>
            <th>
              <FamilyRelationshipInfoButton />
            </th>
            <td>
              <Select
                menuPlacement="auto"
                id="search-filter"
                className="react-select-container"
                classNamePrefix="react-select"
                placeholder="Select option..."
                isClearable={false}
                value={relationshipType}
                options={mapSelectOptions(relationshipTypes)}
                onChange={updateRelationshipType}
              />
            </td>
          </tr>
        </tbody>
      </table>

      <div className="btn-group">
        <Button primary text={`View ${subjectType} Details`} onClick={() => handleViewDetails(subjectId)} />
        <UpdateLinkButton
          relationshipType={relationshipType.value as RelationshipType}
          subjectLink={subjectLink}
          subjectType={subjectType}
        />
        {isDonor && (
          <DeleteLinkButton
            relationshipType={relationshipType.value as RelationshipType}
            subjectId={subjectId}
            subjectLink={subjectLink}
            subjectType={subjectType}
          />
        )}
      </div>
    </div>
  );
};

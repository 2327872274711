import React from 'react';
import { formatDate } from '../../../../../../core/helpers/dateHelpers';
import './textMessagesTable.scss';
import { orderSampleInfoTextMessages } from '../../../../constants/orderTextMessages';
import { ExternalInvestigationTextMessage } from '../../../../../../externalInvestigations/types';

type Props = {
  textMessages: ExternalInvestigationTextMessage[];
};

const TextMessagesTable = ({ textMessages }: Props) => {
  const orderedTextMessages = orderSampleInfoTextMessages(textMessages);
  return (
    <div className="table-wrapper" style={{ maxHeight: '30vh' }}>
      <table className="text-messages-table">
        <thead className="table-header table--sticky-header">
          <tr>
            <th>Sent Date</th>
            <th>Received Date</th>
            <th>Message</th>
          </tr>
        </thead>
        <tbody>
          {textMessages.length > 0 ? (
            orderedTextMessages.map((textMessage) => (
              <tr className="centered-table-data" key={`textMessage${textMessage.sentDate}`}>
                <td className="text-messages-table__date" id="sentDate">
                  {formatDate(textMessage.sentDate)}
                </td>
                <td className="text-messages-table__date" id="receivedDate">
                  {formatDate(textMessage.receivedDate)}
                </td>
                <td className="text-messages-table__message" id="message">
                  {textMessage.message}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={3} style={{ textAlign: 'center', padding: '20px' }}>
                No messages found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TextMessagesTable;

import React from 'react';
import { SampleInformation } from '../../../../../../externalInvestigations/types';
import { formatDate } from '../../../../../../core/helpers/dateHelpers';
import TextWithLimitedLines from '../../../../../../core/components/TextWithLimitedLines/TextWithLimitedLines';
import './sampleInformationTable.scss';

type Props = {
  sampleInformations: SampleInformation[];
};

const SampleInformationTable = ({ sampleInformations }: Props) => {
  const baseTruncatedCellProps = {
    lineHeightPx: 20,
    numberOfLines: 4,
  };

  return (
    <div className="table-wrapper" style={{ maxHeight: '30vh' }}>
      <table className="sample-information-table">
        <thead className="table-header table--sticky-header">
          <tr>
            <th>Information Type</th>
            <th>Remark</th>
            <th>Received Date</th>
          </tr>
        </thead>
        <tbody>
          {sampleInformations.length > 0 ? (
            sampleInformations.map((sampleInformation) => (
              <tr className="centered-table-data" key={`sampleInformation${sampleInformation.receivedDate}`}>
                <td className="sample-information-table__informationType" id="informationType">
                  {sampleInformation.informationType}
                </td>
                <td className="sample-information-table__remark" id="remark">
                  <TextWithLimitedLines {...baseTruncatedCellProps}>{sampleInformation.remark}</TextWithLimitedLines>
                </td>
                <td className="sample-information-table__receivedDate" id="receivedDate">
                  {formatDate(sampleInformation.receivedDate)}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={3} style={{ textAlign: 'center', padding: '20px' }}>
                No messages found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default SampleInformationTable;

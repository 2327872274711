import React, { CSSProperties, PureComponent } from 'react';
import Modal from 'react-modal';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import lociNames from '../../../../hlaInput/helpers/lociNames';
import { styles as commonStyles, Styles as CommonStyles } from '../../style';
import LinkButton from '../../../../core/components/LinkButton/LinkButton';
import defaultTab from '../../../core/constants/defaultPatientDashboardTab';
// eslint-disable-next-line import/no-named-as-default
import HlaSection from '../../../../hlaInput/components/HlaSection/HlaSection';
import type { Genetics, PatientInfo } from '../../../types/index';
import { Locus } from '../../../../core/types';
import '../../../../hlaInput/components/HlaSection/hlaSection.scss';
import { isValid } from '../../../../hlaInput/helpers/geneticValidation';

type Styles = CommonStyles & {
  buttonGroupStyle: CSSProperties;
  submitButtonStyle: CSSProperties;
  buttonStyle: CSSProperties;
  modalStyle: {
    overlay: Record<string, string>;
    content: CSSProperties;
  };
  modalText: CSSProperties;
};

const styles: Styles = {
  ...commonStyles,
  buttonGroupStyle: {
    display: 'flex',
    width: 'auto',
    alignItems: 'center',
    paddingBottom: '15px',
    justifyContent: 'center',
  },
  submitButtonStyle: {
    cursor: 'pointer',
  },
  buttonStyle: {
    marginLeft: '15px',
  },
  modalStyle: {
    overlay: {
      zIndex: '99',
    },
    content: {
      top: '30%',
      left: '30%',
      right: '30%',
      bottom: '30%',
      position: 'absolute',
    },
  },
  modalText: {
    textAlign: 'center',
  },
};

type GeneticData = {
  geneticData: Record<string, Record<string, Genetics>>;
};
type State = {
  secondaryInputsExpanded: boolean;
  selectedAntigenIds: Record<string, Record<string, unknown>>;
  error: string;
  showModal: boolean;
};
type Props = {
  patientInfo: PatientInfo;
  saveGeneticDetails: (geneticData: GeneticData, patientId: string) => void;
  showUpdatePatientDetailsButton?: boolean;
  submitBtnLabel: string;
};

export class GeneticDataForm extends PureComponent<Props, State> {
  static defaultProps = {
    showUpdatePatientDetailsButton: false,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      secondaryInputsExpanded: false,
      selectedAntigenIds: this.getInitialAntigenIds(),
      showModal: false,
      error: '',
    };
  }

  render() {
    const { patientInfo, showUpdatePatientDetailsButton, submitBtnLabel } = this.props;
    const { error, showModal } = this.state;
    const patientOverviewPath = `/patient/${patientInfo.id}/${defaultTab}`;
    const patientUpdatePath = `/patient/${patientInfo.id}/update`;

    return (
      <div>
        <div>
          <div style={styles.buttonHeader}>
            <h3>
              {patientInfo.name} ({patientInfo.id})
            </h3>
          </div>
          <b>Date of Birth: </b>
          {patientInfo.dateOfBirth ? patientInfo.dateOfBirth.format('DD/MM/YYYY') : ''}
        </div>
        <form autoComplete="off" onSubmit={this.saveGeneticData}>
          <div style={styles.buttonHeader}>
            <h3>Genetic Data</h3>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {this.primaryGeneticInputs()}
            {this.secondaryInputSection()}
          </div>

          {error ? (
            <h3 id="genetic-data-form-error-message" data-testid="error">
              {error}
            </h3>
          ) : null}

          <div style={styles.buttonGroupStyle}>
            <button className="btn btn--inline" type="submit" id="submit">
              {submitBtnLabel}
            </button>
            {showUpdatePatientDetailsButton && (
              <button
                className="btn btn--inline"
                type="button"
                onClick={this.handleOpenModal}
                id="updatePatientDetails"
              >
                Update Patient Details
              </button>
            )}
            <Link to={patientOverviewPath} className="btn btn--inline btn--secondary">
              Cancel
            </Link>
          </div>
          <Modal isOpen={showModal} onRequestClose={this.handleCloseModal} shouldCloseOnEsc style={styles.modalStyle}>
            <h2 style={styles.modalText}>CLICKING CONTINUE WILL DELETE UNSAVED GENETIC DATA</h2>
            <div style={styles.buttonGroupStyle}>
              <LinkButton className="btn" to={patientUpdatePath} style={styles.buttonStyle}>
                CONTINUE
              </LinkButton>
              <button
                className="btn btn--secondary"
                style={styles.buttonStyle}
                type="button"
                onClick={this.handleCloseModal}
              >
                CANCEL
              </button>
            </div>
          </Modal>
        </form>
      </div>
    );
  }

  handleOpenModal = () => this.setState({ showModal: true });

  handleCloseModal = () => this.setState({ showModal: false });

  getInitialAntigenIds = () => {
    const lociByType = _.keyBy(lociNames, (locus) => locus.type);
    return _.mapValues(lociByType, (locus) => this.getInitialAntigenIdsForLocus(locus));
  };

  getInitialAntigenIdsForLocus = (locus: { antigenInfoLocusKeys: string[] }) => {
    const { patientInfo } = this.props;
    const values = _.map(locus.antigenInfoLocusKeys, (key: keyof PatientInfo) =>
      // @ts-expect-error - ts is not recognising id after patientInfo[key]
      patientInfo[key] ? patientInfo[key]?.id : null
    );
    return _.pickBy(_.zipObject([0, 1], values), (value) => value !== null);
  };

  handleAntigenChange = (locusType: string) => (rowId: number, antigenId: string | null | undefined) => {
    const { selectedAntigenIds } = this.state;
    this.setState({
      selectedAntigenIds: {
        ...selectedAntigenIds,
        [locusType]: {
          ...selectedAntigenIds[locusType],
          [rowId]: antigenId,
        },
      },
      error: '',
    });
  };

  saveGeneticData = (event: React.MouseEvent<HTMLFormElement>) => {
    const { patientInfo, saveGeneticDetails } = this.props;
    const { selectedAntigenIds } = this.state;
    event.preventDefault();
    if (isValid(selectedAntigenIds)) {
      this.setState({ error: 'One or more antigens are not valid' });
    } else {
      saveGeneticDetails(selectedAntigenIds as GeneticData, patientInfo.id);
    }
  };

  primaryGeneticInputs = (): React.ReactNode[] => lociNames.filter((l) => l.primary).map(this.hlaSection);

  secondaryInputSection = () => {
    const { secondaryInputsExpanded } = this.state;
    return (
      <div>
        <button
          className="btn btn--inline"
          type="button"
          id="expandLoci"
          onClick={() =>
            this.setState({
              secondaryInputsExpanded: !secondaryInputsExpanded,
            })
          }
        >
          {secondaryInputsExpanded ? 'Hide extra loci' : '+ Add extra loci'}
        </button>
        <div id="secondaryGeneticInputsContainer" style={secondaryInputsExpanded ? {} : { display: 'none' }}>
          {this.secondaryGeneticInputs()}
        </div>
      </div>
    );
  };

  secondaryGeneticInputs = (): React.ReactNode[] => lociNames.filter((l) => !l.primary).map(this.hlaSection);

  hlaSection = (
    locus: { type: string; title: string; antigenInfoLocusKeys: string[] },
    index: number
  ): React.ReactNode => {
    const { patientInfo } = this.props;
    return (
      <HlaSection
        isEven={index % 2 === 0}
        initialAntigenValues={locus.antigenInfoLocusKeys.map((key) => patientInfo[key as keyof PatientInfo] as Locus)}
        key={locus.type}
        locusType={locus.type}
        onChange={this.handleAntigenChange(locus.type)}
        title={locus.title}
      />
    );
  };
}

export default GeneticDataForm;

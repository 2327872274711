import _ from 'lodash';
import React, { CSSProperties } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch as ReduxDispatch } from 'redux';
import { styles as commonStyles, Styles as CommonStyles } from '../../style';
import Selectors from '../../redux/selectors';
import * as actions from '../../redux/actions';
import { LoadingMessage } from '../../../../core';
import Checkbox, { CheckboxEvent } from '../../../../core/components/Checkbox';

import type { AlgorithmDataType } from '../../../types';
import type { ErrorMessages } from '../../../../core/types';
import type { ReduxState } from '../../../../rootReducer';
import type { PatientDashboardReducerState } from '../../redux/reducer';

const styles: CommonStyles & Record<string, CSSProperties> = {
  ...commonStyles,
  errorMessage: {
    color: 'red',
  },
};

type StateProps = {
  availableAlgorithms: AlgorithmDataType[];
  errorMessage: ErrorMessages;
  isLoadingAlgorithms: boolean;
  searchRequestAlgorithms: string[];
  searchRequestData: PatientDashboardReducerState;
};
type Props = PropsFromRedux & StateProps;

const mapStateToProps = (state: ReduxState): StateProps => ({
  availableAlgorithms: Selectors.getAvailableAlgorithms(state),
  errorMessage: Selectors.getAlgorithmErrors(state),
  isLoadingAlgorithms: Selectors.isLoadingAlgorithms(state),
  searchRequestAlgorithms: Selectors.getSearchAlgorithms(state),
  searchRequestData: Selectors.getSearchRequestData(state),
});

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>) => ({
  updateSearchAlgorithm: bindActionCreators(actions.updateSearchAlgorithm, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class SearchAlgorithms extends React.Component<Props> {
  componentDidMount() {
    const { availableAlgorithms, updateSearchAlgorithm } = this.props;
    if (availableAlgorithms.length === 1) {
      updateSearchAlgorithm([availableAlgorithms[0].Algorithm]);
    }
  }

  render() {
    const { availableAlgorithms, errorMessage, isLoadingAlgorithms, searchRequestAlgorithms, searchRequestData } =
      this.props;

    return (
      <div id="search-algorithms">
        <h3 style={styles.searchTitle}>Search Algorithms</h3>
        <LoadingMessage isLoading={isLoadingAlgorithms}>
          <div id="search-algorithms" style={styles.algorithms}>
            {availableAlgorithms &&
              availableAlgorithms.map((searchAlgorithm) => (
                <Checkbox
                  key={searchAlgorithm.Algorithm}
                  checked={searchRequestAlgorithms.includes(searchAlgorithm.Algorithm)}
                  disabled={
                    searchAlgorithm.Disabled ||
                    (searchAlgorithm.Algorithm === 'Solar' && searchRequestData.isHlaAdjusted)
                  }
                  id={searchAlgorithm.Algorithm}
                  onChange={this.handleSearchAlgorithmUpdate}
                  label={
                    searchAlgorithm.Validated
                      ? searchAlgorithm.DisplayName
                      : `${searchAlgorithm.DisplayName} (Unvalidated)`
                  }
                />
              ))}
          </div>
        </LoadingMessage>
        {errorMessage && <div style={styles.errorMessage}>{errorMessage}</div>}
      </div>
    );
  }

  handleSearchAlgorithmUpdate = (event: React.SyntheticEvent<CheckboxEvent>) => {
    const { searchRequestAlgorithms, updateSearchAlgorithm } = this.props;
    const searchAlgorithm = event.currentTarget.id;
    const currentSearchAlgorithms = searchRequestAlgorithms.slice(0);
    const newSearchAlgorithm = _.xor(currentSearchAlgorithms, [searchAlgorithm]);
    updateSearchAlgorithm(newSearchAlgorithm);
  };
}

export default connector(SearchAlgorithms);

import update from 'immutability-helper';
import { ReportStatuses } from '../../core/constants';
import type { ApiReportIdsByStatus, ApiSavedReportMinimal } from '../../types/api';
import type { ReportStatus } from '../../types';

const reportStatuses: ApiReportIdsByStatus = {
  Draft: [],
  AwaitingAuthorisation: [],
  Authorised: [],
  Sent: [],
};

const addIdToStatusArray = (
  reportStatusArrays: ApiReportIdsByStatus,
  status: ReportStatus,
  id: string
): ApiReportIdsByStatus => update(reportStatusArrays, { [status]: { $push: [id] } });

const addIdsToStatusArray = (
  reportStatusArrays: ApiReportIdsByStatus,
  report: ApiSavedReportMinimal
): ApiReportIdsByStatus => {
  switch (report.ReportStatus) {
    case ReportStatuses.Draft:
      return addIdToStatusArray(reportStatusArrays, ReportStatuses.Draft, report.Id);
    case ReportStatuses.AwaitingAuthorisation:
      return addIdToStatusArray(reportStatusArrays, ReportStatuses.AwaitingAuthorisation, report.Id);
    case ReportStatuses.Authorised:
      return addIdToStatusArray(reportStatusArrays, ReportStatuses.Authorised, report.Id);
    case ReportStatuses.Sent:
      return addIdToStatusArray(reportStatusArrays, ReportStatuses.Sent, report.Id);
    default:
      return reportStatusArrays;
  }
};

const getReportIdsByStatus = (reports: { [key: number]: ApiSavedReportMinimal }): ApiReportIdsByStatus =>
  Object.values(reports).reduce(
    (reportIdsByStatus, report) => addIdsToStatusArray(reportIdsByStatus, report),
    reportStatuses
  );

export default {
  getReportIdsByStatus,
};

export enum ExternalInvestigationReportStatus {
  NoDataAvailable,
  ReadyToGenerate,
  Generating,
  ReadyToDownload,
  Downloading,
}

export type ExternalInvestigationReportDetails = {
  reportFilename: string | undefined;
  reportStatus: ExternalInvestigationReportStatus;
};

export type ExternalInvestigationReports = {
  virologyReportDetails: ExternalInvestigationReportDetails;
  hlaReportDetails: ExternalInvestigationReportDetails;
};

export type DonorDetails = {
  donorUnavailableToTime: string;
  donorStatus: string;
};

export type ExternalInvestigationTypeValue = 'Idm' | 'VerificationTyping' | 'ExtendedTyping';

export type ExternalInvestigationTextMessage = {
  message: string;
  sentDate: string;
  receivedDate: string;
};

export type BaseExternalInvestigation = {
  actionRequired: boolean;
  donorId: string;
  failureSource: string;
  grid: string;
  id: number;
  reports: ExternalInvestigationReports;
  patientId: string;
  payingInstitutionId: string;
  registryId: string;
  registryName: string;
  remark: string;
  requestedTime: string;
  status: string;
  statusReason: string;
  statusRemark: string;
  lastUpdatedTime: string;
  donorDetails: DonorDetails;
  isIdmResultReceived: boolean;
  isTypingResultReceived: boolean;
  reservedForThisPatient: boolean;
};

export type IDMInvestigationDetails = {
  infectiousDiseaseMarkers: {
    [key: string]: boolean;
    bloodGroupRhesus: boolean;
    cmv: boolean;
    toxoplasmosis: boolean;
    ebv: boolean;
    hivStatus: boolean;
    hivP24: boolean;
    hepatitisBSurfaceAntigen: boolean;
    antibodyHepatitisBSurfaceAntigen: boolean;
    antibodyHepatitisBCoreAntigen: boolean;
    antibodyHepatitisC: boolean;
    luesStatus: boolean;
    altStatus: boolean;
    hltvAntibody: boolean;
  };
  textMessages: ExternalInvestigationTextMessage[];
};

export type ExtendedTypingInvestigationDetails = {
  resolutionRequired: {
    [key: string]: string;
    hlaA: string;
    hlaB: string;
    hlaC: string;
    hlaDrb1: string;
    hlaDrb3: string;
    hlaDrb4: string;
    hlaDrb5: string;
    hlaDqa1: string;
    hlaDqb1: string;
    hlaDpa1: string;
    hlaDpb1: string;
  };
  urgent: boolean;
  textMessages: ExternalInvestigationTextMessage[];
};

export type BloodRequirementType = {
  quantity: number;
  numberOfTubes: number;
};

export type AcceptableDaysType = {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
};

export type SampleInformation = {
  informationType: string;
  remark: string;
  receivedDate: string;
};

export type VTInvestigationDetails = {
  edtaRequirements: BloodRequirementType;
  acdRequirements: BloodRequirementType;
  heparinRequirements: BloodRequirementType;
  clottedRequirements: BloodRequirementType;
  urgent: boolean;
  earliestReceiptDate: string;
  latestReceiptDate: string;
  acceptableDays: AcceptableDaysType;
  sampleRecipientInternationalInstitutionCode: string;
  sampleInformations: SampleInformation[];
  sampleArrivalDate: string;
  textMessages: ExternalInvestigationTextMessage[];
};

export type IDMInvestigation = BaseExternalInvestigation & {
  type: ExternalInvestigationTypeValue;
  investigationDetails: IDMInvestigationDetails;
};

export type ExtendedTypingInvestigation = BaseExternalInvestigation & {
  type: ExternalInvestigationTypeValue;
  investigationDetails: ExtendedTypingInvestigationDetails;
};

export type VTInvestigation = BaseExternalInvestigation & {
  type: ExternalInvestigationTypeValue;
  investigationDetails: VTInvestigationDetails;
};

export type ExternalInvestigation = IDMInvestigation | ExtendedTypingInvestigation | VTInvestigation;

// prettier-ignore
export const CancellationReasons = {
  AutologousTransplantation: 'Autologous Transplantation',
  BmscTransplantationRelatedDonor: 'BMSC Transplantation with related donor',
  BmscTransplantationUnrelatedDonor: 'BMSC Transplantation with unrelated donor',
  CbscTransplantationRelatedCbu: 'CBSC Transplantation with related CBU',
  CbscTransplantationUnrelatedCbu: 'CBSC Transplantation with unrelated CBU',
  PbscTransplantationRelatedDonor: 'PBSC Transplantation with related donor',
  PbscTransplantationUnrelatedDonor: 'PBSC Transplantation with unrelated donor',
  GoodClinicalCondition: 'Good clinical condition of patient',
  BadClinicalCondition: 'Clinical condition of patient deteriorated',
  PatientDeceased: 'Patient deceased before transplantation',
  AlternativeTherapy: 'Patient responds to alternative therapy',
  NonMedicalWithdrawal: 'Patient withdrawal (non medical, personal reasons)',
  TransplantationIndicationChanged: 'Transplantation indication changed',
  OtherDonorFound: 'Other/better donor found',
  NoSuitableDonorFound: 'No suitable donor found',
  CorrectionOfRequest: 'Correction of request',
  HlaMismatch: 'HLA mismatch',
  DonorRelatedReason: 'Donor related reason',
  OtherReason: 'Other reason',
  UnknownReason: 'Unknown reason',
};

export type CancellationReason = keyof typeof CancellationReasons;

export type ExternalInvestigationsCancellationRequest = {
  patientId: string;
  extendedTypingInvestigationIds: number[];
  idmInvestigationIds: number[];
  verificationTypingInvestigationIds: number[];
  cancellationReason: CancellationReason;
  cancellationRemark: string | null | undefined;
};

export type ExternalInvestigationReportCreationModel = {
  patientId: string;
  externalInvestigationId: number;
  externalInvestigationType: string;
  donorGrid: string;
  donorId: string;
};

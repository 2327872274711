import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import ResultsTable from './ResultsTable/ResultsTable';
import { styles } from '../style';
import Selectors from '../redux/selectors';
import ResultSettings from './ResultSettings/ResultSettings';
import { adultTableContents, adultExternalTableContents } from './ResultsTable/donorSearchResultsTableContents';
import cordTableContents from './ResultsTable/cordSearchResultsTableContents';
import { LoadingMessage } from '../../core/components/LoadingMessage/LoadingMessage';
import algorithmTypes from '../../core/constants/algorithmTypes';
import donorTypes from '../../core/constants/donorTypes';

import type { DonorType, SearchResults } from '../../core/types';
import type { SearchRequestSummary } from '../../donorMatchSearchRequests/types';
import type { ReduxState } from '../../rootReducer';
import type { ApiData } from '../redux/resultsPageData/searchResultsData';

type OwnProps = {
  donorType: DonorType;
  // eslint-disable-next-line react/no-unused-prop-types
  isCord: boolean;
  // used in `mapStateToProps`
  patientId?: string;
  registryIds?: string;
  reportId?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  resultSetId: string; // used in `mapStateToProps`
};
type StateProps = {
  errorMessage?: string;
  filteredSearchResults?: SearchResults | void;
  hasErrored: boolean;
  isLoading: boolean;
  searchRequest: ApiData<SearchRequestSummary>;
  searchResults?: SearchResults | void;
};
type Props = OwnProps & StateProps & PropsFromRedux;

const mapStateToProps = (state: ReduxState, { donorType, resultSetId }: OwnProps): StateProps => ({
  errorMessage: Selectors.getSearchErrorMessage(state, resultSetId),
  filteredSearchResults: Selectors.getFilteredSearchResults(state, resultSetId, donorType),
  hasErrored: Selectors.hasSearchErrored(state, resultSetId),
  isLoading: Selectors.isSearchLoading(state, resultSetId),
  searchRequest: Selectors.getSearchRequest(state),
  searchResults: Selectors.getSearchResults(state, resultSetId),
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const MatchedDonors = ({
  donorType,
  errorMessage,
  filteredSearchResults,
  hasErrored,
  isLoading,
  patientId,
  registryIds,
  reportId,
  resultSetId,
  searchRequest,
  searchResults,
}: Props) => {
  const resultSet =
    searchRequest.data && searchRequest.data.resultSetSummaries
      ? searchRequest.data.resultSetSummaries.find(
          (set) => parseInt(set.searchResultSetId, 10) === parseInt(resultSetId, 10)
        )
      : undefined;

  const algorithm = resultSet ? resultSet.algorithmUsed : undefined;

  const specificSearchType =
    donorType === donorTypes.cord.value ? searchRequest.data?.cordSearchType : searchRequest.data?.adultSearchType;

  let tableContents;
  if (donorType === donorTypes.cord.value) {
    tableContents = cordTableContents;
  } else if (algorithm === algorithmTypes.external) {
    tableContents = adultExternalTableContents;
  } else {
    tableContents = adultTableContents;
  }

  return (
    <div style={styles.flexToFillAllTheSpace}>
      {filteredSearchResults && searchResults && algorithm && patientId ? (
        <>
          <ResultSettings
            filteredSearchResults={filteredSearchResults}
            isFetching={isLoading}
            registryIds={registryIds}
            resultSetId={resultSetId}
            donorType={donorType}
            algorithm={algorithm}
            searchResults={searchResults}
            tableContents={tableContents}
            patientId={patientId}
          />
          <div className="col span_12_of_12" style={styles.flexToFillAllTheSpace}>
            <ResultsTable
              algorithm={algorithm}
              searchResults={filteredSearchResults}
              isFetching={isLoading}
              hasErrored={hasErrored}
              errorMessage={errorMessage}
              patientId={patientId}
              reportId={reportId}
              tableContents={tableContents}
              resultSetId={resultSetId}
              donorType={donorType}
              specificSearchType={specificSearchType}
            />
          </div>
        </>
      ) : (
        <div style={styles.flexToFillVerticalSpace}>
          <LoadingMessage />
        </div>
      )}
    </div>
  );
};

MatchedDonors.defaultProps = {
  errorMessage: undefined,
  filteredSearchResults: undefined,
  patientId: undefined,
  registryIds: undefined,
  reportId: undefined,
  searchResults: undefined,
};

export default connector(MatchedDonors);

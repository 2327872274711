import React from 'react';
import _ from 'lodash';
import Select from 'react-select';
import type { TestCategory } from '../../../types';
import { OptionWithValue } from '../../../../core/types';
import { getSelectOptionLabel } from '../../../../core/helpers/getSelectOptionLabel';

type Props = {
  categories: TestCategory[];
  onSelectedCategoryChange: (selectedCategory: string) => void;
  runCode: string | undefined;
};

const SubjectTestCategories = ({ categories, onSelectedCategoryChange, runCode }: Props) => {
  const categoryOptions: OptionWithValue[] = _.sortBy(
    categories.map((category) => ({
      label: `${category.RunCode} - ${category.Name}`,
      value: category.RunCode,
    })),
    'label'
  );

  return (
    <Select
      className="react-select-container"
      classNamePrefix="react-select"
      isClearable
      id="subject-categories"
      name="test-category-select"
      onChange={(e) => onSelectedCategoryChange(e?.value as string)}
      options={categoryOptions}
      value={{ label: getSelectOptionLabel(categoryOptions, runCode), value: runCode }}
    />
  );
};

export default SubjectTestCategories;

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { PopUpWrapper } from '../../../../core';
import CreateSubjectInvestigation from './CreateSubjectInvestigation';
import * as SubjectActions from '../../../redux/actions';

import type { SubjectType } from '../../../types';

const popUpStyle: React.CSSProperties = {
  padding: '0',
  position: 'fixed',
  width: '800px',
  top: '50%',
  left: '50%',
  overflow: 'auto',
  maxHeight: '90%',
};

type Props = {
  linkedPatientId?: string;
  setSelectedInvestigation: (selectedInvestigation: number | null) => void;
  subjectId: string | undefined;
  subjectRecordType: string | undefined;
  subjectType: SubjectType;
};

const CreateInvestigationButton = ({
  linkedPatientId,
  setSelectedInvestigation,
  subjectId,
  subjectRecordType,
  subjectType,
}: Props) => {
  const [showPopup, setShowPopup] = useState(false);
  const dispatch = useDispatch();
  const openPopUp = () => {
    setShowPopup(true);
  };
  const closePopUp = (investigationId: number) => {
    dispatch(SubjectActions.getInvestigations(subjectId));
    setSelectedInvestigation(investigationId);
    setShowPopup(false);
  };
  return (
    <PopUpWrapper
      placement=""
      name="Create New Investigation"
      shouldDarkenBackground
      buttonClassName="btn btn--inline"
      popoverContainerClassName="react-tiny-popover-container--center"
      onClick={openPopUp}
      isPopUpShown={showPopup}
      popUpStyle={popUpStyle}
    >
      <CreateSubjectInvestigation
        linkedPatientId={linkedPatientId}
        onClose={closePopUp}
        subjectId={subjectId}
        subjectRecordType={subjectRecordType}
        subjectType={subjectType}
      />
    </PopUpWrapper>
  );
};

CreateInvestigationButton.defaultProps = {
  linkedPatientId: undefined,
};

export default CreateInvestigationButton;

import React from 'react';
import classNames from 'classnames';

type Props = {
  loading?: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => unknown;
  text?: string;
  buttonClass?: string;
  disabled?: boolean;
  primary?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  type?: 'button' | 'submit' | 'reset';
};

export const Button = ({
  onClick,
  loading,
  text,
  buttonClass,
  disabled,
  primary,
  secondary,
  tertiary,
  type,
}: Props) => {
  const className =
    buttonClass != null
      ? classNames(buttonClass)
      : classNames('btn', 'btn--inline', {
          'btn--primary': primary,
          'btn--tertiary': tertiary,
          'btn--secondary': secondary,
        });

  return (
    // eslint-disable-next-line react/button-has-type
    <button aria-label={text} className={className} onClick={onClick} type={type} disabled={disabled}>
      <span>
        {text}
        {loading && <span data-testid="button-loading-spinner" className="loader loader--btn" />}
      </span>
    </button>
  );
};

Button.defaultProps = {
  loading: false,
  text: '',
  buttonClass: null,
  disabled: false,
  primary: true,
  secondary: false,
  tertiary: false,
  type: 'button',
};

import { HlaPopUpWrapper } from '@an/nova-frontend-hla-popup';
import React, { PureComponent } from 'react';
import { colors } from '../../../style';
import { hlaDisplay } from '../../../core';
import type { Antigen } from '../../../core/types';

type Props = {
  drB31: Antigen;
  drB32: Antigen;
  drB41: Antigen;
  drB42: Antigen;
  drB51: Antigen;
  drB52: Antigen;
};

type State = {
  drB31?: Antigen | void;
  drB32?: Antigen | void;
  drB41?: Antigen | void;
  drB42?: Antigen | void;
  drB51?: Antigen | void;
  drB52?: Antigen | void;
};

const formatHlaForDisplay = (data: Antigen, prefix: string): Antigen | void => {
  if (!data || hlaDisplay.isHlaObjectEmpty(data)) {
    return undefined;
  }

  return {
    hlaName: hlaDisplay.addPrefixToString(data.hlaName, prefix),
    id: data.id,
    nmdpString: hlaDisplay.addPrefixToString(data.nmdpString, prefix),
    locus: data.locus,
  };
};

const getLociDataForStateFromProps = (
  drB31: Antigen,
  drB32: Antigen,
  drB41: Antigen,
  drB42: Antigen,
  drB51: Antigen,
  drB52: Antigen
): State => ({
  drB31: formatHlaForDisplay(drB31, 'B3'),
  drB32: formatHlaForDisplay(drB32, 'B3'),
  drB41: formatHlaForDisplay(drB41, 'B4'),
  drB42: formatHlaForDisplay(drB42, 'B4'),
  drB51: formatHlaForDisplay(drB51, 'B5'),
  drB52: formatHlaForDisplay(drB52, 'B5'),
});

class Drb345DisplayContainer extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    const { drB31, drB32, drB41, drB42, drB51, drB52 } = props;
    this.state = getLociDataForStateFromProps(drB31, drB32, drB41, drB42, drB51, drB52);
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps: Props): void {
    const { drB31, drB32, drB41, drB42, drB51, drB52 } = nextProps;
    this.setState(getLociDataForStateFromProps(drB31, drB32, drB41, drB42, drB51, drB52));
  }

  render(): JSX.Element {
    const drb345DataArray = this.getHlaDataToDisplay();
    const uniqueDrb345Data = hlaDisplay.removeHomozygousData(drb345DataArray);
    return (
      <div>
        {uniqueDrb345Data.map((data: Antigen) => (
          <div key={`Locus${data.locus}-${data.hlaName}`}>
            <HlaPopUpWrapper highlightColour={colors.ANGreen} hlaData={data} />
          </div>
        ))}
      </div>
    );
  }

  getHlaDataToDisplay = (): Antigen[] => Object.values(this.state).filter((antigen?: Antigen) => antigen !== undefined);
}

export default Drb345DisplayContainer;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CurrentReportSelectors } from '../..';
import { ReduxState } from '../../../rootReducer';
import * as actions from '../redux/actions';
import { DateTimeInputWithCopy } from '../../../core/components/DateTimeInputWithCopy';

export const ReceivedDate = () => {
  const dispatch = useDispatch();

  const isReportReadOnly = useSelector((state: ReduxState) => CurrentReportSelectors.isReportReadOnly(state));

  const receivedDate = useSelector((state: ReduxState) => CurrentReportSelectors.getReceivedDate(state));

  const updateReceivedDate = bindActionCreators(actions.updateReceivedDate, dispatch);

  const handleDateChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const newDate = new Date(event.currentTarget.value);
    updateReceivedDate(newDate);
  };

  return (
    <>
      <h2 className="border-bottom-solid">Search Request Received Date/Time</h2>
      <DateTimeInputWithCopy
        dateTime={receivedDate}
        isReportReadOnly={isReportReadOnly}
        onDateChange={handleDateChange}
      />
    </>
  );
};

import { schema } from 'normalizr';

export const cordSet = new schema.Entity('SavedCordSets', {}, { idAttribute: 'Id' });
export const donorSet = new schema.Entity('SavedDonorSets', {}, { idAttribute: 'Id' });
export const cordSetList = [cordSet];
export const donorSetList = [donorSet];

export const searchRequest = new schema.Entity(
  'requests',
  { SavedCordSets: cordSetList, SavedDonorSets: donorSetList },
  { idAttribute: 'Id' }
);
export const searchRequestList = [searchRequest];

export const searchRequestSummary = new schema.Entity(
  'searchRequestSummaries',
  { SavedCordSets: cordSetList, SavedDonorSets: donorSetList },
  { idAttribute: 'Id' }
);

const users = new schema.Entity('users', {}, { idAttribute: 'Username' });
export const usersList = [users];

const recommendations = new schema.Entity('Recommendations', {}, { idAttribute: 'Id' });
export const recommendationsList = [recommendations];

export const searchReport = new schema.Entity(
  'SearchReports',
  { Recommendations: recommendationsList },
  { idAttribute: 'Id' }
);

export const reportsList = new schema.Object({
  SearchReports: new schema.Array(searchReport),
});

import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import Selectors from '../../core/redux/selectors';
import SearchByIdForm from './SearchByIdForm';
import SearchByNameDobForm from './SearchByNameDobForm';
import * as SearchTypes from '../helpers/patientSearchTypes';

import type { PatientSearchType } from '../../types';
import type { ReduxState } from '../../../rootReducer';

type StateProps = {
  searchType: PatientSearchType;
};
type Props = PropsFromRedux & StateProps;

const mapStateToProps = (state: ReduxState): StateProps => ({
  searchType: Selectors.getSearchType(state),
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const SearchForm = ({ searchType }: Props) => {
  if (searchType === SearchTypes.PATIENT_SEARCH_BY_NAME_DOB) {
    return <SearchByNameDobForm />;
  }
  return <SearchByIdForm />;
};

export default connector(SearchForm);

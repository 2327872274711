import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import TransplantCentreManagement from '../TransplantCentreManagement';

import type { RouterMatch } from '../../core/types';

type Props = {
  match: RouterMatch;
};

const TransplantCentrePageWrapper = ({ match }: Props): JSX.Element => (
  <Switch>
    <Route exact path={`${match.path}/:activeTab`} component={TransplantCentreManagement} />
    <Route exact path={`${match.path}`} render={() => <Redirect to={`${match.url}/testRequests`} />} />
  </Switch>
);
export default TransplantCentrePageWrapper;

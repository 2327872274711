import { CSSProperties } from 'react';
import { styles as commonStyles, Styles as CommonStyles } from '../style';

export type Styles = CommonStyles & {
  inputLabel: CSSProperties;
  flexToFillAllTheSpace: CSSProperties;
  flexToFillVerticalSpace: CSSProperties;
  smallCardButton: CSSProperties;
  flexFilterButton: CSSProperties;
  toolBarButtonContainer: CSSProperties;
  savedDonorsList: CSSProperties;
  doubleLinedCell: CSSProperties;
  donorSelectBox: CSSProperties;
  dkmsUkDonorsFilterContainer: CSSProperties;
  hlaCellContainer: CSSProperties;
  hlaCellLocusData: CSSProperties;
  hlaCellLocusMatchCategory: CSSProperties;
};
// eslint-disable-next-line import/prefer-default-export
export const styles: Styles = {
  ...commonStyles,
  inputLabel: {
    display: 'block',
    textTransform: 'capitalize',
    fontWeight: 'bold',
  },
  flexToFillAllTheSpace: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
  },
  flexToFillVerticalSpace: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
  },
  smallCardButton: {
    margin: '0px 0px',
    minHeight: '47px',
    cursor: 'pointer',
    width: '100%',
  },
  flexFilterButton: {
    marginTop: '26px',
  },
  toolBarButtonContainer: {
    padding: '5px 10px',
    textAlign: 'center',
  },
  savedDonorsList: {
    listStyle: 'none',
    maxHeight: '390px',
    overflow: 'auto',
    marginTop: '0px',
  },
  doubleLinedCell: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  donorSelectBox: {
    height: '100%',
    width: '100%',
    display: 'flex',
  },
  dkmsUkDonorsFilterContainer: {
    marginTop: 28,
  },
  hlaCellContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  hlaCellLocusData: {
    width: '100%',
  },
  hlaCellLocusMatchCategory: {
    margin: '0 7px',
  },
};

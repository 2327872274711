import React, { useState } from 'react';
import { ExternalInvestigationTextMessage, SampleInformation } from '../../../../../../externalInvestigations/types';
import TextMessagesTab from '../TextMessages/TextMessagesTab';
import SampleInformationTab from './SampleInformationTab';
import { TabItem, sampleInfoTabItems } from '../../../../constants/tabItems';

type Props = {
  sampleInformations: SampleInformation[];
  textMessages: ExternalInvestigationTextMessage[];
};

const SampleInformationTabs = ({ sampleInformations, textMessages }: Props) => {
  const [activeTab, setActiveTab] = useState(sampleInfoTabItems.sampleInfo.id);

  const onTabChange = (e: React.MouseEvent, id: string) => {
    e.preventDefault();
    setActiveTab(id);
  };

  const tabs = Object.values(sampleInfoTabItems).map((item: TabItem) => (
    <li key={item.id}>
      <a href={`${item.id}`} className={activeTab === item.id ? 'active' : ''} onClick={(e) => onTabChange(e, item.id)}>
        {item.displayName}
      </a>
    </li>
  ));

  return (
    <div className="content group">
      <main role="main" className="col span_12_of_12" style={{ padding: '0', maxHeight: '80%' }}>
        <ul className="nav-tabs">{tabs}</ul>
        {activeTab === sampleInfoTabItems.sampleInfo.id && (
          <SampleInformationTab sampleInformations={sampleInformations} />
        )}
        {activeTab === sampleInfoTabItems.textMessages.id && <TextMessagesTab textMessages={textMessages} />}
      </main>
    </div>
  );
};

export default SampleInformationTabs;

import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { AutoSizer, Column, Table } from 'react-virtualized';
import { connect, ConnectedProps } from 'react-redux';

import { AnyAction, bindActionCreators, Dispatch as ReduxDispatch } from 'redux';
import { algorithmTypes, donorTypes, LoadingMessage, NoResultsFound, reportTypes } from '../../../../../core';
import { PatientSelectors } from '../../../../index';

import UserSelectors from '../../../../../currentUser/redux/selectors';
import { isOddNumber } from '../../../../../core/helpers/arrayHelper';
import type { ApiSearchRequest, ApiSearchRequestSummary } from '../../../../../donorMatchSearchRequests/types/api';
import type { ReduxState } from '../../../../../rootReducer';
import resultSetTypes from './resultSetTypes';
import type { DonorType } from '../../../../../core/types';
import { convertSearchTypeFromApiToNumeric, type ApiSearchType } from '../../../../../core/constants/searchTypes';
import { getSingleSearchRequest } from '../../../../../donorMatchSearchRequests/redux/actions';
import { SearchRequestSelectors } from '../../../../../donorMatchSearchRequests';
import { convertToAlgorithmName } from '../../../helpers/algorithmConverter';

type OwnProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  patientId: string; // used in `mapStateToProps`
};
type StateProps = {
  individualSearchRequestsBeingFetched: string[];
  isSearchAuthoriser: boolean;
  searchRequests?: ApiSearchRequest[];
  // eslint-disable-next-line react/no-unused-prop-types, react/require-default-props
  shouldShowNovaResults?: boolean;
};
type Props = PropsFromRedux & OwnProps & StateProps;
type State = {
  autoRequestFetchingStopped: boolean;
};
type SearchRequestTableData = {
  algorithm: string;
  algorithmCount: number;
  compositeId: string;
  donorType: DonorType;
  id: string;
  registries: string;
  resultsLinkData: {
    requestId: string;
    resultSetStatus: string;
    url: string;
  };
  runDate: string;
  runBy: string;
  adultSearchType: ApiSearchType;
  cordSearchType: ApiSearchType;
  status: string;
  validationUrl: string;
};

const mapStateToProps = (state: ReduxState, ownProps: OwnProps): StateProps => ({
  individualSearchRequestsBeingFetched: SearchRequestSelectors.individualSearchRequestsBeingFetched(state),
  isSearchAuthoriser: UserSelectors.isSearchAuthoriser(state),
  // @ts-expect-error TODO EM-1787: allow typescript to account for combination of dynamic and static keys in PatientSearchReducerState
  searchRequests: PatientSelectors.getPatientSearchRequest(state, ownProps.patientId),
});

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>) => ({
  fetchSearchRequest: bindActionCreators(getSingleSearchRequest, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const formatInternationalRegistries = (donorType: DonorType, search: ApiSearchRequestSummary) => {
  if (donorType === donorTypes.cord.value) {
    return search.EmdisCordRegistriesSearched.length > 0
      ? search.EmdisCordRegistriesSearched.join(' ')
      : 'No international registries';
  }
  return 'N/A';
};

const mapAllSearches = (searchRequests: ApiSearchRequest[]): SearchRequestTableData[] =>
  searchRequests
    .filter(
      (search) =>
        !search.ExternalRegistryDetails ||
        (search.ExternalRegistryDetails && search.ExternalRegistryDetails.length === 0)
    )
    .map((search) =>
      search.ResultSetSummaries.map((resultSet): SearchRequestTableData => {
        const setId = resultSet.SearchResultSetId;
        const donorType = resultSet.DonorType;
        const url = `/donorsearch/${search.Id}/results?resultSetId=${setId}&donorType=${donorType}&reportType=${reportTypes.internal}`;
        const validationUrl = `/donorsearch/${search.Id}/algorithmComparison?donorType=${donorType}`;

        const status = resultSet.AlgorithmUsed === algorithmTypes.solar ? search.Status : resultSet.Status;

        const registries = formatInternationalRegistries(donorType, search);
        return {
          algorithm: convertToAlgorithmName(resultSet.AlgorithmUsed),
          algorithmCount: search.AlgorithmsUsed.length,
          compositeId: `${setId}-${search.Id}`,
          donorType,
          id: search.Id,
          registries,
          resultsLinkData: {
            requestId: search.Id,
            resultSetStatus: resultSet.Status,
            url,
          },
          runBy: search.RequestedByUser,
          runDate: search.SearchRunDate,
          adultSearchType: search.AdultSearchType,
          cordSearchType: search.CordSearchType,
          status,
          validationUrl,
        };
      })
    )
    // $FlowExpectedError - https://github.com/facebook/flow/issues/7397
    .flat();

const validationLink = (compositeId: string, searchRequests: SearchRequestTableData[], isSearchAuthoriser: boolean) => {
  const searchRequest = searchRequests.find((x) => x.compositeId === compositeId);

  if (searchRequest) {
    const { algorithmCount, id, validationUrl } = searchRequest;

    return algorithmCount > 1 && isSearchAuthoriser ? <Link to={validationUrl}>{id}</Link> : searchRequest.id;
  }
  return null;
};

class ResultsTable extends React.PureComponent<Props, State> {
  pollForSearchResultsTimeOut!: NodeJS.Timeout;

  static defaultProps = {
    searchRequests: [],
  };

  state = {
    autoRequestFetchingStopped: false,
  };

  componentDidMount(): void {
    this.pollForSearchResults();
  }

  componentWillUnmount(): void {
    clearTimeout(this.pollForSearchResultsTimeOut);
  }

  render() {
    const { searchRequests, isSearchAuthoriser } = this.props;
    const { autoRequestFetchingStopped } = this.state;

    const resultPageLink = ({ url, resultSetStatus }: { url: string; resultSetStatus: string }) => {
      switch (resultSetStatus) {
        case resultSetTypes.initiated:
          return autoRequestFetchingStopped ? (
            <div style={{ fontSize: 12 }}>
              Not loaded - please {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href="" onClick={() => window.location.reload()}>
                refresh
              </a>
            </div>
          ) : (
            <LoadingMessage isButton />
          );
        case resultSetTypes.failed:
          return null;
        case resultSetTypes.complete:
        default:
          return (
            <Link to={url} className="btn">
              View Results
            </Link>
          );
      }
    };

    const mappedSearchRequests = searchRequests ? mapAllSearches(searchRequests) : [];

    const sortedRequestsByDate: SearchRequestTableData[] = mappedSearchRequests.sort(
      (a, b) => moment(b.runDate).valueOf() - moment(a.runDate).valueOf()
    );

    if (mappedSearchRequests.length === 0) {
      return <NoResultsFound resultType="requests" />;
    }

    return (
      <div style={{ display: 'flex' }}>
        <div style={{ flex: '1 1 auto', height: '400px' }}>
          <AutoSizer>
            {({ width, height }) => (
              <Table
                className="results-table"
                width={width}
                height={height}
                headerHeight={50}
                rowHeight={50}
                rowCount={sortedRequestsByDate.length}
                rowGetter={({ index }) => sortedRequestsByDate[index]}
                rowClassName={({ index }) => (isOddNumber(index) ? 'odd' : 'even')}
              >
                <Column
                  label="Request Id"
                  dataKey="compositeId"
                  width={250}
                  cellRenderer={({ cellData }) => validationLink(cellData, sortedRequestsByDate, isSearchAuthoriser)}
                />
                <Column width={300} label="Status" dataKey="status" />
                <Column
                  width={300}
                  label="Search Type"
                  dataKey="searchType"
                  cellDataGetter={({ rowData }) =>
                    rowData.donorType === donorTypes.cord.value
                      ? convertSearchTypeFromApiToNumeric(rowData.cordSearchType)
                      : convertSearchTypeFromApiToNumeric(rowData.adultSearchType)
                  }
                />
                <Column width={300} label="Donor Type" dataKey="donorType" />
                <Column
                  label="Run Date"
                  dataKey="runDate"
                  width={300}
                  cellRenderer={({ cellData }) => moment(cellData).format('DD-MM-YYYY')}
                />
                <Column label="Run By" dataKey="runBy" width={500} style={{ padding: '10px' }} />
                <Column
                  label="International Registries"
                  dataKey="registries"
                  width={400}
                  cellRenderer={({ cellData }) => cellData}
                />
                <Column
                  width={300}
                  label="Algorithms Used"
                  dataKey="algorithm"
                  cellRenderer={({ cellData }) => cellData}
                />
                <Column
                  width={300}
                  label="View Results"
                  dataKey="resultsLinkData"
                  cellRenderer={({ cellData }) => resultPageLink(cellData)}
                />
              </Table>
            )}
          </AutoSizer>
        </div>
      </div>
    );
  }

  pollForSearchResults() {
    const { searchRequests, fetchSearchRequest, individualSearchRequestsBeingFetched } = this.props;
    if (individualSearchRequestsBeingFetched.some((x) => x)) {
      this.setState({ autoRequestFetchingStopped: true });
      return;
    }
    const mappedSearches = searchRequests ? mapAllSearches(searchRequests) : [];
    const pendingSearches = mappedSearches.filter(
      (s) => s.resultsLinkData.resultSetStatus === resultSetTypes.initiated
    );

    if (pendingSearches.some((x) => x)) {
      pendingSearches.forEach((s) => {
        fetchSearchRequest(s.id);
      });
      this.pollForSearchResultsTimeOut = setTimeout(this.pollForSearchResults.bind(this), 5000);
    }
  }
}

export default connector(ResultsTable);

import React, { CSSProperties, PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch as ReduxDispatch } from 'redux';
import _ from 'lodash';
import Modal, { Styles } from 'react-modal';

import { getAntigenData } from '../../redux/actions';
import Selectors from '../../redux/selectors';
import type { Antigen, Locus } from '../../../core/types/index';
// eslint-disable-next-line import/no-named-as-default
import HlaLookup from '../HlaLookup/HlaLookup';
import {
  getSearchStringFromFilterString,
  isNmdpValue,
  isNullOrWhitespace,
  truncateString,
} from '../../helpers/HlaStringHelpers';
import type { ReduxState } from '../../../rootReducer';
import type { AntigenRequest } from '../../redux/reducer';

const containerStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  paddingTop: '4px',
  paddingBottom: '4px',
};

const containerReadonlyStyle: CSSProperties = {
  justifyContent: 'center',
  textAlign: 'center',
};

const donorContainerStyle = {
  marginRight: '30px',
};

const inputStyle = {
  height: 32,
  paddingLeft: 12,
  fontSize: 16,
  minWidth: 213,
};

const buttonStyle: CSSProperties = {
  minHeight: 0,
  marginLeft: 16,
  paddingRight: 16,
  whiteSpace: 'nowrap',
};

const modalStyle = {
  overlay: {
    zIndex: '99',
  },
};

const selectionHelperTextStyle: CSSProperties = {
  overflow: 'auto',
  wordBreak: 'break-all',
};

const defaultApiAntigenLimit = 200;

type OwnProps = {
  antigenRowId: number;
  // The API will only return a subset of the available antigens for each request, for performance reasons
  apiAntigenLimit?: number;
  // eslint-disable-next-line react/require-default-props
  initialAntigenValue?: Locus;
  locusType: string;
  onChange: (antigenId: string | null | undefined) => void;
  // eslint-disable-next-line react/require-default-props
  isShownOnOverlay?: boolean;
  // eslint-disable-next-line react/require-default-props
  isGias?: boolean;
};
type StateProps = {
  antigens: AntigenRequest;
};
type Props = PropsFromRedux & OwnProps & StateProps;
type State = {
  isLoadingAntigens: boolean;
  isInvalidAntigen: boolean;
  showModal: boolean;
  // The value of the text input as typed
  value: string;
  // The value of the text input that has been searched for on the server - i.e. onBlur
  filterValue: string;
  readonlyValue?: Locus;
};

const mapStateToProps = (state: ReduxState, ownProps: OwnProps): StateProps => ({
  antigens: Selectors.getAntigenRequest(state, ownProps.locusType, ownProps.antigenRowId),
});

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>) => ({
  fetchAntigens: bindActionCreators(getAntigenData, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export class HlaRow extends PureComponent<Props, State> {
  static defaultProps = {
    apiAntigenLimit: defaultApiAntigenLimit,
  };

  constructor(props: Props) {
    super(props);
    const { initialAntigenValue } = this.props;
    this.state = {
      isLoadingAntigens: false,
      isInvalidAntigen: false,
      showModal: false,
      filterValue: '',
      value: initialAntigenValue ? initialAntigenValue.hlaName || '' : '',
      readonlyValue: initialAntigenValue,
    };
  }

  render() {
    const { antigenRowId, antigens, apiAntigenLimit, locusType, isShownOnOverlay, isGias } = this.props;
    const { showModal, value } = this.state;
    return isShownOnOverlay ? (
      <div style={donorContainerStyle}>
        <div style={isGias ? containerReadonlyStyle : containerStyle}>
          {!isGias ? (
            <>
              {this.textField()}
              {this.openModalButton()}
            </>
          ) : (
            this.displayReadonlyLocus()
          )}
          <Modal
            appElement={document.querySelector('#root') as HTMLElement}
            isOpen={showModal}
            shouldCloseOnEsc
            style={modalStyle as Styles}
          >
            <HlaLookup
              antigenRowId={antigenRowId}
              antigens={antigens.data || []}
              apiAntigenLimit={apiAntigenLimit}
              initialValue={value}
              locusType={locusType}
              onSelect={this.handleAntigenLookupSelect}
              onRequestClose={this.handleCloseModal}
            />
          </Modal>
        </div>
        {this.selectionHelperText()}
      </div>
    ) : (
      <div className="patient-genetics">
        <div style={containerStyle}>
          {this.textField()}
          {this.openModalButton()}
          <Modal
            appElement={document.querySelector('#root') as HTMLElement}
            isOpen={showModal}
            shouldCloseOnEsc
            style={modalStyle as Styles}
            onRequestClose={this.handleCloseModal}
          >
            <HlaLookup
              antigenRowId={antigenRowId}
              antigens={antigens.data || []}
              apiAntigenLimit={apiAntigenLimit}
              initialValue={value}
              locusType={locusType}
              onSelect={this.handleAntigenLookupSelect}
              onRequestClose={this.handleCloseModal}
            />
          </Modal>
        </div>
        {this.selectionHelperText()}
      </div>
    );
  }

  handleAntigenLookupSelect = (antigen: Antigen) => {
    this.setState(
      {
        value: antigen.hlaName,
        showModal: false,
      },
      this.validate
    );
  };

  handleOpenModal = () => this.setState({ showModal: true });

  handleCloseModal = () => this.setState({ showModal: false });

  openModalButton = () => (
    <button className="btn" style={buttonStyle} type="button" onClick={this.handleOpenModal}>
      Look up HLA
    </button>
  );

  helperText = (text: string, helperTextStyle: Record<string, unknown> = {}) => {
    const { isShownOnOverlay } = this.props;
    const defaultStyle: CSSProperties = { marginLeft: isShownOnOverlay ? 0 : 20, fontWeight: 'bold' };
    return (
      <div className="helper-text" style={_.merge({}, defaultStyle, helperTextStyle)}>
        {text}
      </div>
    );
  };

  loadingMessage = () => this.helperText('Validating...');

  errorMessage = () => this.helperText('Invalid Antigen Selected', { color: 'red' });

  hlaNameDisplay = (hlaName: string | undefined): JSX.Element | undefined => {
    if (hlaName) {
      return this.helperText(truncateString(hlaName));
    }
    return undefined;
  };

  selectionHelperText = () => {
    const { filterValue, isInvalidAntigen, isLoadingAntigens } = this.state;
    if (filterValue) {
      if (isLoadingAntigens) {
        return <span data-testid="validating-antigen">{this.loadingMessage()}</span>;
      }
      if (isInvalidAntigen) {
        return this.errorMessage();
      }
      const antigen = this.getAntigenMatchingFilterString();
      const hlaName = _.get(antigen, 'hlaName');
      const nmdpString = _.get(antigen, 'nmdpString', '');
      return (
        <div style={selectionHelperTextStyle}>
          {this.helperText(nmdpString)}
          {this.hlaNameDisplay(hlaName)}
        </div>
      );
    }
    return null;
  };

  getAntigenMatchingFilterString = () => {
    const { antigens } = this.props;
    const { filterValue } = this.state;
    const propertyToTest = isNmdpValue(filterValue.replace('*', '')) ? 'nmdpString' : 'hlaName';
    return _.find(
      antigens.data,
      (a) => a[propertyToTest].replace('*', '').toLowerCase() === filterValue.replace('*', '').toLowerCase()
    );
  };

  validate = async () => {
    const { antigenRowId, antigens, apiAntigenLimit, fetchAntigens, locusType, onChange } = this.props;
    const { value: sValue } = this.state;
    const value = getSearchStringFromFilterString(sValue);
    const hlaPrefix = isNmdpValue(value) ? undefined : value;
    const nmdpPrefix = isNmdpValue(value) ? value : undefined;
    // OnChange(null) is called so you can't save while validating.
    onChange(null);
    this.setState({ filterValue: value, isLoadingAntigens: true });
    if (!isNullOrWhitespace(value)) {
      await fetchAntigens(antigenRowId, locusType, hlaPrefix, nmdpPrefix, apiAntigenLimit || defaultApiAntigenLimit);
      if (!antigens.isFetchingLatest) {
        const selectedAntigen = this.getAntigenMatchingFilterString();
        this.setState({
          isLoadingAntigens: false,
          isInvalidAntigen: !selectedAntigen,
        });
        onChange(selectedAntigen ? selectedAntigen.id.toString() : null);
      }
    } else {
      onChange(undefined);
    }
  };

  textField = () => {
    const { antigenRowId, locusType } = this.props;
    const { value } = this.state;
    return (
      <input
        id={`${locusType}${antigenRowId}`}
        style={inputStyle}
        onBlur={this.validate}
        onChange={(e) => this.setState({ value: e.target.value })}
        value={value}
      />
    );
  };

  displayReadonlyLocus = () => {
    const { readonlyValue } = this.state;
    if (readonlyValue) {
      return (
        <div style={selectionHelperTextStyle}>
          {this.helperText(readonlyValue.nmdpString)}
          {this.hlaNameDisplay(readonlyValue.hlaName)}
        </div>
      );
    }
    return '';
  };
}

export default connector(HlaRow);

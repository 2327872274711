import React from 'react';
import { convertToAlgorithmName } from '../../../patient/patientDashboard/helpers/algorithmConverter';
import { styles as commonStyles } from '../../style';

const styles = {
  ...commonStyles,
  dropDownMenuPadding: {
    padding: '0px 12px',
  },
};

type Props = {
  defaultOption: string;
  algorithmsToCompare: string[];
  getUserSelectedAlgorithm: (algorithm: string) => void;
};

class AlgorithmDropDown extends React.Component<Props> {
  render() {
    const { defaultOption, algorithmsToCompare } = this.props;
    return (
      <select value={defaultOption} onChange={this.handleOptionSelect} style={styles.dropDownMenuPadding}>
        {algorithmsToCompare.map((algorithm) => (
          <option key={algorithm} value={algorithm}>
            {convertToAlgorithmName(algorithm)}
          </option>
        ))}
      </select>
    );
  }

  handleOptionSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { getUserSelectedAlgorithm } = this.props;
    event.preventDefault();
    getUserSelectedAlgorithm(event.target.value);
  };
}

export default AlgorithmDropDown;

import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import { LoadingMessage, ErrorMessage, reportTypes } from '../../../../../core/index';
import { SavedReportsSelectors } from '../../../../../searchReports/index';
import SavedReportsTable from './SavedReportsTable';

import type { ErrorMessages } from '../../../../../core/types';
import type { ReduxState } from '../../../../../rootReducer';

type OwnProps = {
  patientId: string;
};
type StateProps = {
  errorMessage: ErrorMessages;
  hasErrored: boolean;
  isFetching: boolean;
};
type Props = PropsFromRedux & OwnProps & StateProps;

const mapStateToProps = (state: ReduxState): StateProps => ({
  errorMessage: SavedReportsSelectors.getReportErrorMessage(state),
  hasErrored: SavedReportsSelectors.hasFetchReportsErrored(state),
  isFetching: SavedReportsSelectors.isFetchingReports(state),
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const SavedReportsTab = ({ patientId, errorMessage, hasErrored, isFetching }: Props) => (
  <div>
    {hasErrored ? (
      <div style={{ marginTop: '10px' }}>
        <ErrorMessage errorMessages={errorMessage} />
      </div>
    ) : (
      <div className="results-table results-table--border fixed-width">
        <Link to={`/patient/${patientId}/reports/new?reportType=${reportTypes.internal}`} className="btn btn--inline">
          New UK Search Report
        </Link>
        <Link
          to={`/patient/${patientId}/reports/new?reportType=${reportTypes.international}`}
          className="btn btn--inline"
        >
          New International Search Report
        </Link>
        <h2 className="border-bottom-solid">Search Reports</h2>
        <LoadingMessage isLoading={isFetching}>
          <SavedReportsTable patientId={patientId} />
        </LoadingMessage>
      </div>
    )}
  </div>
);

export default connector(SavedReportsTab);

import config from '../../config';

type TruncateStringParams = {
  maxLength?: number;
  divider?: string;
};

const defaultMaxLength = config().defaultHlaEditingStringMaxLength;

const isNmdpValue = (inputValue: string) =>
  inputValue && inputValue.includes(':') && /[a-zA-Z]/.test(inputValue.split(':')[1].charAt(0));

const shouldTruncate = (string: string, maxLength: number = defaultMaxLength) => string && string.length > maxLength;

const truncateString = (
  string: string,
  { maxLength = defaultMaxLength, divider = ' ..... ' }: TruncateStringParams = {}
) => {
  if (!shouldTruncate(string, maxLength)) {
    return string;
  }
  const numberToShowEachEndOfTruncatedString = maxLength / 2;
  const firstHalf = string.substr(0, string.length / 2);
  const lastHalf = string.substr(string.length / 2, string.length - 1);
  return `${firstHalf.slice(0, numberToShowEachEndOfTruncatedString)}${divider}${lastHalf.slice(
    -1 * numberToShowEachEndOfTruncatedString
  )}`;
};

// prefix with asterisk if allele (contains :) and * not manually typed
const getSearchStringFromFilterString = (inputValue: string) => {
  const shouldAppendAsterisk =
    inputValue && inputValue.includes(':') && inputValue.length > 0 && inputValue.charAt(0) !== '*';
  return shouldAppendAsterisk ? `*${inputValue}` : inputValue;
};

const isNullOrWhitespace = (string: string) => !string || /^\s*$/.test(string);

export { isNmdpValue, shouldTruncate, truncateString, getSearchStringFromFilterString, isNullOrWhitespace };

import * as React from 'react';

type Props = {
  className: string;
  cellId: string;
  cellStyle: React.CSSProperties;
  children: React.ReactNode | unknown;
};

const DonorTableCellDisplay = (props: Props) => {
  const { className, cellId, cellStyle, children } = props;
  return (
    <div className={className} key={cellId} style={cellStyle}>
      {children as React.ReactNode}
    </div>
  );
};

export default DonorTableCellDisplay;

import _ from 'lodash';
import { SubjectLinkExpanded } from '../types';

export const isMainLink = (link: SubjectLinkExpanded, subjectId: string) => {
  const { Donor, Patient } = link;
  if (Donor.MainLinkedPatientId) {
    return Donor.MainLinkedPatientId === subjectId || Donor.MainLinkedPatientId === Patient.Id;
  }
  return false;
};

const orderLinks = (links: SubjectLinkExpanded[], property: string) => _.orderBy(links, property, 'desc');

export const getOrderedDonorLinks = (links: SubjectLinkExpanded[], subjectId: string) => {
  const orderLinksById = orderLinks(links, 'Id');
  const isMainLinkedPatientId = orderLinksById.some(
    (link: SubjectLinkExpanded) => (link.Donor.MainLinkedPatientId || '') === subjectId
  );

  if (isMainLinkedPatientId) {
    return orderLinks(
      links.map((link: SubjectLinkExpanded) => ({
        ...link,
        mainLinkedPatientId: (link.Donor.MainLinkedPatientId || '') === subjectId,
      })),
      'mainLinkedPatientId'
    );
  }
  return orderLinksById;
};

import _ from 'lodash';
import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch as ReduxDispatch } from 'redux';
import * as actions from '../../../redux/actions';
import CordRequestOptions from '../CordRequestOptions';
import SearchAlgorithms from '../SearchAlgorithms';
import SearchPreferences from '../SearchPreferences';
import SearchType from '../SearchType';
import Selectors from '../../../redux/selectors';
import type { ReduxState } from '../../../../../rootReducer';

import type { PatientDashboardReducerState } from '../../../redux/reducer';

import { searchTypesDetails } from '../../../../../core/constants/searchTypes';
import { Button } from '../../../../../core/components/Button';

type OwnProps = {
  onSubmit: () => void;
  onClose: (e: React.MouseEvent<HTMLButtonElement>) => void;
  patientId: string;
};
type StateProps = {
  patientStatus: string;
  searchRequestAlgorithms: string[];
  searchRequestData: PatientDashboardReducerState;
};
type Props = PropsFromRedux & OwnProps & StateProps;

const mapStateToProps = (state: ReduxState, ownProps: OwnProps): StateProps => ({
  searchRequestData: Selectors.getSearchRequestData(state),
  searchRequestAlgorithms: Selectors.getSearchAlgorithms(state),
  patientStatus: Selectors.getPatientStatus(state, ownProps.patientId),
});

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>) => ({
  createSearchRequest: bindActionCreators(actions.createSearchRequest, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type State = {
  error?: string;
};

export class NewSearchRequestForm extends PureComponent<Props, State> {
  state = { error: undefined };

  render() {
    const { error } = this.state;
    const { patientId, searchRequestData, searchRequestAlgorithms, onClose } = this.props;
    const { isCreatingRequest } = searchRequestData;

    const selectedSearchType = _.values(searchTypesDetails).find((t) => t.value === searchRequestData.adultSearchType);

    const isSearchTypeUnavailable =
      searchRequestAlgorithms.includes('Solar') && !_.get(selectedSearchType, 'isPossibleForSolarSearch', false);

    const isDisabled = !searchRequestAlgorithms.some((a) => a) || isSearchTypeUnavailable;
    const btnDisabled = isDisabled ? 'btn--disabled' : '';

    const { patientStatus } = this.props;
    const isPatientClosed = patientStatus === 'Closed';

    return (
      <form id="uk-search-form" onSubmit={this.submitSearchRequestForm} className="popUpForm">
        <h2 className="border-bottom-solid">Create New Search Request</h2>
        <div className="newSearchRequestPopUp">
          {isPatientClosed && <span className="warning-message">This patient&apos;s record has been closed</span>}
          <SearchType />
          <SearchAlgorithms />
          <CordRequestOptions />
          <SearchPreferences patientId={patientId} />
          <div className="btn-actions">
            <button
              id="search-form-btn"
              type="submit"
              className={`btn btn--inline ${btnDisabled}`}
              disabled={isDisabled || isCreatingRequest}
            >
              {isCreatingRequest ? 'Creating...' : 'Create Search Request'}
            </button>
            <Button buttonClass="btn btn--inline btn--secondary" text="Close" onClick={onClose} />
          </div>
          {error && (
            <div className="error-message" data-testid="error">
              <span>{error}</span>
            </div>
          )}
        </div>
      </form>
    );
  }

  submitSearchRequestForm = async (event: React.MouseEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { createSearchRequest, onSubmit, patientId, searchRequestAlgorithms, searchRequestData } = this.props;

    const message = await createSearchRequest({
      emdisCordCategories: searchRequestData.cordEmdisRegistries,
      includeAlignedRegistries: searchRequestData.includeAlignedRegistries,
      isACordSearch: searchRequestData.isACordSearch,
      isHlaAdjusted: searchRequestData.isHlaAdjusted,
      patientHlaIds: searchRequestData.patientHlaIds,
      patientId,
      searchAlgorithms: searchRequestAlgorithms,
      adultSearchType: searchRequestData.adultSearchType!,
      includeDpb1ScoringInNovaSearch: searchRequestData.includeDpb1InScoring,
    });
    if (!message) {
      onSubmit();
      return;
    }
    this.setState({ error: message as unknown as string });
  };
}

export default connector(NewSearchRequestForm);

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import CreateInvestigationButton from './NewInvestigation/CreateInvestigationButton';
import { Error } from '../../../core';
import ExistingSubjectInvestigations from './ExistingInvestigations/ExistingSubjectInvestigations';
import SubjectSelectors from '../../redux/selectors';
import * as SubjectActions from '../../redux/actions';
import { getSubjectSearchTypesDisplayString, subjectSearchTypes } from '../../constants/subjectSearchTypes';

import type { Subject, SubjectType } from '../../types';
import { ReduxState } from '../../../rootReducer';
import { getSelectableDispatchMethodOptions } from '../../constants/dispatchMethods';

type Props = {
  subject?: Subject;
};

const SubjectInvestigationsScreen = ({ subject }: Props) => {
  const params = useParams<{ patientId: string; investigationId: string; donorId: string }>();

  const isPatient = params.patientId;
  const subjectString: SubjectType = isPatient ? subjectSearchTypes.patient.id : subjectSearchTypes.donor.id;

  const dispatch = useDispatch();

  const [selectedInvestigation, setSelectedInvestigation] = useState<number | null>(null);

  const isLoadingInvestigations = useSelector((state: ReduxState) => SubjectSelectors.isLoadingInvestigations(state));
  const isLoadingSubject = useSelector((state: ReduxState) => SubjectSelectors.isLoadingSubject(state));
  const subjectInvestigations = useSelector((state: ReduxState) => SubjectSelectors.getSubjectInvestigations(state));
  const investigationError = useSelector((state: ReduxState) => SubjectSelectors.getLoadingInvestigationsError(state));

  const getDonorRecordString = () => {
    // @ts-expect-error - NOVA-8986: error due to Subject union type
    if (subject && subject.cordId) {
      return getSubjectSearchTypesDisplayString(subjectSearchTypes.cord.id);
    }
    return getSubjectSearchTypesDisplayString(subjectSearchTypes.donor.id);
  };
  const recordTypeString =
    subject && (isPatient ? getSubjectSearchTypesDisplayString(subjectSearchTypes.patient.id) : getDonorRecordString());

  const subjectId = isPatient ? params.patientId : params.donorId;
  // @ts-expect-error - NOVA-8986: error due to Subject union type
  const linkedPatientId = subject && subject.LinkedPatientId;

  const selectedInvestigationDetails =
    subjectInvestigations &&
    subjectInvestigations.find((investigation) => investigation.InvestigationId === selectedInvestigation);

  const isLoading = isLoadingSubject || isLoadingInvestigations;
  getSelectableDispatchMethodOptions();

  useEffect(() => {
    if (params.investigationId) {
      setSelectedInvestigation(parseInt(params.investigationId, 10));
    }
  }, [params.investigationId]);

  useEffect(() => {
    if (subject) {
      dispatch(SubjectActions.getInvestigations(subjectId));
    }
  }, [subject, subjectId]);

  useEffect(() => {
    if (selectedInvestigationDetails?.SampleId) {
      dispatch(SubjectActions.getInvestigationOriginalSample(selectedInvestigationDetails.SampleId.toString()));
    }
  }, [selectedInvestigationDetails]);

  return (
    <div className="panel">
      <div className="btn--new-investigation">
        {!isPatient && (
          <CreateInvestigationButton
            linkedPatientId={linkedPatientId}
            setSelectedInvestigation={setSelectedInvestigation}
            subjectId={subjectId}
            subjectRecordType={recordTypeString}
            subjectType={subjectString}
          />
        )}
      </div>
      <h2 className="border-bottom-solid" data-testid="subject-investigations-page-heading">
        Investigations for {recordTypeString} {subjectId}
      </h2>
      {isLoading ? (
        <div className="loader small">&nbsp;</div>
      ) : (
        <ExistingSubjectInvestigations
          setSelectedInvestigation={setSelectedInvestigation}
          selectedInvestigation={selectedInvestigation}
          subjectRecordType={recordTypeString}
          subject={subject}
          subjectId={subjectId}
          selectedInvestigationDetails={selectedInvestigationDetails}
          investigationError={investigationError}
        />
      )}
    </div>
  );
};

SubjectInvestigationsScreen.defaultProps = {
  subject: undefined,
};

export default SubjectInvestigationsScreen;

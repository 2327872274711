import type {
  ClosePatientErrorAction,
  ClosePatientRequestAction,
  ClosePatientSuccessAction,
  FetchPatientClosureReasonsErrorAction,
  FetchPatientClosureReasonsRequestAction,
  FetchPatientClosureReasonsSuccessAction,
  OpenPatientErrorAction,
  OpenPatientRequestAction,
  OpenPatientSuccessAction,
} from './actions';
// eslint-disable-next-line import/no-cycle
import { Actions } from './actions';
// eslint-disable-next-line import/no-cycle

type UserActions =
  | ClosePatientErrorAction
  | ClosePatientRequestAction
  | ClosePatientSuccessAction
  | FetchPatientClosureReasonsErrorAction
  | FetchPatientClosureReasonsRequestAction
  | FetchPatientClosureReasonsSuccessAction
  | OpenPatientErrorAction
  | OpenPatientRequestAction
  | OpenPatientSuccessAction;

export type ChangePatientStatusReducerState = {
  patientClosureReasons?: Array<string>;
  isFetchingClosureReasons: boolean;
  fetchClosureReasonsError?: string;
  isClosingPatient: boolean;
  closePatientError?: string;
  isOpeningPatient: boolean;
  openPatientError?: string;
};

const initialState: ChangePatientStatusReducerState = {
  patientClosureReasons: undefined,
  isFetchingClosureReasons: false,
  fetchClosureReasonsError: undefined,
  isClosingPatient: false,
  closePatientError: undefined,
  isOpeningPatient: false,
  openPatientError: undefined,
};

const reducers = (
  state: ChangePatientStatusReducerState = initialState,
  action: UserActions
): ChangePatientStatusReducerState => {
  switch (action.type) {
    case Actions.FETCH_PATIENT_CLOSURE_REASONS_REQUEST:
      return {
        ...state,
        isFetchingClosureReasons: true,
      };
    case Actions.FETCH_PATIENT_CLOSURE_REASONS_SUCCESS:
      return {
        ...state,
        isFetchingClosureReasons: false,
        fetchClosureReasonsError: undefined,
        patientClosureReasons: action.payload.data,
      };
    case Actions.FETCH_PATIENT_CLOSURE_REASONS_ERROR:
      return {
        ...state,
        isFetchingClosureReasons: false,
        fetchClosureReasonsError: action.payload.Error,
      };
    case Actions.CLOSE_PATIENT_REQUEST:
      return {
        ...state,
        isClosingPatient: true,
      };
    case Actions.CLOSE_PATIENT_ERROR:
      return {
        ...state,
        isClosingPatient: false,
        closePatientError: action.payload.response.body.Error,
      };
    case Actions.CLOSE_PATIENT_SUCCESS:
      return {
        ...state,
        isClosingPatient: false,
        closePatientError: undefined,
      };
    case Actions.OPEN_PATIENT_REQUEST:
      return {
        ...state,
        isOpeningPatient: true,
      };
    case Actions.OPEN_PATIENT_ERROR:
      return {
        ...state,
        isOpeningPatient: false,
        openPatientError: action.payload.response.body.Error,
      };
    case Actions.OPEN_PATIENT_SUCCESS:
      return {
        ...state,
        isOpeningPatient: false,
        openPatientError: undefined,
      };
    default:
      return state;
  }
};

export default reducers;

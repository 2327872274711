import React, { CSSProperties } from 'react';
import { Link } from 'react-router-dom';

import defaultTab from '../../constants/defaultPatientDashboardTab';
import Drb345DisplayContainer from '../Drb345DisplayContainer';
import HlaPairDisplay from '../HlaPairDisplay';
import commonStyles, { Styles as CommonStyles } from '../Styles';

import type { PatientInfo } from '../../../types';
import type { SearchRequestSummary } from '../../../../donorMatchSearchRequests/types';
import { getValueOrDefault } from '../../../../core/helpers/stringValueFormatter';

type Props = {
  patient: PatientInfo;
  searchedHla?: SearchRequestSummary;
};

type Styles = CommonStyles & {
  lineBreak: CSSProperties;
  patientLink: CSSProperties;
};

const styles: Styles = {
  ...commonStyles,
  lineBreak: {
    clear: 'both',
    marginLeft: '0',
    borderTop: '1px solid #ddd',
  },
  patientLink: { textAlign: 'left', paddingLeft: '10px' },
};

const DetailedPatientRenderer = ({ searchedHla, patient }: Props): JSX.Element => {
  const shouldShowSearchedHla =
    searchedHla &&
    searchedHla.patientHla &&
    (searchedHla.isHlaAdjusted || !searchedHla.searchedHlaMatchCurrentPatientHla);

  return (
    <div>
      <table style={styles.table}>
        <thead style={styles.tableHeader}>
          <tr
            style={{
              ...styles.headerRow,
              ...styles.patientCardHeaderNameArea,
              ...styles.centeredTableData,
            }}
          >
            <th style={styles.patientLink}>
              <Link to={`/patient/${patient.id}/${defaultTab}`}>
                <h4>
                  {patient.name} ({patient.id})
                </h4>
              </Link>
            </th>
            {shouldShowSearchedHla ? <th>&nbsp;</th> : null}
            <th>A</th>
            <th>B</th>
            <th>C</th>
            <th>DRB1</th>
            <th>DRB3/4/5</th>
            <th>DQA1</th>
            <th>DQB1</th>
            <th>DPA1</th>
            <th>DPB1</th>
            <th>
              ABO
              <br />
              RhD
            </th>
            <th>CMV</th>
          </tr>
        </thead>
        <tbody>
          <tr style={styles.centeredTableData}>
            <td
              id="corePatientInfo"
              rowSpan={shouldShowSearchedHla ? 2 : 1}
              style={{
                ...styles.textContainer,
                ...styles.titleLeft,
              }}
            >
              Ext Ref: {getValueOrDefault(patient.originatingRegistryPatientId)}
              <br />
              Gender: {getValueOrDefault(patient.gender, 'Unknown')}
              <br />
              Date of Birth: {patient.dateOfBirth ? patient.dateOfBirth.format('DD/MM/YYYY') : 'N/A'} (Age{' '}
              {patient.age !== 0 ? `${patient.age}` : 'N/A'}
              ) <br />
              Ethnicity: {getValueOrDefault(patient.ethnicity)}
              <br />
              Weight: {getValueOrDefault(patient.weight)}
              <br />
            </td>
            {shouldShowSearchedHla ? <td>Patient</td> : null}
            <td id="hlaA">
              <HlaPairDisplay locus1={patient.hlaA1} locus2={patient.hlaA2} />
            </td>
            <td id="hlaB">
              <HlaPairDisplay locus1={patient.hlaB1} locus2={patient.hlaB2} />
            </td>
            <td id="hlaC">
              <HlaPairDisplay locus1={patient.hlaC1} locus2={patient.hlaC2} />
            </td>
            <td id="hlaDrb1">
              <HlaPairDisplay locus1={patient.drB11} locus2={patient.drB12} />
            </td>
            <td id="hlaDrb3/4/5">
              <Drb345DisplayContainer
                drB31={patient.drB31}
                drB32={patient.drB32}
                drB41={patient.drB41}
                drB42={patient.drB42}
                drB51={patient.drB51}
                drB52={patient.drB52}
              />
            </td>
            <td id="hlaDqa1">
              <HlaPairDisplay locus1={patient.dqA11} locus2={patient.dqA12} />
            </td>
            <td id="hlaDqb1">
              <HlaPairDisplay locus1={patient.dqB11} locus2={patient.dqB12} />
            </td>
            <td id="hlaDpa1">
              <HlaPairDisplay locus1={patient.dpA11} locus2={patient.dpA12} />
            </td>
            <td id="hlaDpb1">
              <HlaPairDisplay locus1={patient.dpB11} locus2={patient.dpB12} />
            </td>
            <td id="bloodTypeAndRhesus">
              {getValueOrDefault(patient.abo, 'Unknown')}
              <br />
              {getValueOrDefault(patient.rhd, 'Unknown')}
            </td>
            <td id="cmvType">{getValueOrDefault(patient.cmv, 'Unknown')}</td>
          </tr>
          {shouldShowSearchedHla && searchedHla ? (
            <tr
              style={{
                ...styles.centeredTableData,
                ...styles.lineBreak,
              }}
            >
              <td>Searched HLA</td>
              <td>
                <HlaPairDisplay locus1={searchedHla.patientHla.hlaA1} locus2={searchedHla.patientHla.hlaA2} />
              </td>
              <td>
                <HlaPairDisplay locus1={searchedHla.patientHla.hlaB1} locus2={searchedHla.patientHla.hlaB2} />
              </td>
              <td>
                <HlaPairDisplay locus1={searchedHla.patientHla.hlaC1} locus2={searchedHla.patientHla.hlaC2} />
              </td>
              <td>
                <HlaPairDisplay locus1={searchedHla.patientHla.drB11} locus2={searchedHla.patientHla.drB12} />
              </td>
              <td>
                <Drb345DisplayContainer
                  drB31={searchedHla.patientHla.drB31}
                  drB32={searchedHla.patientHla.drB32}
                  drB41={searchedHla.patientHla.drB41}
                  drB42={searchedHla.patientHla.drB42}
                  drB51={searchedHla.patientHla.drB51}
                  drB52={searchedHla.patientHla.drB52}
                />
              </td>
              <td>
                <HlaPairDisplay locus1={searchedHla.patientHla.dqA11} locus2={searchedHla.patientHla.dqA12} />
              </td>
              <td>
                <HlaPairDisplay locus1={searchedHla.patientHla.dqB11} locus2={searchedHla.patientHla.dqB12} />
              </td>
              <td>
                <HlaPairDisplay locus1={searchedHla.patientHla.dpA11} locus2={searchedHla.patientHla.dpA12} />
              </td>
              <td>
                <HlaPairDisplay locus1={searchedHla.patientHla.dpB11} locus2={searchedHla.patientHla.dpB12} />
              </td>
              <td />
              <td />
            </tr>
          ) : null}
        </tbody>
      </table>
    </div>
  );
};

DetailedPatientRenderer.defaultProps = {
  searchedHla: undefined,
};

export default DetailedPatientRenderer;

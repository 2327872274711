import { CSSProperties } from 'react';
import { styles as commonStyles, Styles as CommonStyles } from '../../../style';

export type Styles = CommonStyles & {
  centeredTableData: CSSProperties;
  table: CSSProperties;
  headerRow: CSSProperties;
  titleLeft: CSSProperties;
  patientCardHeaderNameArea: CSSProperties;
  cardHeaderExtraHieght: CSSProperties;
};

const styles: Styles = {
  ...commonStyles,
  centeredTableData: {
    textAlign: 'center',
  },
  table: {
    width: '100%',
    textAlign: 'left',
    fontSize: '15px',
    borderCollapse: 'collapse',
  },
  headerRow: {
    borderBottom: '1px solid rgb(221,221,221)',
  },
  titleLeft: {
    textAlign: 'left',
  },
  patientCardHeaderNameArea: {
    textAlign: 'start',
    paddingLeft: '16px',
    maxWidth: '90px',
    backgroundColor: '#575749',
  },
  cardHeaderExtraHieght: {
    minHeight: '47px',
    height: '47px',
    fontSize: '16px',
  },
};

export default styles;

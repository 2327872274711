import type { Response } from '@an/nova-frontend-rest-client';
import _ from 'lodash';
import moment from 'moment';
import React, { ReactNode, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { connect, ConnectedProps } from 'react-redux';
import Select, { SingleValue } from 'react-select';
import { AnyAction, bindActionCreators, Dispatch as ReduxDispatch } from 'redux';
import ExpandButton from '../../../../core/components/ExpandButton';
import { externalInvestigationTypes } from '../../../../core/constants/externalInvestigationTypes';
import type { ReduxState } from '../../../../rootReducer';
import CoreSelectors from '../../../../patient/core/redux/selectors';
import '../externalInvestigationsStyling.scss';
import * as actions from '../redux/actions';
import Selectors from '../redux/selectors';
import formatRemark from '../../../../core/helpers/remarkFormatter';
import type {
  AcceptedDays,
  BloodRequirementType,
  ExternalInvestigationVTSelectedDonor,
  VTReceptionDates,
  VTRequirement,
  SelectedDonorAndVTRequestOptions,
  LaboratoryDropdownOption,
  Laboratory,
} from '../types';
import BloodRequirementsTable from './BloodRequirementsTable';
import IndividualNewVTRequest from './IndividualNewVTRequest';
import ReceptionDates from './ReceptionDates';
import WeekdaysForReception from './WeekdaysForReception';
import { PatientState } from '../../../../patient/types';
import { splitDonorsErrorMessages } from '../helpers/splitDonorsErrorMessages';
import { Button } from '../../../../core/components/Button';

type OwnProps = {
  onPopUpClose: () => void;
  onClose: (e: React.MouseEvent<HTMLButtonElement>) => void;
  patientId: string;
  vtSelectedDonorsInitialState: ExternalInvestigationVTSelectedDonor[];
  laboratoryOptions?: Laboratory[];
};
type StateProps = {
  isCreatingVTRequests: boolean;
  patient: PatientState;
};
type Props = PropsFromRedux & OwnProps & StateProps;

type BloodRequirementsValidationStatus = {
  edta: boolean;
  acd: boolean;
  heparin: boolean;
  clotted: boolean;
};

const mapStateToProps = (state: ReduxState, ownProps: OwnProps): StateProps => ({
  isCreatingVTRequests: Selectors.isCreatingVTRequests(state),
  // @ts-expect-error TODO EM-1787: allow typescript to account for combination of dynamic and static keys in PatientSearchReducerState
  patient: CoreSelectors.getPatient(state, ownProps.patientId),
});

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>) => ({
  createVTRequests: bindActionCreators(actions.createVTRequests, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const DEFAULT_EARLIEST_DATE = moment().utc().startOf('day').add(2, 'days');

const areRequirementsValid = (bloodRequirement: VTRequirement) => {
  const { tubes, quantity } = bloodRequirement;

  return (tubes == null && quantity == null) || (tubes != null && quantity != null && tubes > 0 && quantity > 0);
};

const NewVTRequests = (props: Props) => {
  const { isCreatingVTRequests, patientId, onClose, vtSelectedDonorsInitialState, patient, laboratoryOptions } = props;
  const [bloodRequirements, setBloodRequirements] = useState<BloodRequirementType>({
    edta: { quantity: undefined, tubes: undefined },
    acd: { quantity: undefined, tubes: undefined },
    heparin: { quantity: undefined, tubes: undefined },
    clotted: { quantity: undefined, tubes: undefined },
  });
  const [bloodRequirementsValidationStatus, setBloodRequirementsValidationStatus] =
    useState<BloodRequirementsValidationStatus>({ edta: true, acd: true, heparin: true, clotted: true });
  const [weekdaysForReception, setWeekDaysForReception] = useState<AcceptedDays>({
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: false,
    saturday: false,
    sunday: false,
  });
  const [receptionDates, setReceptionDates] = useState<VTReceptionDates>({
    earliest: DEFAULT_EARLIEST_DATE,
    latest: undefined,
  });
  const [receivingInternationalInstitutionCode, setReceivingInternationalInstitutionCode] = useState<
    string | undefined
  >(undefined);
  const [errors, setErrors] = useState<ReactNode>(undefined);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [vtSelectedDonors, setVTSelectedDonors] =
    useState<ExternalInvestigationVTSelectedDonor[]>(vtSelectedDonorsInitialState);
  const { externalLaboratoryName } = patient.patientInfo;
  const laboratoryDropdownOptions: LaboratoryDropdownOption[] = _.values(laboratoryOptions).map((lab) => ({
    label: lab.name,
    value: lab.internationalInstitutionCode,
    id: lab.id,
    defaultBloodSampleRequirements: lab.defaultBloodSampleRequirements,
  }));

  const [laboratoryDropdownOption, setLaboratoryDropdownOption] = useState<LaboratoryDropdownOption | undefined>(
    undefined
  );

  useEffect(() => {
    if (laboratoryOptions) {
      setLaboratoryDropdownOption(_.find(laboratoryDropdownOptions, (x) => x.label === externalLaboratoryName));
    }
  }, [laboratoryOptions]);

  useEffect(() => {
    if (laboratoryDropdownOption) {
      setBloodRequirements({
        edta: laboratoryDropdownOption.defaultBloodSampleRequirements.edta,
        acd: laboratoryDropdownOption.defaultBloodSampleRequirements.acd,
        heparin: laboratoryDropdownOption.defaultBloodSampleRequirements.heparin,
        clotted: laboratoryDropdownOption.defaultBloodSampleRequirements.clotted,
      });
      setReceivingInternationalInstitutionCode(laboratoryDropdownOption.value);
      setBloodRequirementsValidationStatus({
        edta: areRequirementsValid(laboratoryDropdownOption.defaultBloodSampleRequirements.edta),
        acd: areRequirementsValid(laboratoryDropdownOption.defaultBloodSampleRequirements.acd),
        heparin: areRequirementsValid(laboratoryDropdownOption.defaultBloodSampleRequirements.heparin),
        clotted: areRequirementsValid(laboratoryDropdownOption.defaultBloodSampleRequirements.clotted),
      });
    }
  }, [laboratoryDropdownOption]);

  const requirementsNotEntered =
    !bloodRequirements.edta.quantity &&
    !bloodRequirements.acd.quantity &&
    !bloodRequirements.heparin.quantity &&
    !bloodRequirements.clotted.quantity;
  const requirementsInvalid =
    !bloodRequirementsValidationStatus.edta ||
    !bloodRequirementsValidationStatus.acd ||
    !bloodRequirementsValidationStatus.heparin ||
    !bloodRequirementsValidationStatus.clotted;
  const donorsWithNoGrids = vtSelectedDonorsInitialState.filter((donor) => !donor.grid).length >= 1;
  const isDisabled =
    requirementsNotEntered ||
    requirementsInvalid ||
    !receptionDates.earliest ||
    !receivingInternationalInstitutionCode ||
    donorsWithNoGrids;

  const handleReceivingInstitutionChange = (laboratory: SingleValue<LaboratoryDropdownOption>) => {
    if (!laboratory?.id) {
      setBloodRequirements({
        edta: { quantity: undefined, tubes: undefined },
        acd: { quantity: undefined, tubes: undefined },
        heparin: { quantity: undefined, tubes: undefined },
        clotted: { quantity: undefined, tubes: undefined },
      });
      setReceivingInternationalInstitutionCode(laboratory?.value);
    } else {
      setLaboratoryDropdownOption(laboratory);
      setBloodRequirements({
        edta: laboratory.defaultBloodSampleRequirements.edta,
        acd: laboratory.defaultBloodSampleRequirements.acd,
        heparin: laboratory.defaultBloodSampleRequirements.heparin,
        clotted: laboratory.defaultBloodSampleRequirements.clotted,
      });
      setReceivingInternationalInstitutionCode(laboratory.value);
      setBloodRequirementsValidationStatus({
        edta: areRequirementsValid(laboratory.defaultBloodSampleRequirements.edta),
        acd: areRequirementsValid(laboratory.defaultBloodSampleRequirements.acd),
        heparin: areRequirementsValid(laboratory.defaultBloodSampleRequirements.heparin),
        clotted: areRequirementsValid(laboratory.defaultBloodSampleRequirements.clotted),
      });
    }
  };

  const updateReceptionDates = (newReceptionDates: VTReceptionDates) => {
    setReceptionDates(newReceptionDates);
  };

  const updateWeekdays = (day: string) => {
    setWeekDaysForReception({
      ...weekdaysForReception,
      [day]: !weekdaysForReception[day as keyof AcceptedDays],
    });
  };

  const updateBloodRequirements = (
    bloodRequirementType: string,
    requirement: string,
    input: number | null | undefined
  ) => {
    const newRequirements = {
      ...bloodRequirements[bloodRequirementType],
      [requirement]: input,
    };
    setBloodRequirements({
      ...bloodRequirements,
      [bloodRequirementType]: {
        ...bloodRequirements[bloodRequirementType],
        [requirement]: input,
      },
    });
    setBloodRequirementsValidationStatus({
      ...bloodRequirementsValidationStatus,
      [bloodRequirementType]: areRequirementsValid(newRequirements),
    });
  };

  const updateExtraInfo = (requirement: string, donorId: string) => {
    const index = vtSelectedDonors.findIndex((donor) => donor.id === donorId);
    setVTSelectedDonors(
      vtSelectedDonors.map((donor) =>
        donor.id === donorId
          ? {
              ...donor,
              // The reason to use an index signature is that in CheckboxOptions,
              // the 'acknowledgementRequired' option will be a future implementation
              [requirement]: !vtSelectedDonors[index][requirement as keyof ExternalInvestigationVTSelectedDonor],
            }
          : donor
      )
    );
  };

  const updateRemark = (remark: string, donorId: string) => {
    setVTSelectedDonors(vtSelectedDonors.map((donor) => (donor.id === donorId ? { ...donor, remark } : donor)));
  };

  const submitVTRequestForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { createVTRequests, onPopUpClose } = props;
    const { edta, acd, heparin, clotted } = bloodRequirements;

    const verificationTypingRequestDetailsAndDonors: SelectedDonorAndVTRequestOptions[] = vtSelectedDonors.map(
      (donor) => {
        const formattedRemark = formatRemark(donor.remark);
        return {
          originatingRegistry: donor.originatingRegistry,
          id: donor.id,
          grid: donor.grid,
          remark: formattedRemark ?? '',
          edta,
          acd,
          heparin,
          clotted,
          urgent: donor.urgent,
          receptionDates,
          weekdaysForReception,
          receivingInternationalInstitutionCode,
        };
      }
    );
    // @ts-expect-error: Not recognising async action
    const response: Response<any> = await createVTRequests(patientId, verificationTypingRequestDetailsAndDonors);
    if (response.response.ok) {
      onPopUpClose();
    } else {
      setErrors(response.response.body ? splitDonorsErrorMessages(response.response.body.error) : 'Unknown error');
    }
  };

  const toggleExpansion = (): void => {
    setIsExpanded(!isExpanded);
  };

  const displaySelectedDonors = vtSelectedDonors.map((donor: ExternalInvestigationVTSelectedDonor) => (
    <IndividualNewVTRequest
      selectedDonor={donor}
      patientId={patientId}
      updateExtraInfo={updateExtraInfo}
      updateRemark={updateRemark}
      urgent={donor.urgent}
      remark={donor.remark}
      key={donor.grid}
    />
  ));

  return (
    <div className="formContainer">
      <h2 className="border-bottom-solid">Create New {externalInvestigationTypes.vt.name} Requests</h2>

      <form autoComplete="off" onSubmit={submitVTRequestForm} className="popUpForm">
        <div className="col span_12_of_12 receivingInstitutionDropdown">
          <p className="col span_4_of_12" style={{ padding: '12px 0' }}>
            Receiving Institution
            <span style={{ color: '#e36060' }}>*</span>{' '}
          </p>
          <div className="col span_8_of_12">
            <Select
              isClearable={false}
              options={laboratoryDropdownOptions}
              onChange={handleReceivingInstitutionChange}
              className="react-select-container"
              classNamePrefix="react-select"
              placeholder="Select instutition..."
              value={laboratoryDropdownOption}
            />
          </div>
        </div>
        <div className="newIDMRequestPopUp">
          <div style={{ borderBottom: '1px solid #dee2e6' }}>
            {isExpanded ? (
              <div>
                <div
                  style={{
                    display: 'flex',
                    paddingBottom: '3%',
                  }}
                  className="col span_12_of_12"
                >
                  <BloodRequirementsTable
                    requestDetails={bloodRequirements}
                    bloodRequirementsValidationStatus={bloodRequirementsValidationStatus}
                    updateBloodRequirements={updateBloodRequirements}
                  />
                  <div className="col span_5_of_12">
                    <ReceptionDates receptionDates={receptionDates} updateReceptionDates={updateReceptionDates} />
                  </div>
                </div>
                <WeekdaysForReception requestDetails={weekdaysForReception} updateWeekdays={updateWeekdays} />
              </div>
            ) : null}
            <ExpandButton isExpanded={isExpanded} toggleExpansion={toggleExpansion} />
          </div>
          {displaySelectedDonors}
          {errors && (
            <div className="error-message" data-testid="error">
              <div>{errors}</div>
            </div>
          )}
          {errors || donorsWithNoGrids ? (
            <div className="btn-actions">
              <Button buttonClass="btn btn--inline btn--secondary" text="Close" onClick={onClose} />
            </div>
          ) : (
            <div className="btn-actions">
              <button disabled={isDisabled || isCreatingVTRequests} className="btn btn--inline" type="submit">
                {isCreatingVTRequests ? 'Creating...' : `Create ${externalInvestigationTypes.vt.name} Request(s)`}
              </button>
              <Button buttonClass="btn btn--inline btn--secondary" text="Close" onClick={onClose} />
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

NewVTRequests.defaultProps = {
  laboratoryOptions: [],
};

export default connector(NewVTRequests);

import moment from 'moment';
import _ from 'lodash';
import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Select from 'react-select';
import Input from '../../../core/components/Input/Input';
import { Button } from '../../../core/components/Button';
import Error from '../../../core/components/Error/Error';
import { formatDate } from '../../../core/helpers/dateHelpers';
import { fieldTypes } from '../../constants';
import { getEditDonorDetailsFields } from '../../helpers/getEditDonorDetailsFields';
import { removeWhitespace, getFieldLabel } from '../../helpers/formsHelpers';
import { SubjectConstants, FormField } from '../../types';
import { HlaNotesTextarea } from './HlaNotesTextarea';
import { DonorDetails, DonorDetailsUnion } from '../../../subject/types';
import { editAdultDonor } from '../../redux/actions';
import * as DonorSelectors from '../../redux/selectors';
import { ReduxState } from '../../../rootReducer';
import { DonorHLATable } from './DonorHLATable';
import gridFormatter from '../../../core/helpers/gridFormatter';
import './donor-record-page.scss';

type Props = { formConstants: SubjectConstants; donor: DonorDetails | undefined; isLoadingDonor: boolean };

const DonorRecordPage = ({ formConstants, donor, isLoadingDonor }: Props) => {
  const isLoading = isLoadingDonor || !formConstants || _.isEmpty(formConstants.GenderOptions);
  const [newDonor, setNewDonor] = useState<DonorDetails | undefined>();
  const dispatch = useDispatch();

  const isSaving = useSelector((state: ReduxState) => DonorSelectors.isSavingDonor(state), shallowEqual);
  const savingRecordError = useSelector(
    (state: ReduxState) => DonorSelectors.getSavingRecordError(state),
    shallowEqual
  );

  const isSaveButtonDisabled =
    (donor && newDonor && _.isEqual(donor, newDonor)) || !newDonor?.DonorType || !newDonor?.LastName;

  const formFields: FormField[] = useMemo(
    () => (donor ? getEditDonorDetailsFields(donor, formConstants) : []),
    [donor, formConstants]
  );

  const hlaArray: string[] = useMemo(() => (donor ? Object.keys(donor.Hla) : []), [donor]);

  const donorAge = useMemo(
    () => (newDonor?.BirthDate ? moment().diff(moment(newDonor.BirthDate), 'years') : ''),
    [newDonor?.BirthDate]
  );

  const handleInputChange = (e: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    e.preventDefault();
    const { id, value } = e.currentTarget;

    const formattedValue = id === 'grid' ? removeWhitespace(value) : value;
    if (newDonor) {
      setNewDonor({ ...newDonor, [id]: formattedValue });
    }
  };

  const handleSelectChange = (value: DonorDetailsUnion, field: string) => {
    const newValue = value || undefined;
    if (newDonor) {
      setNewDonor({ ...newDonor, [field]: newValue });
    }
  };

  const handleDateChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { id, value } = e.currentTarget;
    const dateNowInMs = Date.now();
    const selectedDateInMs = new Date(value).getTime();
    if (dateNowInMs >= selectedDateInMs) {
      if (newDonor) {
        setNewDonor({ ...newDonor, [id]: value });
      }
    }
    if (!value) {
      if (newDonor) {
        setNewDonor({ ...newDonor, [id]: value });
      }
    }
  };

  const handleSave = () => {
    if (newDonor) {
      dispatch(editAdultDonor(newDonor));
    }
  };

  useEffect(() => {
    if (donor) {
      setNewDonor(donor);
    }
  }, [donor?.Id]);

  return (
    <div className="panel">
      <h2>Donor Details</h2>

      {isLoading || !newDonor ? (
        <div className="loader small" />
      ) : (
        <form autoComplete="nope" className="subject-details-form">
          <div className="subject-details-form-column subject-details-form-column-3">
            {formFields.length > 0 &&
              [...formFields].slice(0, -5).map((field) => {
                const fieldKey = field.id as keyof DonorDetails;
                const newDonorFieldValue = newDonor?.[fieldKey] as string;

                switch (field.type) {
                  case fieldTypes.select:
                    return (
                      <label htmlFor={fieldKey} key={fieldKey}>
                        {field.label}
                        <Select
                          className="react-select-container"
                          classNamePrefix="react-select"
                          value={{
                            label: getFieldLabel(
                              field.options,
                              (!!newDonorFieldValue && newDonorFieldValue) || newDonorFieldValue,
                              ''
                            ),
                            value: (!!newDonorFieldValue && !!newDonorFieldValue) || '',
                          }}
                          isDisabled={field.disabled}
                          isClearable
                          id={fieldKey}
                          name={fieldKey}
                          onChange={(e) => handleSelectChange(e?.value as DonorDetailsUnion, fieldKey)}
                          options={field.options || []}
                        />
                      </label>
                    );
                  case fieldTypes.date:
                    return (
                      <div className="field">
                        <label htmlFor="date" key={field.id}>
                          {field.label} {donorAge >= 0 && fieldKey === 'BirthDate' && `(Age ${donorAge})`}
                          <input
                            type="date"
                            id={fieldKey}
                            disabled={field.disabled}
                            name={fieldKey}
                            value={formatDate(newDonorFieldValue, 'YYYY-MM-DD')}
                            onChange={(e) => handleDateChange(e)}
                            max={moment().format('YYYY-MM-DD')}
                          />
                        </label>
                      </div>
                    );
                  case fieldTypes.text: {
                    const fieldValue =
                      fieldKey === 'Grid' ? gridFormatter(newDonorFieldValue) : newDonorFieldValue || '';
                    return (
                      <Input
                        disabled={field.disabled}
                        key={fieldKey}
                        label={field.label}
                        type={field.type}
                        value={fieldValue}
                        id={fieldKey}
                        input={{ onChange: handleInputChange, name: fieldKey }}
                      />
                    );
                  }
                  default:
                    return null;
                }
              })}
          </div>
          <div className="subject-details-form-column subject-details-form-column-4">
            {formFields.length > 0 &&
              [...formFields].slice(9).map((field) => {
                const fieldKey = field.id as keyof DonorDetails;
                const newDonorFieldValue = newDonor?.[fieldKey] as string;
                return fieldKey !== 'TestNotes' ? (
                  <Input
                    disabled={field.disabled}
                    key={fieldKey}
                    label={field.label}
                    type={field.type}
                    value={newDonorFieldValue || ''}
                    id={fieldKey}
                    input={{ onChange: handleInputChange, name: fieldKey }}
                  />
                ) : null;
              })}
          </div>

          {hlaArray.length > 0 && donor && <DonorHLATable hlaData={donor.Hla} />}

          <div style={{ gridColumn: '1/4' }} key="TestNotes">
            <HlaNotesTextarea
              disabled
              input={{
                name: 'TestNotes',
                value: newDonor ? newDonor.TestNotes : '',
                onChange: handleInputChange,
              }}
            />
          </div>
          <div className="btn-actions">
            <Button
              primary
              loading={isSaving}
              disabled={isSaving || isSaveButtonDisabled}
              onClick={handleSave}
              text="Save"
            />
          </div>

          <Error error={savingRecordError as string} />
        </form>
      )}
    </div>
  );
};

export default DonorRecordPage;

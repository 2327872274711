import React from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../Logo';

export const NavButtons = () => (
  <ul className="nav-block menu">
    <div style={{ float: 'left' }}>
      <Logo />
    </div>
    <li>
      <NavLink exact to="/" activeClassName="active">
        Home
      </NavLink>
    </li>
    <li>
      <NavLink exact to="/reports/dashboard" activeClassName="active">
        Reports Dashboard
      </NavLink>
    </li>
    <li>
      <NavLink exact to="/patient/create" activeClassName="active" id="createPatientLink">
        Create Patient
      </NavLink>
    </li>
    <li>
      <NavLink exact to="/tc-management" activeClassName="active" id="tcManagementLink">
        TC Management
      </NavLink>
    </li>
  </ul>
);

export default NavButtons;

import React from 'react';
import { Link } from 'react-router-dom';
import { styles } from '../../../style';
import defaultTab from '../../core/constants/defaultPatientDashboardTab';
import type { PatientInfo } from '../../types';
import { reportTypes } from '../../../core';

type Props = {
  patient: PatientInfo;
};

const PatientSearchResultCard = ({ patient }: Props) => (
  <div className="patientInfoResultsCard" id={patient.id}>
    <header>
      <h2>{patient.name}</h2>
    </header>
    <div style={styles.searchCardText}>
      <div style={styles.summaryCardColumn}>
        <b>Id:</b> {patient.id}
        <br />
        <b>Gender:</b> {patient.gender || 'Unknown'}
        <br />
        <b>Age:</b> {patient.age !== 0 ? `${patient.age}` : 'N/A'}
        <br />
      </div>
      <div style={styles.summaryCardColumn}>
        <b>Weight (kg):</b> {patient.weight || 'Unknown'}
        <br />
        <b>Blood Type:</b> {patient.abo || 'Unknown'} {patient.rhd || 'Unknown'}
        <br />
        <b>CMV:</b> {patient.cmv || 'Unknown'}
        <br />
      </div>
    </div>
    <div style={styles.patientRequestsListWrapper}>
      <Link to={`patient/${patient.id}/${defaultTab}`} className="btn btn--inline">
        Patient Dashboard
      </Link>
      <div style={styles.patientRequestsListWrapper}>
        <Link to={`patient/${patient.id}/reports/new?reportType=${reportTypes.internal}`} className="btn btn--inline">
          New UK Search Report
        </Link>
        <Link
          to={`patient/${patient.id}/reports/new?reportType=${reportTypes.international}`}
          className="btn btn--inline"
        >
          New International Search Report
        </Link>
      </div>
    </div>
  </div>
);

export default PatientSearchResultCard;

import React from 'react';
import moment from 'moment';
import type { ApiSearchRequestSummary } from '../../../donorMatchSearchRequests/types/api';
import { donorTypes } from '../../../core';

export const RecentSearchSummary = ({ recentSearch }: { recentSearch: ApiSearchRequestSummary }) => {
  if (!recentSearch) {
    return (
      <div style={{ marginTop: '40px' }}>
        <h2 className="border-bottom-solid">Most Recent 10/10 UK Search</h2>
        <div>No search found</div>
      </div>
    );
  }

  const { ResultSetSummaries: resultSetSummaries, RequestedDate: searchCreationDate } = recentSearch;

  // resultSetSummaries[0] will be the adult donor result set, we need this to add this an an optional definition to fix a flow error
  const adultResultSetSummary =
    resultSetSummaries.find((summary) => summary.DonorType === donorTypes.adult.value) || resultSetSummaries[0];

  const { ExactMatchCount: exact, PotentialMatchCount: potential } = adultResultSetSummary;

  const totalDonors = (exactCount: string | number, potentialCount: string | number): number => {
    let donors;
    if (typeof exactCount === 'string' && typeof potentialCount === 'string') {
      donors = parseInt(exactCount, 10) + parseInt(potentialCount, 10);
    }
    if (typeof exactCount === 'number' && typeof potentialCount === 'number') {
      donors = exactCount + potentialCount;
    }
    return donors as number;
  };

  return (
    <div style={{ marginTop: '40px' }}>
      <h2 className="border-bottom-solid">Most Recent 10/10 UK Search</h2>
      <div style={{ display: 'flex' }}>
        <div className="summaryCardColumn">
          <strong>Exact Matches: </strong>
          {exact}
          <br />
          <strong>Potential Matches: </strong>
          {potential}
          <br />
          <strong>Total Donors: </strong>
          {totalDonors(exact, potential)}
          <br />
        </div>
        <div className="summaryCardColumn">
          <strong style={{ marginLeft: '10px' }}>Date Created: </strong>
          {moment(searchCreationDate).format('DD/MM/YYYY')}
          <br />
        </div>
      </div>
    </div>
  );
};

import { combineReducers } from 'redux';
// eslint-disable-next-line import/no-cycle
import transplantCentre, { TransplantCentreReducerState } from './reducers/transplantCentre';
// eslint-disable-next-line import/no-cycle
import recommendations, { RecommendationsReducerState } from './reducers/recommendations';
// eslint-disable-next-line import/no-cycle
import details, { ReportDetailsReducerState } from './reducers/details';

export type CurrentReportReducerState = {
  details: ReportDetailsReducerState;
  recommendations: RecommendationsReducerState;
  transplantCentre: TransplantCentreReducerState;
};

const currentReport = combineReducers<CurrentReportReducerState>({
  details,
  recommendations,
  transplantCentre,
});

export type CurrentReportReducer = typeof currentReport;

export default currentReport;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ErrorMessage } from '../../../core';
import MessageDenialTable from './MessageDenialTable';
import Selectors from '../../redux/selectors';
import { getMessageDenialActions, getMessageDenialActionsByDate } from '../../redux/actions';

const MessageDenialTab = () => {
  const messageDenialActions = useSelector(Selectors.getMessageDenialActions);
  const isFetching = useSelector(Selectors.isFetchingMessageDenialActions);
  const errorMessage = useSelector(Selectors.getMessageDenialActionsErrorMessage);

  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dateQueryParam = queryParams.get('date');

  useEffect(() => {
    if (queryParams.has('date')) {
      // @ts-expect-error - type 'null' is not assignable to type 'string'
      dispatch(getMessageDenialActionsByDate(dateQueryParam));
      return;
    }
    dispatch(getMessageDenialActions());
  }, [dateQueryParam]);

  return errorMessage ? (
    <div style={{ marginTop: '10px' }}>
      <ErrorMessage errorMessages={[errorMessage]} />
    </div>
  ) : (
    <div className="results-table results-table--border">
      <MessageDenialTable messageDenialActions={messageDenialActions} isLoading={isFetching} />
    </div>
  );
};

export default MessageDenialTab;

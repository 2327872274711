import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLinks, getRelationshipTypes } from '../../redux/actions';
import Subject from '../../redux/selectors';
import { ReduxState } from '../../../rootReducer';
import { SubjectLinkButton } from './SubjectLinkButton';
import { LinkSummary } from './LinkSummary';
import { subjectTypes } from '../../constants/subjectTypes';
import { RelationshipType, SubjectLinkExpanded, SubjectType } from '../../types';
import { isMainLink, getOrderedDonorLinks } from '../../helper/getSubjectLinks';
import Error from '../../../core/components/Error/Error';
import './subjectLinks.scss';

type Props = {
  subjectId: string;
  subjectType: SubjectType;
};

const SubjectLinks = ({ subjectId, subjectType }: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLinks(subjectId, subjectType));
  }, [dispatch, subjectId, subjectType]);

  useEffect(() => {
    dispatch(getRelationshipTypes());
  }, [dispatch]);

  const subjectLinks: SubjectLinkExpanded[] = useSelector((state: ReduxState) => Subject.getLinks(state));
  const isLoadingLinks: boolean = useSelector((state: ReduxState) => Subject.isLoadingLinks(state));
  const relationshipTypes: RelationshipType[] = useSelector((state: ReduxState) => Subject.getRelationshipTypes(state));
  const isLoadingRelationshipTypes: boolean = useSelector((state: ReduxState) =>
    Subject.isLoadingRelationshipTypes(state)
  );

  const orderedSubjectLinks = getOrderedDonorLinks(subjectLinks, subjectId);
  const isLoading = isLoadingLinks || isLoadingRelationshipTypes;
  const linkedSubjectType = subjectType === subjectTypes.donor.id ? subjectTypes.patient.id : subjectTypes.donor.id;
  const linkedSubjectTypeDisplayName =
    subjectType === subjectTypes.donor.id ? subjectTypes.patient.displayName : subjectTypes.donor.displayName;

  return (
    <div className="panel">
      <SubjectLinkButton
        relationshipTypes={relationshipTypes}
        subjectLinkType={linkedSubjectType}
        isLoading={isLoading}
        subjectId={subjectId}
      />
      <h2 className="border-bottom-solid">Linked {linkedSubjectTypeDisplayName}s</h2>
      {isLoading && <div className="loader small" />}
      {!isLoading && subjectLinks?.length === 0 && (
        <Error error={`No linked ${linkedSubjectType}s found for ${subjectType} ${subjectId}`} />
      )}
      {orderedSubjectLinks &&
        orderedSubjectLinks.map((link) => (
          <LinkSummary
            key={link.Id}
            subjectLink={link}
            subjectType={linkedSubjectType}
            relationshipTypes={relationshipTypes}
            isMainLink={isMainLink(link, subjectId)}
          />
        ))}
    </div>
  );
};

export default SubjectLinks;

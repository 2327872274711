import _ from 'lodash';

export const donorStatuses = {
  Available: { value: 'Available', name: 'Available' },
  TemporarilyUnavailable: {
    value: 'TemporarilyUnavailable',
    name: 'Temporarily Unavailable',
  },
  ReservedForAnotherPatient: {
    value: 'ReservedForAnotherPatient',
    name: 'Reserved For Another Patient',
  },
  Deleted: {
    value: 'Deleted',
    name: 'Deleted',
  },
  ReservedForThisPatient: {
    value: 'ReservedForThisPatient',
    name: 'Reserved For This Patient',
  },
  Reserved: {
    value: 'Reserved',
    name: 'Reserved',
  },
  ReservedTp: {
    value: 'ReservedTP',
    name: 'Reserved TP',
  },
  ReservedOp: {
    value: 'ReservedOP',
    name: 'Reserved OP',
  },
};

const getReservedDetails = (reservedForThisPatient: boolean) => {
  if (reservedForThisPatient) {
    return donorStatuses.ReservedTp;
  }
  return donorStatuses.ReservedOp;
};

export const getDonorStatusName = (value: string, reservedForThisPatient: boolean): string | null | undefined => {
  if (!value) {
    return null;
  }

  const donorStatus = _.find(donorStatuses, (ds) => ds.value === value);
  if (!donorStatus) {
    throw new Error(`Unexpected donor status value: ${value}`);
  }

  if (donorStatus.value === donorStatuses.Reserved.value) {
    return getReservedDetails(reservedForThisPatient).name;
  }

  return donorStatus.name;
};

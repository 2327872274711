import {
  get,
  post,
  ErrorAction as ApiErrorAction,
  RequestAction as ApiRequestAction,
  SuccessAction as ApiSuccessAction,
} from '@an/nova-frontend-rest-client';
import UrlAssembler from 'url-assembler';
import log from 'loglevel';
import type { Genetics, Institution, PatientDetails } from '../../types';
import Config from '../../../config';
import { history } from '../../../core';
// eslint-disable-next-line import/no-cycle
import {
  convertAdjustedHlaToApi,
  convertPatientGeneticsToApi,
  convertPatientDetailsToApi,
} from '../helpers/apiDataConverter';
import defaultTab from '../../core/constants/defaultPatientDashboardTab';
import type { ApiMatchingPatient, ApiPatientCreationConstants } from '../types/api';
import type { InstitutionAddress, Dispatch } from '../../../core/types';

const PATIENT_CREATE_PATIENT_CONSTANTS: 'patient/patientCreation/constants' = 'patient/patientCreation/constants';
const PATIENT_CREATE_PATIENT_CONSTANTS_ERROR: 'patient/patientCreation/constants/error' =
  'patient/patientCreation/constants/error';
const PATIENT_CREATE_PATIENT_CONSTANTS_SUCCESS: 'patient/patientCreation/constants/success' =
  'patient/patientCreation/constants/success';
const PATIENT_CREATE_PATIENT_DETAILS: 'patient/patientCreation/details' = 'patient/patientCreation/details';
const PATIENT_CREATE_PATIENT_DETAILS_ERROR: 'patient/patientCreation/details/error' =
  'patient/patientCreation/details/error';
const PATIENT_CREATE_PATIENT_DETAILS_REQUEST: 'patient/patientCreation/details/request' =
  'patient/patientCreation/details/request';
const PATIENT_CREATE_PATIENT_DETAILS_SUCCESS: 'patient/patientCreation/details/success' =
  'patient/patientCreation/details/success';
const PATIENT_CREATE_PATIENT_DUPLICATE: 'patient/patientCreation/duplicate' = 'patient/patientCreation/duplicate';
const PATIENT_CREATE_PATIENT_DUPLICATE_ERROR: 'patient/patientCreation/duplicate/error' =
  'patient/patientCreation/duplicate/error';
const PATIENT_CREATE_PATIENT_DUPLICATE_REQUEST: 'patient/patientCreation/duplicate/request' =
  'patient/patientCreation/duplicate/request';
const PATIENT_CREATE_PATIENT_DUPLICATE_SUCCESS: 'patient/patientCreation/duplicate/success' =
  'patient/patientCreation/duplicate/success';
const PATIENT_CREATE_SEARCH_REQUEST_ADJUSTED_HLA: 'patient/searchRequestInitiation/hla/adjust' =
  'patient/searchRequestInitiation/hla/adjust';
const PATIENT_GET_EXTERNAL_REGISTRIES: 'patient/patientCreation/externalRegistries' =
  'patient/patientCreation/externalRegistries';
const PATIENT_GET_EXTERNAL_REGISTRIES_ERROR: 'patient/patientCreation/externalRegistries/error' =
  'patient/patientCreation/externalRegistries/error';
const PATIENT_GET_EXTERNAL_REGISTRIES_SUCCESS: 'patient/patientCreation/externalRegistries/success' =
  'patient/patientCreation/externalRegistries/success';
const PATIENT_GET_HOSPITALS: 'patient/patientCreation/hospitals' = 'patient/patientCreation/hospitals';
const PATIENT_GET_HOSPITALS_ERROR: 'patient/patientCreation/hospitals/error' =
  'patient/patientCreation/hospitals/error';
const PATIENT_GET_HOSPITALS_SUCCESS: 'patient/patientCreation/hospitals/success' =
  'patient/patientCreation/hospitals/success';
const PATIENT_GET_INSTITUTION_ADDRESSES: 'patient/patientCreation/institutionAddresses' =
  'patient/patientCreation/institutionAddresses';
const PATIENT_GET_INSTITUTION_ADDRESSES_ERROR: 'patient/patientCreation/institutionAddresses/error' =
  'patient/patientCreation/institutionAddresses/error';
const PATIENT_GET_INSTITUTION_ADDRESSES_SUCCESS: 'patient/patientCreation/institutionAddresses/success' =
  'patient/patientCreation/institutionAddresses/success';
const PATIENT_GET_LABORATORIES: 'patient/patientCreation/laboratories' = 'patient/patientCreation/laboratories';
const PATIENT_GET_LABORATORIES_ERROR: 'patient/patientCreation/laboratories/error' =
  'patient/patientCreation/laboratories/error';
const PATIENT_GET_LABORATORIES_SUCCESS: 'patient/patientCreation/laboratories/success' =
  'patient/patientCreation/laboratories/success';
const PATIENT_SET_PATIENT_GENETICS: 'patient/patientCreation/genetics' = 'patient/patientCreation/genetics';
const PATIENT_SET_PATIENT_GENETICS_ERROR: 'patient/patientCreation/genetics/error' =
  'patient/patientCreation/genetics/error';
const PATIENT_SET_PATIENT_GENETICS_SUCCESS: 'patient/patientCreation/genetics/success' =
  'patient/patientCreation/genetics/success';
const PATIENT_UPDATE_PATIENT_DETAILS: 'patient/patientCreation/updateDetails' = 'patient/patientCreation/updateDetails';
const PATIENT_UPDATE_PATIENT_DETAILS_ERROR: 'patient/patientCreation/updateDetails/error' =
  'patient/patientCreation/updateDetails/error';
const PATIENT_UPDATE_PATIENT_DETAILS_REQUEST: 'patient/patientCreation/updateDetails/request' =
  'patient/patientCreation/updateDetails/request';
const PATIENT_UPDATE_PATIENT_DETAILS_SUCCESS: 'patient/patientCreation/updateDetails/success' =
  'patient/patientCreation/updateDetails/success';

export const Actions = {
  PATIENT_CREATE_PATIENT_CONSTANTS_ERROR,
  PATIENT_CREATE_PATIENT_CONSTANTS_SUCCESS,
  PATIENT_CREATE_PATIENT_DETAILS_ERROR,
  PATIENT_CREATE_PATIENT_DETAILS_REQUEST,
  PATIENT_CREATE_PATIENT_DETAILS_SUCCESS,
  PATIENT_CREATE_PATIENT_DUPLICATE_ERROR,
  PATIENT_CREATE_PATIENT_DUPLICATE_REQUEST,
  PATIENT_CREATE_PATIENT_DUPLICATE_SUCCESS,
  PATIENT_CREATE_SEARCH_REQUEST_ADJUSTED_HLA,
  PATIENT_GET_EXTERNAL_REGISTRIES_ERROR,
  PATIENT_GET_EXTERNAL_REGISTRIES_SUCCESS,
  PATIENT_GET_HOSPITALS_ERROR,
  PATIENT_GET_HOSPITALS_SUCCESS,
  PATIENT_GET_INSTITUTION_ADDRESSES_ERROR,
  PATIENT_GET_INSTITUTION_ADDRESSES_SUCCESS,
  PATIENT_GET_LABORATORIES_ERROR,
  PATIENT_GET_LABORATORIES_SUCCESS,
  PATIENT_SET_PATIENT_GENETICS_ERROR,
  PATIENT_SET_PATIENT_GENETICS_SUCCESS,
  PATIENT_UPDATE_PATIENT_DETAILS_ERROR,
  PATIENT_UPDATE_PATIENT_DETAILS_REQUEST,
  PATIENT_UPDATE_PATIENT_DETAILS_SUCCESS,
};

type ErrorMessageParam = { errorMessage: string };
type SuccessMessageParam = { successMessage: string };
type Error = { Error: string };

export type CreateSearchRequestAdjustedHlaAction = {
  type: typeof PATIENT_CREATE_SEARCH_REQUEST_ADJUSTED_HLA;
  payload: {
    data: {
      geneticsObject: Genetics;
      successMessage: string;
    };
    params: {
      patientId: string;
      successMessage: string;
    };
  };
};
export type PatientCreatePatientConstantsErrorAction = ApiErrorAction<
  typeof PATIENT_CREATE_PATIENT_CONSTANTS_ERROR,
  Record<string, unknown> & ErrorMessageParam
>;
export type PatientCreatePatientConstantsSuccessAction = ApiSuccessAction<
  typeof PATIENT_CREATE_PATIENT_CONSTANTS_SUCCESS,
  Record<string, unknown>,
  ApiPatientCreationConstants
>;
export type PatientCreatePatientDetailsRequestAction = ApiRequestAction<
  typeof PATIENT_CREATE_PATIENT_DETAILS_REQUEST,
  Record<string, unknown>
>;
export type PatientCreatePatientDetailsErrorAction = ApiErrorAction<
  typeof PATIENT_CREATE_PATIENT_DETAILS_ERROR,
  ErrorMessageParam & SuccessMessageParam,
  Error
>;
export type PatientCreatePatientDetailsSuccessAction = ApiSuccessAction<
  typeof PATIENT_CREATE_PATIENT_DETAILS_SUCCESS,
  ErrorMessageParam & SuccessMessageParam,
  string & SuccessMessageParam
>;
export type PatientCreatePatientDuplicateErrorAction = ApiErrorAction<
  typeof PATIENT_CREATE_PATIENT_DUPLICATE_ERROR,
  { firstName: string; surname: string } & ErrorMessageParam
>;
export type PatientCreatePatientDuplicateSuccessAction = ApiSuccessAction<
  typeof PATIENT_CREATE_PATIENT_DUPLICATE_SUCCESS,
  { firstName: string; surname: string } & ErrorMessageParam,
  ApiMatchingPatient[]
>;

export type PatientGetExternalRegistriesSuccessAction = ApiSuccessAction<
  typeof PATIENT_GET_EXTERNAL_REGISTRIES_SUCCESS,
  Record<string, unknown>,
  Institution[]
>;
export type PatientGetHospitalsSuccessAction = ApiSuccessAction<
  typeof PATIENT_GET_HOSPITALS_SUCCESS,
  Record<string, unknown>,
  Institution[]
>;
export type PatientGetInstitutionAddressesSuccessAction = ApiSuccessAction<
  typeof PATIENT_GET_INSTITUTION_ADDRESSES_SUCCESS,
  Record<string, unknown>,
  InstitutionAddress[]
>;
export type PatientGetLaboratoriesSuccessAction = ApiSuccessAction<
  typeof PATIENT_GET_LABORATORIES_SUCCESS,
  Record<string, unknown>,
  Institution[]
>;
export type PatientSetPatientGeneticsErrorAction = ApiErrorAction<
  typeof PATIENT_SET_PATIENT_GENETICS_ERROR,
  ErrorMessageParam & SuccessMessageParam,
  Error
>;
export type PatientSetPatientGeneticsSuccessAction = ApiSuccessAction<
  typeof PATIENT_SET_PATIENT_GENETICS_SUCCESS,
  ErrorMessageParam & SuccessMessageParam,
  string & SuccessMessageParam
>;
export type PatientUpdatePatientDetailsRequestAction = ApiErrorAction<
  typeof PATIENT_UPDATE_PATIENT_DETAILS_REQUEST,
  Record<string, unknown>
>;
export type PatientUpdatePatientDetailsErrorAction = ApiErrorAction<
  typeof PATIENT_UPDATE_PATIENT_DETAILS_ERROR,
  ErrorMessageParam & SuccessMessageParam,
  Error
>;
export type PatientUpdatePatientDetailsSuccessAction = ApiSuccessAction<
  typeof PATIENT_UPDATE_PATIENT_DETAILS_SUCCESS,
  ErrorMessageParam & SuccessMessageParam,
  string & SuccessMessageParam
>;

const adjustHla = (
  geneticsObject: Genetics,
  patientId: string,
  successMessage: string
): CreateSearchRequestAdjustedHlaAction => ({
  type: Actions.PATIENT_CREATE_SEARCH_REQUEST_ADJUSTED_HLA,
  payload: {
    data: {
      geneticsObject,
      successMessage,
    },
    params: {
      patientId,
      successMessage,
    },
  },
});

export const saveNewPatientDetails =
  (patientDetails: PatientDetails) =>
  async (dispatch: Dispatch<any>): Promise<void> => {
    const patientObject = convertPatientDetailsToApi(patientDetails);
    const url = UrlAssembler(Config().apiBaseUrl).template('patients').toString();

    const save = await dispatch(
      post(
        url,
        PATIENT_CREATE_PATIENT_DETAILS,
        {
          successMessage: 'Patient Created',
          errorMessage: 'Patient Creation failed',
        },
        patientObject
      )
    );
    if (save.response.ok) {
      history.push(`/patient/${save.payload}/update/genetic`);
    } else {
      log.error(`Patient Create failed - ${save.response.status}`);
    }
  };

export const getPatientDuplicates =
  (firstName: string, surname: string, dateOfBirth: string) => (dispatch: Dispatch<any>) =>
    dispatch(
      get(
        `${
          Config().apiBaseUrl
        }patients?patientSearchModel.firstname=${firstName}&patientSearchModel.surname=${surname}&patientSearchModel.dateOfBirth=${dateOfBirth}`,
        PATIENT_CREATE_PATIENT_DUPLICATE,
        {
          firstName,
          surname,
          dateOfBirth,
          errorMessage: 'Patient Creation failed',
        }
      )
    );

export const saveUpdatedPatientDetails =
  (patientDetails: PatientDetails) =>
  async (dispatch: Dispatch<any>): Promise<void> => {
    const patientObject = convertPatientDetailsToApi(patientDetails);

    const url = UrlAssembler(Config().apiBaseUrl).template(`patients/${patientObject.Id}`).toString();

    const save = await dispatch(
      post(
        url,
        PATIENT_UPDATE_PATIENT_DETAILS,
        {
          successMessage: 'Patient Updated',
          errorMessage: 'Patient Update failed',
        },
        patientObject
      )
    );

    if (save.response.ok) {
      history.push(`/patient/${patientObject.Id}/${defaultTab}`);
    } else {
      log.error(`Patient Update failed - ${save.response.status}`);
    }
  };

export const savePatientGenetics =
  (geneticDetails: Genetics, patientId: string) =>
  async (dispatch: Dispatch<any>): Promise<void> => {
    const geneticsObject = convertPatientGeneticsToApi(geneticDetails, patientId);

    const url = UrlAssembler(Config().apiBaseUrl).template(`patients/${patientId}/genetic-data`).toString();

    const genetics = await dispatch(
      post(
        url,
        PATIENT_SET_PATIENT_GENETICS,
        {
          successMessage: 'Patient Genetic Data Saved',
          errorMessage: 'Patient Genetic Data Save failed',
        },
        geneticsObject
      )
    );

    if (genetics.response.ok) {
      history.push(`/patient/${patientId}/${defaultTab}`);
    } else {
      log.error(`Patient Genetic Data Save failed - ${genetics.response.status}`);
    }
  };

export const saveAdjustedHla = (adjustedHla: Genetics, patientId: string) => (dispatch: Dispatch<any>) => {
  const geneticsObject = convertAdjustedHlaToApi(adjustedHla);
  dispatch(adjustHla(geneticsObject, patientId, 'Adjusted HLA Saved'));
  history.push(`/patient/${patientId}/${defaultTab}`);
};

export const getPatientCreationConstants = () => (dispatch: Dispatch<any>) => {
  const url = UrlAssembler(Config().apiBaseUrl).template('patients/creation-constants').toString();

  dispatch(get(url, PATIENT_CREATE_PATIENT_CONSTANTS));
};

export const getHospitals = () => async (dispatch: Dispatch<any>) => {
  const url = UrlAssembler(Config().apiBaseUrl).template('institutions/hospitals').toString();

  await dispatch(get(url, PATIENT_GET_HOSPITALS));
};

export const getLaboratories = () => async (dispatch: Dispatch<any>) => {
  const url = UrlAssembler(Config().apiBaseUrl).template('institutions/laboratories').toString();

  await dispatch(get(url, PATIENT_GET_LABORATORIES));
};

export const getExternalRegistries = () => async (dispatch: Dispatch<any>) => {
  const url = UrlAssembler(Config().apiBaseUrl).template('institutions/external-registries').toString();

  await dispatch(get(url, PATIENT_GET_EXTERNAL_REGISTRIES));
};

export const getInstitutionAddresses = () => async (dispatch: Dispatch<any>) => {
  const url = UrlAssembler(Config().apiBaseUrl).template('institutions/institution-addresses').toString();

  await dispatch(get(url, PATIENT_GET_INSTITUTION_ADDRESSES));
};

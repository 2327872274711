import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import moment from 'moment';
import ResultPageSelectors from '../../redux/selectors';
import { SearchRequestSelectors } from '../../../donorMatchSearchRequests/index';
import filterToStringsHelper from '../../helpers/filterToStringsHelper';
import { DateFormat } from '../../../core/index';
import type { AppliedFiltersArray } from '../../types/index';
import type { AdultDonor, DonorType } from '../../../core/types';
import type { SavedResultSet } from '../../../donorMatchSearchRequests/types';
import donorTypes from '../../../core/constants/donorTypes';
import type { ReduxState } from '../../../rootReducer';

type OwnProps = {
  donors: AdultDonor[];
  // eslint-disable-next-line react/no-unused-prop-types
  resultSetId: string; // used in `mapStateToProps`
};
type StateProps = {
  appliedFilters: AppliedFiltersArray;
  visibleSelectedIds: string[];
  donorSet?: SavedResultSet;
};
type Props = PropsFromRedux & OwnProps & StateProps;

const mapStateToProps = (state: ReduxState, ownProps: OwnProps): StateProps => ({
  appliedFilters: ResultPageSelectors.getAppliedFiltersArray(state),
  donorSet: SearchRequestSelectors.getSingleSavedResultSet(
    state,
    donorTypes.adult.value as DonorType,
    ResultPageSelectors.getShownResultSetId(state, donorTypes.adult.value as DonorType)
  ),
  visibleSelectedIds: ResultPageSelectors.getVisibleSelectedIds(state, ownProps.resultSetId, donorTypes.adult.value),
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const getAppliedFiltersAsString = (appliedFilters: AppliedFiltersArray, donors: AdultDonor[]) =>
  filterToStringsHelper(appliedFilters, donors);

class DonorSelectionDescription extends PureComponent<Props> {
  static defaultProps = {
    donorSet: undefined,
  };

  render() {
    const { appliedFilters, donors, visibleSelectedIds } = this.props;
    return (
      <div
        style={{
          boxSizing: 'border-box',
          padding: '5px',
          border: '1px solid #ddd',
          margin: '3px 20px 3px 3px',
          minHeight: '129px',
          flexBasis: '100%',
        }}
      >
        <span style={{ fontWeight: 'bold' }}>Current Donor Set Information</span>
        <br />
        Donors Selected: <span style={{ fontStyle: 'italic' }}>{visibleSelectedIds.length}</span>
        <br />
        Filters:{' '}
        <span style={{ fontStyle: 'italic' }}>
          {getAppliedFiltersAsString(appliedFilters, donors) || 'No filters applied.'}
        </span>
        <br />
        {this.renderUserAndDate()}
      </div>
    );
  }

  renderUserAndDate = () => {
    const { donorSet } = this.props;
    if (!donorSet) {
      return null;
    }

    const user = donorSet.user || 'Unknown';
    const date = donorSet.dateModified ? moment(donorSet.dateModified).format(DateFormat.DATE_ONLY) : null;

    return (
      <div>
        Saved by {user} {date}
      </div>
    );
  };
}

export default connector(DonorSelectionDescription);

import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch as ReduxDispatch } from 'redux';
import { styles } from '../../../../style';
import * as actions from '../../../../redux/actions';
import Selectors from '../../../../redux/selectors';
import type { ReduxState } from '../../../../../rootReducer';

import type { MinFilterName, MinFilterType, MinFilterValue } from '../../../../types/index';

type OwnProps = {
  filterName: MinFilterName;
  filterType: MinFilterType;
};
type StateProps = {
  value?: MinFilterValue;
};
type Props = PropsFromRedux & OwnProps & StateProps;
type State = {
  min?: number;
};

type FilterChangeEvent = {
  name: string;
  value: string;
} & HTMLInputElement;

const mapStateToProps = (state: ReduxState, ownProps: OwnProps): StateProps => ({
  value: Selectors.getMinFilter(state, ownProps.filterType),
});

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>) => ({
  updateMinFilter: bindActionCreators(actions.updateMinFilter, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export class Filter extends PureComponent<Props, State> {
  static defaultProps = {
    value: undefined,
  };

  constructor(props: Props) {
    super(props);
    const { value } = this.props;
    this.state = {
      min: value ? value.min : undefined,
    };
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps: Props) {
    const { value } = nextProps;
    const { min } = this.state;
    const minValue = value ? value.min : undefined;
    if (min !== minValue) {
      this.setState({
        min: minValue,
      });
    }
  }

  render() {
    const { filterName } = this.props;
    const { min } = this.state;
    return (
      <div style={{ display: 'flex' }}>
        <label htmlFor="Min" style={styles.inputLabel}>
          {filterName} Min
          <input
            id="Min"
            type="number"
            placeholder="No Min"
            value={min || ''}
            name="min"
            className="Select-control"
            onChange={this.handleFilterChange}
            onKeyPress={this.handleKeyPress}
            onBlur={this.handleFilter}
          />
        </label>
      </div>
    );
  }

  handleFilter = () => {
    const { filterType, updateMinFilter } = this.props;
    const { min } = this.state;
    updateMinFilter(filterType, min);
  };

  handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // $FlowExpectedError - not possible to type `keyCode`
    if (event.nativeEvent.keyCode === 13) {
      // 13 is the keyCode for "Return"
      event.currentTarget.blur();
    }
  };

  handleFilterChange = (event: React.SyntheticEvent<FilterChangeEvent>) => {
    const filterType = event.currentTarget.name;
    return this.setState({
      [filterType]: event.currentTarget.value,
    });
  };
}

export default connector(Filter);

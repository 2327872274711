import { LOCATION_CHANGE, RouterState } from 'connected-react-router';
import { Actions, SearchTeamUsersFetchErrorAction, SearchTeamUsersFetchSuccessAction } from './actions';
import {
  Actions as SearchRequestActions,
  UserSearchRequestsSuccessAction,
} from '../../donorMatchSearchRequests/redux/actions';
import type { User } from '../types';

type LocationChangeAction = { type: typeof LOCATION_CHANGE; payload: RouterState };
type UsersActions =
  | SearchTeamUsersFetchErrorAction
  | SearchTeamUsersFetchSuccessAction
  | UserSearchRequestsSuccessAction
  | LocationChangeAction;

export type SearchTeamUsersState = {
  hasErrored: boolean;
  message?: string;
  // TODO-Flow: Move to entities reducer
  users: Record<string, User>;
};

const initialState: SearchTeamUsersState = {
  hasErrored: false,
  users: {},
};

export default (state: SearchTeamUsersState = initialState, action: UsersActions): SearchTeamUsersState => {
  switch (action.type) {
    case Actions.SEARCH_TEAM_USERS_SUCCESS:
      return {
        ...state,
        hasErrored: false,
        message: undefined,
        users: action.payload ? action.payload.data.entities.users : {},
      };
    case Actions.SEARCH_TEAM_USERS_ERROR:
      return {
        ...state,
        hasErrored: true,
        message: `Error while fetching Users - ${action.payload.response.status}`,
        users: {},
      };
    case SearchRequestActions.USER_SEARCH_REQUESTS_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          [action.payload.params.username]: {
            ...state.users[action.payload.params.username],
            // TODO: Move out of user object
            requestIds: action.payload.data.result,
          },
        },
      };
    case LOCATION_CHANGE:
      return {
        ...state,
        hasErrored: false,
        message: undefined,
      };
    default:
      return state;
  }
};

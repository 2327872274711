import React from 'react';
import { ExternalInvestigationSelectedDonor } from '../types';
import CheckboxOptions from './CheckboxOptions';
import { SingleLineTextArea } from '../../../../core/components/SingleLineTextArea';
import { formatRequestContext } from '../../../../core/helpers/markupHelpers';
import '../individualNewTestRequest.scss';

type Props = {
  patientId: string;
  selectedDonor: ExternalInvestigationSelectedDonor;
  urgent: boolean;
  remark?: string;
  updateExtraInfo: (requirement: string, donorId: string) => void;
  updateRemark: (remark: string, donorId: string) => void;
};

const IndividualNewVTRequest = (props: Props) => {
  const { selectedDonor, patientId, urgent, remark } = props;

  const handleExtraInfoChange = (requirement: string) => {
    const { updateExtraInfo } = props;
    updateExtraInfo(requirement, selectedDonor.id);
  };

  const handleRemarkChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { updateRemark } = props;
    updateRemark(event.currentTarget.value, selectedDonor.id);
  };

  return selectedDonor.grid ? (
    <div className="border-bottom">
      {formatRequestContext(patientId, selectedDonor.originatingRegistry.name, selectedDonor.id, selectedDonor.grid)}
      <CheckboxOptions urgent={urgent} updateExtraInfo={handleExtraInfoChange} donorId={selectedDonor.id} />
      <SingleLineTextArea
        rows={1}
        maxLength={120}
        placeholder="Remarks (PO can be added here)"
        value={remark}
        onChange={handleRemarkChange}
      />
    </div>
  ) : (
    <div className="border-bottom">
      {formatRequestContext(patientId, selectedDonor.originatingRegistry.name, selectedDonor.id, selectedDonor.grid)}
      <div className="error-message blocker-message" data-testid="error">
        <span>Cannot create a test request for a donor with no GRID</span>
      </div>
    </div>
  );
};

IndividualNewVTRequest.defaultProps = {
  remark: undefined,
};

export default IndividualNewVTRequest;

import React from 'react';
import { styles } from '../../../style';

type Props = {
  data: string | number;
};

export default ({ data }: Props) => (
  <div style={styles.doubleLinedCell}>
    <span key={data}>{data}</span>
  </div>
);

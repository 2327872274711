import React from 'react';
import TextMessagesTable from './TextMessagesTable';
import { ExternalInvestigationTextMessage } from '../../../../../../externalInvestigations/types';

type Props = {
  textMessages: ExternalInvestigationTextMessage[];
};

const TestRequestsTab = ({ textMessages }: Props) => (
  <div className="results-table results-table--border">
    <TextMessagesTable textMessages={textMessages} />
  </div>
);

export default TestRequestsTab;

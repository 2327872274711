// eslint-disable-next-line import/no-cycle
import { Actions, AddFullHlaAction, NotificationErrorAction, NotificationSuccessAction } from './actions';
// eslint-disable-next-line import/no-cycle
import {
  Actions as PatientCreationActions,
  CreateSearchRequestAdjustedHlaAction,
  PatientCreatePatientDetailsErrorAction,
  PatientCreatePatientDetailsSuccessAction,
  PatientSetPatientGeneticsErrorAction,
  PatientSetPatientGeneticsSuccessAction,
  PatientUpdatePatientDetailsErrorAction,
  PatientUpdatePatientDetailsSuccessAction,
} from '../../patient/patientCreation/redux/actions';
// eslint-disable-next-line import/no-cycle
import {
  Actions as PatientDashboardActions,
  ClosePatientErrorAction,
  ClosePatientSuccessAction,
  OpenPatientErrorAction,
  OpenPatientSuccessAction,
  PatientCreateSearchRequestErrorAction,
  PatientCreateSearchRequestSuccessAction,
  PatientRepeatSearchDateUpdateError,
  PatientRepeatSearchDateUpdateSuccessAction,
  SendPatientToWmdaAction,
  SendPatientToWmdaErrorAction,
  SendPatientToWmdaSuccessAction,
} from '../../patient/patientDashboard/redux/actions';
// eslint-disable-next-line import/no-cycle
import { Actions as SearchResultsExternalInvestigationActions } from '../../donorSearchResults/components/ExternalInvestigations/redux/actionNames';
import {
  ExternalInvestigationsCreateExtendedTypingRequestErrorAction,
  ExternalInvestigationsCreateExtendedTypingRequestSuccessAction,
  ExternalInvestigationsCreateIDMRequestErrorAction,
  ExternalInvestigationsCreateIDMRequestSuccessAction,
  ExternalInvestigationsCreateVTRequestErrorAction,
  ExternalInvestigationsCreateVTRequestSuccessAction,
} from '../../donorSearchResults/components/ExternalInvestigations/redux/actions';
import { Actions as SearchDonorRequestActions } from '../../donorSearchResults/redux/actionNames';
import {
  AdultSearchResultsCreateDonorSetSuccessAction,
  AdultSearchResultsDonorSetCreateErrorAction,
  AdultSearchResultsDonorSetUpdateError,
  AdultSearchResultsUpdateDonorSetSuccessAction,
  CordSearchResultsCordSetCreateErrorAction,
  CordSearchResultsCordSetUpdateErrorAction,
  CordSearchResultsCreateCordSetSuccessAction,
  CordSearchResultsUpdateCordSetSuccessAction,
} from '../../donorSearchResults/redux/actions';
import {
  Actions as ExternalInvestigationsActions,
  CancelExternalInvestigationsErrorAction,
  CancelExternalInvestigationsSuccessAction,
  ExternalInvestigationReportDownloadPdfFinalAttemptErrorAction,
  ExternalInvestigationReportDownloadPdfFinalAttemptSuccessAction,
  ExternalInvestigationReportDownloadPdfSuccessAction,
  GenerateVirologyReportErrorAction,
  GenerateVirologyReportSuccessAction,
  SaveVerificationTypingResultErrorAction,
  SaveVerificationTypingResultSuccessAction,
  SendVerificationTypingResultErrorAction,
  SendVerificationTypingResultSuccessAction,
} from '../../externalInvestigations/redux/actions';
import {
  Actions as CurrentReportActions,
  CurrentReportDownloadPdfErrorAction,
  CurrentReportDownloadPdfFinalAttemptErrorAction,
  CurrentReportDownloadPdfFinalAttemptSuccessAction,
  CurrentReportDownloadPdfSuccessAction,
  CurrentReportGeneratePdfErrorAction,
  CurrentReportGeneratePdfSuccessAction,
  CurrentReportStatusUpdateErrorAction,
  CurrentReportStatusUpdateSuccessAction,
  SaveNewReportErrorAction,
  SaveNewReportSuccessAction,
  UpdateReportErrorAction,
  UpdateReportSuccessAction,
} from '../../searchReports/currentReport/redux/actions';
// eslint-disable-next-line import/no-cycle
import {
  Actions as TransplantCentreManagementActions,
  UpdateExternalInvestigationActionsSuccess,
  UpdatedActionsResponseError,
  UpdatedActionsResponseSuccess,
} from '../../transplantCentre/redux/actions';

import type { Locus } from '../../core/types';

type UserActions =
  | AddFullHlaAction
  | AdultSearchResultsCreateDonorSetSuccessAction
  | AdultSearchResultsDonorSetCreateErrorAction
  | AdultSearchResultsDonorSetUpdateError
  | AdultSearchResultsUpdateDonorSetSuccessAction
  | ClosePatientErrorAction
  | ClosePatientSuccessAction
  | CordSearchResultsCordSetCreateErrorAction
  | CordSearchResultsCordSetUpdateErrorAction
  | CordSearchResultsCreateCordSetSuccessAction
  | CordSearchResultsUpdateCordSetSuccessAction
  | CreateSearchRequestAdjustedHlaAction
  | CurrentReportDownloadPdfFinalAttemptErrorAction
  | CurrentReportDownloadPdfSuccessAction
  | CurrentReportDownloadPdfFinalAttemptSuccessAction
  | CurrentReportGeneratePdfErrorAction
  | CurrentReportGeneratePdfSuccessAction
  | CurrentReportStatusUpdateErrorAction
  | CurrentReportStatusUpdateSuccessAction
  | ExternalInvestigationsCreateExtendedTypingRequestErrorAction
  | ExternalInvestigationsCreateExtendedTypingRequestSuccessAction
  | ExternalInvestigationsCreateIDMRequestErrorAction
  | ExternalInvestigationsCreateIDMRequestSuccessAction
  | ExternalInvestigationsCreateVTRequestErrorAction
  | ExternalInvestigationsCreateVTRequestSuccessAction
  | NotificationErrorAction
  | NotificationSuccessAction
  | OpenPatientErrorAction
  | OpenPatientSuccessAction
  | PatientCreatePatientDetailsErrorAction
  | PatientCreatePatientDetailsSuccessAction
  | PatientCreateSearchRequestErrorAction
  | PatientCreateSearchRequestSuccessAction
  | PatientRepeatSearchDateUpdateError
  | PatientRepeatSearchDateUpdateSuccessAction
  | PatientSetPatientGeneticsErrorAction
  | PatientSetPatientGeneticsSuccessAction
  | PatientUpdatePatientDetailsErrorAction
  | PatientUpdatePatientDetailsSuccessAction
  | SaveNewReportErrorAction
  | SaveNewReportSuccessAction
  | SendPatientToWmdaAction
  | UpdateReportErrorAction
  | UpdateReportSuccessAction
  | CancelExternalInvestigationsErrorAction
  | CancelExternalInvestigationsSuccessAction
  | GenerateVirologyReportErrorAction
  | GenerateVirologyReportSuccessAction
  | ExternalInvestigationReportDownloadPdfSuccessAction
  | ExternalInvestigationReportDownloadPdfFinalAttemptSuccessAction
  | ExternalInvestigationReportDownloadPdfFinalAttemptErrorAction
  | SendPatientToWmdaErrorAction
  | SendPatientToWmdaSuccessAction
  | SaveVerificationTypingResultSuccessAction
  | SaveVerificationTypingResultErrorAction
  | SendVerificationTypingResultSuccessAction
  | SendVerificationTypingResultErrorAction
  | UpdateReportSuccessAction
  | UpdateExternalInvestigationActionsSuccess
  | UpdatedActionsResponseSuccess
  | UpdatedActionsResponseError;

export type FullHlaData = {
  hlaData: Locus;
  level: 'info' | 'error' | 'success';
};
export type Notification = {
  level: 'info' | 'error' | 'success';
  message: string;
  timeDisplayed: number;
};
export type NotificationReducerState = {
  fullHla?: FullHlaData;
  notification?: Notification;
};

const initialState: NotificationReducerState = {
  notification: undefined,
  fullHla: undefined,
};

export default (state: NotificationReducerState = initialState, action: UserActions): NotificationReducerState => {
  switch (action.type) {
    case PatientCreationActions.PATIENT_CREATE_SEARCH_REQUEST_ADJUSTED_HLA:
    case PatientDashboardActions.PATIENT_REPEAT_SEARCH_DATE_UPDATE_SUCCESS:
    case PatientDashboardActions.PATIENT_CREATE_SEARCH_REQUEST_SUCCESS:
    case PatientCreationActions.PATIENT_CREATE_PATIENT_DETAILS_SUCCESS:
    case PatientCreationActions.PATIENT_UPDATE_PATIENT_DETAILS_SUCCESS:
    case PatientCreationActions.PATIENT_SET_PATIENT_GENETICS_SUCCESS:
    case PatientDashboardActions.CLOSE_PATIENT_SUCCESS:
    case PatientDashboardActions.OPEN_PATIENT_SUCCESS:
    case CurrentReportActions.SAVE_NEW_REPORT_SUCCESS:
    case CurrentReportActions.UPDATE_REPORT_SUCCESS:
    case CurrentReportActions.CURRENT_REPORT_STATUS_UPDATE_SUCCESS:
    case CurrentReportActions.CURRENT_REPORT_GENERATE_PDF_SUCCESS:
    case CurrentReportActions.CURRENT_REPORT_DOWNLOAD_PDF_SUCCESS:
    case CurrentReportActions.CURRENT_REPORT_DOWNLOAD_PDF_FINAL_ATTEMPT_SUCCESS:
    case SearchDonorRequestActions.ADULT_SEARCH_RESULTS_DONOR_SET_CREATE_SUCCESS:
    case SearchDonorRequestActions.ADULT_SEARCH_RESULTS_DONOR_SET_UPDATE_SUCCESS:
    case SearchDonorRequestActions.CORD_SEARCH_RESULTS_CORD_SET_CREATE_SUCCESS:
    case SearchDonorRequestActions.CORD_SEARCH_RESULTS_CORD_SET_UPDATE_SUCCESS:
    case SearchResultsExternalInvestigationActions.EXTERNAL_INVESTIGATIONS_CREATE_IDM_REQUEST_SUCCESS:
    case SearchResultsExternalInvestigationActions.EXTERNAL_INVESTIGATIONS_CREATE_EXTENDED_TYPING_REQUEST_SUCCESS:
    case SearchResultsExternalInvestigationActions.EXTERNAL_INVESTIGATIONS_CREATE_VT_REQUEST_SUCCESS:
    case ExternalInvestigationsActions.CANCEL_EXTERNAL_INVESTIGATIONS_SUCCESS:
    case ExternalInvestigationsActions.GENERATE_VIROLOGY_REPORT_SUCCESS:
    case ExternalInvestigationsActions.EXTERNAL_INVESTIGATION_REPORT_DOWNLOAD_PDF_SUCCESS:
    case ExternalInvestigationsActions.EXTERNAL_INVESTIGATION_REPORT_DOWNLOAD_PDF_FINAL_ATTEMPT_SUCCESS:
    case ExternalInvestigationsActions.SAVE_VERIFICATION_TYPING_RESULT_SUCCESS:
    case ExternalInvestigationsActions.SEND_VERIFICATION_TYPING_RESULT_SUCCESS:
    case Actions.NOTIFICATION_SUCCESS:
    case PatientDashboardActions.SEND_PATIENT_TO_WMDA_SUCCESS:
    case PatientDashboardActions.SEND_PATIENT_TO_WMDA:
      return {
        ...state,
        notification: {
          message:
            (action.payload.params && action.payload.params.successMessage) || action.payload.data.successMessage,
          level: 'success',
          timeDisplayed: 3,
        },
      };
    case PatientDashboardActions.PATIENT_CREATE_SEARCH_REQUEST_ERROR:
    case PatientDashboardActions.PATIENT_REPEAT_SEARCH_DATE_UPDATE_ERROR:
    case PatientCreationActions.PATIENT_CREATE_PATIENT_DETAILS_ERROR:
    case PatientCreationActions.PATIENT_UPDATE_PATIENT_DETAILS_ERROR:
    case PatientCreationActions.PATIENT_SET_PATIENT_GENETICS_ERROR:
    case PatientDashboardActions.CLOSE_PATIENT_ERROR:
    case PatientDashboardActions.OPEN_PATIENT_ERROR:
    case CurrentReportActions.SAVE_NEW_REPORT_ERROR:
    case CurrentReportActions.UPDATE_REPORT_ERROR:
    case CurrentReportActions.CURRENT_REPORT_STATUS_UPDATE_ERROR:
    case CurrentReportActions.CURRENT_REPORT_GENERATE_PDF_ERROR:
    case SearchDonorRequestActions.ADULT_SEARCH_RESULTS_DONOR_SET_CREATE_ERROR:
    case SearchDonorRequestActions.CORD_SEARCH_RESULTS_CORD_SET_CREATE_ERROR:
    case SearchDonorRequestActions.ADULT_SEARCH_RESULTS_DONOR_SET_UPDATE_ERROR:
    case SearchDonorRequestActions.CORD_SEARCH_RESULTS_CORD_SET_UPDATE_ERROR:
    case CurrentReportActions.CURRENT_REPORT_DOWNLOAD_PDF_FINAL_ATTEMPT_ERROR:
    case SearchResultsExternalInvestigationActions.EXTERNAL_INVESTIGATIONS_CREATE_IDM_REQUEST_ERROR:
    case SearchResultsExternalInvestigationActions.EXTERNAL_INVESTIGATIONS_CREATE_EXTENDED_TYPING_REQUEST_ERROR:
    case SearchResultsExternalInvestigationActions.EXTERNAL_INVESTIGATIONS_CREATE_VT_REQUEST_ERROR:
    case ExternalInvestigationsActions.CANCEL_EXTERNAL_INVESTIGATIONS_ERROR:
    case ExternalInvestigationsActions.GENERATE_VIROLOGY_REPORT_ERROR:
    case ExternalInvestigationsActions.EXTERNAL_INVESTIGATION_REPORT_DOWNLOAD_PDF_FINAL_ATTEMPT_ERROR:
    case ExternalInvestigationsActions.SAVE_VERIFICATION_TYPING_RESULT_ERROR:
    case ExternalInvestigationsActions.SEND_VERIFICATION_TYPING_RESULT_ERROR:
    case Actions.NOTIFICATION_ERROR:
    case PatientDashboardActions.SEND_PATIENT_TO_WMDA_ERROR:
      return {
        ...state,
        notification: {
          message: (action as NotificationErrorAction).payload.data.globalErrors
            ? `${action.payload.params.errorMessage}: ${(action as NotificationErrorAction).payload.data.globalErrors}`
            : (action.payload.params.errorMessage as string),
          level: 'error',
          timeDisplayed: 13,
        },
      };
    case Actions.ADD_FULL_HLA:
      return {
        ...state,
        fullHla: {
          hlaData: action.payload.hlaData,
          level: action.payload.level,
        },
      };
    case TransplantCentreManagementActions.UPDATED_ACTIONS_RESPONSE_SUCCESS:
      return {
        ...state,
        notification: {
          message: action.payload.params.successMessage,
          level: 'success',
          timeDisplayed: 13,
        },
      };
    case TransplantCentreManagementActions.UPDATED_ACTIONS_RESPONSE_ERROR:
      return {
        ...state,
        notification: {
          message: action.payload.params.errorMessage,
          level: 'error',
          timeDisplayed: 13,
        },
      };
    default:
      return state;
  }
};

import * as React from 'react';
import moment from 'moment';
import _ from 'lodash';
import classNames from 'classnames';
import type {
  ExtendedTypingInvestigationDetails,
  ExternalInvestigation,
  IDMInvestigationDetails,
  VTInvestigationDetails,
} from '../../../../../externalInvestigations/types';
import formatInternationalInstitutionCode from '../../../../../core/helpers/internationalInstitutionCodeFormatter';
import { getExternalInvestigationStatusOrderInTable } from '../../../../../core/constants/externalInvestigationStatuses';
import {
  InfectiousDiseaseMarkerVariants,
  infectiousDiseaseMarkerVariants,
} from '../../../../../core/constants/infectiousDiseaseMarkerVariants';
import {
  ExtendedTypingResolutionVariants,
  extendedTypingResolutionVariants,
} from '../../../../../core/constants/extendedTypingResolutionVariants';
import { isOddNumber } from '../../../../../core/helpers/arrayHelper';
import { externalInvestigationTypes } from '../../../../../core/constants/externalInvestigationTypes';
import { REQUEST_SENT, IN_PROGRESS } from '../../../constants/testRequestStatuses';

export type FormattedInvestigationDetails = {
  id: string;
  cell: string | React.ReactNode;
  tooltipBody: React.ReactNode;
};

export const formatInvestigationDetails = (investigation: ExternalInvestigation): FormattedInvestigationDetails => {
  let tooltipBody;
  let cell;
  if (investigation.type === externalInvestigationTypes.idm.value) {
    const { infectiousDiseaseMarkers } = investigation.investigationDetails as IDMInvestigationDetails;
    tooltipBody = (
      <div className="investigation-details idm">
        <div className="investigation-details-container">
          {Object.keys(infectiousDiseaseMarkers)
            .filter((idm) => infectiousDiseaseMarkers[idm])
            .map((idm) => (
              <div key={idm} className="investigation-details-item">
                <b>{infectiousDiseaseMarkerVariants[idm as InfectiousDiseaseMarkerVariants].abbreviation}</b>
              </div>
            ))}
        </div>
        {investigation.remark && (
          <div className="remark">
            <b>Remark:</b> {investigation.remark}
          </div>
        )}
      </div>
    );
    cell = Object.keys(infectiousDiseaseMarkers)
      .filter((idm) => infectiousDiseaseMarkers[idm])
      .map((idm) => infectiousDiseaseMarkerVariants[idm as InfectiousDiseaseMarkerVariants].abbreviation)
      .join(', ');
  }

  if (investigation.type === externalInvestigationTypes.extendedTyping.value) {
    const { resolutionRequired } = investigation.investigationDetails as ExtendedTypingInvestigationDetails;
    const filteredResolutions = Object.keys(resolutionRequired).filter((res) => resolutionRequired[res]);
    tooltipBody = (
      <div className="investigation-details extended-typing">
        <div className="investigation-details-container">
          {filteredResolutions.map((res) => (
            <div key={res} className="investigation-details-item">
              <b>
                {extendedTypingResolutionVariants[res as ExtendedTypingResolutionVariants]}:&nbsp;
                {resolutionRequired[res].substr(0, 1)}
              </b>
            </div>
          ))}
        </div>
        {investigation.remark && (
          <div className="remark">
            <b>Remark:</b> {investigation.remark}
          </div>
        )}
      </div>
    );
    cell = filteredResolutions.map((res, index) => (
      <span key={res}>
        {extendedTypingResolutionVariants[res as ExtendedTypingResolutionVariants]}:&nbsp;
        {resolutionRequired[res].substr(0, 1)}
        {index < filteredResolutions.length - 1 ? ', ' : ''}
      </span>
    ));
  }

  if (investigation.type === externalInvestigationTypes.vt.value) {
    const { sampleRecipientInternationalInstitutionCode } =
      investigation.investigationDetails as VTInvestigationDetails;

    const lab = formatInternationalInstitutionCode(sampleRecipientInternationalInstitutionCode);
    tooltipBody = (
      <div className="investigation-details vt">
        <div className="investigation-details-container">{lab && <b>Lab: {lab}</b>}</div>
        {investigation.remark && (
          <div className="remark">
            <b>Remark:</b> {investigation.remark}
          </div>
        )}
      </div>
    );

    cell = lab && `Lab: ${lab}`;
  }

  const investigationId = investigation.type + investigation.id;

  return { id: investigationId, cell, tooltipBody };
};

export const orderExternalInvestigations = (externalInvestigations: ExternalInvestigation[]): ExternalInvestigation[] =>
  _.orderBy(
    externalInvestigations,
    [
      (investigation) => getExternalInvestigationStatusOrderInTable(investigation.status),
      (investigation) => moment(investigation.requestedTime),
    ],
    ['asc', 'desc']
  );

export const isRowDisabled = (rowData: ExternalInvestigation): boolean => {
  const { status, isIdmResultReceived, isTypingResultReceived, investigationDetails } = rowData;
  // eslint-disable-next-line no-param-reassign
  if (status === REQUEST_SENT) {
    return false;
  }
  if (status === IN_PROGRESS) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: Unreachable code error
    const { sampleArrivalDate } = investigationDetails;

    if (!sampleArrivalDate) {
      return false;
    }

    const sampleArrivalDateTime = new Date(sampleArrivalDate).getTime();
    const notPass = sampleArrivalDateTime > Date.now();

    if (!isIdmResultReceived && !isTypingResultReceived && notPass) {
      return false;
    }

    return true;
  }

  return true;
};

export const getRowClassName = (
  rowIndex: number,
  selectedRowIndexes: number[],
  selectedRequestIndex: number,
  requestsWithActionRequired: number[]
) => {
  const periodicityClass = isOddNumber(rowIndex) ? 'odd' : 'even';

  const selectedClass = (selectedRowIndexes.includes(rowIndex) || selectedRequestIndex === rowIndex) && 'selected';

  const actionRequiredClass = requestsWithActionRequired.includes(rowIndex) && 'action-required';

  return classNames('ext-investigations-table-row', periodicityClass, selectedClass, actionRequiredClass);
};

import { LOCATION_CHANGE, RouterState } from 'connected-react-router';
import { Actions, FeatureFlagsFetchErrorAction, FeatureFlagsFetchSuccessAction } from './actions';
import type { Features } from '../types';
import { featureFlags } from '../../config';

type LocationChangeAction = { type: typeof LOCATION_CHANGE; payload: RouterState };
type UsersActions = FeatureFlagsFetchSuccessAction | FeatureFlagsFetchErrorAction | LocationChangeAction;

export type FeatureFlagsState = {
  hasErrored: boolean;
  message?: string;
  features: Features;
};

const initialState: FeatureFlagsState = {
  hasErrored: false,
  features: {},
};

export default (state: FeatureFlagsState = initialState, action: UsersActions): FeatureFlagsState => {
  switch (action.type) {
    case Actions.FEATURE_FLAGS_SUCCESS:
      return {
        ...state,
        hasErrored: false,
        message: undefined,
        features: action.payload.data,
      };
    case Actions.FEATURE_FLAGS_ERROR:
      return {
        ...state,
        hasErrored: true,
        message: `Error while fetching Feature Flags from API - ${action.payload.response.status}`,
        features: featureFlags.defaultFeatures,
      };
    case LOCATION_CHANGE:
      return {
        ...state,
        hasErrored: false,
        message: undefined,
      };
    default:
      return state;
  }
};

import type { ReduxState } from '../../rootReducer';
import type { User } from '../types';
import type { SearchTeamUsersState } from './reducer';
import type { ApiSearchRequest } from '../../donorMatchSearchRequests/types/api';

const getSearchTeamState = (state: ReduxState): SearchTeamUsersState => state.searchTeamUsers;

const getUsers = (state: ReduxState): { [string: string]: User } => getSearchTeamState(state).users;

const getCurrentUsers = (state: ReduxState): User[] => {
  // $FlowExpectedError - Incorrectly interpreting Object.values type
  const users: User[] = Object.values(getUsers(state));
  return users.filter((user) => !user.IsObsolete);
};

const getSelectedUserState = (state: ReduxState): User | null | undefined => {
  const user = state.latestSearchRequests.selectedUser;
  return user ? getUsers(state)[user] : undefined;
};

const getUserSearchRequests = (state: ReduxState): ApiSearchRequest[] => {
  const selectedUserDetails = getSelectedUserState(state);
  if (selectedUserDetails && selectedUserDetails.requestIds) {
    return selectedUserDetails.requestIds.map(
      (requestId: string) => state.latestSearchRequests.searchRequests.requests[requestId]
    );
  }
  return [];
};

const hasUserFetchErrored = (state: ReduxState): boolean => getSearchTeamState(state).hasErrored;

const getUserFetchErrorMessage = (state: ReduxState): string[] => {
  const error = getSearchTeamState(state).message;
  return error ? [error] : [];
};

export default {
  getUsers,
  getCurrentUsers,
  getUserSearchRequests,
  getUserFetchErrorMessage,
  hasUserFetchErrored,
};

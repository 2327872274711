import { combineReducers } from 'redux';
// eslint-disable-next-line import/no-cycle
import searchResultsData, { SearchResultsDataReducerState } from './resultsPageData/searchResultsData';
// eslint-disable-next-line import/no-cycle
import resultsPageUi, { ResultsPageUIReducerState } from './resultsPageUI/resultsPageUi';
// eslint-disable-next-line import/no-cycle
import externalInvestigationCreation, {
  ExternalInvestigationCreationReducerState,
} from '../components/ExternalInvestigations/redux/ExternalInvestigationCreation/externalInvestigationCreation';

export type SearchResultsPageReducerState = {
  externalInvestigationCreation: ExternalInvestigationCreationReducerState;
  searchResultsData: SearchResultsDataReducerState;
  resultsPageUi: ResultsPageUIReducerState;
};

const searchResultsPage = combineReducers<SearchResultsPageReducerState>({
  externalInvestigationCreation,
  searchResultsData,
  resultsPageUi,
});

export default searchResultsPage;

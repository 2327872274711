import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PatientDashboardSelectors from '../redux/selectors';
import { Button } from '../../../core/components/Button';
import { sendPatientToWmda } from '../redux/actions';
import { ReduxState } from '../../../rootReducer';

type Props = {
  patientId: string;
};

export const SendPatientToWmdaButton = ({ patientId }: Props) => {
  const dispatch = useDispatch();
  const handleSubmit = () => dispatch(sendPatientToWmda(patientId));

  const isSendingPatientToWmda = useSelector((state) =>
    PatientDashboardSelectors.getIsSendingPatientToWmda(state as ReduxState)
  );

  return <Button text="Send to WMDA" onClick={handleSubmit} loading={isSendingPatientToWmda} />;
};

import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { MsalProvider } from '@azure/msal-react';
import { initialiseMsal } from '@an/nova-frontend-rest-client/lib/auth';
import './assets/styles/app.scss';
import configureStore from './store';
import { authScopes, msalInstance } from './config';
import { history } from './core';
import App from './App';

const store = configureStore();
initialiseMsal(msalInstance, authScopes);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

import type { ReduxState } from '../../rootReducer';
import type { UserRole } from '../constants';

const getCurrentUserRoles = (state: ReduxState): UserRole[] | undefined =>
  state && state.currentUser ? state.currentUser.roles : undefined;

const isFetchingCurrentUser = (state: ReduxState): boolean =>
  state && state.currentUser && state.currentUser.isFetching;

const isAuthorised = (state: ReduxState): boolean =>
  (state && state.currentUser && state.currentUser.isAuthorised) || false;

const isSearchAuthoriser = (state: ReduxState): boolean =>
  state && state.currentUser && state.currentUser.roles ? state.currentUser.roles.includes('search-authoriser') : false;

export default {
  getCurrentUserRoles,
  isAuthorised,
  isFetchingCurrentUser,
  isSearchAuthoriser,
};

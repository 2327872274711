import { LOCATION_CHANGE, RouterState } from 'connected-react-router';
import { Moment } from 'moment';
import * as SearchTypes from '../helpers/patientSearchTypes';
// eslint-disable-next-line import/no-cycle
import {
  Actions as DonorMatchActions,
  PatientSearchRequestsSuccessAction,
} from '../../../donorMatchSearchRequests/redux/actions';
// eslint-disable-next-line import/no-cycle
import {
  Actions,
  PatientFetchErrorAction,
  PatientFetchSuccessAction,
  PatientSummariesFetchErrorAction,
  PatientSummariesFetchSuccessAction,
  PatientUpdateSearchTypeAction,
  SetSearchPatientDobAction,
  SetSearchPatientIdAction,
  SetSearchPatientSurnameAction,
  PatientSummariesFetchRequestAction,
  PatientFetchRequestAction,
  PatientResetSummariesAction,
  PatientResetErrorAction,
} from './actions';
// eslint-disable-next-line import/no-cycle
import {
  Actions as PatientCreationActions,
  PatientSetPatientGeneticsSuccessAction,
  PatientUpdatePatientDetailsSuccessAction,
} from '../../patientCreation/redux/actions';
// eslint-disable-next-line import/no-cycle
import {
  Actions as SavedReportActions,
  PatientSavedReportsSuccessAction,
} from '../../../searchReports/savedReports/redux/actions';
// eslint-disable-next-line import/no-cycle
import {
  Actions as PatientDashboardActions,
  PatientSetRepeatSearchDate,
  PatientFetchRepeatSearchDateSuccessAction,
} from '../../patientDashboard/redux/actions';
// eslint-disable-next-line import/no-cycle
import { convertPatientInfoFromApi } from '../helpers/apiDataConverter';
import type { PatientSearchType, PatientSummary } from '../../types';

type LocationChangeAction = { type: typeof LOCATION_CHANGE; payload: RouterState };
type UsersActions =
  | PatientFetchErrorAction
  | PatientFetchSuccessAction
  | PatientSummariesFetchErrorAction
  | PatientSummariesFetchSuccessAction
  | PatientUpdateSearchTypeAction
  | SetSearchPatientDobAction
  | SetSearchPatientIdAction
  | SetSearchPatientSurnameAction
  | PatientSetPatientGeneticsSuccessAction
  | PatientUpdatePatientDetailsSuccessAction
  | PatientSavedReportsSuccessAction
  | PatientSetRepeatSearchDate
  | PatientFetchRepeatSearchDateSuccessAction
  | PatientSummariesFetchRequestAction
  | PatientFetchRequestAction
  | PatientResetSummariesAction
  | PatientResetErrorAction
  | PatientSearchRequestsSuccessAction
  | LocationChangeAction;

export type PatientSearchReducerState = {
  errorMessage?: string;
  hasErrored: boolean;
  isFetching: boolean;
  patientSummaries?: PatientSummary[];
  searchedPatientDob?: Moment;
  searchedPatientId: string;
  searchedPatientSurname?: string;
  searchType: PatientSearchType;
  searchRequests?: Array<unknown>;
};

const initialState: PatientSearchReducerState = {
  hasErrored: false,
  isFetching: false,
  searchType: SearchTypes.PATIENT_SEARCH_BY_ID,
  searchedPatientId: '',
};

export default (state: PatientSearchReducerState = initialState, action: UsersActions): PatientSearchReducerState => {
  switch (action.type) {
    case Actions.PATIENT_FETCH_SUCCESS: {
      if (action.payload) {
        const newPatient = convertPatientInfoFromApi(action.payload.data);
        return {
          ...state,
          [newPatient.id]: {
            // @ts-expect-error TODO EM-1787: allow typescript to account for combination of dynamic and static keys in PatientSearchReducerState
            ...(state[newPatient.id] as Record<string, unknown>),
            patientInfo: newPatient,
          },
          isFetching: false,
          hasErrored: false,
        };
      }
      return state;
    }
    case Actions.PATIENT_SUMMARIES_FETCH_SUCCESS:
      return {
        ...state,
        patientSummaries: action.payload.data,
        isFetching: false,
        hasErrored: false,
      };
    case Actions.PATIENT_FETCH_REQUEST:
    case Actions.PATIENT_SUMMARIES_FETCH_REQUEST:
      return {
        ...state,
        isFetching: true,
        hasErrored: false,
      };
    case Actions.PATIENT_FETCH_ERROR:
      return {
        ...state,
        isFetching: false,
        hasErrored: true,
        errorMessage: `Error while fetching Patient ${action.payload.params.patientId} - ${action.payload.response.status}. Please check the patient Id`,
      };
    case Actions.PATIENT_SUMMARIES_FETCH_ERROR:
      return {
        ...state,
        isFetching: false,
        hasErrored: true,
        errorMessage: `Error while fetching Patients - ${action.payload.response.status}. Please check the patient Name and/or Date of Birth`,
      };
    case Actions.PATIENT_RESET_SUMMARIES:
      return {
        ...state,
        patientSummaries: undefined,
        isFetching: false,
        hasErrored: false,
      };
    case Actions.PATIENT_RESET_ERROR:
      return {
        ...state,
        hasErrored: false,
        errorMessage: undefined,
      };
    case Actions.PATIENT_SET_SEARCHED_PATIENT_ID:
      return {
        ...state,
        searchedPatientId: action.payload,
      };
    case Actions.PATIENT_SET_SEARCHED_PATIENT_SURNAME:
      return {
        ...state,
        searchedPatientSurname: action.payload,
      };
    case Actions.PATIENT_SET_SEARCHED_PATIENT_DOB:
      return {
        ...state,
        searchedPatientDob: action.payload,
      };
    case DonorMatchActions.PATIENTS_SEARCH_REQUESTS_SUCCESS:
      return {
        ...state,
        [action.payload.params.patientId]: {
          // @ts-expect-error TODO EM-1787: allow typescript to account for combination of dynamic and static keys in PatientSearchReducerState
          ...(state[action.payload.params.patientId as keyof PatientSearchReducerState] as Record<string, unknown>),
          searchRequests: action.payload.data.result,
        },
      };
    case SavedReportActions.PATIENTS_SAVED_REPORTS_SUCCESS:
      return {
        ...state,
        [action.payload.params.patientId]: {
          // @ts-expect-error TODO EM-1787: allow typescript to account for combination of dynamic and static keys in PatientSearchReducerState
          ...(state[action.payload.params.patientId as keyof PatientSearchReducerState] as Record<string, unknown>),
          savedReports: action.payload.data.result,
        },
      };
    case Actions.PATIENT_UPDATE_SEARCH_TYPE:
      return {
        ...state,
        searchType: action.payload,
      };
    case PatientDashboardActions.PATIENT_REPEAT_SEARCH_DATE_SET:
      return {
        ...state,
        [action.payload.patientId]: {
          // @ts-expect-error TODO EM-1787: allow typescript to account for combination of dynamic and static keys in PatientSearchReducerState
          ...(state[action.payload.patientId] as Record<string, unknown>),
          repeatSearchDate: action.payload.date,
        },
      };
    case PatientDashboardActions.FETCH_REPEAT_SEARCH_DATE_SUCCESS:
      return {
        ...state,
        [action.payload.params.patientId]: {
          // @ts-expect-error TODO EM-1787: allow typescript to account for combination of dynamic and static keys in PatientSearchReducerState
          ...(state[action.payload.params.patientId] as Record<string, unknown>),
          repeatSearchDate: action.payload?.data,
        },
      };
    case PatientCreationActions.PATIENT_UPDATE_PATIENT_DETAILS_SUCCESS:
      return {
        ...state,
        [action.payload.data]: null,
      };
    case PatientCreationActions.PATIENT_SET_PATIENT_GENETICS_SUCCESS:
      return {
        ...state,
        [action.payload.data]: null,
      };
    case LOCATION_CHANGE:
      return {
        ...state,
        isFetching: false,
        hasErrored: false,
      };
    default:
      return state;
  }
};

import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import Select, { SingleValue } from 'react-select';
import { AnyAction, bindActionCreators, Dispatch as ReduxDispatch } from 'redux';
import * as actions from '../redux/actions';
import Selectors from '../../core/redux/selectors';
import * as SearchTypes from '../helpers/patientSearchTypes';

import type { PatientSearchType } from '../../types';
import type { ReduxState } from '../../../rootReducer';

type StateProps = {
  searchType: PatientSearchType;
};
type Props = PropsFromRedux & StateProps;

type FilterOption = {
  value: PatientSearchType;
  label: string | undefined;
  clearableValue: boolean;
};

const filterOptions: FilterOption[] = [
  {
    value: SearchTypes.PATIENT_SEARCH_BY_ID,
    label: 'Search by ID',
    clearableValue: false,
  },
  {
    value: SearchTypes.PATIENT_SEARCH_BY_NAME_DOB,
    label: 'Search by Name and DOB',
    clearableValue: false,
  },
];

const mapStateToProps = (state: ReduxState): StateProps => ({
  searchType: Selectors.getSearchType(state),
});

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>) => ({
  updateSearchType: bindActionCreators(actions.updateSearchType, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const labelFromValue = (value: string) => {
  const files = filterOptions.find((o) => o.value === value);
  return files ? files.label : undefined;
};

class SearchFilter extends PureComponent<Props> {
  render() {
    const { searchType } = this.props;
    return (
      <Select
        id="search-filter"
        isClearable={false}
        value={{
          value: searchType,
          label: labelFromValue(searchType),
          clearableValue: false,
        }}
        options={filterOptions}
        onChange={this.updateSearchType}
        className="react-select-container"
        classNamePrefix="react-select"
        placeholder="Search by.."
      />
    );
  }

  updateSearchType = (newValue: SingleValue<FilterOption>) => {
    const { updateSearchType } = this.props;
    if (newValue) {
      updateSearchType(newValue.value);
    }
  };
}

export default connector(SearchFilter);

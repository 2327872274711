import React, { PureComponent } from 'react';
import { AnyAction, bindActionCreators, Dispatch as ReduxDispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import * as actions from '../redux/actions';
import { SavedReportsSelectors } from '../../core/redux/selectors';
import type { ReduxState } from '../../../rootReducer';

type StateProps = {
  awaitingAuthCount: number;
};

const mapStateToProps = (state: ReduxState): StateProps => ({
  awaitingAuthCount: SavedReportsSelectors.reportsAwaitingAuthCount(state),
});

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>) => ({
  getReportsAwaitingAuthorisation: bindActionCreators(actions.getReportsAwaitingAuthorisation, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & StateProps;

class AwaitingAuthorisationSummary extends PureComponent<Props> {
  componentDidMount() {
    const { getReportsAwaitingAuthorisation } = this.props;
    getReportsAwaitingAuthorisation();
  }

  render() {
    const { awaitingAuthCount } = this.props;
    return (
      <div style={{ paddingTop: '20px' }}>
        <h2 className="border-bottom-solid">Awaiting Authorisation</h2>
        <div>
          {awaitingAuthCount === 0 ? (
            <p>There currently are no reports awaiting authorisation.</p>
          ) : (
            <p>
              There {awaitingAuthCount > 1 ? `are currently ${awaitingAuthCount} reports` : 'is currently 1 report'}{' '}
              awaiting authorisation.
            </p>
          )}
          <Link to="reports/dashboard" className="btn btn--inline">
            View Reports Dashboard
          </Link>
        </div>
      </div>
    );
  }
}

export default connector(AwaitingAuthorisationSummary);

import { matchGradeConstants } from '../../../core';
import {
  createDonorRows,
  convertCmvOrRhd,
  convertAbo,
  Column,
  columnTypes,
  DonorIdVerificationStatus,
  HlaColumn,
  NumberColumn,
  StringColumn,
  TableContents,
} from './searchResultsTableContents';
import type { AdultDonor, AdultSearchResult } from '../../../core/types';
import formatGrid from '../../../core/helpers/gridFormatter';
import { formatDate } from '../../../core/helpers/dateHelpers';
import alignedRegistryPrefixes from '../../constants/alignedRegistryPrefixes';

const convertMatchGrade = (apiMatchType: string): string => {
  switch (apiMatchType) {
    case matchGradeConstants.matchGrade.Exact:
      return matchGradeConstants.matchGradeSymbols.Exact;
    case matchGradeConstants.matchGrade.Potential:
      return matchGradeConstants.matchGradeSymbols.Potential;
    case matchGradeConstants.matchGrade.Mismatch:
      return matchGradeConstants.matchGradeSymbols.Mismatch;
    case matchGradeConstants.matchGrade.MinorMismatch:
      return matchGradeConstants.matchGradeSymbols.MinorMismatch;
    default:
      return '';
  }
};

export const getDonorIdArray = (donor: AdultDonor) => {
  const internalId = donor.donorRegistryPrefix + donor.id;
  const registryId =
    donor.donorRegistryPrefix === alignedRegistryPrefixes.anthonyNolan ? internalId : donor.homeRegistryId;
  const grid = donor.grid ? formatGrid(donor.grid) : '';
  return [registryId, grid];
};

const convertDonatedStatus = (value: boolean) => (value ? 'Y' : '');

const getAdultColumns = (isExternal: boolean) => {
  const adultColumns: Column<AdultSearchResult, any>[] = [
    new Column<AdultSearchResult, void>('RowNumber', '', () => undefined, columnTypes.rowNumber, 40),
    new Column<AdultSearchResult, string>(
      'SelectionColumn',
      '',
      (searchResult) => searchResult.donor.id,
      columnTypes.selectColumn,
      40
    ),
    new Column<AdultSearchResult, string[]>(
      'MatchGrades',
      '',
      (searchResult) => [convertMatchGrade(searchResult.matchGrade)],
      columnTypes.matchGrade,
      30
    ),
    new Column<AdultSearchResult, DonorIdVerificationStatus>(
      'DonorId',
      'Donor ID',
      (searchResult) => ({
        donorId: getDonorIdArray(searchResult.donor),
        verificationStatus: searchResult.verificationStatus,
      }),
      columnTypes.donorId,
      isExternal ? 220 : 240
    ),
    new HlaColumn<AdultSearchResult>('A', 'A', (searchResult) => ({
      antigens: [searchResult.donor.hlaA1, searchResult.donor.hlaA2],
    })),
    new HlaColumn<AdultSearchResult>('B', 'B', (searchResult) => ({
      antigens: [searchResult.donor.hlaB1, searchResult.donor.hlaB2],
    })),
    new HlaColumn<AdultSearchResult>('C', 'C', (searchResult) => ({
      antigens: [searchResult.donor.hlaC1, searchResult.donor.hlaC2],
    })),
    new HlaColumn<AdultSearchResult>('DRB1', 'DRB1', (searchResult) => ({
      antigens: [searchResult.donor.drB11, searchResult.donor.drB12],
    })),
    new HlaColumn<AdultSearchResult>('DRB3', 'DRB3', (searchResult) => ({
      antigens: [searchResult.donor.drB31, searchResult.donor.drB32],
    })),
    new HlaColumn<AdultSearchResult>('DRB4', 'DRB4', (searchResult) => ({
      antigens: [searchResult.donor.drB41, searchResult.donor.drB42],
    })),
    new HlaColumn<AdultSearchResult>('DRB5', 'DRB5', (searchResult) => ({
      antigens: [searchResult.donor.drB51, searchResult.donor.drB52],
    })),
    new HlaColumn<AdultSearchResult>('DQA1', 'DQA1', (searchResult) => ({
      antigens: [searchResult.donor.dqA11, searchResult.donor.dqA12],
    })),
    new HlaColumn<AdultSearchResult>('DQB1', 'DQB1', (searchResult) => ({
      antigens: [searchResult.donor.dqB11, searchResult.donor.dqB12],
    })),
    new HlaColumn<AdultSearchResult>('DPA1', 'DPA1', (searchResult) => ({
      antigens: [searchResult.donor.dpA11, searchResult.donor.dpA12],
    })),
    new HlaColumn<AdultSearchResult>(
      'DPB1',
      'DPB1',
      (searchResult) => ({
        antigens: [searchResult.donor.dpB11, searchResult.donor.dpB12],
        locusMatchCategory: searchResult.locusDpb1Info?.matchCategory,
      }),
      140
    ),
    new StringColumn<AdultSearchResult>(
      'Gender',
      'Gender',
      (searchResult) => searchResult.donor.gender,
      isExternal ? 80 : 100
    ),
    new NumberColumn<AdultSearchResult>('Age', 'Age', (searchResult) => searchResult.donor.age, isExternal ? 60 : 100),
    new StringColumn<AdultSearchResult>(
      'Abo',
      'ABO',
      (searchResult) => convertAbo(searchResult.donor.abo),
      isExternal ? 60 : 95
    ),
    new StringColumn<AdultSearchResult>(
      'RhD',
      'RhD',
      (searchResult) => convertCmvOrRhd(searchResult.donor.rhd),
      isExternal ? 75 : 95,
      true
    ),
    new StringColumn<AdultSearchResult>(
      'CMV',
      'CMV',
      (searchResult) => convertCmvOrRhd(searchResult.donor.cmv),
      isExternal ? 75 : 95
    ),
    new StringColumn<AdultSearchResult>(
      'CmvTested',
      'Tested',
      (searchResult) => (searchResult.donor.dateCmvTested ? formatDate(searchResult.donor.dateCmvTested) : ''),
      100,
      true
    ),
    new StringColumn<AdultSearchResult>('State', 'State', (searchResult) => searchResult.donor.state, 100, true),
    new StringColumn<AdultSearchResult>(
      'Donated',
      'Donated',
      (searchResult) => convertDonatedStatus(searchResult.donor.donated),
      100
    ),
    new StringColumn<AdultSearchResult>(
      'Contacted',
      'Contacted',
      (searchResult) => searchResult.donor.lastContacted,
      100,
      true
    ),
    new StringColumn<AdultSearchResult>(
      'UnavailableUntil',
      'Unavailable Until',
      (searchResult) => (searchResult.donor.unavailableUntil ? formatDate(searchResult.donor.unavailableUntil) : ''),
      130,
      true
    ),
  ];
  return adultColumns;
};

const externalColumns = getAdultColumns(true);
const internalColumns = getAdultColumns(false);

const adultExternalColumns = [
  ...externalColumns.slice(0, 3),
  new StringColumn<AdultSearchResult>(
    'Registry',
    'Registry',
    (searchResult) => searchResult.donor.originatingRegistry.name,
    200
  ),
  ...externalColumns.slice(3),
];

const createRows = (searchResults: AdultSearchResult[], columns: Column<AdultSearchResult, unknown>[]) =>
  createDonorRows<AdultSearchResult>(searchResults, columns);

export const adultTableContents: TableContents<AdultSearchResult> = {
  columns: internalColumns,
  createRows,
};

export const adultExternalTableContents: TableContents<AdultSearchResult> = {
  columns: adultExternalColumns,
  createRows,
};

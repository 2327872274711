import _ from 'lodash';

const EXTERNAL_CONFIRMATORY_TYPING: 'ExternalConfirmatoryTyping' = 'ExternalConfirmatoryTyping';
const VIROLOGY: 'Virology' = 'Virology';
const FIRST_TIME_DONOR: 'FirstTimeDonor' = 'FirstTimeDonor';
const DR_LOCI_TYPING: 'DRLociTyping' = 'DRLociTyping';
const CONFIRMATORY_TYPING: 'ConfirmatoryTyping' = 'ConfirmatoryTyping';

export const investigationTypes = {
  externalConfirmatoryTyping: {
    value: EXTERNAL_CONFIRMATORY_TYPING,
    displayName: 'External Confirmatory Typing (CTX)',
  },
  virology: {
    value: VIROLOGY,
    displayName: 'Virology (CMV)',
  },
  firstTimeDonor: {
    value: FIRST_TIME_DONOR,
    displayName: 'First Time Donor (FTD)',
  },
  drLociTyping: {
    value: DR_LOCI_TYPING,
    displayName: 'DR Loci Typing (DR)',
  },
  confirmatoryTyping: {
    value: CONFIRMATORY_TYPING,
    displayName: 'Confirmatory Typing (CT)',
  },
};

export const investigationTypeDisplayString = (value: string) =>
  _.find(investigationTypes, (type) => type.value === value)?.displayName;

import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch as ReduxDispatch } from 'redux';
import { externalInvestigationTypes } from '../../../core/constants/externalInvestigationTypes';
import { FeatureFlag } from '../../../featureFlags';
import { ReduxState } from '../../../rootReducer';
import * as actions from './redux/actions';
import Selectors from './redux/selectors';
import CoreSelectors from '../../../patient/core/redux/selectors';
import NewExtendedTypingRequest from './ExtendedTypingRequestForm/NewExtendedTypingRequest';
import NewIDMRequests from './IDMRequestForm/NewIDMRequests';
import type { ExternalInvestigationSelectedDonor, Laboratory } from './types';
import NewVTRequests from './VerificationTypingRequestForm/NewVTRequests';

type OwnProps = {
  adjustStyling: (arg0: Record<string, unknown>) => void;
  onPopUpClose: () => void;
  onClose: (e: React.MouseEvent<HTMLButtonElement>) => void;
  patientId: string;
  selectedItems: ExternalInvestigationSelectedDonor[];
};

type StateProps = {
  laboratoryOptions?: Laboratory[];
};

const mapStateToProps = (state: ReduxState, ownProps: OwnProps): StateProps => ({
  // @ts-expect-error TODO EM-1787: allow typescript to account for combination of dynamic and static keys in PatientSearchReducerState
  patient: CoreSelectors.getPatient(state, ownProps.patientId),
  laboratoryOptions: Selectors.getFilteredLaboratoryOptions(state),
  showExtendedTypingForm: false,
  showVTForm: false,
});

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>) => ({
  getFilteredLaboratoryOptions: bindActionCreators(actions.getFilteredLaboratoryOptions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = PropsFromRedux & OwnProps & StateProps;

type PropsFromRedux = ConnectedProps<typeof connector>;

const idmFormStyle = {
  maxHeight: '90%',
  width: '70%',
};

const extendedTypingFormStyle = {
  maxHeight: '90%',
  width: '40%',
  minWidth: '650px',
};

const ExternalInvestigations = (props: Props) => {
  const { onPopUpClose, onClose, patientId, selectedItems, laboratoryOptions } = props;
  const [showIDMForm, setShowIDMForm] = useState(false);
  const [showExtendedTypingForm, setShowExtendedTypingForm] = useState(false);
  const [showVTForm, setShowVTForm] = useState(false);
  const [showButtons, setShowButtons] = useState(true);
  const isDisabled = selectedItems.length > 1;
  const selectedDonor = selectedItems[0];
  const idmSelectedDonorsInitialState = selectedItems.map((donor) => ({
    id: donor.id,
    grid: donor.grid,
    remark: undefined,
    originatingRegistry: donor.originatingRegistry,
  }));
  const vtSelectedDonorsInitialState = selectedItems.map((donor) => ({
    id: donor.id,
    grid: donor.grid,
    urgent: false,
    remark: undefined,
    originatingRegistry: donor.originatingRegistry,
    // TODO: In CheckboxOptions the 'acknowledgementRequired' option will be a future implementation
    // acknowledgementRequired
  }));
  useEffect(() => {
    const { getFilteredLaboratoryOptions } = props;
    getFilteredLaboratoryOptions();
  }, []);

  const handleIDMClick = () => {
    const { adjustStyling } = props;
    setShowIDMForm(true);
    setShowButtons(false);
    adjustStyling(idmFormStyle);
  };

  const handleExtendedTypingClick = () => {
    const { adjustStyling } = props;
    setShowExtendedTypingForm(true);
    setShowButtons(false);
    adjustStyling(extendedTypingFormStyle);
  };

  const handleVTClick = () => {
    const { adjustStyling } = props;
    setShowVTForm(true);
    setShowButtons(false);
    adjustStyling(extendedTypingFormStyle);
  };

  return (
    <div>
      {showButtons && (
        <div className="chooseRequestPopUp">
          <h2 className="border-bottom-solid">Select a Request Type</h2>
          {selectedItems.length > 1 && (
            <div data-testid="error" className="warning-message" style={{ margin: '0' }}>
              Note: multiple donors selected. Currently only IDM and VT requests can be sent for more than one donor at
              a time.
            </div>
          )}
          <div className="buttonGroup">
            <FeatureFlag
              flag="showIdmRequestButton"
              render={() => (
                <button className="btn" onClick={handleIDMClick} type="button">
                  {externalInvestigationTypes.idm.name}
                </button>
              )}
            />
            <FeatureFlag
              flag="showExtendedTypingRequestButton"
              render={() => (
                <button className="btn" onClick={handleExtendedTypingClick} disabled={isDisabled} type="button">
                  {externalInvestigationTypes.extendedTyping.name}
                </button>
              )}
            />
            <FeatureFlag
              flag="showVerificationTypingRequestButton"
              render={() => (
                <button className="btn" onClick={handleVTClick} type="button">
                  {externalInvestigationTypes.vt.name}
                </button>
              )}
            />
          </div>
        </div>
      )}
      {showIDMForm && (
        <NewIDMRequests
          patientId={patientId}
          idmSelectedDonorsInitialState={idmSelectedDonorsInitialState}
          onPopUpClose={onPopUpClose}
          onClose={onClose}
        />
      )}
      {showExtendedTypingForm && (
        <NewExtendedTypingRequest
          patientId={patientId}
          selectedDonor={selectedDonor}
          onPopUpClose={onPopUpClose}
          onClose={onClose}
        />
      )}
      {showVTForm && (
        <NewVTRequests
          patientId={patientId}
          onPopUpClose={onPopUpClose}
          onClose={onClose}
          vtSelectedDonorsInitialState={vtSelectedDonorsInitialState}
          laboratoryOptions={laboratoryOptions}
        />
      )}
    </div>
  );
};

ExternalInvestigations.defaultProps = {
  laboratoryOptions: undefined,
};

export default connector(ExternalInvestigations);

import _ from 'lodash';
import { TextMessage } from '../types';
import { ExternalInvestigationTextMessage } from '../../../externalInvestigations/types';

export const orderTextMessages = (textMessages: TextMessage[]): TextMessage[] =>
  _.orderBy(textMessages, [(textMessage) => textMessage.SentDate], ['desc']);

export const orderSampleInfoTextMessages = (
  textMessages: ExternalInvestigationTextMessage[]
): ExternalInvestigationTextMessage[] => _.orderBy(textMessages, [(textMessage) => textMessage.sentDate], ['desc']);

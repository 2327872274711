import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, AnyAction, Dispatch as ReduxDispatch } from 'redux';
import * as actions from '../redux/actions';
import { CurrentReportSelectors } from '../../core/redux/selectors';
import EditableTransplantCentreDetails from './EditableTransplantCentreDetails';
import { ErrorMessage, LoadingMessage } from '../../../core';

import type { ErrorMessages } from '../../../core/types';
import type { ReduxState } from '../../../rootReducer';

type DispatchProps = {
  editTransplantCentre: typeof actions.editTransplantCentre;
};

type StateProps = {
  errorMessage: ErrorMessages;
  hasErrored: boolean;
  isFetching: boolean;
  isReportReadOnly: boolean;
  transplantCentre: string[];
};
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

const mapStateToProps = (state: ReduxState): StateProps => ({
  transplantCentre: CurrentReportSelectors.getTransplantCentreAddress(state),
  isReportReadOnly: CurrentReportSelectors.isReportReadOnly(state),
  hasErrored: CurrentReportSelectors.hasfetchTransplantCentreHasErrored(state),
  errorMessage: CurrentReportSelectors.getTransplantCentreErrorMessage(state),
  isFetching: CurrentReportSelectors.isTransplantCentreFetching(state),
});

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>): DispatchProps => ({
  editTransplantCentre: bindActionCreators(actions.editTransplantCentre, dispatch),
});

class TransplantCentre extends PureComponent<Props> {
  static splitDetailsIntoArray = (details: string) => details.split('\n');

  render() {
    const { errorMessage, hasErrored, isFetching } = this.props;
    return (
      <div>
        <h2 className="border-bottom-solid" style={{ marginTop: '20px' }}>
          Transplant Centre Details
        </h2>
        <LoadingMessage isLoading={isFetching}>
          {hasErrored ? <ErrorMessage errorMessages={errorMessage} /> : this.renderTransplantCentreDetails()}
        </LoadingMessage>
      </div>
    );
  }

  renderTransplantCentreDetails = () => {
    const { isReportReadOnly, transplantCentre } = this.props;
    return (
      <EditableTransplantCentreDetails
        centreDetails={transplantCentre}
        detailsEdit={this.handleTransplantCentreDetailsEdit}
        isReportReadOnly={isReportReadOnly}
      />
    );
  };

  handleTransplantCentreDetailsEdit = (editedDetails: string) => {
    const { editTransplantCentre } = this.props;
    const centreDetailsArray = TransplantCentre.splitDetailsIntoArray(editedDetails);
    editTransplantCentre(centreDetailsArray);
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(TransplantCentre);

import mismatchFilterValues from '../../core/constants/mismatchFilterValues';
import type { MismatchesFilterValue } from '../types';
import type { AdultSearchResult } from '../../core/types';

const checkLociMatchExceptOne = (
  result: AdultSearchResult,
  lociToCheck: string[],
  locusToExcept: string | null
): boolean => {
  const { locusAInfo, locusBInfo, locusCInfo, locusDrb1Info, locusDqb1Info } = result;
  if (locusAInfo && locusBInfo && locusCInfo && locusDrb1Info && locusDqb1Info) {
    return (
      (locusToExcept === mismatchFilterValues.A ||
        !lociToCheck.includes(mismatchFilterValues.A) ||
        locusAInfo.matchCount === 2) &&
      (locusToExcept === mismatchFilterValues.B ||
        !lociToCheck.includes(mismatchFilterValues.B) ||
        locusBInfo.matchCount === 2) &&
      (locusToExcept === mismatchFilterValues.C ||
        !lociToCheck.includes(mismatchFilterValues.C) ||
        locusCInfo.matchCount === 2) &&
      (locusToExcept === mismatchFilterValues.Drb1 ||
        !lociToCheck.includes(mismatchFilterValues.Drb1) ||
        locusDrb1Info.matchCount === 2) &&
      (locusToExcept === mismatchFilterValues.Dqb1 ||
        !lociToCheck.includes(mismatchFilterValues.Dqb1) ||
        locusDqb1Info.matchCount === 2)
    );
  }

  return false;
};

// donor should have HLA at required loci (A / B / Drb1) to be shown with Mismatches filter other than "Show All"
const checkRequiredLociHasValues = (result: AdultSearchResult): boolean =>
  (result.donor.hlaA1?.hlaName != null || result.donor.hlaA2?.hlaName != null) &&
  (result.donor.hlaB1?.hlaName != null || result.donor.hlaB2?.hlaName != null) &&
  (result.donor.drB11?.hlaName != null || result.donor.drB12?.hlaName != null);

const shouldIncludeDonorDueToMismatches = (
  result: AdultSearchResult,
  filterValue: MismatchesFilterValue,
  lociExcludedFromFilter: string[]
): boolean => {
  const { locusAInfo, locusBInfo, locusCInfo, locusDrb1Info, locusDqb1Info } = result;
  let lociChecked = [
    mismatchFilterValues.A,
    mismatchFilterValues.B,
    mismatchFilterValues.C,
    mismatchFilterValues.Drb1,
    mismatchFilterValues.Dqb1,
  ];
  lociChecked = lociChecked.filter((lc) => !lociExcludedFromFilter.includes(lc));

  if (!checkRequiredLociHasValues(result)) {
    return false;
  }

  if (locusAInfo && locusBInfo && locusCInfo && locusDrb1Info && locusDqb1Info) {
    return (
      // No mismatch
      (filterValue.locus === null && checkLociMatchExceptOne(result, lociChecked, null)) ||
      // Mismatch
      (filterValue.max === 1 &&
        // at A
        ((filterValue.locus === mismatchFilterValues.A &&
          locusAInfo.matchCount === 1 &&
          checkLociMatchExceptOne(result, lociChecked, mismatchFilterValues.A)) ||
          // at B
          (filterValue.locus === mismatchFilterValues.B &&
            locusBInfo.matchCount === 1 &&
            checkLociMatchExceptOne(result, lociChecked, mismatchFilterValues.B)) ||
          // at C
          (filterValue.locus === mismatchFilterValues.C &&
            locusCInfo.matchCount === 1 &&
            checkLociMatchExceptOne(result, lociChecked, mismatchFilterValues.C)) ||
          // at Drb1
          (filterValue.locus === mismatchFilterValues.Drb1 &&
            locusDrb1Info.matchCount === 1 &&
            checkLociMatchExceptOne(result, lociChecked, mismatchFilterValues.Drb1)) ||
          // at Dqb1
          (filterValue.locus === mismatchFilterValues.Dqb1 &&
            locusDqb1Info.matchCount === 1 &&
            checkLociMatchExceptOne(result, lociChecked, mismatchFilterValues.Dqb1)))) ||
      // 2 Mismatches
      (filterValue.max === 2 &&
        // at A
        ((filterValue.locus === mismatchFilterValues.A &&
          (locusAInfo.matchCount === 0 || locusAInfo.matchCount === 1) &&
          checkLociMatchExceptOne(result, lociChecked, mismatchFilterValues.A)) ||
          // at B
          (filterValue.locus === mismatchFilterValues.B &&
            (locusBInfo.matchCount === 0 || locusBInfo.matchCount === 1) &&
            checkLociMatchExceptOne(result, lociChecked, mismatchFilterValues.B)) ||
          // at C
          (filterValue.locus === mismatchFilterValues.C &&
            (locusCInfo.matchCount === 0 || locusCInfo.matchCount === 1) &&
            checkLociMatchExceptOne(result, lociChecked, mismatchFilterValues.C)) ||
          // at Drb1
          (filterValue.locus === mismatchFilterValues.Drb1 &&
            (locusDrb1Info.matchCount === 0 || locusDrb1Info.matchCount === 1) &&
            checkLociMatchExceptOne(result, lociChecked, mismatchFilterValues.Drb1)) ||
          // at Dqb1
          (filterValue.locus === mismatchFilterValues.Dqb1 &&
            (locusDqb1Info.matchCount === 0 || locusDqb1Info.matchCount === 1) &&
            checkLociMatchExceptOne(result, lociChecked, mismatchFilterValues.Dqb1))))
    );
  }
  return false;
};

export { shouldIncludeDonorDueToMismatches };

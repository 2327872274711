import React from 'react';
import { convertPatientHla } from '../../../donorMatchSearchRequests/helpers/apiDataConverter';
import { ApiDonorHla } from '../../../donorSearchResults/types/api';
import Drb345DisplayContainer from '../../../patient/core/components/Drb345DisplayContainer';
import HlaPairDisplay from '../../../patient/core/components/HlaPairDisplay';

type Props = {
  hlaData: ApiDonorHla;
};

export const DonorHLATable = ({ hlaData }: Props) => {
  const convertedHla = convertPatientHla(hlaData);
  return (
    <div className="table-hla__wrapper">
      <table className="table table-hla">
        <thead className="table-header">
          <tr>
            <th>A</th>
            <th>B</th>
            <th>C</th>
            <th>DRB1</th>
            <th>DRB3/4/5</th>
            <th>DQA1</th>
            <th>DQB1</th>
            <th>DPA1</th>
            <th>DPB1</th>
          </tr>
        </thead>
        <tbody>
          <tr className="centered-table-data">
            <td id="hlaA">
              <HlaPairDisplay locus1={convertedHla.hlaA1} locus2={convertedHla.hlaA2} />
            </td>
            <td id="hlaB">
              <HlaPairDisplay locus1={convertedHla.hlaB1} locus2={convertedHla.hlaB2} />
            </td>
            <td id="hlaC">
              <HlaPairDisplay locus1={convertedHla.hlaC1} locus2={convertedHla.hlaC2} />
            </td>
            <td id="hlaDrb1">
              <HlaPairDisplay locus1={convertedHla.drB11} locus2={convertedHla.drB12} />
            </td>
            <td id="hlaDrb3/4/5">
              <Drb345DisplayContainer
                drB31={convertedHla.drB31}
                drB32={convertedHla.drB32}
                drB41={convertedHla.drB41}
                drB42={convertedHla.drB42}
                drB51={convertedHla.drB51}
                drB52={convertedHla.drB52}
              />
            </td>
            <td id="hlaDqa1">
              <HlaPairDisplay locus1={convertedHla.dqA11} locus2={convertedHla.dqA12} />
            </td>
            <td id="hlaDqb1">
              <HlaPairDisplay locus1={convertedHla.dqB11} locus2={convertedHla.dqB12} />
            </td>
            <td id="hlaDpa1">
              <HlaPairDisplay locus1={convertedHla.dpA11} locus2={convertedHla.dpA12} />
            </td>
            <td id="hlaDpb1">
              <HlaPairDisplay locus1={convertedHla.dpB11} locus2={convertedHla.dpB12} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

// eslint-disable-next-line import/no-cycle
import {
  Actions,
  FetchInternationalSearchCreationConstantsSuccessAction,
  PatientCreateEmdisSearchRequestErrorAction,
  PatientCreateEmdisSearchRequestRequestAction,
  PatientCreateEmdisSearchRequestSuccessAction,
} from './actions';
import type { InternationalSearchCreationConstants } from '../types';
// eslint-disable-next-line import/no-cycle
import { convertInternationalSearchCreationConstants } from '../helpers/apiDataConverter';

type UserActions =
  | FetchInternationalSearchCreationConstantsSuccessAction
  | PatientCreateEmdisSearchRequestErrorAction
  | PatientCreateEmdisSearchRequestRequestAction
  | PatientCreateEmdisSearchRequestSuccessAction;

export type EmdisSearchRequestInitiationReducerState = {
  internationalSearchCreationConstants?: InternationalSearchCreationConstants;
  isCreatingRequest: boolean;
  errorMessage?: string;
};

const initialState: EmdisSearchRequestInitiationReducerState = {
  internationalSearchCreationConstants: undefined,
  isCreatingRequest: false,
  errorMessage: undefined,
};

const emdisSearchRequestInitiation = (
  state: EmdisSearchRequestInitiationReducerState = initialState,
  action: UserActions
): EmdisSearchRequestInitiationReducerState => {
  switch (action.type) {
    case Actions.PATIENT_CREATE_EMDIS_SEARCH_REQUEST_REQUEST:
      return {
        ...state,
        isCreatingRequest: true,
      };
    case Actions.PATIENT_CREATE_EMDIS_SEARCH_REQUEST_SUCCESS:
      return {
        ...state,
        isCreatingRequest: false,
        errorMessage: action.payload.params.errorMessage,
      };
    case Actions.FETCH_INTERNATIONAL_SEARCH_CREATION_CONSTANTS_SUCCESS:
      return {
        ...state,
        internationalSearchCreationConstants: convertInternationalSearchCreationConstants(action.payload.data),
      };
    default:
      return state;
  }
};

export default emdisSearchRequestInitiation;

import { Actions, DonorAction } from './actions';
import { ApiErrorType } from '../../core/types';
import { DonorDetails } from '../../subject/types';

export type DonorReducerState = {
  constants: {
    AddressTypeOptions: string[];
    DiagnosisOptions: string[];
    EthnicGroupOptions: string[];
    GenderOptions: string[];
    PatientTypeOptions: string[];
  };
  constantsError: any;
  loadingConstants: boolean;
  donor: DonorDetails | undefined;
  loadingDonor: boolean;
  loadingDonorError: ApiErrorType | any;
  savingDonor: boolean;
  savingRecordError: ApiErrorType | any;
};

const initialState = {
  constants: {
    AddressTypeOptions: [],
    DiagnosisOptions: [],
    EthnicGroupOptions: [],
    GenderOptions: [],
    PatientTypeOptions: [],
  },
  loadingConstants: false,
  constantsError: undefined,
  donor: undefined,
  loadingDonor: false,
  loadingDonorError: undefined,
  savingDonor: false,
  savingRecordError: undefined,
};

// eslint-disable-next-line default-param-last
export default (state: DonorReducerState = initialState, action: DonorAction) => {
  switch (action.type) {
    case Actions.GET_SUBJECT_CONSTANTS_REQUEST:
      return {
        ...state,
        constantsError: undefined,
        loadingConstants: true,
      };

    case Actions.GET_SUBJECT_CONSTANTS_SUCCESS:
      return {
        ...state,
        constants: action.payload.data,
        constantsError: undefined,
        loadingConstants: false,
      };

    case Actions.GET_SUBJECT_CONSTANTS_ERROR:
      return {
        ...state,
        constantsError: action.payload,
        loadingConstants: false,
      };

    case Actions.GET_ADULT_DONOR_REQUEST:
      return {
        ...state,
        loadingDonor: true,
        loadingDonorError: undefined,
      };
    case Actions.GET_ADULT_DONOR_SUCCESS:
      return {
        ...state,
        loadingDonor: false,
        donor: action.payload.data,
        loadingDonorError: undefined,
      };
    case Actions.GET_ADULT_DONOR_ERROR:
      return {
        ...state,
        loadingDonor: false,
        loadingDonorError: action.payload.data,
        donor: undefined,
      };

    case Actions.EDIT_ADULT_DONOR_REQUEST:
      return {
        ...state,
        savingRecordError: undefined,
        savingDonor: true,
      };

    case Actions.EDIT_ADULT_DONOR_SUCCESS:
      return {
        ...state,
        savingRecordError: undefined,
        savingDonor: false,
        donor: action.payload.params.donor,
      };
    case Actions.EDIT_ADULT_DONOR_ERROR:
      return {
        ...state,
        savingRecordError: action.payload.data.Error,
        savingDonor: false,
      };

    default:
      return state;
  }
};

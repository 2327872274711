import _ from 'lodash';
import type { AdultSearchResult, SearchResults } from '../../core/types';
import type { SelectedResult } from '../../donorMatchSearchRequests/types';

export default (selectedItems: Partial<SelectedResult>[], searchResults: SearchResults) =>
  selectedItems.map<Partial<SelectedResult>>((donor) => {
    const adultSearchResult = searchResults.results as AdultSearchResult[];
    const matchedDonor = adultSearchResult.find((d: AdultSearchResult) => d.donor.id === donor.id);
    return {
      id: _.get(matchedDonor, 'donor.id'),
      matchGrade: _.get(matchedDonor, 'matchGrade'),
      matchType: _.get(matchedDonor, 'matchType'),
      registryId: _.get(matchedDonor, 'donor.originatingRegistry.id'),
    };
  });

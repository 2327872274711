import React, { Component } from 'react';
import { AnyAction, bindActionCreators, Dispatch as ReduxDispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import * as actions from '../../../../../externalInvestigations/redux/actions';
import ExternalInvestigationsSelectors from '../../../../../externalInvestigations/redux/selectors';
import { ErrorMessage, LoadingMessage } from '../../../../../core';
import ExternalInvestigationsTable from './ExternalInvestigationsTable';

import type { ErrorMessages } from '../../../../../core/types/index';
import type { ReduxState } from '../../../../../rootReducer';

type OwnProps = {
  patientId: string;
};
type StateProps = {
  errorMessage: ErrorMessages;
  hasErrored: boolean;
  isFetching: boolean;
};
type Props = PropsFromRedux & OwnProps & StateProps;

const mapStateToProps = (state: ReduxState): StateProps => ({
  errorMessage: ExternalInvestigationsSelectors.getExternalInvestigationsErrorMessage(state),
  hasErrored: ExternalInvestigationsSelectors.hasFetchExternalInvestigationsErrored(state),
  isFetching: ExternalInvestigationsSelectors.isFetchingExternalInvestigations(state),
});

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>) => ({
  getExternalInvestigations: bindActionCreators(actions.getExternalInvestigations, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class ExternalInvestigationsTab extends Component<Props> {
  componentDidMount() {
    const { getExternalInvestigations, patientId } = this.props;
    getExternalInvestigations(patientId);
  }

  render() {
    const { patientId, errorMessage, hasErrored, isFetching } = this.props;
    return (
      <div>
        {hasErrored ? (
          <div style={{ marginTop: '10px' }}>
            <ErrorMessage errorMessages={errorMessage} />
          </div>
        ) : (
          <div className="results-table results-table--border fixed-width">
            <h2 className="border-bottom-solid">Test Requests</h2>
            <LoadingMessage isLoading={isFetching}>
              <ExternalInvestigationsTable patientId={patientId} />
            </LoadingMessage>
          </div>
        )}
      </div>
    );
  }
}

export default connector(ExternalInvestigationsTab);

import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { FeatureFlag } from '../../../featureFlags';
import { ReportStatuses } from '../../core/constants';
import { reportTypes } from '../../../core';
import { styles as commonStyles } from '../style';
import DownloadReport from './DownloadReport';
import ReportPageButton from './ReportPageButton';
import CurrentUserSelectors from '../../../currentUser/redux/selectors';
import { UserRole, UserRoles } from '../../../currentUser/constants';
import type { ReportStatus } from '../../types';
import ReportPageReasonButton from './ReportPageReasonButton';

import type { ReduxState } from '../../../rootReducer';

type OwnProps = {
  handleStatusUpdate: (status: ReportStatus, reason?: string) => void;
  isNewReport: boolean;
  reportStatus: ReportStatus;
  reportType: string | null | undefined;
};
type StateProps = {
  userRoles?: UserRole[];
};
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & OwnProps;

const mapStateToProps = (state: ReduxState): StateProps => ({
  userRoles: CurrentUserSelectors.getCurrentUserRoles(state),
});

const styles = {
  ...commonStyles,
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
};

class UpdateReportStatus extends PureComponent<Props> {
  static defaultProps = {
    userRoles: [],
  };

  render() {
    return <div>{this.renderUpdateStatusButtons()}</div>;
  }

  renderUpdateStatusButtons = () => {
    const { isNewReport, reportStatus, reportType } = this.props;

    if (isNewReport) {
      return null;
    }
    if (reportStatus === ReportStatuses.Draft) {
      return reportType === reportTypes.internal
        ? this.renderUpdateStatusButton('Send for Authorisation', ReportStatuses.AwaitingAuthorisation)
        : this.renderUpdateStatusButton('Finalise', ReportStatuses.Authorised);
    }

    if (reportStatus === ReportStatuses.Authorised) {
      return (
        <div style={styles.flexRow}>
          {this.renderBackToDraftButton(reportType)}
          <div style={{ marginRight: '15px' }}>
            <DownloadReport />
          </div>
          <div style={{ marginRight: '15px' }}>
            {this.renderUpdateStatusButton('Mark As Sent', ReportStatuses.Sent)}
          </div>
          <div>{this.shouldShowUnauthoriseButton() ? this.renderUnauthoriseButton() : null}</div>
        </div>
      );
    }

    if (reportStatus === ReportStatuses.Sent) {
      return <DownloadReport />;
    }

    return (
      <div style={styles.flexRow}>
        <div style={{ marginRight: '15px' }}>
          {this.shouldShowAcceptButton() ? this.renderUpdateStatusButton('Authorise', ReportStatuses.Authorised) : null}
        </div>
        {this.renderUpdateStatusButton('Back To Draft', ReportStatuses.Draft)}
      </div>
    );
  };

  shouldShowUnauthoriseButton = () => {
    const { userRoles } = this.props;
    return userRoles ? userRoles.includes(UserRoles.Administrator) : false;
  };

  shouldShowAcceptButton = () => {
    const { userRoles } = this.props;
    return userRoles
      ? userRoles.includes(UserRoles.Administrator) || userRoles.includes(UserRoles.SearchAuthoriser)
      : false;
  };

  renderUnauthoriseButton = () => (
    <FeatureFlag
      flag="showUnauthoriseButton"
      render={() => (
        <ReportPageReasonButton
          buttonName="Unauthorize Report"
          popUpMessage="Why do you need to unauthorise this report?"
          onButtonClick={this.updateReportStatusWithReason}
          updatedStatus={ReportStatuses.Draft}
        />
      )}
    />
  );

  renderUpdateStatusButton = (buttonName: string, updatedStatus: string) => (
    <ReportPageButton
      buttonName={buttonName}
      handleButtonClick={this.updateReportStatus}
      updatedStatus={updatedStatus}
    />
  );

  renderBackToDraftButton = (reportType?: string | null) =>
    reportType === reportTypes.international || reportType === reportTypes.internal ? (
      <div style={{ marginRight: '15px' }}>{this.renderUpdateStatusButton('Back To Draft', ReportStatuses.Draft)}</div>
    ) : null;

  updateReportStatus = (status: ReportStatus) => {
    const { handleStatusUpdate } = this.props;
    return handleStatusUpdate(status);
  };

  updateReportStatusWithReason = (status: ReportStatus, reason: string) => {
    const { handleStatusUpdate } = this.props;
    return handleStatusUpdate(status, reason);
  };
}

const connector = connect(mapStateToProps);

export default connector(UpdateReportStatus);

import React from 'react';
import Radium from 'radium';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { ToastContainer as NotificationsToastContainer } from 'react-toastify';
import { ToastContainer } from '@an/nova-frontend-hla-popup';
import { styles } from './style';
import { NotificationContainer } from './notifications';
import AppRoutes from './AppRoutes';
import { Loading } from './core/components/Loading';
import { AuthError } from './core/components/AuthError';
import { authScopes } from './config';
import 'react-toastify/dist/ReactToastify.css';

const AppComponent = () => (
  <Radium.StyleRoot>
    <div className="App col span_12_of_12" style={styles.page}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authScopes}
        errorComponent={AuthError}
        loadingComponent={Loading}
      >
        <NotificationsToastContainer className="action-notification" hideProgressBar />
        {/* TODO: EM-2540: replace deprecated react-notifications-system with react-toastify */}
        <NotificationContainer />
        <AppRoutes />
        <ToastContainer className="hla-nmdp-display" toastClassName="toast-content" />
      </MsalAuthenticationTemplate>
    </div>
  </Radium.StyleRoot>
);

export default AppComponent;

const UK_SEARCH_REQUESTS = 'ukSearchRequests';
const EMDIS_SEARCH_REQUESTS = 'emdisSearchRequests';
const SEARCH_REPORTS = 'searchReports';
const TEST_REQUESTS = 'testRequests';
const TEXT_MESSAGES = 'textMessages';
const LINKED_DONORS = 'linkedDonors';
const INVESTIGATIONS = 'investigations';

export const tabItems = {
  ukSearchRequests: {
    id: UK_SEARCH_REQUESTS,
    displayName: 'UK Search Requests',
  },
  emdisSearchRequests: {
    id: EMDIS_SEARCH_REQUESTS,
    displayName: 'EMDIS Search Requests',
  },
  searchReports: { id: SEARCH_REPORTS, displayName: 'Search Reports' },
  testRequests: { id: TEST_REQUESTS, displayName: 'Test Requests' },
  textMessages: { id: TEXT_MESSAGES, displayName: 'Text Messages' },
  investigations: { id: INVESTIGATIONS, displayName: 'Investigations' },
  linkedDonors: { id: LINKED_DONORS, displayName: 'Linked Donors' },
};

export type TabItem = { id: string; displayName: string };

const SAMPLE_INFO = 'sampleInfo';
const SAMPLE_INFO_TEXT_MESSAGES = 'sampleInfoTextMessages';

export const sampleInfoTabItems = {
  sampleInfo: {
    id: SAMPLE_INFO,
    displayName: 'Sample Info',
  },
  textMessages: {
    id: SAMPLE_INFO_TEXT_MESSAGES,
    displayName: 'Text Messages',
  },
};

import React from 'react';

type Props = {
  // eslint-disable-next-line react/no-unused-prop-types
  urgent: boolean;
  updateExtraInfo: (string: string) => void;
  donorId: string;
};

type PropsKey = 'urgent';

type Option = {
  display: string;
  id: string;
};

const requirements: { [string: string]: Option } = {
  urgent: {
    id: 'urgent',
    display: 'Urgent',
  },
};

class CheckboxOptions extends React.Component<Props> {
  render() {
    const { donorId } = this.props;
    return (
      <div key={donorId}>
        {Object.values(requirements).map((requirement: Option) => (
          // When requesting multiple donors these checkboxes use the same id, hence we cannot have a 'htmlFor'
          // attribute on the label, or else it will affect the first checkbox.
          // eslint-disable-next-line jsx-a11y/label-has-associated-control
          <label className="control control--small control--checkbox" key={requirement.id} style={{ width: 'auto' }}>
            <span style={{ paddingRight: '10px' }}>{requirement.display}</span>
            <input
              // eslint-disable-next-line react/destructuring-assignment
              checked={this.props[requirement.id as PropsKey]}
              id={requirement.id}
              onChange={this.handleCheckbox}
              type="checkbox"
            />
          </label>
        ))}
      </div>
    );
  }

  handleCheckbox = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const { updateExtraInfo } = this.props;
    const { id } = event.currentTarget;
    updateExtraInfo(id);
  };
}

export default CheckboxOptions;

import _ from 'lodash';

type Options = { label: string; value: string }[];

export const removeWhitespace = (value: string) => {
  if (!value) {
    return value;
  }
  return value.replace(/\s+/g, '');
};

export const mapSelectOptions = (listOptions: any[], existingValue?: string) => {
  const options: Options = _.sortBy(
    _.uniqBy(
      listOptions.map((option) => ({
        label: option,
        value: option,
      })),
      (option) => option.label
    ),
    'label'
  );

  // We append the existing select values, even if they are not in the available options
  // This is to maintain backwards compatibility with records created with now obsolete values
  return options.some((option) => option.value === existingValue)
    ? options
    : [{ label: existingValue, value: existingValue }, ...options];
};

export const mapDonorTypeOptions = (listOptions: any, existingValue: string) => {
  const options: Options = _.sortBy(
    _.uniqBy(
      Object.keys(listOptions).map((optionKey) => ({
        label: listOptions[optionKey].label,
        value: listOptions[optionKey].value,
      })),
      (option) => option.label
    ),
    'label'
  );

  // We append the existing select values, even if they are not in the available options
  // This is to maintain backwards compatibility with records created with now obsolete values
  return options.some((option) => option.value === existingValue)
    ? options
    : [{ label: existingValue, value: existingValue }, ...options];
};

export const getFieldLabel = (opts: any, value: any, placeholder: string) => {
  const selectedOption = opts && opts.find((o: any) => o.value && value && _.isEqual(o.value, value));
  return selectedOption ? selectedOption.label : placeholder || 'Select...';
};

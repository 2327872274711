import {
  get,
  post,
  Response,
  SuccessAction as ApiSuccessAction,
  ErrorAction as ApiErrorAction,
  RequestAction as ApiRequestAction,
} from '@an/nova-frontend-rest-client';
import Config from '../../../../config';
import {
  convertIDMRequestsOptionsToApi,
  convertExtendedTypingRequestOptionsToApi,
  convertVTRequestsOptionsToApi,
} from '../../../helpers/apiDataConverter';
import type {
  ExtendedTypingRequestOptions,
  ExternalInvestigationSelectedDonor,
  SelectedDonorAndVTRequestOptions,
  SelectedDonorAndIDMRequestOptions,
} from '../types';
import type { Dispatch } from '../../../../core/types';
import { Actions } from './actionNames';
import { ApiLaboratory } from '../types/api';

type RequestIdParam = { requestId: string };
type ErrorMessageParam = { errorMessage: string };
type SuccessMessageParam = { successMessage: string };
type Error = { Error: string };

export type ExternalInvestigationsCreateIDMRequestErrorAction = ApiErrorAction<
  typeof Actions.EXTERNAL_INVESTIGATIONS_CREATE_IDM_REQUEST_ERROR,
  RequestIdParam & ErrorMessageParam,
  Error
>;
export type ExternalInvestigationsCreateIDMRequestRequestAction = ApiRequestAction<
  typeof Actions.EXTERNAL_INVESTIGATIONS_CREATE_IDM_REQUEST_REQUEST,
  Record<string, unknown>
>;
export type ExternalInvestigationsCreateIDMRequestSuccessAction = ApiSuccessAction<
  typeof Actions.EXTERNAL_INVESTIGATIONS_CREATE_IDM_REQUEST_SUCCESS,
  SuccessMessageParam & RequestIdParam,
  SuccessMessageParam
>;
export type ExternalInvestigationsCreateVTRequestErrorAction = ApiErrorAction<
  typeof Actions.EXTERNAL_INVESTIGATIONS_CREATE_VT_REQUEST_ERROR,
  RequestIdParam & ErrorMessageParam,
  Error
>;
export type ExternalInvestigationsCreateVTRequestRequestAction = ApiRequestAction<
  typeof Actions.EXTERNAL_INVESTIGATIONS_CREATE_VT_REQUEST_REQUEST,
  Record<string, unknown>
>;
export type ExternalInvestigationsCreateVTRequestSuccessAction = ApiSuccessAction<
  typeof Actions.EXTERNAL_INVESTIGATIONS_CREATE_VT_REQUEST_SUCCESS,
  SuccessMessageParam,
  SuccessMessageParam
>;
export type ExternalInvestigationsCreateExtendedTypingRequestErrorAction = ApiErrorAction<
  typeof Actions.EXTERNAL_INVESTIGATIONS_CREATE_EXTENDED_TYPING_REQUEST_ERROR,
  RequestIdParam & ErrorMessageParam,
  Error
>;
export type ExternalInvestigationsCreateExtendedTypingRequestRequestAction = ApiRequestAction<
  typeof Actions.EXTERNAL_INVESTIGATIONS_CREATE_EXTENDED_TYPING_REQUEST_REQUEST,
  Record<string, unknown>
>;
export type ExternalInvestigationsCreateExtendedTypingRequestSuccessAction = ApiSuccessAction<
  typeof Actions.EXTERNAL_INVESTIGATIONS_CREATE_EXTENDED_TYPING_REQUEST_SUCCESS,
  SuccessMessageParam,
  SuccessMessageParam
>;
export type FetchBritishLaboratoriestSuccessAction = ApiSuccessAction<
  typeof Actions.FETCH_BRITISH_LABORATORIES_SUCCESS,
  Record<string, unknown>,
  ApiLaboratory[]
>;
export type FetchBritishLaboratoriesErrorAction = ApiErrorAction<
  typeof Actions.FETCH_BRITISH_LABORATORIES_ERROR,
  RequestIdParam & ErrorMessageParam
>;
export type FetchBritishLaboratoriesRequestAction = ApiRequestAction<
  typeof Actions.FETCH_BRITISH_LABORATORIES_REQUEST,
  RequestIdParam & ErrorMessageParam
>;

export const createIDMRequests =
  (patientId: string, idmRequestDetailsAndDonors: SelectedDonorAndIDMRequestOptions[]) =>
  async (dispatch: Dispatch<any>): Promise<Response<any>> => {
    const idmRequestOptions = convertIDMRequestsOptionsToApi(patientId, idmRequestDetailsAndDonors);
    const newRequest = await dispatch(
      post(
        `${Config().apiBaseUrl}external-investigations/idm/batch`,
        Actions.EXTERNAL_INVESTIGATIONS_CREATE_IDM_REQUEST,
        {
          successMessage: 'IDM Test Request Sent',
          errorMessage: 'IDM Test Request Failed',
        },
        idmRequestOptions
      )
    );
    return newRequest;
  };

export const createExtendedTypingRequest =
  (
    patientId: string,
    donor: ExternalInvestigationSelectedDonor,
    extendedTypingRequestOptions: ExtendedTypingRequestOptions,
    remark: string | null | undefined,
    isUrgent: boolean
  ) =>
  async (dispatch: Dispatch<any>): Promise<Response<any>> => {
    const extendedTypingRequest = convertExtendedTypingRequestOptionsToApi(
      extendedTypingRequestOptions,
      isUrgent,
      patientId,
      remark,
      donor.id,
      donor.originatingRegistry.id
    );
    const newRequest = await dispatch(
      post(
        `${Config().apiBaseUrl}external-investigations/extended-typing`,
        Actions.EXTERNAL_INVESTIGATIONS_CREATE_EXTENDED_TYPING_REQUEST,
        {
          successMessage: 'Extended Typing Test Request Sent',
          errorMessage: 'Extended Typing Test Request Failed',
        },
        extendedTypingRequest
      )
    );
    return newRequest;
  };

export const createVTRequests =
  (patientId: string, verificationTypingRequestDetailsAndDonors: SelectedDonorAndVTRequestOptions[]) =>
  async (dispatch: Dispatch<any>): Promise<Response<any>> => {
    const verificationTypingRequest = convertVTRequestsOptionsToApi(
      patientId,
      verificationTypingRequestDetailsAndDonors
    );
    const newRequest = await dispatch(
      post(
        `${Config().apiBaseUrl}external-investigations/verification-typing/batch`,
        Actions.EXTERNAL_INVESTIGATIONS_CREATE_VT_REQUEST,
        {
          successMessage: 'VT Test Request(s) Sent',
          errorMessage: 'Some of VT Test Requests Failed',
        },
        verificationTypingRequest
      )
    );
    return newRequest;
  };

export const getFilteredLaboratoryOptions = () => (dispatch: Dispatch<any>) => {
  dispatch(get(`${Config().apiBaseUrl}institutions/laboratories/british`, Actions.FETCH_BRITISH_LABORATORIES));
};

import _ from 'lodash';

export const messageDenialStatuses = {
  IDMRequest: {
    value: 'IDMRequest',
    name: 'IDM Request',
    orderInTable: 1,
  },
  ETRequest: {
    value: 'ETRequest',
    name: 'ET Request',
    orderInTable: 2,
  },
  VTRequest: {
    value: 'VTRequest',
    name: 'VT Request',
    orderInTable: 3,
  },
  SampleTestingResult: {
    value: 'SampleTestingResult',
    name: 'VT Results',
    orderInTable: 4,
  },
  CancelRequest: {
    value: 'CancelRequest',
    name: 'Cancel Request',
    orderInTable: 5,
  },
  FailedToSend: {
    value: 'PatientUpdate',
    name: 'Initial Search',
    orderInTable: 6,
  },
  Completed: {
    value: 'PatientStatusChange',
    name: 'Search Status',
    orderInTable: 7,
  },
};

export const getMessageDenialStatusName = (value: string): string | undefined =>
  _.find(messageDenialStatuses, (messageDenialStatus) => messageDenialStatus.value === value)?.name;

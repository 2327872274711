import _ from 'lodash';

const CORD = 'Cord';
const ADULT = 'Adult';

const donorTypes = {
  cord: {
    value: CORD,
    displayString: 'Cord',
  },
  adult: {
    value: ADULT,
    displayString: 'Donor',
  },
};

export const donorTypeDisplayString = (value: string): string | undefined =>
  _.find(donorTypes, (donorType) => donorType.value === value)?.displayString;
export default donorTypes;

import React from 'react';
import type { ReportStatus } from '../../types';

type Props = {
  handleButtonClick: (status: ReportStatus) => void;
  updatedStatus: ReportStatus;
  buttonName: string;
};

const ReportPageButton = ({ buttonName, handleButtonClick, updatedStatus }: Props) => (
  <button
    className="btn"
    onClick={() => {
      handleButtonClick(updatedStatus);
    }}
    type="button"
  >
    {buttonName}
  </button>
);

export default ReportPageButton;

import type { ReduxState } from '../../../rootReducer';
import type { ReportIdsByStatus } from '../../types';

const getDashboardState = (state: ReduxState) => state.searchReport.reportDashboard;

const getReportIds = (state: ReduxState): ReportIdsByStatus => getDashboardState(state).reportIdsByStatus;

const getSelectedUser = (state: ReduxState): string | undefined => getDashboardState(state).selectedUser;

export default {
  getReportIds,
  getSelectedUser,
};

import React, { PureComponent } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import adjustedPhenotypeDisplay from '../helpers/adjustedHLA';
import getCordSearchesString from '../helpers/cordSearchesDisplay';
import defaultTab from '../../patient/core/constants/defaultPatientDashboardTab';

import type { ApiResultSetSummary, ApiSearchRequest } from '../types/api';
import { convertSearchTypeFromApiToNumeric } from '../../core/constants/searchTypes';

type Props = {
  request: ApiSearchRequest;
};

class DonorMatchSearchRequestSummary extends PureComponent<Props> {
  render() {
    const { request } = this.props;
    const resultSet = request.ResultSetSummaries.find((set: ApiResultSetSummary) => set.DonorType === 'Adult');
    // TODO: NOVA-2853: Do not hardcode donorType
    const url = `donorsearch/${request.Id}/results?resultSetId=${
      resultSet ? resultSet.SearchResultSetId : ''
    }&donorType=Adult`;

    return (
      <div className="summaryCard">
        <header
          className={request.Status === 'Open' ? 'cardHeader' : 'cardHeader--disabled'}
          style={{ display: 'block' }}
        >
          <div>
            <Link to={`/patient/${request.PatientId}/${defaultTab}`} style={{ textDecoration: 'none' }}>
              {request.PatientName}
              {adjustedPhenotypeDisplay(request.IsHlaAdjusted || !request.SearchedHlaMatchCurrentPatientHla)}
            </Link>
          </div>
          <div>{request.Status === 'Open' ? <Link to={url}>View Results</Link> : <span>Search Closed</span>}</div>
        </header>
        <div style={{ display: 'flex' }}>
          <div className="summaryCardColumn">
            <strong>Id: </strong>
            {request.PatientId}
            <br />
            <strong>Gender: </strong>
            {request.PatientGender}
            <br />
            <strong>Age: </strong>
            {moment().diff(request.PatientBirthDate, 'years')}
            <br />
            <strong>Weight (kg): </strong>
            {request.PatientWeight}
            <br />
          </div>
          <div className="summaryCardColumn">
            <strong>Status: </strong>
            {request.Status}
            <br />
            <strong>Run Date: </strong>
            {moment(request.SearchRunDate).format('DD/MM/YYYY')}
            <br />
            <strong>Search Type: </strong>
            {convertSearchTypeFromApiToNumeric(request.AdultSearchType)}
            <br />
            <strong>Cord Searches: </strong>
            {this.renderCordSearches()}
            <br />
          </div>
        </div>
      </div>
    );
  }

  renderCordSearches = () => {
    const { request } = this.props;
    return getCordSearchesString(request) || 'Not Run';
  };
}

export default DonorMatchSearchRequestSummary;

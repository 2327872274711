import React from 'react';
import { styles } from '../../../style/index';

import type { ErrorMessages } from '../../types/index';

type Props = {
  errorMessages: ErrorMessages | undefined;
};

const ErrorMessage = ({ errorMessages }: Props) => (
  <div style={styles.errorMessage}>
    <strong>Oops, something went wrong!</strong>
    <div>
      <ul>
        {errorMessages?.map((message) =>
          message ? (
            <li key={message} style={styles.allRequests}>
              {message}
            </li>
          ) : null
        )}
      </ul>
    </div>
    <strong>Please contact the I.T. department.</strong>
  </div>
);

export default ErrorMessage;

import React from 'react';
import { LinkButton, reportTypes } from '../../../../core/index';

type Props = {
  isInternational: boolean;
  patientId: string;
  reportId?: string;
};

const ToSearchReportButton = ({ patientId, reportId, isInternational }: Props) => {
  const reportType = isInternational ? reportTypes.international : reportTypes.internal;
  const report = reportId
    ? {
        path: `/patient/${patientId}/reports/${reportId}?reportType=${reportType}`,
        buttonText: 'Back To Search Report',
      }
    : {
        path: `/patient/${patientId}/reports/new?reportType=${reportType}`,
        buttonText: 'New Search Report',
      };

  return (
    <div style={{ alignSelf: 'flexEnd' }}>
      <LinkButton className="btn btn--inline" to={report.path}>
        {report.buttonText}
      </LinkButton>
    </div>
  );
};

ToSearchReportButton.defaultProps = {
  reportId: undefined,
};

export default ToSearchReportButton;

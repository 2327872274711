import type { Response } from '@an/nova-frontend-rest-client';
import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch as ReduxDispatch } from 'redux';
import type { ReduxState } from '../../../../rootReducer';
import * as actions from '../redux/actions';
import formatRemark from '../../../../core/helpers/remarkFormatter';
import { SingleLineTextArea } from '../../../../core/components/SingleLineTextArea';
import { externalInvestigationTypes } from '../../../../core/constants/externalInvestigationTypes';
import { resolutionTypes } from './constants/resolutionTypes';
import ExpandButton from '../../../../core/components/ExpandButton';
import { extendedTypingRequestChoices, Option } from './ExtendedTypingRequestChoices';
import type { ExternalInvestigationSelectedDonor } from '../types';
import Selectors from '../redux/selectors';
import { formatRequestContext } from '../../../../core/helpers/markupHelpers';
import '../externalInvestigationsStyling.scss';
import { Button } from '../../../../core/components/Button';

type OwnProps = {
  onPopUpClose: () => void;
  onClose: (e: React.MouseEvent<HTMLButtonElement>) => void;
  patientId: string;
  selectedDonor: ExternalInvestigationSelectedDonor;
};
type StateProps = {
  isCreatingExtendedTypingRequest: boolean;
};
type Props = PropsFromRedux & OwnProps & StateProps;
type State = {
  error: string | null | undefined;
  hlas: {
    error?: string;
    hlaA?: string;
    hlaB?: string;
    hlaC?: string;
    hlaDrb1?: string;
    hlaDrb3?: string;
    hlaDrb4?: string;
    hlaDrb5?: string;
    hlaDqa1?: string;
    hlaDqb1?: string;
    hlaDpa1?: string;
    hlaDpb1?: string;
  };
  isExpanded: boolean;
  remark: string | null | undefined;
  urgent: boolean;
};

type HlasKeys =
  | 'error'
  | 'hlaA'
  | 'hlaB'
  | 'hlaC'
  | 'hlaDrb1'
  | 'hlaDrb3'
  | 'hlaDrb4'
  | 'hlaDrb5'
  | 'hlaDqa1'
  | 'hlaDqb1'
  | 'hlaDpa1'
  | 'hlaDpb1';

const mapStateToProps = (state: ReduxState): StateProps => ({
  isCreatingExtendedTypingRequest: Selectors.isCreatingExtendedTypingRequest(state),
});

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>) => ({
  createExtendedTypingRequest: bindActionCreators(actions.createExtendedTypingRequest, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export class NewExtendedTypingRequest extends PureComponent<Props, State> {
  state = {
    error: undefined,
    hlas: {
      error: undefined,
      hlaA: undefined,
      hlaB: undefined,
      hlaC: undefined,
      hlaDrb1: undefined,
      hlaDrb3: undefined,
      hlaDrb4: undefined,
      hlaDrb5: undefined,
      hlaDqa1: undefined,
      hlaDqb1: undefined,
      hlaDpa1: undefined,
      hlaDpb1: undefined,
    },
    isExpanded: false,
    remark: undefined,
    urgent: false,
  };

  render() {
    const { isCreatingExtendedTypingRequest, patientId, selectedDonor, onClose } = this.props;
    const { error, hlas, isExpanded, remark, urgent } = this.state;
    const isDisabled = Object.values(hlas).every((option) => !option);
    return (
      <div className="formContainer">
        <h2 className="border-bottom-solid">Create New {externalInvestigationTypes.extendedTyping.name} Request</h2>
        {formatRequestContext(patientId, selectedDonor.originatingRegistry.name, selectedDonor.id, selectedDonor.grid)}
        {selectedDonor.grid ? (
          <form onSubmit={this.handleSubmit}>
            <table>
              <tbody>
                {this.renderTableContents(extendedTypingRequestChoices.defaultHla)}
                {isExpanded && this.renderTableContents(extendedTypingRequestChoices.extraHlaOptions)}
              </tbody>
              <tfoot>
                <tr style={{ backgroundColor: 'unset' }}>
                  <td colSpan={5}>
                    <ExpandButton isExpanded={isExpanded} toggleExpansion={this.toggleExpansion} />
                  </td>
                </tr>
              </tfoot>
            </table>
            <div
              style={{
                display: 'inline-flex',
                width: '100%',
                alignItems: 'center',
              }}
            >
              <div style={{ width: '80%' }}>
                <SingleLineTextArea
                  rows={1}
                  maxLength={120}
                  placeholder="Remarks (PO can be added here)"
                  value={remark}
                  onChange={this.handleRemarkChange}
                  style={{ fontSize: '90%' }}
                />
              </div>
              <label
                className="control control--small control--checkbox"
                htmlFor="urgent"
                style={{
                  paddingBottom: '20px',
                  paddingLeft: '5%',
                  width: '40%',
                }}
              >
                <span style={{ fontSize: 'medium', paddingRight: '20px' }}>Mark as urgent</span>
                <input checked={urgent} id="urgent" onChange={this.handleCheckbox} type="checkbox" />
                <div className="control__indicator control__indicator--small" />
              </label>
            </div>
            {error && (
              <div className="error-message" data-testid="error">
                <span>{error}</span>
              </div>
            )}
            <div className="btn-actions">
              <button
                className="btn btn--inline"
                disabled={isDisabled || isCreatingExtendedTypingRequest}
                type="submit"
              >
                {isCreatingExtendedTypingRequest
                  ? 'Creating...'
                  : `Create ${externalInvestigationTypes.extendedTyping.name} Request`}
              </button>
              <Button buttonClass="btn btn--inline btn--secondary" text="Close" onClick={onClose} />
            </div>
          </form>
        ) : (
          <div className="error-message blocker-message" data-testid="error">
            <span>Cannot create a test request for a donor with no GRID</span>
          </div>
        )}
      </div>
    );
  }

  renderTableContents = (options: { [string: string]: Option }): React.ReactNode[] => {
    const { hlas } = this.state;
    // $FlowExpectedError - Flow doesn't like Object.values
    return Object.values(options).map((choice: Option) => (
      <tr key={choice.id}>
        <td style={{ fontWeight: 'bold' }}>{choice.label}:</td>
        <td className="extendedTypingRequestRadios">
          <label htmlFor={`${choice.id}-serological`}>
            <input
              checked={hlas[choice.id as HlasKeys] === resolutionTypes.serological}
              id={`${choice.id}-serological`}
              name={choice.id}
              onClick={this.onRadioSelect}
              type="radio"
              value={resolutionTypes.serological}
            />
            Serological
          </label>
        </td>
        <td className="extendedTypingRequestRadios">
          <label htmlFor={`${choice.id}-low`}>
            <input
              checked={hlas[choice.id as HlasKeys] === resolutionTypes.low}
              id={`${choice.id}-low`}
              name={choice.id}
              onClick={this.onRadioSelect}
              type="radio"
              value={resolutionTypes.low}
            />
            <span className="selected">Low</span>
          </label>
        </td>
        <td className="extendedTypingRequestRadios">
          <label htmlFor={`${choice.id}-medium`}>
            <input
              checked={hlas[choice.id as HlasKeys] === resolutionTypes.medium}
              id={`${choice.id}-medium`}
              name={choice.id}
              onClick={this.onRadioSelect}
              type="radio"
              value={resolutionTypes.medium}
            />
            <span className="selected">Medium</span>
          </label>
        </td>
        <td className="extendedTypingRequestRadios">
          <label htmlFor={`${choice.id}-high`}>
            <input
              checked={hlas[choice.id as HlasKeys] === resolutionTypes.high}
              id={`${choice.id}-high`}
              name={choice.id}
              onClick={this.onRadioSelect}
              type="radio"
              value={resolutionTypes.high}
            />
            <span className="selected">High</span>
          </label>
        </td>
      </tr>
    ));
  };

  handleRemarkChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ remark: event.currentTarget.value });
  };

  toggleExpansion = () => this.setState((prevState) => ({ isExpanded: !prevState.isExpanded }));

  onRadioSelect = (event: React.MouseEvent<HTMLInputElement>) => {
    const { hlas } = this.state;
    const selectionValue = event.currentTarget.value;
    const selectionName = event.currentTarget.name;
    const resolution = hlas[selectionName as HlasKeys] === selectionValue ? undefined : selectionValue;
    this.setState((prevState) => ({
      hlas: { ...prevState.hlas, [selectionName]: resolution },
    }));
  };

  handleCheckbox = () => {
    this.setState((prevState) => ({ urgent: !prevState.urgent }));
  };

  handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { createExtendedTypingRequest, onPopUpClose, patientId, selectedDonor } = this.props;
    const { hlas, remark, urgent } = this.state;
    const { hlaA, hlaB, hlaC, hlaDrb1, hlaDrb3, hlaDrb4, hlaDrb5, hlaDqa1, hlaDqb1, hlaDpa1, hlaDpb1 } = hlas;
    const extendedTypingRequestOptions = {
      hlaA,
      hlaB,
      hlaC,
      hlaDrb1,
      hlaDrb3,
      hlaDrb4,
      hlaDrb5,
      hlaDqa1,
      hlaDqb1,
      hlaDpa1,
      hlaDpb1,
    };

    const formattedRemark = formatRemark(remark);

    // @ts-expect-error: Not recognising async action
    const response: Response<any> = await createExtendedTypingRequest(
      patientId,
      selectedDonor,
      extendedTypingRequestOptions,
      formattedRemark,
      urgent
    );
    if (response.response.ok) {
      onPopUpClose();
    } else {
      this.setState({
        error: response.response.body ? response.response.body.Error : 'Unknown error',
      });
    }
  };
}

export default connector(NewExtendedTypingRequest);

import type { ApiSearchRequest } from '../types/api';

/*
Due to the fact the many of the requests were made before the Aligned Registries' data was integrated into SOLAR
some of the EmdisRegistriesSearched array will include "GB" and "EN" as these used to be searched using Emdis.
That is why I have removed any duplicates.
*/
export default (request: ApiSearchRequest) => {
  let cordSearches = [];
  if (request.IsAnCordSearch) {
    cordSearches.push('GB');
  }
  if (request.IsNhsBtCordSearch) {
    cordSearches.push('EN');
  }
  if (request.IsEmdisCordSearch) {
    cordSearches = [...cordSearches, ...request.EmdisCordRegistriesSearched];
  }
  const uniqueCordSearches = Array.from(new Set(cordSearches));
  return uniqueCordSearches.join(', ');
};

import type { Response } from '@an/nova-frontend-rest-client';
import React, { ReactNode, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch as ReduxDispatch } from 'redux';
import { externalInvestigationTypes } from '../../../../core/constants/externalInvestigationTypes';
import formatRemark from '../../../../core/helpers/remarkFormatter';
import type { ReduxState } from '../../../../rootReducer';
import '../externalInvestigationsStyling.scss';
import { splitDonorsErrorMessages } from '../helpers/splitDonorsErrorMessages';
import * as actions from '../redux/actions';
import Selectors from '../redux/selectors';
import type {
  ExternalInvestigationIDMSelectedDonor,
  InfectiousDiseaseMarkers,
  SelectedDonorAndIDMRequestOptions,
} from '../types';
import { idmRequestChoices } from './IDMRequestChoices';
import IndividualNewIDMRequest from './IndividualNewIDMRequest';
import { Button } from '../../../../core/components/Button';
import './newIDMRequests.scss';

type OwnProps = {
  onPopUpClose: () => void;
  onClose: (e: React.MouseEvent<HTMLButtonElement>) => void;
  patientId: string;
  idmSelectedDonorsInitialState: ExternalInvestigationIDMSelectedDonor[];
};
type StateProps = {
  isCreatingIDMRequest: boolean;
};
type Props = PropsFromRedux & OwnProps & StateProps;

type InfectiousDiseases = {
  bloodGroupAndRhesus: boolean;
  cmvStatus: boolean;
  toxoplasmosis: boolean;
  ebv: boolean;
  hivStatus: boolean;
  hivP24Antigen: boolean;
  hepBSurfaceAntigen: boolean;
  antibodyToHepBSurfaceAntigen: boolean;
  antibodyToHepBCoreAntigen: boolean;
  antibodyToHepCVirus: boolean;
  luesStatus: boolean;
  alt: boolean;
  antibodyToHTLV1V2: boolean;
};

type CheckboxEvent = { checked: boolean } & HTMLInputElement;

const mapStateToProps = (state: ReduxState): StateProps => ({
  isCreatingIDMRequest: Selectors.isCreatingIDMRequest(state),
});

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>) => ({
  createIDMRequests: bindActionCreators(actions.createIDMRequests, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const NewIDMRequests = (props: Props) => {
  const { isCreatingIDMRequest, patientId, idmSelectedDonorsInitialState, onClose } = props;

  const [infectiousDiseases, setInfectiousDiseases] = useState<InfectiousDiseases>({
    bloodGroupAndRhesus: false,
    cmvStatus: false,
    toxoplasmosis: false,
    ebv: false,
    hivStatus: false,
    hivP24Antigen: false,
    hepBSurfaceAntigen: false,
    antibodyToHepBSurfaceAntigen: false,
    antibodyToHepBCoreAntigen: false,
    antibodyToHepCVirus: false,
    luesStatus: false,
    alt: false,
    antibodyToHTLV1V2: false,
  });
  const donorsWithNoGrids = idmSelectedDonorsInitialState.filter((donor) => !donor.grid).length >= 1;
  const isDisabled = Object.values(infectiousDiseases).every((marker) => !marker) || donorsWithNoGrids;
  const [error, setError] = useState<ReactNode>(undefined);
  const [idmSelectedDonors, setIDMSelectedDonors] =
    useState<ExternalInvestigationIDMSelectedDonor[]>(idmSelectedDonorsInitialState);

  const handleRemarkChange = (remark: string, donorId: string) => {
    setIDMSelectedDonors(idmSelectedDonors.map((donor) => (donor.id === donorId ? { ...donor, remark } : donor)));
  };

  const handleChoiceCheckbox = (event: React.SyntheticEvent<CheckboxEvent>) => {
    const { id } = event.currentTarget;
    setInfectiousDiseases({
      ...infectiousDiseases,
      [id]: !infectiousDiseases[id as keyof InfectiousDiseases],
    });
  };

  const submitIDMRequestForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { createIDMRequests, onPopUpClose } = props;
    const infectiousDiseaseMarkers: InfectiousDiseaseMarkers = {
      BloodGroupRhesus: infectiousDiseases.bloodGroupAndRhesus,
      Cmv: infectiousDiseases.cmvStatus,
      Toxoplasmosis: infectiousDiseases.toxoplasmosis,
      Ebv: infectiousDiseases.ebv,
      HivStatus: infectiousDiseases.hivStatus,
      HivP24: infectiousDiseases.hivP24Antigen,
      HepatitisBSurfaceAntigen: infectiousDiseases.hepBSurfaceAntigen,
      AntibodyHepatitisBSurfaceAntigen: infectiousDiseases.antibodyToHepBSurfaceAntigen,
      AntibodyHepatitisBCoreAntigen: infectiousDiseases.antibodyToHepBCoreAntigen,
      AntibodyHepatitisC: infectiousDiseases.antibodyToHepCVirus,
      LuesStatus: infectiousDiseases.luesStatus,
      AltStatus: infectiousDiseases.alt,
      HltvAntibody: infectiousDiseases.antibodyToHTLV1V2,
    };
    const idmRequestDetailsAndDonors: SelectedDonorAndIDMRequestOptions[] = idmSelectedDonors.map((donor) => {
      const formattedRemark = formatRemark(donor.remark);
      return {
        id: donor.id,
        grid: donor.grid,
        originatingRegistry: donor.originatingRegistry,
        remark: formattedRemark ?? '',
        infectiousDiseaseMarkers,
      };
    });
    // $FlowExpectedError: Not recognising async action
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const response: Response<any> = await createIDMRequests(patientId, idmRequestDetailsAndDonors);
    if (response.response.ok) {
      onPopUpClose();
    } else {
      setError(response.response.body ? splitDonorsErrorMessages(response.response.body.Error) : 'Unknown error');
    }
  };

  const displaySelectedDonors = idmSelectedDonors.map((donor: ExternalInvestigationIDMSelectedDonor) => (
    <IndividualNewIDMRequest
      selectedDonor={donor}
      patientId={patientId}
      updateRemark={handleRemarkChange}
      remark={donor.remark}
      key={donor.grid}
    />
  ));

  return (
    <div className="formContainer">
      <h2 className="border-bottom-solid">Create New {externalInvestigationTypes.idm.name} Requests</h2>
      <form onSubmit={submitIDMRequestForm}>
        <div className="newIDMRequestPopUp">
          <div className="border-bottom-solid checkboxes-container">
            {idmRequestChoices.map((group) => (
              <div key={group[0].id} className="col span_12_of_12 checkboxes-row">
                {group.map((choice) => (
                  <div key={choice.id} className="col span_6_of_12">
                    <label htmlFor={choice.id} className="control control--checkbox">
                      <input type="checkbox" id={choice.id} value="on" onChange={handleChoiceCheckbox} />
                      <div className="control__indicator control-margin" />
                      <span className="label">{choice.label}</span>
                    </label>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
        {displaySelectedDonors}
        {error && (
          <div className="error-message" data-testid="error">
            <span>{error}</span>
          </div>
        )}

        {error || donorsWithNoGrids ? (
          <div className="btn-actions">
            <Button buttonClass="btn btn--inline btn--secondary" text="Close" onClick={onClose} />
          </div>
        ) : (
          <div className="btn-actions">
            <button type="submit" className="btn btn--inline" disabled={isDisabled || isCreatingIDMRequest}>
              {isCreatingIDMRequest ? 'Creating...' : `Create ${externalInvestigationTypes.idm.name} Request`}
            </button>

            <Button buttonClass="btn btn--inline btn--secondary" text="Close" onClick={onClose} />
          </div>
        )}
      </form>
    </div>
  );
};

export default connector(NewIDMRequests);

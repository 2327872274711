import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch as ReduxDispatch } from 'redux';
import { LoadingMessage } from '../../core/components/LoadingMessage/LoadingMessage';
import NotAuthorisedPage from '../../core/components/NotAuthorisedPage/NotAuthorisedPage';
import type { ReduxState } from '../../rootReducer';
import * as actions from '../redux/actions';
import CurrentUserSelectors from '../redux/selectors';

type OwnProps = {
  children: React.ReactNode;
};
type StateProps = {
  currentUserRoles?: string[] | void;
  isAuthorised?: boolean;
  isFetching: boolean;
};

type Props = PropsFromRedux & OwnProps;

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>) => ({
  getUserRoles: bindActionCreators(actions.getUserRoles, dispatch),
});

const mapStateToProps = (state: ReduxState): StateProps => ({
  currentUserRoles: CurrentUserSelectors.getCurrentUserRoles(state),
  isAuthorised: CurrentUserSelectors.isAuthorised(state),
  isFetching: CurrentUserSelectors.isFetchingCurrentUser(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class UserRolesLoader extends React.Component<Props> {
  static defaultProps = {
    isAuthorised: false,
    currentUserRoles: [],
  };

  componentDidMount() {
    const { currentUserRoles, getUserRoles } = this.props;
    if (!currentUserRoles) {
      getUserRoles();
    }
  }

  render() {
    const { children, currentUserRoles, isAuthorised, isFetching } = this.props;

    if (!isFetching && isAuthorised) {
      return <NotAuthorisedPage />;
    }
    if (currentUserRoles) {
      return children;
    }
    if (isFetching) {
      return (
        <div
          className="col span_12_of_12"
          style={{
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <LoadingMessage />
        </div>
      );
    }
    return null;
  }
}

export default connector(UserRolesLoader);

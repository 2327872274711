import React from 'react';
import { ReportStatuses } from '../../core/constants';
import type { ReportStatus } from '../../types';

type Props = {
  reportStatus: ReportStatus;
  isNewReport: boolean;
  handleSaveReport: () => void;
};

export default ({ handleSaveReport, isNewReport, reportStatus }: Props) => {
  const saveReport = () => handleSaveReport();

  const visibility = reportStatus === ReportStatuses.Draft ? 'visible' : 'hidden';

  return (
    <button className="btn" style={{ visibility }} onClick={saveReport} type="button">
      {isNewReport ? 'Save Report' : 'Update Report'}
    </button>
  );
};

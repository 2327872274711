import React from 'react';
import AlgorithmDropDown from './AlgorithmDropDown';
import LinkButton from '../../../core/components/LinkButton/LinkButton';
import { styles } from '../../style';
import type { DonorType } from '../../../core/types';

type Props = {
  donorType: DonorType;
  selectedAlgorithm: string;
  algorithmsToCompare: string[];
  updateSelectedAlgorithm: (algorithm: string) => void;
};

export default ({ donorType, selectedAlgorithm, algorithmsToCompare, updateSelectedAlgorithm }: Props) => (
  <div>
    <AlgorithmDropDown
      defaultOption={selectedAlgorithm}
      algorithmsToCompare={algorithmsToCompare}
      getUserSelectedAlgorithm={updateSelectedAlgorithm}
    />
    <LinkButton
      to={`validationResults?donorType=${donorType}&algorithm=${selectedAlgorithm}`}
      style={{ ...styles.button, marginRight: '0px' }}
    >
      Validation Page
    </LinkButton>
  </div>
);

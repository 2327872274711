import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../../core/components/Button';
import { updateLink } from '../../redux/actions';
import Subject from '../../redux/selectors';
import { ReduxState } from '../../../rootReducer';
import { RelationshipType, SubjectLinkExpanded, SubjectType } from '../../types';

type Props = {
  relationshipType: RelationshipType;
  subjectLink: SubjectLinkExpanded;
  subjectType: SubjectType;
};

export const UpdateLinkButton = ({ relationshipType, subjectLink, subjectType }: Props) => {
  const dispatch = useDispatch();
  const isUpdatingLink = useSelector((state: ReduxState) => Subject.isUpdatingLink(state));
  const [isButtonActive, setIsButtonActive] = useState(false);

  const handleUpdateLink = async () => {
    const updatedSubjectLink = {
      DonorId: subjectLink.Donor.Id,
      PatientId: subjectLink.Patient.Id,
      RelationshipType: relationshipType,
    };
    setIsButtonActive(true);
    await dispatch(updateLink(updatedSubjectLink, subjectType));
    setIsButtonActive(false);
  };

  return (
    <Button
      disabled={subjectLink.RelationshipType === relationshipType || !relationshipType}
      loading={isUpdatingLink && isButtonActive}
      onClick={handleUpdateLink}
      tertiary
      text="Save Changes"
    />
  );
};

import moment from 'moment';
import queryString from 'query-string';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { AutoSizer, Column, Table } from 'react-virtualized';
import { AnyAction, bindActionCreators, Dispatch as ReduxDispatch } from 'redux';
import algorithmTypes from '../../../../core/constants/algorithmTypes';
import donorTypes from '../../../../core/constants/donorTypes';
import { isOddNumber } from '../../../../core/helpers/arrayHelper';
import { rebuildTooltip } from '../../../../core/helpers/rebuildTooltip';
import type { DonorType } from '../../../../core/types';
import { SearchRequestSelectors } from '../../../../donorMatchSearchRequests';
import type { ResultTableRow, SavedResultSet } from '../../../../donorMatchSearchRequests/types';
import { FeatureFlag } from '../../../../featureFlags';
import type { ReduxState } from '../../../../rootReducer';
import { getCountryNames } from '../../../core/helpers/getCountryNames';
import { CurrentReportSelectors } from '../../../core/redux/selectors';
import '../../internationalDonorsTableStyling.scss';
import * as actions from '../../redux/actions';

type DispatchProps = {
  updateCountryNames: typeof actions.updateSelectedCountryNames;
  updateDonorReportSearchSummary: typeof actions.updateDonorReportSearchSummary;
};
type OwnProps = {
  donorType: DonorType;
  filteredResultSets: ResultTableRow[];
  patientId?: string;
  reportType: string;
};
type StateProps = {
  isReportReadOnly: boolean;
  reportId: string;
  savedDonorSets: Record<string, SavedResultSet>;
  selectedDonorSetIds: string[];
};

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & OwnProps;

const mapStateToProps = (state: ReduxState): StateProps => ({
  isReportReadOnly: CurrentReportSelectors.isReportReadOnly(state),
  reportId: CurrentReportSelectors.getCurrentReportId(state),
  savedDonorSets: SearchRequestSelectors.getAllSavedResultSets(state, donorTypes.adult.value),
  selectedDonorSetIds: CurrentReportSelectors.getSelectedResultSetIds(state, donorTypes.adult.value),
});

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>): DispatchProps => ({
  updateCountryNames: bindActionCreators(actions.updateSelectedCountryNames, dispatch),
  updateDonorReportSearchSummary: bindActionCreators(actions.updateDonorReportSearchSummary, dispatch),
});

export class InternationalDonorsTable extends React.Component<Props> {
  static defaultProps = {
    patientId: undefined,
  };

  componentDidMount() {
    const { savedDonorSets, selectedDonorSetIds, updateCountryNames } = this.props;
    const countryNames = getCountryNames(selectedDonorSetIds, savedDonorSets);
    updateCountryNames(countryNames);
  }

  render() {
    const { donorType, filteredResultSets, isReportReadOnly, patientId, reportType, savedDonorSets } = this.props;

    const externalSavedDonors: SavedResultSet[] = Object.values(savedDonorSets).filter(
      (set: SavedResultSet) => set.algorithmUsed === algorithmTypes.external && set.patientId === patientId
    );

    const mappedSavedDonorSets = externalSavedDonors.map((set: SavedResultSet) => {
      const request = filteredResultSets.find((r) => r.savedDonorSets.includes(set.id as string));
      return {
        filterSummary: set.appliedFilters.filterSummary,
        dateModified: set.dateModified,
        notes: set.notes,
        numberOfSavedDonors: set.selectedResults.length,
        requestId: request ? request.requestId : undefined,
        resultSetId: request ? request.resultSetId : undefined,
        setId: set.id,
      };
    });

    const sortedSetsByDate = mappedSavedDonorSets.sort(
      // @ts-expect-error - This is the recommended way to sort by moment
      (a, b) => moment(b.dateModified) - moment(a.dateModified)
    );

    // there is only one result set per patient atm so it is safe to take just the first item in the array.
    const { requestId } = filteredResultSets[0];
    const { resultSetId } = filteredResultSets[0];

    return (
      <div>
        <div style={{ display: 'flex' }}>
          <div
            style={{
              flex: '1 1 auto',
              height: '400px',
            }}
          >
            <ReactTooltip className="tooltip" effect="solid" delayHide={100} place="top" html />
            <AutoSizer onResize={rebuildTooltip}>
              {({ width, height }) => (
                <Table
                  className="results-table"
                  width={width}
                  height={height}
                  headerHeight={60}
                  rowHeight={60}
                  headerClassName="headerClassName"
                  onScroll={rebuildTooltip}
                  rowCount={externalSavedDonors.length}
                  rowGetter={({ index }) => sortedSetsByDate[index]}
                  rowClassName={({ index }) => {
                    const isOdd = isOddNumber(index) ? 'odd' : 'even';
                    const isSelected =
                      sortedSetsByDate[index] && this.isSetSelected(sortedSetsByDate[index].setId as string)
                        ? 'selected'
                        : 'unselected';
                    const isReadOnly = isReportReadOnly ? 'readOnly' : '';
                    return `${isOdd} ${isSelected} ${isReadOnly}`;
                  }}
                >
                  <Column
                    label=""
                    dataKey="setId"
                    width={40}
                    // eslint-disable-next-line react/no-unstable-nested-components
                    cellRenderer={({ cellData }) => (
                      <FeatureFlag
                        flag="showEmdisSearchResults"
                        render={() => (
                          <label htmlFor="selectRow" className="form-checkbox-wrapper">
                            <input
                              data-setid={cellData}
                              onChange={() => this.toggleSelectedSets(cellData)}
                              checked={this.isSetSelected(cellData)}
                              disabled={isReportReadOnly}
                              type="checkbox"
                              name="selectRow"
                              readOnly
                            />
                          </label>
                        )}
                      />
                    )}
                  />
                  <Column
                    label="Date Saved"
                    dataKey="dateModified"
                    width={200}
                    cellRenderer={({ cellData }) => moment(cellData).format('DD-MM-YYYY HH:mm')}
                  />
                  <Column
                    label="Saved Filters"
                    dataKey="filterSummary"
                    width={950}
                    cellRenderer={({ cellData }) => cellData}
                    className="savedSetsTable"
                    headerClassName="filterSummaryHeader"
                  />
                  <Column
                    label="View Saved Set"
                    dataKey="numberOfSavedDonors"
                    style={{ padding: '20px' }}
                    width={450}
                    cellRenderer={({ cellData, rowData }) =>
                      this.showSavedSet(
                        cellData,
                        rowData.requestId,
                        rowData.resultSetId,
                        rowData.setId,
                        rowData.notes,
                        reportType
                      )
                    }
                  />
                </Table>
              )}
            </AutoSizer>
          </div>
        </div>
        <FeatureFlag
          flag="showEmdisSearchResults"
          render={() => (
            <div style={{ textAlign: 'center' }}>
              <span style={{ display: 'inline-block' }}>
                <Link
                  to={{
                    pathname: `/donorsearch/${requestId}/results`,
                    search: queryString.stringify({
                      resultSetId,
                      donorType,
                      reportType,
                    }),
                  }}
                  className="btn"
                >
                  View Result Set
                </Link>
              </span>
            </div>
          )}
        />
      </div>
    );
  }

  showSavedSet = (
    numberOfSavedDonors: number,
    requestId: string,
    resultSetId: number,
    setId: string,
    notes: string,
    reportType: string
  ) => {
    const { donorType, reportId } = this.props;
    const donorDisplayString = numberOfSavedDonors === 1 ? 'donor' : 'donors';
    const noteString = notes.length > 60 ? `${notes.substring(0, 60)}...` : `${notes}`;
    const savedSetString = numberOfSavedDonors === 0 ? 'Select donors' : `${numberOfSavedDonors} ${donorDisplayString}`;
    const noDonorsString = numberOfSavedDonors === 0 ? 'No saved donors found. ' : '';
    return (
      <FeatureFlag
        flag="showEmdisSearchResults"
        render={() => (
          <div>
            <span>
              {noDonorsString}
              <Link
                to={{
                  pathname: `/donorsearch/${requestId}/results`,
                  search: queryString.stringify({
                    resultSetId,
                    donorType,
                    setId,
                    reportId,
                    reportType,
                  }),
                }}
                style={{ textDecoration: 'underline' }}
              >
                {savedSetString}
              </Link>
            </span>
            {notes.length > 1 && (
              <div data-tip={`<strong>Notes:</strong> ${notes}`}>
                <span>
                  Notes: <i> {noteString}</i>
                </span>
              </div>
            )}
          </div>
        )}
        fallbackRender={() =>
          numberOfSavedDonors === 0 ? 'No saved donors found.' : `${numberOfSavedDonors} ${donorDisplayString}`
        }
      />
    );
  };

  toggleSelectedSets = (selectedSetId: string) => {
    const { reportType, savedDonorSets, updateDonorReportSearchSummary } = this.props;
    return updateDonorReportSearchSummary(selectedSetId, savedDonorSets, reportType);
  };

  isSetSelected = (id: string) => {
    const { selectedDonorSetIds } = this.props;
    return selectedDonorSetIds.includes(id);
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(InternationalDonorsTable);

import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import type { DonorType } from '../../../core/types';
import { SearchRequestSelectors } from '../../../donorMatchSearchRequests';
import type { ReduxState } from '../../../rootReducer';

type OwnProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  donorType: DonorType;
  // used in `mapStateToProps`
  // eslint-disable-next-line react/no-unused-prop-types, react/require-default-props
  setId?: string; // used in `mapStateToProps`
};
type StateProps = {
  notes?: string;
};
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = OwnProps & PropsFromRedux;

const mapStateToProps = (state: ReduxState, ownProps: OwnProps): StateProps => {
  const resultSet = SearchRequestSelectors.getSingleSavedResultSet(state, ownProps.donorType, ownProps.setId);
  return {
    notes: resultSet ? resultSet.notes : undefined,
  };
};

const DonorSetNotes = ({ notes }: Props) => {
  if (notes) {
    const notesString = notes.length > 60 ? `${notes.substring(0, 60)}...` : `${notes}`;

    return (
      <span data-tip={`<strong>Notes:</strong> ${notes}`} data-place="right">
        Notes: <i> {notesString}</i>
      </span>
    );
  }
  return null;
};

DonorSetNotes.defaultProps = {
  notes: undefined,
  setId: undefined,
} as Partial<Props>;

const connector = connect(mapStateToProps);

export default connector(DonorSetNotes);

import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch as ReduxDispatch } from 'redux';
import update from 'immutability-helper';
import * as actions from '../../redux/actions';
import { styles } from '../../style';
import Selectors from '../../redux/selectors';
import type { TableContents } from '../ResultsTable/searchResultsTableContents';
import type { HideableColumn } from '../../types';
import type { ReduxState } from '../../../rootReducer';

type OwnProps = {
  tableContents: TableContents<unknown>;
};
type StateProps = {
  hiddenColumns: HideableColumn[];
};
type Props = PropsFromRedux & OwnProps & StateProps;

const mapStateToProps = (state: ReduxState): StateProps => ({
  hiddenColumns: Selectors.getHiddenColumns(state),
});

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>) => ({
  updateHiddenColumns: bindActionCreators(actions.updateHiddenColumns, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class ToggleShownColumnCheckboxes extends PureComponent<Props> {
  render() {
    const { tableContents } = this.props;
    const hideableColumns = tableContents.columns.filter((column) => column.hideable);
    return (
      <div style={{ columnCount: 2 }}>
        {hideableColumns.map((column) => this.renderCheckbox(column.header, column.name))}
      </div>
    );
  }

  renderCheckbox = (header: string, name: string): React.ReactNode => (
    <label key={name} htmlFor={name} style={{ display: 'block' }}>
      <input
        type="checkbox"
        style={styles.columnCheckbox}
        name={name}
        defaultChecked={this.isColumnShown(name)}
        onChange={this.handleBoxChecked}
      />
      {header}
    </label>
  );

  handleBoxChecked = (
    event: React.SyntheticEvent<HTMLInputElement> & {
      currentTarget: HTMLInputElement;
    }
  ) => {
    this.updateHiddenColumnState(event.currentTarget);
  };

  updateHiddenColumnState(column: HTMLInputElement) {
    const { hiddenColumns, updateHiddenColumns } = this.props;
    const selectedColumn = column.name;
    const columnIndex = hiddenColumns.indexOf(selectedColumn as HideableColumn);
    let newHiddenColumns;
    if (column.checked) {
      // A check to see if the selectedColumn is not in the hidden columns list. If it isn't we don't try to remove it.
      if (columnIndex < 0) {
        return;
      }
      newHiddenColumns = update(hiddenColumns, {
        $splice: [[columnIndex, 1]],
      });
    } else {
      // A check to see if the selectedColumn is already in the hidden columns list, if it is we don't add it again.
      if (columnIndex >= 0) {
        return;
      }
      newHiddenColumns = update(hiddenColumns, {
        $push: [selectedColumn as HideableColumn],
      });
    }
    updateHiddenColumns(newHiddenColumns);
  }

  isColumnShown = (columnName: string) => {
    const { hiddenColumns } = this.props;
    return !hiddenColumns.includes(columnName as HideableColumn);
  };
}

export default connector(ToggleShownColumnCheckboxes);

import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch as ReduxDispatch } from 'redux';
import moment, { Moment } from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as actions from '../redux/actions';
import { styles as commonStyles } from '../../../style';
import autocompleteOff from '../../../core/constants/autocompleteOff';
import Selectors from '../../core/redux/selectors';
import type { ReduxState } from '../../../rootReducer';

type StateProps = {
  searchedPatientDob?: Moment;
  searchedPatientSurname?: string;
};
type Props = PropsFromRedux & StateProps;

const mapStateToProps = (state: ReduxState): StateProps => ({
  searchedPatientSurname: Selectors.getSearchedPatientSurname(state),
  searchedPatientDob: Selectors.getSearchedPatientDob(state),
});

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>) => ({
  getPatientDetailsByNameAndDob: bindActionCreators(actions.getPatientDetailsByNameAndDob, dispatch),
  resetPatientSummaries: bindActionCreators(actions.resetPatientSummaries, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type State = {
  patientDob?: Moment;
  patientSurname?: string;
};

type InputEvent = {
  value: string;
} & HTMLInputElement;

const styles = {
  ...commonStyles,
  searchBySurnameInput: {
    flexGrow: 3,
    width: 'initial',
    marginRight: '12px',
  },
};

class SearchByNameDobForm extends PureComponent<Props, State> {
  static defaultProps = {
    searchedPatientDob: undefined,
    searchedPatientSurname: undefined,
  };

  constructor(props: Props) {
    super(props);
    const { searchedPatientDob, searchedPatientSurname } = props;
    this.state = {
      patientDob: searchedPatientDob ? moment(searchedPatientDob) : undefined,
      patientSurname: searchedPatientSurname,
    };
  }

  render() {
    const { patientDob, patientSurname } = this.state;
    return (
      <form autoComplete="off" onSubmit={this.getPatient} style={{ marginTop: '10px' }}>
        <div style={{ display: 'flex' }}>
          <input
            style={styles.searchBySurnameInput}
            type="text"
            onChange={this.handlePatientSurnameInput}
            value={patientSurname}
            placeholder="Patient Surname"
            autoComplete={autocompleteOff}
          />
          <DatePicker
            dateFormat="dd/MM/yyyy"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            disabledKeyboardNavigation={false}
            selected={patientDob?.toDate()}
            onChange={this.handlePatientDobInput}
            placeholderText="Date of Birth"
            openToDate={patientDob?.toDate() || moment().subtract(30, 'years').toDate()}
            maxDate={moment().toDate()}
          />
        </div>
        <button type="submit" className="btn">
          Search
        </button>
      </form>
    );
  }

  getPatient = (event: React.MouseEvent<HTMLFormElement>) => {
    const { getPatientDetailsByNameAndDob, resetPatientSummaries } = this.props;
    const { patientDob, patientSurname } = this.state;
    event.preventDefault();
    if (patientSurname || patientDob) {
      return getPatientDetailsByNameAndDob(patientSurname, patientDob);
    }
    return resetPatientSummaries();
  };

  handlePatientSurnameInput = (event: React.SyntheticEvent<InputEvent>) => {
    this.setState({ patientSurname: event.currentTarget.value });
  };

  handlePatientDobInput = (date?: Date | [Date | null, Date | null] | /* for selectsRange */ null) => {
    this.setState({ patientDob: date ? moment(date as moment.MomentInput | undefined) : undefined });
  };
}

export default connector(SearchByNameDobForm);

import React, { ReactElement } from 'react';
import { styles as commonStyles } from '../style';

type Props = {
  left: ReactElement;
  centre: ReactElement;
  right: ReactElement;
};

const styles = {
  ...commonStyles,
  actionBarLeft: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  actionBarCentre: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '8px',
  },
  actionBarRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
};

const ReportActionsBar = ({ centre, left, right }: Props) => (
  <div>
    <div className="col span_4_of_12" style={styles.actionBarLeft}>
      {left}
    </div>
    <div className="col span_4_of_12" style={styles.actionBarCentre}>
      <h2>{centre}</h2>
    </div>
    <div className="col span_4_of_12" style={styles.actionBarRight}>
      {right}
    </div>
  </div>
);

export default ReportActionsBar;

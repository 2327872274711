import React from 'react';
import _ from 'lodash';
import HlaRow from '../HlaRow/HlaRow';
import type { Locus } from '../../../core/types';
import './hlaSection.scss';

type Props = {
  initialAntigenValues?: (Locus | undefined)[];
  locusType: string;
  onChange: (rowId: number, antigenId: string | null | undefined) => void;
  title: string;
  isShownOnOverlay?: boolean;
  isEven: boolean;
  isGias?: boolean;
};

const HlaSection = ({ isEven, initialAntigenValues, locusType, onChange, title, isShownOnOverlay, isGias }: Props) => (
  <div id={`HLA-${locusType}`} className={`hla-section-container${isEven ? ' hla-section-even' : ' hla-section-odd'}`}>
    <h4 className="hla-title"> {title}</h4>
    <div className={`hla-input-section${isShownOnOverlay ? ' donor' : ''}`}>
      {_(2)
        .range()
        .map((i) => (
          <HlaRow
            antigenRowId={i}
            isShownOnOverlay={isShownOnOverlay}
            initialAntigenValue={initialAntigenValues ? initialAntigenValues[i] : undefined}
            key={i}
            locusType={locusType}
            onChange={(antigenId) => onChange(i, antigenId)}
            isGias={isGias}
          />
        ))
        .value()}
    </div>
  </div>
);

HlaSection.defaultProps = {
  isShownOnOverlay: false,
  initialAntigenValues: undefined,
  isGias: undefined,
};

export default HlaSection;

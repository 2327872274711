import React, { CSSProperties } from 'react';
import { Link } from 'react-router-dom';

type Props = {
  className?: string;
  to: string | Record<string, unknown>;
  children: React.ReactNode;
  handleClick?: () => void;
  style?: Record<string, CSSProperties> | CSSProperties;
};
const LinkButton = ({ className, to, children, handleClick, style }: Props) => (
  <Link to={to} onClick={handleClick} className={className} style={style}>
    {children}
  </Link>
);

LinkButton.defaultProps = {
  handleClick: undefined,
  className: undefined,
  style: {},
};

export default LinkButton;

import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch as ReduxDispatch } from 'redux';
import * as actions from '../redux/actions';
import type { DonorType } from '../../core/types/index';
import { donorTypeDisplayString } from '../../core/constants/donorTypes';

type OwnProps = {
  donorType: DonorType;
};
type Props = PropsFromRedux & OwnProps;

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>) => ({
  resetResultsTable: bindActionCreators(actions.resetResultsTable, dispatch),
});

const connector = connect(undefined, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class ResetResultsButton extends PureComponent<Props> {
  render() {
    const { donorType } = this.props;
    const buttonText = `New ${donorTypeDisplayString(donorType)} Set`;
    return (
      <button className="btn btn--secondary btn--table" onClick={this.handleResetClick} tabIndex={0} type="button">
        {buttonText}
      </button>
    );
  }

  handleResetClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { resetResultsTable } = this.props;
    resetResultsTable();
    event.preventDefault();
  };
}

export default connector(ResetResultsButton);

import React from 'react';
import { bindActionCreators, AnyAction, Dispatch as ReduxDispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import * as actions from '../../currentUser/redux/actions';
import CurrentUserSelectors from '../../currentUser/redux/selectors';
import type { ReduxState } from '../../rootReducer';

type StateProps = {
  userRoles?: string[];
};

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>) => ({
  getUserRoles: bindActionCreators(actions.getUserRoles, dispatch),
});

const mapStateToProps = (state: ReduxState): StateProps => ({
  userRoles: CurrentUserSelectors.getCurrentUserRoles(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

const capitalize = (string: string) => string[0].toUpperCase() + string.substring(1);

class UserRoles extends React.Component<Props> {
  static defaultProps = {
    userRoles: undefined,
  };

  componentDidMount() {
    const { getUserRoles, userRoles } = this.props;

    if (!userRoles) {
      getUserRoles();
    }
  }

  render() {
    const { userRoles } = this.props;

    return (
      <ul className="nav-block user-profile">
        <span style={{ paddingRight: '5px' }}>User Roles:</span>

        {userRoles &&
          userRoles.map((userRole) => (
            <li style={{ paddingRight: '10px' }} key={userRole}>
              {' '}
              {capitalize(userRole)}
            </li>
          ))}
      </ul>
    );
  }
}

export default connector(UserRoles);

import React from 'react';
import { Moment } from 'moment';

type Props = {
  onClick: () => void;
  date?: Moment;
};

const RepeatSearchButton = (props: Props) => {
  const { date, onClick } = props;

  return (
    <div style={{ display: 'inline-flex', alignItems: 'center' }}>
      <button
        className="btn btn--inline"
        onClick={onClick}
        style={{ width: '100%', textAlign: 'center' }}
        type="button"
      >
        Date of Last Re-Search
      </button>
      <input
        disabled
        style={{ opacity: 'unset', margin: '0.5em 0 0.5em' }}
        type="text"
        value={date?.isValid() ? date.format('DD/MM/YYYY') : ''}
      />
    </div>
  );
};

RepeatSearchButton.defaultProps = {
  date: undefined,
};

export default RepeatSearchButton;

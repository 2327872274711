import React, { useEffect, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import ReactTooltip from 'react-tooltip';
import { AutoSizer, Column, Table } from 'react-virtualized';
import { bindActionCreators } from 'redux';
import { NoResultsFound, PopUpWrapper } from '../../../../../core';
import { formatDate } from '../../../../../core/helpers/dateHelpers';
import TextWithLimitedLines from '../../../../../core/components/TextWithLimitedLines/TextWithLimitedLines';
import { getDonorStatusName } from '../../../../../core/constants/donorStatuses';
import {
  externalInvestigationStatuses,
  getExternalInvestigationStatusName,
} from '../../../../../core/constants/externalInvestigationStatuses';
import {
  externalInvestigationTypes,
  getExternalInvestigationTypeNameInTable,
} from '../../../../../core/constants/externalInvestigationTypes';
import formatGrid from '../../../../../core/helpers/gridFormatter';
import { rebuildTooltip } from '../../../../../core/helpers/rebuildTooltip';
import * as actions from '../../../../../externalInvestigations/redux/actions';
import Selectors from '../../../../../externalInvestigations/redux/selectors';
import type {
  ExtendedTypingInvestigation,
  ExternalInvestigation,
  ExternalInvestigationReportCreationModel,
  ExternalInvestigationTextMessage,
  IDMInvestigation,
  SampleInformation,
  VTInvestigation,
  VTInvestigationDetails,
} from '../../../../../externalInvestigations/types';
import { ExternalInvestigationReportStatus } from '../../../../../externalInvestigations/types';
import { FeatureFlagsSelectors } from '../../../../../featureFlags';
import type { ReduxState } from '../../../../../rootReducer';
import institutionCodes from '../../../constants/internationalInstitutionCodes';
import { styles } from '../../../style';
import CancelExternalInvestigationsPopUp from './CancelExternalInvestigationsPopUp';
import './ExternalInvestigationsTable.scss';
import {
  formatInvestigationDetails,
  getRowClassName,
  isRowDisabled,
  orderExternalInvestigations,
} from './ExternalInvestigationsTableHelpers';
import { InvestigationResultsButtonsWithFeatureFlags } from './InvestigationResultsButtons';

const externalInvestigationsTableStyles = {
  cancelExternalInvestigationPopUpStyle: {
    ...styles.patientDashboardPopUp,
    width: '1100px',
  },
};

type Props = {
  patientId: string;
};

const ExternalInvestigationsTable = (props: Props) => {
  useEffect(() => {
    rebuildTooltip();
  });
  const { patientId } = props;
  const externalInvestigations = useSelector((state: ReduxState) => Selectors.getExternalInvestigations(state));
  const shouldShowCancelTestRequestsFeature = useSelector(
    (state: ReduxState) => FeatureFlagsSelectors.getCurrentFeatureFlags(state)?.showCancelTestRequestsFeature === true
  );
  const dispatch = useDispatch();
  const generateVirologyReport = bindActionCreators(actions.generateVirologyReport, dispatch);
  const generateHLAReport = bindActionCreators(actions.generateHLAReport, dispatch);
  const downloadPdfReport = bindActionCreators(actions.downloadPdfReport, dispatch);

  const [selectedRowIndexes, setSelectedRowIndexes] = useState<number[]>([]);
  const [selectedExtendedTypingInvestigationIds, setSelectedExtendedTypingInvestigationIds] = useState<number[]>([]);
  const [selectedIdmInvestigationIds, setSelectedIdmInvestigationIds] = useState<number[]>([]);
  const [selectedVerificationTypingInvestigationIds, setSelectedVerificationTypingInvestigationIds] = useState<
    number[]
  >([]);
  const [isCancelExternalInvestigationPopUpShown, setIsCancelExternalInvestigationPopUpShown] = useState(false);

  const baseTruncatedCellProps = {
    lineHeightPx: 20,
    numberOfLines: 2,
  };

  const getTruncatedCellPropsWithCustomTooltip = (tooltip: string) => ({
    ...{
      tooltip,
      ...baseTruncatedCellProps,
    },
  });

  const tableData = orderExternalInvestigations(externalInvestigations);
  const formattedInvestigationDetails = tableData.map((d) => formatInvestigationDetails(d));

  const createStatusTooltip = (rowData: ExternalInvestigation, cellData: string) => {
    const noneText = `<span class="none-text">None</span>`;
    const noResultsTooltipContent = `<strong>No Results Reason:</strong> ${
      rowData.statusReason || noneText
    }<br/><strong>Remark:</strong> ${rowData.statusRemark || noneText}`;
    const requestRejectedTooltipContent = `<strong>Failure Reason:</strong> ${
      rowData.failureSource || noneText
    }<br/><strong>Remark:</strong> ${rowData.statusRemark || noneText}`;
    let tooltipContent;
    if (cellData === externalInvestigationStatuses.NoResults.value && rowData.statusReason) {
      tooltipContent = noResultsTooltipContent;
    } else if (cellData === externalInvestigationStatuses.RequestRejected.value) {
      tooltipContent = requestRejectedTooltipContent;
    }
    return tooltipContent;
  };

  if (externalInvestigations.length === 0) {
    return <NoResultsFound resultType="requests" />;
  }

  const extractSampleInformations = (investigation: ExternalInvestigation): SampleInformation[] => {
    const vtInvestigation = investigation as VTInvestigation;
    return vtInvestigation?.investigationDetails.sampleInformations ?? [];
  };

  const extractTextMessages = (investigation: ExternalInvestigation): ExternalInvestigationTextMessage[] => {
    const vtInvestigation = investigation as VTInvestigation;
    return vtInvestigation?.investigationDetails.textMessages ?? [];
  };

  const shouldShowSendResultsButton = (investigation: ExternalInvestigation) => {
    const { status } = investigation;
    const isCompletedOrInProgress =
      status === externalInvestigationStatuses.InProgress.value ||
      status === externalInvestigationStatuses.Completed.value;
    return isCompletedOrInProgress;
  };

  const getVTRowHeight = (baseHeight: number, investigation: VTInvestigation) => {
    const anySampleInformationsOrTextMessages =
      (investigation.investigationDetails.sampleInformations &&
        investigation.investigationDetails.sampleInformations?.length > 0) ||
      investigation.investigationDetails.textMessages.length > 0;
    const hasVirologyReport =
      investigation.reports.virologyReportDetails.reportStatus !== ExternalInvestigationReportStatus.NoDataAvailable;

    if (anySampleInformationsOrTextMessages && hasVirologyReport && shouldShowSendResultsButton(investigation)) {
      return baseHeight * 1.9;
    }

    if (
      (anySampleInformationsOrTextMessages && hasVirologyReport) ||
      (anySampleInformationsOrTextMessages && shouldShowSendResultsButton(investigation)) ||
      (hasVirologyReport && shouldShowSendResultsButton(investigation))
    ) {
      return baseHeight * 1.3;
    }
    return baseHeight;
  };

  const getETRowHeight = (baseHeight: number, investigation: ExtendedTypingInvestigation) => {
    const anyTextMessages = investigation.investigationDetails.textMessages.length > 0;
    const hasHlaReport =
      investigation.reports.hlaReportDetails.reportStatus !== ExternalInvestigationReportStatus.NoDataAvailable;

    if (anyTextMessages && hasHlaReport) {
      return baseHeight * 1.3;
    }
    return baseHeight;
  };

  const getIDMRowHeight = (baseHeight: number, investigation: IDMInvestigation) => {
    const anyTextMessages = investigation.investigationDetails.textMessages.length > 0;
    const hasVirologyReport =
      investigation.reports.virologyReportDetails.reportStatus !== ExternalInvestigationReportStatus.NoDataAvailable;

    if (anyTextMessages && hasVirologyReport) {
      return baseHeight * 1.3;
    }
    return baseHeight;
  };

  const getRowHeight = (investigation: ExternalInvestigation) => {
    const baseHeight = 60;
    if (investigation.type === externalInvestigationTypes.vt.value) {
      return getVTRowHeight(baseHeight, investigation as VTInvestigation);
    }
    if (investigation.type === externalInvestigationTypes.extendedTyping.value) {
      return getETRowHeight(baseHeight, investigation as ExtendedTypingInvestigation);
    }
    if (investigation.type === externalInvestigationTypes.idm.value) {
      return getIDMRowHeight(baseHeight, investigation as IDMInvestigation);
    }
    return baseHeight;
  };

  const atLeastOneSelectedInvestigation = () => {
    const allInvestigationIds = selectedExtendedTypingInvestigationIds
      .concat(selectedIdmInvestigationIds)
      .concat(selectedVerificationTypingInvestigationIds);

    return allInvestigationIds.length > 0;
  };

  const handleStartExternalInvestigationCancellation = () => {
    setIsCancelExternalInvestigationPopUpShown(true);
  };

  const handleCancelExternalInvestigationCancellation = () => {
    setIsCancelExternalInvestigationPopUpShown(false);
  };

  const cancelExternalInvestigationsButton = () => (
    <PopUpWrapper
      placement="right"
      name="Cancel Requests"
      buttonClassName="btn btn--inline btn--search-request"
      onClick={handleStartExternalInvestigationCancellation}
      isPopUpShown={isCancelExternalInvestigationPopUpShown}
      popUpStyle={externalInvestigationsTableStyles.cancelExternalInvestigationPopUpStyle}
      shouldDarkenBackground
      popoverContainerClassName="react-tiny-popover-container--center"
    >
      <CancelExternalInvestigationsPopUp
        patientId={patientId}
        externalInvestigations={externalInvestigations}
        selectedExtendedTypingInvestigationIds={selectedExtendedTypingInvestigationIds}
        selectedIdmInvestigationIds={selectedIdmInvestigationIds}
        selectedVerificationTypingInvestigationIds={selectedVerificationTypingInvestigationIds}
        onCancel={handleCancelExternalInvestigationCancellation}
      />
    </PopUpWrapper>
  );

  const handleGenerateVirologyReport = (rowData: ExternalInvestigation) => {
    const creationModel: ExternalInvestigationReportCreationModel = {
      externalInvestigationId: rowData.id,
      externalInvestigationType: rowData.type,
      donorGrid: rowData.grid,
      donorId: rowData.donorId,
      patientId: rowData.patientId,
    };
    generateVirologyReport(creationModel);
  };

  const handleGenerateHLAReport = (rowData: ExternalInvestigation) => {
    const creationModel: ExternalInvestigationReportCreationModel = {
      externalInvestigationId: rowData.id,
      externalInvestigationType: rowData.type,
      donorGrid: rowData.grid,
      donorId: rowData.donorId,
      patientId: rowData.patientId,
    };
    generateHLAReport(creationModel);
  };

  const handleDownloadReport = (rowData: ExternalInvestigation, filename: string) => {
    downloadPdfReport(filename, rowData.id, rowData.type);
  };

  const checkIsGias = (externalInvestigation: ExternalInvestigation) => {
    if (externalInvestigation.type === externalInvestigationTypes.vt.value) {
      const vtInvestigationDetails = externalInvestigation.investigationDetails as VTInvestigationDetails;
      return vtInvestigationDetails.sampleRecipientInternationalInstitutionCode === institutionCodes.an;
    }
    return false;
  };

  const investigationResultsButtons = (rowData: ExternalInvestigation) => {
    const { reports, id, type } = rowData;
    const anyReportAvailable =
      reports.virologyReportDetails.reportStatus !== ExternalInvestigationReportStatus.NoDataAvailable ||
      reports.hlaReportDetails.reportStatus !== ExternalInvestigationReportStatus.NoDataAvailable;
    const sampleInformations = extractSampleInformations(rowData);
    const textMessages = extractTextMessages(rowData);
    const anySampleInformationsOrTextMessages = sampleInformations.length > 0 || textMessages.length > 0;

    const isVTAndShouldShowSendResultsButton =
      rowData.type === externalInvestigationTypes.vt.value && shouldShowSendResultsButton(rowData);

    const hlaSummaryData = {
      registryName: rowData.registryName,
      grid: rowData.grid,
      donorId: rowData.donorId,
      investigationId: rowData.id,
      patientId: rowData.patientId,
    };

    return anyReportAvailable || anySampleInformationsOrTextMessages || isVTAndShouldShowSendResultsButton ? (
      <InvestigationResultsButtonsWithFeatureFlags
        shouldShowSendResultsButton={isVTAndShouldShowSendResultsButton}
        investigationId={id}
        reports={reports}
        generateVirologyReport={() => handleGenerateVirologyReport(rowData)}
        generateHLAReport={() => handleGenerateHLAReport(rowData)}
        downloadReport={(filename: string) => handleDownloadReport(rowData, filename)}
        sampleInformations={sampleInformations}
        hlaSummaryData={hlaSummaryData}
        isGias={checkIsGias(rowData)}
        patientId={patientId}
        textMessages={textMessages}
        investigationType={type}
      />
    ) : null;
  };

  const handleTypedRowSelection = (
    rowIndex: number,
    externalInvestigationId: number,
    selectedExternalInvestigationsOfSingleType: number[]
  ): number[] => {
    if (
      selectedExternalInvestigationsOfSingleType.includes(externalInvestigationId) &&
      selectedRowIndexes.includes(rowIndex)
    ) {
      return selectedExternalInvestigationsOfSingleType.filter((id) => id !== externalInvestigationId);
    }

    return [...selectedExternalInvestigationsOfSingleType, externalInvestigationId];
  };

  const handleRowSelection = (rowIndex: number, externalInvestigation: ExternalInvestigation) => () => {
    if (!selectedRowIndexes.includes(rowIndex)) {
      setSelectedRowIndexes([...selectedRowIndexes, rowIndex]);
    } else {
      const filteredRowIndexes = selectedRowIndexes.filter((item) => item !== rowIndex);
      setSelectedRowIndexes(filteredRowIndexes);
    }

    switch (externalInvestigation.type) {
      case externalInvestigationTypes.extendedTyping.value:
        setSelectedExtendedTypingInvestigationIds(
          handleTypedRowSelection(rowIndex, externalInvestigation.id, selectedExtendedTypingInvestigationIds)
        );
        break;
      case externalInvestigationTypes.idm.value:
        setSelectedIdmInvestigationIds(
          handleTypedRowSelection(rowIndex, externalInvestigation.id, selectedIdmInvestigationIds)
        );
        break;
      case externalInvestigationTypes.vt.value:
        setSelectedVerificationTypingInvestigationIds(
          handleTypedRowSelection(rowIndex, externalInvestigation.id, selectedVerificationTypingInvestigationIds)
        );
        break;
      default:
        throw new Error(`${externalInvestigation.type} is not a recognised type of external investigation.`);
    }
  };

  // To highlight the Test Requests table row selected from the TC Management screen
  const { testRequestId } = useParams<{ testRequestId: string }>();
  const selectedRequestIndex = tableData.findIndex((item) => item.id === parseInt(testRequestId, 10));

  // Highlight rows for VT test requests that have the 'ActionRequired' flag
  const requestsWithActionRequired = tableData.reduce((indexes: number[], item, index) => {
    const actionRequired =
      checkIsGias(item) && item.type === externalInvestigationTypes.vt.value && item.actionRequired === true;

    if (
      (actionRequired && item.status === externalInvestigationStatuses.Completed.value) ||
      (actionRequired && item.status === externalInvestigationStatuses.InProgress.value)
    ) {
      indexes.push(index);
    }
    return indexes;
  }, []);

  return (
    <>
      <div style={{ display: 'flex' }}>
        <div
          style={{
            flex: '1 1 auto',
            height: '350px',
          }}
        >
          <ReactTooltip className="tooltip" effect="solid" delayHide={100} place="right" html />
          <AutoSizer onResize={rebuildTooltip}>
            {({ width, height }) => (
              <Table
                className="results-table external-investigations-table"
                width={width}
                height={height}
                headerHeight={50}
                rowHeight={({ index }) => getRowHeight(tableData[index])}
                headerClassName="headerClassName"
                rowCount={tableData.length}
                rowGetter={({ index }) => tableData[index]}
                rowClassName={({ index }) =>
                  getRowClassName(index, selectedRowIndexes, selectedRequestIndex, requestsWithActionRequired)
                }
                scrollToIndex={selectedRequestIndex}
                onScroll={rebuildTooltip}
              >
                {shouldShowCancelTestRequestsFeature && (
                  <Column
                    label=""
                    dataKey="id"
                    width={40}
                    minWidth={40}
                    flexGrow={1}
                    // eslint-disable-next-line react/no-unstable-nested-components
                    cellRenderer={({ rowIndex, rowData }) => {
                      const isDisabled = isRowDisabled(rowData);
                      return (
                        <label htmlFor="selectRow" className="form-checkbox-wrapper">
                          <input
                            checked={selectedRowIndexes.includes(rowIndex)}
                            disabled={isDisabled}
                            onChange={handleRowSelection(rowIndex, rowData)}
                            type="checkbox"
                            name="selectRow"
                            readOnly
                          />
                        </label>
                      );
                    }}
                  />
                )}
                <Column
                  label="Type"
                  className="column-wrap request-type-column"
                  dataKey="type"
                  width={50}
                  minWidth={50}
                  flexGrow={1}
                  cellRenderer={({ cellData }) => getExternalInvestigationTypeNameInTable(cellData)}
                />
                <Column
                  label="Request Date"
                  dataKey="requestedTime"
                  width={100}
                  minWidth={80}
                  flexGrow={1}
                  cellRenderer={({ cellData }) => formatDate(cellData)}
                />
                <Column
                  label="Details"
                  dataKey="id"
                  className="column-wrap"
                  width={350}
                  minWidth={150}
                  flexGrow={1}
                  // eslint-disable-next-line react/no-unstable-nested-components
                  cellRenderer={({ rowData }) => {
                    const { tooltipBody } = formatInvestigationDetails(rowData);
                    const { cell } = formattedInvestigationDetails.filter((d) => d.id === rowData.type + rowData.id)[0];
                    return (
                      <TextWithLimitedLines
                        {...getTruncatedCellPropsWithCustomTooltip(renderToString(tooltipBody as React.ReactElement))}
                      >
                        {cell}
                      </TextWithLimitedLines>
                    );
                  }}
                />
                <Column
                  className="column-wrap"
                  dataKey="investigationDetails"
                  width={20}
                  minWidth={20}
                  flexGrow={1}
                  cellRenderer={({ cellData }) => (cellData.urgent ? 'Y' : null)}
                  // eslint-disable-next-line react/no-unstable-nested-components
                  headerRenderer={() => (
                    <div className="urgent-column-header" title="Urgent">
                      U
                    </div>
                  )}
                />
                <Column
                  label="EMDIS Registry"
                  className="column-wrap"
                  dataKey="registryName"
                  width={280}
                  minWidth={120}
                  flexGrow={1}
                  // eslint-disable-next-line react/no-unstable-nested-components
                  cellRenderer={({ cellData }) => (
                    <TextWithLimitedLines {...getTruncatedCellPropsWithCustomTooltip(cellData)}>
                      {cellData}
                    </TextWithLimitedLines>
                  )}
                />
                <Column
                  label="Grid"
                  className="column-wrap"
                  dataKey="grid"
                  width={200}
                  minWidth={100}
                  flexGrow={1}
                  // eslint-disable-next-line react/no-unstable-nested-components
                  cellRenderer={({ cellData }) => (
                    <TextWithLimitedLines {...getTruncatedCellPropsWithCustomTooltip(formatGrid(cellData))}>
                      {formatGrid(cellData)}
                    </TextWithLimitedLines>
                  )}
                />
                <Column
                  label="Request Status"
                  className="column-wrap"
                  dataKey="status"
                  width={120}
                  minWidth={95}
                  flexGrow={1}
                  // eslint-disable-next-line react/no-unstable-nested-components
                  cellRenderer={({ cellData, rowData }) => (
                    <div data-tip={createStatusTooltip(rowData, cellData)}>
                      {getExternalInvestigationStatusName(cellData)}
                    </div>
                  )}
                />
                <Column
                  label="Updated Date"
                  dataKey="lastUpdatedTime"
                  width={100}
                  minWidth={80}
                  flexGrow={1}
                  cellRenderer={({ cellData }) => formatDate(cellData)}
                />
                <Column
                  label="Sample Arrival Date"
                  cellDataGetter={({ rowData }) => rowData.investigationDetails.sampleArrivalDate}
                  dataKey="investigationDetails.sampleArrivalDate"
                  width={100}
                  minWidth={80}
                  flexGrow={1}
                  cellRenderer={({ cellData }) => formatDate(cellData)}
                />
                <Column
                  label="Donor Status"
                  className="column-wrap"
                  dataKey="donorDetails.donorStatus"
                  cellDataGetter={({ rowData }) => ({
                    donorStatus: rowData.donorDetails.donorStatus,
                    reservedForThisPatient: rowData.reservedForThisPatient,
                  })}
                  width={120}
                  minWidth={80}
                  flexGrow={1}
                  cellRenderer={({ cellData }) => {
                    const { donorStatus, reservedForThisPatient } = cellData;
                    return getDonorStatusName(donorStatus, reservedForThisPatient);
                  }}
                />
                <Column
                  label="TU Date"
                  dataKey="donorDetails.donorUnavailableToTime"
                  cellDataGetter={({ rowData }) => rowData.donorDetails.donorUnavailableToTime}
                  width={100}
                  minWidth={80}
                  flexGrow={1}
                  cellRenderer={({ cellData }) => formatDate(cellData)}
                />
                <Column
                  label="Test Results"
                  dataKey="externalInvestigationResults"
                  width={150}
                  minWidth={120}
                  flexGrow={1}
                  cellRenderer={({ rowData }) => investigationResultsButtons(rowData)}
                />
              </Table>
            )}
          </AutoSizer>
        </div>
      </div>
      {atLeastOneSelectedInvestigation() && (
        <div style={{ textAlign: 'center' }}>
          <span style={{ display: 'inline-block' }}>{cancelExternalInvestigationsButton()}</span>
        </div>
      )}
    </>
  );
};

export default ExternalInvestigationsTable;

import React from 'react';

import '../../internationalDonorsTableStyling.scss';

import CountryCheckbox from './CountryCheckbox';

import type { Country } from '../../../types';

type Props = {
  country: Country;
  readOnly: boolean;
  isReportingOverride?: () => boolean;
  onHiddenChange: (isHidden: boolean) => void;
  onPendingChange: (isPending: boolean) => void;
};

const CountryRow = (props: Props) => {
  const { country, isReportingOverride, readOnly, onHiddenChange, onPendingChange } = props;

  const isReporting = isReportingOverride ? isReportingOverride() : country.isReporting;

  return (
    <tr key={country.name} className={country.hidden ? 'disabled' : ''}>
      <td>{country.name}</td>
      <td>
        <CountryCheckbox checked={isReporting} name="isReporting" id={country.name} disabled />
      </td>
      <td>
        <CountryCheckbox
          checked={country.isPending || false}
          disabled={readOnly}
          name="isPending"
          id={country.name}
          onChange={onPendingChange}
        />
      </td>
      <td>
        <CountryCheckbox checked={country.isReported} name="isReported" id={country.name} disabled />
      </td>
      {!readOnly && (
        <td>
          <CountryCheckbox
            checked={country.hidden || false}
            disabled={readOnly}
            name="hidden"
            id={country.name}
            onChange={onHiddenChange}
          />
        </td>
      )}
    </tr>
  );
};

CountryRow.defaultProps = {
  isReportingOverride: undefined,
};

export default CountryRow;

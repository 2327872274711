import React, { CSSProperties, PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch as ReduxDispatch } from 'redux';
import _ from 'lodash';

import { getAntigenData } from '../../redux/actions';
import { getSearchStringFromFilterString, isNmdpValue, isNullOrWhitespace } from '../../helpers/HlaStringHelpers';
import type { Antigen } from '../../../core/types';
import HlaLookupRow from '../HlaLookupRow/HlaLookupRow';

const DEBOUNCE_TIME_MS = 800;

type DispatchProps = {
  fetchAntigens: typeof getAntigenData;
};
type OwnProps = {
  antigenRowId: number;
  antigens: Antigen[];
  // The API will only return a subset of the available antigens for each request, for performance reasons
  apiAntigenLimit?: number;
  initialValue: string;
  locusType: string;
  onSelect: (antigen: Antigen) => void;
  onRequestClose?: () => void;
};
type Props = PropsFromRedux & OwnProps;

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>): DispatchProps => ({
  fetchAntigens: bindActionCreators(getAntigenData, dispatch),
});

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type State = {
  isLoadingAntigens: boolean;
  hasSearchedOnce: boolean;
  value: string;
};

type FilterEvent = { value: string } & HTMLInputElement;

const headerStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#899c1c90',
  padding: 24,
};

const inputStyle = {
  marginTop: 24,
  marginBottom: 24,
  width: '100%',
  height: 60,
  fontSize: 32,
  paddingLeft: 16,
};

const loaderStyle = {
  width: '30px',
  height: '30px',
  margin: 'auto',
};

export class HlaLookup extends PureComponent<Props, State> {
  fetchAntigensDebounced: any;

  static defaultProps = {
    apiAntigenLimit: undefined,
    onRequestClose: undefined,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      isLoadingAntigens: false,
      hasSearchedOnce: false,
      value: props.initialValue,
    };
  }

  render() {
    const { antigens, locusType } = this.props;
    const { isLoadingAntigens, value } = this.state;
    return (
      <div>
        {this.header()}
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <input id={`hla${locusType}Input`} style={inputStyle} onChange={this.handleFilterChange} value={value} />
          {isLoadingAntigens ? <div className="loader" style={loaderStyle} /> : null}
        </div>
        {this.noAntigensFound() ? this.noResultsSection() : antigens.map(this.lookupRow)}
      </div>
    );
  }

  header = () => {
    const { antigenRowId, locusType, onRequestClose } = this.props;
    return (
      <div style={headerStyle}>
        <button aria-label="Close" className="close" onClick={onRequestClose} type="button" />
        <h1 style={{ margin: 'auto' }}>HLA LOOKUP</h1>
        <h3 style={{ margin: 'auto', marginTop: 24 }}>
          {' '}
          Locus: {locusType} {antigenRowId + 1}
        </h3>
      </div>
    );
  };

  lookupRow = (antigen: Antigen, index: number) => {
    const { onSelect } = this.props;
    const { isLoadingAntigens, value } = this.state;
    return (
      <HlaLookupRow
        key={antigen.id}
        antigen={antigen}
        onSelect={() => onSelect(antigen)}
        onLookUp={() => this.handleLookUp(antigen)}
        backgroundColor={index % 2 === 0 ? 'white' : '#899c1c20'}
        searchString={value}
        isSearching={isLoadingAntigens}
      />
    );
  };

  handleLookUp = async (antigen: Antigen) => {
    this.setState({ value: antigen.hlaName });
    const hlaPrefix = getSearchStringFromFilterString(antigen.hlaName);
    if (this.fetchAntigensDebounced) {
      this.fetchAntigensDebounced.cancel();
    }
    await this.fetchAntigens(hlaPrefix);
  };

  noAntigensFound = () => {
    const { antigens } = this.props;
    const { hasSearchedOnce, isLoadingAntigens } = this.state;
    return !isLoadingAntigens && antigens.length === 0 && hasSearchedOnce;
  };

  noResultsSection = () => {
    const { value } = this.state;
    return (
      <div id="hla-lookup-no-results">
        No results found for hla prefix: <b>{getSearchStringFromFilterString(value)}</b>
      </div>
    );
  };

  handleFilterChange = async (event: React.SyntheticEvent<FilterEvent>) => {
    const prefix = getSearchStringFromFilterString(event.currentTarget.value);
    this.setState({ value: event.currentTarget.value });

    if (!isNullOrWhitespace(prefix)) {
      // Debounce to avoid hitting the server for every keystroke.
      // We store the function at the point of typing, and cancel it if it's not yet run if a new character is typed within the debounce time
      if (this.fetchAntigensDebounced) {
        this.fetchAntigensDebounced.cancel();
      }
      this.fetchAntigensDebounced = _.debounce(async () => {
        await this.fetchAntigens(prefix);
      }, DEBOUNCE_TIME_MS);
      await this.fetchAntigensDebounced();
    }
  };

  fetchAntigens = async (prefix: string) => {
    const { antigenRowId, apiAntigenLimit, fetchAntigens, locusType } = this.props;
    const hlaPrefix = isNmdpValue(prefix) ? undefined : prefix;
    const nmdpPrefix = isNmdpValue(prefix) ? prefix : undefined;
    this.setState({ isLoadingAntigens: true, hasSearchedOnce: true });

    await fetchAntigens(antigenRowId, locusType, hlaPrefix, nmdpPrefix, apiAntigenLimit);
    this.setState({ isLoadingAntigens: false });
  };
}

export default connector(HlaLookup);

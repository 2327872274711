import _ from 'lodash';
import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { SavedReportsSelectors } from '../../../core/redux/selectors';
import ReportSummaryCard from '../../../savedReports/components/ReportSummaryCard/ReportSummaryCard';
import type { ApiSavedReportMinimal } from '../../../types/api';
import type { ReduxState } from '../../../../rootReducer';

type OwnProps = {
  columnName: string;
  // eslint-disable-next-line react/no-unused-prop-types
  reportIds: string[]; // used in `mapStateToProps`
};
type StateProps = {
  isMinimal: boolean;
  reports: ApiSavedReportMinimal[];
};
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & OwnProps;
type State = {
  isCurrentDataFromMinimalEndpoint: boolean;
};

const mapStateToProps = (state: ReduxState, ownProps: OwnProps): StateProps => ({
  reports: SavedReportsSelectors.getSearchReportsByIds(state, ownProps.reportIds),
  isMinimal: SavedReportsSelectors.getReportIsMinimal(state),
});

export const orderedReports = (reports: ApiSavedReportMinimal[]): ApiSavedReportMinimal[] => {
  const haveReceivedDate = _.filter(reports, 'DateSearchReceived');
  const noReceivedDate = _.filter(reports, (report) => !report?.DateSearchReceived);
  const orderedReceivedDate = _.orderBy(haveReceivedDate, 'DateSearchReceived', 'asc');
  const orderedNoReceivedDate = _.orderBy(noReceivedDate, 'DateGenerated', 'asc');
  return orderedReceivedDate.concat(orderedNoReceivedDate);
};

export class SingleDashboardColumn extends PureComponent<Props, State> {
  render() {
    const { columnName, isMinimal } = this.props;

    const cardStyle = isMinimal ? { backgroundColor: '#f9f9f9' } : undefined;

    return (
      <div className="col span_12_of_12">
        <h2 className="border-bottom-solid" style={{ textAlign: 'center' }}>
          {columnName}
        </h2>
        <div style={cardStyle}>{this.renderReportCardList()}</div>
      </div>
    );
  }

  renderReportCardList = () => {
    const { reports } = this.props;
    return (
      <>
        {orderedReports(reports).map(
          (report) => report && <ReportSummaryCard key={report.Id} report={report} shouldShowDeleteDraftButton />
        )}
      </>
    );
  };
}

const connector = connect(mapStateToProps);

export default connector(SingleDashboardColumn);

import React from 'react';
import { useAccount, useMsal } from '@azure/msal-react';
import packageJson from '../../../package.json';

const { version } = packageJson;

const UserProfile = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);

  const onLogout = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    instance.logoutRedirect();
  };

  return (
    <ul className="nav-block user-profile">
      <li style={{ paddingRight: '10px' }}>App Version: {version}</li>
      <li>{account?.name}</li>
      <li>
        <a href="/logout" onClick={onLogout}>
          Logout
        </a>
      </li>
    </ul>
  );
};

export default UserProfile;

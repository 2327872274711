import React from 'react';
import { HlaPopUpWrapper } from '@an/nova-frontend-hla-popup';
import { hlaDisplay } from '../../../core';
import { colors } from '../../../style';

import type { Antigen } from '../../../core/types';

type Props = {
  locus1: Antigen;
  locus2: Antigen;
};

const HlaPairDisplay = ({ locus1, locus2 }: Props): JSX.Element | null => {
  if (!locus1) {
    return null;
  }

  if (hlaDisplay.areLociDuplicates(locus1, locus2)) {
    return <HlaPopUpWrapper highlightColour={colors.ANGreen} hlaData={locus1} />;
  }
  return (
    <div>
      <HlaPopUpWrapper highlightColour={colors.ANGreen} hlaData={locus1} />
      <HlaPopUpWrapper highlightColour={colors.ANGreen} hlaData={locus2} />
    </div>
  );
};
// This needs to be here to ensure that the Component is named in tests
export default HlaPairDisplay;

import React, { PureComponent } from 'react';
import { AnyAction, bindActionCreators, Dispatch as ReduxDispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import queryString from 'query-string';
import * as actions from '../../redux/actions';
import { DetailedPatientDisplay } from '../../../patient';
import { ErrorMessage } from '../../../core';
import Selectors from '../../redux/selectors';
import { styles } from '../../style';

import type { DonorType, RouterMatch } from '../../../core/types';
import ValidationResultsTable from './ValidationResultsTable';
import LinkButton from '../../../core/components/LinkButton/LinkButton';
import type { ReduxState } from '../../../rootReducer';

type QueryParams = {
  setId: string;
  reportId: string | null | undefined;
  resultSetId: string;
  donorType: DonorType;
};

type OwnProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  location: any;
  // used in `mapStateToProps`
  match: RouterMatch;
};
type StateProps = {
  // eslint-disable-next-line react/require-default-props
  errorMessages?: (string | undefined)[];
  patientId?: string;
  queryParams: QueryParams;
  searchHasErrored: boolean;
};
type Props = PropsFromRedux & OwnProps & StateProps;

const mapStateToProps = (state: ReduxState, ownProps: OwnProps): StateProps => {
  const queryParams: QueryParams = queryString.parse(ownProps.location.search);
  return {
    searchHasErrored: Selectors.getHasErrored(state, queryParams.resultSetId),
    patientId: Selectors.getRequestPatientId(state),
    errorMessages: Selectors.getErrorMessages(state, queryParams.resultSetId),
    queryParams,
  };
};

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>) => ({
  getFullResultsPageData: bindActionCreators(actions.getFullResultsPageData, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class ValidationResultsPage extends PureComponent<Props> {
  static defaultProps = {
    patientId: undefined,
  };

  componentDidMount() {
    const { getFullResultsPageData, match } = this.props;
    const { id } = match.params;
    if (id) {
      const { queryParams } = this.props;
      const { donorType, resultSetId, setId } = queryParams;
      getFullResultsPageData(id, donorType, resultSetId, setId);
    }
  }

  render() {
    const { errorMessages, patientId, queryParams, searchHasErrored } = this.props;
    const { donorType, resultSetId } = queryParams;

    return (
      <div className="col span_12_of_12" style={styles.flexToFillAllTheSpace}>
        {searchHasErrored ? (
          <div className="col span_4_of_12 push_4">
            <ErrorMessage errorMessages={errorMessages} />
          </div>
        ) : (
          <div className="col span_12_of_12" style={styles.flexToFillAllTheSpace}>
            <span style={{ fontSize: 40 }}>
              This page is intended to be used for validation of results from the Anthony Nolan Search Algorithm. If you
              want to create reports, go to the regular results page:
            </span>
            <LinkButton
              to={`results?resultSetId=${resultSetId}&donorType=${donorType}`}
              style={{
                ...styles.button,
                marginRight: '0px',
                width: '300px',
                textAlign: 'center',
              }}
              className="btn btn--inline"
            >
              Back To Results Page
            </LinkButton>
            <div className="col span_12_of_12">
              <DetailedPatientDisplay patientId={patientId} />
            </div>
            <ValidationResultsTable patientId={patientId} donorType={donorType} resultSetId={resultSetId} />
          </div>
        )}
      </div>
    );
  }
}

export default connector(ValidationResultsPage);

import React, { PureComponent } from 'react';
import moment, { Moment } from 'moment';
import commonStyles from './Styles';
import type { PatientInfo } from '../../types';
import { formatAddress } from '../../../core/helpers/formatAddress';

type Props = {
  patient: PatientInfo;
};

type TableRow = {
  titleString: string;
  value: string;
};
type TableColumn = {
  tableContent: TableRow[];
  title: string;
};
type TableContent = TableColumn[];

const displayDate = (date: Moment | null | undefined) => (date ? moment(date).format('DD/MM/YYYY') : 'Unknown');

const trueFalseValueToString = (value: boolean) => {
  if (value === true) {
    return 'Yes';
  }
  if (value === false) {
    return 'No';
  }
  return 'Unknown';
};

const styles = {
  ...commonStyles,
  tableBody: {
    verticalAlign: 'top',
  },
};

class ExtraPatientDetails extends PureComponent<Props> {
  render(): JSX.Element {
    const { patient } = this.props;

    const tableContent = [
      {
        title: 'Additional Details',
        tableContent: [
          { titleString: 'Patient Type', value: patient.patientType },
          { titleString: 'Status', value: patient.status },
          {
            titleString: 'Is Private Patient',
            value: trueFalseValueToString(patient.isPrivatePatient),
          },
          {
            titleString: 'Created Date',
            value: displayDate(patient.dateRegistered),
          },
          { titleString: 'Diagnosis', value: patient.diagnosis },
          {
            titleString: 'Date Of Diagnosis',
            value: displayDate(patient.diagnosisDate),
          },
          { titleString: 'CMV', value: patient.cmv },
          {
            titleString: 'Date CMV Tested',
            value: displayDate(patient.cmvTestedDate),
          },
        ],
      },

      {
        title: 'Hospital Details',
        tableContent: [
          {
            titleString: "Patient's Hospital ID",
            value: patient.hospitalAssignedPatientId,
          },
          {
            titleString: "Patient's Hospital",
            value: patient.hospitalName,
          },
          {
            titleString: 'Referring Doctor',
            value: patient.referringDoctor,
          },
          {
            titleString: 'Invoice',
            value: patient.invoiceAddress ? formatAddress(patient.invoiceAddress) : '',
          },
          {
            titleString: 'Originating Registry',
            value: patient.originatingRegistryName,
          },
          {
            titleString: 'Originating Registry Patient ID',
            value: patient.originatingRegistryPatientId,
          },
          {
            titleString: 'External Laboratory',
            value: patient.externalLaboratoryName,
          },
        ],
      },

      {
        title: 'File Location',
        tableContent: [
          {
            titleString: 'UK or GIAS',
            value: patient.temporaryFileLocation,
          },
          {
            titleString: 'International',
            value: patient.foreignSearchLocation,
          },
          { titleString: 'CORD', value: patient.cordSearchLocation },
        ],
      },

      {
        title: 'Notes',
        tableContent: [{ titleString: 'Notes', value: patient.notes }],
      },
    ];

    return (
      <div className="additionalPatientDetails">
        <div style={{ ...styles.cardLayout }}>
          <table className="table--no-inner-border">
            <thead style={{ ...styles.tableHeader }}>
              <tr
                style={{
                  ...styles.centeredTableData,
                  ...styles.headerRow,
                  ...styles.cardHeaderExtraHieght,
                }}
              >
                {this.renderTableHeaders(tableContent)}
              </tr>
            </thead>
            <tbody style={styles.tableBody}>
              <tr
                style={{
                  ...styles.centeredTableData,
                }}
              >
                {this.renderTableContent(tableContent)}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  renderTableHeaders = (tableContent: TableContent): React.ReactElement<Props>[] =>
    tableContent.map((column) => (
      <th style={styles.patientCardHeaderNameArea} key={column.title}>
        {column.title}
      </th>
    ));

  renderTableContent = (tableContent: TableContent): React.ReactElement<Props>[] =>
    tableContent.map((column) => (
      <td
        rowSpan={1}
        style={{
          ...styles.textContainer,
          ...styles.titleLeft,
        }}
        key={column.title}
      >
        <table className="inner-table">
          <tbody>
            {column.tableContent.map((data) => (
              <tr id={data.titleString.split(' ').join('').replace(/'/, '')} key={data.titleString}>
                <td>{data.titleString}</td>
                <td>{data.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </td>
    ));
}

export default ExtraPatientDetails;

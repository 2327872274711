import React from 'react';
import type { AnyAction, Dispatch as ReduxDispatch } from 'redux';
import Select, { SingleValue } from 'react-select';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { SearchType, searchTypesDetails } from '../../../../core/constants/searchTypes';
import Selectors from '../../redux/selectors';
import * as actions from '../../redux/actions';
import { styles as commonStyles } from '../../style';

import type { ReduxState } from '../../../../rootReducer';
import type { PatientDashboardReducerState } from '../../redux/reducer';

const styles = {
  ...commonStyles,
  dropDownMenuPadding: {
    padding: '0px 12px',
  },
};
type StateProps = {
  searchRequestData: PatientDashboardReducerState;
};
type Props = PropsFromRedux & StateProps;

type Option = {
  value: SearchType;
  label: string;
};

type Options = {
  label: string;
  value: SearchType;
  listPosition: number;
};

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>) => ({
  updateSearchRequestType: bindActionCreators(actions.updateSearchRequestType, dispatch),
});

const mapStateToProps = (state: ReduxState): StateProps => ({
  searchRequestData: Selectors.getSearchRequestData(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const sortByListPosition = (options: Options[]) =>
  options.sort((a: Options, b: Options) => {
    if (a.listPosition > b.listPosition) {
      return 1;
    }
    if (b.listPosition > a.listPosition) {
      return -1;
    }
    return 0;
  });

class SearchTypeSelect extends React.Component<Props> {
  render() {
    const { searchRequestData } = this.props;
    const options = _.values(searchTypesDetails).map((searchType) => ({
      label: searchType.label,
      value: searchType.value,
      listPosition: searchType.listPosition,
    }));
    const sortedOptions = sortByListPosition(options);
    const selectedOption = options.find((option) => option.value === searchRequestData.adultSearchType);

    return (
      <>
        <h3 style={styles.searchTitle}>Adult Search Type</h3>
        <Select
          id="search-types"
          value={selectedOption}
          onChange={this.handleOptionSelect}
          options={sortedOptions}
          className="react-select-container"
          classNamePrefix="react-select"
        />
      </>
    );
  }

  handleOptionSelect = (element: SingleValue<Option>) => {
    const { updateSearchRequestType } = this.props;
    if (element) {
      updateSearchRequestType(element.value);
    }
  };
}

export default connector(SearchTypeSelect);

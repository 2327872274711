import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch as ReduxDispatch } from 'redux';

import PatientDetailsForm from './forms/PatientDetailsForm/PatientDetailsForm';
import Selectors from '../core/redux/selectors';
import { LoadingMessage } from '../../core';
import * as actions from './redux/actions';
import type { ReduxState } from '../../rootReducer';

type StateProps = {
  isPatientBeingSaved: boolean;
};
type Props = PropsFromRedux & StateProps;

const mapStateToProps = (state: ReduxState): StateProps => ({
  isPatientBeingSaved: Selectors.isPatientBeingSaved(state),
});

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>) => ({
  savePatient: bindActionCreators(actions.saveNewPatientDetails, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const PatientCreation = ({ isPatientBeingSaved, savePatient }: Props) => (
  <div className="col span_10_of_12 push_1">
    <h1 className="border-bottom-solid">Create a Patient</h1>
    <LoadingMessage isLoading={isPatientBeingSaved}>
      {/* @ts-expect-error - redux-form onSubmit function not typescript comparable */}
      <PatientDetailsForm onSubmit={savePatient} />
    </LoadingMessage>
  </div>
);

export default connector(PatientCreation);

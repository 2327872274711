import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, AnyAction, Dispatch as ReduxDispatch } from 'redux';
import { fetchAllReportsForDashboard, fetchMinimalReportsForDashboard } from '../../redux/actions';
import DashboardColumns from '../DashboardColumns/DashboardColumns';
import { ReportDashboardSelectors, SavedReportsSelectors } from '../../../core/redux/selectors';
import { ErrorMessage, LoadingMessage } from '../../../../core/index';
import UsersReportsSelect from '../UsersReportsSelect';

import type { ReportIdsByStatus } from '../../../types/index';
import type { ErrorMessages } from '../../../../core/types/index';
import type { ReduxState } from '../../../../rootReducer';

type DispatchProps = {
  fetchMinimalReports: typeof fetchMinimalReportsForDashboard;
  fetchReports: typeof fetchAllReportsForDashboard;
};
type StateProps = {
  errorMessage: ErrorMessages;
  hasErrored: boolean;
  isFetching: boolean;
  reportIds: ReportIdsByStatus;
};
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

const mapStateToProps = (state: ReduxState): StateProps => ({
  reportIds: ReportDashboardSelectors.getReportIds(state),
  isFetching: SavedReportsSelectors.isFetchingReports(state),
  hasErrored: SavedReportsSelectors.hasFetchReportsErrored(state),
  errorMessage: SavedReportsSelectors.getReportErrorMessage(state),
});

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>): DispatchProps => ({
  fetchReports: bindActionCreators(fetchAllReportsForDashboard, dispatch),
  fetchMinimalReports: bindActionCreators(fetchMinimalReportsForDashboard, dispatch),
});

export class ReportDashboard extends PureComponent<Props> {
  componentDidMount() {
    const { fetchMinimalReports, fetchReports } = this.props;
    fetchMinimalReports();
    fetchReports();
  }

  render() {
    const { errorMessage, hasErrored, isFetching, reportIds } = this.props;
    return (
      <div style={{ width: '100%' }}>
        <div className="col span_12_of_12">
          <h1 className="border-bottom-solid">Report Dashboard</h1>
        </div>
        <div className="col span_12_of_12">
          <LoadingMessage isLoading={isFetching}>
            {hasErrored ? (
              <ErrorMessage errorMessages={errorMessage} />
            ) : (
              <div className="col span_12_of_12">
                <div className="col span_3_of_12">
                  <UsersReportsSelect />
                </div>
                <div className="col span_12_of_12">
                  <DashboardColumns reportIds={reportIds} />
                </div>
              </div>
            )}
          </LoadingMessage>
        </div>
      </div>
    );
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ReportDashboard);

import React, { PureComponent } from 'react';
import AlgorithmSelector from './AlgorithmSelector';
import { minimumNumberOfAlgorithms } from './AlgorithmComparatorValidation';
import type { DonorType } from '../../../core/types';

type Props = {
  donorType: DonorType;
  selectedAlgorithm1: string;
  selectedAlgorithm2: string;
  algorithmsToCompare: string[];
  updateSelectedAlgorithms: (algorithm1: string, algorithm2: string) => void;
};

class AlgorithmSelectorPanel extends PureComponent<Props> {
  static insufficientAlgorithmsMessage = () => (
    <p id="insufficient-algorithms-message" style={{ color: 'red' }}>
      Insufficient number of algorithm values provided.
    </p>
  );

  render() {
    const { algorithmsToCompare } = this.props;
    return (
      <div>
        {minimumNumberOfAlgorithms(algorithmsToCompare)
          ? this.algorithmSelectors()
          : AlgorithmSelectorPanel.insufficientAlgorithmsMessage()}
      </div>
    );
  }

  algorithmSelectors() {
    const { donorType, selectedAlgorithm1, selectedAlgorithm2, algorithmsToCompare, updateSelectedAlgorithms } =
      this.props;
    return (
      <>
        <AlgorithmSelector
          donorType={donorType}
          selectedAlgorithm={selectedAlgorithm1}
          algorithmsToCompare={algorithmsToCompare}
          updateSelectedAlgorithm={(updatedselection) => {
            updateSelectedAlgorithms(updatedselection, selectedAlgorithm2);
          }}
        />

        <AlgorithmSelector
          donorType={donorType}
          selectedAlgorithm={selectedAlgorithm2}
          algorithmsToCompare={algorithmsToCompare}
          updateSelectedAlgorithm={(updatedSelection) => {
            updateSelectedAlgorithms(selectedAlgorithm1, updatedSelection);
          }}
        />
      </>
    );
  }
}

export default AlgorithmSelectorPanel;

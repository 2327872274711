import React from 'react';
import { styles } from '../../../style';

const NoPatientFound = () => (
  <div style={{ ...styles.errorMessage, marginTop: '10px' }}>
    <p>No patients found! Please check the entered information.</p>
  </div>
);

export default NoPatientFound;

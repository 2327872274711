import React, { CSSProperties } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ErrorMessage, LoadingMessage, PopUpWrapper } from '../../../../../core';
import type { ErrorMessages } from '../../../../../core/types/index';
import { SearchRequestSelectors } from '../../../../../donorMatchSearchRequests';
import type { ReduxState } from '../../../../../rootReducer';
import { colors } from '../../../../../style/index';
import NewSearchRequestForm from '../../NewSearchRequest/NewSearchRequestForm/NewSearchRequestForm';
import SearchRequestsTable from './SearchRequestsTable';

const styles: Record<string, CSSProperties> = {
  newSearchRequestPopUp: {
    padding: '0',
    position: 'fixed',
    width: '700px',
    top: '50%',
    left: '50%',
    overflow: 'auto',
    maxHeight: '90%',
    // This has been set in app.scss to override react-tiny-popover style
    // transform: 'translate(-50%, -50%) !important',
    border: `1px solid ${colors.ANGreen}`,
  },
};

type OwnProps = {
  patientId: string;
};
type StateProps = {
  errorMessage: ErrorMessages;
  hasErrored: boolean;
  isFetching: boolean;
};
type Props = OwnProps & StateProps & PropsFromRedux;
type State = {
  isPopUpShown: boolean;
};

const mapStateToProps = (state: ReduxState): StateProps => ({
  errorMessage: SearchRequestSelectors.getRequestsErrorMessage(state),
  isFetching: SearchRequestSelectors.isFetchingSearchRequests(state),
  hasErrored: SearchRequestSelectors.hasUserRequestsFetchErrored(state),
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class SearchRequestsTab extends React.Component<Props, State> {
  state = {
    isPopUpShown: true,
  };

  render() {
    const { patientId, errorMessage, hasErrored, isFetching } = this.props;
    const { isPopUpShown } = this.state;
    return (
      <div>
        {hasErrored ? (
          <div style={{ marginTop: '10px' }}>
            <ErrorMessage errorMessages={errorMessage} />
          </div>
        ) : (
          <div className="results-table results-table--border fixed-width">
            <PopUpWrapper
              buttonClassName="btn btn--inline btn--search-request"
              isButtonDisabled={isFetching}
              isPopUpShown={isPopUpShown}
              name="New Search Request"
              onClick={this.handlePopUpClick}
              placement="right"
              popUpStyle={styles.newSearchRequestPopUp}
              shouldDarkenBackground
              popoverContainerClassName="react-tiny-popover-container--center"
            >
              <NewSearchRequestForm onSubmit={this.hidePopUp} onClose={this.hidePopUp} patientId={patientId} />
            </PopUpWrapper>
            <h2 className="border-bottom-solid">Recent Searches</h2>
            <LoadingMessage isLoading={isFetching}>
              <SearchRequestsTable patientId={patientId} />
            </LoadingMessage>
          </div>
        )}
      </div>
    );
  }

  hidePopUp = () => {
    this.setState({ isPopUpShown: false });
  };

  handlePopUpClick = () => {
    this.setState({ isPopUpShown: true });
  };
}

export default connector(SearchRequestsTab);

import React from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { ApiError } from '../../../core';
import { capitalise } from '../../../core/helpers/capitalise';
import { mapSelectOptions } from '../../../patient/patientCreation/helpers/formsHelpers';
import convertToHlaString from '../../../core/helpers/convertToHlaString';
import Subject from '../../redux/selectors';
import { donorRecordTypes } from '../../constants/donorRecordTypes';
import { FamilyRelationshipInfoButton } from './FamilyRelationshipInfoButton';
import type { RelationshipType, SubjectType } from '../../types';
import { ApiErrorType } from '../../../core/types';
import { ReduxState } from '../../../rootReducer';

type Props = {
  subjectLinkType: SubjectType;
  relationshipTypes: RelationshipType[];
  onRelationshipTypeChange: (arg0: RelationshipType) => void;
};

export const LinkedSubjectSearchResult = ({ subjectLinkType, relationshipTypes, onRelationshipTypeChange }: Props) => {
  const isLoading = useSelector((state: ReduxState) => Subject.isLoadingSubject(state));
  const subjectSearchResult = useSelector((state: ReduxState) => Subject.getSubject(state));
  const error: ApiErrorType | unknown = useSelector((state: ReduxState) => Subject.loadingSubjectError(state));

  const firstNameString =
    subjectSearchResult && subjectSearchResult.FirstName ? `${subjectSearchResult.FirstName} ` : '';

  const subjectName = subjectSearchResult && `${firstNameString || ''}  ${subjectSearchResult.LastName || ''}`;

  const isCord =
    subjectSearchResult && subjectSearchResult.adultOrCord && subjectSearchResult.adultOrCord === donorRecordTypes.cord;

  const subjectLinkTypeDisplayString = capitalise(subjectLinkType);

  return (
    <>
      {!isLoading && subjectSearchResult && (
        <>
          <h4>{subjectLinkTypeDisplayString} Details</h4>
          <table className="subject-result__table" data-testid="subject-result-table">
            <tbody>
              {!isCord && (
                <tr>
                  <th>{subjectLinkTypeDisplayString} Name</th>
                  <td>{subjectName}</td>
                </tr>
              )}
              {isCord && (
                <tr>
                  <th>Cord ID</th>
                  <td>{subjectSearchResult.cordId}</td>
                </tr>
              )}
              <tr>
                <th>{subjectLinkTypeDisplayString} ID</th>
                <td>{subjectSearchResult.Id}</td>
              </tr>
              <tr>
                <th>HLA String</th>
                <td>
                  {subjectSearchResult && subjectSearchResult.Hla && (
                    <div style={{ wordBreak: 'break-word' }}>{convertToHlaString(subjectSearchResult.Hla)}</div>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
          <h4>Select Family Relationship</h4>
          <div data-testid="subject-relationship-select">
            <FamilyRelationshipInfoButton />
            <Select
              id="subject-relationship-select"
              name="subject-relationship-select"
              className="react-select-container"
              classNamePrefix="react-select"
              placeholder="Select option..."
              isClearable
              options={mapSelectOptions(relationshipTypes)}
              onChange={(e) => onRelationshipTypeChange(e?.value as RelationshipType)}
            />
          </div>
        </>
      )}
      {/* @ts-expect-error unknown is not assignable to type. Fix by changing rest client to return undefined instead of unknown? */}
      {error && <ApiError error={error} />}
    </>
  );
};

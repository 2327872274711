import React from 'react';
import _ from 'lodash';
import type { InvestigationTest } from '../../types';
import { WarningMessage } from '../../../core/components/WarningMessage';
import { toggleArrayItem } from '../../../util/arrayUtils';

type Props = {
  tests: InvestigationTest[];
  selectedTests: number[];
  onSelectedTestsUpdate: (testsUpdate: number[]) => void;
};

const SubjectTestDetails = ({ onSelectedTestsUpdate, selectedTests, tests }: Props) => {
  const handleSelectTest = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedTest = parseInt(e.currentTarget.value, 10);
    onSelectedTestsUpdate(toggleArrayItem(selectedTests, selectedTest));
  };

  const isSelectedTest = (testId: number) => selectedTests.includes(testId);

  const noTests = tests.every((test) => _.isEmpty(test));
  const sortedTests = _.sortBy(tests, 'TestName');
  return noTests ? (
    <WarningMessage message="No tests in selected category" />
  ) : (
    <div className="table-wrapper" style={{ maxHeight: '200px' }}>
      <table data-testid="subject-tests-table" className="table table-">
        <thead className="table-header table--sticky-header">
          <tr style={{ textAlign: 'left' }}>
            <th>Name</th>
            <th>Team</th>
            <th style={{ width: '120px' }}>Select Test*</th>
          </tr>
        </thead>
        <tbody>
          {sortedTests.map((test) => (
            <tr key={test.TestId}>
              <td>{test.TestName}</td>
              <td>{test.LabsTeam}</td>
              <td>
                <input
                  type="checkbox"
                  className="form-checkbox"
                  checked={isSelectedTest(test.TestId)}
                  key={test.TestId}
                  name={`${test.TestId.toString()}`}
                  id={`select-test-${test.TestId}`}
                  onChange={handleSelectTest}
                  value={test.TestId.toString()}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SubjectTestDetails;

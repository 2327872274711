import {
  get,
  ErrorAction as ApiErrorAction,
  NormalizedPayload,
  SuccessAction as ApiSuccessAction,
} from '@an/nova-frontend-rest-client';
import type { User } from '../types';
import { usersList } from '../../core';
import type { Dispatch, GetState } from '../../core/types';
import Config from '../../config';
import type { ReduxState } from '../../rootReducer';

const SEARCH_TEAM_USERS: 'searchTeamUsers' = 'searchTeamUsers';
const SEARCH_TEAM_USERS_ERROR: 'searchTeamUsers/error' = 'searchTeamUsers/error';
const SEARCH_TEAM_USERS_SUCCESS: 'searchTeamUsers/success' = 'searchTeamUsers/success';

export const Actions = {
  SEARCH_TEAM_USERS_ERROR,
  SEARCH_TEAM_USERS_SUCCESS,
};

type UserEntities = { users: { [string: string]: User } };
type NormalizedSearchTeamUsers = NormalizedPayload<void, UserEntities>;

export type SearchTeamUsersFetchErrorAction = ApiErrorAction<typeof SEARCH_TEAM_USERS_ERROR, Record<string, unknown>>;
export type SearchTeamUsersFetchSuccessAction = ApiSuccessAction<
  typeof SEARCH_TEAM_USERS_SUCCESS,
  Record<string, unknown>,
  NormalizedSearchTeamUsers
>;

const fetchUsers = () =>
  get<ReduxState, NormalizedSearchTeamUsers>(
    `${Config().apiBaseUrl}users/search-team`,
    SEARCH_TEAM_USERS,
    undefined,
    usersList
  );

const shouldFetchUsers = (userState: any) => !userState.users || Object.keys(userState.users).length === 0;

export const getUsers = () => (dispatch: Dispatch<any>, getState: GetState) => {
  if (shouldFetchUsers(getState().searchTeamUsers)) {
    return dispatch(fetchUsers());
  }
  return Promise.resolve();
};

import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import Selectors from '../../core/redux/selectors';
import PatientSearchResultCard from './PatientSearchResultCard';
import PaginationButtons from './PaginationButtons';
import { ErrorMessage, LoadingMessage } from '../../../core';
import NoPatientFound from './NoPatientFound';

import type { PatientSummary } from '../../types';
import type { PatientSearchReducerState } from '../redux/reducer';
import type { ErrorMessages } from '../../../core/types';
import type { ReduxState } from '../../../rootReducer';

type StateProps = {
  errorMessage: ErrorMessages;
  hasErrored: boolean;
  isFetching: boolean;
  isPatientSummariesInState: boolean;
  patients: PatientSearchReducerState;
  patientSummaries?: PatientSummary[];
};
type Props = PropsFromRedux & StateProps;

const mapStateToProps = (state: ReduxState): StateProps => ({
  errorMessage: Selectors.getErrorMessage(state),
  hasErrored: Selectors.hasFetchPatientErrored(state),
  isFetching: Selectors.isFetchingPatient(state),
  isPatientSummariesInState: Selectors.isPatientSummariesInState(state),
  patients: Selectors.getPatients(state),
  patientSummaries: Selectors.getPatientSummaries(state),
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type State = {
  pageNumber: number;
  resultsPerPage: number;
};

class PatientSummaryResultsCardsLoadingContainer extends PureComponent<Props, State> {
  static defaultProps = {
    patientSummaries: [],
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      pageNumber: 1,
      resultsPerPage: 4,
    };
  }

  render() {
    return this.renderPatientSummaries();
  }

  setPageNumber = (pageNumber: number) => {
    this.setState({ pageNumber });
  };

  getPaginationButtons = () => {
    const { hasErrored, patientSummaries } = this.props;
    const { resultsPerPage } = this.state;
    if (hasErrored || !patientSummaries) {
      return null;
    }

    return (
      <PaginationButtons
        setParentPageNumber={this.setPageNumber}
        numberOfResults={patientSummaries.length}
        resultsPerPage={resultsPerPage}
      />
    );
  };

  listPatientSummaryResults = () => {
    const { errorMessage, hasErrored, patients, patientSummaries } = this.props;
    if (hasErrored) {
      return (
        <div style={{ marginTop: '10px' }}>
          <ErrorMessage errorMessages={errorMessage} />
        </div>
      );
    }

    if (!patientSummaries || patientSummaries.length === 0) {
      return <NoPatientFound />;
    }

    return patientSummaries.map<React.ReactNode>((patientSummary: PatientSummary, index: number) => {
      if (this.indexShouldAppearOnPage(index)) {
        // @ts-expect-error TODO EM-1787: allow typescript to account for combination of dynamic and static keys in PatientSearchReducerState
        const patient = patients[patientSummary.Id];
        return patient ? <PatientSearchResultCard patient={patient.patientInfo} /> : null;
      }
      return null;
    });
  };

  indexShouldAppearOnPage = (index: number) => {
    const { pageNumber, resultsPerPage } = this.state;
    return index >= (pageNumber - 1) * resultsPerPage && index < pageNumber * resultsPerPage;
  };

  renderPatientSummaries = () => {
    const { isFetching, isPatientSummariesInState } = this.props;
    if (!isPatientSummariesInState) {
      return null;
    }

    return (
      <LoadingMessage isLoading={isFetching}>
        {this.listPatientSummaryResults()}
        {this.getPaginationButtons()}
      </LoadingMessage>
    );
  };
}

export default connector(PatientSummaryResultsCardsLoadingContainer);

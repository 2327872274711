import React, { PureComponent } from 'react';
import { styles as commonStyles } from '../../../style';

type Props = {
  numberOfResults: number;
  resultsPerPage: number;
  setParentPageNumber: (pageNumber: number) => void;
};
type State = {
  pageNumber: number;
};

const styles = {
  ...commonStyles,
  leftPaginationButton: {
    paddingLeft: '6px',
    width: '40px',
  },
  rightPaginationButton: {
    paddingLeft: '6px',
    width: '40px',
  },
};

class PaginationButtons extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      pageNumber: 1,
    };
  }

  componentDidMount() {
    const { setParentPageNumber } = this.props;
    setParentPageNumber(1);
  }

  render() {
    return <div>{this.getForwardsAndBackButtons()}</div>;
  }

  getForwardsAndBackButtons = () => {
    const { numberOfResults, resultsPerPage } = this.props;
    const { pageNumber } = this.state;

    if (numberOfResults <= resultsPerPage) {
      return null;
    }
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h4>{this.calculatePageNumberString()}</h4>
        <div style={{ display: 'flex' }}>
          <button
            aria-label="Previous"
            className="btn btn--inline btn--arrow-left"
            disabled={pageNumber <= 1}
            style={styles.leftPaginationButton}
            onClick={() => {
              this.decrementPageNumber();
            }}
            type="button"
          />
          <button
            aria-label="Next"
            className="btn btn--inline btn--arrow-right"
            disabled={pageNumber >= this.numberOfPages()}
            style={styles.rightPaginationButton}
            onClick={() => {
              this.incrementPageNumber();
            }}
            type="button"
          />
        </div>
      </div>
    );
  };

  decrementPageNumber = () => {
    const { setParentPageNumber } = this.props;
    const { pageNumber } = this.state;
    const newPageNumber = pageNumber - 1;
    setParentPageNumber(newPageNumber);
    this.setState({ pageNumber: newPageNumber });
  };

  incrementPageNumber = () => {
    const { setParentPageNumber } = this.props;
    const { pageNumber } = this.state;
    const newPageNumber = pageNumber + 1;
    setParentPageNumber(newPageNumber);
    this.setState({ pageNumber: newPageNumber });
  };

  calculatePageNumberString = () => {
    const { pageNumber } = this.state;
    return `Search results page ${pageNumber} of ${this.numberOfPages()}`;
  };

  numberOfPages = () => {
    const { numberOfResults, resultsPerPage } = this.props;
    return Math.ceil(numberOfResults / resultsPerPage);
  };
}

export default PaginationButtons;

import React from 'react';
import TextMessagesTable from './TextMessages/TextMessagesTable';
import { ExternalInvestigationTextMessage } from '../../../../../externalInvestigations/types';
import { formatRequestContext } from '../../../../../core/helpers/markupHelpers';
import { HlaSummaryData } from './HlaSummaryPopup';

type Props = {
  textMessages: ExternalInvestigationTextMessage[];
  onCancel: (
    event: React.SyntheticEvent<HTMLButtonElement> & {
      currentTarget: HTMLButtonElement;
    }
  ) => void;
  hlaSummaryData: HlaSummaryData;
};

const TextMessagesPopUp = ({ onCancel, textMessages, hlaSummaryData }: Props) => (
  <>
    <h1>Text Messages</h1>
    {formatRequestContext(
      hlaSummaryData.patientId,
      hlaSummaryData.registryName,
      hlaSummaryData.donorId,
      hlaSummaryData.grid
    )}
    <TextMessagesTable textMessages={textMessages} />
    <div className="btn-actions">
      <button className="btn btn--secondary btn--inline" type="button" onClick={onCancel}>
        Close
      </button>
    </div>
  </>
);

export default TextMessagesPopUp;

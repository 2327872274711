import { Actions } from '../actionNames';
import {
  ExternalInvestigationsCreateExtendedTypingRequestErrorAction,
  ExternalInvestigationsCreateExtendedTypingRequestRequestAction,
  ExternalInvestigationsCreateExtendedTypingRequestSuccessAction,
} from '../actions';

type UsersAction =
  | ExternalInvestigationsCreateExtendedTypingRequestSuccessAction
  | ExternalInvestigationsCreateExtendedTypingRequestErrorAction
  | ExternalInvestigationsCreateExtendedTypingRequestRequestAction;

export type ExtendedTypingRequestInitiationReducerState = {
  isCreatingRequest: boolean;
  errorMessage?: string;
};

const initialState: ExtendedTypingRequestInitiationReducerState = {
  isCreatingRequest: false,
  errorMessage: undefined,
};

export default (
  state: ExtendedTypingRequestInitiationReducerState = initialState,
  action: UsersAction
): ExtendedTypingRequestInitiationReducerState => {
  switch (action.type) {
    case Actions.EXTERNAL_INVESTIGATIONS_CREATE_EXTENDED_TYPING_REQUEST_REQUEST:
      return {
        ...state,
        isCreatingRequest: true,
      };
    case Actions.EXTERNAL_INVESTIGATIONS_CREATE_EXTENDED_TYPING_REQUEST_SUCCESS:
      return initialState;
    case Actions.EXTERNAL_INVESTIGATIONS_CREATE_EXTENDED_TYPING_REQUEST_ERROR:
      return {
        ...state,
        isCreatingRequest: false,
        errorMessage: action.payload.params.errorMessage,
      };
    default:
      return state;
  }
};

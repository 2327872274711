import {
  ErrorAction as ApiErrorAction,
  get,
  post,
  RequestAction as ApiRequestAction,
  SuccessAction as ApiSuccessAction,
} from '@an/nova-frontend-rest-client';
import log from 'loglevel';
import UrlAssembler from 'url-assembler';
import Config from '../../config';
import { downloadFile, urlBuilder, delay } from '../../core';
import type { Dispatch } from '../../core/types';
import { convertVerificationTypingResultToApi } from '../../patient/patientDashboard/helpers/apiDataConverter';
import { ApiVerificationTypingResult, VerificationTypingResultToSave } from '../../patient/patientDashboard/types';
import type { ReduxState } from '../../rootReducer';
import type { ApiExternalInvestigation } from '../types/api';
import type {
  ExternalInvestigationReportCreationModel,
  ExternalInvestigationsCancellationRequest,
} from '../types/index';

const EXTERNAL_INVESTIGATIONS: 'externalInvestigations' = 'externalInvestigations';
const EXTERNAL_INVESTIGATIONS_ERROR: 'externalInvestigations/error' = 'externalInvestigations/error';
const EXTERNAL_INVESTIGATIONS_REQUEST: 'externalInvestigations/request' = 'externalInvestigations/request';
const EXTERNAL_INVESTIGATIONS_SUCCESS: 'externalInvestigations/success' = 'externalInvestigations/success';

const VERIFICATION_TYPING_RESULT: 'verificationTypingResult' = 'verificationTypingResult';
const VERIFICATION_TYPING_RESULT_ERROR: 'verificationTypingResult/error' = 'verificationTypingResult/error';
const VERIFICATION_TYPING_RESULT_REQUEST: 'verificationTypingResult/request' = 'verificationTypingResult/request';
const VERIFICATION_TYPING_RESULT_SUCCESS: 'verificationTypingResult/success' = 'verificationTypingResult/success';

const SAVE_VERIFICATION_TYPING_RESULT: 'saveVerificationTypingResult' = 'saveVerificationTypingResult';
const SAVE_VERIFICATION_TYPING_RESULT_ERROR: 'saveVerificationTypingResult/error' =
  'saveVerificationTypingResult/error';
const SAVE_VERIFICATION_TYPING_RESULT_SUCCESS: 'saveVerificationTypingResult/success' =
  'saveVerificationTypingResult/success';

const SEND_VERIFICATION_TYPING_RESULT: 'sendVerificationTypingResult' = 'sendVerificationTypingResult';
const SEND_VERIFICATION_TYPING_RESULT_ERROR: 'sendVerificationTypingResult/error' =
  'sendVerificationTypingResult/error';
const SEND_VERIFICATION_TYPING_RESULT_SUCCESS: 'sendVerificationTypingResult/success' =
  'sendVerificationTypingResult/success';

const CANCEL_EXTERNAL_INVESTIGATIONS: 'externalInvestigations/cancel' = 'externalInvestigations/cancel';
const CANCEL_EXTERNAL_INVESTIGATIONS_REQUEST: 'externalInvestigations/cancel/request' =
  'externalInvestigations/cancel/request';
const CANCEL_EXTERNAL_INVESTIGATIONS_SUCCESS: 'externalInvestigations/cancel/success' =
  'externalInvestigations/cancel/success';
const CANCEL_EXTERNAL_INVESTIGATIONS_ERROR: 'externalInvestigations/cancel/error' =
  'externalInvestigations/cancel/error';

const GENERATE_VIROLOGY_REPORT: 'externalInvestigations/reports/virology/generate' =
  'externalInvestigations/reports/virology/generate';
const GENERATE_VIROLOGY_REPORT_REQUEST: 'externalInvestigations/reports/virology/generate/request' =
  'externalInvestigations/reports/virology/generate/request';
const GENERATE_VIROLOGY_REPORT_ERROR: 'externalInvestigations/reports/virology/generate/error' =
  'externalInvestigations/reports/virology/generate/error';
const GENERATE_VIROLOGY_REPORT_SUCCESS: 'externalInvestigations/reports/virology/generate/success' =
  'externalInvestigations/reports/virology/generate/success';

const GENERATE_HLA_REPORT: 'externalInvestigations/reports/hla/generate' =
  'externalInvestigations/reports/hla/generate';
const GENERATE_HLA_REPORT_REQUEST: 'externalInvestigations/reports/hla/generate/request' =
  'externalInvestigations/reports/hla/generate/request';
const GENERATE_HLA_REPORT_ERROR: 'externalInvestigations/reports/hla/generate/error' =
  'externalInvestigations/reports/hla/generate/error';
const GENERATE_HLA_REPORT_SUCCESS: 'externalInvestigations/reports/hla/generate/success' =
  'externalInvestigations/reports/hla/generate/success';

const EXTERNAL_INVESTIGATION_REPORT_DOWNLOAD_PDF: 'externalInvestigations/reports/pdf/download' =
  'externalInvestigations/reports/pdf/download';
const EXTERNAL_INVESTIGATION_REPORT_DOWNLOAD_PDF_REQUEST: 'externalInvestigations/reports/pdf/download/request' =
  'externalInvestigations/reports/pdf/download/request';
const EXTERNAL_INVESTIGATION_REPORT_DOWNLOAD_PDF_ERROR: 'externalInvestigations/reports/pdf/download/error' =
  'externalInvestigations/reports/pdf/download/error';
const EXTERNAL_INVESTIGATION_REPORT_DOWNLOAD_PDF_SUCCESS: 'externalInvestigations/reports/pdf/download/success' =
  'externalInvestigations/reports/pdf/download/success';

const EXTERNAL_INVESTIGATION_REPORT_DOWNLOAD_PDF_FINAL_ATTEMPT: 'externalInvestigations/reports/pdf/download/finalAttempt' =
  'externalInvestigations/reports/pdf/download/finalAttempt';
const EXTERNAL_INVESTIGATION_REPORT_DOWNLOAD_PDF_FINAL_ATTEMPT_ERROR: 'externalInvestigations/reports/pdf/download/finalAttempt/error' =
  'externalInvestigations/reports/pdf/download/finalAttempt/error';
const EXTERNAL_INVESTIGATION_REPORT_DOWNLOAD_PDF_FINAL_ATTEMPT_SUCCESS: 'externalInvestigations/reports/pdf/download/finalAttempt/success' =
  'externalInvestigations/reports/pdf/download/finalAttempt/success';

export const Actions = {
  EXTERNAL_INVESTIGATIONS_ERROR,
  EXTERNAL_INVESTIGATIONS_REQUEST,
  EXTERNAL_INVESTIGATIONS_SUCCESS,
  VERIFICATION_TYPING_RESULT_ERROR,
  VERIFICATION_TYPING_RESULT_REQUEST,
  VERIFICATION_TYPING_RESULT_SUCCESS,
  SAVE_VERIFICATION_TYPING_RESULT_ERROR,
  SAVE_VERIFICATION_TYPING_RESULT_SUCCESS,
  SEND_VERIFICATION_TYPING_RESULT_ERROR,
  SEND_VERIFICATION_TYPING_RESULT_SUCCESS,
  CANCEL_EXTERNAL_INVESTIGATIONS,
  CANCEL_EXTERNAL_INVESTIGATIONS_REQUEST,
  CANCEL_EXTERNAL_INVESTIGATIONS_SUCCESS,
  CANCEL_EXTERNAL_INVESTIGATIONS_ERROR,
  GENERATE_VIROLOGY_REPORT,
  GENERATE_VIROLOGY_REPORT_REQUEST,
  GENERATE_VIROLOGY_REPORT_SUCCESS,
  GENERATE_VIROLOGY_REPORT_ERROR,
  GENERATE_HLA_REPORT,
  GENERATE_HLA_REPORT_REQUEST,
  GENERATE_HLA_REPORT_SUCCESS,
  GENERATE_HLA_REPORT_ERROR,
  EXTERNAL_INVESTIGATION_REPORT_DOWNLOAD_PDF,
  EXTERNAL_INVESTIGATION_REPORT_DOWNLOAD_PDF_REQUEST,
  EXTERNAL_INVESTIGATION_REPORT_DOWNLOAD_PDF_SUCCESS,
  EXTERNAL_INVESTIGATION_REPORT_DOWNLOAD_PDF_ERROR,
  EXTERNAL_INVESTIGATION_REPORT_DOWNLOAD_PDF_FINAL_ATTEMPT,
  EXTERNAL_INVESTIGATION_REPORT_DOWNLOAD_PDF_FINAL_ATTEMPT_ERROR,
  EXTERNAL_INVESTIGATION_REPORT_DOWNLOAD_PDF_FINAL_ATTEMPT_SUCCESS,
};

type ErrorMessageParam = { errorMessage: string };
type PatientIdParam = { patientId: string };
type ExternalInvestigationIdParam = { externalInvestigationId: number; externalInvestigationType: string };
type VerificationTypingResultParam = { investigationId: number };
type SuccessMessageParam = { successMessage: string };
type ExternalInvestigationReportGenerationResultParam = { ReportId: number; PdfFileName: string };

export type ExternalInvestigationsErrorAction = ApiErrorAction<typeof EXTERNAL_INVESTIGATIONS_ERROR, PatientIdParam>;
export type ExternalInvestigationsRequestAction = ApiRequestAction<
  typeof EXTERNAL_INVESTIGATIONS_REQUEST,
  PatientIdParam
>;
export type ExternalInvestigationsSuccessAction = ApiSuccessAction<
  typeof EXTERNAL_INVESTIGATIONS_SUCCESS,
  PatientIdParam,
  ApiExternalInvestigation
>;

export type VerificationTypingResultErrorAction = ApiErrorAction<
  typeof VERIFICATION_TYPING_RESULT_ERROR,
  VerificationTypingResultParam
>;
export type VerificationTypingResultRequestAction = ApiRequestAction<
  typeof VERIFICATION_TYPING_RESULT_REQUEST,
  VerificationTypingResultParam
>;
export type VerificationTypingResultSuccessAction = ApiSuccessAction<
  typeof VERIFICATION_TYPING_RESULT_SUCCESS,
  VerificationTypingResultParam,
  ApiVerificationTypingResult
>;

export type SaveVerificationTypingResultErrorAction = ApiErrorAction<
  typeof SAVE_VERIFICATION_TYPING_RESULT_ERROR,
  ErrorMessageParam & SuccessMessageParam,
  Record<string, unknown>
>;
export type SaveVerificationTypingResultSuccessAction = ApiSuccessAction<
  typeof SAVE_VERIFICATION_TYPING_RESULT_SUCCESS,
  ErrorMessageParam & SuccessMessageParam,
  string & SuccessMessageParam
>;

export type SendVerificationTypingResultErrorAction = ApiErrorAction<
  typeof SEND_VERIFICATION_TYPING_RESULT_ERROR,
  ErrorMessageParam & SuccessMessageParam,
  Record<string, unknown>
>;

export type SendVerificationTypingResultSuccessAction = ApiSuccessAction<
  typeof SEND_VERIFICATION_TYPING_RESULT_SUCCESS,
  ErrorMessageParam & SuccessMessageParam,
  string & SuccessMessageParam
>;

export type CancelExternalInvestigationsErrorAction = ApiErrorAction<
  typeof CANCEL_EXTERNAL_INVESTIGATIONS_ERROR,
  PatientIdParam & ErrorMessageParam,
  Record<string, unknown>
>;
export type CancelExternalInvestigationsRequestAction = ApiRequestAction<
  typeof CANCEL_EXTERNAL_INVESTIGATIONS_REQUEST,
  PatientIdParam
>;
export type CancelExternalInvestigationsSuccessAction = ApiSuccessAction<
  typeof CANCEL_EXTERNAL_INVESTIGATIONS_SUCCESS,
  PatientIdParam & SuccessMessageParam,
  SuccessMessageParam
>;

export type GenerateVirologyReportErrorAction = ApiErrorAction<
  typeof GENERATE_VIROLOGY_REPORT_ERROR,
  ExternalInvestigationIdParam & ErrorMessageParam,
  Record<string, unknown>
>;
export type GenerateVirologyReportRequestAction = ApiRequestAction<
  typeof GENERATE_VIROLOGY_REPORT_REQUEST,
  ExternalInvestigationIdParam
>;
export type GenerateVirologyReportSuccessAction = ApiSuccessAction<
  typeof GENERATE_VIROLOGY_REPORT_SUCCESS,
  ExternalInvestigationIdParam & SuccessMessageParam,
  SuccessMessageParam & ExternalInvestigationReportGenerationResultParam
>;

export type GenerateHLAReportErrorAction = ApiErrorAction<
  typeof GENERATE_HLA_REPORT_ERROR,
  ExternalInvestigationIdParam & ErrorMessageParam,
  Record<string, unknown>
>;
export type GenerateHLAReportRequestAction = ApiRequestAction<
  typeof GENERATE_HLA_REPORT_REQUEST,
  ExternalInvestigationIdParam
>;
export type GenerateHLAReportSuccessAction = ApiSuccessAction<
  typeof GENERATE_HLA_REPORT_SUCCESS,
  ExternalInvestigationIdParam & SuccessMessageParam,
  SuccessMessageParam & ExternalInvestigationReportGenerationResultParam
>;

export type ExternalInvestigationReportDownloadPdfErrorAction = ApiErrorAction<
  typeof EXTERNAL_INVESTIGATION_REPORT_DOWNLOAD_PDF_ERROR,
  ExternalInvestigationIdParam & ErrorMessageParam,
  Record<string, unknown>
>;
export type ExternalInvestigationReportDownloadPdfRequestAction = ApiRequestAction<
  typeof EXTERNAL_INVESTIGATION_REPORT_DOWNLOAD_PDF_REQUEST,
  ExternalInvestigationIdParam
>;
export type ExternalInvestigationReportDownloadPdfSuccessAction = ApiSuccessAction<
  typeof EXTERNAL_INVESTIGATION_REPORT_DOWNLOAD_PDF_SUCCESS,
  ExternalInvestigationIdParam & SuccessMessageParam,
  SuccessMessageParam
>;

export type ExternalInvestigationReportDownloadPdfFinalAttemptErrorAction = ApiErrorAction<
  typeof EXTERNAL_INVESTIGATION_REPORT_DOWNLOAD_PDF_FINAL_ATTEMPT_ERROR,
  ExternalInvestigationIdParam & ErrorMessageParam,
  Record<string, unknown>
>;
export type ExternalInvestigationReportDownloadPdfFinalAttemptSuccessAction = ApiSuccessAction<
  typeof EXTERNAL_INVESTIGATION_REPORT_DOWNLOAD_PDF_FINAL_ATTEMPT_SUCCESS,
  ExternalInvestigationIdParam & SuccessMessageParam,
  SuccessMessageParam
>;

export type ExternalInvestigationsAction =
  | ExternalInvestigationsErrorAction
  | ExternalInvestigationsRequestAction
  | ExternalInvestigationsSuccessAction
  | VerificationTypingResultErrorAction
  | VerificationTypingResultRequestAction
  | VerificationTypingResultSuccessAction
  | SaveVerificationTypingResultErrorAction
  | SaveVerificationTypingResultSuccessAction
  | SendVerificationTypingResultErrorAction
  | SendVerificationTypingResultSuccessAction
  | CancelExternalInvestigationsErrorAction
  | CancelExternalInvestigationsRequestAction
  | CancelExternalInvestigationsSuccessAction
  | GenerateVirologyReportErrorAction
  | GenerateVirologyReportRequestAction
  | GenerateVirologyReportSuccessAction
  | GenerateHLAReportErrorAction
  | GenerateHLAReportRequestAction
  | GenerateHLAReportSuccessAction
  | ExternalInvestigationReportDownloadPdfErrorAction
  | ExternalInvestigationReportDownloadPdfRequestAction
  | ExternalInvestigationReportDownloadPdfSuccessAction;

export const getExternalInvestigations = (patientId: string) =>
  get<ReduxState, ApiExternalInvestigation>(
    `${Config().apiBaseUrl}external-investigations/patient/${patientId}`,
    EXTERNAL_INVESTIGATIONS,
    { patientId }
  );

export const getVerificationTypingResult = (investigationId: number) =>
  get<ReduxState, ApiVerificationTypingResult>(
    `${Config().apiBaseUrl}external-investigations/verification-typing/${investigationId}/result`,
    VERIFICATION_TYPING_RESULT,
    { investigationId }
  );

export const saveDonorVerificationTypingResult =
  (donorDetails: VerificationTypingResultToSave, investigationId: number) =>
  async (dispatch: Dispatch<any>): Promise<void> => {
    const verificationTypingResult = convertVerificationTypingResultToApi(donorDetails);
    const url = UrlAssembler(Config().apiBaseUrl)
      .template(`external-investigations/verification-typing/${investigationId}/result`)
      .toString();
    const save = await dispatch(
      post(
        url,
        SAVE_VERIFICATION_TYPING_RESULT,
        {
          successMessage: 'Verification Typing Results Data Saved',
          errorMessage: 'Verification Typing Results Save Failed',
        },
        verificationTypingResult
      )
    );
    if (save.response.ok) {
      dispatch(getVerificationTypingResult(investigationId));
    }
  };

export const sendVerificationTypingResult =
  (isDonorReserved: boolean, investigationId: number) =>
  async (dispatch: Dispatch<any>): Promise<void> => {
    const convertedIsDonorReservedToApi = { IsDonorReserved: isDonorReserved };
    const url = UrlAssembler(Config().apiBaseUrl)
      .template(`external-investigations/verification-typing/${investigationId}/result/send`)
      .toString();
    const response = await dispatch(
      post(
        url,
        SEND_VERIFICATION_TYPING_RESULT,
        {
          successMessage: 'Verification Typing Result Sent',
          errorMessage: 'Verification Typing Result Failed',
        },
        convertedIsDonorReservedToApi
      )
    );
    if (response.response.ok) {
      dispatch(getVerificationTypingResult(investigationId));
    }
  };

export const cancelExternalInvestigations =
  (patientId: string, cancellationRequest: ExternalInvestigationsCancellationRequest) =>
  async (dispatch: Dispatch<any>) => {
    const url = `${Config().apiBaseUrl}external-investigations/cancel`;
    const result = await dispatch(
      post<ReduxState, ApiRequestAction<string>>(
        url,
        CANCEL_EXTERNAL_INVESTIGATIONS,
        {
          successMessage: 'Cancelled test requests',
          errorMessage: `Failed to cancel test requests for patient ${patientId}`,
        },
        cancellationRequest
      )
    );
    if (result.response.ok) {
      dispatch(getExternalInvestigations(patientId));
    }
  };

export const downloadPdfReport =
  (filename: string, externalInvestigationId: number, externalInvestigationType: string) =>
  async (dispatch: Dispatch<any>) => {
    // Firstly we check the status of the PDF generation process (is there a PDF ot not)
    const pdfStatusUrl = urlBuilder('reports/pdf/:filename/status', { filename });

    let reportPdf;
    const downloadAttempts = Config().reportDownloadAttempts;
    // eslint-disable-next-line no-plusplus
    for (let attempt = 1; attempt <= downloadAttempts; attempt++) {
      // eslint-disable-next-line no-await-in-loop
      reportPdf = await dispatch(
        get(
          pdfStatusUrl,
          attempt === downloadAttempts
            ? EXTERNAL_INVESTIGATION_REPORT_DOWNLOAD_PDF_FINAL_ATTEMPT
            : EXTERNAL_INVESTIGATION_REPORT_DOWNLOAD_PDF,
          {
            externalInvestigationId,
            externalInvestigationType,
            filename,
            successMessage: 'Report PDF has been downloaded',
            errorMessage: 'An error has occurred when downloading report PDF',
          }
        )
      );

      if (reportPdf.response.ok) {
        break;
      }

      // eslint-disable-next-line no-await-in-loop
      await delay(Config().reportDownloadWaitBetweenAttemptsSec * 1000);
    }

    if (!reportPdf.response.ok) {
      return log.error('Error while fetching report PDF');
    }

    const pdfDownloadUrl = urlBuilder('reports/pdf/:filename', { filename });
    downloadFile(pdfDownloadUrl, filename, 'pdf');

    return undefined;
  };

export const generateVirologyReport =
  (creationModel: ExternalInvestigationReportCreationModel) => async (dispatch: Dispatch<any>) => {
    const generation = await dispatch(
      post<ReduxState, string>(
        `${Config().apiBaseUrl}external-investigation-reports/virology-report`,
        GENERATE_VIROLOGY_REPORT,
        {
          externalInvestigationId: creationModel.externalInvestigationId,
          externalInvestigationType: creationModel.externalInvestigationType,
          successMessage: 'Report PDF has been generated. Downloading...',
          errorMessage: 'Report PDF has failed to generate',
        },
        creationModel
      )
    );

    if (generation.response.ok) {
      dispatch(
        downloadPdfReport(
          generation.response.body.PdfFileName,
          creationModel.externalInvestigationId,
          creationModel.externalInvestigationType
        )
      );
    }
  };

export const generateHLAReport =
  (creationModel: ExternalInvestigationReportCreationModel) => async (dispatch: Dispatch<any>) => {
    const generation = await dispatch(
      post<ReduxState, string>(
        `${Config().apiBaseUrl}external-investigation-reports/hla-report`,
        GENERATE_HLA_REPORT,
        {
          externalInvestigationId: creationModel.externalInvestigationId,
          externalInvestigationType: creationModel.externalInvestigationType,
          successMessage: 'Report PDF has been generated. Downloading...',
          errorMessage: 'Report PDF has failed to generate',
        },
        creationModel
      )
    );

    if (generation.response.ok) {
      dispatch(
        downloadPdfReport(
          generation.response.body.PdfFileName,
          creationModel.externalInvestigationId,
          creationModel.externalInvestigationType
        )
      );
    }
  };

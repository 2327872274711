import moment from 'moment';
import React, { CSSProperties } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { AutoSizer, Column, Table } from 'react-virtualized';
import { AnyAction, bindActionCreators, Dispatch as ReduxDispatch } from 'redux';
import { algorithmTypes, donorTypes, reportTypes } from '../../../../core';
import { isOddNumber } from '../../../../core/helpers/arrayHelper';
import type { DonorType } from '../../../../core/types';
import { SearchRequestSelectors } from '../../../../donorMatchSearchRequests';
import type { ResultTableRow, SavedResultSet } from '../../../../donorMatchSearchRequests/types';
import type { ReduxState } from '../../../../rootReducer';
import { CurrentReportSelectors } from '../../../core/redux/selectors';
import { updateCordReportSearchSummary, updateDonorReportSearchSummary } from '../../redux/actions';
import DonorSet from '../DonorSet';
import { setRowHeight } from './SetRowHeight';
import ViewResultsButton from './ViewResultsButton';
import { rebuildTooltip } from '../../../../core/helpers/rebuildTooltip';
import { convertToAlgorithmName } from '../../../../patient/patientDashboard/helpers/algorithmConverter';
import './internalReportDonorsTable.scss';
import { convertSearchTypeFromApiToNumeric } from '../../../../core/constants/searchTypes';

type DispatchProps = {
  updateReportSearchSummary: typeof updateDonorReportSearchSummary;
};
type OwnProps = {
  donorType: DonorType;
  mappedResultSets: ResultTableRow[];
  reportType: string;
};
type StateProps = {
  isReportReadOnly: boolean;
  reportId: string;
  savedResultSets: Record<string, SavedResultSet>;
  selectedResultSetIds: string[];
};

const mapStateToProps = (state: ReduxState, ownProps: OwnProps): StateProps => ({
  isReportReadOnly: CurrentReportSelectors.isReportReadOnly(state),
  reportId: CurrentReportSelectors.getCurrentReportId(state),
  savedResultSets: SearchRequestSelectors.getAllSavedResultSets(state, ownProps.donorType),
  selectedResultSetIds: CurrentReportSelectors.getSelectedResultSetIds(state, ownProps.donorType),
});

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>, ownProps: OwnProps): DispatchProps => ({
  updateReportSearchSummary: bindActionCreators(
    ownProps.donorType === donorTypes.adult.value ? updateDonorReportSearchSummary : updateCordReportSearchSummary,
    dispatch
  ),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & OwnProps;

const InternalReportDonorsTable = (props: Props) => {
  const { donorType, mappedResultSets, reportId, savedResultSets } = props;

  const isCord = () => donorType === donorTypes.cord.value;
  const filteredResultSets =
    donorType === donorTypes.adult.value
      ? mappedResultSets.filter((x) => x.donorType === donorType && x.algorithm !== algorithmTypes.external)
      : mappedResultSets.filter((x) => x.donorType === donorType);

  const isHlaAdjusted = (hlaAdjusted: boolean) => (hlaAdjusted ? 'Yes' : 'No');

  const donorCount = (resultSetId: string) => {
    const resultSet = filteredResultSets.find((x) => x.resultSetId === resultSetId);

    if (resultSet) {
      const { exactMatchCount, potentialMatchCount, mismatchCount, returnedSolarDonorCount } = resultSet;

      const minorMismatches: number =
        returnedSolarDonorCount && returnedSolarDonorCount.MinorMismatches
          ? parseInt(returnedSolarDonorCount.MinorMismatches, 10)
          : 0;
      const totalDonors: number = exactMatchCount + potentialMatchCount + mismatchCount + minorMismatches;
      // Will only be returned if Solar
      const minorMismatch = minorMismatches ? `Minor Mismatch: ${minorMismatches}` : '';
      return (
        <div>
          <div>Exact: {exactMatchCount}</div>
          <div>Potential: {potentialMatchCount}</div>
          <div>{minorMismatch}</div>
          <div>Mismatches: {mismatchCount}</div>
          <div>Total: {totalDonors}</div>
        </div>
      );
    }
    return null;
  };

  const renderNewLink = (resultSet: ResultTableRow) => {
    const typeString = isCord() ? 'cords' : 'donors';
    return resultSet ? (
      <div>
        No saved {typeString} found.{' '}
        <Link
          to={`/donorsearch/${resultSet.requestId}/results?resultSetId=${resultSet.resultSetId}&donorType=${donorType}&reportType=${reportTypes.internal}`}
          style={{ textDecoration: 'underline' }}
        >
          Select {typeString}
        </Link>
      </div>
    ) : null;
  };

  const toggleSelectedSets = (selectedSetId: string) => {
    const { reportType, updateReportSearchSummary } = props;
    return updateReportSearchSummary(selectedSetId, savedResultSets, reportType);
  };

  const isSetSelected = (id: string) => {
    const { selectedResultSetIds } = props;
    return selectedResultSetIds.includes(id);
  };

  const boldIfSetSelectedAndReportReadOnly = (id: string): CSSProperties => {
    const { isReportReadOnly } = props;
    if (isReportReadOnly && isSetSelected(id)) {
      return { fontWeight: 'bold' };
    }
    return { fontWeight: 'normal' };
  };
  const renderSet = (setId: string, resultSetId: string) => {
    const { isReportReadOnly } = props;
    const savedSet = savedResultSets[setId];

    const setCount = savedSet.selectedResults.length;

    const resultSet = mappedResultSets.find((set: ResultTableRow) => set.resultSetId === resultSetId);

    if (resultSet && savedSet.algorithmUsed === resultSet.algorithm) {
      return (
        <DonorSet
          resultSetId={resultSetId}
          set={savedSet}
          isSelected={isSetSelected(setId)}
          isReportReadOnly={isReportReadOnly}
          key={setId}
          reportId={reportId}
          toggle={toggleSelectedSets}
          setCount={setCount}
          typeString={donorType}
          donorType={donorType}
          reportType={reportTypes.internal}
        />
      );
    }
    return null;
  };

  const savedSets = (resultSetId: string) => {
    const resultSet = filteredResultSets.find((x: ResultTableRow) => x.resultSetId === resultSetId);
    if (resultSet) {
      const { algorithm } = resultSet;
      const sets = isCord() ? resultSet.savedCordSets : resultSet.savedDonorSets;
      const algorithmMatch = (setId: string) => savedResultSets[setId].algorithmUsed === algorithm;

      return sets.filter((setId) => algorithmMatch(setId)).length > 0
        ? sets.map((setId) => renderSet(setId, resultSetId))
        : renderNewLink(resultSet);
    }
    return null;
  };

  return (
    <div className="internal-report-donors-table-container">
      <ReactTooltip className="tooltip" effect="solid" delayHide={100} place="left" html />
      <AutoSizer onResize={rebuildTooltip}>
        {({ width, height }) => (
          <Table
            className="results-table"
            width={width}
            height={height}
            headerHeight={50}
            rowHeight={({ index }) => setRowHeight(filteredResultSets[index], savedResultSets, donorType)}
            headerClassName="headerClassName"
            rowCount={filteredResultSets.length}
            rowGetter={({ index }) => filteredResultSets[index]}
            rowClassName={({ index }) => (isOddNumber(index) ? 'odd' : 'even')}
            rowStyle={({ index }) => boldIfSetSelectedAndReportReadOnly(index.toString())}
            onScroll={rebuildTooltip}
          >
            <Column label="Set ID" dataKey="resultSetId" width={100} />
            <Column
              label="Search Type"
              dataKey="type"
              cellDataGetter={({ rowData }) => convertSearchTypeFromApiToNumeric(rowData.type)}
              width={200}
            />
            <Column
              label="Summary"
              dataKey="resultSetId"
              width={150}
              cellRenderer={({ cellData }) => donorCount(cellData)}
              className="column-wrap"
            />
            <Column
              label="Algorithm"
              dataKey="algorithm"
              width={150}
              cellRenderer={({ cellData }) => convertToAlgorithmName(cellData)}
            />
            <Column
              label="HLA Adjusted"
              dataKey="isHlaAdjusted"
              width={110}
              cellRenderer={({ cellData }) => isHlaAdjusted(cellData)}
            />
            <Column
              label="Date"
              dataKey="requestedDate"
              width={150}
              cellRenderer={({ cellData }) => moment(cellData).format('DD/MM/YYYY, HH:mm')}
              className="column-wrap"
            />
            <Column
              label="Saved Sets"
              dataKey="resultSetId"
              flexGrow={2}
              width={600}
              minWidth={450}
              cellRenderer={({ cellData }) => savedSets(cellData)}
              className="column-wrap saved-set-column"
            />
            <Column
              label="View Results"
              dataKey="resultSetId"
              width={150}
              maxWidth={450}
              flexGrow={1}
              // eslint-disable-next-line react/no-unstable-nested-components
              cellRenderer={({ rowData }) => (
                <ViewResultsButton
                  donorType={donorType}
                  reportId={reportId}
                  mappedResultSets={filteredResultSets}
                  resultSetId={rowData.resultSetId}
                  reportType={reportTypes.internal}
                />
              )}
            />
          </Table>
        )}
      </AutoSizer>
    </div>
  );
};

export default connector(InternalReportDonorsTable);

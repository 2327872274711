import { LOCATION_CHANGE, RouterState } from 'connected-react-router';
import {
  Actions,
  PatientCreatePatientConstantsErrorAction,
  PatientCreatePatientConstantsSuccessAction,
  PatientCreatePatientDetailsRequestAction,
  PatientCreatePatientDetailsErrorAction,
  PatientCreatePatientDetailsSuccessAction,
  PatientCreatePatientDuplicateErrorAction,
  PatientCreatePatientDuplicateSuccessAction,
  PatientGetExternalRegistriesSuccessAction,
  PatientGetHospitalsSuccessAction,
  PatientGetInstitutionAddressesSuccessAction,
  PatientGetLaboratoriesSuccessAction,
  PatientSetPatientGeneticsErrorAction,
  PatientUpdatePatientDetailsErrorAction,
  PatientUpdatePatientDetailsRequestAction,
  PatientUpdatePatientDetailsSuccessAction,
  PatientSetPatientGeneticsSuccessAction,
} from './actions';
import type { InstitutionAddress } from '../../../core/types';
import type { Institution } from '../../types';
import type { PatientCreationConstants } from '../types';
import type { ApiMatchingPatient } from '../types/api';
import { formatInstitutionAdresses } from '../helpers/formatAddresses';

type LocationChangeAction = { type: typeof LOCATION_CHANGE; payload: RouterState };

type UserActions =
  | PatientCreatePatientConstantsErrorAction
  | PatientCreatePatientConstantsSuccessAction
  | PatientCreatePatientDetailsRequestAction
  | PatientCreatePatientDetailsErrorAction
  | PatientCreatePatientDetailsSuccessAction
  | PatientCreatePatientDuplicateErrorAction
  | PatientCreatePatientDuplicateSuccessAction
  | PatientGetExternalRegistriesSuccessAction
  | PatientGetHospitalsSuccessAction
  | PatientGetInstitutionAddressesSuccessAction
  | PatientGetLaboratoriesSuccessAction
  | PatientSetPatientGeneticsErrorAction
  | PatientUpdatePatientDetailsErrorAction
  | PatientUpdatePatientDetailsRequestAction
  | PatientUpdatePatientDetailsSuccessAction
  | PatientSetPatientGeneticsSuccessAction
  | LocationChangeAction;

export type PatientCreationReducerState = {
  errorMessage?: string;
  existingMatchingPatients?: ApiMatchingPatient[];
  externalRegistries: Institution[];
  hasErrored?: boolean;
  hospitals: Institution[];
  institutionAddresses: InstitutionAddress[];
  isSaving: boolean;
  laboratories: Institution[];
  newPatientId?: string;
  patientDetailsCompleted: boolean;
  patientGeneticsCompleted: boolean;
  patientCreationConstants: PatientCreationConstants;
};

const initialState: PatientCreationReducerState = {
  isSaving: false,
  patientDetailsCompleted: false,
  patientGeneticsCompleted: false,
  patientCreationConstants: {
    genderTypeOptions: [],
    ethnicGroupTypeOptions: [],
    patientTypeOptions: [],
    cmvAntibodyTypeOptions: [],
    rhdTypeOptions: [],
    bloodGroupTypeOptions: [],
    patientStatusTypeOptions: [],
    diagnosisTypeOptions: [],
    searchClosureReasonOptions: [],
  },
  hospitals: [],
  laboratories: [],
  externalRegistries: [],
  institutionAddresses: [],
};

export default (
  state: PatientCreationReducerState = initialState,
  action: UserActions
): PatientCreationReducerState => {
  switch (action.type) {
    case Actions.PATIENT_CREATE_PATIENT_DETAILS_REQUEST:
      return {
        ...state,
        isSaving: true,
      };
    case Actions.PATIENT_CREATE_PATIENT_DETAILS_SUCCESS:
      return {
        ...state,
        newPatientId: action.payload.data,
        isSaving: false,
        patientDetailsCompleted: true,
      };
    case Actions.PATIENT_CREATE_PATIENT_DETAILS_ERROR:
      return {
        ...state,
        isSaving: false,
        patientDetailsCompleted: false,
        hasErrored: true,
        errorMessage: action.payload.params.errorMessage,
      };
    case Actions.PATIENT_CREATE_PATIENT_DUPLICATE_SUCCESS:
      return {
        ...state,
        existingMatchingPatients: action.payload.data,
      };
    case Actions.PATIENT_CREATE_PATIENT_DUPLICATE_ERROR:
      return {
        ...state,
        isSaving: false,
        hasErrored: true,
        errorMessage: action.payload.params.errorMessage,
      };
    case Actions.PATIENT_UPDATE_PATIENT_DETAILS_REQUEST:
      return {
        ...state,
        isSaving: true,
      };
    case Actions.PATIENT_UPDATE_PATIENT_DETAILS_SUCCESS:
      return {
        ...state,
        isSaving: false,
      };
    case Actions.PATIENT_UPDATE_PATIENT_DETAILS_ERROR:
      return {
        ...state,
        isSaving: false,
        hasErrored: true,
        errorMessage: action.payload.params.errorMessage,
      };
    case Actions.PATIENT_SET_PATIENT_GENETICS_SUCCESS:
      return {
        ...state,
        isSaving: false,
        patientGeneticsCompleted: true,
      };

    case Actions.PATIENT_SET_PATIENT_GENETICS_ERROR:
      return {
        ...state,
        isSaving: false,
        patientGeneticsCompleted: false,
        hasErrored: true,
        errorMessage: action.payload.params.errorMessage,
      };
    case Actions.PATIENT_CREATE_PATIENT_CONSTANTS_SUCCESS:
      return {
        ...state,
        patientCreationConstants: {
          genderTypeOptions: action.payload.data.GenderType,
          ethnicGroupTypeOptions: action.payload.data.EthnicGroupType,
          patientTypeOptions: action.payload.data.PatientType,
          cmvAntibodyTypeOptions: action.payload.data.CmvAntibodyType,
          rhdTypeOptions: action.payload.data.RhdType,
          bloodGroupTypeOptions: action.payload.data.BloodGroupType,
          patientStatusTypeOptions: action.payload.data.PatientStatusType,
          diagnosisTypeOptions: action.payload.data.DiagnosisType,
          searchClosureReasonOptions: action.payload.data.SearchClosureReason,
        },
      };
    case Actions.PATIENT_CREATE_PATIENT_CONSTANTS_ERROR:
      return {
        ...state,
        patientCreationConstants: {
          ...state.patientCreationConstants,
          hasErrored: true,
          message: action.payload.params.errorMessage,
        },
      };
    case Actions.PATIENT_GET_HOSPITALS_SUCCESS:
      return {
        ...state,
        hospitals: action.payload.data,
      };
    case Actions.PATIENT_GET_LABORATORIES_SUCCESS:
      return {
        ...state,
        laboratories: action.payload.data,
      };
    case Actions.PATIENT_GET_EXTERNAL_REGISTRIES_SUCCESS:
      return {
        ...state,
        externalRegistries: action.payload.data,
      };
    case Actions.PATIENT_GET_INSTITUTION_ADDRESSES_SUCCESS:
      return {
        ...state,
        institutionAddresses: formatInstitutionAdresses(action.payload.data),
      };
    case LOCATION_CHANGE:
      return initialState;
    default:
      return state;
  }
};

import React from 'react';
import { useSelector } from 'react-redux';
import SubjectTestDetails from '../SubjectTestDetails';

import { getDispatchMethodDisplayString } from '../../../constants/dispatchMethods';
import Subject from '../../../redux/selectors';
import type { SubjectInvestigation } from '../../../types';
import { ReduxState } from '../../../../rootReducer';
import { formatDate } from '../../../../core/helpers/dateHelpers';

type Props = {
  investigation: SubjectInvestigation;
};

const ExistingInvestigationDetails = ({ investigation }: Props) => {
  const originalSample = useSelector((state: ReduxState) => Subject.getOriginalSample(state));
  const isLoadingOriginalSample = useSelector((state: ReduxState) => Subject.isLoadingOriginalSample(state));
  const { DispatchMethod, Urgent } = originalSample || {};
  const { KitRequestedDate, KitSentDate, Name, RunCode, SampleId, SampleReceivedDate, Tests } = investigation;

  return (
    <>
      <h3 data-testid="investigation-details-header--existing" style={{ marginTop: '3rem' }}>
        Investigation Details
      </h3>
      {isLoadingOriginalSample ? (
        <div className="loader exsmall">&nbsp;</div>
      ) : (
        <>
          <h4>
            <span>Category </span>
            {`${RunCode} - ${Name}`}
          </h4>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline', gap: '40px' }}>
            <div style={{ width: '50%' }}>
              <SubjectTestDetails tests={Tests} showStatus />
            </div>
            <table className="table table--left-header inner-table" style={{ width: '50%' }}>
              <tbody>
                <tr>
                  <th>Sample ID</th>
                  <td data-testid="sample-id">{SampleId}</td>
                </tr>
                <tr>
                  <th>Sample Type</th>
                  <td>{DispatchMethod && getDispatchMethodDisplayString(DispatchMethod)}</td>
                </tr>
                <tr>
                  <th>Date Kit Sent</th>
                  <td data-testid="date-kit-sent">{formatDate(KitSentDate)}</td>
                </tr>
                <tr>
                  <th>Date Kit Requested</th>
                  <td data-testid="date-kit-requested">{formatDate(KitRequestedDate)}</td>
                </tr>
                <tr>
                  <th>Sample Received</th>
                  <td data-testid="date-sample-received">{formatDate(SampleReceivedDate)}</td>
                </tr>
                <tr>
                  <th>Urgent?</th>
                  <td className="urgent-sample">
                    <input
                      className="form-checkbox--small"
                      type="checkbox"
                      id="checkbox"
                      checked={Urgent}
                      onChange={() => {}}
                      disabled
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
};

export default ExistingInvestigationDetails;

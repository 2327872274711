import { combineReducers } from 'redux';
// eslint-disable-next-line import/no-cycle
import emdisSearchRequestInitiation, {
  EmdisSearchRequestInitiationReducerState,
} from '../../patientDashboard/redux/emdisReducer';
// eslint-disable-next-line import/no-cycle
import patients, { PatientSearchReducerState } from '../../patientSearch/redux/reducer';
// eslint-disable-next-line import/no-cycle
import searchRequestInitiation, { PatientDashboardReducerState } from '../../patientDashboard/redux/reducer';
// eslint-disable-next-line import/no-cycle
import patientCreation, { PatientCreationReducerState } from '../../patientCreation/redux/reducer';
import patientStatus, {
  ChangePatientStatusReducerState,
} from '../../patientDashboard/redux/changePatientStatusReducer';

export type SearchPatientsReducerState = {
  emdisSearchRequestInitiation: EmdisSearchRequestInitiationReducerState;
  patientCreation: PatientCreationReducerState;
  patients: PatientSearchReducerState;
  searchRequestInitiation: PatientDashboardReducerState;
  patientStatus: ChangePatientStatusReducerState;
};

const searchPatients = combineReducers<SearchPatientsReducerState>({
  patients,
  emdisSearchRequestInitiation,
  searchRequestInitiation,
  patientCreation,
  patientStatus,
});

export default searchPatients;

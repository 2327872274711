import React from 'react';
import { Link } from 'react-router-dom';
import { RouterMatch } from '../../core/types';
import { TabItem, tabItems } from '../constants/tabItems';
import TestRequestsTab from './TestRequests/TestRequestsTab';
import MessageDenialTab from './MessageDenial/MessageDenialTab';

type Props = {
  match: RouterMatch;
};

const TransplantCentreTabs = ({ match }: Props) => {
  const { activeTab } = match.params;
  const renderTab = (item: TabItem) => (
    <li key={item.id}>
      <Link className={activeTab === item.id ? 'active' : ''} to={`/tc-management/${item.id}`}>
        {item.displayName}
      </Link>{' '}
    </li>
  );
  const tabs = Object.values(tabItems).map((item: TabItem) => renderTab(item));

  return (
    <div className="content group fixed-width">
      <main role="main" className="col span_12_of_12">
        <ul className="nav-tabs">{tabs}</ul>
        {activeTab === tabItems.testResults.id && <TestRequestsTab />}
        {activeTab === tabItems.rejectedMessages.id && <MessageDenialTab />}
      </main>
    </div>
  );
};

export default TransplantCentreTabs;

import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import moment from 'moment';
import InternalReportDonorsTable from './ReportsTables/InternalReportDonorsTable';
import { PatientSelectors } from '../../../patient';
import { LoadingMessage } from '../../../core';
import donorTypes from '../../../core/constants/donorTypes';
import FeatureFlagSelectors from '../../../featureFlags/redux/selectors';

import type { ApiSearchRequest } from '../../../donorMatchSearchRequests/types/api';
import type { ResultTableRow } from '../../../donorMatchSearchRequests/types';
import type { DonorType } from '../../../core/types';
import type { ReduxState } from '../../../rootReducer';
import type { Features } from '../../../featureFlags/types';

type OwnProps = {
  donorType: DonorType;
  // eslint-disable-next-line react/no-unused-prop-types
  patientId?: string;
  // used in `mapStateToProps`
  reportType: string;
};
type StateProps = {
  features: Features;
  isFetchingRequests: boolean;
  patientSearchRequests?: ApiSearchRequest[];
};
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = OwnProps & PropsFromRedux;

const mapStateToProps = (state: ReduxState, { patientId }: OwnProps): StateProps => ({
  // @ts-expect-error TODO EM-1787: allow typescript to account for combination of dynamic and static keys in PatientSearchReducerState
  patientSearchRequests: PatientSelectors.getPatientSearchRequest(state, patientId),
  isFetchingRequests: state.latestSearchRequests.searchRequests.isFetching,
  features: FeatureFlagSelectors.getCurrentFeatureFlags(state),
});

class InternalRequestsList extends PureComponent<Props> {
  static defaultProps = {
    patientId: undefined,
    patientSearchRequests: undefined,
  };

  render() {
    const { isFetchingRequests } = this.props;
    return <LoadingMessage isLoading={isFetchingRequests}>{this.renderSets()}</LoadingMessage>;
  }

  renderSets() {
    const { donorType, patientSearchRequests, reportType } = this.props;

    const sortedRequests = patientSearchRequests ? patientSearchRequests.sort(this.compareRequestedDate) : [];

    const authoriserSearchResultSets: ResultTableRow[] = sortedRequests
      .map((request) =>
        request.ResultSetSummaries.map((resultSet) => ({
          algorithm: resultSet.AlgorithmUsed,
          donorType: resultSet.DonorType,
          externalRegistries: request.ExternalRegistryDetails,
          requestId: request.Id,
          isHlaAdjusted: request.IsHlaAdjusted || !request.SearchedHlaMatchCurrentPatientHla,
          requestedDate: request.RequestedDate,
          savedCordSets: request.SavedCordSets,
          savedDonorSets: request.SavedDonorSets,
          exactMatchCount: resultSet.ExactMatchCount as number,
          potentialMatchCount: resultSet.PotentialMatchCount as number,
          mismatchCount: resultSet.MismatchCount,
          type: resultSet.DonorType === donorTypes.adult.value ? request.AdultSearchType : request.CordSearchType,
          resultSetId: resultSet.SearchResultSetId,
          resultSetSummaries: request.ResultSetSummaries,
          returnedSolarDonorCount: request.ReturnedSolarDonorCount,
        }))
      )
      .flat();

    const mappedResultSets = authoriserSearchResultSets;

    if (mappedResultSets.length > 0) {
      return (
        <InternalReportDonorsTable mappedResultSets={mappedResultSets} donorType={donorType} reportType={reportType} />
      );
    }
    return <div>No search results found for this patient.</div>;
  }

  compareRequestedDate = (requestA: ApiSearchRequest, requestB: ApiSearchRequest) =>
    moment(requestB.RequestedDate).diff(moment(requestA.RequestedDate), 'seconds', true);

  // eslint-disable-next-line react/no-unused-class-component-methods
  requestContainsCord = (request: ApiSearchRequest) => request.DonorTypesSearched.includes(donorTypes.cord.value);
}

const connector = connect(mapStateToProps);

export default connector(InternalRequestsList);

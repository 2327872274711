import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorMessage, LoadingMessage } from '../../../../../core';
import { getPatientTextMessages } from '../../../redux/actions';
import Selectors from '../../../redux/selectors';
import TextMessagesTable from './TextMessagesTable';

type Props = {
  patientId: string;
};

const TextMessagesTab = ({ patientId }: Props) => {
  const dispatch = useDispatch();
  const textMessages = useSelector(Selectors.getPatientTextMessages);
  const hasErrored = useSelector(Selectors.hasTextMessagesLoadingFailed);
  const isLoading = useSelector(Selectors.isLoadingTextMessages);
  const errorMessage = useSelector(Selectors.getTextMessagesErrorMessage);

  useEffect(() => {
    dispatch(getPatientTextMessages(patientId));
  }, []);

  return (
    <div>
      {hasErrored ? (
        <div style={{ marginTop: '10px' }}>
          <ErrorMessage errorMessages={errorMessage} />
        </div>
      ) : (
        <div className="results-table results-table--border">
          <h2 className="border-bottom-solid">Text Messages</h2>
          <LoadingMessage isLoading={isLoading}>
            <TextMessagesTable textMessages={textMessages} />
          </LoadingMessage>
        </div>
      )}
    </div>
  );
};

export default TextMessagesTab;

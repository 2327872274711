import {
  get,
  RequestAction as ApiRequestAction,
  SuccessAction as ApiSuccessAction,
} from '@an/nova-frontend-rest-client';
import UrlAssembler from 'url-assembler';
import Config from '../../config';
import type { ApiAntigen, Dispatch } from '../../core/types/index';

const GET_ANTIGENS: 'hlaInput/antigens' = 'hlaInput/antigens';
const GET_ANTIGENS_ERROR: 'hlaInput/antigens/error' = 'hlaInput/antigens/error';
const GET_ANTIGENS_REQUEST: 'hlaInput/antigens/request' = 'hlaInput/antigens/request';
const GET_ANTIGENS_SUCCESS: 'hlaInput/antigens/success' = 'hlaInput/antigens/success';

export const Actions = {
  GET_ANTIGENS_ERROR,
  GET_ANTIGENS_REQUEST,
  GET_ANTIGENS_SUCCESS,
};

export type GetAntigensRequestAction = ApiRequestAction<
  typeof GET_ANTIGENS_REQUEST,
  { antigenRowId: number; locusType: string; hlaPrefix: string }
>;
export type GetAntigensSuccessAction = ApiSuccessAction<
  typeof GET_ANTIGENS_SUCCESS,
  { antigenRowId: number; locusType: string; hlaPrefix: string },
  { Data: ApiAntigen[] }
>;

export const getAntigenData =
  (antigenRowId: number, locusType: string, hlaPrefix?: string, nmdpPrefix?: string, pageSize?: number) =>
  async (dispatch: Dispatch<any>) => {
    const url = UrlAssembler(Config().apiBaseUrl)
      .template('patients/antigens')
      .query({
        locusType,
        hlaPrefix,
        nmdpPrefix,
        pageSize,
      })
      .toString();
    await dispatch(
      get(url, GET_ANTIGENS, {
        antigenRowId,
        locusType,
        hlaPrefix,
      })
    );
  };

import React, { PureComponent } from 'react';
import { AnyAction, bindActionCreators, Dispatch as ReduxDispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import * as actions from '../../redux/actions';
import AlgorithmComparatorHeader from './AlgorithmComparatorHeader';
import AlgorithmSelectorPanel from './AlgorithmSelectorPanel';
import AlgorithmResultsViewer from './AlgorithmResultsViewer';
import { minimumNumberOfAlgorithms } from './AlgorithmComparatorValidation';
import type { DonorType } from '../../../core/types';
import type { ResultSetSummary } from '../../../donorMatchSearchRequests/types';

type StateProps = {
  algorithmsToCompare: string[];
  donorType: DonorType;
  resultSetSummaries: ResultSetSummary[];
};

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>) => ({
  getSearchMetricsAndResults: bindActionCreators(actions.getSearchMetricsAndResults, dispatch),
});

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & StateProps;

type State = {
  algorithm1: string;
  algorithm2: string;
};

export class AlgorithmComparator extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    const { algorithmsToCompare } = props;
    this.state = {
      algorithm1: algorithmsToCompare[0],
      algorithm2: algorithmsToCompare[1],
    };
  }

  componentDidMount() {
    const { algorithm1, algorithm2 } = this.state;
    this.getSearchDataForAlgorithms(algorithm1, algorithm2);
  }

  render() {
    const { donorType, algorithmsToCompare, resultSetSummaries } = this.props;
    const { algorithm1, algorithm2 } = this.state;

    const resultSet1 = resultSetSummaries.find((resultSet) => resultSet.algorithmUsed === algorithm1);

    const resultSet2 = resultSetSummaries.find((resultSet) => resultSet.algorithmUsed === algorithm2);
    return (
      <div>
        <AlgorithmComparatorHeader donorType={donorType} />

        <AlgorithmSelectorPanel
          donorType={donorType}
          selectedAlgorithm1={algorithm1}
          selectedAlgorithm2={algorithm2}
          algorithmsToCompare={algorithmsToCompare}
          updateSelectedAlgorithms={this.handleChangeToSelectedAlgorithms}
        />

        <AlgorithmResultsViewer resultSet1={resultSet1} resultSet2={resultSet2} />
      </div>
    );
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  getIntialAlgorithmValue(index: number) {
    const { algorithmsToCompare } = this.props;
    return minimumNumberOfAlgorithms(algorithmsToCompare) ? algorithmsToCompare[index] : undefined;
  }

  handleChangeToSelectedAlgorithms = (algorithm1: string, algorithm2: string) => {
    this.setState({
      algorithm1,
      algorithm2,
    });

    this.getSearchDataForAlgorithms(algorithm1, algorithm2);
  };

  getSearchDataForAlgorithms(algorithm1?: string, algorithm2?: string) {
    if (algorithm1) {
      this.getSearchDataForSingleAlgorithm(algorithm1);
    }
    if (algorithm2) {
      this.getSearchDataForSingleAlgorithm(algorithm2);
    }
  }

  getSearchDataForSingleAlgorithm(algorithm: string) {
    const { donorType, getSearchMetricsAndResults, resultSetSummaries } = this.props;
    const resultSet = resultSetSummaries.find((item) => item.algorithmUsed === algorithm);
    if (resultSet) {
      const resultSetId = resultSet && resultSet.searchResultSetId;
      getSearchMetricsAndResults(resultSetId, donorType);
    }
  }
}

export default connector(AlgorithmComparator);

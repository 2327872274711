import { Actions } from '../actionNames';
import {
  ExternalInvestigationsCreateIDMRequestErrorAction,
  ExternalInvestigationsCreateIDMRequestRequestAction,
  ExternalInvestigationsCreateIDMRequestSuccessAction,
} from '../actions';

type UsersActions =
  | ExternalInvestigationsCreateIDMRequestSuccessAction
  | ExternalInvestigationsCreateIDMRequestErrorAction
  | ExternalInvestigationsCreateIDMRequestRequestAction;

export type IDMRequestInitiationReducerState = {
  isCreatingRequest: boolean;
  errorMessage?: string;
};

const initialState: IDMRequestInitiationReducerState = {
  isCreatingRequest: false,
  errorMessage: undefined,
};

export default (
  state: IDMRequestInitiationReducerState = initialState,
  action: UsersActions
): IDMRequestInitiationReducerState => {
  switch (action.type) {
    case Actions.EXTERNAL_INVESTIGATIONS_CREATE_IDM_REQUEST_REQUEST:
      return {
        ...state,
        isCreatingRequest: true,
      };
    case Actions.EXTERNAL_INVESTIGATIONS_CREATE_IDM_REQUEST_SUCCESS:
      return initialState;
    case Actions.EXTERNAL_INVESTIGATIONS_CREATE_IDM_REQUEST_ERROR:
      return {
        ...state,
        isCreatingRequest: false,
        errorMessage: action.payload.params.errorMessage,
      };
    default:
      return state;
  }
};

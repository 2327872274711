import _ from 'lodash';
import type { InstitutionAddress } from '../types';

export const formatAddress = (address: InstitutionAddress) => {
  const addressFieldsToDisplay: string[] = [];
  if (address.Name) {
    addressFieldsToDisplay.push('Name');
  }
  if (address.Address1) {
    addressFieldsToDisplay.push('Address1');
  }
  if (address.Address2) {
    addressFieldsToDisplay.push('Address2');
  }
  if (address.Address3) {
    addressFieldsToDisplay.push('Address3');
  }
  if (address.Town) {
    addressFieldsToDisplay.push('Town');
  }
  if (address.County) {
    addressFieldsToDisplay.push('County');
  }
  if (address.Postcode) {
    addressFieldsToDisplay.push('Postcode');
  }
  if (address.CountryType) {
    addressFieldsToDisplay.push('CountryType');
  }

  const { Id, InstitutionId, ...addressWithoutId } = address;

  // @ts-expect-error - can not match type ObjectIterateeCustom as second argument
  const addressValues = _.filter(addressWithoutId, (value: string, key: string) =>
    addressFieldsToDisplay.includes(key)
  );

  return addressValues.join(', ');
};

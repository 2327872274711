import React from 'react';
import { LoadingMessage } from '../../../core';
import { getExternalInvestigationActionMessageTypeName } from '../../../core/constants/externalInvestigationActionMessageTypes';
import { getMessageDenialStatusName } from '../../../core/constants/messageDenialStatuses';
import { ApiFailedActions, ApiSuceededActions } from '../../types/api';

type OwnProps = {
  onConfirm: () => void;
  onClose: () => void;
  isUpdating: boolean;
  dialogueButtonText: string;
  failedActions: ApiFailedActions | null | undefined;
  succeededActions: ApiSuceededActions | null | undefined;
  isMessageDenials?: boolean;
};

const ActionsDialogue = ({
  onConfirm,
  onClose,
  isUpdating,
  dialogueButtonText,
  failedActions,
  succeededActions,
  isMessageDenials,
}: OwnProps) => (
  <div className="dialogue-container">
    <h2 className="border-bottom-solid">
      {isMessageDenials
        ? 'The following message denials will be marked as completed'
        : 'The following actions will be marked as completed'}
    </h2>
    {succeededActions?.map((succeededAction) => (
      <div className="alert alert--success">
        {isMessageDenials
          ? getMessageDenialStatusName(succeededAction.MessageType)
          : getExternalInvestigationActionMessageTypeName(succeededAction.MessageType)}{' '}
        - Completed
      </div>
    ))}
    {failedActions?.map((failedAction) => (
      <div className="alert alert--danger" data-testid="error">
        {isMessageDenials
          ? getMessageDenialStatusName(failedAction.MessageType)
          : getExternalInvestigationActionMessageTypeName(failedAction.MessageType)}{' '}
        - {failedAction.Reason}
      </div>
    ))}
    <LoadingMessage isLoading={isUpdating} />
    <div className="btn-actions">
      <button className="btn" type="button" onClick={onConfirm}>
        {dialogueButtonText}
      </button>
      <button className="btn btn--secondary" type="button" onClick={onClose}>
        Cancel
      </button>
    </div>
  </div>
);

ActionsDialogue.defaultProps = {
  isMessageDenials: false,
};

export default ActionsDialogue;

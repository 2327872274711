import moment, { Moment } from 'moment';
import React from 'react';
import '../externalInvestigationsStyling.scss';
import type { VTReceptionDates } from '../types';

type Props = {
  receptionDates: VTReceptionDates;
  updateReceptionDates: (newDates: VTReceptionDates) => void;
};
type State = {
  minEarliestDate: Moment;
  minLatestDate: Moment;
};
const ReceptionDateType = {
  earliest: 'earliest',
  latest: 'latest',
};

const TODAY_DATE = moment().utc().startOf('day');

const getMinLatestDate = (earliest: Moment | null | undefined): Moment =>
  (earliest ?? TODAY_DATE).clone().add(1, 'days');

class ReceptionDates extends React.Component<Props, State> {
  state = {
    minEarliestDate: TODAY_DATE,
    minLatestDate: getMinLatestDate(this.props.receptionDates.earliest),
  };

  render() {
    const { receptionDates } = this.props;
    const { minEarliestDate, minLatestDate } = this.state;
    return (
      <div style={{ paddingBottom: '10%' }}>
        <div style={{ padding: '20px 0 2px' }}>Reception Dates</div>
        <div className="dateVTForm">
          <div
            style={{
              alignItems: 'center',
              display: 'inline-flex',
              width: '100%',
            }}
          >
            <span style={{ width: '65%' }}>
              Earliest
              <span style={{ color: '#e36060' }}>*</span>
            </span>
            <input
              type="date"
              id="earliest"
              className="verificationTypingRequestDate"
              value={receptionDates.earliest?.format('YYYY-MM-DD')}
              onChange={(event) => this.updateDate(ReceptionDateType.earliest, event.currentTarget.value)}
              min={minEarliestDate.format('YYYY-MM-DD')}
            />
          </div>
          <div
            style={{
              alignItems: 'center',
              display: 'inline-flex',
              width: '100%',
            }}
          >
            <span style={{ width: '65%' }}>Latest</span>
            <input
              type="date"
              id="latest"
              className="verificationTypingRequestDate"
              value={receptionDates.latest?.format('YYYY-MM-DD')}
              onChange={(event) => this.updateDate(ReceptionDateType.latest, event.currentTarget.value)}
              min={minLatestDate.format('YYYY-MM-DD')}
            />
          </div>
        </div>
      </div>
    );
  }

  updateDate = (type: string, date: string) => {
    const { updateReceptionDates, receptionDates } = this.props;

    // Date input returns a string.
    // We need to convert it to a correctly formatted Moment UTC date.

    const newDate = moment(date).isValid() ? moment.utc(date) : undefined;

    const newReceptionDates = { ...receptionDates };

    if (type === ReceptionDateType.earliest) {
      newReceptionDates.earliest = newDate;

      const minLatestDate = getMinLatestDate(newDate);
      this.setState({ minLatestDate });

      if (minLatestDate && receptionDates.latest && receptionDates.latest.isBefore(minLatestDate)) {
        newReceptionDates.latest = undefined;
      }

      updateReceptionDates(newReceptionDates);
      return;
    }

    if (type === ReceptionDateType.latest) {
      newReceptionDates.latest = newDate;

      updateReceptionDates(newReceptionDates);
      return;
    }

    throw new Error(`Unexpected reception date type: ${type}`);
  };
}

export default ReceptionDates;

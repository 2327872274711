const CONFIRMATORY_TYPING: 'ConfirmatoryTyping' = 'ConfirmatoryTyping';
const EXTERNAL_CONFIRMATORY_TYPING: 'ExternalConfirmatoryTyping' = 'ExternalConfirmatoryTyping';
const FIRST_TIME_DONOR: 'FirstTimeDonor' = 'FirstTimeDonor';

export const investigationTypes = {
  confirmatoryTyping: {
    value: CONFIRMATORY_TYPING,
    label: 'CT',
  },
  externalConfirmatoryTyping: {
    value: EXTERNAL_CONFIRMATORY_TYPING,
    label: 'CTX',
  },
  firstTimeDonor: {
    value: FIRST_TIME_DONOR,
    label: 'FTD',
  },
};

export const patientInvestigationTypeOptions = [investigationTypes.confirmatoryTyping];
export const donorInvestigationTypeOptions = [
  ...patientInvestigationTypeOptions,
  investigationTypes.externalConfirmatoryTyping,
  investigationTypes.firstTimeDonor,
];

import * as React from 'react';
import { styles as commonStyles, Styles as CommonStyles } from '../../style';

type Props = {
  notes: string;
  isReadOnly: boolean;
  notesEdit: (editedNote: string) => void;
};

type State = {
  notes: string;
};

const styles: CommonStyles & Record<string, React.CSSProperties> = {
  ...commonStyles,
  savedDonorsNotesInput: {
    fontSize: '15px',
    padding: '5px',
    margin: '3px 0 3px 0',
    border: '1px solid $grey',
    backgroundColor: 'transparent',
    resize: 'none',
    minHeight: '129px',
  },
};

class EditableResultDonorsNotes extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { notes } = this.props;
    this.state = {
      notes,
    };
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps: Props) {
    this.setState({
      notes: nextProps.notes,
    });
  }

  render() {
    const { isReadOnly } = this.props;
    const { notes } = this.state;
    return (
      <textarea
        style={styles.savedDonorsNotesInput}
        placeholder="Add Notes to the Selected Set."
        readOnly={isReadOnly}
        value={notes || ''}
        onChange={this.handleInputChange}
        onBlur={this.handleNoteUpdate}
      />
    );
  }

  handleInputChange = (
    event: React.SyntheticEvent<HTMLTextAreaElement> & {
      currentTarget: HTMLTextAreaElement;
    }
  ) => {
    this.setState({
      notes: event.currentTarget.value,
    });
  };

  handleNoteUpdate = () => {
    const { notesEdit } = this.props;
    const { notes } = this.state;
    notesEdit(notes);
  };
}

export default EditableResultDonorsNotes;

import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PatientDashboard from '../../patientDashboard/components/PatientDashboard';
import { ReportCreationPage } from '../../../searchReports';

import type { RouterMatch } from '../../../core/types';

type Props = {
  match: RouterMatch;
};

const PatientPageWrapper = ({ match }: Props): JSX.Element => (
  <Switch>
    <Route exact path={`${match.path}`} render={() => <Redirect to={`${match.url}/ukSearchRequests`} />} />
    <Route path={`${match.path}/reports/:reportId`} component={ReportCreationPage} />
    <Route
      exact
      path={[`${match.path}/:activeTab`, `${match.path}/:activeTab/:testRequestId`]}
      component={PatientDashboard}
    />
  </Switch>
);

export default PatientPageWrapper;

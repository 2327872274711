import React, { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import PatientCreationSelectors from '../../../../patient/patientCreation/redux/selectors';
import * as PatientCreationActions from '../../../../patient/patientCreation/redux/actions';

import type { SubjectInvestigation } from '../../../types';
import { formatDate } from '../../../../core/helpers/dateHelpers';
import { ReduxState } from '../../../../rootReducer';
import Checkbox from '../../../../core/components/Checkbox';
import { formatAddress } from '../../../../core/helpers/formatAddress';

type Props = {
  investigation: SubjectInvestigation;
};

const ExistingInvestigationInvoicing = ({ investigation }: Props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(PatientCreationActions.getInstitutionAddresses());
  }, []);

  const invoiceeAddresses = useSelector(
    (state: ReduxState) => PatientCreationSelectors.getInstitutionAddresses(state),
    shallowEqual
  );

  const invoiceAddress =
    invoiceeAddresses &&
    invoiceeAddresses.find((address) => address.Id === parseInt(investigation.InvoiceeInstitutionAddressId, 10));

  return (
    <>
      <h3>Invoice To</h3>
      <table className="table">
        <thead className="table-header">
          <tr>
            <th>Requires Invoicing?</th>
            <th>Invoicee</th>
            <th>Investigation Date</th>
          </tr>
        </thead>
        <tbody>
          <tr className="centered-table-data">
            <td>
              <div style={{ margin: '0 auto', width: '56px' }}>
                <Checkbox
                  id="requires-invoicing-existing"
                  label=""
                  checked={investigation.InvoiceRequired}
                  onChange={() => {}}
                  disabled
                />
              </div>
            </td>
            <td>{invoiceAddress && formatAddress(invoiceAddress)}</td>
            <td>{formatDate(investigation.InvestigationDate)}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default ExistingInvestigationInvoicing;

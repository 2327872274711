import { LOCATION_CHANGE, RouterState } from 'connected-react-router';
import { Actions } from '../actionNames';
// eslint-disable-next-line import/no-cycle
import {
  convertAdultSearchResultsFromApi,
  convertCordSearchResultsFromApi,
  convertSearchMetricsFromApi,
} from '../../helpers/apiDataConverter';
// eslint-disable-next-line import/no-cycle
import { convertSearchRequestSummaryFromApi } from '../../../donorMatchSearchRequests/helpers/apiDataConverter';

import type {
  AdultSearchResultsCreateDonorSetSuccessAction,
  AdultSearchResultsErrorAction,
  AdultSearchResultsRequestAction,
  AdultSearchResultsSavedDonorsErrorAction,
  AdultSearchResultsSavedDonorsRequestAction,
  AdultSearchResultsSavedDonorsSuccessAction,
  AdultSearchResultsSuccessAction,
  CordSearchResultsCordSetsRequestAction,
  CordSearchResultsCreateCordSetSuccessAction,
  CordSearchResultsErrorAction,
  CordSearchResultsRequestAction,
  CordSearchResultsSavedCordsErrorAction,
  CordSearchResultsSavedCordsRequestAction,
  CordSearchResultsSavedCordsSuccessAction,
  CordSearchResultsSuccessAction,
  SearchMetricsErrorAction,
  SearchMetricsRequestAction,
  SearchMetricsSuccessAction,
  SearchRequestsErrorAction,
  SearchRequestsSuccessAction,
  SearchResultsRequestAction,
  SearchResultsSuccessAction,
} from '../actions';
import type { SearchResults } from '../../../core/types';
import type { SearchMetrics } from '../../types';
import type { SearchRequestSummary } from '../../../donorMatchSearchRequests/types';

type LocationChangeAction = { type: typeof LOCATION_CHANGE; payload: RouterState };

type UsersAction =
  | AdultSearchResultsCreateDonorSetSuccessAction
  | AdultSearchResultsErrorAction
  | AdultSearchResultsRequestAction
  | AdultSearchResultsSavedDonorsErrorAction
  | AdultSearchResultsSavedDonorsRequestAction
  | AdultSearchResultsSavedDonorsSuccessAction
  | AdultSearchResultsSuccessAction
  | CordSearchResultsCordSetsRequestAction
  | CordSearchResultsCreateCordSetSuccessAction
  | CordSearchResultsErrorAction
  | CordSearchResultsRequestAction
  | CordSearchResultsSavedCordsErrorAction
  | CordSearchResultsSavedCordsRequestAction
  | CordSearchResultsSavedCordsSuccessAction
  | CordSearchResultsSuccessAction
  | LocationChangeAction
  | SearchMetricsErrorAction
  | SearchMetricsRequestAction
  | SearchMetricsSuccessAction
  | SearchRequestsErrorAction
  | SearchRequestsSuccessAction
  | SearchResultsRequestAction
  | SearchResultsSuccessAction;

export type ApiData<T> = {
  readonly data?: T;
  readonly isFetching: boolean;
  readonly hasErrored: boolean;
  readonly message?: string;
};
export type SearchResultsDataReducerState = {
  cordsSaved: ApiData<string[]>;
  donorsSaved: ApiData<string[]>;
  isFetching: boolean;
  // TODO: combine cordsSaved and donorsSaved to `savedResultSets`
  SearchResults: Record<string, ApiData<SearchResults>>;
  searchMetrics: ApiData<Record<string, SearchMetrics>>;
  searchRequest: ApiData<SearchRequestSummary>;
};

const initialState: SearchResultsDataReducerState = {
  searchRequest: {
    isFetching: false,
    hasErrored: false,
  },
  searchMetrics: {
    data: {},
    isFetching: false,
    hasErrored: false,
  },
  SearchResults: {},
  donorsSaved: {
    data: [],
    isFetching: false,
    hasErrored: false,
  },
  cordsSaved: {
    data: [],
    isFetching: false,
    hasErrored: false,
  },
  isFetching: false,
};

export default (
  state: SearchResultsDataReducerState = initialState,
  action: UsersAction
): SearchResultsDataReducerState => {
  switch (action.type) {
    case LOCATION_CHANGE:
      return initialState;
    case Actions.SEARCH_METRICS_ERROR: {
      const { requestId, resultSetId } = action.payload.params;
      return {
        ...state,
        searchMetrics: {
          data: {},
          isFetching: false,
          hasErrored: true,
          message: `Error while fetching Search Metrics for Request ${requestId}, Result Set ID ${resultSetId}. Status code ${action.payload.response.status}`,
        },
      };
    }
    case Actions.SEARCH_METRICS_REQUEST:
      return {
        ...state,
        searchMetrics: {
          ...state.searchMetrics,
          isFetching: true,
          hasErrored: false,
        },
      };
    case Actions.SEARCH_METRICS_SUCCESS: {
      const { resultSetId } = action.payload.params;
      const searchMetrics = convertSearchMetricsFromApi(action.payload.data);
      return {
        ...state,
        searchMetrics: {
          data: {
            ...state.searchMetrics.data,
            [resultSetId]: searchMetrics,
          },
          isFetching: false,
          hasErrored: false,
        },
      };
    }
    case Actions.SEARCH_REQUESTS_ERROR:
      return {
        ...state,
        searchRequest: {
          data: undefined,
          isFetching: false,
          hasErrored: true,
          message:
            action.payload.response.status !== 200
              ? `Error while fetching Search Request ${action.payload.params.requestId} - ${action.payload.response.status}`
              : action.payload.data && action.payload.data.message,
        },
      };
    case Actions.SEARCH_REQUESTS_SUCCESS: {
      const request = action.payload
        ? convertSearchRequestSummaryFromApi(
            action.payload.data.entities.searchRequestSummaries[action.payload.data.result]
          )
        : undefined;
      return {
        ...state,
        searchRequest: {
          ...state.searchRequest,
          data: request,
          isFetching: false,
          hasErrored: false,
        },
      };
    }
    case Actions.SEARCH_RESULTS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Actions.SEARCH_RESULTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case Actions.ADULT_SEARCH_RESULTS_ERROR: {
      const { resultSetId } = action.payload.params;
      return {
        ...state,
        SearchResults: {
          [resultSetId]: {
            isFetching: false,
            hasErrored: true,
            message: `Error while fetching Search Results for Result Set ID ${resultSetId}. Status code ${action.payload.response.status}`,
          },
        },
      };
    }
    case Actions.ADULT_SEARCH_RESULTS_REQUEST: {
      const { resultSetId } = action.payload.params;
      return {
        ...state,
        SearchResults: {
          ...state.SearchResults,
          [resultSetId]: {
            isFetching: true,
            hasErrored: false,
          },
        },
      };
    }
    case Actions.ADULT_SEARCH_RESULTS_SUCCESS: {
      const { resultSetId } = action.payload.params;
      const searchResults = convertAdultSearchResultsFromApi(resultSetId, action.payload.data);
      return {
        ...state,
        SearchResults: {
          ...state.SearchResults,
          [resultSetId]: {
            data: searchResults,
            isFetching: false,
            hasErrored: false,
          },
        },
      };
    }
    case Actions.ADULT_SEARCH_RESULTS_DONOR_SET_CREATE_SUCCESS:
      return {
        ...state,
        donorsSaved: {
          ...state.donorsSaved,
          data: state.donorsSaved.data
            ? [...state.donorsSaved.data, action.payload.data.result]
            : [action.payload.data.result],
        },
      };
    case Actions.ADULT_SEARCH_RESULTS_DONOR_SETS_ERROR:
      return {
        ...state,
        donorsSaved: {
          ...state.donorsSaved,
          isFetching: false,
          hasErrored: true,
          message: `Error while fetching Saved Donors for Request ${action.payload.params.requestId}. Status code ${action.payload.response.status}`,
        },
      };
    case Actions.ADULT_SEARCH_RESULTS_DONOR_SETS_REQUEST:
      return {
        ...state,
        donorsSaved: {
          ...state.donorsSaved,
          isFetching: true,
          hasErrored: false,
        },
      };
    case Actions.ADULT_SEARCH_RESULTS_DONOR_SETS_SUCCESS:
      return {
        ...state,
        donorsSaved: {
          data: state.donorsSaved.data
            ? [...state.donorsSaved.data, ...action.payload.data.result]
            : action.payload.data.result,
          isFetching: false,
          hasErrored: false,
        },
      };
    case Actions.CORD_SEARCH_RESULTS_ERROR: {
      const { resultSetId } = action.payload.params;
      return {
        ...state,
        SearchResults: {
          [resultSetId]: {
            isFetching: false,
            hasErrored: true,
            message: `Error while fetching Cord Search Results for Result Set ID ${resultSetId}. Status code ${action.payload.response.status}`,
          },
        },
      };
    }
    case Actions.CORD_SEARCH_RESULTS_REQUEST: {
      const { resultSetId } = action.payload.params;
      return {
        ...state,
        SearchResults: {
          ...state.SearchResults,
          [resultSetId]: {
            isFetching: true,
            hasErrored: false,
          },
        },
      };
    }
    case Actions.CORD_SEARCH_RESULTS_SUCCESS: {
      const { resultSetId } = action.payload.params;
      const cordSearchResults = convertCordSearchResultsFromApi(resultSetId, action.payload.data);
      return {
        ...state,
        SearchResults: {
          ...state.SearchResults,
          [resultSetId]: {
            data: cordSearchResults,
            isFetching: false,
            hasErrored: false,
          },
        },
      };
    }
    case Actions.CORD_SEARCH_RESULTS_CORD_SET_CREATE_SUCCESS:
      return {
        ...state,
        cordsSaved: {
          ...state.cordsSaved,
          data: state.cordsSaved.data
            ? [...state.cordsSaved.data, action.payload.data.result]
            : [action.payload.data.result],
        },
      };
    case Actions.CORD_SEARCH_RESULTS_CORD_SETS_ERROR:
      return {
        ...state,
        cordsSaved: {
          ...state.cordsSaved,
          isFetching: false,
          hasErrored: true,
          message: `Error while fetching Saved Cords for Request ${action.payload.params.requestId}. Status code ${action.payload.response.status}`,
        },
      };
    case Actions.CORD_SEARCH_RESULTS_CORD_SETS_REQUEST:
      return {
        ...state,
        cordsSaved: {
          ...state.cordsSaved,
          isFetching: true,
          hasErrored: false,
        },
      };
    case Actions.CORD_SEARCH_RESULTS_CORD_SETS_SUCCESS:
      return {
        ...state,
        cordsSaved: {
          data: state.cordsSaved.data
            ? [...state.cordsSaved.data, ...action.payload.data.result]
            : action.payload.data.result,
          isFetching: false,
          hasErrored: false,
        },
      };
    default:
      return state;
  }
};

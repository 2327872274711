import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import type { DonorType } from '../../../core/types';
import type { SearchType } from '../../../core/constants/searchTypes';
import Selectors from '../../redux/selectors';
import type { ReduxState } from '../../../rootReducer';

type OwnProps = {
  donorType: DonorType;
};
type StateProps = {
  patientId?: string | void;
  searchRunDate?: string | void;
  searchType?: SearchType;
};
type Props = PropsFromRedux & StateProps & OwnProps;

const mapStateToProps = (state: ReduxState, ownProps: OwnProps): StateProps => ({
  patientId: Selectors.getRequestPatientId(state),
  searchRunDate: Selectors.getRequestRequestedDate(state),
  searchType: Selectors.getRequestSearchType(state, ownProps.donorType),
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export const AlgorithmComparatorHeader = ({ donorType, searchType, patientId, searchRunDate }: Props) => (
  <>
    <h1>Algorithm Comparison</h1>
    <h1>
      {donorType}, {searchType} Search for Patient {patientId}, run on {searchRunDate}
    </h1>
  </>
);

AlgorithmComparatorHeader.defaultProps = {
  patientId: undefined,
  searchRunDate: undefined,
  searchType: undefined,
};

export default connector(AlgorithmComparatorHeader);

import { get, ErrorAction as ApiErrorAction, SuccessAction as ApiSuccessAction } from '@an/nova-frontend-rest-client';
import { FeatureFlagsState } from '..';
import Config, { featureFlags } from '../../config';
import type { Dispatch, GetState } from '../../core/types';
import type { ReduxState } from '../../rootReducer';
import type { Features } from '../types';

const FEATURE_FLAGS: 'featureFlags' = 'featureFlags';
const FEATURE_FLAGS_ERROR: 'featureFlags/error' = 'featureFlags/error';
const FEATURE_FLAGS_SUCCESS: 'featureFlags/success' = 'featureFlags/success';

export const Actions = {
  FEATURE_FLAGS_ERROR,
  FEATURE_FLAGS_SUCCESS,
};

export type FeatureFlagsFetchErrorAction = ApiErrorAction<typeof FEATURE_FLAGS_ERROR, Record<string, unknown>>;
export type FeatureFlagsFetchSuccessAction = ApiSuccessAction<
  typeof FEATURE_FLAGS_SUCCESS,
  Record<string, unknown>,
  Features
>;

const fetchFeatureFlags = () => {
  if (featureFlags.useLocalFlags) {
    return {
      type: FEATURE_FLAGS_SUCCESS,
      payload: featureFlags.defaultFeatures,
    };
  }
  return get<ReduxState, Record<string, unknown>>(`${Config().apiBaseUrl}feature-flags`, FEATURE_FLAGS);
};

const shouldFetchFeatureFlags = (flagsState: FeatureFlagsState) =>
  !flagsState.features || Object.keys(flagsState.features).length === 0;

export const getFeatureFlags = () => (dispatch: Dispatch<any>, getState: GetState) => {
  if (shouldFetchFeatureFlags(getState().featureFlags)) {
    return dispatch(fetchFeatureFlags());
  }
  return Promise.resolve();
};

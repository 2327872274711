import type { Locus } from '../../core/types';

const ADD_FULL_HLA: 'notification/fullHla/add' = 'notification/fullHla/add';
const NOTIFICATION_SUCCESS: 'notification/success' = 'notification/success';
const NOTIFICATION_ERROR: 'notification/error' = 'notification/error';

export type LogLevel = 'error' | 'info' | 'success';

export type AddFullHlaAction = {
  payload: {
    hlaData: Locus;
    level: LogLevel;
  };
  type: typeof ADD_FULL_HLA;
};

export type NotificationErrorAction = {
  type: typeof NOTIFICATION_ERROR;
  payload: {
    data: {
      globalErrors: string;
    };
    params: {
      errorMessage?: string;
    };
    response: {
      body: {
        Error: string;
      };
      timeDisplayed: number;
    };
  };
};

export type NotificationSuccessAction = {
  type: typeof NOTIFICATION_SUCCESS;
  payload: {
    data: {
      successMessage: string;
      timeDisplayed: number;
    };
    params: {
      successMessage: string;
    };
  };
};

export const showFullHla = (hlaData: Locus, level: LogLevel): AddFullHlaAction => ({
  type: ADD_FULL_HLA,
  payload: {
    hlaData,
    level,
  },
});

export const Actions = {
  ADD_FULL_HLA,
  NOTIFICATION_SUCCESS,
  NOTIFICATION_ERROR,
};

import { DonorDetails } from '../../subject/types';
import { formatDate } from '../../core/helpers/dateHelpers';
import gridFormatter from '../../core/helpers/gridFormatter';
import { mapDonorTypeOptions, mapSelectOptions } from './formsHelpers';
import { donorTypesDetails, fieldTypes, cmvStatuses, bloodGroupTypes, Rhesus } from '../constants';
import { SubjectConstants, FormField } from '../types';

export const getEditDonorDetailsFields = (donor: DonorDetails, formConstants?: SubjectConstants): FormField[] => [
  {
    label: 'Donor ID',
    id: 'Id',
    type: fieldTypes.text,
    value: donor.Id,
    disabled: true,
  },
  {
    label: 'First Name',
    id: 'FirstName',
    type: fieldTypes.text,
    value: donor.FirstName,
    disabled: false,
  },
  {
    label: 'Second Name',
    id: 'MiddleNames',
    type: fieldTypes.text,
    value: donor.MiddleNames,
    disabled: false,
  },
  {
    label: 'Surname*',
    id: 'LastName',
    type: fieldTypes.text,
    value: donor.LastName,
    disabled: false,
  },
  {
    label: 'Gender',
    id: 'Gender',
    type: fieldTypes.select,
    value: donor.Gender,
    options: formConstants && mapSelectOptions(formConstants.GenderOptions),
    disabled: false,
  },
  {
    label: 'Date of Birth',
    id: 'BirthDate',
    type: fieldTypes.date,
    value: formatDate(donor.BirthDate, 'YYYY-MM-DD'),
    disabled: false,
  },
  {
    label: 'GRID',
    id: 'Grid',
    type: fieldTypes.text,
    value: gridFormatter(donor.Grid),
    disabled: true,
  },
  {
    label: 'Donor Type*',
    id: 'DonorType',
    type: fieldTypes.select,
    value: donor.DonorType,
    options: mapDonorTypeOptions(donorTypesDetails, donor.DonorType),
    disabled: false,
  },
  {
    label: 'Linked Patient ID',
    id: 'LinkedPatientId',
    type: fieldTypes.text,
    value: donor.LinkedPatientId,
    disabled: true,
  },
  {
    label: 'CMV Status',
    id: 'Cmv',
    type: fieldTypes.text,
    value: donor.Cmv,
    options: mapSelectOptions(cmvStatuses, donor.Cmv),
    disabled: true,
  },
  {
    label: 'Last Tested',
    id: 'DateCmvTested',
    type: fieldTypes.date,
    value: formatDate(donor.DateCmvTested, 'YYYY-MM-DD'),
    disabled: true,
  },
  {
    label: 'Blood Group',
    id: 'BloodType',
    type: fieldTypes.text,
    value: donor.BloodType,
    options: mapSelectOptions(bloodGroupTypes, donor.BloodType),
    disabled: true,
  },
  {
    label: 'Rh Type',
    id: 'Rhesus',
    type: fieldTypes.text,
    value: donor.Rhesus,
    options: mapSelectOptions(Rhesus, donor.Rhesus),
    disabled: true,
  },
  {
    label: 'HLA Notes',
    id: 'TestNotes',
    type: fieldTypes.textarea,
    value: donor.TestNotes,
    disabled: true,
  },
];

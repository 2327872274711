import * as React from 'react';
import './TextWithLimitedLines.scss';

type Props = {
  numberOfLines: number;
  lineHeightPx: number;
  children: React.ReactNode | string;
  tooltip?: string | undefined | null;
  customTooltipId?: string | undefined | null;
};

const TextWithLimitedLines = ({ numberOfLines, lineHeightPx, tooltip, customTooltipId, children }: Props) => {
  if (!numberOfLines) {
    throw new Error('numberOfLines is required');
  }
  if (!lineHeightPx) {
    throw new Error('lineHeightPx is required');
  }

  const style = {
    lineHeight: `${lineHeightPx}px`,
    maxHeight: `${lineHeightPx * numberOfLines}px`,
    WebkitLineClamp: numberOfLines,
  };

  return (
    <div data-tip={tooltip} data-for={customTooltipId} style={style} className="text-with-limited-lines">
      {children}
    </div>
  );
};

TextWithLimitedLines.defaultProps = {
  tooltip: null,
  customTooltipId: null,
};

export default TextWithLimitedLines;

const SOLAR = 'Solar';
const EXTERNAL = 'External';
const NOVACONTROL = 'NovaControl';
const ATLAS = 'Atlas';

export default {
  solar: SOLAR,
  external: EXTERNAL,
  novaControl: NOVACONTROL,
  atlas: ATLAS,
};

import { LOCATION_CHANGE, RouterState } from 'connected-react-router';
import _ from 'lodash';

// eslint-disable-next-line import/no-cycle
import {
  Actions,
  PatientSearchRequestsErrorAction,
  PatientSearchRequestsSuccessAction,
  SearchRequestsUpdateSelectedUserAction,
  UserSearchRequestsErrorAction,
  UserSearchRequestsSuccessAction,
} from './actions';
import { Actions as DonorSearchResultsActions } from '../../donorSearchResults/redux/actionNames';
import type {
  AdultSearchResultsCreateDonorSetSuccessAction,
  AdultSearchResultsSavedDonorsSuccessAction,
  AdultSearchResultsUpdateDonorSetSuccessAction,
  CordSearchResultsCreateCordSetSuccessAction,
  CordSearchResultsSavedCordsSuccessAction,
  CordSearchResultsUpdateCordSetSuccessAction,
} from '../../donorSearchResults/redux/actions';
// eslint-disable-next-line import/no-cycle
import {
  convertSavedCordSetsFromApi,
  convertSavedDonorSetsFromApi,
} from '../../donorSearchResults/helpers/apiDataConverter';

import type { SavedResultSet } from '../types';
import type { ApiSearchRequest } from '../types/api';
import type {
  FetchSingleSearchRequestRequestAction,
  FetchSingleSearchRequestSuccessAction,
  FetchSingleSearchRequestErrorAction,
  PatientSearchRequestsRequestAction,
  UserSearchRequestsRequestAction,
} from './actions';

type LocationChangeAction = { type: typeof LOCATION_CHANGE; payload: RouterState };

type UsersActions =
  | AdultSearchResultsCreateDonorSetSuccessAction
  | AdultSearchResultsSavedDonorsSuccessAction
  | AdultSearchResultsUpdateDonorSetSuccessAction
  | CordSearchResultsCreateCordSetSuccessAction
  | CordSearchResultsSavedCordsSuccessAction
  | CordSearchResultsUpdateCordSetSuccessAction
  | FetchSingleSearchRequestErrorAction
  | FetchSingleSearchRequestRequestAction
  | FetchSingleSearchRequestSuccessAction
  | PatientSearchRequestsErrorAction
  | PatientSearchRequestsRequestAction
  | PatientSearchRequestsSuccessAction
  | SearchRequestsUpdateSelectedUserAction
  | UserSearchRequestsErrorAction
  | UserSearchRequestsRequestAction
  | UserSearchRequestsSuccessAction
  | LocationChangeAction;

export type LatestSearchRequestsReducerState = {
  // TODO-Flow: Move to entities reducer
  savedCordSets: { [string: string]: SavedResultSet };
  // TODO-Flow: Move to entities reducer
  savedDonorSets: { [string: string]: SavedResultSet };
  hasErrored: boolean;
  message?: string | null | undefined;
  searchRequests: {
    isFetching: boolean;
    hasErrored: boolean;
    message?: string | undefined;
    // TODO-Flow: Move to entities reducer
    requests: { [string: string]: ApiSearchRequest };
    requestsBeingFetched: string[];
  };
  selectedUser?: string;
};

const initialState: LatestSearchRequestsReducerState = {
  selectedUser: undefined,
  hasErrored: false,
  message: undefined,
  searchRequests: {
    isFetching: false,
    hasErrored: false,
    requests: {},
    requestsBeingFetched: [],
  },
  savedDonorSets: {},
  savedCordSets: {},
};

export default (
  state: LatestSearchRequestsReducerState = initialState,
  action: UsersActions
): LatestSearchRequestsReducerState => {
  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        ...state,
        hasErrored: false,
        message: null,
        searchRequests: {
          ...state.searchRequests,
          hasErrored: false,
          message: undefined,
        },
      };
    case Actions.SEARCH_REQUESTS_SELECTED_USER_UPDATE:
      return {
        ...state,
        selectedUser: action.payload,
      };
    case Actions.PATIENTS_SEARCH_REQUESTS_ERROR:
    case Actions.USER_SEARCH_REQUESTS_ERROR:
    case Actions.FETCH_SINGLE_SEARCH_REQUEST_ERROR:
      return {
        ...state,
        searchRequests: {
          ...state.searchRequests,
          isFetching: false,
          hasErrored: true,
          message: `Error while fetching requests - ${action.payload.response.status}`,
        },
      };
    case Actions.PATIENTS_SEARCH_REQUESTS_REQUEST:
    case Actions.USER_SEARCH_REQUESTS_REQUEST:
      return {
        ...state,
        searchRequests: {
          ...state.searchRequests,
          isFetching: true,
          hasErrored: false,
        },
      };
    case Actions.FETCH_SINGLE_SEARCH_REQUEST_REQUEST:
      return {
        ...state,
        searchRequests: {
          ...state.searchRequests,
          requestsBeingFetched: [...state.searchRequests.requestsBeingFetched, action.payload.params.requestId],
        },
      };
    case Actions.PATIENTS_SEARCH_REQUESTS_SUCCESS:
    case Actions.USER_SEARCH_REQUESTS_SUCCESS: {
      const { requests, SavedCordSets, SavedDonorSets } = action.payload.data.entities;
      return {
        ...state,
        searchRequests: {
          ...state.searchRequests,
          requests: {
            ...state.searchRequests.requests,
            ...requests,
          },
          isFetching: false,
          hasErrored: false,
        },
        savedDonorSets: {
          ...state.savedDonorSets,
          ...(SavedDonorSets ? convertSavedDonorSetsFromApi(SavedDonorSets) : null),
        },
        savedCordSets: {
          ...state.savedCordSets,
          ...(SavedCordSets ? convertSavedCordSetsFromApi(SavedCordSets) : null),
        },
      };
    }

    case DonorSearchResultsActions.ADULT_SEARCH_RESULTS_DONOR_SETS_SUCCESS:
    case DonorSearchResultsActions.ADULT_SEARCH_RESULTS_DONOR_SET_CREATE_SUCCESS:
    case DonorSearchResultsActions.ADULT_SEARCH_RESULTS_DONOR_SET_UPDATE_SUCCESS: {
      const { SavedDonorSets } = action.payload.data.entities;
      return {
        ...state,
        savedDonorSets: {
          ...state.savedDonorSets,
          ...(SavedDonorSets ? convertSavedDonorSetsFromApi(SavedDonorSets) : null),
        },
      };
    }
    case DonorSearchResultsActions.CORD_SEARCH_RESULTS_CORD_SETS_SUCCESS:
    case DonorSearchResultsActions.CORD_SEARCH_RESULTS_CORD_SET_CREATE_SUCCESS:
    case DonorSearchResultsActions.CORD_SEARCH_RESULTS_CORD_SET_UPDATE_SUCCESS: {
      const { SavedCordSets } = action.payload.data.entities;
      return {
        ...state,
        savedCordSets: {
          ...state.savedCordSets,
          ...(SavedCordSets ? convertSavedCordSetsFromApi(SavedCordSets) : null),
        },
      };
    }
    case Actions.FETCH_SINGLE_SEARCH_REQUEST_SUCCESS: {
      const searchRequest = _.values(action.payload.data.entities.requests)[0];
      const otherRequests = _.pickBy(state.searchRequests.requests, (r) => r.Id !== searchRequest.Id);
      return {
        ...state,
        searchRequests: {
          ...state.searchRequests,
          requests: {
            ...otherRequests,
            [searchRequest.Id]: searchRequest,
          },
          requestsBeingFetched: state.searchRequests.requestsBeingFetched.filter((r) => r !== searchRequest.Id),
        },
      };
    }

    default:
      return state;
  }
};

export const ReportStatuses = {
  Draft: 'Draft',
  AwaitingAuthorisation: 'AwaitingAuthorisation',
  Authorised: 'Authorised',
  Sent: 'Sent',
  Deleted: 'Deleted',
};
type Values<T> = T[keyof T];
export type ReportStatus = Values<typeof ReportStatuses>;

export const internationalReportStatuses = {
  isReporting: 'isReporting',
  isReported: 'isReported',
  isPending: 'isPending',
};

export type ReportType = {
  apiString: string;
  displayString: string;
};

const CordSearch: ReportType = {
  apiString: 'CordSearch',
  displayString: 'Cord Search',
};
const InitialSearch: ReportType = {
  apiString: 'InitialSearch',
  displayString: 'Initial Search',
};
const ReSearch: ReportType = {
  apiString: 'ReSearch',
  displayString: 'Re-search',
};

export const ReportTypes: { [string: string]: ReportType } = {
  CordSearch,
  InitialSearch,
  ReSearch,
};

export const InternationalReportTypes: { [string: string]: ReportType } = {
  InitialSearch,
  ReSearch,
};

import React from 'react';

type Props = {
  id: string;
  title: string;
  maxDonorsValue?: number;
  updateMaxDonors: (arg0: number) => void;
  // This default will be applied in the backend, not the frontend - we just need to display it to the users here
  defaultMaxDonors?: number;
};

type MaxDonorInput = { value: number } & HTMLInputElement;

export default class MaxDonorField extends React.Component<Props> {
  static defaultProps = {
    defaultMaxDonors: undefined,
    maxDonorsValue: undefined,
  };

  render() {
    const { id, title, maxDonorsValue, defaultMaxDonors } = this.props;
    return (
      <input
        name={id}
        type="number"
        value={maxDonorsValue}
        placeholder={`${title}${defaultMaxDonors ? ` (${defaultMaxDonors})` : ''}`}
        onChange={this.updateMaxDonorValue}
      />
    );
  }

  updateMaxDonorValue = (event: React.ChangeEvent<MaxDonorInput>) => {
    const { updateMaxDonors } = this.props;
    updateMaxDonors(event.currentTarget.value);
  };
}

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { capitalise } from '../../../core/helpers/capitalise';
import { isNumeric } from '../../../core/helpers/isNumeric';
import { Button, Error } from '../../../core';
import { getAdultDonor, getPatient } from '../../redux/actions';
import type { SubjectType } from '../../types';

type Props = {
  isLoading: boolean;
  subjectLinkType: SubjectType;
};

export const LinkedSubjectSearch = ({ isLoading, subjectLinkType }: Props) => {
  const [subjectId, setSubjectId] = useState('');
  const [inputError, setInputError] = useState<string | undefined>(undefined);

  const dispatch = useDispatch();

  const findSubject = () => {
    setInputError(undefined);
    if (isNumeric(subjectId)) {
      if (subjectLinkType === 'patient') {
        return dispatch(getPatient(subjectId));
      }
      return dispatch(getAdultDonor(subjectId));
    }
    return setInputError(`${capitalise(subjectLinkType)} ID must be an integer value.`);
  };

  return (
    <>
      <h4 className="header subheader">Find {subjectLinkType}</h4>
      <input
        name="subject-id"
        onChange={(e) => {
          setSubjectId(e.target.value);
        }}
        placeholder={`${capitalise(subjectLinkType)} ID`}
        type="text"
        value={subjectId}
      />
      <div className="btn-group">
        <Button onClick={findSubject} text="Search" disabled={!subjectId || isLoading} loading={isLoading} />
      </div>
      <Error error={inputError} />
    </>
  );
};

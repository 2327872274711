import React from 'react';
import SearchFilter from './SearchFilter';
import SearchForm from './SearchForm';

const PatientSearch = () => (
  <>
    <h2 className="border-bottom-solid" id="patient-search">
      Patient Search
    </h2>
    <SearchFilter />
    <SearchForm />
  </>
);

export default PatientSearch;

import {
  ErrorAction as ApiErrorAction,
  get,
  NormalizedPayload,
  RequestAction as ApiRequestAction,
  SuccessAction as ApiSuccessAction,
} from '@an/nova-frontend-rest-client';
import { searchRequest, searchRequestList } from '../../core';
import Config from '../../config';

import type { Dispatch } from '../../core/types';
import type { ReduxState } from '../../rootReducer';
import type { ApiSavedCordSet, ApiSavedDonorSet, ApiSearchRequest, ApiSearchRequestSummary } from '../types/api';

const PATIENTS_SEARCH_REQUESTS: 'patientSearchRequests' = 'patientSearchRequests';
const PATIENTS_SEARCH_REQUESTS_ERROR: 'patientSearchRequests/error' = 'patientSearchRequests/error';
const PATIENTS_SEARCH_REQUESTS_REQUEST: 'patientSearchRequests/request' = 'patientSearchRequests/request';
const PATIENTS_SEARCH_REQUESTS_SUCCESS: 'patientSearchRequests/success' = 'patientSearchRequests/success';
const FETCH_SINGLE_SEARCH_REQUEST: 'fetchSingleSearchRequest' = 'fetchSingleSearchRequest';
const FETCH_SINGLE_SEARCH_REQUEST_REQUEST: 'fetchSingleSearchRequest/request' = 'fetchSingleSearchRequest/request';
const FETCH_SINGLE_SEARCH_REQUEST_SUCCESS: 'fetchSingleSearchRequest/success' = 'fetchSingleSearchRequest/success';
const FETCH_SINGLE_SEARCH_REQUEST_ERROR: 'fetchSingleSearchRequest/error' = 'fetchSingleSearchRequest/error';
const SEARCH_REQUESTS_SELECTED_USER_UPDATE: 'searchRequests/selectedUser/update' = 'searchRequests/selectedUser/update';
const USER_SEARCH_REQUESTS: 'userSearchRequests' = 'userSearchRequests';
const USER_SEARCH_REQUESTS_ERROR: 'userSearchRequests/error' = 'userSearchRequests/error';
const USER_SEARCH_REQUESTS_REQUEST: 'userSearchRequests/request' = 'userSearchRequests/request';
const USER_SEARCH_REQUESTS_SUCCESS: 'userSearchRequests/success' = 'userSearchRequests/success';

export const Actions = {
  PATIENTS_SEARCH_REQUESTS_ERROR,
  PATIENTS_SEARCH_REQUESTS_REQUEST,
  PATIENTS_SEARCH_REQUESTS_SUCCESS,
  SEARCH_REQUESTS_SELECTED_USER_UPDATE,
  USER_SEARCH_REQUESTS_ERROR,
  USER_SEARCH_REQUESTS_REQUEST,
  USER_SEARCH_REQUESTS_SUCCESS,
  FETCH_SINGLE_SEARCH_REQUEST_REQUEST,
  FETCH_SINGLE_SEARCH_REQUEST_SUCCESS,
  FETCH_SINGLE_SEARCH_REQUEST_ERROR,
};

type CordSetEntities = { SavedCordSets: { [string: string]: ApiSavedCordSet } };
type DonorSetEntities = { SavedDonorSets: { [string: string]: ApiSavedDonorSet } };
type SearchRequestEntities = { requests: { [string: string]: ApiSearchRequest } };
type NormalizedSearchRequestList = NormalizedPayload<
  string[],
  CordSetEntities & DonorSetEntities & SearchRequestEntities
>;

type PatientIdParam = { patientId: string };
type RequestIdParam = { requestId: string };
type UsernameParam = { username: string };

export type PatientSearchRequestsErrorAction = ApiErrorAction<typeof PATIENTS_SEARCH_REQUESTS_ERROR, PatientIdParam>;
export type PatientSearchRequestsRequestAction = ApiRequestAction<
  typeof PATIENTS_SEARCH_REQUESTS_REQUEST,
  PatientIdParam
>;
export type PatientSearchRequestsSuccessAction = ApiSuccessAction<
  typeof PATIENTS_SEARCH_REQUESTS_SUCCESS,
  PatientIdParam,
  NormalizedSearchRequestList
>;
export type SearchRequestsUpdateSelectedUserAction = {
  type: typeof SEARCH_REQUESTS_SELECTED_USER_UPDATE;
  payload: string;
};
export type UserSearchRequestsErrorAction = ApiErrorAction<typeof USER_SEARCH_REQUESTS_ERROR, UsernameParam>;
export type UserSearchRequestsRequestAction = ApiRequestAction<typeof USER_SEARCH_REQUESTS_REQUEST, UsernameParam>;
export type UserSearchRequestsSuccessAction = ApiSuccessAction<
  typeof USER_SEARCH_REQUESTS_SUCCESS,
  UsernameParam,
  NormalizedSearchRequestList
>;
export type DonorMatchSearchRequestsAction =
  | PatientSearchRequestsErrorAction
  | PatientSearchRequestsRequestAction
  | PatientSearchRequestsSuccessAction
  | SearchRequestsUpdateSelectedUserAction
  | UserSearchRequestsErrorAction
  | UserSearchRequestsRequestAction
  | UserSearchRequestsSuccessAction;

export type FetchSingleSearchRequestSuccessAction = ApiSuccessAction<
  typeof FETCH_SINGLE_SEARCH_REQUEST_SUCCESS,
  unknown,
  NormalizedSearchRequestList
>;

export type FetchSingleSearchRequestRequestAction = ApiRequestAction<
  typeof FETCH_SINGLE_SEARCH_REQUEST_REQUEST,
  RequestIdParam
>;
export type FetchSingleSearchRequestErrorAction = ApiErrorAction<
  typeof FETCH_SINGLE_SEARCH_REQUEST_ERROR,
  RequestIdParam
>;

const updateSelectedUser = (username: string): SearchRequestsUpdateSelectedUserAction => ({
  type: SEARCH_REQUESTS_SELECTED_USER_UPDATE,
  payload: username,
});

const fetchUserRequests = (username: string) =>
  get<ReduxState, NormalizedSearchRequestList>(
    `${Config().apiBaseUrl}search-requests/user/${username}`,
    USER_SEARCH_REQUESTS,
    { username },
    searchRequestList
  );

export const getUserSearchRequests = (username: string) => (dispatch: Dispatch<any>) => {
  dispatch(updateSelectedUser(username));
  if (username) {
    dispatch(fetchUserRequests(username));
  }
};

export const getPatientSearchRequest = (patientId: string) =>
  get<ReduxState, NormalizedSearchRequestList>(
    `${Config().apiBaseUrl}search-requests/patient/${patientId}`,
    PATIENTS_SEARCH_REQUESTS,
    { patientId },
    searchRequestList
  );

export const getSingleSearchRequest = (requestId: string) =>
  get<ReduxState, ApiSearchRequestSummary>(
    `${Config().apiBaseUrl}search-requests/${requestId}`,
    FETCH_SINGLE_SEARCH_REQUEST,
    { requestId },
    searchRequest
  );

import _ from 'lodash';

const INITIATED = 'Initiated';
const RESULTS_AVAILABLE = 'ResultsAvailable';
const FAILED_TO_SEND = 'FailedToSend';
const REJECTED = 'Rejected';
const CLOSED = 'Closed';

export const searchResultStatuses = {
  resultsAvailable: {
    value: RESULTS_AVAILABLE,
    displayString: 'Results Available',
    orderInTable: 1,
  },
  initiated: {
    value: INITIATED,
    displayString: 'Initiated',
    orderInTable: 2,
  },
  rejected: {
    value: REJECTED,
    displayString: 'Rejected',
    orderInTable: 3,
  },
  failedToSend: {
    value: FAILED_TO_SEND,
    displayString: 'Failed To Send',
    orderInTable: 4,
  },
  closed: {
    value: CLOSED,
    displayString: 'Closed',
    orderInTable: 5,
  },
};

export const searchResultStatusDisplayString = (value: string): string | undefined =>
  _.find(searchResultStatuses, (status) => status.value === value)?.displayString;

export const searchResultStatusOrderInTable = (value: string): number | undefined =>
  _.find(searchResultStatuses, (status) => status.value === value)?.orderInTable;

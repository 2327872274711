export const infectiousDiseaseMarkerVariants = {
  bloodGroupRhesus: {
    name: 'Blood group and RhD',
    abbreviation: 'ABO',
  },
  cmv: {
    name: 'CMV status',
    abbreviation: 'ANTI CMV',
  },
  toxoplasmosis: {
    name: 'Toxoplasmosis',
    abbreviation: 'TOXO',
  },
  ebv: {
    name: 'EBV',
    abbreviation: 'EBV',
  },
  hivStatus: {
    name: 'HIV status',
    abbreviation: 'ANTI HIV 1/2',
  },
  hivP24: {
    name: 'HIV p24 antigen',
    abbreviation: 'HIV P24',
  },
  hepatitisBSurfaceAntigen: {
    name: 'Hepatitis B surface antigen',
    abbreviation: 'HBS AG',
  },
  antibodyHepatitisBSurfaceAntigen: {
    name: 'Antibody to Hepatitis B surface antigen',
    abbreviation: 'ANTI HBS',
  },
  antibodyHepatitisBCoreAntigen: {
    name: 'Antibody to Hepatitis B core antigen',
    abbreviation: 'ANTI HBC',
  },
  antibodyHepatitisC: {
    name: 'Antibody to Hepatitis C virus',
    abbreviation: 'ANTI HCV',
  },
  luesStatus: {
    name: 'Lues status',
    abbreviation: 'TPHA',
  },
  altStatus: {
    name: 'ALT (alanine aminotransferase)',
    abbreviation: 'ALT',
  },
  hltvAntibody: {
    name: 'Antibody to HTLV1.V2',
    abbreviation: 'ANTI HTLV',
  },
};

export type InfectiousDiseaseMarkerVariants = keyof typeof infectiousDiseaseMarkerVariants;

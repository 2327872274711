import React from 'react';
import _ from 'lodash';
import '../../internationalDonorsTableStyling.scss';
import type { SavedResultSet } from '../../../../donorMatchSearchRequests/types';
import { ApiSearchRequest } from '../../../../donorMatchSearchRequests/types/api';
import { convertSearchTypeFromApi, convertSearchTypeToNumeric } from '../../../../core/constants/searchTypes';

type Props = {
  selectedDonorSets: SavedResultSet[];
  patientSearchRequests?: ApiSearchRequest[];
  isInternalSearch?: string;
};

export const SelectedDonorSetsTable = ({ selectedDonorSets, patientSearchRequests, isInternalSearch }: Props) => {
  const getInternalSearchType = (requestId: string) => {
    const request = _.find(patientSearchRequests, (x) => x.Id === requestId);
    if (request) {
      // this table uses only Adult search types
      const searchType = request.AdultSearchType;
      const convertedSearchType = convertSearchTypeFromApi(searchType);
      return convertSearchTypeToNumeric(convertedSearchType);
    }
    return '';
  };
  return (
    <div style={{ marginTop: '40px' }}>
      <h2 className="border-bottom-solid">Selected donor sets</h2>
      <div className="selectedDonorSetsTable">
        <table>
          <thead>
            <tr>
              {isInternalSearch && <td className="searchTypeColumn">Search Type</td>}
              <td className="filterSummaryColumn">Filter summary</td>
              <td className="notesColumn">Notes</td>
              <td className="donorsColumn">Number of donors</td>
            </tr>
          </thead>
          <tbody>
            {selectedDonorSets.map((set: SavedResultSet) => (
              <tr key={set.id}>
                {isInternalSearch && <td className="searchTypeColumn">{getInternalSearchType(set.requestId)}</td>}
                <td className="filterSummaryColumn">{set.appliedFilters.filterSummary}</td>
                <td className="notesColumn">{set.notes || '-'}</td>
                <td className="donorsColumn">{set.selectedResults.length}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

SelectedDonorSetsTable.defaultProps = {
  patientSearchRequests: undefined,
  isInternalSearch: undefined,
};

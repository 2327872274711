import moment, { Moment } from 'moment';
import type { PatientInfo } from '../../types';
import type { ApiPatientInfo } from '../types/api';
import { convertAntigen } from '../../../core';
import { InstitutionAddress } from '../../../core/types';

const momentOrNull = (value: moment.MomentInput): Moment | null | undefined => (value ? moment(value) : null);

const formatInvoiceAddress = (invoiceAddress: InstitutionAddress) => ({
  Id: invoiceAddress.Id,
  InstitutionId: invoiceAddress.InstitutionId,
  AddressType: invoiceAddress.AddressType,
  Name: invoiceAddress.Name,
  Address1: invoiceAddress.Address1,
  Address2: invoiceAddress.Address2,
  Address3: invoiceAddress.Address3,
  Town: invoiceAddress.Town,
  County: invoiceAddress.County,
  Postcode: invoiceAddress.Postcode,
  CountryType: invoiceAddress.CountryType,
});

export const convertPatientInfoFromApi = (apiPatientData: ApiPatientInfo): PatientInfo => ({
  id: apiPatientData.Id,
  patientType: apiPatientData.Type,
  isPrivatePatient: apiPatientData.IsPrivatePatient,
  status: apiPatientData.Status,
  dateRegistered: momentOrNull(apiPatientData.DateRegistered),
  closureReasonType: apiPatientData.ClosureReason,
  closureDate: momentOrNull(apiPatientData.DateClosed),
  causeOfDeath: apiPatientData.CauseOfDeath,
  deathDate: momentOrNull(apiPatientData.DateOfDeath),
  firstName: apiPatientData.FirstName,
  secondName: apiPatientData.MiddleNames,
  surname: apiPatientData.Surname,
  name: `${apiPatientData.FirstName} ${apiPatientData.Surname}`,
  dateOfBirth: apiPatientData.DateOfBirth ? moment(apiPatientData.DateOfBirth) : null,
  age: moment().diff(apiPatientData.DateOfBirth, 'years'),
  weight: apiPatientData.Weight,
  gender: apiPatientData.Gender,
  ethnicity: apiPatientData.Ethnicity,
  diagnosis: apiPatientData.Diagnosis,
  diagnosisDate: momentOrNull(apiPatientData.DateOfDiagnosis),
  cmv: apiPatientData.Cmv,
  cmvTestedDate: momentOrNull(apiPatientData.DateCmvTested),
  abo: apiPatientData.BloodGroup,
  rhd: apiPatientData.RhD,
  hospital: apiPatientData.Hospital,
  hospitalName: apiPatientData.HospitalName,
  hospitalAssignedPatientId: apiPatientData.HospitalNumber,
  referringDoctor: apiPatientData.ReferringDoctor,
  invoicee: apiPatientData.Invoicee,
  invoiceAddress: apiPatientData.InvoiceAddress ? formatInvoiceAddress(apiPatientData.InvoiceAddress) : undefined,
  originatingRegistryId: apiPatientData.OriginatingRegistry,
  originatingRegistryName: apiPatientData.OriginatingRegistryName,
  originatingRegistryPatientId: apiPatientData.OriginatingRegistryPatientId,
  laboratoryId: apiPatientData.Laboratory,
  externalLaboratoryName: apiPatientData.ExternalLaboratoryName,
  foreignSearchLocation: apiPatientData.ForeignSearchLocation,
  cordSearchLocation: apiPatientData.CordSearchLocation,
  temporaryFileLocation: apiPatientData.TemporaryFileLocation,
  notes: apiPatientData.Notes,
  hlaA1: convertAntigen(apiPatientData.Hla.A_1),
  hlaA2: convertAntigen(apiPatientData.Hla.A_2),
  hlaB1: convertAntigen(apiPatientData.Hla.B_1),
  hlaB2: convertAntigen(apiPatientData.Hla.B_2),
  hlaC1: convertAntigen(apiPatientData.Hla.C_1),
  hlaC2: convertAntigen(apiPatientData.Hla.C_2),
  drB11: convertAntigen(apiPatientData.Hla.Drb1_1),
  drB12: convertAntigen(apiPatientData.Hla.Drb1_2),
  drB31: convertAntigen(apiPatientData.Hla.Drb3_1),
  drB32: convertAntigen(apiPatientData.Hla.Drb3_2),
  drB41: convertAntigen(apiPatientData.Hla.Drb4_1),
  drB42: convertAntigen(apiPatientData.Hla.Drb4_2),
  drB51: convertAntigen(apiPatientData.Hla.Drb5_1),
  drB52: convertAntigen(apiPatientData.Hla.Drb5_2),
  dqA11: convertAntigen(apiPatientData.Hla.Dqa1_1),
  dqA12: convertAntigen(apiPatientData.Hla.Dqa1_2),
  dqB11: convertAntigen(apiPatientData.Hla.Dqb1_1),
  dqB12: convertAntigen(apiPatientData.Hla.Dqb1_2),
  dpA11: convertAntigen(apiPatientData.Hla.Dpa1_1),
  dpA12: convertAntigen(apiPatientData.Hla.Dpa1_2),
  dpB11: convertAntigen(apiPatientData.Hla.Dpb1_1),
  dpB12: convertAntigen(apiPatientData.Hla.Dpb1_2),
});

import React from 'react';
import { XYPlot, XAxis, YAxis, VerticalGridLines, HorizontalGridLines, VerticalRectSeries } from 'react-vis';
import _ from 'lodash';
import type { RankingComparisons } from './types';

type Props = {
  rankingComparisons: RankingComparisons[];
};

const BAR_WIDTH_ADJUSTMENT = 0.5;

function getChartData(rankingComparisons: RankingComparisons[]): any[] {
  const groupedComparisons: { [string: string]: RankingComparisons[] } = _.groupBy(
    rankingComparisons,
    (comparison: RankingComparisons) => comparison.rankDifference
  );

  return Object.entries(groupedComparisons).map(
    // $FlowExpectedError: Flow doesn't like Object.entries
    ([rankDifference, comparisons]: [string, RankingComparisons[]]) => ({
      x: Number(rankDifference) - BAR_WIDTH_ADJUSTMENT,
      x0: Number(rankDifference) + BAR_WIDTH_ADJUSTMENT,
      y: comparisons.length,
    })
  );
}

export default function AlgorithmRankingComparisonChart({ rankingComparisons }: Props) {
  const chartData = getChartData(rankingComparisons);

  const xValues = chartData.map((data: any) => data.x);
  const minXDomain = Math.min(...xValues);

  const x0Values = chartData.map((data: any) => data.x0);
  const maxXDomain = Math.max(...x0Values);

  const yValues = chartData.map((data: any) => data.y);
  const maxYDomain = Math.max(...yValues);

  return (
    <XYPlot xDomain={[minXDomain, maxXDomain]} yDomain={[0, maxYDomain]} width={1000} height={300}>
      <VerticalGridLines />
      <HorizontalGridLines />
      <XAxis />
      <YAxis />
      <VerticalRectSeries data={chartData} style={{ stroke: '#000' }} />
    </XYPlot>
  );
}

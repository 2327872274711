import React from 'react';
import Textarea from '../../../core/components/Textarea';

type Props = {
  input: {
    name: string;
    onChange: (e: React.SyntheticEvent<HTMLTextAreaElement>) => void;
    value: string;
  };
  disabled: boolean;
};

const SOLAR_HLA_NOTE_CHARACTER_LIMIT = 2000;

export const HlaNotesTextarea = ({ input, ...props }: Props) => {
  const inputValue = input?.value || '';
  const charactersRemaining = SOLAR_HLA_NOTE_CHARACTER_LIMIT - inputValue.length;

  const characterString = charactersRemaining === 1 ? 'character' : 'characters';

  return (
    <Textarea
      {...props}
      input={input}
      label="HLA Notes"
      subLabel={` - (${charactersRemaining} ${characterString} remaining)`}
    />
  );
};

import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, AnyAction, Dispatch as ReduxDispatch } from 'redux';
import * as actions from '../redux/actions';
import { CurrentReportSelectors } from '../../core/redux/selectors';
import type { ReduxState } from '../../../rootReducer';

type DispatchProps = {
  updatePrivateNotes: typeof actions.updatePrivateNotes;
};
type StateProps = {
  privateNotes: string;
  isReportReadOnly: boolean;
};
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

const mapStateToProps = (state: ReduxState): StateProps => ({
  privateNotes: CurrentReportSelectors.getPrivateNotes(state),
  isReportReadOnly: CurrentReportSelectors.isReportReadOnly(state),
});

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>): DispatchProps => ({
  updatePrivateNotes: bindActionCreators(actions.updatePrivateNotes, dispatch),
});

type State = {
  notes: string;
};

class PrivateNotes extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    const { privateNotes } = this.props;
    this.state = {
      notes: privateNotes,
    };
  }

  componentDidUpdate(prevProps: Props) {
    const { privateNotes } = this.props;
    if (privateNotes !== prevProps.privateNotes) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ notes: privateNotes });
    }
  }

  render() {
    const { isReportReadOnly } = this.props;
    const { notes } = this.state;
    return (
      <div>
        <h2 className="border-bottom-solid">Report Notes</h2>
        <textarea
          name="privateNotes"
          rows={5}
          placeholder="Add Private Report Notes"
          onChange={this.handleNoteChanges}
          onBlur={this.saveNotesToState}
          value={notes}
          readOnly={isReportReadOnly}
        />
      </div>
    );
  }

  saveNotesToState = () => {
    const { updatePrivateNotes } = this.props;
    const { notes } = this.state;
    updatePrivateNotes(notes);
  };

  handleNoteChanges = (event: React.SyntheticEvent<HTMLTextAreaElement>) => {
    const notes = event.currentTarget.value;
    this.setState({ notes });
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(PrivateNotes);

const lociNames = [
  {
    title: 'HLA-A',
    type: 'A',
    primary: true,
    antigenInfoLocusKeys: ['hlaA1', 'hlaA2'],
  },
  {
    title: 'HLA-B',
    type: 'B',
    primary: true,
    antigenInfoLocusKeys: ['hlaB1', 'hlaB2'],
  },
  {
    title: 'HLA-C',
    type: 'C',
    primary: true,
    antigenInfoLocusKeys: ['hlaC1', 'hlaC2'],
  },
  {
    title: 'HLA-DRB1',
    type: 'Drb1',
    primary: true,
    antigenInfoLocusKeys: ['drB11', 'drB12'],
  },
  {
    title: 'HLA-DQB1',
    type: 'Dqb1',
    primary: true,
    antigenInfoLocusKeys: ['dqB11', 'dqB12'],
  },
  {
    title: 'HLA-DPB1',
    type: 'Dpb1',
    primary: true,
    antigenInfoLocusKeys: ['dpB11', 'dpB12'],
  },
  {
    title: 'HLA-DRB3',
    type: 'Drb3',
    primary: false,
    antigenInfoLocusKeys: ['drB31', 'drB32'],
  },
  {
    title: 'HLA-DRB4',
    type: 'Drb4',
    primary: false,
    antigenInfoLocusKeys: ['drB41', 'drB42'],
  },
  {
    title: 'HLA-DRB5',
    type: 'Drb5',
    primary: false,
    antigenInfoLocusKeys: ['drB51', 'drB52'],
  },
  {
    title: 'HLA-DQA1',
    type: 'Dqa1',
    primary: false,
    antigenInfoLocusKeys: ['dqA11', 'dqA12'],
  },
  {
    title: 'HLA-DPA1',
    type: 'Dpa1',
    primary: false,
    antigenInfoLocusKeys: ['dpA11', 'dpA12'],
  },
];

export default lociNames;

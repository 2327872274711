import React, { PureComponent } from 'react';
import CountryCheckbox from './CountryCheckbox';
import type { CustomCountry } from '../../../types';
import isWhiteSpaceOrEmpty from '../../../../core/helpers/isWhiteSpaceOrEmpty';

type Props = {
  country: CustomCountry;
  readOnly: boolean;
  onCountryChange: (country: CustomCountry) => void;
};
type State = {
  countryName: string;
};

class CustomCountryRow extends PureComponent<Props, State> {
  state = {
    // eslint-disable-next-line react/destructuring-assignment
    countryName: this.props.country.name,
  };

  render() {
    const { country, readOnly, onCountryChange } = this.props;

    const { countryName } = this.state;

    return (
      <tr
        key={country.name}
        className={!country.isReporting && !country.isPending && !country.isReported ? 'disabled' : ''}
      >
        <td>
          <input
            className={isWhiteSpaceOrEmpty(country.name) ? 'error' : undefined}
            disabled={readOnly}
            onBlur={this.saveCountryNameChange}
            onChange={this.handleCountryNameChange}
            onKeyPress={this.handleKeyPress}
            placeholder="Add new country"
            type="text"
            value={countryName}
          />
        </td>
        <td>
          <CountryCheckbox
            checked={country.isReporting}
            disabled={readOnly}
            name="isReporting"
            id={country.id}
            onChange={(value) => onCountryChange({ ...country, isReporting: value || false })}
          />
        </td>
        <td>
          <CountryCheckbox
            checked={country.isPending || false}
            disabled={readOnly}
            name="isPending"
            id={country.id}
            onChange={(value) => onCountryChange({ ...country, isPending: value || false })}
          />
        </td>
        <td>
          <CountryCheckbox
            checked={country.isReported}
            disabled={readOnly}
            name="isReported"
            id={country.id}
            onChange={(value) => onCountryChange({ ...country, isReported: value || false })}
          />
        </td>
        {!readOnly && <td />}
      </tr>
    );
  }

  handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.saveCountryNameChange();
    }
  };

  handleCountryNameChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    this.setState({
      countryName: target.value,
    });
  };

  saveCountryNameChange = () => {
    const { onCountryChange, country } = this.props;
    const { countryName } = this.state;

    onCountryChange({
      ...country,
      name: countryName,
    });
  };
}

export default CustomCountryRow;

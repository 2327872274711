export const idmRequestChoices = [
  [
    {
      label: 'Blood group and RhD',
      id: 'bloodGroupAndRhesus',
    },
    {
      label: 'CMV status',
      id: 'cmvStatus',
    },
  ],
  [
    {
      label: 'Toxoplasmosis',
      id: 'toxoplasmosis',
    },
    {
      label: 'EBV',
      id: 'ebv',
    },
  ],
  [
    {
      label: 'HIV status',
      id: 'hivStatus',
    },
    {
      label: 'HIV p24 antigen',
      id: 'hivP24Antigen',
    },
  ],
  [
    {
      label: 'Hepatitis B surface antigen',
      id: 'hepBSurfaceAntigen',
    },
    {
      label: 'Antibody to Hepatitis B surface antigen',
      id: 'antibodyToHepBSurfaceAntigen',
    },
  ],
  [
    {
      label: 'Antibody to Hepatitis B core antigen',
      id: 'antibodyToHepBCoreAntigen',
    },
    {
      label: 'Antibody to Hepatitis C virus',
      id: 'antibodyToHepCVirus',
    },
  ],
  [
    {
      label: 'Lues status',
      id: 'luesStatus',
    },
    {
      label: 'ALT (alanine aminotransferase)',
      id: 'alt',
    },
  ],
  [
    {
      label: 'Antibody to HTLV1.V2',
      id: 'antibodyToHTLV1V2',
    },
  ],
];

import React from 'react';
import { CellMeasurer } from 'react-virtualized';
import { CellMeasurerCacheInterface, MeasuredCellParent } from 'react-virtualized/dist/es/CellMeasurer';
import { joinUniqueArrayItems } from '../../../../../core/helpers/arrayHelper';
import { ApiSearchType, convertSearchTypeFromApiToNumeric } from '../../../../../core/constants/searchTypes';

type Props = {
  cache: CellMeasurerCacheInterface;
  data: ApiSearchType[];
  dataKey: string;
  parent: MeasuredCellParent;
  rowIndex: number;
};

export default ({ cache, data, dataKey, parent, rowIndex }: Props) => {
  const readableData = data.map((searchType) => convertSearchTypeFromApiToNumeric(searchType));
  const searchTypesList = joinUniqueArrayItems(readableData);

  return (
    <CellMeasurer cache={cache} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
      <div
        style={{
          whiteSpace: 'normal',
        }}
      >
        {searchTypesList}
      </div>
    </CellMeasurer>
  );
};

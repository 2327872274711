import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch as ReduxDispatch } from 'redux';
import Selectors from '../../redux/selectors';
import * as actions from '../../redux/actions';
import { styles } from '../../style';
import Checkbox, { CheckboxEvent } from '../../../../core/components/Checkbox';
import { FeatureFlag } from '../../../../featureFlags';

import type { PatientDashboardReducerState } from '../../redux/reducer';
import type { ReduxState } from '../../../../rootReducer';
import UserSelectors from '../../../../currentUser/redux/selectors';

type OwnProps = {
  patientId: string;
};
type StateProps = {
  searchRequestData: PatientDashboardReducerState;
  isSearchAuthoriser: boolean;
};
type Props = PropsFromRedux & OwnProps & StateProps;

const mapStateToProps = (state: ReduxState): StateProps => ({
  searchRequestData: Selectors.getSearchRequestData(state),
  isSearchAuthoriser: UserSelectors.isSearchAuthoriser(state),
});

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>) => ({
  toggleIsHlaAdjusted: bindActionCreators(actions.toggleIsHlaAdjusted, dispatch),
  updateIncludeAlignedRegistry: bindActionCreators(actions.updateIncludeAlignedRegistry, dispatch),
  updateIncludeDpb1InScoring: bindActionCreators(actions.updateIncludeDpb1InScoring, dispatch),
  updateSearchAlgorithm: bindActionCreators(actions.updateSearchAlgorithm, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class SearchPreferences extends React.Component<Props> {
  render() {
    const { patientId, searchRequestData, isSearchAuthoriser } = this.props;
    return (
      <div id="search-preferences">
        <h3 style={styles.searchTitle}>Search Preferences</h3>
        <div style={{ display: 'flex' }}>
          <Checkbox
            label="Include aligned registry donors"
            id="includeAlignedRegistries"
            checked={searchRequestData.includeAlignedRegistries}
            onChange={this.handleIncludeAlignedRegistriesClick}
          />
          <Checkbox
            label="Use Adjusted HLA"
            checked={searchRequestData.isHlaAdjusted}
            id="useAdjustedGeneticData"
            onChange={this.handleUseAdjustedHlaClick}
            disabled={!searchRequestData.adjustedHlaPatientData || !searchRequestData.adjustedHlaPatientData[patientId]}
          />
        </div>
        {isSearchAuthoriser && (
          <FeatureFlag
            flag="showOptionToIncludeDPB1InDonorRanking"
            render={() => (
              <Checkbox
                label="Include DPB1 in Donor Ranking"
                checked={searchRequestData.includeDpb1InScoring}
                id="includeDpb1InScoring"
                onChange={this.handleIncludeDpb1ScoringChange}
              />
            )}
          />
        )}
      </div>
    );
  }

  handleUseAdjustedHlaCheckbox = (adjustedHla: boolean) => {
    const { toggleIsHlaAdjusted } = this.props;
    return toggleIsHlaAdjusted(adjustedHla);
  };

  handleUseAdjustedHlaClick = (event: React.SyntheticEvent<CheckboxEvent>) => {
    const { searchRequestData, updateSearchAlgorithm } = this.props;
    this.handleUseAdjustedHlaCheckbox(event.currentTarget.checked);
    if (!searchRequestData.isHlaAdjusted) {
      updateSearchAlgorithm([]);
    }
  };

  handleIncludeAlignedRegistriesClick = (event: React.SyntheticEvent<CheckboxEvent>) => {
    const { updateIncludeAlignedRegistry } = this.props;
    updateIncludeAlignedRegistry(event.currentTarget.checked);
  };

  handleIncludeDpb1ScoringChange = (event: React.SyntheticEvent<CheckboxEvent>) => {
    const { updateIncludeDpb1InScoring } = this.props;
    updateIncludeDpb1InScoring(event.currentTarget.checked);
  };
}

export default connector(SearchPreferences);

import React, { Component, CSSProperties } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { AutoSizer, Column, Table } from 'react-virtualized';
import { connect, ConnectedProps } from 'react-redux';
import cn from 'classnames';
import ReactTooltip from 'react-tooltip';
import { FeatureFlag } from '../../../../../featureFlags';
import { NoResultsFound, donorTypes, reportTypes } from '../../../../../core';
import { PatientSelectors } from '../../../../index';
import { isOddNumber } from '../../../../../core/helpers/arrayHelper';
import { formatDate } from '../../../../../core/helpers/dateHelpers';
import {
  searchResultStatuses as searchStatuses,
  searchResultStatusDisplayString,
  searchResultStatusOrderInTable,
} from '../../../constants/searchResultStatuses';
import type { SearchResultStatus } from '../../../types';
import type { ApiSearchRequest, ApiRejectionDetails } from '../../../../../donorMatchSearchRequests/types/api';
import type { ReduxState } from '../../../../../rootReducer';
import { rebuildTooltip } from '../../../../../core/helpers/rebuildTooltip';

type OwnProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  patientId: string; // used in `mapStateToProps`
};
type StateProps = {
  searchRequests?: ApiSearchRequest[];
};
type Props = PropsFromRedux & OwnProps & StateProps;
type State = {
  selectedRowIndexes: number[];
  selectedRegistryIds: number[];
};
type SearchRequestTableItem = {
  externalRegistryName: string;
  externalRegistryId: string;
  id: string;
  rejectionDetails: ApiRejectionDetails[];
  resultCount?: number;
  resultSetId: string;
  runBy: string;
  runDate: string;
  searchType: string;
  status: string;
  statusOrder: number | undefined;
  updatedDate: string;
  url: string;
};

const mapStateToProps = (state: ReduxState, ownProps: OwnProps): StateProps => ({
  // @ts-expect-error TODO EM-1787: allow typescript to account for combination of dynamic and static keys in PatientSearchReducerState
  searchRequests: PatientSelectors.getPatientSearchRequest(state, ownProps.patientId),
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapAllSearches = (searchRequests: ApiSearchRequest[]): SearchRequestTableItem[] =>
  searchRequests
    .filter((search) => !!search.ExternalRegistryDetails)
    .reduce((requests: SearchRequestTableItem[], search: ApiSearchRequest) => {
      // Find the Adult result set summary (there should only be one)
      const resultSet = search.ResultSetSummaries.find((x) => x.DonorType === donorTypes.adult.value);
      const setId = resultSet ? resultSet.SearchResultSetId : '';
      const url = `/donorsearch/${search.Id}/results?resultSetId=${setId}&donorType=${donorTypes.adult.value}&reportType=${reportTypes.international}`;
      return [
        ...requests,
        ...search.ExternalRegistryDetails.map((registry) => {
          const registryCount =
            resultSet && resultSet.RegistryCounts.find((reg) => reg.RegistryId === registry.RegistryId);
          const resultCount = registryCount && registryCount.ResultCount;
          return {
            externalRegistryName: registry.RegistryName,
            externalRegistryId: registry.RegistryId,
            id: search.Id,
            rejectionDetails: registry.RejectionDetails,
            resultCount,
            resultSetId: setId,
            runBy: registry.RequestedByUser,
            runDate: registry.RequestedDate,
            searchType: registry.SearchType,
            status: registry.Status,
            statusOrder: searchResultStatusOrderInTable(registry.Status),
            updatedDate: registry.LastUpdatedTimeUtc,
            url,
          };
        }),
      ];
    }, []);

const leftJustify: CSSProperties = {
  alignItems: 'unset',
  textAlign: 'center',
};

const resultPageLink = (url: string, selectedRegistryId: number) => (
  <Link to={selectedRegistryId ? `${url}&registryIds=${selectedRegistryId}` : url} className="btn btn--primary">
    View Results
  </Link>
);

const isResultsReturned = (status: SearchResultStatus) =>
  status === searchStatuses.resultsAvailable.value || status === searchStatuses.closed.value;

const hasNoMatchedDonors = (resultCount: number, status: SearchResultStatus) =>
  resultCount === 0 && isResultsReturned(status);

const isSearchOverdue = (searchRunDate: moment.MomentInput | undefined, status: string) =>
  !!moment(searchRunDate).isBefore(moment().subtract(48, 'hours')) && status === searchStatuses.initiated.value;

const formatStatus = (status: string, resultCount: number, isBackfilling: boolean) => {
  if (isBackfilling) {
    return 'Backfill In Progress';
  }
  if (status === searchStatuses.initiated.value) {
    return searchResultStatusDisplayString(status);
  }
  if (status === searchStatuses.failedToSend.value) {
    return searchResultStatusDisplayString(status);
  }
  if (hasNoMatchedDonors(resultCount, status)) {
    return 'No Donors Matched';
  }
  if (status === searchStatuses.resultsAvailable.value) {
    return searchResultStatusDisplayString(status);
  }
  return status;
};

export class EmdisRequestsTable extends Component<Props, State> {
  static defaultProps = {
    searchRequests: [],
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      selectedRowIndexes: [],
      selectedRegistryIds: [],
    };
  }

  componentDidMount() {
    rebuildTooltip();
  }

  render() {
    const { searchRequests } = this.props;
    const { selectedRegistryIds, selectedRowIndexes } = this.state;
    const mappedSearchRequests = searchRequests ? mapAllSearches(searchRequests) : [];

    const resultsPageUrl = mappedSearchRequests.length > 0 ? mappedSearchRequests[0].url : '';

    const sortedRequests = this.orderSearchResults(mappedSearchRequests);

    const hasResultSet = (row: SearchRequestTableItem): boolean => !!row.resultSetId;

    const isBackfilling = (row: SearchRequestTableItem): boolean =>
      // We have assumed that the only cause of a missing resultSetId in combination with a 'ResultsAvailable' status is backfilling, but it is possible that something else causes this.
      // TODO: EM-1023: We need to start showing Backfill in Progress for Closed status, when the backfill process is actually happening
      row.status === searchStatuses.resultsAvailable.value && !hasResultSet(row);

    const isRowDisabled = (rowData: SearchRequestTableItem): boolean =>
      !isResultsReturned(rowData.status) ||
      hasNoMatchedDonors(rowData.resultCount as number, rowData.status) ||
      !hasResultSet(rowData);

    if (mappedSearchRequests.length === 0) {
      return <NoResultsFound resultType="requests" />;
    }
    return (
      <div>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: '1 1 auto', height: '400px' }}>
            <ReactTooltip className="tooltip" effect="solid" delayHide={100} place="right" html />
            <AutoSizer onResize={rebuildTooltip}>
              {({ width, height }) => (
                <Table
                  className="results-table"
                  width={width}
                  height={height}
                  headerHeight={50}
                  rowHeight={50}
                  rowCount={sortedRequests.length}
                  rowGetter={({ index }) => sortedRequests[index]}
                  rowClassName={({ index }) => this.getRowClass(index)}
                >
                  <Column
                    label=""
                    dataKey="id"
                    width={60}
                    // eslint-disable-next-line react/no-unstable-nested-components
                    cellRenderer={({ rowIndex, rowData }) => {
                      const isDisabled = isRowDisabled(rowData);
                      return (
                        <FeatureFlag
                          flag="showEmdisSearchResults"
                          render={() => (
                            <label htmlFor="selectRow">
                              <input
                                checked={selectedRowIndexes.includes(rowIndex)}
                                disabled={isDisabled}
                                onChange={this.handleRowSelection(rowIndex, rowData.externalRegistryId)}
                                type="checkbox"
                                name="selectRow"
                                readOnly
                              />
                            </label>
                          )}
                        />
                      );
                    }}
                  />

                  <Column
                    label="Emdis Registry"
                    dataKey="externalRegistryName"
                    width={550}
                    cellRenderer={({ cellData }) => cellData}
                    style={leftJustify}
                  />
                  <Column
                    width={300}
                    label="Status"
                    dataKey="status"
                    // eslint-disable-next-line react/no-unstable-nested-components
                    cellRenderer={({ cellData, rowData }) => {
                      const formattedStatus = formatStatus(cellData, rowData.resultCount, isBackfilling(rowData));
                      const noneText = `<span class="none-text">None</span>`;
                      const rejectionChecker =
                        formattedStatus === searchStatuses.rejected.value ? 'No rejection details available' : null;
                      const rejectionDetails =
                        rowData.rejectionDetails.length > 0
                          ? rowData.rejectionDetails
                              .map(
                                (rejectionDetail: ApiRejectionDetails) =>
                                  `<strong>Failure Reason:</strong> ${
                                    rejectionDetail.FailureSource || noneText
                                  }<br/><strong>Remark:</strong> ${rejectionDetail.Remark || noneText}`
                              )
                              .join('<hr/>')
                          : rejectionChecker;
                      return <div data-tip={rejectionDetails}>{formattedStatus}</div>;
                    }}
                  />
                  <Column width={350} label="Search Type" dataKey="searchType" />
                  <Column
                    label="Requested Date"
                    dataKey="runDate"
                    width={250}
                    // eslint-disable-next-line react/no-unstable-nested-components
                    cellRenderer={({ cellData, rowData }) => {
                      const style = isSearchOverdue(cellData, rowData.status) ? { color: 'red' } : {};
                      return <span style={style}>{formatDate(cellData)}</span>;
                    }}
                  />
                  <Column
                    label="Updated Date"
                    dataKey="updatedDate"
                    width={250}
                    cellRenderer={({ cellData }) => formatDate(cellData)}
                  />
                  <Column label="Run By" dataKey="runBy" width={400} style={leftJustify} />
                  <Column
                    width={300}
                    label="View Results"
                    dataKey="url"
                    // eslint-disable-next-line react/no-unstable-nested-components
                    cellRenderer={({ cellData, rowData }) => {
                      const btnClass = isRowDisabled(rowData) || selectedRegistryIds.length > 0 ? 'btn--disabled' : '';
                      return (
                        <FeatureFlag
                          flag="showEmdisSearchResults"
                          render={() => (
                            <span className={btnClass}>{resultPageLink(cellData, rowData.externalRegistryId)}</span>
                          )}
                        />
                      );
                    }}
                  />
                </Table>
              )}
            </AutoSizer>
          </div>
        </div>
        {selectedRegistryIds.length > 0 && (
          <div style={{ textAlign: 'center' }}>
            <span style={{ display: 'inline-block' }}>{this.multipleRegistriesResultPageLink(resultsPageUrl)}</span>
          </div>
        )}
      </div>
    );
  }

  multipleRegistriesResultPageLink = (url: string) => {
    const { selectedRegistryIds } = this.state;
    const uniqueSelectedRegistryIds = [...new Set(selectedRegistryIds)];
    return (
      <Link
        to={selectedRegistryIds ? `${url}&registryIds=${uniqueSelectedRegistryIds.join(',')}` : url}
        className="btn"
      >
        View Selected Results
      </Link>
    );
  };

  getRowClass = (rowIndex: number) => {
    const { selectedRowIndexes } = this.state;
    const periodicityClass = isOddNumber(rowIndex) ? 'odd' : 'even';
    const selectedClass = selectedRowIndexes.includes(rowIndex) && 'selected';

    return cn('cell', periodicityClass, selectedClass);
  };

  handleRowSelection = (rowIndex: number, registryId: number) => () => {
    const { selectedRegistryIds, selectedRowIndexes } = this.state;
    if (!selectedRowIndexes.includes(rowIndex)) {
      this.setState({
        selectedRowIndexes: [...selectedRowIndexes, rowIndex],
      });
    } else {
      const filteredRowIndexes = selectedRowIndexes.filter((item) => item !== rowIndex);
      this.setState({
        selectedRowIndexes: filteredRowIndexes,
      });
    }
    if (selectedRegistryIds.includes(registryId) && selectedRowIndexes.includes(rowIndex)) {
      const indexOfSelectedRegistry = selectedRowIndexes.indexOf(rowIndex);
      selectedRegistryIds.splice(indexOfSelectedRegistry, 1);
      this.setState({
        selectedRegistryIds,
      });
    } else {
      this.setState({
        selectedRegistryIds: [...selectedRegistryIds, registryId],
      });
    }
  };

  orderSearchResults = (mappedSearchRequests: SearchRequestTableItem[]): SearchRequestTableItem[] =>
    _.orderBy(mappedSearchRequests, ['statusOrder', 'externalRegistryName', 'runDate'], ['asc', 'asc', 'desc']);
}

export default connector(EmdisRequestsTable);

import _ from 'lodash';
import type { ApiAntigen, ApiHla } from '../types';

export default (hlaData: ApiHla) => {
  const filteredHla = _(hlaData)
    .filter((loci) => _.has(loci, 'Locus'))
    .filter((loci) => _.has(loci, 'HlaName'))
    .groupBy('Locus')
    .value();

  const lociString = Object.values(filteredHla)
    .map((item: ApiAntigen[]) => {
      const locus = item[0].Locus;
      return `${locus}${item[0].HlaName},${item[1].HlaName}`;
    })
    .sort();

  const hlaString = lociString.join(';').toUpperCase();
  return hlaString;
};

import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import NotificationSystem from 'react-notification-system';
import { styles } from '../../style';
import type { ReduxState } from '../../rootReducer';
import type { Notification } from '../redux/reducer';

type StateProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  notification?: Notification; // used in `mapStateToProps`
};
type Props = PropsFromRedux & StateProps;

const mapStateToProps = (state: ReduxState): StateProps => ({
  notification: state.notification.notification,
});

type PropsFromRedux = ConnectedProps<typeof connector>;

class NotificationContainer extends PureComponent<Props> {
  notificationSystem!: NotificationSystem;

  static defaultProps = {
    notification: undefined,
  };

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(prevProps: Props) {
    if (prevProps.notification) {
      const { message, level, timeDisplayed } = prevProps.notification;

      this.notificationSystem.addNotification({
        level,
        autoDismiss: timeDisplayed,
        dismissible: false,
        position: 'tc',
        children: <div style={styles.notificationPopUp}>{message}</div>,
      });
    }
  }

  render() {
    return (
      <div id="notificationBanner">
        <NotificationSystem
          ref={(notification: NotificationSystem) => {
            this.notificationSystem = notification;
          }}
        />
      </div>
    );
  }
}

const connector = connect(mapStateToProps);

export default connector(NotificationContainer);

import React, { FunctionComponent, ReactElement } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { FeatureFlagsSelectors, Feature, Features } from '../index';
import type { ReduxState } from '../../rootReducer';

type OwnProps = {
  andFlags?: Feature[];
  fallbackRender?: () => React.ReactNode;
  flag?: Feature;
  orFlags?: Feature[];
  render: () => React.ReactNode;
};
type StateProps = {
  features?: Features;
};

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = OwnProps & PropsFromRedux;

const mapStateToProps = (state: ReduxState): StateProps => ({
  features: FeatureFlagsSelectors.getCurrentFeatureFlags(state),
});

export const FeatureFlag: FunctionComponent<Props> = ({
  andFlags,
  fallbackRender,
  features,
  flag,
  orFlags,
  render,
}): ReactElement | null => {
  if (
    !features ||
    (flag && features[flag]) ||
    (andFlags && andFlags.every((item) => features[item])) ||
    (orFlags && orFlags.some((item) => features[item]))
  ) {
    return <>{render()}</>;
  }
  return fallbackRender ? <>{fallbackRender()}</> : null;
};

FeatureFlag.defaultProps = {
  andFlags: undefined,
  fallbackRender: undefined,
  flag: undefined,
  orFlags: undefined,
};

const connector = connect(mapStateToProps);

export default connector(FeatureFlag);

import { combineReducers } from 'redux';
import { convertExternalInvestigationActionsFromApi, convertRejectedMessageFromApi } from '../helpers/apiDataConverter';
import { ExternalInvestigationActions, MessageDenialAction } from '../types';
// eslint-disable-next-line import/no-cycle
import { Actions, ExternalInvestigationAction, RejectedMessageReduxAction } from './actions';

export type ExternalInvestigationActionsState = {
  hasErrored: boolean;
  isFetching: boolean;
  externalInvestigationsActions: ExternalInvestigationActions[];
  errorMessage?: string;
  isUpdating: boolean;
  isUpdatingActionsComplete: boolean;
};

const initialState: ExternalInvestigationActionsState = {
  hasErrored: false,
  isFetching: false,
  isUpdating: false,
  isUpdatingActionsComplete: false,
  externalInvestigationsActions: [],
};

const externalInvestigationActions = (
  state: ExternalInvestigationActionsState = initialState,
  action: ExternalInvestigationAction
): ExternalInvestigationActionsState => {
  switch (action.type) {
    case Actions.EXTERNAL_INVESTIGATION_ACTIONS_REQUEST:
      return {
        ...state,
        hasErrored: false,
        isFetching: true,
      };
    case Actions.EXTERNAL_INVESTIGATION_ACTIONS_SUCCESS:
      return {
        ...state,
        hasErrored: false,
        isFetching: false,
        externalInvestigationsActions: convertExternalInvestigationActionsFromApi(action.payload.data),
      };
    case Actions.EXTERNAL_INVESTIGATION_ACTIONS_ERROR:
      return {
        ...state,
        hasErrored: true,
        isFetching: false,
        errorMessage: `Error while fetching Test Results - ${action.payload.response.status}`,
      };
    case Actions.UPDATE_EXTERNAL_INVESTIGATION_ACTIONS_REQUEST:
      return {
        ...state,
        isUpdating: true,
      };
    case Actions.UPDATE_EXTERNAL_INVESTIGATION_ACTIONS_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        isUpdatingActionsComplete: true,
      };
    case Actions.UPDATE_EXTERNAL_INVESTIGATION_ACTIONS_ERROR:
      return {
        ...state,
        hasErrored: true,
        isUpdating: false,
        isUpdatingActionsComplete: false,
        errorMessage: action.payload.params.errorMessage,
      };

    default:
      return state;
  }
};

export type MessageDenialActionsState = {
  hasErrored: boolean;
  isFetching: boolean;
  messageDenialActions: MessageDenialAction[];
  errorMessage?: string;
  isUpdating: boolean;
  isUpdatingActionsComplete: boolean;
};

const rejectedMessageInitialState: MessageDenialActionsState = {
  hasErrored: false,
  isFetching: false,
  isUpdating: false,
  isUpdatingActionsComplete: false,
  messageDenialActions: [],
};

const messageDenialActions = (
  state: MessageDenialActionsState = rejectedMessageInitialState,
  action: RejectedMessageReduxAction
): MessageDenialActionsState => {
  switch (action.type) {
    case Actions.MESSAGE_DENIAL_ACTIONS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Actions.MESSAGE_DENIAL_ACTIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        messageDenialActions: convertRejectedMessageFromApi(action.payload.data),
      };
    case Actions.MESSAGE_DENIAL_ACTIONS_ERROR:
      return {
        ...state,
        isFetching: false,
        errorMessage: `Error while fetching Test Results - ${action.payload.response.status}`,
      };
    case Actions.UPDATE_MESSAGE_DENIAL_ACTIONS_REQUEST:
      return {
        ...state,
        isUpdating: true,
      };
    case Actions.UPDATE_MESSAGE_DENIAL_ACTIONS_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        isUpdatingActionsComplete: true,
      };
    case Actions.UPDATE_MESSAGE_DENIAL_ACTIONS_ERROR:
      return {
        ...state,
        hasErrored: true,
        isUpdating: false,
        isUpdatingActionsComplete: false,
        errorMessage: action.payload.params.errorMessage,
      };

    default:
      return state;
  }
};

const TransplantCentreReducer = combineReducers({
  externalInvestigationActions,
  messageDenialActions,
});

export default TransplantCentreReducer;

export type TransplantCentreReducerState = ReturnType<typeof TransplantCentreReducer>;

import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { connect, useDispatch } from 'react-redux';
import { LoadingMessage } from '../../../../../../core';
import { SingleLineTextArea } from '../../../../../../core/components/SingleLineTextArea';
import { formatRequestContext } from '../../../../../../core/helpers/markupHelpers';
import formatRemark from '../../../../../../core/helpers/remarkFormatter';
import { Locus } from '../../../../../../core/types';
import {
  getVerificationTypingResult,
  saveDonorVerificationTypingResult,
  sendVerificationTypingResult,
} from '../../../../../../externalInvestigations/redux/actions';
import HlaSection from '../../../../../../hlaInput/components/HlaSection/HlaSection';
import { isValid } from '../../../../../../hlaInput/helpers/geneticValidation';
import lociNames from '../../../../../../hlaInput/helpers/lociNames';
import { ReduxState } from '../../../../../../rootReducer';
import Selectors from '../../../../redux/selectors';
import { Hla, VerificationTypingResult } from '../../../../types';
import { DonorOriginalHLATable } from './DonorOriginalHLATable';
import './sendResultsPopup.scss';

type OwnProps = {
  investigationId: number;
  onClose: () => void;
  showModal: boolean;
  setShowModal: (isVerificationTypingResultSaved: boolean) => void;
  setIsVerificationTypingResultSaved: (isVerificationTypingResultSaved: boolean) => void;
  setAreResultsSent: (isVerificationTypingResultsSent: boolean) => void;
  onClosePopover: () => void;
  setIsEdited: (isEdited: boolean) => void;
  isGias?: boolean;
  isVerificationTypingResultSaved: boolean;
};

type StateProps = {
  result?: VerificationTypingResult;
  isVerificationTypingResultLoading: boolean;
};

type Props = OwnProps & StateProps;
type CheckboxEvent = { checked: boolean } & HTMLInputElement;

const mapStateToProps = (state: ReduxState): StateProps => ({
  result: Selectors.getHlaResult(state),
  isVerificationTypingResultLoading: Selectors.isVerificationTypingResultLoading(state),
});
const connector = connect(mapStateToProps);
const SendResultsPopup = ({
  investigationId,
  result,
  isVerificationTypingResultLoading,
  onClose,
  showModal,
  setShowModal,
  setIsVerificationTypingResultSaved,
  setAreResultsSent,
  onClosePopover,
  setIsEdited,
  isGias,
  isVerificationTypingResultSaved,
}: Props) => {
  const dispatch = useDispatch();
  const [commentValue, setCommentValue] = useState<string | undefined>(result?.comment);
  const [isCompleted, setIsCompleted] = useState(result?.isCompleted);
  const [error, setError] = useState('');
  const [radioState, setRadioState] = useState({
    releaseDonor: false,
    reserveDonor: false,
  });
  const [isRadioButtonSelected, setIsRadioButtonSelected] = useState(false);
  const [isEditedAndUnsaved, setIsEditedAndUnsaved] = useState({
    hlaInput: false,
    comment: false,
  });
  const [isInvalidAntigen, setIsInvalidAntigen] = useState(false);

  useEffect(() => {
    dispatch(getVerificationTypingResult(investigationId));
    setCommentValue(result?.comment);
    setIsCompleted(result?.isCompleted);
    if (result?.isDonorReserved !== undefined) {
      setRadioState({ reserveDonor: result?.isDonorReserved, releaseDonor: !result?.isDonorReserved });
      setIsRadioButtonSelected(true);
    } else {
      setRadioState({ releaseDonor: false, reserveDonor: false });
      setIsRadioButtonSelected(false);
    }
  }, [investigationId, result?.comment, result?.isCompleted, result?.isDonorReserved]);

  const getInitialAntigenIdsForLocus = (locus: { antigenInfoLocusKeys: string[] }) => {
    const values = _.map(locus.antigenInfoLocusKeys, (key: keyof Hla) =>
      result?.updatedHla && result?.updatedHla[key] ? result.updatedHla[key]?.id : null
    );
    return _.pickBy(_.zipObject([0, 1], values), (value) => value !== null);
  };
  const getInitialAntigenIds = () => {
    const lociByType = _.keyBy(lociNames, (locus) => locus.type);
    return _.mapValues(lociByType, (locus) => getInitialAntigenIdsForLocus(locus));
  };
  const [selectedAntigenIds, setSelectedAntigenIds] = useState<Record<string, Record<string, unknown>>>({});
  useEffect(() => {
    setSelectedAntigenIds(getInitialAntigenIds());
  }, [result?.updatedHla]);
  const handleAntigenChange = (locusType: string) => (rowId: number, antigenId: string | null | undefined) => {
    setSelectedAntigenIds((prevSelectedAntigenIds) => ({
      ...prevSelectedAntigenIds,
      [locusType]: {
        ...prevSelectedAntigenIds[locusType],
        [rowId]: antigenId,
      },
    }));
    // In typescript casting doesn't actually change the type of the data within the variable.
    const selectedAntigenId = selectedAntigenIds[locusType][rowId] as string;
    if (!selectedAntigenId) {
      if (antigenId === null) {
        setIsInvalidAntigen(true);
      }
      if (antigenId === undefined) {
        setIsInvalidAntigen(false);
      }
      if (antigenId === undefined) {
        setIsInvalidAntigen(false);
      }
      if (antigenId) {
        setIsEdited(true);
        setIsEditedAndUnsaved({ hlaInput: true, comment: false });
      }
    }
    if (selectedAntigenId) {
      if (antigenId === undefined) {
        setIsInvalidAntigen(true);
        setIsEdited(true);
        setIsEditedAndUnsaved({ hlaInput: true, comment: false });
      }
    }
    if (selectedAntigenId && antigenId) {
      // It was needed to convert the casted variable toString() to succeed with the conversion
      if (selectedAntigenId.toString() !== antigenId) {
        setIsEdited(true);
        setIsEditedAndUnsaved({ hlaInput: true, comment: false });
      } else {
        setIsEdited(false);
        setIsEditedAndUnsaved({ hlaInput: false, comment: false });
      }
    }
    setError('');
  };

  const handleReleaseRadioState = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.value === 'reserve') {
      setRadioState({ ...radioState, releaseDonor: false, reserveDonor: true });
    } else {
      setRadioState({ ...radioState, releaseDonor: true, reserveDonor: false });
    }
    setIsRadioButtonSelected(true);
  };

  const resetRadioButtons = () => {
    setRadioState({ ...radioState, releaseDonor: false, reserveDonor: false });
    setIsRadioButtonSelected(false);
  };

  const handleSaveButton = (event: React.MouseEvent<HTMLFormElement>) => {
    const donorObject = {
      updatedHla: selectedAntigenIds,
      comment: formatRemark(commentValue),
      isCompleted,
    };
    event.preventDefault();
    if (isValid(selectedAntigenIds)) {
      setError('One or more antigens are not valid');
    } else {
      setIsVerificationTypingResultSaved(true);
      dispatch(saveDonorVerificationTypingResult(donorObject, investigationId));
      setIsEdited(false);
      setIsEditedAndUnsaved({ hlaInput: false, comment: false });
      setIsInvalidAntigen(false);
    }
  };

  const hlaSection = (
    locus: { type: string; title: string; antigenInfoLocusKeys: string[] },
    index: number
  ): React.ReactNode =>
    result && (
      <HlaSection
        isShownOnOverlay
        isEven={index % 2 === 0}
        initialAntigenValues={locus.antigenInfoLocusKeys.map((key) =>
          result.updatedHla ? (result.updatedHla[key as keyof Hla] as Locus) : undefined
        )}
        key={locus.type}
        locusType={locus.type}
        onChange={handleAntigenChange(locus.type)}
        title={locus.title}
        isGias={isGias}
      />
    );
  const geneticInputs = (): React.ReactNode[] => lociNames.map(hlaSection);

  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (!isEditedAndUnsaved.hlaInput) {
      if (result?.comment === e.currentTarget.value) {
        setIsEdited(false);
        setIsEditedAndUnsaved({ hlaInput: false, comment: false });
      } else {
        setIsEdited(true);
        setIsEditedAndUnsaved({ hlaInput: false, comment: true });
      }
    }
    setCommentValue(formatRemark(e.currentTarget.value) as string | undefined);
  };

  const handleCheckboxChange = (e: React.SyntheticEvent<CheckboxEvent>) => {
    if (!result?.isCompleted) {
      setIsCompleted(e.currentTarget.checked);
      setIsEdited(true);
    }
  };

  const handleCloseModalAndPopup = () => {
    setIsEdited(false);
    setShowModal(false);
    onClose();
  };

  const displayIsCompletedStatus = () => (
    <div className="checkbox-label">
      <h6>Investigation Status: </h6>
      <p>{result?.isCompleted ? 'Completed' : 'Open'}</p>
    </div>
  );

  const handleSendResultsButton = () => {
    if (radioState) {
      dispatch(sendVerificationTypingResult(radioState.reserveDonor, investigationId));
      setAreResultsSent(true);
    }
  };

  if (isVerificationTypingResultLoading) {
    return <LoadingMessage isLoading={isVerificationTypingResultLoading} />;
  }

  return result ? (
    <>
      <div>
        <h2 className="border-bottom-solid send-results-popup-header">International Donor HLA Results</h2>
        {formatRequestContext(result.patientId, result.externalRegistry, result.donorId, result.grid)}
        <DonorOriginalHLATable result={result} />
      </div>
      <form className="columns-container" autoComplete="off" onSubmit={handleSaveButton}>
        <div>
          <div className="details-wrapper">
            <h6>Results Entered By:</h6>
            {result.enteredBy || '-'}
            <h6>Results Entered On:</h6>
            {result.enteredOn ? moment(result.enteredOn).format('DD-MM-YY') : '-'}
          </div>
          <div>
            <div className="details-wrapper">
              <h6>Results Sent By:</h6>
              {result.sentBy || '-'}
              <h6>Results Sent On:</h6>
              {result.sentOn ? moment(result.sentOn).format('DD-MM-YY') : '-'}
            </div>
            <div className={`radiobuttons-container${result?.isCompleted ? '' : ' disabled'}`}>
              <div className="radiobutton-wrapper">
                <input
                  type="radio"
                  value="reserve"
                  checked={radioState.reserveDonor}
                  onChange={handleReleaseRadioState}
                  disabled={!result?.isCompleted}
                />
                <h6>Reserve Donor</h6>
              </div>
              <div className="radiobutton-wrapper">
                <input
                  type="radio"
                  value="release"
                  checked={radioState.releaseDonor}
                  onChange={handleReleaseRadioState}
                  disabled={!result?.isCompleted}
                />
                <h6>Release Donor</h6>
              </div>
              <button
                type="button"
                className="btn btn--secondary resetButton"
                onClick={resetRadioButtons}
                disabled={!result?.isCompleted}
              >
                Clear Selection
              </button>
            </div>
          </div>
          <div className="comments-wrapper">
            <h6>Comments</h6>
            <SingleLineTextArea
              rows={1}
              maxLength={120}
              placeholder=""
              disabled={isGias}
              value={commentValue}
              style={{ resize: 'vertical', height: '70px' }}
              onChange={(e) => handleCommentChange(e)}
            />
          </div>
          {isGias ? (
            displayIsCompletedStatus()
          ) : (
            <label className="checkbox-label" htmlFor="checkbox">
              <input
                className="form-checkbox--large"
                type="checkbox"
                id="checkbox"
                checked={isCompleted}
                onChange={(e) => handleCheckboxChange(e)}
                disabled={result?.isCompleted}
              />
              <h6>Complete Investigation</h6>
            </label>
          )}
          <div className="btn-container">
            {error ? (
              <h3 id="genetic-data-form-error-message" data-testid="error">
                {error}
              </h3>
            ) : null}
            <button type="submit" className="btn" disabled={isGias}>
              Save
            </button>
            <button
              type="button"
              className="btn"
              onClick={handleSendResultsButton}
              disabled={
                !result.isCompleted ||
                !isRadioButtonSelected ||
                !isVerificationTypingResultSaved ||
                isEditedAndUnsaved.hlaInput ||
                isEditedAndUnsaved.comment ||
                isInvalidAntigen
              }
            >
              Send Results
            </button>
            {!isCompleted ? (
              <span className="warning-message">Results cannot be sent because the tests have not been completed</span>
            ) : (
              ''
            )}
            {isCompleted && !result.isCompleted ? (
              <span className="warning-message">
                Results cannot be sent because complete investigation is not saved
              </span>
            ) : (
              ''
            )}
            {isCompleted && (isEditedAndUnsaved.hlaInput || isEditedAndUnsaved.comment) ? (
              <span className="warning-message">
                Results cannot be sent because there are unsaved changes in the HLA results/comments
              </span>
            ) : (
              ''
            )}
            {result.isCompleted && !isRadioButtonSelected ? (
              <span className="warning-message">
                Results cannot be sent because reserve/release donor is not selected
              </span>
            ) : (
              ''
            )}
            <button type="button" className="btn btn--secondary" onClick={onClosePopover}>
              Close
            </button>
          </div>
        </div>
        <div className="hla-table-container">{geneticInputs()}</div>
        <Modal
          portalClassName="modal-container"
          isOpen={showModal}
          onRequestClose={() => setShowModal(false)}
          shouldCloseOnEsc
          ariaHideApp={false}
        >
          <h2>You have unsaved changes. Do you want to close the overlay?</h2>
          <div className="buttons-container">
            <button type="button" className="btn" onClick={handleCloseModalAndPopup}>
              Yes
            </button>
            <button className="btn btn--secondary" type="button" onClick={() => setShowModal(false)}>
              No
            </button>
          </div>
        </Modal>
      </form>
    </>
  ) : null;
};

SendResultsPopup.defaultProps = {
  result: undefined,
  isGias: undefined,
};

export default connector(SendResultsPopup);

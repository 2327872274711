export const ApiSearchTypes = {
  FourOutOfSix: 'FourOutOfSix',
  FourOutOfEight: 'FourOutOfEight',
  SixOutOfSix: 'SixOutOfSix',
  FiveOutOfSixAtA: 'A Mismatch',
  FiveOutOfSixAtB: 'B Mismatch',
  FiveOutOfSixAtDrb1: 'DRB1 Mismatch',
  TenOutOfTen: 'TenOutOfTen',
  NineOutOfTenAtA: 'NineOutOfTenAtA',
  NineOutOfTenAtB: 'NineOutOfTenAtB',
  NineOutOfTenAtC: 'NineOutOfTenAtC',
  NineOutOfTenAtDrb1: 'NineOutOfTenAtDrb1',
  NineOutOfTenAtDqb1: 'NineOutOfTenAtDqb1',
  EightOutOfEight: 'EightOutOfEight',
  EightOutOfTen: 'EightOutOfTen',
} as const;

export const SearchTypes = {
  sixOutOfSix: 'sixOutOfSix',
  fourOutOfSix: 'fourOutOfSix',
  fourOutOfEight: 'fourOutOfEight',
  fiveOutOfSixAtA: 'fiveOutOfSixAtA',
  fiveOutOfSixAtB: 'fiveOutOfSixAtB',
  fiveOutOfSixAtDrb1: 'fiveOutOfSixAtDrb1',
  tenOutOfTen: 'tenOutOfTen',
  nineOutOfTenAtA: 'nineOutOfTenAtA',
  nineOutOfTenAtB: 'nineOutOfTenAtB',
  nineOutOfTenAtC: 'nineOutOfTenAtC',
  nineOutOfTenAtDRB1: 'nineOutOfTenAtDRB1',
  nineOutOfTenAtDQB1: 'nineOutOfTenAtDQB1',
  eightOutOfEight: 'eightOutOfEight',
  eightOutOfTen: 'eightOutOfTen',
} as const;

export const NumericSearchTypes = {
  fourOutOfSix: '4-6/6',
  fourOutOfEight: '4-8/8',
  sixOutOfSix: '6/6',
  fiveOutOfSixAtA: '5/6 at A',
  fiveOutOfSixAtB: '5/6 at B',
  fiveOutOfSixAtDrb1: '5/6 at DRB1',
  tenOutOfTen: '10/10',
  nineOutOfTenAtA: '9/10 at A',
  nineOutOfTenAtB: '9/10 at B',
  nineOutOfTenAtC: '9/10 at C',
  nineOutOfTenAtDRB1: '9/10 at DRB1',
  nineOutOfTenAtDQB1: '9/10 at DQB1',
  eightOutOfEight: '8/8',
  eightOutOfTen: '8/10',
} as const;

export type ApiSearchType = typeof ApiSearchTypes[keyof typeof ApiSearchTypes];
export type SearchType = typeof SearchTypes[keyof typeof SearchTypes];
export type NumericSearchType = typeof NumericSearchTypes[keyof typeof NumericSearchTypes];

export const convertSearchTypeFromApi = (searchType: ApiSearchType): SearchType => {
  switch (searchType) {
    case ApiSearchTypes.FourOutOfSix:
      return SearchTypes.fourOutOfSix;
    case ApiSearchTypes.FourOutOfEight:
      return SearchTypes.fourOutOfEight;
    case ApiSearchTypes.SixOutOfSix:
      return SearchTypes.sixOutOfSix;
    case ApiSearchTypes.FiveOutOfSixAtA:
      return SearchTypes.fiveOutOfSixAtA;
    case ApiSearchTypes.FiveOutOfSixAtB:
      return SearchTypes.fiveOutOfSixAtB;
    case ApiSearchTypes.FiveOutOfSixAtDrb1:
      return SearchTypes.fiveOutOfSixAtDrb1;
    case ApiSearchTypes.TenOutOfTen:
      return SearchTypes.tenOutOfTen;
    case ApiSearchTypes.NineOutOfTenAtA:
      return SearchTypes.nineOutOfTenAtA;
    case ApiSearchTypes.NineOutOfTenAtB:
      return SearchTypes.nineOutOfTenAtB;
    case ApiSearchTypes.NineOutOfTenAtC:
      return SearchTypes.nineOutOfTenAtC;
    case ApiSearchTypes.NineOutOfTenAtDrb1:
      return SearchTypes.nineOutOfTenAtDRB1;
    case ApiSearchTypes.NineOutOfTenAtDqb1:
      return SearchTypes.nineOutOfTenAtDQB1;
    case ApiSearchTypes.EightOutOfEight:
      return SearchTypes.eightOutOfEight;
    case ApiSearchTypes.EightOutOfTen:
      return SearchTypes.eightOutOfTen;
    default:
      return searchType;
  }
};

export const convertSearchTypeToNumeric = (searchType: SearchType): NumericSearchType => {
  switch (searchType) {
    case SearchTypes.fourOutOfSix:
      return NumericSearchTypes.fourOutOfSix;
    case SearchTypes.fourOutOfEight:
      return NumericSearchTypes.fourOutOfEight;
    case SearchTypes.sixOutOfSix:
      return NumericSearchTypes.sixOutOfSix;
    case SearchTypes.fiveOutOfSixAtA:
      return NumericSearchTypes.fiveOutOfSixAtA;
    case SearchTypes.fiveOutOfSixAtB:
      return NumericSearchTypes.fiveOutOfSixAtB;
    case SearchTypes.fiveOutOfSixAtDrb1:
      return NumericSearchTypes.fiveOutOfSixAtDrb1;
    case SearchTypes.tenOutOfTen:
      return NumericSearchTypes.tenOutOfTen;
    case SearchTypes.nineOutOfTenAtA:
      return NumericSearchTypes.nineOutOfTenAtA;
    case SearchTypes.nineOutOfTenAtB:
      return NumericSearchTypes.nineOutOfTenAtB;
    case SearchTypes.nineOutOfTenAtC:
      return NumericSearchTypes.nineOutOfTenAtC;
    case SearchTypes.nineOutOfTenAtDRB1:
      return NumericSearchTypes.nineOutOfTenAtDRB1;
    case SearchTypes.nineOutOfTenAtDQB1:
      return NumericSearchTypes.nineOutOfTenAtDQB1;
    case SearchTypes.eightOutOfEight:
      return NumericSearchTypes.eightOutOfEight;
    case SearchTypes.eightOutOfTen:
      return NumericSearchTypes.eightOutOfTen;
    default:
      return searchType;
  }
};

export const convertSearchTypeFromApiToNumeric = (searchType: ApiSearchType): NumericSearchType =>
  convertSearchTypeToNumeric(convertSearchTypeFromApi(searchType));

export type SearchTypeDetails = {
  isPossibleForSolarSearch: boolean;
  label: string;
  value: SearchType;
  listPosition: number;
};

// The string values used here must match the enum values in the backend
export const searchTypesDetails = {
  TEN_OUT_OF_TEN_SEARCH: {
    value: 'tenOutOfTen',
    label: '10/10',
    listPosition: 1,
    isPossibleForSolarSearch: false,
  },
  A_NINE_OUT_OF_TEN_SEARCH: {
    value: 'nineOutOfTenAtA',
    label: '9/10 Mismatch at A',
    listPosition: 2,
    isPossibleForSolarSearch: false,
  },
  B_NINE_OUT_OF_TEN_SEARCH: {
    value: 'nineOutOfTenAtB',
    label: '9/10 Mismatch at B',
    listPosition: 3,
    isPossibleForSolarSearch: false,
  },
  C_NINE_OUT_OF_TEN_SEARCH: {
    value: 'nineOutOfTenAtC',
    label: '9/10 Mismatch at C',
    listPosition: 4,
    isPossibleForSolarSearch: false,
  },
  DRB1_NINE_OUT_OF_TEN_SEARCH: {
    value: 'nineOutOfTenAtDRB1',
    label: '9/10 Mismatch at DRB1',
    listPosition: 5,
    isPossibleForSolarSearch: false,
  },
  DQB1_NINE_OUT_OF_TEN_SEARCH: {
    value: 'nineOutOfTenAtDQB1',
    label: '9/10 Mismatch at DQB1',
    listPosition: 6,
    isPossibleForSolarSearch: false,
  },
  EIGHT_OUT_OF_TEN_SEARCH: {
    value: 'eightOutOfTen',
    label: '8/10',
    listPosition: 7,
    isPossibleForSolarSearch: false,
  },
  EIGHT_OUT_OF_EIGHT_SEARCH: {
    value: 'eightOutOfEight',
    label: '8/8',
    listPosition: 8,
    isPossibleForSolarSearch: false,
  },
  POTENTIAL_SEARCH: {
    value: 'sixOutOfSix',
    label: '6/6 Potential at A, B, DRB1 Matched',
    listPosition: 9,
    isPossibleForSolarSearch: true,
  },
  A_MISMATCH_SEARCH: {
    value: 'fiveOutOfSixAtA',
    label: '5/6 Mismatch at A',
    listPosition: 10,
    isPossibleForSolarSearch: true,
  },
  B_MISMATCH_SEARCH: {
    value: 'fiveOutOfSixAtB',
    label: '5/6 Mismatch at B',
    listPosition: 11,
    isPossibleForSolarSearch: true,
  },
  DRB1_MISMATCH_SEARCH: {
    value: 'fiveOutOfSixAtDrb1',
    label: '5/6 Mismatch at DRB1',
    listPosition: 12,
    isPossibleForSolarSearch: true,
  },
} as Record<string, SearchTypeDetails>;

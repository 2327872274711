import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { styles as commonStyles } from '../style';
import Selectors from '../redux/selectors';
import SaveDonorButton from './SaveDonorButton/SaveDonorButton';
import type { DonorType } from '../../core/types';
import type { ReduxState } from '../../rootReducer';

type OwnProps = {
  donorType: DonorType;
  isRequestClosed?: boolean;
  resultSetId: string;
};
type StateProps = {
  shownResultSetId?: string | void;
};
type Props = OwnProps & StateProps & PropsFromRedux;

const mapStateToProps = (state: ReduxState, ownProps: OwnProps): StateProps => ({
  shownResultSetId: Selectors.getShownResultSetId(state, ownProps.donorType),
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const styles = {
  ...commonStyles,
  searchClosedLabel: {
    paddingRight: '15px',
    fontSize: '18px',
  },
};

class SaveDonors extends PureComponent<Props> {
  static defaultProps = {
    isRequestClosed: false,
    shownResultSetId: undefined,
  };

  render() {
    const { donorType, isRequestClosed, resultSetId, shownResultSetId } = this.props;
    if (isRequestClosed) {
      return <h4 style={styles.searchClosedLabel}>SEARCH CLOSED</h4>;
    }

    if (shownResultSetId) {
      return (
        <div className="fieldRow">
          <SaveDonorButton
            buttonText="Update"
            shownResultSetIdIfUpdate={shownResultSetId}
            donorType={donorType}
            resultSetId={resultSetId}
          />
          <SaveDonorButton
            buttonText="Save As New"
            style={{ marginRight: 0 }}
            donorType={donorType}
            resultSetId={resultSetId}
          />
        </div>
      );
    }
    return (
      <SaveDonorButton style={{ marginRight: 0 }} buttonText="Save" donorType={donorType} resultSetId={resultSetId} />
    );
  }
}

export default connector(SaveDonors);

import _ from 'lodash';

const CORD: 'cord' = 'cord';
const DONOR: 'donor' = 'donor';
const PATIENT: 'patient' = 'patient';
const SAMPLE: 'sample' = 'sample';

export const subjectTypes = {
  cord: {
    id: CORD,
    displayName: 'Cord Donor',
  },
  donor: {
    id: DONOR,
    displayName: 'Donor',
  },
  patient: {
    id: PATIENT,
    displayName: 'Patient',
  },
  sample: {
    id: SAMPLE,
    displayName: 'Sample',
  },
};

export const getSubjectTypeDisplayString = (id: string) => _.find(subjectTypes, (type) => type.id === id)?.displayName;

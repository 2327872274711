import _ from 'lodash';
import type { DispatchMethod } from '../types';

const BLOOD: 'Blood' = 'Blood';
const CELLS: 'Cells' = 'Cells';
const DNA: 'Dna' = 'Dna';
const SWAB: 'Swab' = 'Swab';
const SALIVA: 'Saliva' = 'Saliva';
const OTHER: 'Other' = 'Other';
const UNKNOWN: 'Unknown' = 'Unknown';

export const dispatchMethods = {
  blood: {
    id: BLOOD,
    displayValue: BLOOD,
    isSelectable: true,
  },
  swab: {
    id: SWAB,
    displayValue: 'Buccal Swab',
    isSelectable: true,
  },
  cells: {
    id: CELLS,
    displayValue: CELLS,
    isSelectable: true,
  },
  dna: {
    id: DNA,
    displayValue: 'DNA',
    isSelectable: true,
  },
  saliva: {
    id: SALIVA,
    displayValue: SALIVA,
    isSelectable: true,
  },
  other: {
    id: OTHER,
    displayValue: OTHER,
    isSelectable: true,
  },
  unknown: {
    id: UNKNOWN,
    displayValue: UNKNOWN,
    isSelectable: false,
  },
};

export const getDispatchMethodDisplayString = (id: DispatchMethod | null) => {
  const dispatchMethod = _.find(dispatchMethods, (method) => method.id === id);
  return dispatchMethod ? dispatchMethod.displayValue : id || '';
};

export const getSelectableDispatchMethodOptions = () =>
  Object.keys(dispatchMethods)
    .filter((method) => dispatchMethods[method as keyof typeof dispatchMethods].isSelectable)
    .map<{ label: string | null; value: string | null }>((method) => ({
      label: dispatchMethods[method as keyof typeof dispatchMethods].displayValue,
      value: dispatchMethods[method as keyof typeof dispatchMethods].id,
    }));

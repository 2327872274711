import React from 'react';
import FieldError from './FieldError';

type Props = {
  label: string;
  id: string;
  type: string;
  value: string;
  input: {
    name: string;
    onChange: (e: React.SyntheticEvent<HTMLInputElement>) => void;
  };
  disabled?: boolean;
  meta?: {
    error: string;
    touched: boolean;
    warning: string;
  };
};

const Input = (props: Props) => {
  const { label, disabled, type, value, meta, input, ...rest } = props;
  const touched = meta ? meta.touched : undefined;
  const error = meta ? meta.error : undefined;

  return (
    <div className="field">
      {label && <label htmlFor={input.name}>{label}</label>}
      <input
        {...input}
        {...rest}
        className={touched && error ? 'error' : ''}
        type={type}
        value={value}
        id={input.name}
        autoComplete={type === 'text' ? 'nope' : undefined}
        disabled={disabled}
      />
      {meta ? <FieldError meta={meta} /> : null}
    </div>
  );
};

Input.defaultProps = {
  disabled: false,
  meta: undefined,
};
export default Input;

import UrlAssembler from 'url-assembler';
import moment from 'moment';
import {
  get,
  ErrorAction as ApiErrorAction,
  NormalizedPayload,
  Response as ApiResponse,
  SuccessAction as ApiSuccessAction,
} from '@an/nova-frontend-rest-client';
import { reportsList } from '../../../core';
import Config from '../../../config';
import type { NormalizedSavedReportMinimal } from '../../currentReport/redux/actions';
import type { ApiSavedReportMinimal } from '../../types/api';
import type { ReduxState } from '../../../rootReducer';

const PATIENTS_SAVED_REPORTS: 'searchReports/patients/savedReports' = 'searchReports/patients/savedReports';
const PATIENTS_SAVED_REPORTS_ERROR: 'searchReports/patients/savedReports/error' =
  'searchReports/patients/savedReports/error';
const PATIENTS_SAVED_REPORTS_REQUEST: 'searchReports/patients/savedReports/request' =
  'searchReports/patients/savedReports/request';
const PATIENTS_SAVED_REPORTS_SUCCESS: 'searchReports/patients/savedReports/success' =
  'searchReports/patients/savedReports/success';
const SAVED_REPORTS_AWAITING_AUTH_SUCCESS: 'searchReports/savedReports/awaitingAuth/success' =
  'searchReports/savedReports/awaitingAuth/success';
const SAVED_REPORTS: 'searchReports/savedReports' = 'searchReports/savedReports';
const SAVED_REPORTS_ERROR: 'searchReports/savedReports/error' = 'searchReports/savedReports/error';
const SAVED_REPORTS_REQUEST: 'searchReports/savedReports/request' = 'searchReports/savedReports/request';
const SAVED_REPORTS_SUCCESS: 'searchReports/savedReports/success' = 'searchReports/savedReports/success';
const SAVED_REPORTS_MINIMAL: 'searchReports/savedReports/minimal' = 'searchReports/savedReports/minimal';
const SAVED_REPORTS_AWAITING_AUTH: 'searchReports/savedReports/awaitingAuth' =
  'searchReports/savedReports/awaitingAuth';
const SAVED_REPORTS_MINIMAL_ERROR: 'searchReports/savedReports/minimal/error' =
  'searchReports/savedReports/minimal/error';
const SAVED_REPORTS_MINIMAL_REQUEST: 'searchReports/savedReports/minimal/request' =
  'searchReports/savedReports/minimal/request';
const SAVED_REPORTS_MINIMAL_SUCCESS: 'searchReports/savedReports/minimal/success' =
  'searchReports/savedReports/minimal/success';
const UPDATE_SAVED_REPORTS: 'searchReports/savedReports/update' = 'searchReports/savedReports/update';

export const Actions = {
  PATIENTS_SAVED_REPORTS_ERROR,
  PATIENTS_SAVED_REPORTS_REQUEST,
  PATIENTS_SAVED_REPORTS_SUCCESS,
  SAVED_REPORTS_AWAITING_AUTH_SUCCESS,
  SAVED_REPORTS_ERROR,
  SAVED_REPORTS_REQUEST,
  SAVED_REPORTS_SUCCESS,
  SAVED_REPORTS_MINIMAL_ERROR,
  SAVED_REPORTS_MINIMAL_REQUEST,
  SAVED_REPORTS_MINIMAL_SUCCESS,
  UPDATE_SAVED_REPORTS,
};

type AwaitingAuthorisationStatusParam = { status: 'Awaiting Authorisation' };
type PatientIdParam = { patientId: string };

export type SavedReportEntitiesMinimal = { [string: string]: ApiSavedReportMinimal };
export type NormalizedSavedReportListMinimal = NormalizedPayload<SavedReportEntitiesMinimal>;

export type PatientSavedReportsErrorAction = ApiErrorAction<typeof PATIENTS_SAVED_REPORTS_ERROR, PatientIdParam>;
export type PatientSavedReportsRequestAction = ApiSuccessAction<typeof PATIENTS_SAVED_REPORTS_REQUEST, PatientIdParam>;
export type PatientSavedReportsSuccessAction = ApiSuccessAction<
  typeof PATIENTS_SAVED_REPORTS_SUCCESS,
  PatientIdParam,
  NormalizedSavedReportListMinimal
>;
export type SavedReportsAwaitingAuthorisationSuccessAction = ApiSuccessAction<
  typeof SAVED_REPORTS_AWAITING_AUTH_SUCCESS,
  AwaitingAuthorisationStatusParam,
  number
>;
export type SavedReportsErrorAction = ApiErrorAction<typeof SAVED_REPORTS_ERROR, Record<string, unknown>>;
export type SavedReportsRequestAction = ApiSuccessAction<typeof SAVED_REPORTS_REQUEST, Record<string, unknown>>;
export type SavedReportsSuccessAction = ApiSuccessAction<
  typeof SAVED_REPORTS_SUCCESS,
  Record<string, unknown>,
  NormalizedSavedReportListMinimal
>;
export type SavedReportsMinimalErrorAction = ApiErrorAction<
  typeof SAVED_REPORTS_MINIMAL_ERROR,
  Record<string, unknown>
>;
export type SavedReportMinimalRequestAction = ApiSuccessAction<
  typeof SAVED_REPORTS_MINIMAL_REQUEST,
  Record<string, unknown>
>;
export type SavedReportsMinimalSuccessAction = ApiSuccessAction<
  typeof SAVED_REPORTS_MINIMAL_SUCCESS,
  Record<string, unknown>,
  NormalizedSavedReportListMinimal
>;

export type UpdateSavedReportsAction = {
  type: typeof UPDATE_SAVED_REPORTS;
  payload: NormalizedSavedReportMinimal;
};

export const updateSavedReports = (
  savedReport: ApiResponse<NormalizedSavedReportMinimal>
): UpdateSavedReportsAction => ({
  type: Actions.UPDATE_SAVED_REPORTS,
  // @ts-expect-error - we know that payload is going to be populated
  payload: savedReport.payload,
});

const dateMinus15Days = moment().subtract(15, 'day').format('YYYY/MM/DD');
const dateMinus7Days = moment().subtract(7, 'day').format('YYYY/MM/DD');

const generatedOnOrAfterDateParameter = 'generatedOnOrAfterDate';
const sentOnOrAfterDateParameter = 'sentOnOrAfterDate';

export const getSavedReports = () => {
  const url = UrlAssembler()
    .template(`${Config().apiBaseUrl}search-reports`)
    .param(generatedOnOrAfterDateParameter, dateMinus15Days)
    .param(sentOnOrAfterDateParameter, dateMinus7Days)
    .toString();

  return get<ReduxState, NormalizedSavedReportListMinimal>(url, SAVED_REPORTS, undefined, reportsList);
};

export const getMinimalSavedReports = () => {
  const url = UrlAssembler()
    .template(`${Config().apiBaseUrl}search-reports/minimal`)
    .param(generatedOnOrAfterDateParameter, dateMinus15Days)
    .param(sentOnOrAfterDateParameter, dateMinus7Days)
    .toString();

  return get<ReduxState, NormalizedSavedReportListMinimal>(url, SAVED_REPORTS_MINIMAL, undefined, reportsList);
};

export const getPatientSearchReports = (patientId: string) => {
  const url = UrlAssembler()
    .template(`${Config().apiBaseUrl}search-reports`)
    .param('reportFilterModel.patientId', `${patientId}`)
    .toString();

  return get<ReduxState, NormalizedSavedReportListMinimal>(url, PATIENTS_SAVED_REPORTS, { patientId }, reportsList);
};

export const getReportsAwaitingAuthorisation = () => {
  // TODO: Initialise UrlAssembler with base url
  const url = UrlAssembler()
    .template(`${Config().apiBaseUrl}search-reports/awaiting-authorisation-number`)
    .param(generatedOnOrAfterDateParameter, dateMinus15Days)
    .toString();

  return get<ReduxState, number>(url, SAVED_REPORTS_AWAITING_AUTH);
};

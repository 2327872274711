import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import type { ReduxState } from '../../rootReducer';
import * as SubjectActions from '../redux/actions';
import * as SubjectSelectors from '../redux/selectors';

export const useSubjectFormOptions = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(SubjectActions.getSubjectConstants());
  }, []);

  const subjectConstants = useSelector((state: ReduxState) => SubjectSelectors.getSubjectConstants(state));

  return {
    subjectConstants,
  };
};

import React, { Component, CSSProperties } from 'react';
import { AnyAction, bindActionCreators, Dispatch as ReduxDispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import cn from 'classnames';
import _ from 'lodash';
import { AutoSizer, GridCellProps, MultiGrid } from 'react-virtualized';
import { FeatureFlag } from '../../../featureFlags';
import { styles as commonStyles, Styles as CommonStyles } from '../../style';
import { colors } from '../../../style/index';
import formatSelectedExternalDonors from '../../helpers/formatSelectedExternalDonors';
import ExternalInvestigations from '../ExternalInvestigations/ExternalInvestigations';
import TableTitle from '../TableHeader/TableTitle';
import ResultsSelectors from '../../redux/selectors';
import SaveDonors from '../SaveDonors';
import ToSearchReportButton from '../TableHeader/ToSearchReportButton/ToSearchReportButton';
import { LoadingMessage, PopUpWrapper } from '../../../core';
import DonorTableCellDisplay from './Cells/DonorTableCellDisplay';
import type { SelectedResult, SelectedRow } from '../../../donorMatchSearchRequests/types';
import * as actions from '../../redux/actions';
import type { DonorType, AdultSearchResults, SearchResults, AdultSearchResult } from '../../../core/types';
import donorTypes from '../../../core/constants/donorTypes';
import algorithmTypes from '../../../core/constants/algorithmTypes';
import type { ReduxState } from '../../../rootReducer';
import type { RenderCellParams, TableContents } from './searchResultsTableContents';
import type { HideableColumn } from '../../types';
import type { ExternalInvestigationSelectedDonor } from '../ExternalInvestigations/types';
import { SearchType } from '../../../core/constants/searchTypes';
import { convertToAlgorithmName } from '../../../patient/patientDashboard/helpers/algorithmConverter';

type OwnProps = {
  algorithm: string;
  donorType: DonorType;
  errorMessage?: string;
  hasErrored: boolean;
  isFetching: boolean;
  patientId: string;
  reportId?: string;
  resultSetId: string;
  searchResults: SearchResults;
  tableContents: TableContents<any>;
  specificSearchType?: SearchType;
};
type StateProps = {
  hiddenColumns: HideableColumn[];
  isHlaAdjusted: boolean;
  isRequestClosed: boolean;
  selectedItems: Partial<SelectedResult>[];
  unscoredCount?: number;
  unfilteredSearchResults?: SearchResults;
};
type Props = PropsFromRedux & OwnProps & StateProps;
type State = {
  fixedColumnCount: number;
  fixedRowCount: number;
  list: Array<Array<any>>;
  mouseOverHla: boolean;
  overscanRowCount: number;
  popUpStyle: Record<string, any>;
  isPopUpShown: boolean;
};

const mapStateToProps = (state: ReduxState, ownProps: OwnProps): StateProps => ({
  hiddenColumns: ResultsSelectors.getHiddenColumns(state),
  isHlaAdjusted: ResultsSelectors.isSearchRequestHlaAdjusted(state),
  isRequestClosed: ResultsSelectors.isSearchRequestClosed(state),
  unscoredCount: ResultsSelectors.getResultSetUnscoredCount(state, ownProps.donorType),
  selectedItems: ResultsSelectors.getSelectedItems(state),
  unfilteredSearchResults: ResultsSelectors.getSearchResults(state, ownProps.resultSetId),
});
const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>) => ({
  updateSelectedItems: bindActionCreators(actions.updateSelectedItems, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const headerRowIndex = 0;

const initialPopUpStyle = {
  maxHeight: '60%',
  width: '35%',
  top: '50%',
  left: '50%',
};

const rowHeights = {
  header: 35,
  table: 70,
};

const styles: CommonStyles & Record<string, CSSProperties> = {
  ...commonStyles,
  bottomLeftGrid: {
    borderRight: '2px solid #aaa',
    backgroundColor: '#f7f7f7',
  },
  topLeftGrid: {
    borderBottom: '2px solid #aaa',
    borderRight: '2px solid #aaa',
    fontWeight: 'bold',
  },
  topRightGrid: {
    borderBottom: '2px solid #aaa',
    fontWeight: 'bold',
  },
  tableMessage: {
    width: '200px',
    margin: '50px auto',
    padding: '1em',
  },
  cellFontSize: {
    fontSize: '14px',
  },
};

const isInternational = (donorType: DonorType, algorithm: string) =>
  algorithm === algorithmTypes.external && donorType === donorTypes.adult.value;

export class ResultsTable extends Component<Props, State> {
  static defaultProps = {
    errorMessage: undefined,
    reportId: undefined,
    specificSearchType: undefined,
    unfilteredSearchResults: undefined,
    unscoredCount: undefined,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      list: [],
      overscanRowCount: 10,
      fixedColumnCount: 0,
      fixedRowCount: 1,
      mouseOverHla: false,
      isPopUpShown: false,
      popUpStyle: initialPopUpStyle,
    };
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    return this.updateStateForRender(this.props);
  }

  componentDidMount() {
    const { algorithm, donorType, selectedItems, unfilteredSearchResults, updateSelectedItems } = this.props;
    if (isInternational(donorType, algorithm) && unfilteredSearchResults) {
      updateSelectedItems(formatSelectedExternalDonors(selectedItems, unfilteredSearchResults));
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps: Props) {
    return this.updateStateForRender(nextProps);
  }

  componentDidUpdate(prevProps: Props) {
    const {
      algorithm,
      donorType,
      hiddenColumns,
      searchResults,
      selectedItems,
      unfilteredSearchResults,
      updateSelectedItems,
    } = this.props;
    if (prevProps.hiddenColumns !== hiddenColumns || (prevProps.searchResults !== searchResults && this.gridRef)) {
      this.gridRef.recomputeGridSize();
    }

    if (
      !_.isEqual(selectedItems, prevProps.selectedItems) &&
      isInternational(donorType, algorithm) &&
      unfilteredSearchResults
    ) {
      updateSelectedItems(formatSelectedExternalDonors(selectedItems, unfilteredSearchResults));
    }
  }

  render() {
    const {
      algorithm,
      donorType,
      isHlaAdjusted,
      isRequestClosed,
      patientId,
      reportId,
      resultSetId,
      searchResults,
      selectedItems,
      specificSearchType,
    } = this.props;
    const { popUpStyle, isPopUpShown } = this.state;
    const { height, maxHeight, width, top, left, minWidth } = popUpStyle;
    const newRequestPopUpStyle: CSSProperties = {
      position: 'fixed',
      width,
      minWidth,
      top,
      left,
      overflow: 'auto',
      height,
      maxHeight,
      border: `1px solid ${colors.ANGreen}`,
    };

    return (
      <div
        className="outer"
        style={{
          ...styles.flexToFillAllTheSpace,
          flex: '1 0 auto',
          height: '400px',
        }}
      >
        <div>
          {this.isReadyToRenderTable() && (
            <TableTitle
              searchType={donorType}
              isHlaAdjusted={isHlaAdjusted}
              searchResults={searchResults}
              resultSetId={resultSetId}
              algorithm={algorithm}
              specificSearchType={specificSearchType}
            />
          )}
          <div style={{ float: 'right' }}>
            <SaveDonors resultSetId={resultSetId} donorType={donorType} />
          </div>
          {algorithm === algorithmTypes.external &&
            searchResults.type === donorTypes.adult.value &&
            !(selectedItems.length < 1) && (
              <div style={{ float: 'right' }}>
                <FeatureFlag
                  orFlags={[
                    'showIdmRequestButton',
                    'showVerificationTypingRequestButton',
                    'showExtendedTypingRequestButton',
                  ]}
                  render={() => (
                    <PopUpWrapper
                      buttonClassName="btn btn--inline"
                      isPopUpShown={isPopUpShown}
                      onClick={this.handlePopUpClick}
                      resizePopUp={this.resizePopUp}
                      name="Send Request"
                      placement="left"
                      popUpStyle={newRequestPopUpStyle}
                      shouldDarkenBackground
                      popoverContainerClassName="react-tiny-popover-container--center"
                    >
                      <ExternalInvestigations
                        adjustStyling={this.adjustPopUpStyling}
                        onPopUpClose={this.onPopUpClose}
                        onClose={this.hidePopUp}
                        patientId={patientId}
                        selectedItems={this.getSelectedDonors(searchResults as AdultSearchResults, selectedItems)}
                      />
                    </PopUpWrapper>
                  )}
                />
              </div>
            )}
          {isRequestClosed ? null : (
            <div style={{ float: 'right' }}>
              <ToSearchReportButton
                patientId={patientId}
                reportId={reportId}
                isInternational={isInternational(donorType, algorithm)}
              />
            </div>
          )}
        </div>

        {this.renderResults()}
      </div>
    );
  }

  renderResults() {
    const { donorType, errorMessage, hasErrored, tableContents, unscoredCount } = this.props;

    const resultType =
      donorType === donorTypes.cord.value ? donorTypes.cord.displayString : donorTypes.adult.displayString;
    if (hasErrored) {
      return (
        <div
          style={{
            ...styles.errorMessage,
            ...styles.cardLayout,
            ...styles.tableMessage,
          }}
        >
          {errorMessage}
        </div>
      );
    }
    if (this.thereAreNoResults() && unscoredCount && unscoredCount > 0) {
      const errorString = `There are unscored ${resultType}s returned in this search`;
      return (
        <span
          role="img"
          aria-label={errorString}
          style={{
            ...styles.errorMessage,
            ...styles.cardLayout,
            ...styles.tableMessage,
          }}
        >
          ⚠️ {errorString}.
        </span>
      );
    }

    if (this.thereAreNoResults()) {
      const noResultMessage = `No ${resultType}s matching the filter criteria were returned in this search`;

      return (
        <span
          role="img"
          aria-label={noResultMessage}
          style={{
            ...styles.errorMessage,
            ...styles.cardLayout,
            ...styles.tableMessage,
          }}
        >
          ⚠️ {noResultMessage}.
        </span>
      );
    }

    if (this.isReadyToRenderTable()) {
      const { overscanRowCount, fixedColumnCount, fixedRowCount, list } = this.state;

      return (
        <div id="AutoSizerWrapper" style={styles.flexToFillAllTheSpace}>
          <AutoSizer>
            {({ width, height }) => (
              <MultiGrid
                // @ts-expect-error - suppressing the error until we add types for multigrid. TODO NOVA-1390
                ref={(ref) => this.setGridRef(ref)}
                fixedColumnCount={fixedColumnCount}
                fixedRowCount={fixedRowCount}
                cellRenderer={this.cellRenderer}
                columnWidth={this.setColumnWidth}
                columnCount={tableContents.columns.length}
                height={height}
                overscanRowCount={overscanRowCount}
                rowHeight={70}
                rowCount={list.length}
                width={width}
                styleBottomLeftGrid={styles.bottomLeftGrid}
                styleTopLeftGrid={styles.topLeftGrid}
                styleTopRightGrid={styles.topRightGrid}
              />
            )}
          </AutoSizer>
        </div>
      );
    }
    return (
      <div className="col span_2_of_12 push_5">
        <LoadingMessage />
      </div>
    );
  }

  getSelectedDonors = (
    searchResults: AdultSearchResults,
    selectedItems: Partial<SelectedResult>[]
  ): ExternalInvestigationSelectedDonor[] => {
    const selectedDonors: ExternalInvestigationSelectedDonor[] = [];
    selectedItems.forEach((donor) => {
      const selectedDonor = searchResults.results.find((r) => r.donor.id === donor.id);
      if (selectedDonor) {
        selectedDonors.push({
          id: selectedDonor.donor.homeRegistryId,
          grid: selectedDonor.donor.grid,
          originatingRegistry: selectedDonor.donor.originatingRegistry,
        });
      }
    });
    return selectedDonors;
  };

  isReadyToRenderTable = () => {
    const { algorithm, isFetching } = this.props;
    return !isFetching && algorithm;
  };

  adjustPopUpStyling = (popUpStyle: Record<string, unknown>) => {
    this.setState({ popUpStyle });
  };

  resizePopUp = () => {
    this.setState({ popUpStyle: initialPopUpStyle });
  };

  hidePopUp = () => {
    this.setState({ isPopUpShown: false });
  };

  handlePopUpClick = () => {
    this.setState({ isPopUpShown: true });
  };

  onPopUpClose = () => {
    const { updateSelectedItems } = this.props;
    updateSelectedItems([]);
  };

  setGridRef(ref: { recomputeGridSize: () => null }) {
    this.gridRef = ref;
  }

  // This is for defining the type of gridRef.
  gridRef = { recomputeGridSize: () => null };

  // eslint-disable-next-line react/no-unused-class-component-methods
  setRowHeight = ({ index }: { index: number }) => {
    if (this.isHeaderRow(index)) {
      return rowHeights.header;
    }
    return rowHeights.table;
  };

  setColumnWidth = ({ index }: { index: number }) => {
    const { tableContents } = this.props;
    const column = tableContents.columns[index];
    if (this.isColumnHidden(column.name as HideableColumn)) {
      return 0;
    }
    return column.width;
  };

  setRowClass = (rowIndex: number, id: number) => {
    const isSelected = this.isRowSelected(id);
    const isUntrustworthyDonor = this.isDonorUntrustworthy(id);
    const selectedClass = isSelected ? 'selected' : 'unselected';
    const untrustworthyClass = isUntrustworthyDonor ? 'untrustworthy' : null;
    const periodicityClass = rowIndex % 2 === 0 ? 'even' : 'odd';
    const rowClass = this.isHeaderRow(rowIndex) ? 'ReactVirtualized__Table__headerRow' : periodicityClass;

    return cn('cell', rowClass, selectedClass, untrustworthyClass);
  };

  clickRow = (id: string) => {
    const { mouseOverHla } = this.state;
    if (!mouseOverHla && id) {
      const { searchResults } = this.props;
      const row: SelectedRow = {
        id,
        matchGrade: undefined,
        matchType: undefined,
        registryId: undefined,
      };

      if (searchResults.type === donorTypes.adult.value) {
        const adultSearchResultList = searchResults.results as AdultSearchResult[];
        const result = adultSearchResultList.find((r) => r.donor.id === id);
        if (result) {
          row.matchGrade = result.matchGrade;
          row.matchType = result.matchType;
          row.registryId = _.get(result, 'donor.originatingRegistry.id', null);
        }
      }
      this.toggleSelectRow(row);
    }
  };

  toggleSelectRow = (donorRow: SelectedResult) => {
    const { selectedItems, updateSelectedItems } = this.props;
    this.setState({ popUpStyle: initialPopUpStyle });
    const newItemRows = _.xorBy(selectedItems, [donorRow], 'id');
    updateSelectedItems(newItemRows);
  };

  isHeaderRow = (rowIndex: number) => rowIndex === headerRowIndex;

  isRowSelected = (id: number) => {
    const { selectedItems } = this.props;
    return selectedItems.findIndex((item: Partial<SelectedResult>) => item.id === id.toString()) >= 0;
  };

  isDonorUntrustworthy = (id: number) => {
    const { searchResults } = this.props;
    const adultSearchResultList = searchResults.results as AdultSearchResult[];
    const untrustworthyDonorIds = adultSearchResultList
      .filter((item) => item.verificationStatus && !item.verificationStatus.isResultVerified)
      .map((item) => item.donor.id);
    return untrustworthyDonorIds.findIndex((item: any) => item === id) >= 0;
  };

  isColumnHidden = (columnName: HideableColumn) => {
    const { hiddenColumns } = this.props;
    return hiddenColumns.includes(columnName);
  };

  updateStateForRender(props: Props) {
    this.setState({
      list: props.tableContents.createRows(props.searchResults.results, props.tableContents.columns),
    });
  }

  thereAreNoResults = () => {
    const { isFetching, searchResults } = this.props;
    return !isFetching && searchResults && searchResults.results.length <= 0;
  };

  cellRenderer = ({ columnIndex, key, rowIndex, style }: GridCellProps) => {
    const { list } = this.state;
    const row = {
      columnIndex,
      key,
      rowIndex,
      style,
    };
    const rowId = list[rowIndex][1];
    const classNames = this.setRowClass(rowIndex, rowId);

    return (
      <div key={key} onClick={() => this.clickRow(rowId)} role="presentation">
        {this.innerCellRenderer(row, classNames)}
      </div>
    );
  };

  innerCellRenderer = (
    {
      columnIndex,
      key,
      rowIndex,
      style,
    }: { columnIndex: number; key: string; rowIndex: number; style: React.CSSProperties },
    classNames: string
  ) => {
    const { algorithm, donorType, resultSetId, tableContents } = this.props;
    const { list } = this.state;
    const cellContents = list[rowIndex][columnIndex];
    const rowId = list[rowIndex][1];
    const params: RenderCellParams<string> = {
      algorithm,
      data: cellContents,
      donorType,
      headerRowIndex,
      isRowSelected: this.isRowSelected(rowId),
      mouseLeftHla: this.mouseLeftHla,
      mouseMovedOverHla: this.mouseMovedOverHla,
      resultSetId,
      rowIndex,
    };

    if (this.isColumnHidden(tableContents.columns[columnIndex].name as HideableColumn)) {
      return null;
    }

    return (
      <DonorTableCellDisplay
        className={classNames}
        key={key}
        cellId={key}
        cellStyle={{ ...style, ...styles.cellFontSize }}
      >
        {tableContents.columns[columnIndex].columnType.render(params)}
      </DonorTableCellDisplay>
    );
  };

  mouseMovedOverHla = () => {
    this.setState({ mouseOverHla: true });
  };

  mouseLeftHla = () => {
    this.setState({ mouseOverHla: false });
  };
}

export default connector(ResultsTable);

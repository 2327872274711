/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { RouterMatch } from '../core/types';
import TransplantCentreTabs from './components/TransplantCentreTabs';
import './transplantCentreManagement.scss';

type OwnProps = {
  match: RouterMatch;
};
type Props = RouteComponentProps & OwnProps;

const TransplantCentreManagement = ({ match }: Props) => (
  <div className="tc-container">
    <h1 className="border-bottom-solid">TC Management</h1>
    <TransplantCentreTabs match={match} />
  </div>
);

export default TransplantCentreManagement;

import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import Select, { MultiValue, SingleValue } from 'react-select';
import { AnyAction, bindActionCreators, Dispatch as ReduxDispatch } from 'redux';

import * as actions from '../../../../redux/actions';
import { styles } from '../../../../style';
import Selectors from '../../../../redux/selectors';
import gridFormatter from '../../../../../core/helpers/gridFormatter';
import { filterNames } from '../../../../constants/filterNames';

import type { FilterOptions, ValuesFilterName, ValuesFilterType } from '../../../../types/index';

import type { Option } from '../../../../../core/types';
import type { ReduxState } from '../../../../../rootReducer';

type OwnProps = {
  filterName: ValuesFilterName;
  filterType: ValuesFilterType;
  isMulti?: boolean;
  selectOptions: FilterOptions;
  // eslint-disable-next-line react/no-unused-prop-types, react/require-default-props
  showCords?: boolean; // used in `mapStateToProps`
  menuPlacement?: 'auto' | 'bottom' | 'top';
};
type StateProps = {
  value: string[];
};
type Props = PropsFromRedux & OwnProps & StateProps;

const mapStateToProps = (state: ReduxState, ownProps: OwnProps): StateProps => ({
  value: Selectors.getValuesFilter(state, ownProps.filterType),
});

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>) => ({
  updateValuesFilter: bindActionCreators(actions.updateValuesFilter, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const formatFilterOption = (filterType: ValuesFilterType, option: Option): Option =>
  filterType === filterNames.grid
    ? {
        label: gridFormatter(option.value),
        value: option.value,
      }
    : option;

class Filter extends PureComponent<Props> {
  static defaultProps = {
    isMulti: false,
    menuPlacement: 'auto',
  };

  render() {
    const { isMulti, filterName, filterType, selectOptions, value } = this.props;

    const defaultValues = selectOptions.filter((option) => value.indexOf(option.value as string) !== -1);

    const formattedDefaultValues = defaultValues.map((option) => formatFilterOption(filterType, option));

    const formattedSelectOptions = selectOptions.map((option) => formatFilterOption(filterType, option));

    return (
      <div>
        {/* eslint-disable-next-line jsx-a11y/label-has-for */}
        <label htmlFor={filterName} style={styles.inputLabel}>
          {filterName} Filter
          <Select
            className="react-select-container"
            classNamePrefix="react-select"
            isClearable
            id={filterName}
            isMulti={isMulti}
            name={`${filterType}-filter`}
            onChange={this.handleFilterValueChange}
            options={formattedSelectOptions}
            value={formattedDefaultValues}
            menuPlacement={this.props.menuPlacement}
          />
        </label>
      </div>
    );
  }

  handleFilterValueChange = (values: SingleValue<Option> | MultiValue<Option>) => {
    const { filterType, updateValuesFilter } = this.props;
    let filterValues: string[] = [];
    const newValues = values as unknown as Option;
    if (newValues) {
      filterValues = Array.isArray(newValues) ? newValues.map((item) => item.value) : [newValues.value];
    }
    updateValuesFilter(filterType, filterValues);
  };
}

export default connector(Filter);

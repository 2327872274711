export enum InformationType {
  BoneMarrowOnlyForPersonalReasons = 'BoneMarrowOnlyForPersonalReasons',
  BoneMarrowOnlyForMedicalReasons = 'BoneMarrowOnlyForMedicalReasons',
  PbscOnlyForPersonalReasons = 'PbscOnlyForPersonalReasons',
  PbscOnlyForMedicalReasons = 'PbscOnlyForMedicalReasons',
  DonorNotAvailable = 'DonorNotAvailable',
  Multidonation = 'Multidonation',
  DonorUnavailableAfterBloodCollection = 'DonorUnavailableAfterBloodCollection',
  RiskDonorIsDeclinedInPhysicalExam = 'RiskDonorIsDeclinedInPhysicalExam',
  AdditionalInformationForCmv = 'AdditionalInformationForCmv',
  RegionWithHigherRiskOfInfection = 'RegionWithHigherRiskOfInfection',
  OtherMedical = 'OtherMedical',
  OtherNonMedical = 'OtherNonMedical',
}

export type ApiSampleInformation = {
  Remark: string;
  ReceivedDate: string;
  InformationType: InformationType;
};

export type ApiDonorDetails = {
  DonorUnavailableToTime: string;
  DonorStatus: string;
};

export interface ApiBaseExternalInvestigation {
  ActionRequired: boolean;
  DonorId: string;
  FailureSource: string;
  Grid: string;
  Id: number;
  IsIdmResultReceived: boolean;
  VirologyReportFilename: string;
  HlaReportFilename: string;
  IsTypingResultReceived: boolean;
  PatientId: string;
  PayingInstitutionId: string;
  RegistryId: string;
  RegistryName: string;
  Remark: string;
  RequestedTime: string;
  Status: string;
  StatusReason: string;
  StatusRemark: string;
  LastUpdatedTime: string;
  DonorDetails: ApiDonorDetails;
  ReservedForThisPatient: boolean;
}

export type ApiExternalInvestigationTextMessage = {
  Message: string;
  SentDate: string;
  ReceivedDate: string;
};

export type ApiIDMInvestigationDetailsBase = {
  InfectiousDiseaseMarkers: {
    BloodGroupRhesus: boolean;
    Cmv: boolean;
    Toxoplasmosis: boolean;
    Ebv: boolean;
    HivStatus: boolean;
    HivP24: boolean;
    HepatitisBSurfaceAntigen: boolean;
    AntibodyHepatitisBSurfaceAntigen: boolean;
    AntibodyHepatitisBCoreAntigen: boolean;
    AntibodyHepatitisC: boolean;
    LuesStatus: boolean;
    AltStatus: boolean;
    HltvAntibody: boolean;
  };
};
export type ApiIDMInvestigationDetails = ApiIDMInvestigationDetailsBase & {
  TextMessages: ApiExternalInvestigationTextMessage[];
};

export type ApiExtendedTypingInvestigationDetailsBase = {
  ResolutionRequired: {
    HlaA: string;
    HlaB: string;
    HlaC: string;
    HlaDrb1: string;
    HlaDrb3: string;
    HlaDrb4: string;
    HlaDrb5: string;
    HlaDqa1: string;
    HlaDqb1: string;
    HlaDpa1: string;
    HlaDpb1: string;
  };
  Urgent: boolean;
};
export type ApiExtendedTypingInvestigationDetails = ApiExtendedTypingInvestigationDetailsBase & {
  TextMessages: ApiExternalInvestigationTextMessage[];
};

export type ApiBloodRequirementType = {
  Quantity: number;
  NumberOfTubes: number;
};

type ApiAcceptableDaysType = {
  Monday: boolean;
  Tuesday: boolean;
  Wednesday: boolean;
  Thursday: boolean;
  Friday: boolean;
  Saturday: boolean;
  Sunday: boolean;
};

export type ApiVTInvestigationDetailsBase = {
  EdtaRequirements: ApiBloodRequirementType;
  AcdRequirements: ApiBloodRequirementType;
  HeparinRequirements: ApiBloodRequirementType;
  ClottedRequirements: ApiBloodRequirementType;
  Urgent: boolean;
  EarliestReceiptDate: string;
  LatestReceiptDate: string;
  AcceptableDays: ApiAcceptableDaysType;
  SampleRecipientInternationalInstitutionCode: string;
  SampleArrivalDate: string;
};

export type ApiVTInvestigationDetails = ApiVTInvestigationDetailsBase & {
  SampleInformations: ApiSampleInformation[];
  TextMessages: ApiExternalInvestigationTextMessage[];
};

export interface ApiIDMInvestigation extends ApiBaseExternalInvestigation {
  InvestigationDetails: ApiIDMInvestigationDetails;
  Type: 'IDM';
}

export interface ApiExtendedTypingInvestigation extends ApiBaseExternalInvestigation {
  InvestigationDetails: ApiExtendedTypingInvestigationDetails;
  Type: 'Extended Typing';
}

export interface ApiVTInvestigation extends ApiBaseExternalInvestigation {
  InvestigationDetails: ApiVTInvestigationDetails;
  Type: 'VT';
}

export type ApiExternalInvestigation = {
  IdmInvestigations: ApiIDMInvestigation[];
  ExtendedTypingInvestigations: ApiExtendedTypingInvestigation[];
  VerificationTypingInvestigations: ApiVTInvestigation[];
};

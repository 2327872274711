const WALES = 'WA';
const NHSBT = 'EN';
const DKMS = 'DBC';
const AN = 'GB';

export default {
  wales: WALES,
  nhsbt: NHSBT,
  dkms: DKMS,
  anthonyNolan: AN,
};

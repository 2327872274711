import {
  createDonorRows,
  convertCmvOrRhd,
  convertAbo,
  Column,
  columnTypes,
  HlaColumn,
  MatchCountColumn,
  NumberColumn,
  StringColumn,
  TableContents,
} from './searchResultsTableContents';
import type { CordSearchResult } from '../../../core/types';
import { formatDate } from '../../../core/helpers/dateHelpers';

const columns = [
  new Column<CordSearchResult, void>('RowNumber', '', () => undefined, columnTypes.rowNumber, 40),
  new Column<CordSearchResult, string>(
    'SelectionColumn',
    '',
    (searchResult) => searchResult.cord.cordId,
    columnTypes.selectColumn,
    40
  ),
  new MatchCountColumn<CordSearchResult>(
    'TotalMatch',
    'TM',
    (searchResult) => ({
      matchCount: searchResult.totalScore,
      matchCountDenominator: searchResult.matchCountDenominator,
    }),
    60
  ),
  new Column<CordSearchResult, string[]>(
    'Id',
    'Ids',
    (searchResult) => {
      const ids: string[] = [searchResult.cord.id];
      if (searchResult.cord.primaryId) {
        ids.push(searchResult.cord.primaryId);
      }
      return ids;
    },
    columnTypes.array,
    260
  ),
  new HlaColumn<CordSearchResult>('A', 'A', (searchResult) => ({
    antigens: [searchResult.cord.hlaA1, searchResult.cord.hlaA2],
  })),
  new HlaColumn<CordSearchResult>('B', 'B', (searchResult) => ({
    antigens: [searchResult.cord.hlaB1, searchResult.cord.hlaB2],
  })),
  new HlaColumn<CordSearchResult>('C', 'C', (searchResult) => ({
    antigens: [searchResult.cord.hlaC1, searchResult.cord.hlaC2],
  })),
  new HlaColumn<CordSearchResult>('DRB1', 'DRB1', (searchResult) => ({
    antigens: [searchResult.cord.drB11, searchResult.cord.drB12],
  })),
  new HlaColumn<CordSearchResult>('DRB3', 'DRB3', (searchResult) => ({
    antigens: [searchResult.cord.drB31, searchResult.cord.drB32],
  })),
  new HlaColumn<CordSearchResult>('DRB4', 'DRB4', (searchResult) => ({
    antigens: [searchResult.cord.drB41, searchResult.cord.drB42],
  })),
  new HlaColumn<CordSearchResult>('DRB5', 'DRB5', (searchResult) => ({
    antigens: [searchResult.cord.drB51, searchResult.cord.drB52],
  })),
  new HlaColumn<CordSearchResult>('DQA1', 'DQA1', (searchResult) => ({
    antigens: [searchResult.cord.dqA11, searchResult.cord.dqA12],
  })),
  new HlaColumn<CordSearchResult>('DQB1', 'DQB1', (searchResult) => ({
    antigens: [searchResult.cord.dqB11, searchResult.cord.dqB12],
  })),
  new HlaColumn<CordSearchResult>('DPA1', 'DPA1', (searchResult) => ({
    antigens: [searchResult.cord.dpA11, searchResult.cord.dpA12],
  })),
  new HlaColumn<CordSearchResult>('DPB1', 'DPB1', (searchResult) => ({
    antigens: [searchResult.cord.dpB11, searchResult.cord.dpB12],
  })),
  new NumberColumn<CordSearchResult>(
    'TncFinalCount',
    'TNC',
    (searchResult) => searchResult.cord.tncFinalCount,
    100,
    true
  ),
  new NumberColumn<CordSearchResult>('CD34', 'CD34', (searchResult) => searchResult.cord.cd34CellCount, 100, true),
  new MatchCountColumn<CordSearchResult>(
    'ActualMatch',
    'AM',
    (searchResult) => ({
      matchCount: searchResult.hlaActualMatchScore,
      matchCountDenominator: searchResult.matchCountDenominator,
    }),
    100,
    true
  ),
  new MatchCountColumn<CordSearchResult>(
    'PotentialMatch',
    'PM',
    (searchResult) => ({
      matchCount: searchResult.hlaPotentialMatchScore,
      matchCountDenominator: searchResult.matchCountDenominator,
    }),
    100,
    true
  ),
  new StringColumn<CordSearchResult>(
    'Abo',
    'ABO',
    (searchResult) => convertAbo(searchResult.cord.bloodGroup),
    100,
    true
  ),
  new StringColumn<CordSearchResult>(
    'RhD',
    'RhD',
    (searchResult) => convertCmvOrRhd(searchResult.cord.rhType),
    100,
    true
  ),
  new NumberColumn<CordSearchResult>(
    'CD45',
    'CD45',
    (searchResult) => searchResult.cord.viableCD45CellPercentage,
    100,
    true
  ),
  new NumberColumn<CordSearchResult>(
    'NC_ml',
    'NC_ml',
    (searchResult) => searchResult.cord.nucleatedCellsPerMl,
    100,
    true
  ),
  new StringColumn<CordSearchResult>(
    'CMV',
    'CMV',
    (searchResult) => convertCmvOrRhd(searchResult.cord.cmvAntibodyType),
    100,
    true
  ),
  new StringColumn<CordSearchResult>(
    'Tested',
    'Tested',
    (searchResult) => (searchResult.cord.dateCmvTested ? formatDate(searchResult.cord.dateCmvTested) : ''),
    100,
    true
  ),
  new NumberColumn<CordSearchResult>('YearBanked', 'Banked', (searchResult) => searchResult.cord.yearBanked, 110, true),
  new StringColumn<CordSearchResult>('State', 'State', (searchResult) => searchResult.cord.cordState || 'Unknown', 120),
  new StringColumn<CordSearchResult>(
    'UnavailableUntil',
    'Unavailable Until',
    (searchResult) => (searchResult.cord.unavailableUntil ? formatDate(searchResult.cord.unavailableUntil) : ''),
    130,
    true
  ),
];

const createRows = (searchResults: CordSearchResult[]) => createDonorRows<CordSearchResult>(searchResults, columns);

const tableContents: TableContents<CordSearchResult> = {
  columns,
  createRows,
};

export default tableContents;

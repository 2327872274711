import * as React from 'react';

type Props = {
  error?: string;
  id?: string;
};

const Error = ({ error, id }: Props) => {
  if (!error) {
    return null;
  }

  return (
    <div id={id} data-testid="error" className="error-message">
      {error}
    </div>
  );
};

Error.defaultProps = {
  error: undefined,
  id: undefined,
};

export default Error;

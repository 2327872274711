import React from 'react';
import ValueCell from './ValueCell';

type Props = {
  matchCount: number;
  matchCountDenominator: number | null | undefined;
};

const DEFAULT_MATCH_COUNT_DENOMINATOR = 6;

export const MatchCountCell = ({ matchCount, matchCountDenominator }: Props) => {
  const matchCountForSearchRun = `${matchCount}/${matchCountDenominator || DEFAULT_MATCH_COUNT_DENOMINATOR}`;

  return <ValueCell data={matchCountForSearchRun} />;
};

import React, { CSSProperties } from 'react';

type Props = {
  isLoading?: boolean;
  isButton?: boolean;
  children?: React.PropsWithChildren<any> | null;
  loadingStyle?: CSSProperties;
  spinnerStyle?: CSSProperties;
};

export const LoadingMessage = (props: Props) => {
  // TODO: add loadingStyle and spinnerStyle
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isLoading, children, isButton, loadingStyle, spinnerStyle } = props;

  const sizeClass = isButton ? 'loader--btn' : 'small';

  if (isLoading) {
    return <div className={`loader ${sizeClass}`} />;
  }
  return children || null;
};

LoadingMessage.defaultProps = {
  isLoading: true,
  isButton: false,
  children: undefined,
  loadingStyle: undefined,
  spinnerStyle: undefined,
};

export default LoadingMessage;

import _ from 'lodash';
import { externalInvestigationTypes } from '../../../core/constants/externalInvestigationTypes';
import { convertPatientHla } from '../../../donorMatchSearchRequests/helpers/apiDataConverter';
import {
  BaseExternalInvestigation,
  BloodRequirementType,
  DonorDetails,
  ExtendedTypingInvestigation,
  ExtendedTypingInvestigationDetails,
  ExternalInvestigation,
  ExternalInvestigationReportStatus,
  ExternalInvestigationTextMessage,
  IDMInvestigation,
  IDMInvestigationDetails,
  SampleInformation,
  VTInvestigation,
  VTInvestigationDetails,
} from '../../../externalInvestigations/types';
import type {
  ApiBaseExternalInvestigation,
  ApiBloodRequirementType,
  ApiDonorDetails,
  ApiExtendedTypingInvestigation,
  ApiExtendedTypingInvestigationDetails,
  ApiExternalInvestigation,
  ApiExternalInvestigationTextMessage,
  ApiIDMInvestigation,
  ApiIDMInvestigationDetails,
  ApiSampleInformation,
  ApiVTInvestigation,
  ApiVTInvestigationDetails,
} from '../../../externalInvestigations/types/api';
import { InformationType } from '../../../externalInvestigations/types/api';
import { formatGeneticDetails } from '../../../hlaInput/helpers/geneticDetailsFormatter';
import type {
  InternationalSearchCreationConstants,
  VerificationTypingResultToSave,
  VerificationTypingResult,
  ApiVerificationTypingResult,
  ApiHlaSummary,
} from '../types';
import { HlaSummary } from '../types';

export const convertInternationalSearchCreationConstants = (
  apiCreationConstants: any
): InternationalSearchCreationConstants => ({
  defaultMaxAbDonors: apiCreationConstants.DefaultMaxAbDonors,
  defaultMaxAbdrDonors: apiCreationConstants.DefaultMaxAbdrDonors,
  defaultMaxCordDonors: apiCreationConstants.DefaultMaxCordDonors,
});

const getReportStatus = (isResultReceived: boolean, filename: string | null): ExternalInvestigationReportStatus => {
  if (!isResultReceived) {
    return ExternalInvestigationReportStatus.NoDataAvailable;
  }

  if (filename) {
    return ExternalInvestigationReportStatus.ReadyToDownload;
  }

  return ExternalInvestigationReportStatus.ReadyToGenerate;
};

const convertDonorDetailsFromApi = (donor: ApiDonorDetails): DonorDetails => ({
  donorStatus: donor.DonorStatus,
  donorUnavailableToTime: donor.DonorUnavailableToTime,
});

const convertTextMessagesFromApi = (
  textMessages: ApiExternalInvestigationTextMessage
): ExternalInvestigationTextMessage => ({
  sentDate: textMessages.SentDate,
  receivedDate: textMessages.ReceivedDate,
  message: textMessages.Message,
});

const convertBaseExternalInvestigationFromApi = (
  externalInvestigation: ApiBaseExternalInvestigation
): BaseExternalInvestigation => ({
  actionRequired: externalInvestigation.ActionRequired,
  donorId: externalInvestigation.DonorId,
  failureSource: externalInvestigation.FailureSource,
  grid: externalInvestigation.Grid,
  id: externalInvestigation.Id,
  reports: {
    virologyReportDetails: {
      reportFilename: externalInvestigation.VirologyReportFilename,
      reportStatus: getReportStatus(
        externalInvestigation.IsIdmResultReceived,
        externalInvestigation.VirologyReportFilename
      ),
    },
    hlaReportDetails: {
      reportFilename: externalInvestigation.HlaReportFilename,
      reportStatus: getReportStatus(
        externalInvestigation.IsTypingResultReceived,
        externalInvestigation.HlaReportFilename
      ),
    },
  },
  patientId: externalInvestigation.PatientId,
  payingInstitutionId: externalInvestigation.PayingInstitutionId,
  registryId: externalInvestigation.RegistryId,
  registryName: externalInvestigation.RegistryName,
  remark: externalInvestigation.Remark,
  requestedTime: externalInvestigation.RequestedTime,
  status: externalInvestigation.Status,
  statusReason: externalInvestigation.StatusReason,
  statusRemark: externalInvestigation.StatusRemark,
  lastUpdatedTime: externalInvestigation.LastUpdatedTime,
  donorDetails: convertDonorDetailsFromApi(externalInvestigation.DonorDetails),
  isIdmResultReceived: externalInvestigation.IsIdmResultReceived,
  isTypingResultReceived: externalInvestigation.IsTypingResultReceived,
  reservedForThisPatient: externalInvestigation.ReservedForThisPatient,
});

export const convertIdmInvestigationDetailsFromApi = (
  idmInvestigationDetails: ApiIDMInvestigationDetails
): IDMInvestigationDetails => ({
  infectiousDiseaseMarkers: {
    bloodGroupRhesus: idmInvestigationDetails.InfectiousDiseaseMarkers.BloodGroupRhesus,
    cmv: idmInvestigationDetails.InfectiousDiseaseMarkers.Cmv,
    toxoplasmosis: idmInvestigationDetails.InfectiousDiseaseMarkers.Toxoplasmosis,
    ebv: idmInvestigationDetails.InfectiousDiseaseMarkers.Ebv,
    hivStatus: idmInvestigationDetails.InfectiousDiseaseMarkers.HivStatus,
    hivP24: idmInvestigationDetails.InfectiousDiseaseMarkers.HivP24,
    hepatitisBSurfaceAntigen: idmInvestigationDetails.InfectiousDiseaseMarkers.HepatitisBSurfaceAntigen,
    antibodyHepatitisBSurfaceAntigen: idmInvestigationDetails.InfectiousDiseaseMarkers.AntibodyHepatitisBSurfaceAntigen,
    antibodyHepatitisBCoreAntigen: idmInvestigationDetails.InfectiousDiseaseMarkers.AntibodyHepatitisBCoreAntigen,
    antibodyHepatitisC: idmInvestigationDetails.InfectiousDiseaseMarkers.AntibodyHepatitisC,
    luesStatus: idmInvestigationDetails.InfectiousDiseaseMarkers.LuesStatus,
    altStatus: idmInvestigationDetails.InfectiousDiseaseMarkers.AltStatus,
    hltvAntibody: idmInvestigationDetails.InfectiousDiseaseMarkers.HltvAntibody,
  },
  textMessages: idmInvestigationDetails.TextMessages.map(convertTextMessagesFromApi),
});

const convertIdmInvestigationFromApi = (idmInvestigation: ApiIDMInvestigation): IDMInvestigation => ({
  ...convertBaseExternalInvestigationFromApi(idmInvestigation),
  investigationDetails: convertIdmInvestigationDetailsFromApi(idmInvestigation.InvestigationDetails),
  type: externalInvestigationTypes.idm.value,
});

export const convertExtendedTypingInvestigationDetailsFromApi = (
  extendedTypingInvestigationDetails: ApiExtendedTypingInvestigationDetails
): ExtendedTypingInvestigationDetails => ({
  resolutionRequired: {
    hlaA: extendedTypingInvestigationDetails.ResolutionRequired.HlaA,
    hlaB: extendedTypingInvestigationDetails.ResolutionRequired.HlaB,
    hlaC: extendedTypingInvestigationDetails.ResolutionRequired.HlaC,
    hlaDrb1: extendedTypingInvestigationDetails.ResolutionRequired.HlaDrb1,
    hlaDrb3: extendedTypingInvestigationDetails.ResolutionRequired.HlaDrb3,
    hlaDrb4: extendedTypingInvestigationDetails.ResolutionRequired.HlaDrb4,
    hlaDrb5: extendedTypingInvestigationDetails.ResolutionRequired.HlaDrb5,
    hlaDqa1: extendedTypingInvestigationDetails.ResolutionRequired.HlaDqa1,
    hlaDqb1: extendedTypingInvestigationDetails.ResolutionRequired.HlaDqb1,
    hlaDpa1: extendedTypingInvestigationDetails.ResolutionRequired.HlaDpa1,
    hlaDpb1: extendedTypingInvestigationDetails.ResolutionRequired.HlaDpb1,
  },
  urgent: extendedTypingInvestigationDetails.Urgent,
  textMessages: extendedTypingInvestigationDetails.TextMessages.map(convertTextMessagesFromApi),
});

const convertExtendedTypingInvestigationFromApi = (
  typingInvestigation: ApiExtendedTypingInvestigation
): ExtendedTypingInvestigation => ({
  ...convertBaseExternalInvestigationFromApi(typingInvestigation),
  investigationDetails: convertExtendedTypingInvestigationDetailsFromApi(typingInvestigation.InvestigationDetails),
  type: externalInvestigationTypes.extendedTyping.value,
});

export const convertBloodRequirementTypeFromApi = (
  bloodRequirementType: ApiBloodRequirementType
): BloodRequirementType => ({
  numberOfTubes: bloodRequirementType.NumberOfTubes,
  quantity: bloodRequirementType.Quantity,
});

const convertInformationTypeFromApi = (informationType: InformationType): string => {
  switch (informationType) {
    case InformationType.BoneMarrowOnlyForPersonalReasons:
      return 'Donor only available for bone marrow donation for personal reasons ';
    case InformationType.BoneMarrowOnlyForMedicalReasons:
      return 'Donor only available for bone marrow donation for medical reasons';
    case InformationType.PbscOnlyForPersonalReasons:
      return 'Donor only available for PBSC donation for personal reasons';
    case InformationType.PbscOnlyForMedicalReasons:
      return 'Donor only available for PBSC donation for medical reasons ';
    case InformationType.DonorNotAvailable:
      return '(Absent) donor not available for a certain period of time ';
    case InformationType.Multidonation:
      return 'Multidonation';
    case InformationType.DonorUnavailableAfterBloodCollection:
      return 'Donor unavailable after blood collection ';
    case InformationType.RiskDonorIsDeclinedInPhysicalExam:
      return 'Increased risk that donor is declined during physical examination';
    case InformationType.AdditionalInformationForCmv:
      return 'Additional information regarding CMV status ';
    case InformationType.RegionWithHigherRiskOfInfection:
      return 'Donor was in a region with a higher risk of infection';
    case InformationType.OtherMedical:
      return 'Other medical information ';
    case InformationType.OtherNonMedical:
      return 'Other non-medical information';
    default:
      return 'Unknown information type';
  }
};

const convertSampleInformationTypeFromApi = (apiSampleInformations: ApiSampleInformation[]): SampleInformation[] => {
  const sampleModelFromApi = (apiModel: ApiSampleInformation): SampleInformation => ({
    informationType: convertInformationTypeFromApi(apiModel.InformationType),
    remark: apiModel.Remark,
    receivedDate: apiModel.ReceivedDate,
  });
  return apiSampleInformations.map(sampleModelFromApi);
};

export const convertVTInvestigationDetailsFromApi = (
  vtInvestigationDetails: ApiVTInvestigationDetails
): VTInvestigationDetails => ({
  edtaRequirements: convertBloodRequirementTypeFromApi(vtInvestigationDetails.EdtaRequirements),
  acdRequirements: convertBloodRequirementTypeFromApi(vtInvestigationDetails.AcdRequirements),
  heparinRequirements: convertBloodRequirementTypeFromApi(vtInvestigationDetails.HeparinRequirements),
  clottedRequirements: convertBloodRequirementTypeFromApi(vtInvestigationDetails.ClottedRequirements),
  urgent: vtInvestigationDetails.Urgent,
  earliestReceiptDate: vtInvestigationDetails.EarliestReceiptDate,
  latestReceiptDate: vtInvestigationDetails.LatestReceiptDate,
  acceptableDays: {
    monday: vtInvestigationDetails.AcceptableDays.Monday,
    tuesday: vtInvestigationDetails.AcceptableDays.Tuesday,
    wednesday: vtInvestigationDetails.AcceptableDays.Wednesday,
    thursday: vtInvestigationDetails.AcceptableDays.Thursday,
    friday: vtInvestigationDetails.AcceptableDays.Friday,
    saturday: vtInvestigationDetails.AcceptableDays.Saturday,
    sunday: vtInvestigationDetails.AcceptableDays.Sunday,
  },
  sampleArrivalDate: vtInvestigationDetails.SampleArrivalDate,
  sampleRecipientInternationalInstitutionCode: vtInvestigationDetails.SampleRecipientInternationalInstitutionCode,
  sampleInformations: convertSampleInformationTypeFromApi(vtInvestigationDetails.SampleInformations),
  textMessages: vtInvestigationDetails.TextMessages.map(convertTextMessagesFromApi),
});

const convertVTInvestigationFromApi = (vtInvestigation: ApiVTInvestigation): VTInvestigation => ({
  ...convertBaseExternalInvestigationFromApi(vtInvestigation),
  investigationDetails: convertVTInvestigationDetailsFromApi(vtInvestigation.InvestigationDetails),
  type: externalInvestigationTypes.vt.value,
});

export const convertExternalInvestigationsFromApi = (
  apiExternalInvestigation: ApiExternalInvestigation
): ExternalInvestigation[] => {
  const idmInvestigations = apiExternalInvestigation.IdmInvestigations.map(convertIdmInvestigationFromApi);
  const typingInvestigations = apiExternalInvestigation.ExtendedTypingInvestigations.map(
    convertExtendedTypingInvestigationFromApi
  );
  const vtInvestigations = apiExternalInvestigation.VerificationTypingInvestigations.map(convertVTInvestigationFromApi);
  return [...idmInvestigations, ...typingInvestigations, ...vtInvestigations];
};
export const convertVerificationTypingResultFromApi = (
  apiExternalInvestigationResult: ApiVerificationTypingResult
): VerificationTypingResult => ({
  verificationTypingInvestigationId: apiExternalInvestigationResult.VerificationTypingInvestigationId,
  grid: apiExternalInvestigationResult.Grid,
  donorId: apiExternalInvestigationResult.DonorId,
  patientId: apiExternalInvestigationResult.PatientId,
  externalRegistry: apiExternalInvestigationResult.ExternalRegistry,
  originalHla: convertPatientHla(apiExternalInvestigationResult.OriginalHla),
  abo: apiExternalInvestigationResult.Bloodgroup,
  cmv: apiExternalInvestigationResult.Cmv,
  rhd: apiExternalInvestigationResult.RhD,
  updatedHla: apiExternalInvestigationResult.UpdatedHla
    ? convertPatientHla(apiExternalInvestigationResult.UpdatedHla)
    : undefined,
  enteredBy: apiExternalInvestigationResult.EnteredBy,
  enteredOn: apiExternalInvestigationResult.EnteredOn,
  sentBy: apiExternalInvestigationResult.SentBy,
  sentOn: apiExternalInvestigationResult.SentOn,
  comment: apiExternalInvestigationResult.Comment,
  isCompleted: apiExternalInvestigationResult.IsCompleted,
  isDonorReserved: apiExternalInvestigationResult.IsDonorReserved,
});
const convertApiRhesus = (apiRhesus?: string): string => {
  switch (apiRhesus) {
    case 'Positive':
      return '+';
    case 'Negative':
      return '-';
    default:
      return '';
  }
};
export const convertApiHlaSummary = (apiHlaSummary: ApiHlaSummary): HlaSummary => ({
  bloodGroup: apiHlaSummary.BloodGroup,
  dateReceived: apiHlaSummary.DateReceived,
  registryName: apiHlaSummary.RegistryName,
  remark: apiHlaSummary.Remark,
  rhesus: convertApiRhesus(apiHlaSummary.Rhesus),
  originalHla: convertPatientHla(apiHlaSummary.OriginalHla),
  updatedHla: convertPatientHla(apiHlaSummary.UpdatedHla),
});

export const convertVerificationTypingResultToApi = (
  updatedVerificationTypingResult: VerificationTypingResultToSave
) => {
  const formattedGeneticDetails = formatGeneticDetails(updatedVerificationTypingResult.updatedHla);
  const formattedGeneticDetailsObject = _.merge({}, ...formattedGeneticDetails);
  const verificationTypingResults = {
    Comment: updatedVerificationTypingResult.comment,
    IsCompleted: updatedVerificationTypingResult.isCompleted,
    UpdatedHla: formattedGeneticDetailsObject,
  };
  return verificationTypingResults;
};

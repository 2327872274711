import React from 'react';

type Props = {
  checked: boolean;
  name: string;
  id: string;
  onChange?: (arg0: boolean) => void;
  disabled?: boolean;
};

const CountryCheckbox = (props: Props) => {
  const { checked, name, id, onChange, disabled } = props;

  return (
    <div className="form-checkbox-wrapper" style={{ textAlign: 'center', width: '100%' }}>
      <input
        checked={checked}
        value={checked ? 'on' : 'off'}
        disabled={disabled}
        id={id}
        name={name}
        type="checkbox"
        onChange={onChange ? (event) => onChange(event.currentTarget.checked) : undefined}
      />
    </div>
  );
};

CountryCheckbox.defaultProps = {
  disabled: false,
  onChange: undefined,
};

export default CountryCheckbox;

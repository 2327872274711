import _ from 'lodash';
import type { LocusMatchCategory, Option } from '../../core/types';

const API_MISMATCH: 'Mismatch' = 'Mismatch';
const API_MATCH: 'Match' = 'Match';
const API_PERMISSIVE_MISMATCH: 'PermissiveMismatch' = 'PermissiveMismatch';
const API_UNKNOWN: 'Unknown' = 'Unknown';

type LocusMatchCategoryKeys = 'nonPermissive' | 'match' | 'permissive' | 'unknown';

export const locusMatchCategories = {
  nonPermissive: {
    matchCategoryCode: 'NP',
    id: API_MISMATCH,
    displayValue: 'Non-Permissive',
  },
  match: {
    matchCategoryCode: 'M',
    id: API_MATCH,
    displayValue: 'Match',
  },
  permissive: {
    matchCategoryCode: 'P',
    id: API_PERMISSIVE_MISMATCH,
    displayValue: 'Permissive',
  },
  unknown: {
    matchCategoryCode: 'U',
    id: API_UNKNOWN,
    displayValue: 'Unknown',
  },
};

export const getLocusMatchCategoryKey = (
  locusHasValue: boolean,
  resultLocusMatchCategory: LocusMatchCategory | null | undefined
): string | undefined => {
  // If the locus does not have value, we show Match Category as Unknown
  if (!locusHasValue) {
    return locusMatchCategories.unknown.matchCategoryCode;
  }

  // If the locus has value, but the Match Category was not returned, we don't show the Match Category
  if (resultLocusMatchCategory == null) {
    return undefined;
  }

  // If the locus has value, and the Match Category was returned, we show the returned Match Category value
  const locusMatchCategory = _.find(locusMatchCategories, (category) => category.id === resultLocusMatchCategory);
  return locusMatchCategory?.matchCategoryCode;
};

export const getLocusMatchCategoryDisplayValue = (resultLocusMatchCategory: string) => {
  const locusMatchCategory = _.find(locusMatchCategories, (category) => category.id === resultLocusMatchCategory);
  return locusMatchCategory?.displayValue;
};

export const getLocusMatchCategoryFilterOptions = (): Option[] =>
  Object.keys(locusMatchCategories).map((matchCategory: string) => {
    const { displayValue, matchCategoryCode } = locusMatchCategories[matchCategory as LocusMatchCategoryKeys];
    const filterLabel = `${displayValue} (${matchCategoryCode})`;

    return {
      label: filterLabel,
      value: locusMatchCategories[matchCategory as LocusMatchCategoryKeys].id,
    };
  });

import { InstitutionAddress } from '../../../core/types';

export const formatInstitutionAdresses = (institutionAddresses: InstitutionAddress[]) => {
  const format = institutionAddresses.map((institutionAddress) => ({
    Id: institutionAddress.Id,
    InstitutionId: institutionAddress.InstitutionId,
    AddressType: institutionAddress.AddressType,
    Name: institutionAddress.Name,
    Address1: institutionAddress.Address1,
    Address2: institutionAddress.Address2,
    Address3: institutionAddress.Address3,
    Town: institutionAddress.Town,
    County: institutionAddress.County,
    Postcode: institutionAddress.Postcode,
    CountryType: institutionAddress.CountryType,
  }));
  return format;
};

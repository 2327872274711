/* eslint-disable array-callback-return */
import _ from 'lodash';
import moment from 'moment';
import { formatAddress } from '../../../core/helpers/formatAddress';
import { institutionAddressTypes } from '../../core/constants/institutionAddressTypes';
import type { PatientInfo, Institution } from '../../types';
import type { InstitutionAddress } from '../../../core/types';

export type Option = {
  label: string;
  value: string;
};

type Values = {
  surname: string;
  firstName: string;
  weight: string;
  dateOfBirth: number;
};

type Errors = {
  surname: string;
  firstName: string;
  weight: string;
  dateOfBirth: string;
};

type Warnings = {
  dateOfBirth: string;
};

const WEIGHT_REGEX = /^\d+$/;
export const PATIENT_STATUS_OPEN = 'Open';

const getNameChanges = (info: PatientInfo) => ({
  cmvType: info.cmv,
  bloodGroup: info.abo,
  rhType: info.rhd,
  internationalFileLocation: info.foreignSearchLocation,
  cordFileLocation: info.cordSearchLocation,
  ukOrGaisFileLocation: info.temporaryFileLocation,
  reasonClosed: info.closureReasonType,
});

const formatDateForDropdown = (date: moment.Moment | null | undefined) => date && date.format('YYYY-MM-DD');

const getReformattedFields = (info: PatientInfo) => ({
  dateRegistered: formatDateForDropdown(info.dateRegistered),
  dateClosed: formatDateForDropdown(info.closureDate),
  dateOfDeath: formatDateForDropdown(info.deathDate),
  dateOfBirth: formatDateForDropdown(info.dateOfBirth),
  diagnosisDate: formatDateForDropdown(info.diagnosisDate),
  cmvTestedDate: formatDateForDropdown(info.cmvTestedDate),
  externalLaboratory: parseInt(info.laboratoryId, 10),
  hospital: parseInt(info.hospital, 10),
  invoicee: parseInt(info.invoicee, 10),
  originatingRegistry: parseInt(info.originatingRegistryId, 10),
});

export const warn = (values: Values) => {
  const warnings: Warnings = {
    dateOfBirth: '',
  };
  if (values.dateOfBirth && moment().diff(values.dateOfBirth) <= 0) {
    warnings.dateOfBirth = 'The selected birth date is in the future';
  }
  return warnings;
};

export const mapSelectOptions = (options: string[]): Option[] =>
  _.sortBy(
    _.uniqBy(
      options.map((option) => ({
        label: option,
        value: option,
      })),
      (option) => option.label
    ),
    'label'
  );

export const mapInstitutionOptions = (options: Institution[]): Option[] =>
  _.uniqBy(
    options.map((option) => ({ value: option.Id, label: option.Name })),
    (option) => option.label
  );

export const mapInvoiceeAddressOptions = (listOptions: InstitutionAddress[], existingValue?: number | undefined) => {
  const addressOptions = listOptions.filter(
    (address) =>
      address.AddressType === institutionAddressTypes.billing || address.AddressType === institutionAddressTypes.main
  );

  const options = _.sortBy(
    addressOptions.map((option) => ({
      label: formatAddress(option),
      value: option?.Id,
    })),
    (option) => option.label,
    'label'
  );

  // We append the existing select values, even if they are not in the available options
  // This is to maintain backwards compatibility with records created with now obsolete values
  return options.some((option) => option.value === existingValue || !existingValue)
    ? options
    : [
        {
          label: `Institution deprecated (${existingValue || ''})`,
          value: existingValue,
        },
        ...options,
      ];
};

export const validate = (values: Values) => {
  const errors: Errors = {
    surname: '',
    firstName: '',
    weight: '',
    dateOfBirth: '',
  };
  if (!values.surname) {
    errors.surname = 'Required';
  }
  if (!values.firstName) {
    errors.firstName = 'Required';
  }
  if (values.weight && !WEIGHT_REGEX.test(values.weight)) {
    errors.weight = 'Must be a whole number';
  }
  if (!values.dateOfBirth) {
    errors.dateOfBirth = 'Required';
  }
  return errors;
};

export const getInitialValues = (patientInfo: PatientInfo) => {
  const defaults = {
    status: PATIENT_STATUS_OPEN,
    dateRegistered: moment(),
    gender: 'Unknown',
    ethnicity: 'Unknown',
    patientType: 'ANBMT',
    cmv: 'Unknown',
  };
  const merged = { ...defaults, ...patientInfo };
  return {
    ...merged,
    ...getReformattedFields(merged),
    ...getNameChanges(merged),
  };
};

import ReactTooltip from 'react-tooltip';
import * as React from 'react';

export type Props = {
  id: string;
  children: React.ReactChildren | string;
};

export const InfoTooltip = ({ id, children }: Props) => (
  <div data-testid="info-tooltip">
    <div data-tip data-for={id} className="info-tooltip__circle">
      ℹ
    </div>
    <ReactTooltip id={id} aria-haspopup>
      {children}
    </ReactTooltip>
  </div>
);

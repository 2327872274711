import React from 'react';
import moment from 'moment';
import { connect, ConnectedProps } from 'react-redux';
import { AutoSizer, CellMeasurerCache, Column, Table } from 'react-virtualized';
import { Link } from 'react-router-dom';
import SearchTypesCell from './SearchTypesCell';
import Selectors from '../../../../core/redux/selectors';
import { isOddNumber } from '../../../../../core/helpers/arrayHelper';
import { NoResultsFound, reportTypes } from '../../../../../core';

import type { ApiSavedReportMinimal } from '../../../../../searchReports/types/api';
import type { ReduxState } from '../../../../../rootReducer';
import { ApiSearchType } from '../../../../../core/constants/searchTypes';

type OwnProps = {
  patientId: string;
};
type StateProps = {
  patientSavedReports: ApiSavedReportMinimal[];
};
type Props = PropsFromRedux & OwnProps & StateProps;

const reportsPageLink = (id: string, patientId: string, isInternationalReport: boolean) => {
  const reportType = isInternationalReport ? reportTypes.international : reportTypes.internal;
  return (
    <Link to={`/patient/${patientId}/reports/${id}?reportType=${reportType}`} className="btn">
      View Report
    </Link>
  );
};

const dateCell = (date: Date, user?: string) => (
  <div>
    <div>{moment(date).format('DD-MM-YYYY')}</div>
    <div>{user}</div>
  </div>
);

const dateAuthorised = (date: Date, reports: ApiSavedReportMinimal[]) => {
  const search = reports.find((x) => x.DateAuthorised === date);
  const user = search ? search.Authoriser : undefined;

  return dateCell(date, user);
};

const dateCreated = (date: Date, reports: ApiSavedReportMinimal[]) => {
  const search = reports.find((x) => x.DateGenerated === date);
  const user = search ? search.Author : undefined;

  return dateCell(date, user);
};

const internationalOrInternalLabel = (isInternational: boolean) => (isInternational ? 'INT' : 'UK');

const mapStateToProps = (state: ReduxState, ownProps: OwnProps): StateProps => ({
  patientSavedReports: Selectors.getPatientsSavedReports(state, ownProps.patientId),
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const cache = new CellMeasurerCache({
  fixedWidth: true,
  minHeight: 50,
});

const SavedReportsTable = ({ patientSavedReports, patientId }: Props) => {
  const sortedReportsByDate = patientSavedReports
    // Flow throws an error here but this is the recommended way to sort Moment.js dates:
    // https://github.com/moment/moment/issues/1493
    // $FlowExpectedError - suppressing the error.
    .sort((a, b) => (moment(b.DateGenerated) as unknown as number) - (moment(a.DateGenerated) as unknown as number));

  if (patientSavedReports.length === 0) {
    return <NoResultsFound resultType="reports" />;
  }

  return (
    <div style={{ display: 'flex' }}>
      <div
        style={{
          flex: '1 1 auto',
          height: '400px',
        }}
      >
        <AutoSizer>
          {({ width, height }) => (
            <Table
              className="results-table"
              deferredMeasurementCache={cache}
              width={width}
              height={height}
              headerHeight={50}
              rowHeight={cache.rowHeight}
              headerClassName="headerClassName"
              rowCount={sortedReportsByDate.length}
              rowGetter={({ index }) => sortedReportsByDate[index]}
              rowClassName={({ index }) => (isOddNumber(index) ? 'odd' : 'even')}
            >
              <Column label="Report ID" dataKey="Id" width={200} />
              <Column
                label="Type"
                dataKey="IsInternational"
                width={200}
                cellRenderer={({ cellData }) => internationalOrInternalLabel(cellData)}
              />
              <Column label="Status" dataKey="ReportStatus" width={300} />
              <Column
                label="Search Types"
                dataKey="Types"
                width={600}
                // eslint-disable-next-line react/no-unstable-nested-components
                cellRenderer={({ dataKey, parent, rowIndex }) => (
                  <SearchTypesCell
                    cache={cache}
                    data={sortedReportsByDate[rowIndex].Types as ApiSearchType[]}
                    dataKey={dataKey}
                    parent={parent}
                    rowIndex={rowIndex}
                  />
                )}
              />
              <Column
                label="Created"
                dataKey="DateGenerated"
                width={300}
                cellRenderer={({ cellData }) => dateCreated(cellData, patientSavedReports)}
              />
              <Column
                label="Authorised"
                dataKey="DateAuthorised"
                width={300}
                cellRenderer={({ cellData }) => dateAuthorised(cellData, patientSavedReports)}
              />
              <Column
                width={300}
                label="View Reports"
                dataKey="Id"
                cellRenderer={({ cellData, rowData }) => reportsPageLink(cellData, patientId, rowData.IsInternational)}
              />
            </Table>
          )}
        </AutoSizer>
      </div>
    </div>
  );
};

export default connector(SavedReportsTable);

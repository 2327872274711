import React, { PureComponent } from 'react';
import { AnyAction, bindActionCreators, Dispatch as ReduxDispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import _ from 'lodash';

import Selectors from '../../../../redux/selectors';
import * as actions from '../../../../redux/actions';

import type { AdultSearchResult, CordSearchResult, DonorType, SearchResults } from '../../../../../core/types';
import donorTypes from '../../../../../core/constants/donorTypes';
import type { ReduxState } from '../../../../../rootReducer';

type OwnProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  resultSetId: string;
  // used in `mapStateToProps`
  // eslint-disable-next-line react/no-unused-prop-types
  donorType: DonorType; // used in `mapStateToProps`
};
type StateProps = {
  filteredSearchResults?: SearchResults;
  // eslint-disable-next-line react/no-unused-prop-types
  visibleSelectedIds: string[]; // used in `isSelected`
};
type Props = PropsFromRedux & OwnProps & StateProps;
type State = {
  isSelected: boolean | undefined;
};

const mapStateToProps = (state: ReduxState, ownProps: OwnProps): StateProps => ({
  filteredSearchResults: Selectors.getFilteredSearchResults(state, ownProps.resultSetId, ownProps.donorType),
  visibleSelectedIds: Selectors.getVisibleSelectedIds(state, ownProps.resultSetId, ownProps.donorType),
});

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>) => ({
  updateSelectedItems: bindActionCreators(actions.updateSelectedItems, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export class SelectAllItemsCell extends PureComponent<Props, State> {
  static defaultProps = {
    filteredSearchResults: undefined,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      isSelected: false,
    };
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    const isSelected = this.isSelected(this.props);
    this.setState({ isSelected });
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps: Props) {
    const isSelected = this.isSelected(nextProps);
    this.setState({ isSelected });
  }

  render() {
    const { isSelected } = this.state;
    return (
      <label htmlFor="selectDonorAll">
        <input
          type="checkbox"
          name="selectDonorAll"
          value={0}
          onChange={this.toggleSelectAllItems}
          style={{ margin: 'auto' }}
          checked={isSelected}
        />
      </label>
    );
  }

  isSelected = ({ filteredSearchResults, visibleSelectedIds }: Props) =>
    filteredSearchResults && visibleSelectedIds.length === filteredSearchResults.results.length;

  toggleSelectAllItems = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { filteredSearchResults, updateSelectedItems } = this.props;

    if (!event.target.checked || !filteredSearchResults) {
      updateSelectedItems([]);
    } else {
      const newSelectedRows =
        filteredSearchResults.type === donorTypes.cord.value
          ? filteredSearchResults.results.map((result) => {
              const cordSearchResult = result as CordSearchResult;
              return {
                id: cordSearchResult.cord.cordId,
                totalScore: result.totalScore,
              };
            })
          : filteredSearchResults.results.map((result) => {
              const adultSearchResult = result as AdultSearchResult;
              return {
                id: adultSearchResult.donor.id,
                matchGrade: adultSearchResult.matchGrade,
                matchType: adultSearchResult.matchType,
                registryId: _.get(result, 'donor.originatingRegistry.id', null),
              };
            });
      updateSelectedItems(newSelectedRows);
    }
  };
}

export default connector(SelectAllItemsCell);

import type { ReduxState } from '../../../../rootReducer';

const isCreatingIDMRequest = (state: ReduxState): boolean =>
  state.searchResultsPage.externalInvestigationCreation.idmRequestInitiation.isCreatingRequest;

const getIDMRequestErrorMessage = (state: ReduxState): string | undefined =>
  state.searchResultsPage.externalInvestigationCreation.idmRequestInitiation.errorMessage || undefined;

const isCreatingExtendedTypingRequest = (state: ReduxState): boolean =>
  state.searchResultsPage.externalInvestigationCreation.extendedTypingRequestInitiation.isCreatingRequest;

const isCreatingVTRequests = (state: ReduxState): boolean =>
  state.searchResultsPage.externalInvestigationCreation.verificationTypingRequestInitiation.isCreatingRequest;

const getFilteredLaboratoryOptions = (state: ReduxState) =>
  state.searchResultsPage.externalInvestigationCreation.verificationTypingRequestInitiation.laboratories;

export default {
  isCreatingIDMRequest,
  getFilteredLaboratoryOptions,
  getIDMRequestErrorMessage,
  isCreatingExtendedTypingRequest,
  isCreatingVTRequests,
};

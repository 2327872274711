import React from 'react';
import FieldError from './Input/FieldError';

type Props = {
  input: {
    name: string;
    onChange: (e: React.SyntheticEvent<HTMLTextAreaElement>) => void;
    value: string;
    maxLength?: number;
  };
  label: string;
  subLabel?: string;
  meta?: {
    error: string;
    touched: boolean;
    warning: string;
  };
};

const Textarea = ({ input, label, meta, subLabel, ...props }: Props) => {
  const error = meta ? meta.error : undefined;
  const touched = meta ? meta.touched : undefined;
  return (
    <div className="field">
      {label && (
        <label htmlFor={input.name}>
          {label}
          {subLabel && <i style={{ fontWeight: 'normal' }}>{subLabel}</i>}
        </label>
      )}
      <textarea
        {...input}
        {...props}
        className={touched && error ? 'error' : ''}
        id={input.name}
        maxLength={2000}
        style={{ height: '300px' }}
      />
      {meta && <FieldError meta={meta} />}
    </div>
  );
};

Textarea.defaultProps = {
  meta: undefined,
  subLabel: undefined,
};

export default Textarea;

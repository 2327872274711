import { combineReducers } from 'redux';
import idmRequestInitiation, { IDMRequestInitiationReducerState } from './idmRequestCreation';
// eslint-disable-next-line import/no-cycle
import verificationTypingRequestInitiation, {
  VTRequestInitiationReducerState,
} from './verificationTypingRequestCreation';
import extendedTypingRequestInitiation, {
  ExtendedTypingRequestInitiationReducerState,
} from './extendedTypingRequestCreation';

export type ExternalInvestigationCreationReducerState = {
  readonly idmRequestInitiation: IDMRequestInitiationReducerState;
  readonly verificationTypingRequestInitiation: VTRequestInitiationReducerState;
  readonly extendedTypingRequestInitiation: ExtendedTypingRequestInitiationReducerState;
};

const externalInvestigationCreation = combineReducers<ExternalInvestigationCreationReducerState>({
  idmRequestInitiation,
  verificationTypingRequestInitiation,
  extendedTypingRequestInitiation,
});

export default externalInvestigationCreation;

import React, { PureComponent } from 'react';
import Radium from 'radium';
import { styles as commonStyles } from '../../style';
import { colors } from '../../../style/index';
import type { DonorType } from '../../../core/types/index';

type Props = {
  selectionLength: number;
  filterSummary: string | undefined;
  showSavedSet: () => void;
  donorType: DonorType;
  isOldSavedDonorSet: boolean | null;
};

const styles: any = {
  ...commonStyles,
  oldSavedDonorSet: {
    color: '#b8b8b8',
  },
  selectableDonorSet: {
    cursor: 'pointer',
    ':hover': {
      color: `${colors.ANGreen}`,
    },
  },
};

class SavedDonorsSummary extends PureComponent<Props> {
  render() {
    const { donorType, filterSummary, selectionLength, isOldSavedDonorSet } = this.props;
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        href="#"
        style={(isOldSavedDonorSet ? styles.oldSavedDonorSet : { color: '' }, styles.selectableDonorSet)}
        onClick={this.handleClick}
      >
        <div>
          {donorType}: {selectionLength} ({filterSummary})
        </div>
      </a>
    );
  }

  handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    const { showSavedSet } = this.props;
    event.preventDefault();
    showSavedSet();
  };
}

export default Radium(SavedDonorsSummary);

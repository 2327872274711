import React from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../rootReducer';
import { WarningMessage } from '../../../core/components/WarningMessage';
import SubjectSelectors from '../../../subject/redux/selectors';

type Props = {
  donorId: string;
};

export const DonorNotFoundPage = ({ donorId }: Props) => {
  const error = useSelector((state: ReduxState) => SubjectSelectors.loadingSubjectError(state));
  const errorString = error ? `- ${error.Error || ''}` : '';

  const warningMessage = `Cannot find donor ${donorId}.  error ${errorString}`;
  return <WarningMessage message={warningMessage} />;
};

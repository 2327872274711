import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import ResultPageSelectors from '../../redux/selectors';
import filterToStringsHelper from '../../helpers/filterToStringsHelper';

import type { AppliedFiltersArray } from '../../types/index';
import donorTypes from '../../../core/constants/donorTypes';
import type { ReduxState } from '../../../rootReducer';

type OwnProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  resultSetId: string; // used in `mapStateToProps`
};
type StateProps = {
  appliedFilters: AppliedFiltersArray;
  visibleSelectedIds: string[];
};
type Props = PropsFromRedux & OwnProps & StateProps;

const mapStateToProps = (state: ReduxState, ownProps: OwnProps): StateProps => ({
  appliedFilters: ResultPageSelectors.getAppliedFiltersArray(state),
  visibleSelectedIds: ResultPageSelectors.getVisibleSelectedIds(state, ownProps.resultSetId, donorTypes.cord.value),
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const getAppliedFiltersAsString = (appliedFilters: AppliedFiltersArray) => filterToStringsHelper(appliedFilters);

class CordSelectionDescription extends PureComponent<Props> {
  render() {
    const { appliedFilters, visibleSelectedIds } = this.props;
    return (
      <div
        style={{
          boxSizing: 'border-box',
          padding: '5px',
          border: '1px solid #ddd',
          margin: '3px 20px 3px 3px',
          minHeight: '129px',
          flexBasis: '100%',
        }}
      >
        <span style={{ fontWeight: 'bold' }}>Current Cord Set Information</span>
        <br />
        Cord Selected: <span style={{ fontStyle: 'italic' }}>{visibleSelectedIds.length}</span>
        <br />
        Filters:{' '}
        <span style={{ fontStyle: 'italic' }}>
          {getAppliedFiltersAsString(appliedFilters) || 'No filters applied.'}
        </span>
        <br />
      </div>
    );
  }
}

export default connector(CordSelectionDescription);

import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Radium from 'radium';
import queryString from 'query-string';
import DonorSetNotes from './DonorSetNotes';

import type { SavedResultSet } from '../../../donorMatchSearchRequests/types';
import type { DonorType } from '../../../core/types';
import './donorSet.scss';

type Props = {
  resultSetId: string | number;
  donorType: DonorType;
  set: SavedResultSet;
  typeString: string;
  isSelected: boolean;
  isReportReadOnly: boolean;
  reportId: string;
  setCount: number;
  toggle: (id: string, donorType?: DonorType) => void;
  reportType: string;
};

const DonorSet = (props: Props) => {
  const {
    donorType,
    isReportReadOnly,
    isSelected,
    reportId,
    reportType,
    resultSetId,
    set,
    setCount,
    toggle,
    typeString,
  } = props;
  const { id, appliedFilters, dateModified, requestId } = set;
  const filterSummaryString = appliedFilters.filterSummary ? appliedFilters.filterSummary.substring(0, 60) : '';
  const pluralise = (count: number, baseString: string) => `${count} ${baseString}${count !== 1 ? 's' : ''}`;
  return (
    <div className="donor-set-container">
      <div className="col span_6_of_12">
        <div className="form-checkbox-wrapper">
          <input
            type="checkbox"
            data-setid={id}
            onChange={() => toggle(id as string, donorType)}
            checked={isSelected}
            disabled={isReportReadOnly}
          />
          <label htmlFor={id}>
            <Link
              to={{
                pathname: `/donorsearch/${requestId}/results`,
                search: queryString.stringify({
                  resultSetId,
                  donorType,
                  setId: id,
                  reportId,
                  reportType,
                }),
              }}
            >
              <span>
                {filterSummaryString} | {pluralise(setCount, typeString)}
              </span>
            </Link>
            {` - ${moment(dateModified).format('DD/MM/YYYY')}`}
          </label>
        </div>
      </div>
      <div className="col span_6_of_12">
        <DonorSetNotes setId={id} donorType={donorType} />
      </div>
    </div>
  );
};

export default Radium(DonorSet);

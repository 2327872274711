import {
  get,
  ErrorAction as ApiErrorAction,
  RequestAction as ApiRequestAction,
  SuccessAction as ApiSuccessAction,
} from '@an/nova-frontend-rest-client';
import Config from '../../config';
import type { UserRole } from '../constants';
import type { ReduxState } from '../../rootReducer';

const USER_ROLES_FETCH: 'searchUserRoles/fetch' = 'searchUserRoles/fetch';
const USER_ROLES_FETCH_ERROR: 'searchUserRoles/fetch/error' = 'searchUserRoles/fetch/error';
const USER_ROLES_FETCH_REQUEST: 'searchUserRoles/fetch/request' = 'searchUserRoles/fetch/request';
const USER_ROLES_FETCH_SUCCESS: 'searchUserRoles/fetch/success' = 'searchUserRoles/fetch/success';

export const Actions = {
  USER_ROLES_FETCH_REQUEST,
  USER_ROLES_FETCH_ERROR,
  USER_ROLES_FETCH_SUCCESS,
};

type UserRolesFetchErrorAction = ApiErrorAction<typeof USER_ROLES_FETCH_ERROR, Record<string, unknown>>;

type UserRolesFetchRequestAction = ApiRequestAction<typeof USER_ROLES_FETCH_REQUEST, Record<string, unknown>>;

type UserRolesFetchSuccessAction = ApiSuccessAction<
  typeof USER_ROLES_FETCH_SUCCESS,
  Record<string, unknown>,
  UserRole[]
>;

export type CurrentUserAction = UserRolesFetchErrorAction | UserRolesFetchRequestAction | UserRolesFetchSuccessAction;

export const getUserRoles = () =>
  get<ReduxState, UserRole[]>(`${Config().apiBaseUrl}current-user/roles`, USER_ROLES_FETCH);

import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { RouterMatch } from '../../../core/types';
import { TabItem, tabItems } from './constants/tabItems';
import DonorRecordPage from '../donorDetails/DonorRecordPage';
import { DonorNotFoundPage } from '../donorDetails/DonorNotFoundPage';
import SubjectLinks from '../../../subject/components/Links/SubjectLinks';
import SubjectInvestigationsScreen from '../../../subject/components/SubjectInvestigations/SubjectInvestigationsScreen';
import { ReduxState } from '../../../rootReducer';
import * as DonorAction from '../../redux/actions';
import * as Donor from '../../redux/selectors';
import { useSubjectFormOptions } from '../../hooks/useSubjectFormOptions';

type OwnProps = {
  match?: RouterMatch;
};
type Props = OwnProps & RouteComponentProps;

const DonorTabs = (props: Props) => {
  const { match } = props;
  const { activeTab, donorId } = match.params;
  const donor = useSelector((state: ReduxState) => Donor.getDonor(state));
  const isLoadingDonor: boolean = useSelector((state: ReduxState) => Donor.isLoadingDonor(state), shallowEqual);
  const dispatch = useDispatch();
  const { subjectConstants } = useSubjectFormOptions();

  useEffect(() => {
    if (donorId) {
      dispatch(DonorAction.getAdultDonor(donorId));
    }
  }, []);

  const renderTab = (item: TabItem) => (
    <li key={item.id}>
      <Link className={activeTab === item.id ? 'active' : ''} to={`/donor/${donorId}/${item.id}`}>
        {item.displayName}
      </Link>
    </li>
  );
  if (!donor && !isLoadingDonor) {
    return <DonorNotFoundPage donorId={donorId} />;
  }

  const tabs = Object.values(tabItems).map((tab) => renderTab(tab));

  return (
    <div className="content group">
      <main role="main" className="col span_12_of_12">
        <ul className="nav-tabs">{tabs}</ul>
        {activeTab === tabItems.details.id && (
          <DonorRecordPage donor={donor} isLoadingDonor={isLoadingDonor} formConstants={subjectConstants} />
        )}
        {activeTab === tabItems.investigations.id && <SubjectInvestigationsScreen subject={donor} />}
        {activeTab === tabItems.linkedPatients.id && <SubjectLinks subjectId={donorId} subjectType="donor" />}
      </main>
    </div>
  );
};

DonorTabs.defaultProps = {
  match: {
    isExact: false,
    path: '',
    url: '',
    params: {
      activeTab: '',
      donorId: '',
      id: '',
      patientId: '',
      reportId: '',
    },
  },
};

export default withRouter(DonorTabs);

const matchGrade = {
  Exact: 'EM',
  Potential: 'PM',
  MinorMismatch: 'MN',
  Mismatch: 'MM',
};

const matchGradeSymbols = {
  Exact: '\u2714',
  Potential: '?',
  MinorMismatch: '\u2612',
  Mismatch: '\u2716',
};

const EXACT_MATCH = 'Exact';
const POTENTIAL_MATCH = 'Potential';
const MINOR_MISMATCH = 'MinorMismatch';
const MISMATCH = 'Mismatch';

export default {
  matchGrade,
  matchGradeSymbols,
  EXACT_MATCH,
  POTENTIAL_MATCH,
  MINOR_MISMATCH,
  MISMATCH,
};

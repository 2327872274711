import React, { CSSProperties, PureComponent } from 'react';
import { AnyAction, bindActionCreators, Dispatch as ReduxDispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { saveSelectedDonorsToServer, saveSelectedCordsToServer } from '../../redux/actions';
import Selectors from '../../redux/selectors';
import { ConfirmationPopUp } from '../../../core';

import type { AppliedFiltersMap } from '../../types';
import type { SelectedResult } from '../../../donorMatchSearchRequests/types';
import type { DonorType } from '../../../core/types';
import donorTypes from '../../../core/constants/donorTypes';
import type { ReduxState } from '../../../rootReducer';

type OwnProps = {
  buttonText: string;
  // eslint-disable-next-line react/no-unused-prop-types
  donorType: DonorType;
  // used in `mapStateToProps`
  // eslint-disable-next-line react/no-unused-prop-types
  resultSetId: string;
  // used in `mapStateToProps`
  shownResultSetIdIfUpdate?: string;
  style?: CSSProperties;
};
type StateProps = {
  appliedFilters: AppliedFiltersMap;
  notes: string;
  patientId?: string;
  requestId?: string;
  selectedItems: Partial<SelectedResult>[];
};
type Props = PropsFromRedux & OwnProps & StateProps;
type State = {
  isPopUpShown: boolean;
};

const mapStateToProps = (state: ReduxState, ownProps: OwnProps): StateProps => ({
  appliedFilters: Selectors.getAppliedFilters(state),
  notes: Selectors.getSelectedSavedNotes(state),
  patientId: Selectors.getRequestPatientId(state),
  requestId: Selectors.getSearchRequestId(state),
  selectedItems: Selectors.getVisibleSelectedItems(state, ownProps.resultSetId, ownProps.donorType),
});

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>, ownProps: OwnProps) => ({
  saveSelectedResults: bindActionCreators(
    ownProps.donorType === donorTypes.adult.value ? saveSelectedDonorsToServer : saveSelectedCordsToServer,
    dispatch
  ),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export class SaveDonorButton extends PureComponent<Props, State> {
  // eslint-disable-next-line react/no-unused-class-component-methods
  target: HTMLButtonElement | null | undefined;

  static defaultProps = {
    patientId: undefined,
    requestId: undefined,
    shownResultSetIdIfUpdate: undefined,
    style: undefined,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      isPopUpShown: false,
    };
  }

  render() {
    const { buttonText, style } = this.props;
    const { isPopUpShown } = this.state;
    return (
      <div>
        <ConfirmationPopUp
          buttonClassName="btn btn--inline"
          message="There are no donors selected.  Are you sure you want to save this empty donor set?"
          isPopUpShown={isPopUpShown}
          onCancelButton={this.handleCancel}
          onConfirm={this.handleConfirm}
          onOpenPopUp={this.saveWithCheck}
          buttonText={buttonText}
          buttonStyle={style}
          placement="left"
        />
      </div>
    );
  }

  saveWithCheck = () => {
    const { selectedItems } = this.props;
    if (selectedItems && selectedItems.length === 0) {
      this.setState({ isPopUpShown: true });
    } else {
      this.saveResults();
    }
  };

  saveResults = () => {
    const {
      appliedFilters,
      notes,
      patientId,
      requestId,
      resultSetId,
      saveSelectedResults,
      selectedItems,
      shownResultSetIdIfUpdate,
    } = this.props;
    if (patientId && requestId) {
      saveSelectedResults({
        resultSetId,
        notes,
        patientId,
        requestId,
        appliedFilters,
        id: shownResultSetIdIfUpdate,
        selectedResults: selectedItems,
      });
    }
  };

  handleCancel = () => {
    this.setState({ isPopUpShown: false });
  };

  handleConfirm = () => {
    this.saveResults();
    this.setState({ isPopUpShown: false });
  };
}

export default connector(SaveDonorButton);

import React from 'react';
import { PatientSearch, PatientSearchResults } from '../../patient';
import { SearchUsersSearchRequest } from '../../donorMatchSearchRequests';
import { AwaitingAuthorisationSummary } from '../../searchReports';

const HomePageComponent = () => (
  <div className="col span_12_of_12">
    <div className="col span_5_of_12 push_1">
      <div className="col span_12_of_12">
        <PatientSearch />
      </div>
      <div className="col span_12_of_12">
        <PatientSearchResults />
      </div>
      <div className="col span_12_of_12">
        <AwaitingAuthorisationSummary />
      </div>
    </div>
    <div className="col span_5_of_12 pull_1">
      <SearchUsersSearchRequest />
    </div>
  </div>
);

export default HomePageComponent;

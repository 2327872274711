import React, { CSSProperties } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { FeatureFlag } from '../../../../../featureFlags';
import { colors } from '../../../../../style/index';
import NewEmdisSearchRequestForm from '../../NewSearchRequest/NewEmdisSearchRequestForm/NewEmdisSearchRequestForm';
import { SearchRequestSelectors } from '../../../../../donorMatchSearchRequests';
import { ErrorMessage, LoadingMessage, PopUpWrapper } from '../../../../../core';
import EmdisRequestsTable from './EmdisRequestsTable';

import type { ErrorMessages } from '../../../../../core/types/index';
import type { ReduxState } from '../../../../../rootReducer';

const styles: Record<string, CSSProperties> = {
  newSearchRequestPopUp: {
    position: 'fixed',
    width: '50%',
    top: '50%',
    left: '50%',
    // This has been set in app.scss to override react-tiny-popover style
    // transform: 'translate(-50%, -50%) !important',
    overflow: 'auto',
    height: '85%',
    border: `1px solid ${colors.ANGreen}`,
  },
};

type OwnProps = {
  patientId: string;
};
type StateProps = {
  errorMessage: ErrorMessages;
  hasErrored: boolean;
  isFetching: boolean;
};
type Props = PropsFromRedux & OwnProps & StateProps;
type State = {
  isPopUpShown: boolean;
};

const mapStateToProps = (state: ReduxState): StateProps => ({
  errorMessage: SearchRequestSelectors.getRequestsErrorMessage(state),
  hasErrored: SearchRequestSelectors.hasUserRequestsFetchErrored(state),
  isFetching: SearchRequestSelectors.isFetchingSearchRequests(state),
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class EmdisRequestsTab extends React.Component<Props, State> {
  state = {
    isPopUpShown: false,
  };

  render() {
    const { patientId, errorMessage, hasErrored, isFetching } = this.props;
    const { isPopUpShown } = this.state;
    return (
      <div>
        {hasErrored && errorMessage ? (
          <div style={{ marginTop: '10px' }}>
            <ErrorMessage errorMessages={errorMessage} />
          </div>
        ) : (
          <div className="results-table results-table--border fixed-width">
            <FeatureFlag
              flag="showNewEMDISSearchRequestButton"
              render={() => (
                <PopUpWrapper
                  buttonClassName="btn btn--inline"
                  isButtonDisabled={isFetching}
                  isPopUpShown={isPopUpShown}
                  name="New EMDIS Search Request"
                  onClick={this.handlePopUpClick}
                  placement="right"
                  popUpStyle={styles.newSearchRequestPopUp}
                  shouldDarkenBackground
                  popoverContainerClassName="react-tiny-popover-container--center"
                >
                  <NewEmdisSearchRequestForm onSubmit={this.hidePopUp} onClose={this.hidePopUp} patientId={patientId} />
                </PopUpWrapper>
              )}
            />
            <h2 className="border-bottom-solid">Recent Searches</h2>
            <LoadingMessage isLoading={isFetching}>
              <EmdisRequestsTable patientId={patientId} />
            </LoadingMessage>
          </div>
        )}
      </div>
    );
  }

  hidePopUp = () => {
    this.setState({ isPopUpShown: false });
  };

  handlePopUpClick = () => {
    this.setState({ isPopUpShown: true });
  };
}

export default connector(EmdisRequestsTab);

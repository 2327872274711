import React from 'react';
import type { AcceptedDays } from '../types';

type Props = {
  requestDetails: AcceptedDays;
  updateWeekdays: (string: string) => void;
};

type Option = {
  id: string;
  name: string;
};

const weekdays: { [string: string]: Option } = {
  monday: { id: 'monday', name: 'Mon' },
  tuesday: { id: 'tuesday', name: 'Tues' },
  wednesday: { id: 'wednesday', name: 'Wed' },
  thursday: { id: 'thursday', name: 'Thurs' },
  friday: { id: 'friday', name: 'Fri' },
  saturday: { id: 'saturday', name: 'Sat' },
  sunday: { id: 'sunday', name: 'Sun' },
};

class WeekdaysForReception extends React.Component<Props> {
  render() {
    const { requestDetails } = this.props;
    return (
      <div>
        <div style={{ padding: '20px 0 10px' }}>Weekdays for reception</div>
        <table style={{ padding: '0px', border: 'none', marginBottom: '0px' }}>
          <tbody>
            <tr style={{ backgroundColor: 'unset' }}>
              {Object.values(weekdays).map((day: Option) => (
                <td key={day.id}>
                  <label htmlFor={day.id} style={{ paddingBottom: '20px' }}>
                    <span style={{ textAlign: 'left', marginRight: '20%' }}>{day.name}</span>
                    <input
                      checked={requestDetails[day.id as keyof AcceptedDays]}
                      id={day.id}
                      onChange={this.handleWeekdayCheckbox}
                      type="checkbox"
                    />
                  </label>
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  handleWeekdayCheckbox = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const { updateWeekdays } = this.props;
    const { id } = event.currentTarget;
    updateWeekdays(id);
  };
}

export default WeekdaysForReception;

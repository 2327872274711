import React from 'react';
import type { Antigen, LocusInfo } from '../../../core/types';
import HlaPairDisplay from '../../../patient/core/components/HlaPairDisplay';

type Props = {
  locusInfo?: LocusInfo | void;
  position1: Antigen;
  position2: Antigen;
};

const background = (isUntyped: boolean, matchCount?: number) => {
  if (isUntyped) {
    return '#ffffff00';
  }
  if (matchCount === 0) {
    return '#ff000055';
  }
  if (matchCount === 1) {
    return '#ffa50055';
  }
  if (matchCount === 2) {
    return '#00ff0055';
  }
  return '#ffffff';
};

const LocusCell = ({ locusInfo, position1, position2 }: Props) => (
  <td
    style={{
      background: background(position1.id === undefined, locusInfo ? locusInfo.matchCount : undefined),
    }}
  >
    <HlaPairDisplay locus1={position1} locus2={position2} />
    {locusInfo && (
      <>
        {`${locusInfo.matchCount}/2`}
        <div>
          Grades:
          {locusInfo.matchGrade1}/{locusInfo.matchGrade2}
        </div>
        <div>
          Confidence:
          {locusInfo.matchConfidence1}/{locusInfo.matchConfidence2}
        </div>
      </>
    )}
  </td>
);

LocusCell.defaultProps = {
  locusInfo: undefined,
};

export default LocusCell;

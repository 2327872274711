import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import Selectors from '../../redux/selectors';
import type { SearchMetrics } from '../../types';
import type { ResultSetSummary } from '../../../donorMatchSearchRequests/types';
import type { ReduxState } from '../../../rootReducer';
import { convertToAlgorithmName } from '../../../patient/patientDashboard/helpers/algorithmConverter';

type OwnProps = {
  resultSet1?: ResultSetSummary;
  resultSet2?: ResultSetSummary;
};
type StateProps = {
  searchMetrics1?: SearchMetrics;
  searchMetrics2?: SearchMetrics;
};
type Props = PropsFromRedux & StateProps & OwnProps;

const mapStateToProps = (state: ReduxState, { resultSet1, resultSet2 }: OwnProps): StateProps => ({
  searchMetrics1: resultSet1 ? Selectors.getSearchMetricsForAlgorithm(state, resultSet1.searchResultSetId) : undefined,
  searchMetrics2: resultSet2 ? Selectors.getSearchMetricsForAlgorithm(state, resultSet2.searchResultSetId) : undefined,
});
const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const loaderStyle = {
  width: '30px',
  height: '30px',
  margin: 'auto',
};

const NOT_AVAILABLE_TEXT = 'N/A';

const getCellWithData = (cellId: string, dataForDisplay?: string | number) => (
  <td id={cellId}>{dataForDisplay || NOT_AVAILABLE_TEXT}</td>
);

const formatSearchTime = (timeInMilliseconds?: number) =>
  timeInMilliseconds ? Math.round((timeInMilliseconds / 1000) * 10) / 10 : undefined;

export const AlgorithmResultsOverviewTable = ({ resultSet1, resultSet2, searchMetrics1, searchMetrics2 }: Props) => (
  <div>
    <h2>Results Overview</h2>
    {resultSet1 && resultSet2 && searchMetrics1 && searchMetrics2 ? (
      <table style={{ width: '100%' }}>
        <thead>
          <tr>
            <th>Metric</th>
            <th>{convertToAlgorithmName(resultSet1.algorithmUsed)}</th>
            <th>{convertToAlgorithmName(resultSet2.algorithmUsed)}</th>
            <th>Difference</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Donor Count</td>
            {getCellWithData('matching-donor-count-1', searchMetrics1.matchingDonorCount)}
            {getCellWithData('matching-donor-count-2', searchMetrics2.matchingDonorCount)}
            {getCellWithData(
              'matching-donor-count-difference',
              searchMetrics2.matchingDonorCount - searchMetrics1.matchingDonorCount
            )}
          </tr>
          <tr>
            <td>Timing (seconds)</td>
            {getCellWithData('search-time-1', formatSearchTime(searchMetrics1.searchTimeInMilliseconds))}
            {getCellWithData('search-time-2', formatSearchTime(searchMetrics2.searchTimeInMilliseconds))}
            {getCellWithData(
              'search-time-difference',
              formatSearchTime(
                searchMetrics2.searchTimeInMilliseconds && searchMetrics1.searchTimeInMilliseconds
                  ? searchMetrics2.searchTimeInMilliseconds - searchMetrics1.searchTimeInMilliseconds
                  : undefined
              )
            )}
          </tr>
        </tbody>
      </table>
    ) : (
      <div className="loader" style={loaderStyle} />
    )}
  </div>
);

AlgorithmResultsOverviewTable.defaultProps = {
  resultSet1: undefined,
  resultSet2: undefined,
  searchMetrics1: undefined,
  searchMetrics2: undefined,
};

export default connector(AlgorithmResultsOverviewTable);

import * as React from 'react';
import CountDisplay from './CountDisplay/CountDisplay';
import { adjustedPhenotypeDisplay } from '../../../donorMatchSearchRequests/index';
import { convertSearchTypeToNumeric, SearchType } from '../../../core/constants/searchTypes';

import type { DonorType, SearchResults } from '../../../core/types';
import { convertToAlgorithmName } from '../../../patient/patientDashboard/helpers/algorithmConverter';

type Props = {
  algorithm?: string;
  searchType: DonorType;
  isHlaAdjusted: boolean;
  searchResults: SearchResults;
  resultSetId: string;
  specificSearchType?: SearchType;
};

const TableTitle = (props: Props) => {
  const { algorithm, searchType, isHlaAdjusted, resultSetId, searchResults, specificSearchType } = props;

  return (
    <h4 style={{ float: 'left', paddingTop: '10px' }}>
      {algorithm ? convertToAlgorithmName(algorithm) : ''} {searchType}{' '}
      {specificSearchType ? convertSearchTypeToNumeric(specificSearchType) : ''}
      {adjustedPhenotypeDisplay(isHlaAdjusted)} Search Results:
      <CountDisplay filteredResults={searchResults} donorType={searchType} resultSetId={resultSetId} />
    </h4>
  );
};

TableTitle.defaultProps = {
  algorithm: undefined,
  specificSearchType: undefined,
};

export default TableTitle;

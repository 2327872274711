import React from 'react';
import DonorTabs from './components/donorTabs/DonorTabs';

const DonorDashboard = () => (
  <div className="col span_12_of_12">
    <h1>Donor Dashboard</h1>
    <DonorTabs />
  </div>
);

export default DonorDashboard;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ErrorMessage } from '../../../core';
import TestRequestsTable from './TestRequestsTable';
import Selectors from '../../redux/selectors';
import { getExternalInvestigationActions, getExternalInvestigationActionsByDate } from '../../redux/actions';

const TestRequestsTab = () => {
  const testRequests = useSelector(Selectors.getExternalInvestigationsActions);
  const isFetching = useSelector(Selectors.isFetchingExternalInvestigationsActions);
  const hasErrored = useSelector(Selectors.hasFetchExternalInvestigationActionsErrored);
  const errorMessage = useSelector(Selectors.getExternalInvestigationActionsErrorMessage);

  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dateQueryParam = queryParams.get('date');

  useEffect(() => {
    if (queryParams.has('date')) {
      // @ts-expect-error - type 'null' is not assignable to type 'string'
      dispatch(getExternalInvestigationActionsByDate(dateQueryParam));
      return;
    }
    dispatch(getExternalInvestigationActions());
  }, [dateQueryParam]);

  return hasErrored ? (
    <div style={{ marginTop: '10px' }}>
      <ErrorMessage errorMessages={errorMessage} />
    </div>
  ) : (
    <div className="results-table results-table--border">
      <TestRequestsTable isLoading={isFetching} testRequests={testRequests} />
    </div>
  );
};

export default TestRequestsTab;

import React, { PureComponent } from 'react';
import ContentEditable from 'react-contenteditable';

type Props = {
  recommendation: string;
  id: string;
  isReadOnly: boolean;
  recommendationEdit: (recommendation: string, id: string) => void;
};
type State = {
  displayText: string;
};

type RecommendationEvent = {
  textContent: string;
  value: string;
} & HTMLInputElement;

type ChangeEvent = {
  value: string;
} & HTMLInputElement;

class EditableRecommendation extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    const { recommendation } = this.props;
    this.state = {
      displayText: recommendation,
    };
  }

  componentDidUpdate(prevProps: Props) {
    const { recommendation } = this.props;
    if (recommendation !== prevProps.recommendation) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ displayText: recommendation });
    }
  }

  render() {
    const { isReadOnly } = this.props;
    const { displayText } = this.state;
    if (isReadOnly) {
      return <div>{displayText}</div>;
    }
    return (
      <span
        style={{
          display: 'block',
          marginLeft: '15px',
        }}
      >
        <ContentEditable
          html={displayText || ''}
          onChange={this.handleChange}
          // @ts-expect-error Type 'FocusEvent<HTMLDivElement, Element>' is not assignable to type
          // 'SyntheticEvent<RecommendationEvent, Event>'
          onBlur={this.handleRecommendationChange}
        />
      </span>
    );
  }

  handleChange = (event: React.SyntheticEvent<ChangeEvent>) => {
    const target = event.target as ChangeEvent;
    this.setState({
      displayText: target.value,
    });
  };

  handleRecommendationChange = (event: React.SyntheticEvent<RecommendationEvent>) => {
    const { recommendationEdit, id } = this.props;
    const { textContent } = event.currentTarget;
    recommendationEdit(textContent, id);
  };
}

export default EditableRecommendation;

import React, { CSSProperties, PureComponent } from 'react';
import ReactDOM from 'react-dom';
import { ContentRenderer, Popover, PopoverPosition } from 'react-tiny-popover';
import { styles as commonStyles, Styles as CommonStyles } from '../../../style';

type Props = {
  buttonClassName?: string;
  buttonStyle?: CSSProperties;
  children: React.ReactNode;
  isButtonDisabled?: boolean;
  isPopUpShown?: boolean;
  name: string | React.ReactNode;
  onClick?: () => void;
  placement: string;
  popUpStyle?: CSSProperties;
  resizePopUp?: () => void;
  shouldDarkenBackground?: boolean;
  popoverContainerClassName?: string;
  onClosePopover?: () => void;
  buttonInfo?: boolean;
};

type State = {
  open: boolean;
};

const styles: CommonStyles & Record<string, CSSProperties> = {
  ...commonStyles,
  darkMask: {
    background: 'rgba(0, 0, 0, 0.5)',
    position: 'fixed',
    inset: 0,
    margin: 'auto',
    width: '100vw',
    height: '100vh',
  },
  darkMaskLevel: {
    zIndex: 2,
  },
  popUpStyle: {
    zIndex: 99,
    padding: '20px',
    backgroundColor: 'white',
    boxShadow: 'rgb(39 43 55 / 15%) 0px 0px 30px',
  },
};

class PopUpWrapper extends PureComponent<Props, State> {
  // eslint-disable-next-line react/no-unused-class-component-methods
  target: HTMLElement | null | undefined;

  static defaultProps = {
    buttonClassName: 'button',
    buttonStyle: undefined,
    isButtonDisabled: false,
    isPopUpShown: true,
    onClick: undefined,
    onClosePopover: undefined,
    popoverContainerClassName: undefined,
    popUpStyle: undefined,
    resizePopUp: undefined,
    shouldDarkenBackground: false,
    buttonInfo: false,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  render() {
    const {
      buttonClassName,
      buttonStyle,
      children,
      isButtonDisabled,
      isPopUpShown,
      name,
      placement,
      popUpStyle,
      popoverContainerClassName,
      buttonInfo,
    } = this.props;
    const { open } = this.state;
    const closeButton = (
      <div>
        <button aria-label="Close" className="close" onClick={this.handleClose} type="button" />
        {children}
      </div>
    );
    const popoverContent = closeButton as JSX.Element | ContentRenderer;

    // Prevents to double click to re-open the popover
    // As the close button is handled from parent component, once the component
    // is closed needs to double click on the button to re-open it.
    if (isPopUpShown === false) {
      this.setState({ open: false });
    }
    return (
      <div style={{ width: buttonInfo ? 'auto' : '100%' }}>
        {this.renderPageDarken()}
        <Popover
          isOpen={open && (isPopUpShown as boolean)}
          onClickOutside={(e: Event) => {
            const target = e.target as HTMLElement;
            // Some actions within a popover are incorrectly detected as outside clicks -
            // return early if so and do not close the popover.
            if (
              // do not close the popover if selecting an option within a react-select field
              (target && target.outerHTML.includes('react-select')) ||
              // do not close the popup if removing an option from a react-select multi-select field
              target.closest('svg') ||
              // do not close the popup if clicking on a button
              target.closest('button') ||
              // do not close the popup if clicking on a modal
              target.closest('.ReactModal__Content')
            ) {
              return;
            }
            this.handleClose();
          }}
          positions={[placement as PopoverPosition]}
          reposition={false}
          content={popoverContent}
          align="center"
          containerStyle={{ ...styles.popUpStyle, ...popUpStyle } as Partial<CSSStyleDeclaration>}
          containerClassName={popoverContainerClassName}
        >
          <a
            href="popOver"
            className={`${buttonClassName || ''} ${isButtonDisabled ? 'btn--disabled' : ''}`}
            ref={(c) => {
              // eslint-disable-next-line react/no-unused-class-component-methods
              this.target = c;
            }}
            onClick={this.handleClick}
            style={buttonStyle}
          >
            {name}
          </a>
        </Popover>
      </div>
    );
  }

  handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    const { onClick } = this.props;
    const { open } = this.state;
    event.preventDefault();
    if (onClick) {
      onClick();
    }
    this.setState({ open: !open });
  };

  handleClose = () => {
    const { resizePopUp, onClosePopover } = this.props;
    if (onClosePopover) {
      onClosePopover();
      return;
    }
    this.setState({ open: false });
    if (resizePopUp) {
      resizePopUp();
    }
  };

  renderPageDarken = () => {
    const { isPopUpShown, shouldDarkenBackground } = this.props;
    const { open } = this.state;
    if (shouldDarkenBackground && open && isPopUpShown) {
      const element = <div style={{ ...styles.darkMask, ...styles.darkMaskLevel }} />;
      const body = document.getElementById('root');
      if (body != null) {
        return ReactDOM.createPortal(element, body);
      }
    }
    return null;
  };
}

export default PopUpWrapper;

import _ from 'lodash';
import { Genetics } from '../../patient/types';

export const formatGeneticDetails = (genetics: Genetics) =>
  _.flatten(
    _.map(genetics, (d, locus) =>
      _.map(d, (antigenId, rowId) => ({
        [`${locus}${parseInt(rowId, 10) + 1}Id`]: antigenId,
      }))
    )
  );

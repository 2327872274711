import _ from 'lodash';

export const externalInvestigationActionMessageTypes = {
  IdmResultReceived: {
    value: 'IdmResultReceived',
    name: 'IDM Results',
    orderInTable: 1,
  },
  ExtendedTypingResultReceived: {
    value: 'ExtendedTypingResultReceived',
    name: 'ET Results',
    orderInTable: 2,
  },
  NoResultReceived: {
    value: 'NoResultReceived',
    name: 'No Results',
    orderInTable: 3,
  },
  SampleArrivalReceived: {
    value: 'SampleArrivalReceived',
    name: 'Sample Arrival',
    orderInTable: 4,
  },
  SampleInfoReceived: {
    value: 'SampleInfoReceived',
    name: 'Sample Info',
    orderInTable: 5,
  },
  SampleResultsEntered: {
    value: 'SampleResultsEntered',
    name: 'Sample Results',
    orderInTable: 6,
  },
  TextMessageReceived: {
    value: 'TextMessageReceived',
    name: 'Text Message',
    orderInTable: 7,
  },
};

export const getExternalInvestigationActionMessageTypeName = (value: string): string | undefined =>
  _.find(
    externalInvestigationActionMessageTypes,
    (externalInvestigationActionMessageType) => externalInvestigationActionMessageType.value === value
  )?.name;

import React, { CSSProperties, PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import ResultsSelectors from '../../../redux/selectors';
import { LoadingMessage, matchGradeConstants } from '../../../../core/index';
import { styles as commonStyles, Styles as CommonStyles } from '../../../style';
import { colors, keyframes } from '../../../../style/index';
import type { DonorType, AdultSearchResults, SearchResults, AdultSearchResult } from '../../../../core/types';
import donorTypes from '../../../../core/constants/donorTypes';
import type { ReduxState } from '../../../../rootReducer';

type OwnProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  donorType: DonorType;
  // used in `mapStateToProps`
  filteredResults: SearchResults;
  // eslint-disable-next-line react/no-unused-prop-types
  resultSetId: string; // used in `mapStateToProps`
};
type StateProps = {
  isLoading: boolean;
  isPotentialSearch: boolean;
  searchResults?: SearchResults;
  visibleSelectedIds: string[];
};
type Props = PropsFromRedux & OwnProps & StateProps;

const mapStateToProps = (state: ReduxState, { donorType, resultSetId }: OwnProps): StateProps => ({
  isLoading: ResultsSelectors.isSearchLoading(state, resultSetId),
  isPotentialSearch: ResultsSelectors.isPotentialSearch(state),
  searchResults: ResultsSelectors.getSearchResults(state, resultSetId),
  visibleSelectedIds: ResultsSelectors.getVisibleSelectedIds(state, resultSetId, donorType),
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type MatchGradeKeys = 'Exact' | 'Potential' | 'MinorMismatch';

const styles: CommonStyles & Record<string, CSSProperties | Record<string, unknown>> = {
  ...commonStyles,
  titleLoader: {
    borderTop: '3px solid #f3f3f3',
    borderRight: `3px solid ${colors.ANGreenLight}`,
    borderBottom: `3px solid ${colors.ANGreenLight}`,
    borderLeft: `3px solid ${colors.ANGreenLight}`,
    borderRadius: '50%',
    width: '10px',
    height: '10px',
    animation: 'x 1.5s linear infinite',
    animationName: keyframes.spinKeyframes,
    margin: '0px 15px',
    padding: '0px',
    fontSize: '4px',
    display: 'inline',
  },
  loadingInlineFlex: {
    display: 'inline-flex',
    justifyContent: 'space-around',
  },
};

export class CountDisplay extends PureComponent<Props> {
  static defaultProps = {
    searchResults: undefined,
  };

  render() {
    const { isLoading } = this.props;
    return (
      <LoadingMessage isLoading={isLoading} loadingStyle={styles.loadingInlineFlex} spinnerStyle={styles.titleLoader}>
        {this.renderResultsCountInfo()}
      </LoadingMessage>
    );
  }

  renderResultsCountInfo = () => {
    const { filteredResults, isPotentialSearch, searchResults, visibleSelectedIds } = this.props;
    const totalNumberOfResults = searchResults ? searchResults.results.length : 0;
    const numberOfResultsShown = filteredResults.results.length;
    const numberOfResultsSelected = visibleSelectedIds.length;
    const matchGradeSpecificData =
      isPotentialSearch && filteredResults.type !== donorTypes.cord.value
        ? this.potentialSpecificData(filteredResults as AdultSearchResults)
        : ' ';
    const totalOfUnscoredDonors = searchResults ? this.getTotalOfUnscoredDonors(searchResults) : '';

    return (
      <span>
        {` Total: ${totalNumberOfResults} |
           ${totalOfUnscoredDonors}
           Shown: ${numberOfResultsShown} |
           ${matchGradeSpecificData}
           Selected: ${numberOfResultsSelected}`}
      </span>
    );
  };

  potentialSpecificData = (searchResults: AdultSearchResults) => {
    const exact = this.getDonorCountOfSingleMatchType(searchResults, matchGradeConstants.EXACT_MATCH);
    const potential = this.getDonorCountOfSingleMatchType(searchResults, matchGradeConstants.POTENTIAL_MATCH);
    const minorMismatch = this.getDonorCountOfSingleMatchType(searchResults, matchGradeConstants.MINOR_MISMATCH);

    return `Exact: ${exact} | Potential: ${potential} | Minor Mismatch: ${minorMismatch} | `;
  };

  getDonorCountOfSingleMatchType = (searchResults: AdultSearchResults, singleMatchGrade: string) => {
    const donorsOfSingleMatchType = searchResults.results.filter(
      (donor) => donor.matchGrade === matchGradeConstants.matchGrade[singleMatchGrade as MatchGradeKeys]
    );
    return donorsOfSingleMatchType.length;
  };

  getTotalOfUnscoredDonors = (searchResults: SearchResults) => {
    const searchResultsList = searchResults.results as AdultSearchResult[];
    const totalOfUnscoredDonors = searchResultsList.filter(
      (donor) => donor.verificationStatus?.isResultVerified === false
    );
    return totalOfUnscoredDonors.length > 0 ? ` Total Unscored: ${totalOfUnscoredDonors.length} |` : '';
  };
}

export default connector(CountDisplay);

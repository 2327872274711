import React from 'react';
import AlgorithmRankingComparisonChart from './AlgorithmRankingComparisonChart';
import AlgorithmRankingComparisonTable from './AlgorithmRankingComparisonTable';
import type { AlgorithmResults } from './types';

type Props = {
  algorithm1Results: AlgorithmResults;
  algorithm2Results: AlgorithmResults;
};

const getRankFromIndex = (donorId: string, donorIds: string[]) => 1 + donorIds.indexOf(donorId);

function getRankingComparison(donorId: string, donorIds1: string[], donorIds2: string[]) {
  const rank1 = getRankFromIndex(donorId, donorIds1);
  const rank2 = getRankFromIndex(donorId, donorIds2);

  return {
    donorId,
    algorithm1Rank: rank1,
    algorithm2Rank: rank2,
    rankDifference: rank2 - rank1,
  };
}

function getAllRankingComparisons(donorIds1: string[], donorIds2: string[]) {
  const donorIdIntersection = donorIds1.filter((id) => donorIds2.includes(id));
  return donorIdIntersection.map((donorId) => getRankingComparison(donorId, donorIds1, donorIds2));
}

export default function AlgorithmRankingComparator({ algorithm1Results, algorithm2Results }: Props) {
  const rankingComparisons = getAllRankingComparisons(
    algorithm1Results.rankedDonorIds,
    algorithm2Results.rankedDonorIds
  );

  return (
    <div>
      <h2>Donor Ranking Comparison</h2>
      <AlgorithmRankingComparisonChart rankingComparisons={rankingComparisons} />

      <AlgorithmRankingComparisonTable
        algorithm1={algorithm1Results.algorithmName}
        algorithm2={algorithm2Results.algorithmName}
        rankingComparisons={rankingComparisons}
      />
    </div>
  );
}

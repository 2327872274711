type Values<T> = T[keyof T];

const BILLING = 'Billing';
const MAIN = 'Main';
const SHIPPING = 'Shipping';

export const institutionAddressTypes = {
  billing: BILLING,
  main: MAIN,
  shipping: SHIPPING,
};

export type InstitutionAddressType = Values<typeof institutionAddressTypes>;

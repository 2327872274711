import React from 'react';
import {
  ExtendedTypingResolutionVariants,
  extendedTypingResolutionVariants,
} from '../../core/constants/extendedTypingResolutionVariants';
import { externalInvestigationTypes } from '../../core/constants/externalInvestigationTypes';
import {
  infectiousDiseaseMarkerVariants,
  InfectiousDiseaseMarkerVariants,
} from '../../core/constants/infectiousDiseaseMarkerVariants';
import formatInternationalInstitutionCode from '../../core/helpers/internationalInstitutionCodeFormatter';
import {
  ExtendedTypingInvestigationDetails,
  IDMInvestigationDetails,
  VTInvestigationDetails,
} from '../../externalInvestigations/types';
import { ExternalInvestigationDetails } from '../types';

type FormattedInvestigationDetails = {
  cell: string | React.ReactNode;
  tooltipBody: React.ReactNode;
};

export const formatInvestigationDetails = (
  investigation: ExternalInvestigationDetails,
  investigationType?: string
): FormattedInvestigationDetails => {
  let tooltipBody;
  let cell;
  if (investigationType === externalInvestigationTypes.idm.value) {
    const { infectiousDiseaseMarkers } = investigation as IDMInvestigationDetails;
    tooltipBody = (
      <div className="investigation-details idm">
        <div className="investigation-details-container">
          {Object.keys(infectiousDiseaseMarkers)
            .filter((idm) => infectiousDiseaseMarkers[idm])
            .map((idm) => (
              <div key={idm} className="investigation-details-item">
                <b>{infectiousDiseaseMarkerVariants[idm as InfectiousDiseaseMarkerVariants]?.abbreviation}</b>
              </div>
            ))}
        </div>
      </div>
    );
    cell = Object.keys(infectiousDiseaseMarkers)
      .filter((idm) => infectiousDiseaseMarkers[idm])
      .map((idm) => infectiousDiseaseMarkerVariants[idm as InfectiousDiseaseMarkerVariants]?.abbreviation)
      .join(', ');
  }

  if (investigationType === externalInvestigationTypes.extendedTyping.value) {
    const { resolutionRequired } = investigation as ExtendedTypingInvestigationDetails;
    const filteredResolutions = Object.keys(resolutionRequired).filter((res) => resolutionRequired[res]);
    tooltipBody = (
      <div className="investigation-details extended-typing">
        <div className="investigation-details-container">
          {filteredResolutions.map((res) => (
            <div key={res} className="investigation-details-item">
              <b>
                {extendedTypingResolutionVariants[res as ExtendedTypingResolutionVariants]}:&nbsp;
                {resolutionRequired[res].substr(0, 1)}
              </b>
            </div>
          ))}
        </div>
      </div>
    );
    cell = filteredResolutions.map((res, index) => (
      <span key={res}>
        {extendedTypingResolutionVariants[res as ExtendedTypingResolutionVariants]}:&nbsp;
        {resolutionRequired[res].substr(0, 1)}
        {index < filteredResolutions.length - 1 ? ', ' : ''}
      </span>
    ));
  }

  if (investigationType === externalInvestigationTypes.vt.value) {
    const { sampleRecipientInternationalInstitutionCode } = investigation as VTInvestigationDetails;

    const lab = formatInternationalInstitutionCode(sampleRecipientInternationalInstitutionCode);
    tooltipBody = (
      <div className="investigation-details vt">
        <div className="investigation-details-container">{lab && <b>Lab: {lab}</b>}</div>
      </div>
    );

    cell = lab && `Lab: ${lab}`;
  }

  return { cell, tooltipBody };
};

import _ from 'lodash';
// eslint-disable-next-line import/no-cycle
import {
  Actions,
  ExternalInvestigationsErrorAction,
  ExternalInvestigationsRequestAction,
  ExternalInvestigationsSuccessAction,
  VerificationTypingResultErrorAction,
  VerificationTypingResultRequestAction,
  VerificationTypingResultSuccessAction,
  SaveVerificationTypingResultErrorAction,
  SaveVerificationTypingResultSuccessAction,
  SendVerificationTypingResultErrorAction,
  SendVerificationTypingResultSuccessAction,
  CancelExternalInvestigationsErrorAction,
  CancelExternalInvestigationsRequestAction,
  CancelExternalInvestigationsSuccessAction,
  GenerateVirologyReportErrorAction,
  GenerateVirologyReportRequestAction,
  GenerateVirologyReportSuccessAction,
  GenerateHLAReportErrorAction,
  GenerateHLAReportRequestAction,
  GenerateHLAReportSuccessAction,
  ExternalInvestigationReportDownloadPdfRequestAction,
  ExternalInvestigationReportDownloadPdfSuccessAction,
  ExternalInvestigationReportDownloadPdfFinalAttemptErrorAction,
  ExternalInvestigationReportDownloadPdfFinalAttemptSuccessAction,
} from './actions';
// eslint-disable-next-line import/no-cycle
import {
  convertExternalInvestigationsFromApi,
  convertVerificationTypingResultFromApi,
} from '../../patient/patientDashboard/helpers/apiDataConverter';
import { ExternalInvestigation, ExternalInvestigationReportDetails, ExternalInvestigationReportStatus } from '../types';
import { externalInvestigationTypes } from '../../core/constants/externalInvestigationTypes';
import { VerificationTypingResult } from '../../patient/patientDashboard/types';

type UsersActions =
  | CancelExternalInvestigationsErrorAction
  | CancelExternalInvestigationsRequestAction
  | CancelExternalInvestigationsSuccessAction
  | ExternalInvestigationsErrorAction
  | ExternalInvestigationsRequestAction
  | ExternalInvestigationsSuccessAction
  | VerificationTypingResultErrorAction
  | VerificationTypingResultRequestAction
  | VerificationTypingResultSuccessAction
  | SaveVerificationTypingResultErrorAction
  | SaveVerificationTypingResultSuccessAction
  | SendVerificationTypingResultErrorAction
  | SendVerificationTypingResultSuccessAction
  | GenerateVirologyReportErrorAction
  | GenerateVirologyReportRequestAction
  | GenerateVirologyReportSuccessAction
  | GenerateHLAReportErrorAction
  | GenerateHLAReportRequestAction
  | GenerateHLAReportSuccessAction
  | ExternalInvestigationReportDownloadPdfRequestAction
  | ExternalInvestigationReportDownloadPdfSuccessAction
  | ExternalInvestigationReportDownloadPdfFinalAttemptSuccessAction
  | ExternalInvestigationReportDownloadPdfFinalAttemptErrorAction;

export type ExternalInvestigationsState = {
  hasErrored: boolean;
  isFetching: boolean;
  isSaving: boolean;
  isVerificationTypingResultCompleted: boolean;
  errorMessage?: string;
  requests: ExternalInvestigation[];
  verificationTypingResult?: VerificationTypingResult;
  hasVerificationTypingResultLoadingFailed: boolean;
  isVerificationTypingResultLoading: boolean;
  isCancellingExternalInvestigations: boolean;
  cancelExternalInvestigationsHasErrored: boolean;
  cancelExternalInvestigationsError?: string;
  isVerificationTypingResultSent: boolean;
};

const initialState: ExternalInvestigationsState = {
  hasErrored: false,
  isFetching: false,
  isSaving: false,
  isVerificationTypingResultCompleted: false,
  requests: [],
  verificationTypingResult: undefined,
  hasVerificationTypingResultLoadingFailed: false,
  isVerificationTypingResultLoading: false,
  isCancellingExternalInvestigations: false,
  cancelExternalInvestigationsHasErrored: false,
  cancelExternalInvestigationsError: undefined,
  isVerificationTypingResultSent: false,
};

const reportUpdateFunc = (
  stateRequests: ExternalInvestigation[],
  externalInvestigationId: number,
  externalInvestigationType: string,
  updateFunc: (e: ExternalInvestigationReportDetails) => void
): ExternalInvestigation[] => {
  const requests = _.cloneDeep(stateRequests);
  const requestInProgress = requests.find(
    (r) => r.id === externalInvestigationId && r.type === externalInvestigationType
  );
  if (requestInProgress) {
    let report;
    if (requestInProgress.type === externalInvestigationTypes.idm.value) {
      report = requestInProgress.reports.virologyReportDetails;
    }
    if (requestInProgress.type === externalInvestigationTypes.vt.value) {
      report = requestInProgress.reports.virologyReportDetails;
    }
    if (requestInProgress.type === externalInvestigationTypes.extendedTyping.value) {
      report = requestInProgress.reports.hlaReportDetails;
    }
    if (report) {
      updateFunc(report);
    }
  }

  return requests;
};

export default (
  state: ExternalInvestigationsState = initialState,
  action: UsersActions
): ExternalInvestigationsState => {
  switch (action.type) {
    case Actions.EXTERNAL_INVESTIGATIONS_REQUEST:
      return {
        ...state,
        hasErrored: false,
        isFetching: true,
      };
    case Actions.EXTERNAL_INVESTIGATIONS_SUCCESS:
      return {
        ...state,
        hasErrored: false,
        isFetching: false,
        requests: convertExternalInvestigationsFromApi(action.payload.data),
      };
    case Actions.EXTERNAL_INVESTIGATIONS_ERROR:
      return {
        ...state,
        hasErrored: true,
        isFetching: false,
        errorMessage: `Error while fetching Test Requests - ${action.payload.response.status}`,
      };
    case Actions.VERIFICATION_TYPING_RESULT_REQUEST:
      return {
        ...state,
        isVerificationTypingResultLoading: true,
        hasVerificationTypingResultLoadingFailed: false,
      };
    case Actions.VERIFICATION_TYPING_RESULT_SUCCESS:
      return {
        ...state,
        hasVerificationTypingResultLoadingFailed: false,
        isVerificationTypingResultLoading: false,
        verificationTypingResult: convertVerificationTypingResultFromApi(action.payload.data),
      };
    case Actions.VERIFICATION_TYPING_RESULT_ERROR:
      return {
        ...state,
        hasVerificationTypingResultLoadingFailed: true,
        isVerificationTypingResultLoading: false,
        errorMessage: `Error while fetching Verification Typing Test Result - ${action.payload.response.status}`,
      };
    case Actions.SAVE_VERIFICATION_TYPING_RESULT_ERROR:
      return {
        ...state,
        isSaving: false,
        isVerificationTypingResultCompleted: false,
        hasErrored: true,
        errorMessage: action.payload.params.errorMessage,
      };
    case Actions.SAVE_VERIFICATION_TYPING_RESULT_SUCCESS:
      return {
        ...state,
        isSaving: false,
        isVerificationTypingResultCompleted: true,
      };
    case Actions.SEND_VERIFICATION_TYPING_RESULT_ERROR:
      return {
        ...state,
        isVerificationTypingResultSent: false,
        hasErrored: true,
        errorMessage: action.payload.params.errorMessage,
      };
    case Actions.SEND_VERIFICATION_TYPING_RESULT_SUCCESS:
      return {
        ...state,
        isVerificationTypingResultSent: true,
      };
    case Actions.CANCEL_EXTERNAL_INVESTIGATIONS_REQUEST:
      return {
        ...state,
        cancelExternalInvestigationsHasErrored: false,
        isCancellingExternalInvestigations: true,
      };
    case Actions.CANCEL_EXTERNAL_INVESTIGATIONS_ERROR:
      return {
        ...state,
        isCancellingExternalInvestigations: false,
        cancelExternalInvestigationsHasErrored: true,
        cancelExternalInvestigationsError: `An error has occurred while cancelling test requests - ${
          action.payload.response.status
        }${action.payload.response.body?.Error ? ` (${action.payload.response.body.Error})` : ''}`,
      };
    case Actions.CANCEL_EXTERNAL_INVESTIGATIONS_SUCCESS:
      return {
        ...state,
        isCancellingExternalInvestigations: false,
        cancelExternalInvestigationsHasErrored: false,
        cancelExternalInvestigationsError: undefined,
      };
    case Actions.GENERATE_VIROLOGY_REPORT_REQUEST: {
      const externalInvestigation = action.payload.params;
      const requests = reportUpdateFunc(
        state.requests,
        externalInvestigation.externalInvestigationId,
        externalInvestigation.externalInvestigationType,
        (r) => {
          // eslint-disable-next-line no-param-reassign
          r.reportStatus = ExternalInvestigationReportStatus.Generating;
        }
      );

      return {
        ...state,
        requests,
      };
    }
    case Actions.GENERATE_VIROLOGY_REPORT_ERROR: {
      const externalInvestigation = action.payload.params;
      const requests = reportUpdateFunc(
        state.requests,
        externalInvestigation.externalInvestigationId,
        externalInvestigation.externalInvestigationType,
        (r) => {
          // eslint-disable-next-line no-param-reassign
          r.reportStatus = ExternalInvestigationReportStatus.ReadyToGenerate;
        }
      );

      return {
        ...state,
        requests,
      };
    }
    case Actions.GENERATE_VIROLOGY_REPORT_SUCCESS: {
      const externalInvestigation = action.payload.params;
      const requests = reportUpdateFunc(
        state.requests,
        externalInvestigation.externalInvestigationId,
        externalInvestigation.externalInvestigationType,
        (r) => {
          // eslint-disable-next-line no-param-reassign
          r.reportStatus = ExternalInvestigationReportStatus.ReadyToDownload;
          // eslint-disable-next-line no-param-reassign
          r.reportFilename = action.payload.response.body?.PdfFileName;
        }
      );

      return {
        ...state,
        requests,
      };
    }
    case Actions.GENERATE_HLA_REPORT_REQUEST: {
      const externalInvestigation = action.payload.params;
      const requests = reportUpdateFunc(
        state.requests,
        externalInvestigation.externalInvestigationId,
        externalInvestigation.externalInvestigationType,
        (r) => {
          // eslint-disable-next-line no-param-reassign
          r.reportStatus = ExternalInvestigationReportStatus.Generating;
        }
      );

      return {
        ...state,
        requests,
      };
    }
    case Actions.GENERATE_HLA_REPORT_ERROR: {
      const externalInvestigation = action.payload.params;
      const requests = reportUpdateFunc(
        state.requests,
        externalInvestigation.externalInvestigationId,
        externalInvestigation.externalInvestigationType,
        (r) => {
          // eslint-disable-next-line no-param-reassign
          r.reportStatus = ExternalInvestigationReportStatus.ReadyToGenerate;
        }
      );

      return {
        ...state,
        requests,
      };
    }
    case Actions.GENERATE_HLA_REPORT_SUCCESS: {
      const externalInvestigation = action.payload.params;
      const requests = reportUpdateFunc(
        state.requests,
        externalInvestigation.externalInvestigationId,
        externalInvestigation.externalInvestigationType,
        (r) => {
          // eslint-disable-next-line no-param-reassign
          r.reportStatus = ExternalInvestigationReportStatus.ReadyToDownload;
          // eslint-disable-next-line no-param-reassign
          r.reportFilename = action.payload.response.body?.PdfFileName;
        }
      );

      return {
        ...state,
        requests,
      };
    }
    case Actions.EXTERNAL_INVESTIGATION_REPORT_DOWNLOAD_PDF_REQUEST: {
      const externalInvestigation = action.payload.params;
      const requests = reportUpdateFunc(
        state.requests,
        externalInvestigation.externalInvestigationId,
        externalInvestigation.externalInvestigationType,
        (r) => {
          // eslint-disable-next-line no-param-reassign
          r.reportStatus = ExternalInvestigationReportStatus.Downloading;
        }
      );

      return {
        ...state,
        requests,
      };
    }
    case Actions.EXTERNAL_INVESTIGATION_REPORT_DOWNLOAD_PDF_FINAL_ATTEMPT_ERROR:
    case Actions.EXTERNAL_INVESTIGATION_REPORT_DOWNLOAD_PDF_FINAL_ATTEMPT_SUCCESS:
    case Actions.EXTERNAL_INVESTIGATION_REPORT_DOWNLOAD_PDF_SUCCESS: {
      const externalInvestigation = action.payload.params;
      const requests = reportUpdateFunc(
        state.requests,
        externalInvestigation.externalInvestigationId,
        externalInvestigation.externalInvestigationType,
        (r) => {
          // eslint-disable-next-line no-param-reassign
          r.reportStatus = ExternalInvestigationReportStatus.ReadyToDownload;
        }
      );

      return {
        ...state,
        requests,
      };
    }
    default:
      return state;
  }
};

import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch as ReduxDispatch } from 'redux';
import _ from 'lodash';

import * as actions from '../../../redux/actions';

import type {
  DonorType,
  AdultSearchResult,
  AdultSearchResults,
  CordSearchResult,
  CordSearchResults,
  SearchResults,
} from '../../../../core/types';
import donorTypes from '../../../../core/constants/donorTypes';
import { SelectedResult } from '../../../../donorMatchSearchRequests/types';

type OwnProps = {
  donorType: DonorType;
  searchResults: SearchResults;
};
type Props = PropsFromRedux & OwnProps;
type State = {
  selectionRange?: number;
};

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>) => ({
  updateSelectedItems: bindActionCreators(actions.updateSelectedItems, dispatch),
});

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const compareTsThenTNC = (a: CordSearchResult, b: CordSearchResult) => {
  if (b.totalScore - a.totalScore === 0) {
    return b.cord.tncFinalCount - a.cord.tncFinalCount;
  }
  return b.totalScore - a.totalScore;
};

const getSortedRangeOfSelectedCords = (searchResults: CordSearchResults, numberOfDonors: number): CordSearchResult[] =>
  searchResults.results
    .sort((a, b) => compareTsThenTNC(a, b)) // TODO NOVA-808 update this to sort using order Number.
    .slice(0, numberOfDonors);

const getRangeOfSelectedDonors = (searchResults: AdultSearchResults, numberOfDonors: number): AdultSearchResult[] =>
  searchResults.results.slice(0, numberOfDonors);

export class SelectRange extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectionRange: undefined,
    };
  }

  render() {
    const { donorType, searchResults } = this.props;
    const { selectionRange } = this.state;
    return (
      <div>
        <input
          type="number"
          placeholder={`Top X ${donorType}s`}
          value={selectionRange || ''}
          name="topX"
          className="Select-control"
          onChange={this.handleSelectionRangeChange}
          onKeyPress={this.handleKeyPress}
          onBlur={this.toggleSelectRange}
          min="0"
          max={searchResults.results.length}
          style={{ width: '200px' }}
        />
      </div>
    );
  }

  toggleSelectRange = () => {
    const { searchResults } = this.props;
    if (searchResults.type === donorTypes.cord.value) {
      this.toggleSelectRangeOfCords(searchResults as CordSearchResults);
    } else {
      this.toggleSelectRangeOfDonors(searchResults as AdultSearchResults);
    }
  };

  toggleSelectRangeOfCords = (searchResults: CordSearchResults) => {
    const { updateSelectedItems } = this.props;
    const { selectionRange } = this.state;
    let newSelectedRows: Partial<SelectedResult>[];
    if (selectionRange) {
      const rangeOfDonors = getSortedRangeOfSelectedCords(searchResults, selectionRange);
      newSelectedRows = rangeOfDonors.map((result) => ({
        id: result.cord.cordId,
      }));
    } else {
      newSelectedRows = [];
    }
    updateSelectedItems(newSelectedRows);
  };

  handleSelectionRangeChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    this.setState({
      selectionRange: Number(event.currentTarget.value),
    });
  };

  handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // keyCode is deprecated
    if (event.nativeEvent.keyCode === 13) {
      // 13 is the keyCode for "Return"
      event.currentTarget.blur();
    }
  };

  toggleSelectRangeOfDonors = (searchResults: AdultSearchResults) => {
    const { updateSelectedItems } = this.props;
    const { selectionRange } = this.state;
    let newSelectedRows: Partial<SelectedResult>[];
    if (selectionRange) {
      const rangeOfDonors = getRangeOfSelectedDonors(searchResults, selectionRange);
      newSelectedRows = rangeOfDonors.map((result) => ({
        id: result.donor.id,
        matchGrade: result.matchGrade,
        matchType: result.matchType,
        registryId: _.get(result, 'donor.originatingRegistry.id') as string,
      }));
    } else {
      newSelectedRows = [];
    }
    updateSelectedItems(newSelectedRows);
  };
}

export default connector(SelectRange);

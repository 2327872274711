import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { PopUpWrapper } from '../../../../../core';
import { getExternalInvestigations } from '../../../../../externalInvestigations/redux/actions';
import { styles } from '../../../style';
import SendResultsPopup from './SendResultsPopup/SendResultsPopup';

type Props = {
  investigationId: number;
  patientId: string;
  isGias?: boolean;
};

export const SendResultsButton = ({ investigationId, isGias, patientId }: Props) => {
  const popUpStyle = {
    ...styles.patientDashboardPopUp,
    padding: '50px',
    border: '1px solid #dee2e6',
    fontSize: '14px',
    maxWidth: '90%',
    maxHeight: '90%',
    overflow: 'auto',
    width: '90%',
  };
  const dispatch = useDispatch();
  const [isPopupShown, setIsPopupShown] = useState(false);
  const [isVerificationTypingResultSaved, setIsVerificationTypingResultSaved] = useState(true);
  const [areResultsSent, setAreResultsSent] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isEdited, setIsEdited] = useState(false);

  const handleOpenAndCloseModal = () => {
    if (areResultsSent) {
      dispatch(getExternalInvestigations(patientId));
    }
    if (isVerificationTypingResultSaved && isEdited) {
      setShowModal(true);
    } else {
      setIsPopupShown(false);
    }
  };

  return (
    <div>
      <PopUpWrapper
        placement=""
        name="Send Results"
        shouldDarkenBackground
        buttonClassName="btn btn--table-btn btn--test-result"
        popoverContainerClassName="react-tiny-popover-container--center"
        onClick={() => setIsPopupShown(true)}
        isPopUpShown={isPopupShown}
        popUpStyle={popUpStyle}
        onClosePopover={handleOpenAndCloseModal}
      >
        <SendResultsPopup
          investigationId={investigationId}
          onClose={() => setIsPopupShown(false)}
          showModal={showModal}
          setIsVerificationTypingResultSaved={setIsVerificationTypingResultSaved}
          setShowModal={setShowModal}
          setIsEdited={setIsEdited}
          setAreResultsSent={setAreResultsSent}
          onClosePopover={handleOpenAndCloseModal}
          isGias={isGias}
          isVerificationTypingResultSaved={isVerificationTypingResultSaved}
        />
      </PopUpWrapper>
    </div>
  );
};

SendResultsButton.defaultProps = {
  isGias: undefined,
};

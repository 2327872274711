import * as React from 'react';
import { Field, FormAction } from 'redux-form';
import autocompleteOff from '../../../../core/constants/autocompleteOff';

type Option = {
  label: string;
  value: string;
};

type Props = {
  className?: string;
  label?: string;
  name?: string;
  component?: 'input' | 'textarea' | React.ComponentType<any>;
  type?: 'date' | 'input' | 'checkbox' | 'text' | React.ComponentType<any>;
  placeholder?: string;
  wide?: boolean;
  onChange?: () => FormAction;
  options?: Option[];
  onBlur?: (e: React.SyntheticEvent) => Promise<void>;
};

type InputProps = {
  input: Record<string, unknown>;
  label: string;
  type: string;
  className: string;
  meta: {
    touched: boolean;
    error: string;
    warning: string;
  };
};

const labelledFieldStyle = {
  flex: '1',
  margin: '18px 0',
  paddingRight: 32,
};

const errorMessageStyle = {
  color: 'red',
};

export const renderInput = ({
  input,
  className,
  type,
  meta: { touched, error, warning },
}: InputProps): React.ReactElement<any> => (
  <div>
    {type === 'checkbox' ? (
      /* eslint-disable-next-line jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for */
      <label className="control control--checkbox">
        <input {...input} className={className} type={type} autoComplete={autocompleteOff} />
        <div className="control__indicator" />
      </label>
    ) : (
      <input {...input} className={className} type={type} autoComplete={autocompleteOff} />
    )}
    {touched &&
      ((error && <span style={errorMessageStyle}>{error}</span>) ||
        (warning && <span style={errorMessageStyle}>{warning}</span>))}
  </div>
);

const LabelledField = (props: Props) => {
  const { component, label, name } = props;
  const fieldComponent = component === 'input' ? renderInput : component;

  return (
    <div style={labelledFieldStyle}>
      <label htmlFor={name}>
        {label}
        <Field {...props} component={fieldComponent} />
      </label>
    </div>
  );
};

LabelledField.defaultProps = {
  className: undefined,
  label: undefined,
  name: undefined,
  component: undefined,
  type: undefined,
  placeholder: undefined,
  wide: undefined,
  onChange: undefined,
  options: undefined,
  onBlur: undefined,
};

export default LabelledField;

import {
  post,
  get,
  SuccessAction as ApiSuccessAction,
  RequestAction as ApiRequestAction,
  ErrorAction as ApiErrorAction,
} from '@an/nova-frontend-rest-client';
import type { ReduxState } from '../../rootReducer';
import Config from '../../config';
import type { Dispatch } from '../../core/types';
import { subjectTypes } from '../../subject/constants/subjectTypes';
import { DonorDetails } from '../../subject/types';
import { addNotification } from '../../notifications/addNotification';

const GET_SUBJECT_CONSTANTS: 'subject/getSubjectConstants' = 'subject/getSubjectConstants';
const GET_SUBJECT_CONSTANTS_REQUEST: 'subject/getSubjectConstants/request' = 'subject/getSubjectConstants/request';
const GET_SUBJECT_CONSTANTS_SUCCESS: 'subject/getSubjectConstants/success' = 'subject/getSubjectConstants/success';
const GET_SUBJECT_CONSTANTS_ERROR: 'subject/getSubjectConstants/error' = 'subject/getSubjectConstants/error';

const EDIT_ADULT_DONOR: 'subject/editAdultDonor' = 'subject/editAdultDonor';
const EDIT_ADULT_DONOR_REQUEST: 'subject/editAdultDonor/request' = 'subject/editAdultDonor/request';
const EDIT_ADULT_DONOR_SUCCESS: 'subject/editAdultDonor/success' = 'subject/editAdultDonor/success';
const EDIT_ADULT_DONOR_ERROR: 'subject/editAdultDonor/error' = 'subject/editAdultDonor/error';

const GET_ADULT_DONOR: 'subject/getAdultDonor' = 'subject/getAdultDonor';
const GET_ADULT_DONOR_REQUEST: 'subject/getAdultDonor/request' = 'subject/getAdultDonor/request';
const GET_ADULT_DONOR_SUCCESS: 'subject/getAdultDonor/success' = 'subject/getAdultDonor/success';
const GET_ADULT_DONOR_ERROR: 'subject/getAdultDonor/error' = 'subject/getAdultDonor/error';

export type SubjectConstants = {
  GenderOptions: string[];
  DiagnosisOptions: string[];
  PatientTypeOptions: string[];
  EthnicGroupOptions: string[];
  AddressTypeOptions: string[];
};
export const Actions = {
  GET_SUBJECT_CONSTANTS,
  GET_SUBJECT_CONSTANTS_REQUEST,
  GET_SUBJECT_CONSTANTS_SUCCESS,
  GET_SUBJECT_CONSTANTS_ERROR,

  EDIT_ADULT_DONOR_REQUEST,
  EDIT_ADULT_DONOR_SUCCESS,
  EDIT_ADULT_DONOR_ERROR,

  GET_ADULT_DONOR,
  GET_ADULT_DONOR_REQUEST,
  GET_ADULT_DONOR_SUCCESS,
  GET_ADULT_DONOR_ERROR,
};

type GetSubjectConstantRequestAction = ApiRequestAction<typeof GET_SUBJECT_CONSTANTS_REQUEST, Record<string, unknown>>;
type GetSubjectConstantSuccessAction = ApiSuccessAction<typeof GET_SUBJECT_CONSTANTS_SUCCESS, any>;
type GetSubjectConstantErrorAction = ApiErrorAction<typeof GET_SUBJECT_CONSTANTS_ERROR, ApiErrorAction>;

type GetAdultDonorRequestAction = ApiRequestAction<typeof GET_ADULT_DONOR_REQUEST, Record<string, unknown>>;
type GetAdultDonorSuccessAction = ApiSuccessAction<
  typeof GET_ADULT_DONOR_SUCCESS,
  Record<string, unknown>,
  DonorDetails
>;
type GetAdultDonorErrorAction = ApiErrorAction<typeof GET_ADULT_DONOR_ERROR, ApiErrorAction>;

type EditAdultDonorRequestAction = ApiRequestAction<typeof EDIT_ADULT_DONOR_REQUEST, Record<string, unknown>>;
type EditAdultDonorSuccessAction = ApiSuccessAction<
  typeof EDIT_ADULT_DONOR_SUCCESS,
  Record<string, DonorDetails>,
  Record<string, unknown>
>;

type EditAdultDonorErrorAction = ApiErrorAction<typeof EDIT_ADULT_DONOR_ERROR, ApiErrorAction, Record<string, unknown>>;

export type DonorAction =
  | GetSubjectConstantRequestAction
  | GetSubjectConstantSuccessAction
  | GetSubjectConstantErrorAction
  | GetAdultDonorRequestAction
  | GetAdultDonorSuccessAction
  | GetAdultDonorErrorAction
  | EditAdultDonorRequestAction
  | EditAdultDonorSuccessAction
  | EditAdultDonorErrorAction;

export const getSubjectConstants = () => (dispatch: Dispatch<unknown>) =>
  dispatch(
    get<ReduxState, ApiRequestAction<any>>(`${Config().apiBaseUrl}creation-constants`, GET_SUBJECT_CONSTANTS, {})
  );

export const getAdultDonor = (donorId: string) =>
  get<ReduxState, ApiRequestAction<DonorDetails>>(`${Config().apiBaseUrl}donors/adults/${donorId}`, GET_ADULT_DONOR, {
    donorId,
    subjectType: subjectTypes.donor.id,
  });

export const editAdultDonor = (donor: DonorDetails) => async (dispatch: Dispatch<any>) => {
  const { Id } = donor;
  const data = await dispatch(
    post<ReduxState, ApiRequestAction<void>>(
      `${Config().apiBaseUrl}donors/adults/${Id}`,
      EDIT_ADULT_DONOR,
      {
        donor,
        successMessage: `Successfully updated donor ${Id}`,
        errorMessage: `Failed to update donor ${Id}`,
      },
      donor
    )
  );
  addNotification(data);
  return data;
};

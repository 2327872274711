/* eslint-disable @typescript-eslint/no-inferrable-types */
import {
  ErrorAction as ApiErrorAction,
  get,
  post,
  RequestAction as ApiRequestAction,
  SuccessAction as ApiSuccessAction,
  Response as ApiResponse,
} from '@an/nova-frontend-rest-client';
import UrlAssembler from 'url-assembler';
import Config from '../../config';
import type { Dispatch } from '../../core/types';
// eslint-disable-next-line import/no-cycle
import { ReduxState } from '../../rootReducer';
import { ApiExternalInvestigationAction, ApiMessageDenialAction, ApiUpdatedActionsResponse } from '../types/api';

const EXTERNAL_INVESTIGATION_ACTIONS: 'externalInvestigationActions' = 'externalInvestigationActions';
const EXTERNAL_INVESTIGATION_ACTIONS_ERROR: 'externalInvestigationActions/error' = 'externalInvestigationActions/error';
const EXTERNAL_INVESTIGATION_ACTIONS_REQUEST: 'externalInvestigationActions/request' =
  'externalInvestigationActions/request';
const EXTERNAL_INVESTIGATION_ACTIONS_SUCCESS: 'externalInvestigationActions/success' =
  'externalInvestigationActions/success';
const UPDATE_EXTERNAL_INVESTIGATION_ACTIONS: 'updateExternalInvestigationActions' =
  'updateExternalInvestigationActions';
const UPDATE_EXTERNAL_INVESTIGATION_ACTIONS_REQUEST: 'updateExternalInvestigationActions/request' =
  'updateExternalInvestigationActions/request';
const UPDATE_EXTERNAL_INVESTIGATION_ACTIONS_SUCCESS: 'updateExternalInvestigationActions/success' =
  'updateExternalInvestigationActions/success';
const UPDATE_EXTERNAL_INVESTIGATION_ACTIONS_ERROR: 'updateExternalInvestigationActions/error' =
  'updateExternalInvestigationActions/error';
const UPDATED_ACTIONS_RESPONSE_SUCCESS: 'updatedActionsResponse/success' = 'updatedActionsResponse/success';
const UPDATED_ACTIONS_RESPONSE_ERROR: 'updatedActionsResponse/error' = 'updatedActionsResponse/error';

const MESSAGE_DENIAL_ACTIONS: 'rejectedMessagesActions' = 'rejectedMessagesActions';
const MESSAGE_DENIAL_ACTIONS_REQUEST: 'rejectedMessagesActions/request' = 'rejectedMessagesActions/request';
const MESSAGE_DENIAL_ACTIONS_SUCCESS: 'rejectedMessagesActions/success' = 'rejectedMessagesActions/success';
const MESSAGE_DENIAL_ACTIONS_ERROR: 'rejectedMessagesActions/error' = 'rejectedMessagesActions/error';
const UPDATE_MESSAGE_DENIAL_ACTIONS: 'updateRejectedMessagesActions' = 'updateRejectedMessagesActions';
const UPDATE_MESSAGE_DENIAL_ACTIONS_REQUEST: 'updateRejectedMessagesActions/request' =
  'updateRejectedMessagesActions/request';
const UPDATE_MESSAGE_DENIAL_ACTIONS_SUCCESS: 'updateRejectedMessagesActions/success' =
  'updateRejectedMessagesActions/success';
const UPDATE_MESSAGE_DENIAL_ACTIONS_ERROR: 'updateRejectedMessagesActions/error' =
  'updateRejectedMessagesActions/error';

export const Actions = {
  EXTERNAL_INVESTIGATION_ACTIONS_REQUEST,
  EXTERNAL_INVESTIGATION_ACTIONS_SUCCESS,
  EXTERNAL_INVESTIGATION_ACTIONS_ERROR,
  MESSAGE_DENIAL_ACTIONS_REQUEST,
  MESSAGE_DENIAL_ACTIONS_SUCCESS,
  MESSAGE_DENIAL_ACTIONS_ERROR,
  UPDATE_EXTERNAL_INVESTIGATION_ACTIONS_REQUEST,
  UPDATE_EXTERNAL_INVESTIGATION_ACTIONS_SUCCESS,
  UPDATE_EXTERNAL_INVESTIGATION_ACTIONS_ERROR,
  UPDATE_MESSAGE_DENIAL_ACTIONS_REQUEST,
  UPDATE_MESSAGE_DENIAL_ACTIONS_SUCCESS,
  UPDATE_MESSAGE_DENIAL_ACTIONS_ERROR,
  UPDATED_ACTIONS_RESPONSE_SUCCESS,
  UPDATED_ACTIONS_RESPONSE_ERROR,
};

type ErrorMessageParam = { errorMessage: string };
type SuccessMessageParam = { successMessage: string };
type Error = { Error: string };

export type ExternalInvestigationActionsRequest = ApiRequestAction<
  typeof EXTERNAL_INVESTIGATION_ACTIONS_REQUEST,
  Record<string, unknown>
>;
export type ExternalInvestigationActionsSuccess = ApiSuccessAction<
  typeof EXTERNAL_INVESTIGATION_ACTIONS_SUCCESS,
  Record<string, unknown>,
  ApiExternalInvestigationAction[]
>;
export type ExternalInvestigationActionsError = ApiErrorAction<
  typeof EXTERNAL_INVESTIGATION_ACTIONS_ERROR,
  Record<string, unknown>
>;
export type UpdateExternalInvestigationActionsRequest = ApiRequestAction<
  typeof UPDATE_EXTERNAL_INVESTIGATION_ACTIONS_REQUEST,
  Record<string, unknown>
>;
export type UpdateExternalInvestigationActionsSuccess = ApiSuccessAction<
  typeof UPDATE_EXTERNAL_INVESTIGATION_ACTIONS_SUCCESS,
  ErrorMessageParam & SuccessMessageParam,
  SuccessMessageParam
>;
export type UpdateExternalInvestigationActionsError = ApiErrorAction<
  typeof UPDATE_EXTERNAL_INVESTIGATION_ACTIONS_ERROR,
  ErrorMessageParam & SuccessMessageParam,
  Error
>;
export type UpdatedActionsResponseSuccess = {
  type: typeof UPDATED_ACTIONS_RESPONSE_SUCCESS;
  payload: {
    params: SuccessMessageParam;
  };
};
export type UpdatedActionsResponseError = {
  type: typeof UPDATED_ACTIONS_RESPONSE_ERROR;
  payload: {
    params: ErrorMessageParam;
  };
};

export type ExternalInvestigationAction =
  | ExternalInvestigationActionsRequest
  | ExternalInvestigationActionsSuccess
  | ExternalInvestigationActionsError
  | UpdateExternalInvestigationActionsRequest
  | UpdateExternalInvestigationActionsSuccess
  | UpdateExternalInvestigationActionsError
  | UpdatedActionsResponseSuccess
  | UpdatedActionsResponseError;

export type MessageDenialActionsRequest = ApiRequestAction<
  typeof MESSAGE_DENIAL_ACTIONS_REQUEST,
  Record<string, unknown>
>;
export type MessageDenialActionsSuccess = ApiSuccessAction<
  typeof MESSAGE_DENIAL_ACTIONS_SUCCESS,
  Record<string, unknown>,
  ApiMessageDenialAction[]
>;
export type MessageDenialActionsError = ApiErrorAction<typeof MESSAGE_DENIAL_ACTIONS_ERROR, Record<string, unknown>>;
export type UpdateMessageDenialsActionsRequest = ApiRequestAction<
  typeof UPDATE_MESSAGE_DENIAL_ACTIONS_REQUEST,
  Record<string, unknown>
>;
export type UpdateMessageDenialsActionsSuccess = ApiSuccessAction<
  typeof UPDATE_MESSAGE_DENIAL_ACTIONS_SUCCESS,
  ErrorMessageParam & SuccessMessageParam,
  SuccessMessageParam
>;
export type UpdateMessageDenialsActionsError = ApiErrorAction<
  typeof UPDATE_MESSAGE_DENIAL_ACTIONS_ERROR,
  ErrorMessageParam & SuccessMessageParam,
  Error
>;

export type RejectedMessageReduxAction =
  | MessageDenialActionsRequest
  | MessageDenialActionsSuccess
  | MessageDenialActionsError
  | UpdateMessageDenialsActionsRequest
  | UpdateMessageDenialsActionsSuccess
  | UpdateMessageDenialsActionsError;

export const getExternalInvestigationActions = () => (dispatch: Dispatch<unknown>) => {
  dispatch(
    get<ReduxState, ApiExternalInvestigationAction>(
      `${Config().apiBaseUrl}actions/external-investigations`,
      EXTERNAL_INVESTIGATION_ACTIONS
    )
  );
};

export const getExternalInvestigationActionsByDate = (date: string) => (dispatch: Dispatch<unknown>) => {
  dispatch(
    get<ReduxState, ApiExternalInvestigationAction>(
      `${Config().apiBaseUrl}actions/external-investigations?isActioned=true&updatedDate=${date}`,
      EXTERNAL_INVESTIGATION_ACTIONS
    )
  );
};

export const getMessageDenialActions = () => (dispatch: Dispatch<unknown>) => {
  dispatch(
    get<ReduxState, ApiMessageDenialAction[]>(`${Config().apiBaseUrl}actions/message-denials`, MESSAGE_DENIAL_ACTIONS)
  );
};

export const getMessageDenialActionsByDate = (date: string) => (dispatch: Dispatch<unknown>) => {
  dispatch(
    get<ReduxState, ApiMessageDenialAction[]>(
      `${Config().apiBaseUrl}actions/message-denials?isActioned=true&updatedDate=${date}`,
      MESSAGE_DENIAL_ACTIONS
    )
  );
};

const setFailedActions = (isMessageDenials?: boolean) => ({
  type: UPDATED_ACTIONS_RESPONSE_ERROR,
  payload: {
    params: {
      errorMessage: isMessageDenials
        ? 'Failed to mark message denials as completed'
        : 'Failed to mark actions as completed',
    },
  },
});

const setSuccedeedActions = (isMessageDenials?: boolean) => ({
  type: UPDATED_ACTIONS_RESPONSE_SUCCESS,
  payload: {
    params: {
      successMessage: isMessageDenials
        ? 'Message denials have been marked as completed'
        : 'Actions have been marked as completed',
    },
  },
});

const setActionsNotification =
  (response: ApiResponse<ApiUpdatedActionsResponse>, isMessageDenials?: boolean) => (dispatch: Dispatch<unknown>) => {
    const { body } = response.response;
    if (body) {
      if (body.FailedActions.length > 0) {
        dispatch(setFailedActions(isMessageDenials));
      } else {
        dispatch(setSuccedeedActions(isMessageDenials));
      }
    }
  };

export const updateExternalInvestigationActions =
  (actionIds: number[]) =>
  async (dispatch: Dispatch<any>): Promise<any> => {
    const url = UrlAssembler(Config().apiBaseUrl).template('actions/external-investigations/action').toString();
    const response = await dispatch(
      post(
        url,
        UPDATE_EXTERNAL_INVESTIGATION_ACTIONS,
        {
          successMessage: 'Actions have been marked as complete',
          errorMessage: 'Failed to mark actions as completed',
        },
        actionIds
      )
    );
    dispatch(setActionsNotification(response));
    return response;
  };

export const updateMessageDenialsActions =
  (actionIds: number[]) =>
  async (dispatch: Dispatch<any>): Promise<any> => {
    const url = UrlAssembler(Config().apiBaseUrl).template('actions/message-denials/action').toString();
    const response = await dispatch(
      post(
        url,
        UPDATE_MESSAGE_DENIAL_ACTIONS,
        {
          successMessage: 'Actions have been marked as complete',
          errorMessage: 'Failed to mark actions as completed',
        },
        actionIds
      )
    );
    dispatch(setActionsNotification(response, true));
    return response;
  };

import { LOCATION_CHANGE, RouterState } from 'connected-react-router';
import { convertAntigen } from '../../core';
import { Antigen } from '../../core/types';

import { Actions, GetAntigensRequestAction, GetAntigensSuccessAction } from './actions';

type LocationChangeAction = { type: typeof LOCATION_CHANGE; payload: RouterState };

type AntigenState = Record<number, AntigenRequest>;

type HlaActions = GetAntigensRequestAction | GetAntigensSuccessAction | LocationChangeAction;

export type AntigenRequest = {
  data: Antigen[] | null | undefined;
  latestHlaPrefixRequested: string;
  isFetchingLatest: boolean;
};

export type HlaAntigensReducerState = {
  antigens: {
    [k: string]: AntigenState;
    A: AntigenState;
    B: AntigenState;
    C: AntigenState;
    Cw: AntigenState;
    Drb1: AntigenState;
    Dqb1: AntigenState;
    Dpb1: AntigenState;
    Drb3: AntigenState;
    Drb4: AntigenState;
    Drb5: AntigenState;
    Dqa1: AntigenState;
    Dpa1: AntigenState;
  };
};

const initialState: HlaAntigensReducerState = {
  antigens: {
    A: {},
    B: {},
    C: {},
    Cw: {},
    Drb1: {},
    Dqb1: {},
    Dpb1: {},
    Drb3: {},
    Drb4: {},
    Drb5: {},
    Dqa1: {},
    Dpa1: {},
  },
};

export default (state: HlaAntigensReducerState = initialState, action: HlaActions): HlaAntigensReducerState => {
  switch (action.type) {
    case Actions.GET_ANTIGENS_REQUEST: {
      const locusState: AntigenState = state.antigens[action.payload.params.locusType];
      const rowState = locusState[action.payload.params.antigenRowId] || {};
      return {
        ...state,
        antigens: {
          ...state.antigens,
          [action.payload.params.locusType]: {
            ...locusState,
            [action.payload.params.antigenRowId]: {
              ...rowState,
              latestHlaPrefixRequested: action.payload.params.hlaPrefix,
              isFetchingLatest: true,
            },
          },
        },
      };
    }
    case Actions.GET_ANTIGENS_SUCCESS:
      return state.antigens[action.payload.params.locusType][action.payload.params.antigenRowId]
        .latestHlaPrefixRequested === action.payload.params.hlaPrefix
        ? {
            ...state,
            antigens: {
              ...state.antigens,
              [action.payload.params.locusType]: {
                ...state.antigens[action.payload.params.locusType],
                [action.payload.params.antigenRowId]: {
                  data: action.payload.data.Data.map((a) => convertAntigen(a)),
                  latestHlaPrefixRequested:
                    state.antigens[action.payload.params.locusType][action.payload.params.antigenRowId]
                      .latestHlaPrefixRequested,
                  isFetchingLatest: false,
                },
              },
            },
          }
        : state;
    default:
      return initialState;
  }
};

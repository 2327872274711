import _ from 'lodash';
import { ExternalInvestigationTypeValue } from '../../externalInvestigations/types';

type ExternalInvestigationTypeNameAndValue = {
  value: ExternalInvestigationTypeValue;
  name: string;
  nameInTable: string;
};

export const externalInvestigationTypes: Record<string, ExternalInvestigationTypeNameAndValue> = {
  idm: {
    value: 'Idm',
    name: 'IDM',
    nameInTable: 'IDM',
  },
  vt: {
    value: 'VerificationTyping',
    name: 'VT',
    nameInTable: 'VT',
  },
  extendedTyping: {
    value: 'ExtendedTyping',
    name: 'Extended Typing',
    nameInTable: 'ET',
  },
};

export const getExternalInvestigationTypeNameInTable = (value: string): string => {
  const type = _.find(
    externalInvestigationTypes,
    (externalInvestigationType) => externalInvestigationType.value === value
  );

  if (type == null) {
    throw Error(`Could not find external investigation type ${value}`);
  }

  return type.nameInTable;
};

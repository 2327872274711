import React, { PureComponent } from 'react';

type Props = {
  centreDetails: string[];
  isReportReadOnly: boolean;
  detailsEdit: (address: string) => void;
};
type State = {
  details: string;
};

type DetailsEvent = { value: string } & HTMLTextAreaElement;

export default class EditableTransplantCentreDetails extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    const { centreDetails } = this.props;
    this.state = {
      details: this.displayCentreDetails(centreDetails),
    };
  }

  componentDidUpdate(prevProps: Props) {
    const { centreDetails } = this.props;
    if (centreDetails !== prevProps.centreDetails) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        details: this.displayCentreDetails(centreDetails),
      });
    }
  }

  render() {
    const { isReportReadOnly } = this.props;
    const { details } = this.state;
    return (
      <textarea
        placeholder="Transplant Centre Details Not Found - Add Details."
        name="transplantCentreDetails"
        rows={6}
        value={details}
        readOnly={isReportReadOnly}
        onChange={this.handleDetailsChange}
        onBlur={this.saveEditedDetails}
      />
    );
  }

  handleDetailsChange = (event: React.SyntheticEvent<DetailsEvent>) => {
    this.setState({
      details: event.currentTarget.value,
    });
  };

  saveEditedDetails = () => {
    const { detailsEdit } = this.props;
    const { details } = this.state;
    detailsEdit(details);
  };

  displayCentreDetails = (centreDetails: string[]): string => centreDetails.join('\n');
}

import Config from '../../config';

const downloadFile = (fileUrl: string, filename: string, fileExtention: string) => {
  const anchor = document.createElement('a');
  document.body.appendChild(anchor);

  const headers = new Headers();
  const token = sessionStorage.getItem(Config().accessTokenKey);
  headers.append('Authorization', `Bearer ${token}`);

  fetch(fileUrl, { headers })
    .then((response) => response.blob())
    .then((blob) => {
      const objectUrl = window.URL.createObjectURL(blob);

      anchor.href = objectUrl;
      anchor.download = `${filename}.${fileExtention}`;
      anchor.click();

      window.URL.revokeObjectURL(objectUrl);
    });
};

export default downloadFile;

import React from 'react';
import { Button } from '../../../../core/components/Button';
import { formatDate } from '../../../../core/helpers/dateHelpers';
import { investigationTypeDisplayString } from '../../../helpers/getInvestigationType';
import type { SubjectInvestigation } from '../../../types';

type Props = {
  investigations: SubjectInvestigation[];
  onSelectRow: (selectedRow: number) => void;
  selectedInvestigation: number | null;
  subjectRecordType: string | undefined;
};

const ExistingInvestigationsTable = ({
  investigations,
  onSelectRow,
  selectedInvestigation,
  subjectRecordType,
}: Props) => (
  <div className="subject-investigations-section">
    <h3
      className="subject-investigations-section__heading"
      data-testid="subject-investigations-section-heading"
    >{`Select ${subjectRecordType || ''} Investigation`}</h3>
    {investigations.length > 0 && (
      <div className="table-wrapper" style={{ maxHeight: '367px' }}>
        <table data-testid="existing-investigations-table" className="table centered-table-data">
          <thead className="table-header table--sticky-header">
            <tr>
              <th>Type</th>
              <th>Category</th>
              <th>Sample Received</th>
              <th>Sample</th>
              <th>Status</th>
              <th>View Investigation Details</th>
            </tr>
          </thead>
          <tbody>
            {investigations.map((investigation) => (
              <tr
                className={`${
                  selectedInvestigation === investigation.InvestigationId
                    ? 'centered-table-data active-row'
                    : 'centered-table-data'
                }`}
                key={investigation.InvestigationId}
                onDoubleClick={() => {
                  onSelectRow(investigation.InvestigationId);
                }}
              >
                <td>
                  {investigation.InvestigationType && investigationTypeDisplayString(investigation.InvestigationType)}
                </td>
                <td>{investigation.Name}</td>
                <td data-testid="investigations-table-sample-received-date">
                  {formatDate(investigation.SampleReceivedDate)}
                </td>
                <td>{investigation.SampleId}</td>
                <td>{investigation.Status}</td>
                <td>
                  <Button
                    onClick={() => onSelectRow(investigation.InvestigationId)}
                    text="View Investigation Details"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )}
  </div>
);

export default ExistingInvestigationsTable;

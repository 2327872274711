import React from 'react';
import { convertToAlgorithmName } from '../../../patient/patientDashboard/helpers/algorithmConverter';
import RankingComparisonRow from './AlgorithmRankingComparisonRow';
import type { RankingComparisons } from './types';

type Props = {
  algorithm1: string;
  algorithm2: string;
  rankingComparisons: RankingComparisons[];
};

export default function AlgorithmRankingComparisonTable({ algorithm1, algorithm2, rankingComparisons }: Props) {
  rankingComparisons.sort((a, b) => Math.abs(a.rankDifference) - Math.abs(b.rankDifference));
  rankingComparisons.reverse();

  return (
    <table style={{ width: '100%' }}>
      <thead>
        <tr>
          <th>Donor ID</th>
          <th>{convertToAlgorithmName(algorithm1)}</th>
          <th>{convertToAlgorithmName(algorithm2)}</th>
          <th>Difference In Rank</th>
        </tr>
      </thead>
      <tbody>
        {rankingComparisons.map((comparison) => (
          <RankingComparisonRow key={comparison.donorId} {...comparison} />
        ))}
      </tbody>
    </table>
  );
}

import React, { PureComponent } from 'react';
import ExpandButton from '../../../core/components/ExpandButton';
import LoadingMessage from '../../../core/components/LoadingMessage/LoadingMessage';
import DetailedPatientRenderer from './DetailedPatientRenderer/DetailedPatientRenderer';
import ExtraPatientDetails from './ExtraPatientDetails';
import type { PatientInfo } from '../../types';
import type { SearchRequestSummary } from '../../../donorMatchSearchRequests/types';

type Props = {
  patient?: PatientInfo;
  searchedHla?: SearchRequestSummary;
  isExpandedByDefault?: boolean;
};

type State = {
  isExpanded: boolean;
};

export class PatientInfoRenderer extends PureComponent<Props, State> {
  static defaultProps = {
    patient: undefined,
    isExpandedByDefault: false,
    searchedHla: undefined,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      isExpanded: props.isExpandedByDefault || false,
    };
  }

  render(): JSX.Element {
    const { patient, searchedHla } = this.props;
    const { isExpanded } = this.state;

    return patient ? (
      <>
        <DetailedPatientRenderer patient={patient} searchedHla={searchedHla} />
        {isExpanded ? <ExtraPatientDetails patient={patient} /> : null}
        <ExpandButton isExpanded={isExpanded} toggleExpansion={this.toggleExpansion} />
      </>
    ) : (
      <>
        <div
          style={{
            backgroundColor: '#555',
            border: '1px solid #ddd',
            borderBottom: 'none',
            height: '60px',
          }}
        />
        <div
          style={{
            backgroundColor: '#f9f9f9',
            border: '1px solid #ddd',
            borderTop: 'none',
            padding: '20px 0',
          }}
        >
          <LoadingMessage />
        </div>
      </>
    );
  }

  toggleExpansion = (): void => {
    const { isExpanded } = this.state;
    this.setState({ isExpanded: !isExpanded });
  };
}

export default PatientInfoRenderer;

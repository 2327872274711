import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
// eslint-disable-next-line import/no-cycle
import searchPatients, { SearchPatientsReducerState } from './patient/core/redux/reducer';
// eslint-disable-next-line import/no-cycle
import searchResultsPage, { SearchResultsPageReducerState } from './donorSearchResults/redux/reducer';
// eslint-disable-next-line import/no-cycle
import latestSearchRequests, { LatestSearchRequestsReducerState } from './donorMatchSearchRequests/redux/reducer';
// eslint-disable-next-line import/no-cycle
import notification, { NotificationReducerState } from './notifications/redux/reducer';
// eslint-disable-next-line import/no-cycle
import searchReport, { SearchReportReducerState } from './searchReports/core/redux/reducer';
// eslint-disable-next-line import/no-cycle
import externalInvestigations, { ExternalInvestigationsState } from './externalInvestigations/redux/reducer';
// eslint-disable-next-line import/no-cycle
import currentUser, { CurrentUserState } from './currentUser/redux/reducer';
import { featureFlags, FeatureFlagsState } from './featureFlags';
import hlaAntigens, { HlaAntigensReducerState } from './hlaInput/redux/reducer';
// eslint-disable-next-line import/no-cycle
import transplantCentreManagement, { TransplantCentreReducerState } from './transplantCentre/redux/reducer';
import { searchTeamUsers, SearchTeamUsersState } from './users';

import subject, { SubjectReducerState } from './subject/redux/reducer';
import donor, { DonorReducerState } from './donor/redux/reducer';

// TODO-Flow: It should be possible to generate this automatically from the
// reducers as explained in this post:
// https://blog.callstack.io/type-checking-react-and-redux-thunk-with-flow-part-2-206ce5f6e705

export type ReduxState = {
  currentUser: CurrentUserState;
  featureFlags: FeatureFlagsState;
  hlaAntigens: HlaAntigensReducerState;
  latestSearchRequests: LatestSearchRequestsReducerState;
  notification: NotificationReducerState;
  searchPatients: SearchPatientsReducerState;
  searchReport: SearchReportReducerState;
  searchResultsPage: SearchResultsPageReducerState;
  searchTeamUsers: SearchTeamUsersState;
  externalInvestigations: ExternalInvestigationsState;
  transplantCentreManagement: TransplantCentreReducerState;
  subject: SubjectReducerState;
  donor: DonorReducerState;
};

export default (history: any) =>
  combineReducers<any>({
    currentUser,
    featureFlags,
    hlaAntigens,
    form: formReducer,
    latestSearchRequests,
    notification,
    router: connectRouter(history),
    searchPatients,
    searchReport,
    searchResultsPage,
    searchTeamUsers,
    externalInvestigations,
    transplantCentreManagement,
    subject,
    donor,
  });

import moment from 'moment';
import { convertSearchTypeFromApi } from '../../core/constants/searchTypes';
import { convertAntigen } from '../../core';
import type { ApiResultSetSummary, ApiSearchRequest, ApiSearchRequestSummary } from '../types/api';
import type { ResultSetSummary, SearchRequest, SearchRequestSummary } from '../types';
import { ApiDonorHla } from '../../donorSearchResults/types/api';
import { ApiAntigen } from '../../core/types';

const convertResultSetSummaryFromApi = (apiResultSetSummary: ApiResultSetSummary): ResultSetSummary => ({
  algorithmUsed: apiResultSetSummary.AlgorithmUsed,
  donorType: apiResultSetSummary.DonorType,
  exactMatchCount: apiResultSetSummary.ExactMatchCount as number,
  mismatchCount: apiResultSetSummary.MismatchCount,
  potentialMatchCount: apiResultSetSummary.PotentialMatchCount as number,
  searchResultSetId: apiResultSetSummary.SearchResultSetId,
  unknownCount: apiResultSetSummary.UnknownCount,
});

const convertResultSetSummariesFromApi = (apiResultSetSummaries: ApiResultSetSummary[]): ResultSetSummary[] =>
  apiResultSetSummaries.map((apiResultSetSummary) => convertResultSetSummaryFromApi(apiResultSetSummary));

// convertIfPresent is necessary as API sometimes returns empty or undefined antigens (although typing could be updated to reflect this)
const convertIfPresent = (antigen: ApiAntigen) => (antigen ? convertAntigen(antigen) : antigen);

export const convertPatientHla = (apiPatientHla: ApiDonorHla) => ({
  hlaA1: convertIfPresent(apiPatientHla.A_1),
  hlaA2: convertIfPresent(apiPatientHla.A_2),
  hlaB1: convertIfPresent(apiPatientHla.B_1),
  hlaB2: convertIfPresent(apiPatientHla.B_2),
  hlaC1: convertIfPresent(apiPatientHla.C_1),
  hlaC2: convertIfPresent(apiPatientHla.C_2),
  drB11: convertIfPresent(apiPatientHla.Drb1_1),
  drB12: convertIfPresent(apiPatientHla.Drb1_2),
  drB31: convertIfPresent(apiPatientHla.Drb3_1),
  drB32: convertIfPresent(apiPatientHla.Drb3_2),
  drB41: convertIfPresent(apiPatientHla.Drb4_1),
  drB42: convertIfPresent(apiPatientHla.Drb4_2),
  drB51: convertIfPresent(apiPatientHla.Drb5_1),
  drB52: convertIfPresent(apiPatientHla.Drb5_2),
  dqA11: convertIfPresent(apiPatientHla.Dqa1_1),
  dqA12: convertIfPresent(apiPatientHla.Dqa1_2),
  dqB11: convertIfPresent(apiPatientHla.Dqb1_1),
  dqB12: convertIfPresent(apiPatientHla.Dqb1_2),
  dpA11: convertIfPresent(apiPatientHla.Dpa1_1),
  dpA12: convertIfPresent(apiPatientHla.Dpa1_2),
  dpB11: convertIfPresent(apiPatientHla.Dpb1_1),
  dpB12: convertIfPresent(apiPatientHla.Dpb1_2),
});

export const convertSearchRequestSummaryFromApi = (
  apiSearchRequestData: ApiSearchRequestSummary
): SearchRequestSummary => ({
  algorithmsUsed: apiSearchRequestData.AlgorithmsUsed,
  donorTypesSearched: apiSearchRequestData.DonorTypesSearched,
  emdisCordRegistriesSearched: apiSearchRequestData.EmdisCordRegistriesSearched,
  externalRegistries: apiSearchRequestData.ExternalRegistryDetails,
  id: apiSearchRequestData.Id,
  isAlignedRegistriesSearch: apiSearchRequestData.IsAlignedRegistriesSearch,
  isAnBmtSearch: apiSearchRequestData.IsAnBmtSearch,
  isAnCordSearch: apiSearchRequestData.IsAnCordSearch,
  isEmdisCordSearch: apiSearchRequestData.IsEmdisCordSearch,
  isHlaAdjusted: apiSearchRequestData.IsHlaAdjusted,
  isNhsBtCordSearch: apiSearchRequestData.IsNhsBtCordSearch,
  patientHla: apiSearchRequestData.PatientHla
    ? convertPatientHla(apiSearchRequestData.PatientHla)
    : apiSearchRequestData.PatientHla,
  patientId: apiSearchRequestData.PatientId,
  requestedByUser: apiSearchRequestData.RequestedByUser,
  requestedDate: moment(apiSearchRequestData.RequestedDate).format('DD/MM/YY'),
  resultSetSummaries: convertResultSetSummariesFromApi(apiSearchRequestData.ResultSetSummaries),
  // TODO-Flow: Convert to non-Api types
  savedCordSets: apiSearchRequestData.SavedCordSets,
  // TODO-Flow: Convert to non-Api types
  savedDonorSets: apiSearchRequestData.SavedDonorSets,
  returnedSolarDonorCount: apiSearchRequestData.ReturnedSolarDonorCount,
  searchedHlaMatchCurrentPatientHla: apiSearchRequestData.SearchedHlaMatchCurrentPatientHla,
  searchRunDate: moment(apiSearchRequestData.SearchRunDate).format('DD/MM/YY'),
  adultSearchType: apiSearchRequestData.AdultSearchType
    ? convertSearchTypeFromApi(apiSearchRequestData.AdultSearchType)
    : undefined,
  cordSearchType: apiSearchRequestData.CordSearchType
    ? convertSearchTypeFromApi(apiSearchRequestData.CordSearchType)
    : undefined,
  status: apiSearchRequestData.Status,
});

export const convertSearchRequestFromApi = (searchRequest: ApiSearchRequest): SearchRequest => ({
  ...convertSearchRequestSummaryFromApi(searchRequest),
  patientBirthDate: searchRequest.PatientBirthDate,
  patientGender: searchRequest.PatientGender,
  patientName: searchRequest.PatientName,
  patientWeight: searchRequest.PatientWeight,
});

import React, { useMemo } from 'react';
import _ from 'lodash';
import { testStatus } from '../../constants/testStatus';
import type { InvestigationTest } from '../../types';
import { WarningMessage } from '../../../core/components/WarningMessage';

type Props = {
  tests: InvestigationTest[];
  showStatus?: boolean;
};

const SubjectTestDetails = ({ tests, showStatus }: Props) => {
  const noTests = tests.every((test) => _.isEmpty(test));
  const sortedTests = useMemo(() => _.sortBy(tests, 'testName'), [tests]);

  const displayTestStatus = (test: InvestigationTest) => {
    if (test.IsComplete) return testStatus.complete;
    if (test.IsFailed) return testStatus.failed;
    if (test.IsNotTested) return testStatus.notTested;
    // If it's a DRB345 test with isNegative true, show as complete
    if ((test.TestName.includes('DRB345') || test.TestName.includes('DRB3/4/5')) && test.IsNegative)
      return testStatus.complete;
    return '';
  };

  return noTests ? (
    <WarningMessage message="No tests in selected category" />
  ) : (
    <table data-testid="subject-tests-table" className="table">
      <thead className="table-header">
        <tr>
          <th>Name</th>
          <th>Team</th>
          {showStatus && <th>Status</th>}
        </tr>
      </thead>
      <tbody>
        {sortedTests.map((test) => (
          <tr key={test.TestName}>
            <td>{test.TestName}</td>
            <td>{test.LabsTeam}</td>
            {showStatus && <td>{displayTestStatus(test)}</td>}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

SubjectTestDetails.defaultProps = {
  showStatus: false,
};

export default SubjectTestDetails;

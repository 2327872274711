import { LOCATION_CHANGE, RouterState } from 'connected-react-router';
import type { SelectedResult } from '../../../donorMatchSearchRequests/types';
// import { Actions as DonorSearchResultsActions } from '../../../donorSearchResults/redux/actionNames';
import { ClearFiltersAction, ClearMismatchesFilterAction, ResetResultsTableAction } from '../actions';
import type { AppliedFiltersArray, AppliedFiltersKey, AppliedFiltersMap, Filter, HideableColumn } from '../../types';
import { Actions } from '../actionNames';
import type {
  ShowSavedCordsSetAction,
  ShowSavedDonorsSetAction,
  UpdateAgeFilterAction,
  UpdateExcludeDkmsUkFilterAction,
  UpdateFiltersAction,
  UpdateHiddenColumnsAction,
  UpdateMinFilterAction,
  UpdateMismatchesFilterAction,
  UpdateSelectedItemsAction,
  UpdateSelectedNotesAction,
  UpdateValuesFilterAction,
} from '../actions';

type LocationChangeAction = { type: typeof LOCATION_CHANGE; payload: RouterState };

type UserActions =
  | ClearFiltersAction
  | ClearMismatchesFilterAction
  | LocationChangeAction
  | ResetResultsTableAction
  | ShowSavedCordsSetAction
  | ShowSavedDonorsSetAction
  | UpdateAgeFilterAction
  | UpdateExcludeDkmsUkFilterAction
  | UpdateFiltersAction
  | UpdateHiddenColumnsAction
  | UpdateMinFilterAction
  | UpdateMismatchesFilterAction
  | UpdateSelectedItemsAction
  | UpdateSelectedNotesAction
  | UpdateValuesFilterAction;

export type ResultsPageUIReducerState = {
  appliedFilters: AppliedFiltersMap;
  appliedFiltersArray: AppliedFiltersArray;
  hiddenColumns: HideableColumn[];
  savedDonorsNotes: string;
  selectedItems: Partial<SelectedResult>[];
  shownSavedCordSetId?: string;
  shownSavedDonorSetId?: string;
};

const initialState: ResultsPageUIReducerState = {
  selectedItems: [],
  appliedFilters: {
    abo: undefined,
    age: undefined,
    cd34: undefined,
    cmv: undefined,
    dpb1MatchCategories: undefined,
    excludeDkmsUkDonors: undefined,
    filterSummary: undefined,
    gender: undefined,
    grid: undefined,
    homeRegistryId: undefined,
    mismatches: undefined,
    originatingRegistryId: undefined,
    rhd: undefined,
    tnc: undefined,
  },
  appliedFiltersArray: [],
  hiddenColumns: [
    'ActualMatch',
    'CD45',
    'Contacted',
    'DRB3',
    'DRB4',
    'DRB5',
    'DPA1',
    'DQA1',
    'NC_ml',
    'PotentialMatch',
    'Tested',
    'UnavailableUntil',
  ],
  savedDonorsNotes: '',
};

const toAppliedFiltersArray = (filters: AppliedFiltersMap): AppliedFiltersArray => {
  const appliedFiltersArray: AppliedFiltersArray = [];
  Object.keys(filters).forEach((filterName: string) => {
    // TODO-Flow: Consider moving `filterSummary` key outside the main object
    if (filterName !== 'filterSummary' && filters[filterName as AppliedFiltersKey]) {
      const filter: Filter = filters[filterName as AppliedFiltersKey] as Filter;
      appliedFiltersArray.push(filter);
    }
  });
  return appliedFiltersArray;
};

// TODO-Flow: move away from handleActions, and/or introduce proper action type
const resultsPageUi = (
  state: ResultsPageUIReducerState = initialState,
  action: UserActions
): ResultsPageUIReducerState => {
  let appliedFilters: AppliedFiltersMap;
  switch (action.type) {
    case LOCATION_CHANGE:
      return { ...initialState };
    case Actions.SEARCH_RESULTS_UPDATE_AGE_FILTER:
      appliedFilters = {
        ...state.appliedFilters,
        age: {
          ...state.appliedFilters.age,
          name: 'age',
          value: {
            maxAge: action.payload.maxAge,
            minAge: action.payload.minAge,
          },
        },
      };
      return {
        ...state,
        appliedFilters,
        appliedFiltersArray: toAppliedFiltersArray(appliedFilters),
      };
    case Actions.SEARCH_RESULTS_UPDATE_EXCLUDE_DKMS_UK_FILTER:
      appliedFilters = {
        ...state.appliedFilters,
        excludeDkmsUkDonors: {
          name: 'excludeDkmsUkDonors',
          value: action.payload.excludeDkmsUkDonors,
        },
      };
      return {
        ...state,
        appliedFilters,
        appliedFiltersArray: toAppliedFiltersArray(appliedFilters),
      };
    case Actions.SEARCH_RESULTS_UPDATE_FILTERS:
      appliedFilters = action.isInternational
        ? {
            ...action.payload,
            excludeDkmsUkDonors: {
              name: 'excludeDkmsUkDonors',
              value: true,
            },
          }
        : {
            ...action.payload,
          };
      return {
        ...state,
        appliedFilters,
        appliedFiltersArray: toAppliedFiltersArray(appliedFilters),
      };
    case Actions.SEARCH_RESULTS_UPDATE_MIN_FILTER:
      appliedFilters = {
        ...state.appliedFilters,
        [action.payload.filter as string]: {
          ...state.appliedFilters[action.payload.filter],
          name: action.payload.filter,
          value: {
            min: action.payload.value,
          },
        },
      };
      return {
        ...state,
        appliedFilters,
        appliedFiltersArray: toAppliedFiltersArray(appliedFilters),
      };
    case Actions.SEARCH_RESULTS_UPDATE_MISMATCHES_FILTER:
      appliedFilters = {
        ...state.appliedFilters,
        mismatches: {
          ...state.appliedFilters.mismatches,
          name: 'mismatches',
          value: {
            locus: action.payload.locus,
            max: action.payload.max,
            searchLociCount: action.payload.searchLociCount,
          },
        },
      };
      return {
        ...state,
        appliedFilters,
        appliedFiltersArray: toAppliedFiltersArray(appliedFilters),
      };
    case Actions.SEARCH_RESULTS_CLEAR_MISMATCH_FILTER:
      appliedFilters = {
        ...state.appliedFilters,
        mismatches: undefined,
      };
      return {
        ...state,
        appliedFilters,
        appliedFiltersArray: toAppliedFiltersArray(appliedFilters),
      };
    case Actions.SEARCH_RESULTS_UPDATE_VALUES_FILTER:
      appliedFilters = {
        ...state.appliedFilters,
        [action.payload.filter as string]: {
          ...state.appliedFilters[action.payload.filter],
          name: action.payload.value.length === 0 ? undefined : action.payload.filter,
          value: action.payload.value,
        },
      };
      return {
        ...state,
        appliedFilters,
        appliedFiltersArray: toAppliedFiltersArray(appliedFilters),
      };
    case Actions.SEARCH_RESULTS_CLEAR_FILTERS:
      return {
        ...state,
        appliedFilters: initialState.appliedFilters,
        appliedFiltersArray: initialState.appliedFiltersArray,
      };
    case Actions.SEARCH_RESULTS_RESET_RESULTS_TABLE: {
      const { originatingRegistryId } = state.appliedFilters;
      const { excludeDkmsUkDonors } = state.appliedFilters;
      appliedFilters = {
        ...initialState.appliedFilters,
        originatingRegistryId,
        excludeDkmsUkDonors,
      };
      return {
        ...initialState,
        appliedFilters,
        appliedFiltersArray: toAppliedFiltersArray(appliedFilters),
      };
    }
    case Actions.SEARCH_RESULTS_SHOW_SAVED_CORDS_SET:
      return {
        ...state,
        shownSavedCordSetId: action.payload,
      };
    case Actions.SEARCH_RESULTS_SHOW_SAVED_DONORS_SET:
      return {
        ...state,
        shownSavedDonorSetId: action.payload,
      };
    case Actions.SEARCH_RESULTS_UPDATE_HIDDEN_COLUMNS:
      return {
        ...state,
        hiddenColumns: action.payload,
      };
    case Actions.SEARCH_RESULTS_UPDATE_SELECTED_ITEMS:
      return {
        ...state,
        selectedItems: action.payload,
      };
    case Actions.SEARCH_RESULTS_UPDATE_SELECTED_DONOR_NOTES:
      return {
        ...state,
        savedDonorsNotes: action.payload || '',
      };
    default:
      return state;
  }
};

export default resultsPageUi;

import React from 'react';
import Select from 'react-select';
import _ from 'lodash';
import FieldRow from '../../../../patientCreation/components/FieldRow/FieldRow';
import MaxDonorField from './MaxDonorField';
import type { EmdisSearchCreationDetails, InternationalSearchCreationConstants } from '../../../types';
import { SearchTypeDetails, searchTypesDetails } from '../../../../../core/constants/searchTypes';

type Props = {
  creationConstants?: InternationalSearchCreationConstants;
  requestCreationDetails: EmdisSearchCreationDetails;
  updateRequestCreationDetails: (details: EmdisSearchCreationDetails) => void;
};
type State = {
  isExpanded: boolean;
};
type RegistryDetailsOption = {
  value: string;
  label: string;
};
const mismatchSearchTypes = [
  searchTypesDetails.A_MISMATCH_SEARCH,
  searchTypesDetails.B_MISMATCH_SEARCH,
  searchTypesDetails.DRB1_MISMATCH_SEARCH,
];

export class RegistryDetails extends React.PureComponent<Props, State> {
  static defaultProps = {
    creationConstants: undefined,
  };

  state = {
    isExpanded: true,
  };

  render() {
    const { creationConstants, requestCreationDetails, updateRequestCreationDetails } = this.props;
    const { isExpanded } = this.state;

    const availableSearchTypes = requestCreationDetails.registry.MismatchSearchesAvailable
      ? [searchTypesDetails.POTENTIAL_SEARCH, ...mismatchSearchTypes]
      : [searchTypesDetails.POTENTIAL_SEARCH];

    return (
      <div>
        <button
          style={{
            alignItems: 'center',
            border: 'none',
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'row',
            fontWeight: 300,
            height: '50px',
            marginTop: '8px',
            paddingBottom: 0,
            textTransform: 'uppercase',
          }}
          onClick={(e) => {
            e.preventDefault();
            this.setState({ isExpanded: !isExpanded });
          }}
          type="button"
        >
          <p style={{ margin: 0 }}>
            {isExpanded ? '-  ' : '+  '}
            {requestCreationDetails.registry.Name}
          </p>
        </button>
        {isExpanded && (
          <Select
            isMulti
            value={requestCreationDetails.searchTypes.map((t: SearchTypeDetails) => ({
              value: t.value,
              label: t.label,
            }))}
            isClearable={false}
            options={availableSearchTypes.map((t) => ({
              value: t.value,
              label: t.label,
            }))}
            onChange={(selectedSearchTypes) => {
              updateRequestCreationDetails({
                ...requestCreationDetails,
                searchTypes: selectedSearchTypes?.map((st: RegistryDetailsOption) =>
                  _.find(searchTypesDetails, (t) => t.value === st.value)
                ) as SearchTypeDetails[],
              });
            }}
            className="react-select-container"
            classNamePrefix="react-select"
            placeholder="Select search types..."
          />
        )}
        {isExpanded && (
          <FieldRow>
            <MaxDonorField
              id="abdrMaxDonors"
              title="ABDR Max"
              maxDonorsValue={requestCreationDetails.abdrMaxDonors}
              defaultMaxDonors={creationConstants ? creationConstants.defaultMaxAbdrDonors : undefined}
              updateMaxDonors={(abdrMaxDonors: number) => {
                updateRequestCreationDetails({
                  ...requestCreationDetails,
                  abdrMaxDonors,
                });
              }}
            />
            <MaxDonorField
              id="cordMaxDonors"
              title="Cord Max"
              maxDonorsValue={requestCreationDetails.cordMaxDonors}
              defaultMaxDonors={creationConstants ? creationConstants.defaultMaxCordDonors : undefined}
              updateMaxDonors={(cordMaxDonors: number) => {
                updateRequestCreationDetails({
                  ...requestCreationDetails,
                  cordMaxDonors,
                });
              }}
            />
          </FieldRow>
        )}
      </div>
    );
  }
}

import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import Selectors from '../../redux/selectors';
import AlgorithmResultsOverviewTable from './AlgorithmResultsOverviewTable';
import AlgorithmDonorDifferencesTable from './AlgorithmDonorDifferencesTable';
import AlgorithmRankingComparator from './AlgorithmRankingComparator';
import donorTypes from '../../../core/constants/donorTypes';
import type { AdultSearchResult, CordSearchResult, SearchResults } from '../../../core/types';
import type { ReduxState } from '../../../rootReducer';
import type { AlgorithmResults } from './types';
import type { ResultSetSummary } from '../../../donorMatchSearchRequests/types';

type OwnProps = {
  resultSet1?: ResultSetSummary;
  resultSet2?: ResultSetSummary;
};
type StateProps = {
  searchResults1?: SearchResults;
  searchResults2?: SearchResults;
};
type Props = PropsFromRedux & OwnProps & StateProps;

const mapStateToProps = (state: ReduxState, ownProps: OwnProps): StateProps => ({
  searchResults1: ownProps.resultSet1
    ? Selectors.getSearchResults(state, ownProps.resultSet1.searchResultSetId)
    : undefined,
  searchResults2: ownProps.resultSet2
    ? Selectors.getSearchResults(state, ownProps.resultSet2.searchResultSetId)
    : undefined,
});
const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const loaderStyle = {
  width: '30px',
  height: '30px',
  margin: 'auto',
};

export class AlgorithmResultsViewer extends PureComponent<Props> {
  static defaultProps = {
    resultSet1: undefined,
    resultSet2: undefined,
    searchResults1: undefined,
    searchResults2: undefined,
  };

  render() {
    const { resultSet1, resultSet2, searchResults1, searchResults2 } = this.props;

    const algorithmResults1 =
      resultSet1 && searchResults1 ? this.getAlgorithmResults(resultSet1.algorithmUsed, searchResults1) : undefined;
    const algorithmResults2 =
      resultSet2 && searchResults2 ? this.getAlgorithmResults(resultSet2.algorithmUsed, searchResults2) : undefined;

    return (
      <div>
        <AlgorithmResultsOverviewTable resultSet1={resultSet1} resultSet2={resultSet2} />
        {algorithmResults1 && algorithmResults2 ? (
          <>
            <AlgorithmDonorDifferencesTable
              algorithm1Results={algorithmResults1}
              algorithm2Results={algorithmResults2}
            />
            <AlgorithmRankingComparator algorithm1Results={algorithmResults1} algorithm2Results={algorithmResults2} />
          </>
        ) : (
          <div className="loader" style={loaderStyle} />
        )}
      </div>
    );
  }

  getAlgorithmResults = (algorithmName: string, searchResults: SearchResults): AlgorithmResults => {
    const rankedDonorIds =
      searchResults.type === donorTypes.adult.value
        ? searchResults.results.map((result) => {
            const adultSearchResult = result as AdultSearchResult;
            return adultSearchResult.donor.id;
          })
        : searchResults.results.map((result) => {
            const cordSearchResult = result as CordSearchResult;
            return cordSearchResult.cord.id;
          });
    return {
      algorithmName,
      rankedDonorIds,
    };
  };
}

export default connector(AlgorithmResultsViewer);

/* eslint-disable import/no-import-module-exports */
import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import immutableCheckMiddleWare from 'redux-immutable-state-invariant';
import logger from 'redux-logger';
import { history } from './core';
import createRootReducer, { ReduxState } from './rootReducer';
import type { Dispatch } from './core/types';

const middleWare: any[] = [];
middleWare.push(thunk);
middleWare.push(routerMiddleware(history));

if (process.env.NODE_ENV === 'development') {
  middleWare.push(immutableCheckMiddleWare());
  middleWare.push(logger);
}

function configureStore() {
  // @ts-expect-error-disable-next-line no-underscore-dangle
  // eslint-disable-next-line no-underscore-dangle
  const create = window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__()(createStore) : createStore;

  const createStoreWithMiddleware = applyMiddleware<ReduxState, Dispatch<any>>(...middleWare)(create);

  const store = createStoreWithMiddleware(createRootReducer(history));

  // @ts-expect-error - Typescript should be made aware of the module.hot property
  if (module.hot) {
    // @ts-expect-error - Typescript should be made aware of the module.hot property
    module.hot.accept('./rootReducer', () => {
      const nextReducer = createRootReducer(history);
      store.replaceReducer(nextReducer);
    });
  }

  return store;
}

export default configureStore;

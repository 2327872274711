import React from 'react';
import { Link } from 'react-router-dom';
import queryString from 'query-string';

import type { DonorType } from '../../../../core/types';
import type { ResultTableRow } from '../../../../donorMatchSearchRequests/types';

type Props = {
  donorType: DonorType;
  reportId: string;
  resultSetId: string;
  mappedResultSets: ResultTableRow[];
  reportType: string;
};

export default ({ donorType, reportId, mappedResultSets, resultSetId, reportType }: Props) => {
  const resultSet = mappedResultSets.find((x: ResultTableRow) => x.resultSetId === resultSetId);

  return resultSet ? (
    <Link
      to={{
        pathname: `/donorsearch/${resultSet.requestId}/results`,
        search: queryString.stringify({
          donorType,
          reportId,
          resultSetId,
          reportType,
        }),
      }}
      className="btn"
    >
      View Results
    </Link>
  ) : null;
};

import React from 'react';
import * as FilterHelper from '../../../helpers/filterHelper';
import ValuesFilter from './ValuesFilter/ValuesFilter';
import MinFilter from './MinFilter/MinFilter';
import ClearFilters from './ClearFilters/ClearFilters';
import { filterNames } from '../../../constants/filterNames';
import { styles } from '../../../style';

export default () => (
  <div style={{ width: '100%' }}>
    <div className="col span_12_of_12" style={{ paddingBottom: '10px', display: 'flex' }}>
      <div className="col span_4_of_12">
        <ValuesFilter
          filterType={filterNames.cmv}
          filterName="CMV"
          isMulti
          selectOptions={FilterHelper.cmvOptions}
          showCords
        />
      </div>
      <div className="col span_4_of_12">
        <ValuesFilter
          filterType={filterNames.abo}
          filterName="ABO"
          isMulti
          selectOptions={FilterHelper.aboOptions}
          showCords
        />
      </div>
      <div className="col span_3_of_12">
        <MinFilter filterType={filterNames.cd34} filterName="CD34" />
      </div>
      <div className="col span_3_of_12">
        <MinFilter filterType={filterNames.tnc} filterName="TNC" />
      </div>
      <div className="col span_2_of_12" style={styles.flexFilterButton}>
        <ClearFilters />
      </div>
    </div>
  </div>
);

import React from 'react';
import { ReportStatuses } from '../../core/constants';

import type { ReportStatus } from '../../types';

type Props = {
  status: ReportStatus;
};

export default ({ status }: Props) => {
  const formattedStatus = status === ReportStatuses.AwaitingAuthorisation ? 'Awaiting Authorisation' : status;

  return <span style={{ paddingTop: '10px' }}>{formattedStatus}</span>;
};

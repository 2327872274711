import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch as ReduxDispatch } from 'redux';
import * as actions from '../../../../redux/actions';
import * as FilterHelper from '../../../../helpers/filterHelper';
import Selectors from '../../../../redux/selectors';
import mismatchFilterValues from '../../../../../core/constants/mismatchFilterValues';

import type { FilterOptions, MismatchesFilter, MismatchesFilterValue, MismatchLocus } from '../../../../types/index';
import type { ReduxState } from '../../../../../rootReducer';
import { searchLociCount } from '../../../../constants/searchLociCount';
import { filterNames } from '../../../../constants/filterNames';
import ValuesFilter from '../ValuesFilter/ValuesFilter';

type OwnProps = {
  selectOptions: FilterOptions;
};
type StateProps = {
  mismatches?: MismatchesFilter;
  value?: MismatchesFilterValue;
};
type Props = PropsFromRedux & OwnProps & StateProps;

type CheckboxEvent = { checked: boolean } & HTMLInputElement;

const mapStateToProps = (state: ReduxState): StateProps => ({
  value: Selectors.getMismatchesFilter(state),
  mismatches: Selectors.getAppliedFilters(state).mismatches,
});

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>) => ({
  clearMismatches: bindActionCreators(actions.clearMismatchesFilter, dispatch),
  updateMismatchesFilter: bindActionCreators(actions.updateMismatchesFilter, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export class Filter extends PureComponent<Props> {
  static defaultProps = {
    mismatches: undefined,
    value: undefined,
  };

  render() {
    const { mismatches, selectOptions, value } = this.props;
    const locus = value ? value.locus : undefined;
    const max = value ? value.max : undefined;
    return (
      <>
        <div
          style={{
            borderBottom: '1px solid #ccc',
            marginBottom: '10px',
            paddingBottom: '5px',
          }}
        >
          <strong>Mismatches</strong>
        </div>

        <div className="col span_7_of_12">
          {selectOptions.map((item) => (
            <div key={item.value} className="form-radio-wrapper">
              <input
                checked={item.value === locus}
                id={item.value || mismatchFilterValues.None}
                name="mismatchLocus"
                onChange={this.handleLocusChange}
                type="radio"
                className="form-radio--large"
                value={item.value || mismatchFilterValues.None}
              />
              <label htmlFor={item.value || mismatchFilterValues.None}>{item.label}</label>
            </div>
          ))}
          <div className="form-radio-wrapper">
            <input
              checked={!mismatches}
              id={mismatchFilterValues.ShowAll}
              name="mismatchLocus"
              onChange={this.handleLocusChange}
              type="radio"
              className="form-radio--large"
              value={mismatchFilterValues.ShowAll}
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor={mismatchFilterValues.ShowAll}>Show All</label>
          </div>
        </div>

        <div className="col span_4_of_12">
          <div className="form-checkbox-wrapper">
            <input
              checked={max === 2}
              disabled={!locus}
              id="twoMismatches"
              name="twoMismatches"
              onChange={this.handleTwoMismatchesChange}
              type="checkbox"
              className="form-checkbox--large"
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="twoMismatches">Allow two mismatches</label>
          </div>
          <div>
            <ValuesFilter
              filterType={filterNames.dpb1MatchCategories}
              filterName="DPB1 Match Category"
              isMulti
              selectOptions={FilterHelper.dpb1MatchCategoryOptions}
              menuPlacement="top"
            />
          </div>
        </div>
      </>
    );
  }

  handleLocusChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { updateMismatchesFilter, clearMismatches } = this.props;
    if (event.currentTarget.value === mismatchFilterValues.ShowAll) {
      clearMismatches();
    } else if (event.currentTarget.value === mismatchFilterValues.None) {
      updateMismatchesFilter(null, null, searchLociCount);
    } else {
      const mismatatchLocus = event.currentTarget.value;
      updateMismatchesFilter(mismatatchLocus as MismatchLocus, 1, searchLociCount);
    }
  };

  handleTwoMismatchesChange = (event: React.SyntheticEvent<CheckboxEvent>) => {
    const { updateMismatchesFilter, value } = this.props;
    const locus = value ? value.locus : undefined;
    updateMismatchesFilter(locus, event.currentTarget.checked ? 2 : 1, searchLociCount);
  };
}

export default connector(Filter);

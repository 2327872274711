import React from 'react';
import { useMsal } from '@azure/msal-react';

const NotAuthorisedPage = () => {
  const { instance } = useMsal();

  const onLogout = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    instance.logoutRedirect();
  };

  return (
    <div className="col span_12_of_12">
      <div
        className="span_4_of_12 push_4"
        style={{
          textAlign: 'center',
        }}
      >
        <div>
          <h1>Not Authorised!</h1>
          <h3>Sorry, you are not authorised to view this page.</h3>
          <a href="/logout" onClick={onLogout}>
            Click here to log out
          </a>
        </div>
      </div>
    </div>
  );
};

export default NotAuthorisedPage;

import _ from 'lodash';
import type { SavedResultSet } from '../../../donorMatchSearchRequests/types';

export const getCountryNames = (
  selectedDonorSetIds: string[],
  allSavedDonorSets: { [string: string]: SavedResultSet }
): (string | undefined)[] =>
  _.uniq(
    selectedDonorSetIds
      .map((setId) => allSavedDonorSets[setId].selectedResults.map((selectedResult) => selectedResult.countryName))
      // $FlowExpectedError - https://github.com/facebook/flow/issues/7397
      .flat()
  );

import React, { PureComponent } from 'react';
import { AnyAction, bindActionCreators, Dispatch as ReduxDispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import Select, { SingleValue } from 'react-select';
import * as actions from '../../redux/actions';
import RenderDonorMatchSearchRequestSummary from '../RenderDonorMatchSearchRequestSummary';
import Selectors from '../../redux/selectors';
import { ErrorMessage, LoadingMessage } from '../../../core';
import { getUsers as getUsersAction, UserSelectors } from '../../../users';

import type { ApiSearchRequest } from '../../types/api';
import type { User } from '../../../users/types';
import type { ReduxState } from '../../../rootReducer';

type StateProps = {
  hasUserFetchErrored: boolean;
  hasUserRequestsFetchErrored: boolean;
  isFetchingRequests: boolean;
  searchTeamUsers: User[];
  selectedUser?: string | undefined;
  userFetchErrorMessage: string[];
  userRequestsErrorMessage: string[];
  userSearchRequests: ApiSearchRequest[];
};
type Props = PropsFromRedux & StateProps;

type UserOption = {
  value: string | undefined;
  label: string;
};
type State = {
  options: UserOption[];
};

const mapStateToProps = (state: ReduxState): StateProps => ({
  hasUserFetchErrored: UserSelectors.hasUserFetchErrored(state),
  hasUserRequestsFetchErrored: Selectors.hasUserRequestsFetchErrored(state),
  isFetchingRequests: Selectors.getSearchRequests(state).isFetching,
  searchTeamUsers: UserSelectors.getCurrentUsers(state),
  selectedUser: Selectors.getSelectedUser(state),
  userFetchErrorMessage: UserSelectors.getUserFetchErrorMessage(state),
  userRequestsErrorMessage: Selectors.getRequestsErrorMessage(state),
  userSearchRequests: UserSelectors.getUserSearchRequests(state),
});

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>) => ({
  getUsers: bindActionCreators(getUsersAction, dispatch),
  getUserSearchRequests: bindActionCreators(actions.getUserSearchRequests, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export class SearchUsersSearchRequest extends PureComponent<Props, State> {
  static getUserOptions = (users: User[]): UserOption[] =>
    users.map((user) => ({
      value: user.Username,
      label: user.FullName,
    }));

  static defaultProps = {
    selectedUser: undefined,
  };

  state: State = { options: [] };

  componentDidMount() {
    const { getUserSearchRequests, selectedUser } = this.props;
    this.getSearchTeamUser();
    if (selectedUser) {
      getUserSearchRequests(selectedUser);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { searchTeamUsers } = this.props;

    if (searchTeamUsers !== prevProps.searchTeamUsers) {
      // TODO: Remove setState from componentDidUpdate
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        options: SearchUsersSearchRequest.getUserOptions(searchTeamUsers),
      });
    }
  }

  render() {
    const { hasUserFetchErrored, isFetchingRequests, selectedUser, userFetchErrorMessage } = this.props;
    const { options } = this.state;
    const getLabel = (users: UserOption[], username: string | undefined) => {
      const user = users.find((o) => o.value === username);
      return user ? user.label : 'Select user...';
    };

    return (
      <div className="requestsList">
        <h2 className="border-bottom-solid">Recent Search Requests</h2>
        <Select
          options={options}
          name="selected-user"
          value={{
            value: selectedUser || undefined,
            label: getLabel(options, selectedUser),
          }}
          onChange={this.getLatestUserRequests}
          isSearchable
          isClearable={false}
          className="react-select-container"
          classNamePrefix="react-select"
        />
        {hasUserFetchErrored ? (
          <div style={{ marginTop: '10px' }}>
            <ErrorMessage errorMessages={userFetchErrorMessage} />
          </div>
        ) : (
          <LoadingMessage isLoading={isFetchingRequests}>{this.renderList()}</LoadingMessage>
        )}
      </div>
    );
  }

  getSearchTeamUser = async () => {
    const { getUsers, searchTeamUsers } = this.props;
    await getUsers();
    await SearchUsersSearchRequest.getUserOptions(searchTeamUsers);
  };

  getLatestUserRequests = (newValue: SingleValue<UserOption>) => {
    const { getUserSearchRequests } = this.props;
    if (newValue) {
      getUserSearchRequests(newValue.value as string);
    }
  };

  renderList(): React.ReactNode {
    const { userSearchRequests, hasUserRequestsFetchErrored, userRequestsErrorMessage } = this.props;
    if (hasUserRequestsFetchErrored) {
      return (
        <div style={{ marginTop: '10px' }}>
          <ErrorMessage errorMessages={userRequestsErrorMessage} />
        </div>
      );
    }
    return (
      userSearchRequests &&
      userSearchRequests.map((request) => <RenderDonorMatchSearchRequestSummary key={request.Id} request={request} />)
    );
  }
}

export default connector(SearchUsersSearchRequest);

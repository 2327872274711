import React from 'react';
import UserProfile from '../../users/components/UserProfile';
import UserRoles from '../../users/components/UserRoles';
import NavBar from './NavButtons/NavButtons';

const Header = () => (
  <header id="an-header">
    <div className="nav section group">
      <NavBar />
      <UserProfile />
      <UserRoles />
    </div>
  </header>
);

export default Header;

import React from 'react';
import donorTypes from '../../../core/constants/donorTypes';
import type { AdultSearchResult, CordSearchResult, SearchResult } from '../../../core/types';
import LocusCell from './LocusCell';

type Props = {
  result: SearchResult;
};

const ValidationResultsRow = ({ result }: Props) => {
  // const donor = result.type === donorTypes.adult.value ? result.donor : result.cord;
  let donor;
  let matchGrade;
  if (result.type === donorTypes.adult.value && result.type === 'Adult') {
    const adultSearchResult = result as AdultSearchResult;
    matchGrade = result.type === donorTypes.adult.value && result.searchMatchGrade;
    donor = adultSearchResult.donor;
  } else {
    const cordSearchResult = result as CordSearchResult;
    donor = cordSearchResult.cord;
  }

  return (
    <tr>
      <td>
        <div>{donor.id}</div>
        <div>{matchGrade}</div>
      </td>
      <LocusCell locusInfo={result.locusAInfo} position1={donor.hlaA1} position2={donor.hlaA2} />
      <LocusCell locusInfo={result.locusBInfo} position1={donor.hlaB1} position2={donor.hlaB2} />
      <LocusCell locusInfo={result.locusCInfo} position1={donor.hlaC1} position2={donor.hlaC2} />
      <LocusCell locusInfo={result.locusDqb1Info} position1={donor.dqB11} position2={donor.dqB12} />
      <LocusCell locusInfo={result.locusDrb1Info} position1={donor.drB11} position2={donor.drB12} />
      <LocusCell locusInfo={result.locusDpb1Info} position1={donor.dpB11} position2={donor.dpB12} />
    </tr>
  );
};

export default ValidationResultsRow;

import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch as ReduxDispatch } from 'redux';
import * as actions from '../../../../redux/actions';

type Props = PropsFromRedux;

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>) => ({
  clearFilters: bindActionCreators(actions.clearFilters, dispatch),
});

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const ClearFilters = ({ clearFilters }: Props) => (
  <button type="button" name="clearFilters" value="Filter" onClick={clearFilters} className="btn btn--inline">
    Clear Filters
  </button>
);

export default connector(ClearFilters);

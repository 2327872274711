import React, { PureComponent } from 'react';
import ReasonPopUp from './ReasonPopUp';
import type { ReportStatus } from '../../types';

type Props = {
  buttonName: string;
  onButtonClick: (status: ReportStatus, reason: string) => void;
  popUpMessage: string;
  updatedStatus: ReportStatus;
};

type State = {
  isPopUpShown: boolean;
};

class ReportPageReasonButton extends PureComponent<Props, State> {
  target: HTMLButtonElement | null | undefined;

  constructor(props: Props) {
    super(props);
    this.state = {
      isPopUpShown: false,
    };
  }

  render() {
    const { buttonName, popUpMessage, updatedStatus } = this.props;
    const { isPopUpShown } = this.state;
    return (
      <div>
        <ReasonPopUp
          handleButtonClick={this.handleUnauthorise}
          updatedStatus={updatedStatus}
          buttonName={buttonName}
          message={popUpMessage}
          isPopUpShown={isPopUpShown}
          onCancel={this.handleCancel}
          onConfirm={this.handleConfirm}
          target={this.target}
          placement="left"
        />
      </div>
    );
  }

  handleUnauthorise = () => {
    this.setState({ isPopUpShown: true });
  };

  handleCancel = () => {
    this.setState({ isPopUpShown: false });
  };

  handleConfirm = (reason: string) => {
    const { onButtonClick, updatedStatus } = this.props;
    onButtonClick(updatedStatus, reason);
    this.setState({ isPopUpShown: false });
  };
}

export default ReportPageReasonButton;

const EXTERNAL: 'External' = 'External';
const FAMILY_DONOR: 'Family' = 'Family';
const INTERNATIONAL_DONOR: 'International' = 'International';
const QUALITY_CONTROL: 'Quality Control' = 'Quality Control';
const SOLID_ORGAN: 'Solid Organ' = 'Solid Organ';

const POSITIVE: 'Positive' = 'Positive';
const NEGATIVE: 'Negative' = 'Negative';
const EQUIVOCAL: 'Equivocal' = 'Equivocal';
const UNKNOWN: 'Unknown' = 'Unknown';

export const fieldTypes = {
  date: 'date',
  select: 'select',
  text: 'text',
  textarea: 'textarea',
};

export const donorTypesDetails = {
  external: {
    value: EXTERNAL,
    label: 'Commercial',
  },
  family: {
    value: FAMILY_DONOR,
    label: FAMILY_DONOR,
  },
  international: {
    value: INTERNATIONAL_DONOR,
    label: INTERNATIONAL_DONOR,
  },
  qualityControl: {
    value: QUALITY_CONTROL,
    label: QUALITY_CONTROL,
  },
  solidOrgan: {
    value: SOLID_ORGAN,
    label: SOLID_ORGAN,
  },
};

export const cmvStatuses = [POSITIVE, NEGATIVE, EQUIVOCAL, UNKNOWN];

export const bloodGroupTypes = ['A', 'AB', 'B', 'O'];

export const Rhesus = [POSITIVE, NEGATIVE];

import _ from 'lodash';
import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, AnyAction, Dispatch as ReduxDispatch } from 'redux';
import { styles } from '../../style';
import * as actions from '../../redux/actions';
import Selectors from '../../redux/selectors';
import EmdisCordRegistries from '../../../../core/constants/EmdisCordRegistries';
import Checkbox, { CheckboxEvent } from '../../../../core/components/Checkbox';

import type { ReduxState } from '../../../../rootReducer';
import type { PatientDashboardReducerState } from '../../redux/reducer';

type StateProps = {
  searchRequestData: PatientDashboardReducerState;
};
type Props = PropsFromRedux & StateProps;

const mapStateToProps = (state: ReduxState): StateProps => ({
  searchRequestData: Selectors.getSearchRequestData(state),
});

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>) => ({
  toggleIsACord: bindActionCreators(actions.toggleIsACord, dispatch),
  updateEmdisRegistries: bindActionCreators(actions.updateEmdisRegistries, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class CordRequestOptions extends PureComponent<Props> {
  render() {
    const { searchRequestData } = this.props;
    let searchCriteria;
    if (searchRequestData.searchAlgorithms.includes('Solar')) {
      searchCriteria = ' - 4-6/6';
    } else if (searchRequestData.searchAlgorithms.includes('Atlas')) {
      searchCriteria = ' - 4-8/8';
    } else {
      searchCriteria = '';
    }

    return (
      <div>
        <div>
          <h3 style={styles.searchTitle}>Cord Search</h3>
          <Checkbox
            checked={searchRequestData.isACordSearch}
            id="isACordSearch"
            onChange={this.handleIsACordCheckbox}
            label={`Is a Cord Search${searchCriteria}`}
          />
        </div>
        {searchRequestData.isACordSearch && (
          <div id="cord-registry-options">
            <h3 style={styles.searchTitle}>Cord EMDIS Registries</h3>
            {EmdisCordRegistries.map((registry) => (
              <div key={registry} style={{ display: 'inline-block', width: '20%' }}>
                <Checkbox
                  checked={this.isRegistrySelected(registry)}
                  id={registry}
                  onChange={this.handleEmdisRegistryCheckbox}
                  label={registry}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }

  isRegistrySelected = (registry: string) => {
    const { searchRequestData } = this.props;
    return searchRequestData.cordEmdisRegistries.includes(registry);
  };

  handleIsACordCheckbox = () => {
    const { toggleIsACord, updateEmdisRegistries } = this.props;
    toggleIsACord();
    updateEmdisRegistries([]);
  };

  handleEmdisRegistryCheckbox = (event: React.SyntheticEvent<CheckboxEvent>) => {
    const { searchRequestData, updateEmdisRegistries } = this.props;
    const { id } = event.currentTarget;

    const emdisRegistries = [...searchRequestData.cordEmdisRegistries];

    const newRegistries = _.xor([id], emdisRegistries);
    updateEmdisRegistries(newRegistries);
  };
}

export default connector(CordRequestOptions);

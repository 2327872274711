import React from 'react';
import PopUpWrapper from '../../../core/components/PopUpWrapper/PopUpWrapper';

export const FamilyRelationshipInfoButton = () => (
  <>
    Family Relationship
    <div style={{ display: 'inline-block' }}>
      <PopUpWrapper
        buttonInfo
        name=""
        placement="top"
        buttonClassName="btn btn--inline btn--info btn--primary"
        popoverContainerClassName="info-popup"
      >
        <small style={{ display: 'inline-block', lineHeight: '1.3' }}>
          The Family Relationship is the Donor&apos;s relationship to the Patient. For example, if the Donor is the
          Patient&apos;s mother, the relationship is &apos;mother&apos;, rather than &apos;child&apos;.
        </small>
      </PopUpWrapper>
    </div>
  </>
);

import type { ReduxState } from '../../rootReducer';

const getSubjectConstants = (state: ReduxState) => state.donor.constants;

const getDonor = (state: ReduxState) => state.donor.donor;
const isLoadingDonor = (state: ReduxState): boolean => state.donor.loadingDonor;
const loadingDonorError = (state: ReduxState) => state.donor.loadingDonorError;

const isSavingDonor = (state: ReduxState) => state.donor.savingDonor;
const getSavingRecordError = (state: ReduxState) => state.donor.savingRecordError;

export { getSubjectConstants, getDonor, isLoadingDonor, loadingDonorError, isSavingDonor, getSavingRecordError };

import * as React from 'react';
import { bindActionCreators, AnyAction, Dispatch as ReduxDispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import * as actions from '../redux/actions';

type DispatchProps = {
  getFeatureFlags: typeof actions.getFeatureFlags;
};
type OwnProps = {
  children: React.ReactNode;
};
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & OwnProps;

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>): DispatchProps => ({
  getFeatureFlags: bindActionCreators(actions.getFeatureFlags, dispatch),
});

class FeatureFlagsProvider extends React.Component<Props> {
  componentDidMount() {
    const { getFeatureFlags } = this.props;
    getFeatureFlags();
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

const connector = connect(null, mapDispatchToProps);

export default connector(FeatureFlagsProvider);

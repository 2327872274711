const TEST_REQUESTS = 'testRequests';
const REJECTED_MESSAGES = 'rejectedMessages';

export const tabItems = {
  testResults: {
    id: TEST_REQUESTS,
    displayName: 'Test Requests',
  },
  rejectedMessages: {
    id: REJECTED_MESSAGES,
    displayName: 'Rejected Messages',
  },
};

export type TabItem = { id: string; displayName: string };

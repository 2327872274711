import type { ApiCountry, ApiCustomCountry, ApiSavedSetCountry } from '../../types/api';
import type { Country, CustomCountry, SavedSetCountry } from '../../types';

export const formatCountries = (countries: ApiCountry[]): Country[] =>
  countries.map((country) => ({
    name: country.Name,
    hidden: country.Hidden,
    isPending: country.IsPending,
    isReported: country.IsReported,
    isReporting: country.IsReporting,
  }));

export const formatCustomCountries = (countries: ApiCustomCountry[]): CustomCountry[] =>
  countries.map((country, index) => ({
    id: index.toString(),
    name: country.Name,
    isPending: country.IsPending,
    isReported: country.IsReported,
    isReporting: country.IsReporting,
  }));

export const formatSavedSetCountries = (savedSetCountries: ApiSavedSetCountry[]): SavedSetCountry[] =>
  savedSetCountries.map((country) => ({
    name: country.Name,
    isReported: country.IsReported,
  }));

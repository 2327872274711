import type { Antigen, Locus } from '../../types';

const isHlaObjectEmpty = (hla: Locus) => Boolean(!Object.keys(hla).length);

const areLociDuplicates = (locus1: Locus, locus2: Locus) =>
  locus1.hlaName === locus2.hlaName && locus1.nmdpString === locus2.nmdpString;

const addPrefixToString = (locusString: string, prefix: string) => {
  if (locusString) {
    return `${prefix}${locusString}`;
  }
  return '';
};

const removeHomozygousData = (data: Antigen[]): Antigen[] => {
  const uniqSet = new Set(data.map((e) => JSON.stringify(e)));
  return Array.from(uniqSet).map((e) => JSON.parse(e));
};

export default {
  areLociDuplicates,
  addPrefixToString,
  removeHomozygousData,
  isHlaObjectEmpty,
};

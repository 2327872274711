import React from 'react';
import { ExternalInvestigationSelectedDonor } from '../types';
import { SingleLineTextArea } from '../../../../core/components/SingleLineTextArea';
import { formatRequestContext } from '../../../../core/helpers/markupHelpers';
import '../individualNewTestRequest.scss';

type Props = {
  patientId: string;
  selectedDonor: ExternalInvestigationSelectedDonor;
  remark?: string;
  updateRemark: (remark: string, donorId: string) => void;
};

const IndividualNewIDMRequest = (props: Props) => {
  const { selectedDonor, patientId, remark } = props;

  const handleRemarkChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { updateRemark } = props;
    updateRemark(event.currentTarget.value, selectedDonor.id);
  };
  return (
    <div className="donor-container">
      {selectedDonor.grid ? (
        <div className="border-bottom">
          {formatRequestContext(
            patientId,
            selectedDonor.originatingRegistry.name,
            selectedDonor.id,
            selectedDonor.grid
          )}
          <SingleLineTextArea
            rows={1}
            maxLength={120}
            placeholder="Remarks (PO can be added here)"
            value={remark}
            onChange={handleRemarkChange}
          />
        </div>
      ) : (
        <div className="border-bottom">
          {formatRequestContext(
            patientId,
            selectedDonor.originatingRegistry.name,
            selectedDonor.id,
            selectedDonor.grid
          )}
          <div className="error-message blocker-message" data-testid="error">
            <span>Cannot create a test request for a donor with no GRID</span>
          </div>
        </div>
      )}
    </div>
  );
};

IndividualNewIDMRequest.defaultProps = {
  remark: undefined,
};

export default IndividualNewIDMRequest;

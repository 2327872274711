import { combineReducers } from 'redux';
// eslint-disable-next-line import/no-cycle
import type { CurrentReportReducerState } from '../../currentReport/redux/reducer';
import currentReport from '../../currentReport/redux/reducer'; // eslint-disable-next-line import/no-cycle

import type { SavedReportsReducerState } from '../../savedReports/redux/reducer';
import savedReports from '../../savedReports/redux/reducer'; // eslint-disable-next-line import/no-cycle

import type { ReportDashbordReducerState } from '../../reportDashboard/redux/reducer';
import reportDashboard from '../../reportDashboard/redux/reducer';

export type SearchReportReducerState = {
  currentReport: CurrentReportReducerState;
  reportDashboard: ReportDashbordReducerState;
  savedReports: SavedReportsReducerState;
};

const searchReport = combineReducers<SearchReportReducerState>({
  currentReport,
  reportDashboard,
  savedReports,
});

export default searchReport;

import React, { PureComponent } from 'react';
import Select, { SingleValue } from 'react-select';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, AnyAction, Dispatch as ReduxDispatch } from 'redux';
import SavedReportsSelectors from '../../savedReports/redux/selectors';
import DashboardSelectors from '../redux/selectors';
import { filterReportsOnDashboardByUser } from '../redux/actions';
import type { ReduxState } from '../../../rootReducer';

type DispatchProps = {
  filterUserReports: typeof filterReportsOnDashboardByUser;
};
type StateProps = {
  selectedUser?: string;
  usersList: string[];
};

type UserOption = {
  value: string | undefined;
  label: string;
};

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

type State = {
  userOptions: UserOption[];
};

const mapStateToProps = (state: ReduxState): StateProps => ({
  usersList: SavedReportsSelectors.getUsersWithReportsList(state),
  selectedUser: DashboardSelectors.getSelectedUser(state),
});

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>): DispatchProps => ({
  filterUserReports: bindActionCreators(filterReportsOnDashboardByUser, dispatch),
});

class UsersReportsSelect extends PureComponent<Props, State> {
  static defaultProps = {
    selectedUser: undefined,
  };

  state: State = { userOptions: [] };

  componentDidMount() {
    this.getUserOptions();
  }

  render() {
    const { selectedUser } = this.props;
    const { userOptions } = this.state;
    const emptyOption = { value: '', label: 'Select User...' };

    const userOptionsPlusEmptyOption = [emptyOption, ...userOptions];

    return (
      <div style={{ marginBottom: '25px' }}>
        <div>
          <h4>Select a user to view their reports...</h4>
        </div>
        <div>
          <Select
            options={userOptionsPlusEmptyOption}
            value={{
              value: selectedUser,
              label: selectedUser || 'Select User...',
            }}
            name="userReports"
            isSearchable
            isClearable={false}
            onChange={this.filterReportsByUser}
            className="react-select-container"
            classNamePrefix="react-select"
          />
        </div>
      </div>
    );
  }

  getUserOptions() {
    const { usersList } = this.props;
    const options = usersList.map((user) => ({
      value: user,
      label: user,
    }));
    this.setState({ userOptions: options });
  }

  filterReportsByUser = (newValue: SingleValue<UserOption>) => {
    const { filterUserReports } = this.props;
    if (newValue) {
      filterUserReports(newValue.value as string);
    }
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(UsersReportsSelect);

export const extendedTypingResolutionVariants = {
  hlaA: 'HLA-A',
  hlaB: 'HLA-B',
  hlaC: 'HLA-C',
  hlaDrb1: 'HLA-DRB1',
  hlaDrb3: 'HLA-DRB3',
  hlaDrb4: 'HLA-DRB4',
  hlaDrb5: 'HLA-DRB5',
  hlaDqa1: 'HLA-DQA1',
  hlaDqb1: 'HLA-DQB1',
  hlaDpa1: 'HLA-DPA1',
  hlaDpb1: 'HLA-DPB1',
};

export type ExtendedTypingResolutionVariants = keyof typeof extendedTypingResolutionVariants;

import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
  message: string;
  link?: { url: string; text: string };
};

export const WarningMessage = ({ message, link }: Props) => (
  <div className="alert alert--warning" role="img" aria-label="no-results-message">
    <span aria-label="warning symbol" role="img" style={{ marginRight: 10 }}>
      ⚠️
    </span>
    {message}
    {link && (
      <Link to={link.url}>
        <span>{link.text}</span>
      </Link>
    )}
  </div>
);

WarningMessage.defaultProps = {
  link: undefined,
};

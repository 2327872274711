export type Option = {
  id: string;
  label: string;
};

export const verificationTypingOptions: { [string: string]: Option } = {
  edta: {
    label: 'EDTA',
    id: 'edta',
  },
  heparin: {
    label: 'HEPARIN',
    id: 'heparin',
  },
  acd: {
    label: 'ACD',
    id: 'acd',
  },
  clotted: {
    label: 'CLOTTED',
    id: 'clotted',
  },
};

import _ from 'lodash';
import moment from 'moment';
import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import { AnyAction, bindActionCreators, Dispatch as ReduxDispatch } from 'redux';
import { change, clearFields, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';
import { LoadingMessage } from '../../../../core';
import type { ReduxState } from '../../../../rootReducer';
import defaultTab from '../../../core/constants/defaultPatientDashboardTab';
import { institutionAddressTypes } from '../../../core/constants/institutionAddressTypes';
import type { InstitutionAddress } from '../../../../core/types';
import type { CreationConstants, Institution, PatientDetails, PatientInfo } from '../../../types';
import FieldRow from '../../components/FieldRow/FieldRow';
import LabelledField from '../../components/LabelledField/LabelledField';
import {
  getInitialValues,
  mapInstitutionOptions,
  mapInvoiceeAddressOptions,
  mapSelectOptions,
  PATIENT_STATUS_OPEN,
  validate,
  warn,
} from '../../helpers/formsHelpers';
import RenderSelectInput from '../../helpers/RenderSelectInput';
import * as actions from '../../redux/actions';
import { styles as commonStyles } from '../../style';
import { ApiMatchingPatient } from '../../types/api';

const PATIENT_DATA_FORM_ID = 'patientData';
const CLOSED_ONLY_FIELD_IDS = ['reasonClosed', 'dateClosed', 'causeOfDeath', 'dateOfDeath'];

const styles = {
  ...commonStyles,
  buttonGroupStyle: {
    display: 'flex',
    width: 'auto',
    alignItems: 'center',
    paddingBottom: '15px',
  },
  submitButtonStyle: {
    cursor: 'pointer',
  },
  buttonStyle: {
    marginLeft: '2px',
  },
  duplicateAlert: {
    color: 'red',
    paddingBottom: '10px',
    textDecoration: 'underline',
  },
};

type OwnProps = {
  patientInfo?: PatientInfo;
  handleSubmit: (patientDetails: PatientDetails) => void;
  initialize?: (arg0: Record<string, unknown>) => void;
  updating?: boolean;
};
const formSelector = formValueSelector(PATIENT_DATA_FORM_ID);

const mapStateToProps = (state: ReduxState): StateProps => ({
  creationConstants: state.searchPatients.patientCreation.patientCreationConstants,
  hospitals: state.searchPatients.patientCreation.hospitals,
  laboratories: state.searchPatients.patientCreation.laboratories,
  externalRegistries: state.searchPatients.patientCreation.externalRegistries,
  institutionAddresses: state.searchPatients.patientCreation.institutionAddresses,
  existingMatchingPatients: state.searchPatients.patientCreation.existingMatchingPatients,
  patientStatus: formSelector(state, 'status'),
  firstName: formSelector(state, 'firstName'),
  surname: formSelector(state, 'surname'),
  dateOfBirth: formSelector(state, 'dateOfBirth'),
});

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>) => ({
  fetchCreationConstants: bindActionCreators(actions.getPatientCreationConstants, dispatch),
  fetchHospitals: bindActionCreators(actions.getHospitals, dispatch),
  fetchLaboratories: bindActionCreators(actions.getLaboratories, dispatch),
  fetchExternalRegistries: bindActionCreators(actions.getExternalRegistries, dispatch),
  fetchInstitutionAddresses: bindActionCreators(actions.getInstitutionAddresses, dispatch),
  getPatientDuplicates: bindActionCreators(actions.getPatientDuplicates, dispatch),
  resetClosedOnlyFields: () => dispatch(clearFields(PATIENT_DATA_FORM_ID, false, false, ...CLOSED_ONLY_FIELD_IDS)),
  setInvoicee: (value: number, institutionAddresses: InstitutionAddress[]) => {
    const invoiceeAddresses = institutionAddresses.filter((address) => address.InstitutionId === value);
    const billingAddress = invoiceeAddresses.find((address) => address.AddressType === institutionAddressTypes.billing);
    const mainAddress = invoiceeAddresses.find((address) => address.AddressType === institutionAddressTypes.main);
    const invoiceeAddress = billingAddress || mainAddress;
    const invoiceeOption = invoiceeAddress && invoiceeAddress.Id ? invoiceeAddress.Id : null;
    dispatch(change(PATIENT_DATA_FORM_ID, 'invoicee', invoiceeOption));
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & StateProps & OwnProps;

type StateProps = {
  creationConstants: CreationConstants;
  dateOfBirth: moment.Moment;
  existingMatchingPatients: ApiMatchingPatient[] | undefined;
  externalRegistries: Institution[];
  firstName: string;
  hospitals: Institution[];
  institutionAddresses: InstitutionAddress[];
  laboratories: Institution[];
  patientStatus: string;
  surname: string;
};

type State = {
  genderTypeOptions: string[];
  ethnicGroupTypeOptions: string[];
  patientTypeOptions: string[];
  rhdTypeOptions: string[];
  bloodGroupTypeOptions: string[];
  patientStatusTypeOptions: string[];
  diagnosisTypeOptions: string[];
  hospitals: Institution[];
  laboratories: Institution[];
  externalRegistries: Institution[];
  institutionAddresses: InstitutionAddress[];
};

export class PatientDetailsForm extends PureComponent<
  Props & InjectedFormProps<Record<string, unknown>, Props>,
  State
> {
  constructor(props: Props & InjectedFormProps<Record<string, unknown>, Props>) {
    super(props);
    this.state = {
      genderTypeOptions: _.get(props, 'creationConstants.genderTypeOptions', []),
      ethnicGroupTypeOptions: _.get(props, 'creationConstants.ethnicGroupTypeOptions', []),
      patientTypeOptions: _.get(props, 'creationConstants.patientTypeOptions', []),
      rhdTypeOptions: _.get(props, 'creationConstants.rhdTypeOptions', []),
      bloodGroupTypeOptions: _.get(props, 'creationConstants.bloodGroupTypeOptions', []),
      patientStatusTypeOptions: _.get(props, 'creationConstants.patientStatusTypeOptions', []),
      diagnosisTypeOptions: _.get(props, 'creationConstants.diagnosisTypeOptions', []),
      hospitals: props.hospitals || [],
      laboratories: props.laboratories || [],
      externalRegistries: props.externalRegistries || [],
      institutionAddresses: props.institutionAddresses || [],
    };
  }

  componentDidMount() {
    const {
      fetchCreationConstants,
      fetchExternalRegistries,
      fetchHospitals,
      fetchInstitutionAddresses,
      fetchLaboratories,
      initialize,
      patientInfo,
    } = this.props;
    Promise.all([
      fetchCreationConstants(),
      fetchHospitals(),
      fetchLaboratories(),
      fetchExternalRegistries(),
      fetchInstitutionAddresses(),
    ]).then(this.addExistingValuesToOptionsIfNotPresent);

    if (initialize) initialize(getInitialValues(patientInfo as PatientInfo));
  }

  render() {
    const {
      genderTypeOptions,
      ethnicGroupTypeOptions,
      patientTypeOptions,
      rhdTypeOptions,
      bloodGroupTypeOptions,
      patientStatusTypeOptions,
      diagnosisTypeOptions,
    } = this.state;

    const {
      creationConstants,
      existingMatchingPatients,
      handleSubmit,
      patientInfo,
      resetClosedOnlyFields,
      setInvoicee,
      updating,
    } = this.props;

    // The enum in the Patients service requires an explicit Unknown, but one is not returned from the creation constants call
    const cmvAntibodyTypeOptions = [...creationConstants.cmvAntibodyTypeOptions, 'Unknown'];

    const { hospitals, laboratories, externalRegistries, institutionAddresses } = this.state;

    const isLoading = _.some(
      [hospitals, laboratories, institutionAddresses, externalRegistries, ..._.values(creationConstants)],
      _.isEmpty
    );

    const patientIdPath = patientInfo ? `/patient/${patientInfo.id}/${defaultTab}` : '/';

    return (
      <LoadingMessage isLoading={isLoading}>
        <div className="col span_12_of_12">
          <form autoComplete="off" action="" onSubmit={handleSubmit}>
            <div style={styles.buttonHeader}>
              <h3>General Details</h3>
            </div>
            <FieldRow>
              <LabelledField
                name="patientType"
                label="Patient Type"
                component={RenderSelectInput}
                options={mapSelectOptions(patientTypeOptions)}
              />
              <LabelledField
                name="isPrivatePatient"
                label="Private Patient?"
                component="input"
                type="checkbox"
                className="form-checkbox--large"
              />
              <LabelledField
                name="status"
                label="Status"
                component={RenderSelectInput}
                onChange={resetClosedOnlyFields}
                options={mapSelectOptions(patientStatusTypeOptions)}
              />
              <LabelledField name="dateRegistered" label="Created On" component="input" type="date" />
            </FieldRow>
            {this.closedRow()}
            <FieldRow>
              <LabelledField
                name="firstName"
                label="First Name*"
                component="input"
                type="text"
                onBlur={this.onPatientNameInputBlur}
              />
              <LabelledField
                name="secondName"
                label="Second Name"
                component="input"
                type="text"
                placeholder="Second Name"
              />
              <LabelledField
                name="surname"
                label="Surname*"
                component="input"
                type="text"
                onBlur={this.onPatientNameInputBlur}
              />
              <LabelledField
                name="dateOfBirth"
                label="Date of Birth*"
                component="input"
                type="date"
                onBlur={this.onPatientNameInputBlur}
              />
            </FieldRow>
            {existingMatchingPatients &&
              !updating &&
              existingMatchingPatients.map((patient) => (
                <div style={styles.duplicateAlert}>
                  <a href={`/patient/${patient.Id}/${defaultTab}`} rel="noopener noreferrer" target="_blank">
                    {patient.FirstName} {patient.LastName} already exists in database. Click to open in new tab.
                  </a>
                </div>
              ))}

            <FieldRow>
              <LabelledField name="weight" label="Weight (kg)" component="input" type="text" />
              <LabelledField
                name="gender"
                label="Gender"
                component={RenderSelectInput}
                options={mapSelectOptions(genderTypeOptions)}
              />
              <LabelledField
                name="ethnicity"
                label="Ethnicity"
                component={RenderSelectInput}
                options={mapSelectOptions(ethnicGroupTypeOptions)}
              />
            </FieldRow>
            <FieldRow>
              <LabelledField
                name="diagnosis"
                label="Diagnosis"
                component={RenderSelectInput}
                options={mapSelectOptions(diagnosisTypeOptions)}
              />
              <LabelledField name="diagnosisDate" label="Date of Diagnosis" component="input" type="date" />
            </FieldRow>
            <FieldRow>
              <LabelledField
                name="cmvType"
                label="CMV Type"
                component={RenderSelectInput}
                options={mapSelectOptions(cmvAntibodyTypeOptions)}
              />
              <LabelledField name="cmvTestedDate" label="Date CMV Tested" component="input" type="date" />
              <LabelledField
                name="bloodGroup"
                label="Blood Group"
                component={RenderSelectInput}
                options={mapSelectOptions(bloodGroupTypeOptions)}
              />
              <LabelledField
                name="rhType"
                label="Rh Type"
                component={RenderSelectInput}
                options={mapSelectOptions(rhdTypeOptions)}
              />
            </FieldRow>
            <h3>Hospital/Transplant Centre Information</h3>
            <FieldRow>
              <LabelledField
                name="hospital"
                label="Patient's Hospital"
                component={RenderSelectInput}
                options={mapInstitutionOptions(hospitals)}
                // @ts-expect-error - onChange function has different type in LabelledField
                onChange={(value: number) => setInvoicee(value, institutionAddresses)}
              />
              <LabelledField name="referringDoctor" label="Referring Doctor" component="input" type="text" />
              <LabelledField
                name="hospitalAssignedPatientId"
                label="Patient Hospital ID"
                component="input"
                type="text"
              />
            </FieldRow>
            <FieldRow>
              <LabelledField
                name="invoicee"
                label="Invoicee"
                component={RenderSelectInput}
                // @ts-expect-error - TODO: need to find correct typing for value in option type for LabelledField component
                options={mapInvoiceeAddressOptions(
                  institutionAddresses,
                  patientInfo?.invoicee ? parseInt(patientInfo.invoicee, 10) : undefined
                )}
              />
            </FieldRow>
            <FieldRow>
              <LabelledField
                name="originatingRegistry"
                label="Originating Registry"
                component={RenderSelectInput}
                options={mapInstitutionOptions(externalRegistries)}
              />
              <LabelledField
                name="originatingRegistryPatientId"
                label="Originating Registry Patient ID"
                component="input"
                type="text"
              />
            </FieldRow>
            <FieldRow>
              <LabelledField
                name="externalLaboratory"
                label="External Laboratory"
                component={RenderSelectInput}
                options={mapInstitutionOptions(laboratories)}
              />
            </FieldRow>
            <h3>File Location</h3>
            <FieldRow>
              <LabelledField name="ukOrGaisFileLocation" label="UK or GIAS" component="input" type="text" />
              <LabelledField name="internationalFileLocation" label="International" component="input" type="text" />
              <LabelledField name="cordFileLocation" label="CORD" component="input" type="text" />
            </FieldRow>
            <FieldRow>
              <LabelledField name="notes" label="Notes" component="textarea" />
            </FieldRow>
            <div className="inline group" style={{ display: 'flex' }}>
              <button className="btn btn--inline" id="submit" type="submit" disabled={this.isButtonDisabled()}>
                Save Patient Details
              </button>
              <Link className="btn btn--inline btn--secondary" to={patientIdPath}>
                Cancel
              </Link>
            </div>
          </form>
        </div>
      </LoadingMessage>
    );
  }

  addExistingValuesToOptionsIfNotPresent = () => {
    const { creationConstants, patientInfo, hospitals, laboratories, externalRegistries, institutionAddresses } =
      this.props;
    const {
      genderTypeOptions,
      ethnicGroupTypeOptions,
      patientTypeOptions,
      rhdTypeOptions,
      bloodGroupTypeOptions,
      patientStatusTypeOptions,
      diagnosisTypeOptions,
    } = creationConstants;

    const appendExistingValueToOptionsIfNotPresent = (options: string[], propertyKey: string) => {
      if (!patientInfo) {
        return options;
      }
      const existingValue = _.get(patientInfo, propertyKey);
      return options.includes(existingValue) ? options : [existingValue, ...options];
    };

    const appendExistingValueToInstitutionsIfNotPresent = (institutions: Institution[], propertyKey: string) => {
      if (!patientInfo) {
        return institutions;
      }
      const existingRegistryId = _.get(patientInfo, propertyKey);
      return institutions.some((r: Institution) => r.Id === existingRegistryId)
        ? institutions
        : [
            {
              Id: existingRegistryId,
              Name: 'Institution deprecated',
            },
            ...institutions,
          ];
    };

    const appendExistingValueToInstitutionAddressesIfNotPresent = (
      addresses: InstitutionAddress[],
      propertyKey: string
    ): InstitutionAddress[] => {
      if (!patientInfo) {
        return addresses;
      }
      const existingRegistryId = _.get(patientInfo, propertyKey);
      return addresses.some((r: InstitutionAddress) => r.Id === existingRegistryId)
        ? addresses
        : [
            {
              InstitutionId: 0,
              Id: existingRegistryId,
              Name: 'Institution deprecated',
              Address1: '',
            } as InstitutionAddress,
            ...addresses,
          ];
    };

    // We append the existing values, even if they are not in the available options
    // This is to maintain backwards compatibility with records created with now obsolete values
    this.setState({
      ethnicGroupTypeOptions: appendExistingValueToOptionsIfNotPresent(ethnicGroupTypeOptions, 'ethnicity'),
      genderTypeOptions: appendExistingValueToOptionsIfNotPresent(genderTypeOptions, 'gender'),
      patientTypeOptions: appendExistingValueToOptionsIfNotPresent(patientTypeOptions, 'patientType'),
      rhdTypeOptions: appendExistingValueToOptionsIfNotPresent(rhdTypeOptions, 'rhd'),
      bloodGroupTypeOptions: appendExistingValueToOptionsIfNotPresent(bloodGroupTypeOptions, 'abo'),
      patientStatusTypeOptions: appendExistingValueToOptionsIfNotPresent(patientStatusTypeOptions, 'status'),
      diagnosisTypeOptions: appendExistingValueToOptionsIfNotPresent(diagnosisTypeOptions, 'diagnosis'),
      hospitals: appendExistingValueToInstitutionsIfNotPresent(hospitals, 'hospital'),
      laboratories: appendExistingValueToInstitutionsIfNotPresent(laboratories, 'laboratoryId'),
      externalRegistries: appendExistingValueToInstitutionsIfNotPresent(externalRegistries, 'originatingRegistryId'),
      institutionAddresses: appendExistingValueToInstitutionAddressesIfNotPresent(institutionAddresses, 'invoicee'),
    });
  };

  onPatientNameInputBlur = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const { dateOfBirth, firstName, getPatientDuplicates, surname } = this.props;

    if (firstName && surname && dateOfBirth) {
      await getPatientDuplicates(firstName, surname, moment(dateOfBirth).format('DD-MMM-YYYY'));
    }
  };

  closedRow = () => {
    const { creationConstants, patientStatus } = this.props;
    const shouldShowClosedSection = patientStatus !== PATIENT_STATUS_OPEN;
    const closedSection = [
      <LabelledField
        name="reasonClosed"
        key="reasonClosed"
        label="Reason Closed"
        component={RenderSelectInput}
        options={mapSelectOptions(creationConstants.searchClosureReasonOptions)}
      />,
      <LabelledField name="dateClosed" key="dateClosed" label="Date Closed" component="input" type="date" />,
      <LabelledField name="causeOfDeath" key="causeOfDeath" label="Cause of Death" component="input" type="text" />,
      <LabelledField name="dateOfDeath" key="dateOfDeath" label="Date of Death" component="input" type="date" />,
    ];
    return <FieldRow>{shouldShowClosedSection ? closedSection : null}</FieldRow>;
  };

  // TODO: drive this from redux-form validation data
  isButtonDisabled = () => false;
}

// @ts-expect-error - TODO-1793: replace/remove redux-form
const patientDetailsForm = reduxForm({ form: PATIENT_DATA_FORM_ID, validate, warn })(PatientDetailsForm);
export default connector(patientDetailsForm);

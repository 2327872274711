import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import Selectors from '../../core/redux/selectors';
import PatientSearchResultCardLoadingContainer from './PatientSearchResultCardLoadingContainer';
import PatientSummaryResultsCardsLoadingContainer from './PatientSummaryResultsCardsLoadingContainer';
import * as SearchTypes from '../helpers/patientSearchTypes';

import type { PatientSearchType } from '../../types';
import type { ReduxState } from '../../../rootReducer';

type StateProps = {
  searchType: PatientSearchType;
};
type Props = PropsFromRedux & StateProps;

const mapStateToProps = (state: ReduxState): StateProps => ({
  searchType: Selectors.getSearchType(state),
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class PatientSearchResults extends PureComponent<Props> {
  render() {
    const { searchType } = this.props;
    if (searchType === SearchTypes.PATIENT_SEARCH_BY_ID) {
      return <PatientSearchResultCardLoadingContainer />;
    }
    return <PatientSummaryResultsCardsLoadingContainer />;
  }
}

export default connector(PatientSearchResults);

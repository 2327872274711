import * as React from 'react';

type Props = {
  children?: React.ReactNode;
};

const FieldRow = ({ children }: Props): JSX.Element => <div className="fieldRow">{children}</div>;

FieldRow.defaultProps = { children: undefined };

export default FieldRow;

import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, AnyAction, Dispatch as ReduxDispatch } from 'redux';
import * as actions from '../../currentReport/redux/actions';
import { ConfirmationPopUp } from '../../../core/index';

type OwnProps = {
  reportId: string;
};

type Props = PropsFromRedux & OwnProps;
type State = {
  isDeleteReportPopUpShown: boolean;
};

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>) => ({
  deleteReport: bindActionCreators(actions.deleteReport, dispatch),
});

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class DeleteReportButton extends PureComponent<Props, State> {
  // eslint-disable-next-line react/no-unused-class-component-methods
  target: HTMLButtonElement | null | undefined;

  constructor(props: Props) {
    super(props);
    this.state = {
      isDeleteReportPopUpShown: false,
    };
  }

  render() {
    const { isDeleteReportPopUpShown } = this.state;
    return (
      <div>
        <ConfirmationPopUp
          buttonClassName="btn btn--secondary"
          buttonText="Delete"
          placement="right"
          isPopUpShown={isDeleteReportPopUpShown}
          onConfirm={this.handleConfirmDelete}
          onCancelButton={this.handleCancelDelete}
          onOpenPopUp={this.handleDeleteButtonClick}
          message="Are you sure you want to delete this report?"
        />
      </div>
    );
  }

  handleConfirmDelete = () => {
    const { deleteReport, reportId } = this.props;
    deleteReport(reportId);
    this.setState({ isDeleteReportPopUpShown: false });
  };

  handleDeleteButtonClick = () => {
    this.setState({ isDeleteReportPopUpShown: true });
  };

  handleCancelDelete = () => {
    this.setState({ isDeleteReportPopUpShown: false });
  };
}

export default connector(DeleteReportButton);

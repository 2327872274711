import React from 'react';
import Select from 'react-select';
import { subjectSearchTypes } from '../../../constants/subjectSearchTypes';
import type { InvestigationTest, SubjectType, TestCategory } from '../../../types';
import SubjectTestDetails from '../SubjectTestDetails';
import SubjectTestSelector from '../SubjectTestSelector';
import SubjectTestCategories from './SubjectTestCategories';
import {
  investigationTypes,
  patientInvestigationTypeOptions,
  donorInvestigationTypeOptions,
} from '../../../constants/investigationTypes';
import { getSelectOptionLabel } from '../../../../core/helpers/getSelectOptionLabel';

type Props = {
  investigationType: string;
  loading: boolean;
  onInvestigationTypeChange: (investigationType: string) => void;
  onSelectedCategoryChange: (selectedCategory: string) => void;
  selectedCategoryRunCode: string | undefined;
  selectedTests: number[];
  setSelectedTests: (selectedTests: number[]) => void;
  subjectType: SubjectType;
  testCategoriesForInvestigationType: TestCategory[];
  testsForSelectedCategory?: InvestigationTest[];
};

const SubjectInvestigationDetails = ({
  investigationType,
  loading,
  onInvestigationTypeChange,
  onSelectedCategoryChange,
  selectedCategoryRunCode,
  selectedTests,
  setSelectedTests,
  subjectType,
  testCategoriesForInvestigationType,
  testsForSelectedCategory,
}: Props) => {
  const options =
    subjectType === subjectSearchTypes.donor.id ? donorInvestigationTypeOptions : patientInvestigationTypeOptions;

  return (
    <>
      <h4>Investigation Details</h4>
      {loading ? (
        <div className="loader small">&nbsp;</div>
      ) : (
        <div style={{ alignItems: 'end', display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label data-testid="investigation-type" htmlFor="investigation-type" style={{ width: '48%' }}>
            Investigation Type*
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              isClearable={false}
              placeholder="Select..."
              onChange={(e) => onInvestigationTypeChange(e?.value as string)}
              name="investigation-type"
              options={options}
              value={{ label: getSelectOptionLabel(options, investigationType), value: investigationType }}
            />
          </label>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label data-testid="test-category" htmlFor="test-categories" style={{ width: '48%' }}>
            Category*
            <SubjectTestCategories
              categories={testCategoriesForInvestigationType}
              onSelectedCategoryChange={onSelectedCategoryChange}
              runCode={selectedCategoryRunCode}
            />
          </label>
        </div>
      )}
      {testsForSelectedCategory &&
        (investigationType === investigationTypes.confirmatoryTyping.value ||
        investigationType === investigationTypes.externalConfirmatoryTyping.value ? (
          <SubjectTestSelector
            onSelectedTestsUpdate={setSelectedTests}
            selectedTests={selectedTests}
            tests={testsForSelectedCategory}
          />
        ) : (
          <SubjectTestDetails tests={testsForSelectedCategory} />
        ))}
    </>
  );
};

SubjectInvestigationDetails.defaultProps = {
  testsForSelectedCategory: [],
};

export default SubjectInvestigationDetails;

import React, { CSSProperties, PureComponent } from 'react';
import _ from 'lodash';
import MdCheck from 'react-icons/lib/md/check';
import IoArrowExpand from 'react-icons/lib/io/arrow-expand';
import IoIosSearchStrong from 'react-icons/lib/io/ios-search-strong';

import type { Antigen } from '../../../core/types';
import { shouldTruncate, truncateString } from '../../helpers/HlaStringHelpers';

import './HlaLookupRow.scss';

type Props = {
  antigen: Antigen;
  onSelect: () => void;
  onLookUp: (arg0: string) => Promise<void>;
  backgroundColor?: string;
  searchString: string;
  isSearching: boolean;
};

type State = {
  expanded: boolean;
  backgroundColor?: string;
};

const rowStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingLeft: 8,
};

const hlaLookUpButtonStyle = {
  align: 'right',
  minHeight: 0,
  padding: 5,
  marginLeft: 4,
  marginRight: 4,
};

const textButtonStyle: CSSProperties = {
  width: '100%',
  border: '0',
  fontSize: '20px',
  padding: '20px',
  textAlign: 'left',
  backgroundColor: 'transparent',
};

const wrapTextStyle: CSSProperties = {
  wordBreak: 'break-word',
};

export class HlaLookupRow extends PureComponent<Props, State> {
  static defaultProps = {
    backgroundColor: undefined,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      expanded: false,
      backgroundColor: undefined,
    };
  }

  render() {
    return (
      <div
        style={_.merge({}, rowStyle, {
          background: this.rowBackgroundColor(),
        })}
      >
        {this.antigenText()}
        {this.expandButton()}
        {this.lookUpButton()}
        {this.selectButton()}
      </div>
    );
  }

  rowBackgroundColor = () => {
    const { backgroundColor: pBgColor } = this.props;
    const { backgroundColor: sBgColor } = this.state;
    return sBgColor || pBgColor;
  };

  lookUpButton = () => {
    const { antigen, onLookUp } = this.props;
    return (
      <button
        id="lookUpButton"
        className="btn"
        style={hlaLookUpButtonStyle}
        type="button"
        onClick={() => onLookUp(antigen.hlaName)}
      >
        <IoIosSearchStrong size="32" />
      </button>
    );
  };

  expandButton = () => {
    const { antigen } = this.props;
    const { expanded } = this.state;
    const button = (
      <button
        id="expandButton"
        className="btn"
        style={hlaLookUpButtonStyle}
        type="button"
        onClick={() => this.setState({ expanded: true })}
      >
        <IoArrowExpand size="32" />
      </button>
    );
    return shouldTruncate(antigen.hlaName) && !expanded ? button : null;
  };

  selectButton = () => {
    const { onSelect } = this.props;
    return (
      <button id="selectButton" className="btn" style={hlaLookUpButtonStyle} type="button" onClick={onSelect}>
        <MdCheck size="32" />
      </button>
    );
  };

  antigenText = () => {
    const { onSelect } = this.props;
    return (
      <button
        className="hla-lookup-row"
        style={textButtonStyle}
        onClick={onSelect}
        onMouseOver={() => this.setState({ backgroundColor: '#adcad650' })}
        onMouseLeave={() => this.setState({ backgroundColor: undefined })}
        onFocus={() => this.setState({ backgroundColor: '#adcad650' })}
        onBlur={() => this.setState({ backgroundColor: undefined })}
        type="button"
      >
        {this.nmdpText()}
        {this.hlaText()}
      </button>
    );
  };

  nmdpText = () => {
    const { antigen } = this.props;
    return <div>{antigen.nmdpString ? `${antigen.nmdpString} ` : ''}</div>;
  };

  hlaText = () => {
    const { antigen, isSearching, searchString } = this.props;
    const { expanded } = this.state;
    const numberOfCharsToBold = searchString.charAt(0) === '*' ? searchString.length : searchString.length + 1;

    const hla = antigen.hlaName;
    const truncated = truncateString(hla);
    const displayedText = expanded ? hla : truncated;
    // Bold as much of the string as has been searched for, as we know it matches
    // Not sure if this is detrimental to performance - we can just use displayedText as is if so
    const semiBoldedText = (
      <div style={wrapTextStyle}>
        <b>{displayedText.slice(0, numberOfCharsToBold)}</b>
        {displayedText.substring(numberOfCharsToBold, displayedText.length)}
      </div>
    );
    return isSearching ? displayedText : semiBoldedText;
  };
}

export default HlaLookupRow;

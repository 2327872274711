import { toast, ToastContent } from 'react-toastify';
import type { Response } from '@an/nova-frontend-rest-client';

export const addNotification = (requestData: Response<any>) => {
  if (requestData.response.ok) {
    toast.success(requestData.params.successMessage as ToastContent, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 5000,
      closeButton: false,
    });
  }
  if (!requestData.response.ok) {
    const errorMessage = `${requestData.params.errorMessage}`;
    toast.error(errorMessage, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 10000,
      closeButton: false,
    });
  }
};
